import { stringUtils } from "cayo.ui";
import React, { CSSProperties, FC, Fragment } from "react";
import styled from "styled-components";
import { Severity } from "../../api/cayo-graph";
import Critical from "./../../images/risk-indicators/critical.svg";
import Error from "./../../images/risk-indicators/error.svg";
import High from "./../../images/risk-indicators/high.svg";
import Informational from "./../../images/risk-indicators/informational.svg";
import Low from "./../../images/risk-indicators/low.svg";
import Medium from "./../../images/risk-indicators/medium.svg";
import None from "./../../images/risk-indicators/none.svg";
import Success from "./../../images/risk-indicators/success.svg";
import Warning from "./../../images/risk-indicators/warning.svg";

type SeverityMap = {
  [key in Severity]: any;
};

const iconMap: SeverityMap = {
  none: None,
  critical: Critical,
  high: High,
  medium: Medium,
  error: Error,
  informational: Informational,
  low: Low,
  success: Success,
  warning: Warning,
  debug: <Fragment />,
};

const RiskIndicator: FC<{ severity: Severity; style?: CSSProperties }> = ({ severity, style }) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
      title={stringUtils.makePascal(severity)}
    >
      <StyledSvg
        alt="3dots"
        src={iconMap[(severity || "none").toLowerCase() as Severity]}
        width="22"
        height="16"
      />
    </div>
  );
};

const StyledSvg = styled.img`
  text-align: center;
`;

export default RiskIndicator;
