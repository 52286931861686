import { PrimaryButton } from "@fluentui/react";
import { DropDownPicker } from "cayo.ui";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { PossibleValueItem, PropertyAnnotation } from "../../api/cayo-graph";
import { IObjectPicker } from "../../api/schema.api";
import logger from "../../libs/logger";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
import ObjectPicker from "../ObjectPicker";
import { IPickerItem } from "../ObjectPicker/IPickerItem";
import { IFormComponent } from "../QueryFilterForm/IFormComponent";
import { commonMessages } from "../common-messages";
import { useQuickFieldTitle } from "./hooks";

const log = logger.getLogger("InplaceObjectPicker");

type InplaceObjectPickerProps = Omit<IObjectPicker, "type"> &
  IFormComponent & {
    value: IPickerItem[];
    onApply?: () => void;
    onPickerStateChanged?: (isOpened: boolean) => void;
    propertyAnnotations?: PropertyAnnotation[];
    targetType?: string;
    possibleValues?: PossibleValueItem[];
  };

const InplaceObjectPicker: FC<InplaceObjectPickerProps> = (props) => {
  const toggleDropDownPicker = useRef<() => void>();
  const intl = useIntl();

  const { onPickerStateChanged, possibleValues } = props;

  const title = useQuickFieldTitle(props.shortName || props.name!, props.value, possibleValues);

  const pickerOpened = useRef(false);
  const pickerStateHandler = useCallback(
    function (isOpened: boolean) {
      if (!!props.onPickerStateChanged) {
        pickerOpened.current = isOpened;
        props.onPickerStateChanged(isOpened);
      }
    },
    [props.onPickerStateChanged]
  );

  useEffect(() => {
    return () => {
      if (pickerOpened.current) {
        onPickerStateChanged && onPickerStateChanged(false);
      }

      log.debug("unmount", props.name);
    };
  }, []);

  return (
    <DropDownPicker
      title={title.title || title.shortName}
      initToggle={(f) => (toggleDropDownPicker.current = f)}
      isActive={!!title.title}
      onPickerStateChanged={pickerStateHandler}
      onClose={() => {
        props.setValue(props.value, props);
      }}
    >
      <Picker {...props} />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <PrimaryButton
          text={intl.formatMessage(commonMessages.save)}
          onClick={() => {
            props.setValue(props.value, props);
            onPickerStateChanged && onPickerStateChanged(false);
            toggleDropDownPicker.current && toggleDropDownPicker.current();
          }}
          style={{ marginTop: 6, width: "100%" }}
        />
      </div>
    </DropDownPicker>
  );
};

export const Picker = (props: InplaceObjectPickerProps) => {
  const { getIconAnnotation } = useTypeAnnotations();
  const { propertyAnnotations, targetType } = props;
  return (
    <ObjectPicker
      key={props.name}
      {...props}
      hideMore={true}
      label={props.label}
      showClearButton={true}
      getIconAnnotation={getIconAnnotation}
      propertyAnnotations={propertyAnnotations}
      targetType={targetType}
    />
  );
};

export default InplaceObjectPicker;
