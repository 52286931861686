import { o, OdataConfig, OHandler } from "odata";
import logger from "../libs/logger";

export const oClient = (rootUrl: string | URL, config?: OdataConfig | any): OHandler => {
  const actualConfig: OdataConfig = {
    credentials: "same-origin",
    headers: new Headers({
      // Authorization: ajaxUtils.getAuthHeader() || "",
      "Content-Type": "application/json",
    }),
    ...config,
    onError: onRequestError,
  };

  let fixedRoot = rootUrl.toString();
  if (!fixedRoot.endsWith("/")) {
    fixedRoot += "/";
  }

  const handler = o(fixedRoot, actualConfig);

  return handler;
};

const onRequestError = (h: OHandler, response: Response) => {
  logger.debug(response);
};

export const parseResponse = (responseText: string) => {
  const resultData200 = undefined;
  try {
    return JSON.parse(responseText);
  } catch {
    return resultData200;
  }
};

export const getError = (e: any): Promise<any> => {
  return getErrorMessage(e).then((message) => Promise.reject(message));
};

export async function getErrorMessage(e: any): Promise<string> {
  if (typeof e === "string") {
    return e;
  }
  if (e.message) {
    return e.message;
  }

  const responseText = await e.text();
  const resultData200 = parseResponse(responseText);

  const error: string =
    (resultData200 &&
      (resultData200?.error?.message ||
        resultData200.message ||
        resultData200[Object.keys(resultData200)[0]])) ||
    e.statusText ||
    e.status;

  return error || responseText || "Unknown error";
}
