import { IColumn, IFormField } from "../../api/schema.api";
import { ExpressionLogicType } from "../GridContainer/grid-model/Query";
import { IExpressionFormState } from "./components/ExpressionForm";
import { OperandType } from "./utils";

type Nullable<T> = T | null;

export type ValueType = {
  [key: string]: any;
};

export type SetValueType = (
  value: ValueType,
  itemAnnotation: IFormField,
  expression?: ExpressionType
) => void;

export type FilterValuesType = {
  value: ValueType;
  expressionForm: IExpressionFormState;
};

export type ExpressionType = {
  kind: string;
  objectType: string;
  operator: string;
  operands: OperandType[];
  enabled: boolean;
};

export type LogicalExpressionType = Omit<ExpressionType, "kind"> & {
  enabled: boolean;
  columns?: Array<IColumn>;
};

export type OperandModelType = {
  objectType: ExpressionLogicType;
  property: string;
  value: string;
  enabled: boolean;
  columns?: Array<IColumn>;
  operator: string;
  metadata: Nullable<{
    [key: string]: any;
  }>;
};

export enum SyntaxEnum {
  string = "Edm.String",
  integer64 = "Edm.Int64",
  integer32 = "Edm.Int32",
  date = "Edm.DateTimeOffset",
}

export const syntaxRegex = {
  [SyntaxEnum.integer32]: {
    regex: /^\d+$/,
    errorMessage: "Please enter a valid integer value.",
  },

  [SyntaxEnum.integer64]: {
    regex: /^\d+$/,
    errorMessage: "Please enter a valid integer value.",
  },

  [SyntaxEnum.date]: {
    regex: /\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/,
    errorMessage: "Please enter a valid date (YYY-MM-DD)",
  },
};
