import { Icon, IconButton, Link, Text } from "@fluentui/react";
import { themeUtils } from "cayo.ui";
import { ITheme } from "cayo.ui/lib/esm/themes/theme-types";
import styled from "styled-components";

import { Column, defaultLabelStyle, Row } from "../../CommonLayout";
import { alertDisappearEffect } from "../Alerts.styles";

export const DateText = styled(Text)`
  color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.disabled};
`;

export const SmallLink = styled(Link)`
  font-size: 12px;
`;

export const AlertFooter = styled(Row)`
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 8px;
`;

export const DismissIconButton = styled(IconButton)<{ isHovered: boolean }>`
  margin-right: 4px;
  margin-top: -6px;
  position: absolute;
  right: 0px;
  top: 10px;
  visibility: ${(props) => (props.isHovered ? "visible" : "hidden")};
  z-index: 1;
`;

export const DetailsText = styled(Text)<{ isHovered: boolean }>`
  flex-grow: 1;
  width: 100%;
  padding-right: 20px;
  text-decoration: ${(props) => props.isHovered && "underline"};
  ${{ ...defaultLabelStyle }}
`;

export const StyledColumn = styled(Column)`
  padding-right: 16px;
  padding-left: 6px;
`;

export const SeverityIcon = styled(Icon)`
  margin-top: 4px;
  margin-right: 4px;
`;

export const Wrapper = styled(Row)`
  margin-bottom: 20px;
  margin-top: 6px;
  cursor: pointer;
`;

export const AlertItemContainer = styled.div<{
  disappeared: boolean;
  highlighted?: boolean;
  isFirst?: boolean;
}>`
  overflow: hidden;
  display: flex;
  flex-flow: column;
  max-width: 100%;
  width: 100%;
  padding-right: 4px !important;
  padding-left: 16px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px, rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px;
  border: ${(props) =>
    props.highlighted
      ? `2px solid ${themeUtils.addAlpha(
          (props.theme as ITheme).cayoTheme.brandColors.warning,
          0.5
        )}`
      : "unset"};
  background-color: ${(props) => (props.theme as ITheme).officeTheme.semanticColors.bodyBackground};
  margin: ${(props) => (props.isFirst ? "4px 0px 10px 0px" : "10px 0")};
  transition: width 600ms ease-out, height 600ms ease-out;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.21) 0px 0.3px 0.9px, rgba(0, 0, 0, 0.233) 0px 1.6px 3.6px;
  }

  position: relative;
  right: ${(props) => (props.disappeared ? "-150%" : 0)};
  animation-duration: 0.5s;

  animation-name: ${(props) => (props.disappeared ? alertDisappearEffect : "none")};

  animation-delay: 0s;
  animation-timing-function: cubic-bezier(0.1, 0.3, 0.1, 0.1);
`;
