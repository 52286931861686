import React from "react";
import { singletonHook } from "react-singleton-hook";
import { appSettings } from "../../settings/app-settings";
import UserSettings from "../../settings/user-settings";

const getDefaultUserSettigs = () => {
  let settings = appSettings.userSetting.get();
  if (!settings) {
    settings = {
      lang: "en",
      teachingStep: 0,
    };

    appSettings.userSetting.set(settings);
  }

  return settings;
};

const initialUserSettings = getDefaultUserSettigs();

const _useUserSettings = () => {
  const [userSettings, setUserSettings] = React.useState<UserSettings>(initialUserSettings);

  React.useEffect(() => {
    const onUpdateSettingsHandler = (settings: UserSettings | undefined) =>
      setUserSettings(settings || {});

    return appSettings.userSetting.SaveEvent.on(onUpdateSettingsHandler);
  }, []);

  return {
    userSettings,
    setUserSettings: (settings: UserSettings) => {
      appSettings.userSetting.update(settings);
    },
  };
};

export const useUserSettings = singletonHook(
  { userSettings: initialUserSettings, setUserSettings: () => null },
  _useUserSettings
);
