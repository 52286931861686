import { Link, Stack } from "@fluentui/react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Icon from "../Graphics/Icon";
import { EmptyContainer } from "../Widgets/common";
import dashboardMessages from "./messages";

type EmptyDashboardProps = { onResetView: () => void };

const EmptyDashboard = (props: EmptyDashboardProps) => {
  const { onResetView } = props;
  const intl = useIntl();
  return (
    <EmptyContainer>
      <FormattedMessage id="empty-dashboard.no-widgets" defaultMessage="No widgets selected." />
      <br />
      <br />

      <Stack horizontal={true} verticalAlign="center" tokens={{ childrenGap: 4 }}>
        <Link onClick={onResetView}>{intl.formatMessage(dashboardMessages.resetView)}</Link>
        <div>
          <FormattedMessage id="empty-dashboard.or-click" defaultMessage="or click on the" />
        </div>

        <div style={{ position: "relative" }}>
          <Icon style={{ width: 14 }} iconName="GridViewSmall+EditSolid12" />
        </div>
        <div>
          <FormattedMessage
            id="empty-dashboard.button"
            defaultMessage="button to customize your dashboard."
          />
        </div>
      </Stack>
    </EmptyContainer>
  );
};

export default EmptyDashboard;
