import styled from "styled-components";
import { IRowLayout } from "../../api/schema.api";

const Row = styled.div<Partial<IRowLayout>>`
  display: flex;
  flex-flow: row;
  max-width: ${(props) => (props.stretch ? "100%" : "auto")};
  width: ${(props) => (props.stretch ? "100%" : "auto")};
  align-items: ${(props) => (props.valign ? "center" : "flex-start")};
  align-content: center;
  justify-content: ${(props) => props.justifyContent || "unset"};
  height: ${(props) => (props.stretch ? "100%" : undefined)};
`;

export default Row;
