import {
  IconButton,
  IPanelStyles,
  Panel as OfficePanel,
  PanelType as OfficePanelType,
  Text,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import React from "react";
import { CSSProperties } from "styled-components";
import { IPanel } from "../../api/schema.api";
import logger from "../../libs/logger";
import { FCC } from "../../types/react";
import Column from "../Layout/Column";
import Row from "../Layout/Row";
import GenericScheme from "../Schemes/GenericScheme";

export interface IClosableComponent {
  onClose: (() => void) | undefined;
  onClosed?: () => void;
}

const Panel: FCC<IPanel & IClosableComponent & { style?: CSSProperties }> = (props) => {
  // eslint-disable-next-line no-unused-vars

  const { onClose, style, ...panelProps } = props;
  const panelId = useId("panel");

  let actualType: number = OfficePanelType[props.panelType as keyof typeof OfficePanelType];
  return (
    <OfficePanel
      type={actualType}
      hasCloseButton={false}
      isOpen={true}
      styles={styles(props?.style?.overflowX === "hidden")}
      isBlocking={props.isBlocking}
      onDismiss={(e) => {
        if ((e as unknown as KeyboardEvent)?.code === "Escape") {
          onClose && onClose();
        }
        logger.debug(e);
      }}
      onRenderNavigation={() => {
        return (
          <Column style={{ padding: "12px 10px 0px 16px" }}>
            <Row
              justifyContent={props.title ? "space-between" : "flex-end"}
              valign={true}
              style={{ padding: "2px 0px 0px 0px" }}
            >
              <Text variant="xLarge" style={{ fontWeight: "bold" }}>
                {props.title}
              </Text>
              <IconButton
                autoFocus={true}
                style={{}}
                iconProps={{ iconName: "cancel" }}
                onClick={onClose}
              />
            </Row>
            {props.header && <GenericScheme {...props.header} key={panelId + "header"} />}
          </Column>
        );
      }}
      style={style || {}}
    >
      <div style={{ paddingTop: 6 }}>
        {panelProps.items?.length ? (
          <GenericScheme {...panelProps} key={panelId + "content"} />
        ) : (
          props.children
        )}
      </div>
    </OfficePanel>
  );
};

const styles = (hideHorizontalScroll?: boolean): Partial<IPanelStyles> => {
  return {
    content: {
      paddingLeft: 20,
      paddingRight: 20,
    },

    scrollableContent: [hideHorizontalScroll && { overflowX: "hidden" }],
  };
};
export default Panel;
