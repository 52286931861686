import {
  IBreadcrumbItem,
  INavLink,
  INavLinkGroup,
  ResponsiveMode,
  useResponsiveMode,
} from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { singletonHook } from "react-singleton-hook";
import { INavLink as IAdminNavLink } from "../../api/schema.api";
import { appEvents } from "../../components/App/app-events";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import { endpoints } from "../../services/endpoints.service";
import useService from "../../services/services.hook";
import customUrlUtils from "../../utils/custom-url-utils";
import { showLazyProps } from "../../utils/lazy-props";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
// import useService from "../services.hook";
import { NavController, toNodeId } from "./nav-controller";

const log = logger.getLogger("nav-hooks");

const ctrl = new NavController(endpoints.isAdminMode);

export const isMedium = (mode: ResponsiveMode): boolean => {
  return (mode as number) <= ResponsiveMode.medium;
};

function handleSearchProps(location: { pathname: string; search: string }, nodePath: string) {
  if (location.search.toLowerCase().indexOf("?props=") !== -1) {
    let actionUrl = customUrlUtils.getActionUrl(location.pathname + location.search);
    if (location.search.toLowerCase().indexOf("&action=") !== -1) {
      const actionPath = location.search.split("&action=")[1];
      actionUrl += `/${actionPath}`;

      appEvents.trigger({
        action: {
          schemeId: actionUrl!,
          gridId: "dummy",
        },
      });
      return;
    }

    if (nodePath === "/") {
      appEvents.trigger({
        showControl: { controlId: "scheme", parameters: actionUrl },
      });
    } else {
      showLazyProps(nodePath, actionUrl!);
    }
  }
}

export function useNav(rootLinks: IAdminNavLink[]) {
  const location = useLocation();
  const history = useHistory();
  const { collapsed, toggleCollapsed } = useToggleNavCollapsed();
  const navTreeClient = useService("navTreeClient");

  const initialized = useRef(false);
  const stubRef = useRef(null);
  const mediumMode = isMedium(useResponsiveMode(stubRef));

  const [groups, setGroups] = useState<INavLinkGroup[]>([]);
  const { getTypeAnnotation } = useTypeAnnotations();
  const getIcon = (oType: string) =>
    getTypeAnnotation(oType)?.icon?.iconId ?? "CheckboxIndeterminate";

  const { fetchFn } = ajax;
  const [selectedKey, setSelectedKey] = useState("");

  useEffect(() => {
    log.debug("useNav.OnMount -> rootLinks, location, mediumMode", rootLinks, location, mediumMode);

    // set collapsed by responsiveMode on initial
    if ((mediumMode && !collapsed) || (!mediumMode && collapsed)) {
      toggleCollapsed();
    }

    //   .then((nodes) => {
    //     log.debug("useNav -> loaded root nodes", nodes);
    //     return ctrl.setRootNodes(nodes);
    //   })
    navTreeClient
      .getNavTree()
      .then((groups) => {
        // init node tree
        ctrl.setRootNodes(groups);
        return ctrl.loadRootSubnodes(fetchFn, getIcon);
      })
      .then(() => {
        return ctrl.handleLocation(location, fetchFn, getIcon);
      })
      .then((result) => {
        log.debug("handleLocation -> result", result);
        setGroups(ctrl.getNavGroups());
        setSelectedKey(result.selectedKey);
        if (result.location !== location) {
          history.push(result.location.pathname);
        }
        initialized.current = true;
        handleSearchProps(location, result.location.pathname);
      });
  }, [mediumMode]);

  useEffect(() => {
    if (!initialized.current) {
      return;
    }

    ctrl.handleLocation(location, fetchFn, getIcon).then((result) => {
      if (result.location.pathname !== location.pathname) {
        history.push(result.location.pathname);
      } else {
        setSelectedKey(result.selectedKey);
        if (result.needRerender) {
          setGroups(ctrl.getNavGroups());
        }
      }
      handleSearchProps(location, result.location.pathname);
    });
  }, [location.pathname]);

  return {
    groups,
    selectedKey,
    isMedium: mediumMode,
    setSelectedKey,
    onLinkExpandHandler: async (ev?: React.MouseEvent<HTMLElement>, link?: INavLink) => {
      log.debug("onLinkExpandHandler -> link", link);
      if (!link) {
        return;
      }
      const targetNode = ctrl.getById(link.key!);
      if (!targetNode) {
        return;
      }
      targetNode.expanded = !link.isExpanded;
      const needUpdate = await ctrl.loadSubNodes(targetNode!, fetchFn, getIcon);
      if (needUpdate) {
        setGroups(ctrl.getNavGroups());
      }
    },
    resolveName: ctrl.getFullName,
  };
}

function loadBreadcrumbs(path: string, rootName?: string): IBreadcrumbItem[] {
  log.debug("loadBreadcrumbs -> path, productName", path, rootName);
  return ctrl.makeBreadcrumbs(path, rootName);
}

export function useBreadcumbs(rootName?: string): IBreadcrumbItem[] {
  const [bredCrumbItems, setBredcrumbItems] = useState<IBreadcrumbItem[]>([]);

  const evtHandler = useRef<EventListenerObject>({
    handleEvent: (evt: CustomEvent<string>) => {
      log.debug("useBreadcumbs.handleEvent", evt.detail);

      const breadCrumbs = loadBreadcrumbs(evt.detail, rootName);
      setBredcrumbItems(breadCrumbs);
    },
  }).current;

  useEffect(() => {
    ctrl.addEventListener(NavController.locationChangedEvent, evtHandler);
    return () => ctrl.removeEventListener(NavController.locationChangedEvent, evtHandler);
  }, []);
  return bredCrumbItems;
}

export function resolveNodePath(pathname: string) {
  return ctrl.getById(toNodeId(pathname))?.path ?? "";
}

export function useNavSelectedSchemeId(): string {
  const { pathname } = useLocation();
  const [schemeId, setSchemeId] = useState("");
  const evtHandler = useRef<EventListenerObject>({
    handleEvent: (evt: CustomEvent<string>) => {
      const path = resolveNodePath(evt.detail);
      setSchemeId(path);
    },
  }).current;

  useEffect(() => {
    setSchemeId(resolveNodePath(pathname));
    ctrl.addEventListener(NavController.locationChangedEvent, evtHandler);
    return () => ctrl.removeEventListener(NavController.locationChangedEvent, evtHandler);
  }, []);

  return schemeId;
}

export const useToggleNavCollapsed = singletonHook(
  { collapsed: false, toggleCollapsed: () => {} },
  () => {
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
      setCollapsed((v) => {
        const newV = !v;
        return newV;
      });
    };
    return { collapsed, toggleCollapsed };
  }
);
