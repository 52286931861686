import { useCallback, useRef } from "react";

const usePreventRequestDuplication = () => {
  let requests = useRef<Date[]>([]);

  const addRequest = useCallback(() => {
    const requestTime = new Date();
    requests.current.push(requestTime);

    return requestTime;
  }, []);

  const removeRequest = useCallback((requestTime: Date) => {
    requests.current = requests.current.filter((r) => r !== requestTime);
  }, []);

  const isLastRequest = useCallback((requestTime: Date) => {
    const result = requests.current.length === 1 && requests.current[0] === requestTime;

    return result;
  }, []);

  return { addRequest, removeRequest, isLastRequest };
};

export default usePreventRequestDuplication;
