import React from "react";
import { IntlProvider } from "react-intl";
import { defaultLanguage, languages, localeStore } from "../../i18n/locale-store";
import { FCC } from "../../types/react";

// import { defaultLanguage, languages, localeStore } from "src/i18n/locale-store";

interface IMessageProviderProps {
  lang: string;
}

const MessageProvider: FCC<IMessageProviderProps> = ({ lang, children }) => {
  let locale = lang;

  if (!languages[locale]) {
    locale = defaultLanguage;
  }

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={localeStore.getMessages()}
      textComponent={React.Fragment}
    >
      {children}
    </IntlProvider>
  );
};

export default MessageProvider;
