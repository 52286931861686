import { ITextField } from "../../../api/schema.api";

const fieldTypes = {
  string: "Edm.String",
  boolean: "Edm.Boolean",
  enum: "Edm.Enum",
  enumCollection: "Collection(Edm.Enum)",
  dateTime: "Edm.DateTime",
  dateTimeOffset: "Edm.DateTimeOffset",
  int32: "Edm.Int32",
  softLink: "cayo.graph.softLink",
  timelimit: "timelimit",
};

const _odataNumberToHtml = (type?: string) => {
  switch (type) {
    case fieldTypes.int32:
      return "number";

    default:
      return "text";
  }
};

export const odataTypeToHtml = (annotation: ITextField) => {
  let type = annotation.isConfidential ? "password" : _odataNumberToHtml(annotation.type);
  return type;
};

export default fieldTypes;
