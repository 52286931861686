import { defineMessages } from "react-intl";

const messages = defineMessages({
  description: {
    defaultMessage:
      "To start using the product, add your Azure AD tenant(s) and Active Directory domain(s).",
    id: "initial-configuration-wizard.description",
  },
  addDomain: {
    defaultMessage: "Add domain\n(optional step)",
    id: "initial-configuration-wizard.add-domain",
  },
  addTenant: {
    defaultMessage: "Add tenant\n(optional step)",
    id: "initial-configuration-wizard.add-tenant",
  },
  activateProduct: {
    defaultMessage: "Activate product",
    id: "initial-configuration-wizard.activate",
  },
  goToWebPortal: {
    defaultMessage: "Go to web portal",
    id: "initial-configuration-wizard.goto-portal",
  },
  configureSQL: {
    defaultMessage: "Configure database",
    id: "initial-configuration-wizard.configure-sql",
  },
  stepCount: {
    defaultMessage: "Step {index}",
    id: "initial-configuration-wizard.step-count",
  },
});

export default messages;
