import { stringUtils } from "cayo.ui";
import * as Msal from "msal";
import { AuthOptions } from "msal/lib-commonjs/Configuration";
import redirectUtils from "../components/routing/redirect-utils";
import logger from "../libs/logger";
import { endpoints } from "../services/endpoints.service";
import { appSettings } from "../settings/app-settings";

export type AuthMode = "bearer" | "office365" | undefined;

const log = logger.getLogger("SSO");

class SSO {
  private static instance: SSO;
  private readonly app: Msal.UserAgentApplication;

  public static scopes = ["openid", "profile", "adminapi"];
  private log = new Msal.Logger(logger.getLogger("MSAL").debug);

  private constructor() {
    const authorityEndpoint = stringUtils.trim(endpoints.apiEndpoint + endpoints.PUBLIC_URL);
    const auth: AuthOptions = {
      clientId: "csm.client.web",
      validateAuthority: false,
      navigateToLoginRequestUrl: false,
      authority: authorityEndpoint,
    };

    const additionalOptions = {
      scopes: SSO.scopes,
      responseType: "id_token",
    };

    const msalConfig: Msal.Configuration = {
      auth: { ...auth, ...additionalOptions },
      system: { logger: this.log },
    };

    log.debug("msalConfig", msalConfig);

    this.app = new Msal.UserAgentApplication(msalConfig);

    this.app.handleRedirectCallback((error, response) => {
      // handle redirect response or error
      if (error) {
        log.debug("handleRedirectCallback, error", error);
      } else {
        log.debug("handleRedirectCallback, response ", response);
        try {
          const loginHint =
            response!["account"]["idToken"][
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
            ];
          log.debug("handleRedirectCallback, loginHint found", loginHint);
          appSettings.userSetting.update({ loginHint });
        } catch (e) {
          log.error("handleRedirectCallback, failed to get user name, error", e);
        }
      }
    });
  }

  public static get Instance(): SSO {
    if (!SSO.instance) {
      SSO.instance = new SSO();
    }

    return SSO.instance;
  }

  public get authMode() {
    return appSettings.userSetting.get()?.authMode;
  }

  public get application() {
    return this.app;
  }

  public setAuthMode(authMode: AuthMode) {
    appSettings.userSetting.update({ authMode });
  }

  public static useOffice365Auth() {
    appSettings.userSetting.update({ authMode: "office365" });

    const redirectUrl = redirectUtils.getRedirectUrl();

    log.debug("useOffice365Auth, redirectUrl", redirectUrl);

    let hashRoute = "#/";
    if (redirectUrl && redirectUrl !== "/") {
      hashRoute += redirectUrl;
    }

    window.location.assign(hashRoute);
  }
}

export default SSO;
