import { CustomInterval, LastDaysInterval, QueryInterval } from "../../api/cayo-graph";

const fromPredefinedRange = (predefinedRange: string): QueryInterval | undefined => {
  let objectType = undefined;
  let number: number | undefined = undefined;

  switch (predefinedRange) {
    case "60":
      objectType = "cayo.graph.lastHourInterval";
      break;

    case "1440":
      objectType = "cayo.graph.lastDaysInterval"; // day
      number = 1;
      break;

    case "10080":
      objectType = "cayo.graph.lastDaysInterval"; // week
      number = 7;
      break;

    case "43200":
      objectType = "cayo.graph.lastDaysInterval"; // month
      number = 30;
      break;

    case "129600":
      objectType = "cayo.graph.lastDaysInterval"; // 90 days
      number = 90;
      break;

    case "259200":
      objectType = "cayo.graph.lastDaysInterval"; // 180 days
      number = 180;
      break;
  }

  return objectType ? ({ "@odata.type": objectType, objectType, number } as any) : undefined;
};

const fromPredefinedType = (interval?: CustomInterval): string | undefined => {
  switch (interval?.objectType) {
    case "cayo.graph.lastHourInterval":
      return "60";

    // case "cayo.graph.lastDayInterval":
    //   return "1440";

    // case "cayo.graph.lastWeekInterval":
    //   return "10080";

    // case "cayo.graph.lastMonthInterval":
    //   return "43200";

    case "cayo.graph.lastDaysInterval":
      return ((interval as LastDaysInterval)?.number! * 60 * 24).toString();

    default:
      break;
  }

  return undefined;
};

const rangeToType = (r: number) => {
  let objectType = "";
  let number: number | undefined = undefined;
  switch (r) {
    case 0:
      objectType = "cayo.graph.lastHourInterval";
      break;
    case 1:
      objectType = "cayo.graph.lastDaysInterval";
      number = 1;
      break;
    case 7:
      objectType = "cayo.graph.lastDaysInterval";
      number = 7;
      break;
    case 30:
      objectType = "cayo.graph.lastDaysInterval";
      number = 30;
      break;
    case 90:
      objectType = "cayo.graph.lastDaysInterval";
      number = 90;
      break;
    case 180:
      objectType = "cayo.graph.lastDaysInterval";
      number = 180;
      break;
    default:
      throw new Error("Invalid range");
  }

  return {
    "@odata.type": objectType,
    objectType,
    number,
  } as any;
};

const fromRange = (
  startDateTime?: Date | undefined,
  endDateTime?: Date | undefined
): CustomInterval | undefined => {
  if (!startDateTime || !endDateTime) {
    return undefined;
  }

  return {
    "@odata.type": "#cayo.graph.customInterval",
    startDateTime: startDateTime.toISOString(),
    endDateTime: endDateTime.toISOString(),
    objectType: "cayo.graph.customInterval",
  } as any;
};

export const dateRangeConverters = {
  fromPredefinedRange,
  fromPredefinedType,
  fromRange,
  rangeToType,
};
