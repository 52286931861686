import { ITheme, builtinFormatters } from "cayo.ui";
import React from "react";
import { IntlShape } from "react-intl";
import { ExecutionRecord, JobRecord } from "../../api/cayo-graph";
import { buildInIconFormatters } from "../../scheme/formatters/builtin-icon-formatters";

import { IAppTheme } from "../../themes";
import Icon from "../Graphics/Icon";
import { AuxRenderProps } from "../LegacyDetailsList/cell-renderers";
import { ActionContainer, StyledWorkflowTitle } from "./tree.styles";

const jobIcon = (item: ExecutionRecord, theme: ITheme) => {
  const value = item["executionState"] as string;

  const icon = buildInIconFormatters.jobstate(theme as IAppTheme, {
    executionState: item.executionState || "none",
    lastExecutionResult: item.executionResult || "none",
  });

  return (
    <Icon
      rotate={value === "running" ? "1" : ""}
      iconName={icon?.iconName || "UnknownSolid"}
      style={{ fontSize: "14px", color: icon?.color || theme.cayoTheme.brandColors.disabled }}
    />
  );
};

const workFlowItem = (item: any, index: number, isSelected: boolean, auxProps?: AuxRenderProps) => {
  const jobRecord = item as JobRecord;
  const title = jobRecord.name || jobRecord.objectName;
  return (
    <div
      key={item.id}
      style={{
        alignItems: "center",
        display: "flex",
        maxWidth: "100%",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        height: "100%",
        width: "100%",
      }}
    >
      {treeRendereres.jobIcon(jobRecord, auxProps!.theme)}
      <ActionContainer
        horizontalAlign={"stretch"}
        verticalAlign="center"
        wrap={false}
        horizontal
        style={{ flexGrow: 1 }}
      >
        <StyledWorkflowTitle isSelected={isSelected} title={title}>
          {title}
        </StyledWorkflowTitle>
        {duration(item?.duration, auxProps!.intl)}
      </ActionContainer>
    </div>
  );
};

function duration(duration?: string, intl?: IntlShape) {
  return (
    <span style={{ whiteSpace: "nowrap", paddingLeft: 2, paddingRight: 4, fontSize: 12 }}>
      {builtinFormatters.duration(duration, intl)}
    </span>
  );
}
const treeRendereres = {
  workFlowItem,
  jobIcon,
  duration,
};

export default treeRendereres;
