import React, { Fragment } from "react";
import { objectUtils } from "../../../utils/object-utils";
import Column from "../../Layout/Column";
import LinkedObject from "../components/LinkedObject";
import GridCell from "./GridCell";
import { isObjectLink, renderClassNames, renderObjectProp } from "./common";
import { RenderCellFn } from "./types";

export const renderArrayCell: RenderCellFn = ({ item, column, auxProps, onItemClicked }) => {
  const value = item[column.fieldName!];
  if (!Array.isArray(value)) {
    return null;
  }

  if (value.length === 0) {
    return <></>;
  }

  const isSingleValue = value.length === 1;
  const firstObject = value[0];
  if (isObjectLink(firstObject)) {
    return (
      <GridCell renderer="array-link" multiline toCopyValue={() => arrayToCopyString(value)}>
        <Column key={item.id} style={{ height: "100%" }}>
          {value.map((v, i) => (
            <LinkedObject
              key={i}
              {...v}
              onItemClicked={onItemClicked}
              getIconAnnotation={auxProps?.getIconAnnotation}
            />
          ))}
        </Column>
      </GridCell>
    );
  } else if (value.length > 0 && typeof firstObject === "object") {
    return (
      <GridCell
        renderer="array-object"
        multiline
        toCopyValue={() => arrayToCopyString(value, !!item.expandMV)}
      >
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
            whiteSpace: "nowrap",
          }}
        >
          {value.map((v, i) =>
            renderObjectProp(
              i.toString(),
              v,
              isSingleValue ? undefined : "#" + (i + 1) + " ",
              onItemClicked,
              auxProps
            )
          )}
        </div>
      </GridCell>
    );
  } else {
    if (item.expandMV) {
      return (
        <GridCell renderer="array-primitive" multiline toCopyValue={() => value.join("\n")}>
          {value.map((v, i) => (
            <Fragment key={i}>
              {v}
              <br />
            </Fragment>
          ))}
        </GridCell>
      );
    }
    return (
      <GridCell renderer="array-primitive">
        <span className={renderClassNames.toCopyValueContainer} data-to-copy>
          {value.join("; ")}
        </span>
      </GridCell>
    );
  }
};

export const arrayToCopyString = (
  value: Array<any>,
  expandMV: boolean = false,
  indent: number = 0
) => {
  if (value.length === 0) {
    return "";
  }
  const firstItem = value[0];
  if (isObjectLink(firstItem)) {
    return value.map((v, i) => `${i === 0 ? "" : " ".repeat(indent)}${v.objectName}`).join("\n");
  }
  if (objectUtils.isObject(firstItem)) {
    return value.map((v, i) => objToCopy(v, `#${i + 1}`, indent)).join("\n");
  }

  return value.join(expandMV ? "\n" : "; ");
};

function objToCopy(value: object, title: string = "", indent: number = 0): string {
  return Object.entries(value)
    .map(([key, value], i) => {
      const prefix = `${i === 0 ? "" : " ".repeat(indent)}${
        i === 0 ? title : " ".repeat(title.length)
      } ${key}: `;
      return `${prefix}${objValueToCopy(value, prefix.length)}`;
    })
    .join("\n");
}

function objValueToCopy(value: any, indent: number = 0) {
  switch (true) {
    case value === null || value === undefined:
      return `${value}`;
    case objectUtils.isObject(value) && isObjectLink(value):
      return `${value.objectName}`;
    case objectUtils.isObject(value):
      return objToCopy(value, "", indent);
    case objectUtils.isArray(value):
      return arrayToCopyString(value, false, indent);
    default:
      return `${value}`;
  }
}
