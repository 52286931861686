import { Alignment, Stack, Text } from "@fluentui/react";
import { stringUtils, useChartColors, useTheme } from "cayo.ui";
import React, { CSSProperties, FC, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { ICounter } from "../../api/schema.api";
import { nameofFactory } from "../../utils/object-utils";

const counterNames = nameofFactory<ICounter>();

const Counter: FC<
  Omit<ICounter, "type"> & { horizontalAlign?: Alignment; style?: CSSProperties }
> = ({ label, labelColor, dataColor, linearIndicator, data, horizontalAlign, style }) => {
  const { cayoTheme, officeTheme } = useTheme();
  const { brandColors } = cayoTheme;
  const { semanticColors } = officeTheme;

  const realColors = useChartColors(
    [counterNames("label"), counterNames("dataColor"), counterNames("linearIndicator")],
    undefined,
    [
      labelColor || brandColors.disabled,
      dataColor || semanticColors.bodyText,
      linearIndicator?.color || brandColors.disabled,
    ]
  );

  //const isIndicatorHorizontal = linearIndicator && linearIndicator.horizontal;
  const isIndicatorVertical = !linearIndicator?.horizontal;

  return (
    <Stack
      horizontal={true}
      horizontalAlign={horizontalAlign ?? "start"}
      verticalFill={true}
      verticalAlign="center"
      style={{ minWidth: 80 }}
    >
      {typeof data !== "number" ? (
        <FormattedMessage id="counters-chart.no-statistics2" defaultMessage="?" />
      ) : (
        <Fragment>
          <Stack
            horizontal={false}
            horizontalAlign="start"
            verticalFill={false}
            verticalAlign="center"
            style={style}
          >
            <Stack horizontal={true} verticalAlign="center">
              {linearIndicator && isIndicatorVertical && (
                <VerticalIndicator color={realColors.linearIndicator} />
              )}
              <Stack horizontal={false} horizontalAlign={horizontalAlign ?? "start"}>
                {label && (
                  <Text
                    block={true}
                    nowrap={true}
                    variant={"small"}
                    styles={{ root: { fontWeight: 500, color: realColors.labelColor } }}
                  >
                    {stringUtils.toSpaceDelimitedPascalCase(label)}
                  </Text>
                )}
                <Text
                  variant={data === 0 ? "xxLarge" : "xxLargePlus"}
                  block={true}
                  nowrap={true}
                  styles={{
                    root: {
                      color: realColors.dataColor,
                      fontWeight: `${data === 0 ? "normal" : 600} !important`,
                    },
                  }}
                >
                  {typeof data === "number" && data?.toString()}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Fragment>
      )}
    </Stack>
  );
};

const VerticalIndicator = styled.div<{ color: string }>`
  width: 4px;
  min-height: 40px;
  height: 50px;
  background: ${(props) => props.color};
  margin-right: 8px;
  border-radius: 1px;
`;

export default Counter;
