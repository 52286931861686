import { stringUtils } from "cayo.ui";
import { IPropertiesPanel } from "./types";

const generateKey = (p: IPropertiesPanel) => {
  var id = p.key || p.id;
  if (p.isPicker) {
    id += "_picker";
  }
  return "scheme" + id!.toLowerCase();
};

const getShemePath = (p: IPropertiesPanel) => {
  if (p.property) {
    if (!p.object) {
      throw new Error("Invalid scheme");
    }

    const type = stringUtils.trim(p.object["@odata.type"], "#");
    return `form/type/${type ?? p.property.type}`;
  }

  if (p.isPicker) {
    return `picker/${p.id}`;
  }

  return (p.isNewObject ? "form/new" : "props") + `/${p.id}`;
};

const propertiesPanelUtils = {
  generateKey,
  getShemePath,
};

export default propertiesPanelUtils;
