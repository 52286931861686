import {
  ActionButton,
  IDropdownOption,
  Dropdown as OfficeDropdown,
  Spinner,
} from "@fluentui/react";
import { PieChart, domUtils } from "cayo.ui";
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { LongCounterValue, ProtectedItemsCounter } from "../../api/cayo-graph";
import { IProtectedObjects } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import { INotificationMessage } from "../../services/notification.service";
import useService from "../../services/services.hook";
import { IComponentViewStorage } from "../../settings/view-storage-factory";
import { FCC } from "../../types/react";
import injectViewStorage from "../Hoc/inject-view-storage";
import Column from "../Layout/Column";
import Row from "../Layout/Row";
import { commonMessages } from "../common-messages";
import WidgetContainer from "./Container";
import WidgetHeader from "./Header";
import { EmptyContainer } from "./common";
import useWidgetSignals from "./dataset-signals.hook";

interface IProtectedObjectSettings {
  selectedSystem?: string;
}

const ProtectedObjects: FCC<
  IProtectedObjects & {
    viewStorage: IComponentViewStorage<IProtectedObjectSettings>;
  }
> = (props) => {
  const intl = useIntl();

  const allSystems = intl.formatMessage(messages.allSystems);

  const { viewStorage, title } = props;
  const [updateTime, setUpdateTime] = useState(new Date());
  const { data, isLoading, reloadData } = ajax.useGet(props.url, {
    ...props,
    updateTime,
  });
  const [systemOptions, setSystemOptions] = useState<IDropdownOption[] | undefined>(undefined);
  const notificationService = useService("notificationService");

  useWidgetSignals({
    signal: "refresh",
    handler: reloadData,
  });

  useEffect(() => {
    const counter = data as ProtectedItemsCounter;
    let systems = counter?.objectsPerSystem?.map<IDropdownOption>((o) => ({
      key: o.systemName!,
      text: o.systemName!,
      title: o.systemName!,
    }));

    if (systems) {
      systems = [
        {
          key: allSystems,
          text: allSystems,
          title: allSystems,
        },
        ...systems,
      ];

      const settings = viewStorage.get();
      const selectedSystem = systems?.find((s) => s.key === settings?.selectedSystem);
      if (selectedSystem) {
        selectedSystem.selected = true;
      } else {
        systems[0].selected = true;
      }
    }

    setSystemOptions(systems);
  }, [data]);

  const handleChange = useCallback(
    (e: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option || !systemOptions) {
        return;
      }

      systemOptions.forEach((o) => (o.selected = undefined));
      option.selected = true;

      setSystemOptions([...systemOptions]);

      viewStorage.set({ selectedSystem: option!.key as string });
    },
    [systemOptions]
  );

  useEffect(() => {
    const onMessages = (msgs: INotificationMessage[]) => {
      if (msgs.length) {
        setUpdateTime(new Date());
      }
    };

    return notificationService.subscribe("statisticsCounter", onMessages);
  }, [updateTime]);

  const selectedSystem = systemOptions?.find((o) => o.selected)?.key || allSystems;

  const chartData = useMemo<LongCounterValue[] | undefined>(() => {
    const counter = data as ProtectedItemsCounter;
    if (!counter) {
      return undefined;
    }
    if (selectedSystem === allSystems) {
      return counter.totals?.length ? counter.totals : undefined;
    } else {
      return (
        counter.objectsPerSystem &&
        counter.objectsPerSystem?.find((s) => s.systemName === selectedSystem)?.objects
      );
    }
  }, [systemOptions, data]);

  return (
    <WidgetContainer stick={props.stick}>
      <WidgetHeader title={title!} updateTime={updateTime} onRefresh={reloadData} />

      {!chartData && (
        <Column style={{ flexGrow: 1, height: "100%" }}>
          <EmptyContainer
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: "0 24px",
            }}
          >
            {isLoading ? (
              <Spinner label={intl.formatMessage(commonMessages.loading)} labelPosition="right" />
            ) : (
              <FormattedMessage
                id="counters-chart.no-statistics"
                defaultMessage="To see a diagram with protected objects add your domains or tenants first."
              />
            )}
          </EmptyContainer>
        </Column>
      )}
      {chartData && <PieChart {...props.chart} data={chartData} />}

      <Row
        valign={false}
        justifyContent="space-between"
        style={{ alignItems: "center", marginTop: 8, marginBottom: 2, justifyContent: "start" }}
        onMouseDown={domUtils.stopMouseDown}
      >
        <OfficeDropdown
          onChange={handleChange}
          options={systemOptions || []}
          selectedKey={selectedSystem}
          disabled={!chartData}
          style={{ visibility: chartData ? "visible" : "hidden" }}
          styles={{
            dropdown: { minWidth: "240px", whiteSpace: "nowrap" },
            dropdownItem: { whiteSpace: "nowrap" },
            label: { paddingRight: 4 },

            root: {
              minHeight: 28,
              alignSelf: "flex-end",
              display: systemOptions && systemOptions.length ? "flex" : "none",

              right: "20px",
              top: 0,
              marginRight: 4,
            },
          }}
        />

        <ActionButton
          iconProps={{ iconName: "Refresh" }}
          title={"Refresh"}
          style={{ height: 28, fontSize: 13, fontWeight: 400, visibility: "hidden" }}
          onClick={reloadData}
        >
          <FormattedMessage id="counters-chart.buttons.refresh" defaultMessage="Refresh" />
        </ActionButton>
      </Row>
    </WidgetContainer>
  );
};

const messages = defineMessages({
  allSystems: {
    defaultMessage: "All systems",
    id: "counters-chart.all-systems",
  },
});

export default injectViewStorage(ProtectedObjects);
