import { debounce } from "cayo.ui";
import { NotificationMode } from "../../../api/schema.api";
import logger from "../../../libs/logger";
import notificationService, {
  INotificationMessage,
  NotificationAction,
} from "../../../services/notification.service";

import { objectUtils } from "../../../utils/object-utils";
import { IInteractiveItem } from "../../Alerts/alerts.api";
import { CreateNotificationFn, GridController } from "../grid-model/GridController";

export abstract class GridNotification {
  protected constructor(protected controller: GridController | null) {}

  dispose() {
    this.controller = null;
  }

  public static createFn(
    notificationMode: NotificationMode,
    notificationSource?: string
  ): CreateNotificationFn {
    switch (true) {
      case notificationMode === "polling":
        return (ctrl) => new PollingNotification(ctrl);
      case !!notificationSource && notificationMode === "off":
        return (ctrl) => new ManualNotification(ctrl, notificationSource!);
      case !!notificationSource && notificationMode === "websockets":
        return (ctrl) => new WebSocketNotification(ctrl, notificationSource!);
      default:
        return (ctrl) => new StubNotification(ctrl);
    }
  }
}

class StubNotification extends GridNotification {
  dispose(): void {
    super.dispose();
  }
}

//
class ManualNotification extends GridNotification {
  private readonly _logger = logger.getLogger("QUICK-FILTER-NEW OffNotification");
  private log(...msg: any[]) {
    this._logger.log(...msg);
  }
  private unsubscribe: () => void = () => {};

  constructor(controller: GridController, notificationSource: string) {
    super(controller);
    this.unsubscribe = notificationService.subscribe(notificationSource, (messages) => {
      this.log("notify messages -> ", messages);
      this.controller!.dispatch({ kind: "hasChanges" });
    });
  }

  dispose(): void {
    this.unsubscribe();
    super.dispose();
  }
}

class WebSocketNotification extends GridNotification {
  private readonly _logger = logger.getLogger("QUICK-FILTER-NEW WebSocketNotification");
  private unsubscribe: () => void = () => {};
  private log(...msg: any[]) {
    this._logger.log(...msg);
  }

  constructor(controller: GridController, notificationSource: string) {
    super(controller);

    this.log(".ctor -> notificationSource", notificationSource);
    if (!notificationSource) {
      return;
    }

    this.unsubscribe = notificationService.subscribe(notificationSource, (messages) => {
      this.log("notify messages -> ", messages);
      const dispatchRefresh = debounce(
        () => this.controller!.dispatch({ kind: "refresh", hideLoading: true }),
        3000
      );

      this.onDetailsListMessages(
        messages,
        () => {
          this.log("refresh all");
          dispatchRefresh();
        },
        (items) => {
          this.log("mergeItems -> items", items);
          this.controller!.dispatch({ kind: "mergeItems", items });
        }
      );
    });
  }

  onDetailsListMessages = (
    messages: INotificationMessage[],
    refreshAllHandler: () => void,
    updateItems: (items: IInteractiveItem[]) => void
  ) => {
    try {
      const msgGroups = objectUtils.groupBy(messages, (j) => j.action);
      const newOrDeletedItems: IInteractiveItem[] = [];
      const changedItems: IInteractiveItem[] = [];
      for (const group of msgGroups) {
        for (const message of group[1]) {
          const item = JSON.parse(message.body) as IInteractiveItem;

          switch (message.action) {
            case NotificationAction.Add:
            case NotificationAction.Remove:
              newOrDeletedItems.push(item);
              break;

            case NotificationAction.Modify:
              changedItems.push(item);
              break;
            default:
              break;
          }
        }
      }

      if (newOrDeletedItems.length) {
        refreshAllHandler();
      } else if (changedItems.length) {
        updateItems(changedItems);
      }
    } catch (e) {
      logger.log(e);
    }
  };
  dispose() {
    this.unsubscribe();
    super.dispose();
  }
}

class PollingNotification extends GridNotification {
  private readonly _logger = logger.getLogger("QUICK-FILTER-NEW PollingNotification");
  private _interval: NodeJS.Timer;
  private log(...msg: any[]) {
    this._logger.log(...msg);
  }
  constructor(controller: GridController) {
    super(controller);
    this.log(".ctor");
    this._interval = setInterval(() => {
      if (this.controller!.requrestExecuting === false) {
        this.controller!.dispatch({ kind: "refresh", hideLoading: true, keepSelection: true });
      }
    }, 3000);
  }
  dispose() {
    clearInterval(this._interval);
    super.dispose();
  }
}
