import {
  ActionButton,
  DefaultButton,
  IColumn,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import { LoadingOverlay, TextField, useComponentLogger, useTheme } from "cayo.ui";
import React, { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PropertyAnnotation, SavedQuery } from "../../api/cayo-graph";
import { getError, oClient } from "../../libs/odata.client";
import { endpoints } from "../../services/endpoints.service";
import { filterSignalsService } from "../../services/filter-signal.service";
import AppContext, { IClientComponentParameters } from "../App/app.context";
import { Column, Row } from "../CommonLayout";
//import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
import { ItemAnnotation, withEmptyConditions } from "../GridContainer/GridFilterBar.utils";
import { fromSavedQuery, toSavedQuery } from "../GridContainer/grid-model/savedQuery.portal";
import ObjectIcon from "../ObjectIcon";
// import { IPickerItem } from "../ObjectPicker/IPickerItem";
// import { StringsToEntities } from "../ObjectPicker/logic";
import Panel from "../Panel/Panel";
import { IClosableComponent } from "../Surfaces/Panel";
import { commonMessages } from "../common-messages";
import { IQueryFilterParameters } from "./IQueryFilterParameters";
import { queryFilterFormHooks, savedQueryModelReducer } from "./hooks";
import { makeErrorMessage, savedQueries } from "./logic";
import messages from "./messages";
// import renderComponent from "./renderers";
import styled from "styled-components";
import { IJsonForm } from "../../api/schema.api";
import { appEvents } from "../App/app-events";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
import cellRenderers, { AuxRenderProps } from "../LegacyDetailsList/cell-renderers";

const messageStyles = (bgColor: string) => ({
  icon: { color: "white" },
  text: { fontWeight: "bold" },
  root: {
    background: bgColor,
    color: "white",
    padding: "15px",
  },
});

type QueryFilterFormProps = Omit<IJsonForm, "type"> &
  IClientComponentParameters &
  IQueryFilterParameters & {
    propertyAnnotations?: PropertyAnnotation[];
    readonly?: boolean;
    selectedSavedQuery: any;
    setSelectedSavedQuery: (param: SavedQuery | undefined) => void;
    url?: string | undefined;
    gridId?: string | undefined;
  };

const QueryFilterForm: FC<QueryFilterFormProps> = (props) => {
  const {
    onClose,
    items,
    url,
    filter,
    gridId,
    saveOnServerOnly,
    onFilterSaved,
    deleteFilter,
    targetType,
    // propertyAnnotations,
    readonly,
    selectedSavedQuery,
    setSelectedSavedQuery,
  } = props;
  const addProps: IClosableComponent = { onClose };
  //const typeAnnotations = useTypeAnnotations();
  const theme = useTheme();
  const { metrics, brandColors } = theme.cayoTheme;

  const intl = useIntl();
  const log = useComponentLogger(QueryFilterForm, "QUICK-FILTER");

  const [loadingText, setLoadingText] = React.useState<string | undefined>(undefined);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const { saveSuccess, setSaveSuccess } = queryFilterFormHooks.useHideSaveSuccessInfo();

  const { showConfirmation, hideConfirmation } = React.useContext(AppContext);

  const { errors, setErrorCallback } = queryFilterFormHooks.useFormErrors();

  const [savedQuery, setSavedQuery] = React.useReducer(
    savedQueryModelReducer,
    savedQueries.getClearFilter(items!, targetType)
  );

  useEffect(() => {
    log.debug("mount -> props", props);
    return () => {
      setSelectedSavedQuery(undefined);
    };
  }, []);
  useEffect(() => {
    setLoadingText(intl.formatMessage(commonMessages.loading));

    if (filter && !props.doNotFetch) {
      oClient(endpoints.publicUrl)
        .get(url + "/" + filter.id)
        .query({ $select: "*" })
        .then((response: SavedQuery) => {
          setLoadingText(undefined);

          response = savedQueries.normalizeCommonConditions(response, items!);
          if (filter.builtIn) {
            response = savedQueries.makeCustomSavedQuery(response);
          }
          log.debug(".mount (filter && !props.doNotFetch) -> savedQuery", response);
          setSavedQuery(toFormModel(response, items));
          return Promise.resolve();
        })
        .catch((e) => {
          log.error(e);
          setLoadingText(undefined);
          setSavedQuery({ name: "", commonConditions: {} });
          getError(e).then((value) => setError(value));
        });
    } else {
      if (props.doNotFetch) {
        const customQuery = filter?.builtIn
          ? savedQueries.makeCustomSavedQuery(filter as SavedQuery)
          : (filter as SavedQuery);
        log.debug(".mount (props.doNotFetch) -> savedQuery", customQuery);
        setSavedQuery(toFormModel(customQuery, items));
      }
      setLoadingText(undefined);
    }
  }, []);

  return (
    <Panel isOpen={true} isBlocking={false} type={"medium"} {...addProps}>
      <Column
        style={{
          display: "flex",
          height: "100vh",
          maxHeight: "100%",
          overflowY: "auto",
        }}
      >
        <div>
          <Row style={{ padding: "20px 20px 0 20px" }}>
            <ObjectIcon iconName="FilterSettings" />
            <Column>
              <Text block={true} variant={"large"} style={{ marginLeft: 20, marginTop: 10 }}>
                <FormattedMessage id="query-filter-form.title" defaultMessage="Custom Query" />
              </Text>
              {!readonly && (
                <Row style={{ marginLeft: 12 }}>
                  {/* <ActionButton
                    iconProps={{ iconName: "ClearFilter" }}
                    onClick={() => {
                      setSavedQuery(savedQueries.getClearFilter(annotations!, targetType));
                    }}
                  >
                    {intl.formatMessage(messages.clearFilter)}
                  </ActionButton> */}
                  {savedQuery.id && (
                    <ActionButton
                      onClick={() => {
                        if (!!deleteFilter) {
                          deleteFilter(savedQuery);
                          return;
                        }
                        //TODO: maybe remove
                        queryFilterFormHooks.deleteFilter(
                          url!,
                          savedQuery,
                          gridId!,
                          { onFilterSaved, saveOnServerOnly },
                          showConfirmation,
                          hideConfirmation,
                          onClose!,
                          setError
                        );
                      }}
                      iconProps={{ iconName: "Delete" }}
                    >
                      {intl.formatMessage(messages.deleteFilter)}
                    </ActionButton>
                  )}
                </Row>
              )}
            </Column>
          </Row>
          {!loadingText && !savedQuery.id && !selectedSavedQuery?.builtIn && (
            <MessageBar
              messageBarType={MessageBarType.info}
              styles={messageStyles(brandColors.primaryHighlight)}
            >
              <span>
                <FormattedMessage
                  id="query-filter-form.create-info"
                  defaultMessage="To edit query conditions, use predefined filters and OData expressions in the Quick
                Filter bar."
                />
                &nbsp;
                <a
                  href="https://support.cayosoft.com/hc/en-us/articles/360054605171"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="query-filter-form.create-learn-more"
                    defaultMessage="Learn more."
                  />
                </a>
              </span>
            </MessageBar>
          )}
          {error && (
            <div style={{ paddingTop: 20 }}>
              <MessageBar
                styles={{ root: { display: "flex" } }}
                messageBarType={MessageBarType.blocked}
                messageBarIconProps={{ iconName: "ErrorBadge" }}
                onDismiss={() => setError(undefined)}
                dismissButtonAriaLabel={intl.formatMessage(commonMessages.close)}
                truncated={true}
                overflowButtonAriaLabel={intl.formatMessage(commonMessages.seeMore)}
                isMultiline={false}
              >
                {error}
              </MessageBar>
            </div>
          )}
          {saveSuccess && (
            <div style={{ paddingTop: 20 }}>
              <MessageBar
                styles={{ root: { display: "flex" } }}
                messageBarType={MessageBarType.success}
                onDismiss={() => setSaveSuccess(undefined)}
                dismissButtonAriaLabel={intl.formatMessage(commonMessages.close)}
                truncated={false}
                isMultiline={false}
              >
                {saveSuccess}
              </MessageBar>
            </div>
          )}
        </div>

        <Stack
          horizontal={false}
          verticalFill={true}
          tokens={metrics.stackTokens}
          style={{
            padding: "20px 24px 8px 24px",
            flexGrow: 1,
            position: "relative",
            overflowY: "auto",
            height: "100%",
            display: "flex",
            overflowX: "hidden",
          }}
        >
          {loadingText && <LoadingOverlay text={loadingText} />}
          <TextField
            tooltip={intl.formatMessage(messages.filterNameTooltip)}
            required={true}
            errorMessage={errors.name}
            label={intl.formatMessage(messages.filterNameLabel)}
            disabled={readonly}
            readOnly={!selectedSavedQuery?.createdBy}
            setValue={(v) => {
              setSavedQuery({ name: v });
              if (v && errors.name) {
                setErrorCallback("name", undefined);
              }
            }}
            onBlur={(e) => {
              setErrorCallback(
                "name",
                !e.target.value && intl.formatMessage(commonMessages.inputRequired)
              );
            }}
            value={savedQuery?.name}
          />
          {selectedSavedQuery && (
            <>
              <TextField
                label={intl.formatMessage(messages.effectiveFilter)}
                setValue={() => null}
                value={selectedSavedQuery?.effectiveFilter}
                readOnly
              />
              <CreatedBy selectedSavedQuery={selectedSavedQuery} />
            </>
          )}
          {/* <TextField
            label={intl.formatMessage(messages.searchLabel)}
            disabled={readonly}
            setValue={(v) => {
              setSavedQuery({ anrFilter: v });
            }}
            value={savedQuery?.anrFilter}
          /> */}
          {/* {savedQuery &&
            savedQuery.commonConditions &&
            annotations?.map((a) => {
              log.log("render type:" + a.type + ", name: " + a.name);
              const picker = a as unknown as IObjectPickerAnnotation;
              const selectPropertyName = (a as unknown as IObjectPickerAnnotation)
                ?.selectPropertyName;
              const isSimplePicker = picker.isPicker && !picker?.columns?.length;
              const possibleValues = propertyAnnotations?.find(
                (p) => p.target === props?.targetType + "/" + a.name!
              )?.possibleValues;

              return renderComponent(
                a,
                a.type === "Edm.String" || a.dataType === "Edm.String"
                  ? savedQuery.advancedFilter
                  : savedQuery.commonConditions &&
                      (selectPropertyName || isSimplePicker
                        ? StringsToEntities(
                            savedQuery.commonConditions[a.name!],
                            selectPropertyName,
                            possibleValues
                          )
                        : savedQuery.commonConditions[a.name!]),
                undefined,
                !!readonly,
                (v, annotation) => {
                  const aPicker = annotation as unknown as IObjectPickerAnnotation;
                  const isSimplePicker = aPicker.isPicker && !aPicker?.columns?.length;
                  const isPicker = aPicker.popupTitle;
                  const isDateTime = !!(annotation as unknown as IDateTimeRangeAnnotation)
                    .predefinedRange;

                  const newModel: SavedQuery = {
                    ...savedQuery,
                  };

                  if (isDateTime) {
                    newModel.commonConditions![annotation.name!] = v;
                  } else if (isPicker || isSimplePicker) {
                    newModel.commonConditions![annotation.name!] =
                      (v && (v as IPickerItem[])?.map<SoftLink>((ii) => ii.link as any)) || [];

                    if (isSimplePicker) {
                      newModel.commonConditions![`${a.name}@odata.type`] = "#Collection(String)";
                    }
                  } else {
                    if (typeof v === "string") {
                      newModel![annotation.name!] = v;
                    } else {
                      newModel.commonConditions![annotation.name!] = "";
                    }
                  }

                  setSavedQuery(newModel);
                },
                (v) => setErrorCallback(a.name!, v),
                { intl, ...typeAnnotations, theme, possibleValues }
              );
            })} */}
        </Stack>

        <Stack
          horizontal={true}
          horizontalAlign={"end"}
          style={{ padding: "20px" }}
          tokens={metrics.stackTokens}
        >
          {!readonly && (
            <PrimaryButton
              disabled={
                !!loadingText ||
                (errors && Object.getOwnPropertyNames(errors).length > 0) ||
                !selectedSavedQuery?.createdBy
              }
              onClick={() => {
                if (!savedQuery.name || savedQuery.name.trim().length === 0) {
                  setErrorCallback("name", intl.formatMessage(commonMessages.inputRequired));
                  return;
                }
                setError(undefined);
                setLoadingText(intl.formatMessage(commonMessages.submittingData));

                savedQueries
                  .save(url!, savedQuery!)
                  .then((newSavedQuery) => {
                    setLoadingText(undefined);
                    setSaveSuccess(intl.formatMessage(commonMessages.changesWereSaved));

                    newSavedQuery = savedQueries.normalizeCommonConditions(newSavedQuery, items!);

                    if (!saveOnServerOnly) {
                      filterSignalsService.send("filterChanged", newSavedQuery, gridId);
                    }

                    setSavedQuery({ id: newSavedQuery.id });

                    if (onFilterSaved) {
                      onFilterSaved(newSavedQuery);
                    }

                    onClose!();
                  })
                  .catch((e) => {
                    setError(makeErrorMessage(e));
                    setLoadingText(undefined);
                  });
              }}
            >
              {savedQuery.id
                ? intl.formatMessage(commonMessages.save)
                : intl.formatMessage(commonMessages.add)}
            </PrimaryButton>
          )}

          <DefaultButton disabled={!!loadingText} onClick={() => onClose!()}>
            {intl.formatMessage(commonMessages.cancel)}
          </DefaultButton>
        </Stack>
      </Column>
    </Panel>
  );
};

function toFormModel(savedQuery: SavedQuery, annotations?: ItemAnnotation[]) {
  return toSavedQuery(withEmptyConditions(fromSavedQuery(savedQuery), annotations));
}

const CreatedBy = ({ selectedSavedQuery }: { selectedSavedQuery: any }) => {
  const { getIconAnnotation } = useTypeAnnotations();
  const intl = useIntl();

  return (
    <Wrapper>
      <label>{intl.formatMessage(messages.createdBy)}</label>
      {selectedSavedQuery.createdBy ? (
        <>
          {cellRenderers.object(
            selectedSavedQuery,
            0,

            { fieldName: "createdBy" } as IColumn,
            (path?: string, query?: string, item?: any) => {
              appEvents.trigger({
                showControl: { controlId: "scheme", parameters: item + "/properties" },
              });
              return Promise.resolve();
            },
            {
              getIconAnnotation,
            } as AuxRenderProps
          )}
        </>
      ) : (
        <span>{"<System>"}</span>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
  width: 580px;

  label {
    margin-bottom: 2px;
  }

  .column-checkbox {
    margin-top: 10px;
  }
`;

export default QueryFilterForm;
