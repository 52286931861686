import {
  IGroup,
  IColumn as IOfficeColumn,
  ColumnActionsMode as OfficeColumnActionsMode,
} from "@fluentui/react";
import { dateTimeUtils } from "cayo.ui";
import { IColumn } from "../../api/schema.api";
import { StoredColumn } from "../../settings/stored-column";
import { objectUtils } from "../../utils/object-utils";

enum severity {
  none = 0,
  // Messages, system alerts
  debug = 1000,
  informational = 2000,
  success = 3000,
  warning = 4000,
  error = 5000,
  // Change alerts
  low = 6000,
  medium = 7000,
  high = 8000,
  critical = 9000,
}

const convertToOfficeColumns = (mc: IColumn[]) => {
  return mc
    .filter((c) => !!c)
    .map<IOfficeColumn>((c) => {
      const { columnActionsMode, ...other2 } = c;
      const isText = c.renderOptions?.formatter === "text";
      const isIconOnly =
        (c.fieldName === "severity" ||
          c.fieldName === "executionResult" ||
          c.fieldName === "changeType" ||
          c.fieldName === "state" ||
          c.iconOnly) &&
        !isText;

      let iconColumnProps: Partial<IOfficeColumn> = {};
      if (isIconOnly) {
        iconColumnProps = {
          isIconOnly: false,
          maxWidth: 36,
          minWidth: 36,
        };

        if (c.fieldName === "severity" || c.iconOnly) {
          iconColumnProps.isIconOnly = true;
          iconColumnProps.iconName = c?.icon?.iconName || "Lightbulb";
          iconColumnProps.ariaLabel = c.displayName || c.fieldName;
          iconColumnProps.styles = {
            cellName: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "32px",
            },
            cellTitle: { paddingLeft: 8, flexFlow: "row", justifyContent: "center" },
          };
        }

        if (c.fieldName === "changeType") {
          iconColumnProps.iconName = undefined;
          iconColumnProps.isIconOnly = false;
          iconColumnProps.styles = {
            cellTitle: {
              paddingLeft: 8,
              flexFlow: "row",
              justifyContent: "center",
            },
          };
        }
      }

      let columnMode: OfficeColumnActionsMode;

      switch (columnActionsMode) {
        case "clickable":
          columnMode = OfficeColumnActionsMode.clickable;
          break;
        case "hasDropdown":
          columnMode = OfficeColumnActionsMode.hasDropdown;
          break;

        default:
          columnMode = OfficeColumnActionsMode.disabled;
      }

      let name = c.displayName || c.key!;
      if (c.renderOptions?.formatter === "DateTime") {
        name = dateTimeUtils.formatDateTimeLabel(name);
      }

      return {
        columnActionsMode: columnMode,
        //fieldName: c.fieldName,

        ...other2,
        isResizable: true,
        isSorted: c.isSorted,
        isSortedDescending: c.isSortedDescending,
        maxWidth: c.maxWidth,
        minWidth: c.minWidth!,
        name,
        ...iconColumnProps,
        renderOptions: c.renderOptions,
      };
    });
};

export const detailsListConverters = {
  convertToOfficeColumns,
};

export const getSortedGroups = (
  responseItems: any,
  groupByColumn: string,
  sortedColumn: StoredColumn | undefined
) => {
  let responseGroups = objectUtils.groupBy(responseItems, (i) => {
    const o = i[groupByColumn!];
    return o?.objectName ?? o;
  });
  const isDateTimeColumn =
    sortedColumn &&
    sortedColumn.fieldName &&
    (sortedColumn.fieldName.toLowerCase().indexOf("datetime") >= 0 ||
      responseItems[0][sortedColumn.fieldName] instanceof Date);

  const isNumberColumn =
    sortedColumn &&
    sortedColumn.fieldName &&
    typeof responseItems[0][sortedColumn.fieldName] === "number";

  if (sortedColumn && sortedColumn.fieldName) {
    responseGroups.forEach((v) => {
      (v as any[]).sort((a, b) => {
        if (isDateTimeColumn) {
          const aDate = (a[sortedColumn.fieldName!] as Date)?.getTime() || 0;
          const bDate = (b[sortedColumn.fieldName!] as Date)?.getTime() || 0;

          return (aDate - bDate) * (sortedColumn.isSortedDescending ? -1 : 1);
        } else if (isNumberColumn) {
          const nA = (a[sortedColumn.fieldName!] as number) || 0;
          const nB = (b[sortedColumn.fieldName!] as number) || 0;
          return (nB - nA) * (sortedColumn.isSortedDescending ? 1 : -1);
        } else {
          const strA = a[sortedColumn.fieldName!] as any as string;
          const strB = b[sortedColumn.fieldName!] as any as string;

          return (
            (strA || "").localeCompare(strB || "") * (sortedColumn.isSortedDescending ? 1 : -1)
          );
        }
      });
    });
  }

  const newResponse: any[] = [];
  let newGroups: IGroup[] = [];
  let startIndex = 0;
  // let groupNumber = 0;
  // const isGroupedByDateTime = responseItems[0][groupByColumn] instanceof Date;

  const sortedResponseGroups = new Map();
  if (responseItems[0].snapshotDateTime) {
    const sortedKeys: string[] = [];
    responseGroups.forEach(function callback(value, key) {
      sortedKeys.push(key);
    });

    const mins = {};

    sortedKeys.sort((a, b) => {
      const ar = responseGroups.get(a) as [];
      const br = responseGroups.get(b) as [];

      const minDateA = (mins[a] =
        mins[a] ||
        Math.max.apply(
          null,
          ar.map((aa) => (aa as any).snapshotDateTime)
        ));
      const minDateB = (mins[b] =
        mins[b] ||
        Math.max.apply(
          null,
          br.map((aa) => (aa as any).snapshotDateTime)
        ));

      return (minDateA - minDateB) * -1;
    });

    sortedKeys.forEach((key) => {
      sortedResponseGroups.set(key, responseGroups.get(key));
    });

    responseGroups = sortedResponseGroups;
  }

  responseGroups.forEach((v, k) => {
    newResponse.push(...v);
    newGroups.push({
      count: v.length,
      key: k,
      name: k || "Not set",
      startIndex,
    });
    startIndex += v.length;
  });

  if (groupByColumn === "severity") {
    newGroups = newGroups.sort((a, b) => {
      const d = (severity[b.key] ?? 0) - (severity[a.key] ?? 0);
      return d;
    });
  }

  return { newGroups, newResponse };
};
