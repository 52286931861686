import React, { CSSProperties, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { Stick } from "../../api/schema.api";
import { FCC } from "../../types/react";
import { Column } from "../CommonLayout";
import useRefreshKey from "../GlobalHooks/use-refresh-key.hook";

// TODO: calc dynamically depending on screen resolution?
const horizontalOffset = 16;
const verticalOffset = 8;

const WidgetContainer: FCC<{
  onHeightChanged?: (height: number) => void;
  stick?: Stick;
  style?: CSSProperties;
}> = (props) => {
  const stickStyle = useMemo<CSSProperties>(() => {
    if (!props.stick) {
      return {
        padding: `${verticalOffset}px ${horizontalOffset}px ${
          verticalOffset * 2
        }px ${horizontalOffset}px `,
      };
    }

    const stickVals = props.stick!.split(",").map((s) => s.trim()) as Stick[];

    const stickTop = stickVals.find((s) => s === "top");
    const stickRight = stickVals.find((s) => s === "right");
    const stickBottom = stickVals.find((s) => s === "bottom");
    const stickLeft = stickVals.find((s) => s === "left");

    return {
      paddingTop: stickTop ? 0 : verticalOffset,
      paddingRight: stickRight ? 0 : horizontalOffset,
      paddingBottom: stickBottom ? 0 : verticalOffset,
      paddingLeft: stickLeft ? 0 : horizontalOffset,
    };
  }, [props]);

  const fullStyle = { ...(props?.style || {}), ...stickStyle, ...{ flexGrow: 1, height: "100%" } };
  const ref = useRef<HTMLDivElement>(null);
  const { key, forceRefresh } = useRefreshKey("container");
  const { onHeightChanged } = props;

  const onResize = () => {
    forceRefresh();
  };

  useLayoutEffect(() => {
    if (ref?.current) {
      ref.current.addEventListener("resize", onResize);
      return () => ref.current!.removeEventListener("resize", onResize);
    }

    return;
  }, [ref?.current]);

  useEffect(() => {
    if (onHeightChanged && ref?.current?.clientHeight) {
      onHeightChanged(ref?.current?.clientHeight);
    }
  }, [ref?.current?.clientHeight]);

  return (
    <Column ref={ref} style={fullStyle} key={key}>
      {props.children}
    </Column>
  );
};

export default WidgetContainer;
