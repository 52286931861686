import { defineMessages } from "react-intl";

const dashboardMessages = defineMessages({
  widgetsTitle: {
    defaultMessage: "Select a widget",
    id: "widget-list.title",
  },
  resetView: {
    defaultMessage: "Reset view",
    id: "widget-list.reset-view",
  },
  welcome: {
    defaultMessage: "Welcome to Cayosoft Guardian",
    id: "dashboard.welcome",
  },
});

export default dashboardMessages;
