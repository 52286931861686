import { IColumn as IOfficeColumn } from "@fluentui/react";
import React from "react";
import { AuxRenderProps, IActionPromise } from "../../LegacyDetailsList/cell-renderers";
import { renderArrayCell } from "./renderArrayCell";
import { renderBooleanCell } from "./renderBooleanCell";
import { renderDateCell } from "./renderDateCell";
import { renderEmptyCell } from "./renderEmptyCell";
import { renderFormattedCell } from "./renderFormattedCell";
import { renderIconAndTextCell } from "./renderIconAndTextCell";
import { renderIconOnlyCell } from "./renderIconOnlyGridCell";
import { renderObjectCell } from "./renderObjectCell";
import { renderOdataCell } from "./renderOdataCell";
import { RenderCellFn, RenderCellFnArgs } from "./types";

const renderChain: Array<RenderCellFn> = [
  renderIconOnlyCell,
  renderIconAndTextCell,
  renderEmptyCell,
  renderOdataCell,
  renderArrayCell,
  renderObjectCell,
  renderDateCell,
  renderBooleanCell,
  renderFormattedCell,
];

export default function renderGridCell(
  item: any,
  index: number,
  column: IOfficeColumn | undefined,
  onItemClicked?: IActionPromise,
  auxProps?: AuxRenderProps
) {
  if (!column) return null;
  const renderArgs: RenderCellFnArgs = { item, index, column, onItemClicked, auxProps };
  for (let i = 0; i < renderChain.length; i++) {
    const cell = renderChain[i](renderArgs);
    if (cell !== null) {
      return cell;
    }
  }
  return <h2 style={{ color: "red" }}>UNKNOWN</h2>;
}
