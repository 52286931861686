import { useContext, useEffect, useRef, useState } from "react";
import logger from "../../libs/logger";
import { getError } from "../../libs/odata.client";
import { endpoints } from "../../services/endpoints.service";
import { NewAccessTokenRequestService } from "../../services/new-access-token-request.service";
import { appSettings } from "../../settings/app-settings";
import { ITokenStorage } from "../../settings/token-storage";
import AppContext from "../App/app.context";
import redirectUtils from "./redirect-utils";

const log = logger.getLogger("useAuth");

const useAuth = (tokenEndpoint: string, tokenStorage: ITokenStorage) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isRedirected = useRef(false);
  const { showServerUnavailable } = useContext(AppContext);

  useEffect(() => {
    const addBearer = (token: string, init: RequestInit | undefined) => {
      const newInit: RequestInit = {
        ...(init || {}),
        credentials: "same-origin",
        headers: {
          ...(init?.headers || {}),
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      };

      if (endpoints.isAdminMode) {
        (newInit!.headers! as any).csmclient = "admin";
      }

      return newInit;
    };

    (function () {
      log.debug("fetch monkey patching");
      const originalFetch = fetch;

      const tokenService = new NewAccessTokenRequestService(
        tokenEndpoint,
        tokenStorage,
        originalFetch
      );

      window.fetch = async function (input: RequestInfo | URL, init?: RequestInit) {
        const _this = this;

        const token = tokenStorage.getToken();
        const newInit = token?.access_token ? addBearer(token.access_token, init) : init;

        try {
          const data = await originalFetch.apply(this, [input as any, newInit]);
          if (data.status === 401 || data.status === 403) {
            const responseClone = data.clone();
            let response: any = undefined;
            try {
              response = await data.json();
            } catch {
              // skip errors
            }

            if (response?.source || data.status === 403) {
              return Promise.resolve(responseClone);
            }

            const emptyResponse: Response = {
              status: 200,
              json: async () => null,
              statusText: "",
              text: async () => "",
              ok: true,
            } as Response;

            const redirectUrl = "#" + redirectUtils.getLoginUrl();
            const userSettings = appSettings.userSetting.get();
            const autoLogin = userSettings?.authMode === "office365";
            if (autoLogin && data.headers.get("Cayo-AuthMethod") === "office365") {
              if (!isRedirected.current) {
                isRedirected.current = true;
                clearAuthStorage();

                log.debug("token has expired. redirect to:", redirectUrl);
                window.location.assign(redirectUrl);
              } else {
                log.debug("token has expired. skip redirecting");
              }

              return Promise.resolve(emptyResponse);
            }

            return tokenService
              .refreshToken()
              .then(() => {
                const newToken = tokenStorage.getToken()?.access_token;
                if (!newToken) {
                  return Promise.resolve(emptyResponse);
                }
                const newInit = addBearer(newToken, init);
                return originalFetch.apply(_this, [input as any, newInit]);
              })

              .catch((e) => {
                tokenStorage.clear();
                return Promise.reject(e);
              });
          } else {
            return data;
          }
        } catch (e) {
          log.debug(e);
          const error = await getError(e);
          showServerUnavailable({ message: error, state: "failed" });

          throw e;
        }
      };
    })();

    setIsLoggedIn(!!tokenStorage.getToken()?.access_token);

    return tokenStorage.SaveEvent.on((token) => {
      setIsLoggedIn(!!token?.access_token);
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      log.debug("set isRedirected to false");
      isRedirected.current = false;
    }
  }, [isLoggedIn]);

  return { isLoggedIn };
};

const clearAuthStorage = () => {
  const previousStorage = appSettings.localAuthStorage.get()
    ? appSettings.localAuthStorage
    : appSettings.sessionAuthStorage;

  previousStorage.set(undefined);
  sessionStorage.clear();
};

export default useAuth;
