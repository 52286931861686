function isNativeHTMLTag(tag: string) {
  const tagName = tag.replace(/[<>]/g, '');
  const element = document.createElement(tagName);
  return element instanceof HTMLElement && !(element instanceof HTMLUnknownElement);
}

export function isNativeHTML(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const allElements = doc.getElementsByTagName("*");

  for (let el of allElements) {
    if (!isNativeHTMLTag(el.tagName.toLowerCase())) {
      return false;
    }
  }

  return true;
}
