import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Database } from "../../../api/cayo-graph";
import ODataFacts from "../../Lists/ODataFacts";

const DatabaseSettingsReview: FC<{
  defaultStorageSettings?: Database | undefined;
  customStorageSettings: any;
}> = ({ defaultStorageSettings, customStorageSettings }) => {
  const intl = useIntl();
  return (
    <ODataFacts
      items={[
        {
          name: intl.formatMessage(messages.server),
          value: customStorageSettings
            ? customStorageSettings?.server
            : defaultStorageSettings?.server,
        },
        {
          name: intl.formatMessage(messages.database),
          value: customStorageSettings
            ? customStorageSettings?.database
            : defaultStorageSettings?.databaseName,
        },
        {
          name: intl.formatMessage(messages.databasePath),
          value: customStorageSettings
            ? customStorageSettings?.databasePath
            : defaultStorageSettings?.databasePath,
        },
        {
          name: intl.formatMessage(messages.currentLoginName),
          value: customStorageSettings
            ? customStorageSettings?.user
            : defaultStorageSettings?.loginName,
        },
        {
          name: intl.formatMessage(messages.additionalConnectionParameters),
          value: customStorageSettings && customStorageSettings?.additionalParameters,
        },
      ].filter((i) => !!i.value)}
    />
  );
};

const messages = defineMessages({
  server: {
    defaultMessage: "Server",
    id: "DatabaseSettingsReview.server",
  },
  database: {
    defaultMessage: "Database",
    id: "DatabaseSettingsReview.database",
  },
  databasePath: {
    defaultMessage: "Database path",
    id: "DatabaseSettingsReview.database-pth",
  },
  currentLoginName: {
    defaultMessage: "Login name",
    id: "DatabaseSettingsReview.current-login-name",
  },
  additionalConnectionParameters: {
    defaultMessage: "Additional connection parameters",
    id: "DatabaseSettingsReview.additional-connection-parameters",
  },
});
export default DatabaseSettingsReview;
