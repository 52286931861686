import { Icon, Link, PrimaryButton, Stack, Text } from "@fluentui/react";
import { ITheme, LoadingOverlay, useTheme } from "cayo.ui";
import React, { FC } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import styled from "styled-components";
import { NetworkMessages } from "../i18n/messages/network-messages";
import logger from "../libs/logger";
import { getError } from "../libs/odata.client";
import downloadUtils from "../utils/download-utils";
import { CenteredPanel } from "./CommonLayout";

const DownloadFile: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { cayoTheme } = useTheme();

  const [isLoading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [resolvedUrl, setResolvedUrl] = React.useState<string | undefined>(undefined);

  log.debug("location", window.location.href);

  React.useEffect(() => {
    if (resolvedUrl) {
      return;
    }

    const url = localStorage.getItem("download");
    localStorage.removeItem("download");

    if (!url) {
      log.debug("no file found in LS");
      setLoading(false);
      setError(intl.formatMessage(messages.fileNotFound));

      return;
    }

    fetch(url.replace("/$value", ""), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((r) => {
        if (r.status >= 200 && r.status < 300) {
          logger.debug("report file info", r);
          setResolvedUrl(url);
          downloadUtils.downloadODataFile(url);
        } else if (r.status >= 500) {
          logger.debug("Failed to get report file info", r);
          getError(r).catch((m) => log.debug("failed to get report file", m));
          setError(intl.formatMessage(messages.fileNotFound));
        }
      })
      .catch((e) => {
        if (e.status === 0) {
          setError(intl.formatMessage(NetworkMessages.serverUnavailable));
          localStorage.setItem("download", url);
        }
        log.debug(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <CenteredPanel>
      <LogoPanelContainer>
        <LogoPanel>
          <Logo>Cayosoft</Logo>
        </LogoPanel>
      </LogoPanelContainer>
      {resolvedUrl && !error ? (
        <BigIcon iconName={"DownloadDocument"} />
      ) : (
        <BigIcon iconName={"PageRemove"} />
      )}
      <StyledText variant="mediumPlus">
        {resolvedUrl && !error ? (
          <>
            <FormattedMessage
              id="download-file.intro1"
              defaultMessage="Your download would start shortly. If your download did not start, please, "
            />
            <Link onClick={() => downloadUtils.downloadODataFile(resolvedUrl)}>
              <FormattedMessage id="download-file.intro2" defaultMessage="click here." />
            </Link>
          </>
        ) : (
          error
        )}
      </StyledText>

      <Stack horizontal={true} tokens={cayoTheme.metrics.stackTokens}>
        <PrimaryButton
          onClick={() => {
            log.debug("navigate to root with reload");
            history.push({ pathname: "/", key: new Date().toISOString() });
          }}
        >
          <Icon iconName={"Home"}></Icon>&nbsp;&nbsp;
          <FormattedMessage id="download-file.retry" defaultMessage="Go to Cayosoft Guardian" />
        </PrimaryButton>
      </Stack>
    </CenteredPanel>
  );
};

const Logo = styled.div`
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 27px;
  font-weight: bold;
  display: block;
  height: 100%;
  line-height: 44px;
  padding: 8px;
  color: rgb(37, 198, 255);
`;

const LogoPanel = styled.div`
  width: 300px;
  height: 100%;
  margin: 0px;
  flex-flow: row;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
`;

const LogoPanelContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.primaryBackground};
`;

const BigIcon = styled(Icon)`
  font-size: 70px;
`;

const StyledText = styled(Text)`
  padding: 18px 0px;
  margin: 6px;
  font-weight: 600;
`;

const messages = defineMessages({
  fileNotFound: {
    defaultMessage: "Report file not found.",
    id: "download-file.file-not-found",
  },
});

const log = logger.getLogger("download");

export default DownloadFile;
