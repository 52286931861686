import React, { FC } from "react";
import {
  ConditionType,
  IAnrCondition,
  IQuery,
  newQuery,
  setCondition,
} from "./GridContainer/grid-model/Query";
import { DispatchAction } from "./GridContainer/grid-model/grid.controller.actions";
import SearchBox from "./SearchBox";

interface IAnrSearchBoxProps {
  query?: IQuery;
  dispatch: DispatchAction;
  width?: number;
}

const AnrSearchBox: FC<IAnrSearchBoxProps> = (props) => {
  const value = ((
    props.query?.conditions.find((c) => c.kind === ConditionType.Anr) as Readonly<IAnrCondition>
  )?.value ?? "") as string;
  const handleSetAnr = (v?: string) => {
    const query = setCondition(props.query ?? newQuery(), {
      kind: ConditionType.Anr,
      value: v ?? "",
    });
    props.dispatch({ kind: "changeQuery", query });
  };
  return <SearchBox setValue={handleSetAnr} value={value} width={props.width} />;
};

export default AnrSearchBox;
