import { PrimaryButton, Stack, Text } from "@fluentui/react";
import React, { FC, Fragment, useCallback, useState } from "react";
import { IPickerScheme } from "../../api/schema.api";
import ajax from "../../libs/ajax";

import { LoadingOverlay, Row, debounce } from "cayo.ui";
import { useIntl } from "react-intl";
import { Entity } from "../../api/cayo-graph";
import { oClient } from "../../libs/odata.client";
import { endpoints } from "../../services/endpoints.service";
import { ajaxUtils } from "../../utils/ajax-utils";
import { urlUtils } from "../../utils/url-utils";
import { useSchemes } from "../App/hooks";
import { GridContainer } from "../GridContainer/GridContainer";
import { commonMessages } from "../common-messages";
import { useGridSettingsKey } from "./ODataScheme/hook";

const PickerSchemeLoader: FC<{ navPropPath: string }> = ({ navPropPath }) => {
  const intl = useIntl();
  const {
    data: scheme,
    isLoading,
    error,
  } = ajax.useGet<IPickerScheme>(`scheme/{schemePath}`, {
    schemePath: "picker/" + navPropPath,
  });

  const { closePropertiesPanel, refreshPropertiesPanel } = useSchemes();
  const [isSubmitting, setIsSubmittng] = useState(false);

  const [selectedItem, setSelectedItem] = useState<Entity | undefined>(undefined);

  const { data: propertyAnnotations, isLoading: isAnnotationsLoading } =
    ajax.usePropertyAnnotations(`v0/{schemePath}`, {
      schemePath: scheme?.entitySetPath,
    });

  const gridSettingsKey = useGridSettingsKey(scheme?.detailsList?.id);

  const onSelectedItem = useCallback((item: Entity | undefined, submit?: boolean) => {
    if (submit && item) {
      const savePath = "v0/" + urlUtils.replaceLastSegment(navPropPath, "$ref");
      const objectPath = endpoints.publicUrl + "/v0/" + item.objectPath;

      setIsSubmittng(true);
      return oClient(endpoints.publicUrl)
        .post(savePath!, { "@odata.id": objectPath })
        .query()
        .then(() => {
          closePropertiesPanel();
          debounce(refreshPropertiesPanel, 100)();
        })
        .catch((e) => {
          ajaxUtils.handleError(e);
        })
        .finally(() => setIsSubmittng(false));
    } else {
      setSelectedItem(item);
      return Promise.resolve();
    }
  }, []);

  if (isLoading || isAnnotationsLoading || isSubmitting) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <Fragment>{error}</Fragment>;
  }

  return (
    <Stack verticalFill={true}>
      <Stack
        horizontal={true}
        verticalAlign={"center"}
        style={{
          width: "100%",
          paddingRight: 80,
          paddingTop: 14,
          paddingLeft: 20,
          marginBottom: 20,
        }}
      >
        <Text block={true} nowrap={true} variant={"xLarge"}>
          {scheme?.title}
        </Text>
      </Stack>

      {/* <Stack grow={true}> */}
      <GridContainer
        scheme={scheme!}
        propertyAnnotations={propertyAnnotations}
        gridSettingsKey={gridSettingsKey}
        onSelect={onSelectedItem}
      />
      {/* </Stack> */}

      <Row
        style={{
          padding: 20,
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <PrimaryButton
          onClick={async () => await onSelectedItem(selectedItem, true)}
          disabled={!selectedItem}
        >
          {intl.formatMessage(commonMessages.select)}
        </PrimaryButton>
      </Row>
    </Stack>
  );
};

export default PickerSchemeLoader;
