import { CommandBarButton, IContextualMenuItem, IContextualMenuProps } from "@fluentui/react";
import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import logger from "../../libs/logger";
import { commandBarMessages } from "../CommandBar/messages";
import { useQuickFilterBarButtonStyle } from "./GridFilterBar.style";
import { IQuery, newQuery } from "./grid-model/Query";
import { DispatchAction } from "./grid-model/grid.controller.actions";

type ManageQueryProps = {
  currentQuery: IQuery;
  dispatch: DispatchAction;
  //makeForEdit: (query: IQuery) => IQuery;
};

const log = logger.getLogger("QUICK-FILTER-NEW GridFilterBar.ManageQuery");

export const ManageQuery = ({ currentQuery, dispatch }: ManageQueryProps) => {
  const intl = useIntl();

  useEffect(() => {
    log.debug("mount -> props", { currentQuery, dispatch });
  }, []);

  const saveEnabled = currentQuery.isTemp;
  const menuItems: IContextualMenuProps = useMemo(() => {
    const addNew = {
      key: "new-query",
      text: intl.formatMessage(commandBarMessages.newFilter),
      iconProps: { iconName: "Add" },
      onClick: () => {
        dispatch({ kind: "createNewQuery", newQuery: newQuery() });
      },
    };
    const saveAs = {
      key: "save-query-as",
      text: intl.formatMessage(commandBarMessages.saveAsFilter),
      iconProps: { iconName: "SaveAs" },
      onClick: () => {
        dispatch({ kind: "querySaveAs", query: currentQuery });
      },
    };
    const edit = {
      key: "edit-query",
      text: intl.formatMessage(commandBarMessages.editFilter),
      iconProps: { iconName: "Edit" },
      disabled: !currentQuery.id,
      onClick: () => {
        dispatch({ kind: "openEditForm", query: currentQuery });
      },
    };
    const save = {
      key: "save-query",
      text: intl.formatMessage(commandBarMessages.saveFilter),
      iconProps: { iconName: "Save" },
      disabled: !currentQuery.isTemp,
      onClick: () => {
        dispatch({ kind: "save", query: currentQuery });
      },
    };

    const items: Array<IContextualMenuItem> = currentQuery.isSystem
      ? [addNew, save, saveAs]
      : [addNew, edit, save, saveAs];
    return { items };
  }, [currentQuery, dispatch]);

  const btnStyle = useQuickFilterBarButtonStyle();

  return (
    <CommandBarButton
      iconProps={{ iconName: "Save" }}
      title={"Save"}
      ariaLabel={"Save button"}
      splitButtonAriaLabel={'See options'}
      styles={btnStyle}
      split
      primaryDisabled={!saveEnabled}
      menuProps={menuItems}
      onClick={() => dispatch({ kind: "save", query: currentQuery })}
    ></CommandBarButton>
  );
};
