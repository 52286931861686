import { SavedQuery } from "../../../api/cayo-graph";
import logger from "../../../libs/logger";
import {
  Condition,
  ConditionType,
  ExpressionLogicType,
  IQuery,
  LogicalOperandType,
  OdataQueryOperandType,
  PropertyOperandType,
  TimeIntervalType,
  filterDisabledOperandsFromExpression,
  isOdataQueryOperandType,
  isPropertyOperandType,
} from "./Query";

const log = logger.getLogger("QUICK-FILTER-NEW Query");

export function toSavedQuery(query: IQuery): SavedQuery {
  const result: SavedQuery = {
    id: query.id,
    name: query.name,
    builtIn: query.isSystem,
  };
  if (query.orderBy) {
    result.orderBy = query.orderBy.field;
    result.orderDirection = query.orderBy.direction;
  }
  let commonConditions: any = {};
  query.conditions.forEach((c) => {
    switch (c.kind) {
      case ConditionType.Anr:
        result.anrFilter = c.value;
        break;
      case ConditionType.Expression:
        result.expression = makeExpressionCondition(c);
        break;
      case ConditionType.Advanced:
        result.advancedFilter = c.value;
        break;
      default:
        commonConditions = { ...commonConditions, ...makeCommonCondition(c) };
    }
  });

  if (Object.keys(commonConditions).length) result.commonConditions = commonConditions;
  return result;
}
export function fromSavedQuery(savedQuey: SavedQuery): IQuery {
  const result: IQuery = {
    id: savedQuey.id,
    name: savedQuey.name,
    isSystem: savedQuey.builtIn ?? false,
    conditions: makeConditions(savedQuey),
    isDefault: savedQuey.default,
    orderBy: savedQuey.orderBy
      ? {
          field: savedQuey.orderBy,
          direction: savedQuey.orderDirection || "ascending",
        }
      : undefined,
    alertRule: savedQuey.alertRule,
    job: savedQuey.job,
  };
  return result;
}

function toExpressionOdataQueryOperand(operand: OdataQueryOperandType): any {
  return {
    "@odata.type": operand.objectType,
    filter: operand.filter,
    enabled: operand.enabled,
  };
}

function fromExpressionOdataQueryOperand(operand: any): OdataQueryOperandType {
  return {
    objectType: operand["@odata.type"],
    filter: operand.filter,
    enabled: operand.enabled,
  };
}

function toExpressionPropertyOperand(operand: PropertyOperandType): any {
  return {
    "@odata.type": operand.objectType,
    operator: operand.operator,
    valueLink: operand.valueLink,
    metadata: operand.metadata,
    property: operand.propertyObjectType?.key
      ? `${operand.propertyObjectType.key}/${operand.property}`
      : operand.property,
    value: operand.value,
    enabled: operand.enabled,
  };
}

function fromExpressionPropertyOperand(operand: any): PropertyOperandType {
  const propertyParts = operand.property.split("/");
  const property = propertyParts.pop()!;
  const propertyObjectType = { key: propertyParts[0] || "", id: "", type: "" };

  return {
    objectType: operand["@odata.type"],
    operator: operand.operator,
    propertyObjectType,
    property,
    value: operand.value,
    enabled: operand.enabled,
    valueLink: operand.valueLink,
    metadata: operand.metadata,
  };
}

function toExpressionLogicalOperand(operand: LogicalOperandType): any {
  return {
    "@odata.type": operand.objectType,
    operator: operand.operator,
    enabled: operand.enabled,
    operands: operand.operands?.map((v) => {
      if (isPropertyOperandType(v)) return toExpressionPropertyOperand(v);
      else if (isOdataQueryOperandType(v)) return toExpressionOdataQueryOperand(v);
      else return toExpressionLogicalOperand(v);
    }),
  };
}

function fromExpressionLogicalOperand(operand: any): LogicalOperandType {
  return {
    objectType: operand["@odata.type"],
    operator: operand.operator,
    enabled: operand.enabled,
    operands: operand.operands.map((v: any) => {
      if (
        v["@odata.type"] === `#${ExpressionLogicType.PropertyExpression}` ||
        v.objectType === ExpressionLogicType.PropertyExpression
      )
        return fromExpressionPropertyOperand(v);
      else if (
        v["@odata.type"] === `#${ExpressionLogicType.OdataQueryExpression}` ||
        v.objectType === ExpressionLogicType.OdataQueryExpression
      )
        return fromExpressionOdataQueryOperand(v);
      else return fromExpressionLogicalOperand(v);
    }),
  };
}

function makeExpressionCondition(condition: Condition): any {
  if (condition.kind === ConditionType.Expression) {
    return {
      "@odata.type": condition.objectType,
      operator: condition.operator,
      operands: filterDisabledOperandsFromExpression(condition.operands).map((v) => {
        if (isPropertyOperandType(v)) return toExpressionPropertyOperand(v);
        else if (isOdataQueryOperandType(v)) return toExpressionOdataQueryOperand(v);
        else return toExpressionLogicalOperand(v);
      }),
    };
  }
}

function fromExpressionCondition(condition: any): Condition {
  return {
    kind: ConditionType.Expression,
    objectType: condition["@odata.type"],
    operator: condition.operator,
    operands: condition.operands?.map((v: any) => {
      if (
        v["@odata.type"] === `#${ExpressionLogicType.PropertyExpression}` ||
        v.objectType === ExpressionLogicType.PropertyExpression
      )
        return fromExpressionPropertyOperand(v);
      else if (
        v["@odata.type"] === `#${ExpressionLogicType.OdataQueryExpression}` ||
        v.objectType === ExpressionLogicType.OdataQueryExpression
      )
        return fromExpressionOdataQueryOperand(v);
      else return fromExpressionLogicalOperand(v);
    }),
  };
}

function makeCommonCondition(condition: Condition): any {
  switch (condition.kind) {
    case ConditionType.TimeInterval:
      switch (condition.value.objectType) {
        case TimeIntervalType.LastHour:
          return {
            [condition.propName]: {
              "@odata.type": `#${condition.value.objectType}`,
              objectType: condition.value.objectType,
            },
          };
        case TimeIntervalType.LastDay:
          return {
            [condition.propName]: {
              "@odata.type": `#${TimeIntervalType.LastDays}`,
              objectType: TimeIntervalType.LastDays,
              number: 1,
            },
          };
        case TimeIntervalType.LastWeek:
          return {
            [condition.propName]: {
              "@odata.type": `#${TimeIntervalType.LastDays}`,
              objectType: TimeIntervalType.LastDays,
              number: 7,
            },
          };
        case TimeIntervalType.LastMonth:
          return {
            [condition.propName]: {
              "@odata.type": `#${TimeIntervalType.LastDays}`,
              objectType: TimeIntervalType.LastDays,
              number: 30,
            },
          };
        case TimeIntervalType.LastDays:
          return {
            [condition.propName]: {
              "@odata.type": `#${condition.value.objectType}`,
              objectType: condition.value.objectType,
              number: condition.value.number,
            },
          };
        case TimeIntervalType.Custom:
          return {
            [condition.propName]: {
              "@odata.type": `#${condition.value.objectType}`,
              objectType: condition.value.objectType,
              startDateTime: condition.value.startDateTime,
              endDateTime: condition.value.endDateTime,
            },
          };
        default:
          return {};
      }
    case ConditionType.Property:
      return {
        [`${condition.propName}@odata.type`]: condition.dataType,
        [condition.propName]: condition.value,
      };
    default:
      return {};
  }
}

function makeConditions(savedQuery: SavedQuery): Array<Condition> {
  const result: Condition[] = [];
  if (!!savedQuery.anrFilter) {
    result.push({ kind: ConditionType.Anr, value: savedQuery.anrFilter });
  }
  if (!!savedQuery.advancedFilter) {
    result.push({ kind: ConditionType.Advanced, value: savedQuery.advancedFilter });
  }

  if (!!savedQuery.expression) {
    result.push(fromExpressionCondition(savedQuery.expression));
  }

  if (!!savedQuery.commonConditions) {
    const commonConditions = { ...savedQuery.commonConditions };
    // prop conditions
    Object.keys(commonConditions)
      .filter((k) => k.endsWith("@odata.type"))
      .forEach((k) => {
        const dataType = commonConditions![k];
        const propName = k.substring(0, k.length - "@odata.type".length);
        const value = commonConditions![propName];
        result.push({ kind: ConditionType.Property, propName, dataType, value });
        delete commonConditions[k];
        delete commonConditions[propName];
      });
    // time intervals
    Object.keys(commonConditions).forEach((k) => {
      const ti = commonConditions[k];
      if (!ti) return;
      switch (ti.objectType) {
        case TimeIntervalType.LastHour:
          result.push({
            kind: ConditionType.TimeInterval,
            propName: k,
            value: { objectType: TimeIntervalType.LastHour },
          });
          break;
        case TimeIntervalType.LastDay:
          result.push({
            kind: ConditionType.TimeInterval,
            propName: k,
            value: { objectType: TimeIntervalType.LastDays, number: 1 },
          });
          break;
        case TimeIntervalType.LastWeek:
          result.push({
            kind: ConditionType.TimeInterval,
            propName: k,
            value: { objectType: TimeIntervalType.LastDays, number: 7 },
          });
          break;
        case TimeIntervalType.LastMonth:
          result.push({
            kind: ConditionType.TimeInterval,
            propName: k,
            value: { objectType: TimeIntervalType.LastDays, number: 30 },
          });
          break;
        case TimeIntervalType.LastDays:
          result.push({
            kind: ConditionType.TimeInterval,
            propName: k,
            value: {
              objectType: TimeIntervalType.LastDays,
              number: ti.number,
            },
          });
          break;
        case TimeIntervalType.Custom:
          result.push({
            kind: ConditionType.TimeInterval,
            propName: k,
            value: {
              objectType: TimeIntervalType.Custom,
              startDateTime: ti.startDateTime,
              endDateTime: ti.endDateTime,
            },
          });
          break;
        default:
          log.error(
            "fromSavedQuery -> makeConditions unknown condition -> condition, savedQuery",
            ti,
            savedQuery.commonConditions
          );
          if (process.env.NODE_ENV !== "production") {
            throw Error("fromSavedQuery -> makeConditions unknown condition");
          }
      }
    });
  }
  return result;
}
