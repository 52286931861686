import React, { Fragment } from "react";
import { HashRouter as Router } from "react-router-dom";
//import { ThemeProvider } from "styled-components";
import { registerFormatters } from "cayo.ui";
import { initWizards } from "cayo.ui.wizards";
import { arrayBufferToBase64 } from "../../scheme/formatters/array-buffer-to-base64-formatter";
import { durationMinutes } from "../../scheme/formatters/custom-duration-formatter";
import { sidToString } from "../../scheme/formatters/sid-formatter";
import { endpoints } from "../../services/endpoints.service";
import globalHooks from "../GlobalHooks";
import MessageProvider from "../Localization/MessageProvider";
import LocalizedApp from "./LocalizedApp";
import initTheme from "./init-theme";
import { useDebugStuff } from "./useDebugStuff";

// tslint:disable-next-line: no-var-requires
require("./../../polyfill");

// eslint:disable-next-line: no-var-requires
require("url-polyfill");

initTheme();
initWizards({ PUBLIC_URL: endpoints.publicUrl, REDIRECT_URI: endpoints.redirectUri });

registerFormatters({
  sid: sidToString,
  base64: arrayBufferToBase64 as any,
  durationMinutes: durationMinutes as any,
});

const isIFrame = window.self !== window.top;

const App = () => {
  const { userSettings } = globalHooks.useUserSettings();

  useDebugStuff();

  if (isIFrame) {
    return <Fragment />;
  }

  return (
    <MessageProvider lang={userSettings.lang!}>
      <Router>
        <LocalizedApp />
      </Router>
    </MessageProvider>
  );
};

export default App;
