import {
  IDropdownStyles,
  IDropdownOption as IOfficeDropdownOption,
  IRenderFunction,
  ISelectableOption,
  MessageBarType,
  Dropdown as OfficeDropDown,
  Stack,
} from "@fluentui/react";
import { Html, ITheme, useCustomLabel, useTheme } from "cayo.ui";
import React, { CSSProperties, FC, useEffect, useMemo } from "react";
import { IDropDown } from "../api/schema.api";
import { StyledMessageBar } from "./CommonLayout";

const styles = (
  props: Omit<IDropDown, "type"> & { style?: CSSProperties },
  hasChanges: boolean,
  theme: ITheme
): Partial<IDropdownStyles> => {
  const callout = {};
  const { brandColors } = theme.cayoTheme;
  return {
    callout,
    panel: { ...callout },
    root: {
      ...(props.style as any), // TODO: remove cast
      minWidth: 176,
    },
    title: [
      hasChanges && {
        borderColor: `${brandColors.success} !important`,
        selectors: { ":focus": { borderColor: brandColors.success } },
      },
    ],
    dropdown: [
      hasChanges && {
        borderColor: `${brandColors.success} !important`,
        selectors: {
          ":focus": { selectors: { "::after": { borderColor: brandColors.success } } },
        },
      },
    ],
  };
};

const DropDown: FC<
  Omit<IDropDown, "type"> & {
    style?: CSSProperties;
    onChange: (
      e: React.FormEvent<HTMLDivElement>,
      option?: IOfficeDropdownOption | undefined
    ) => void;
  }
> = (props) => {
  const { onChange, options, selectedKey } = props;
  const theme = useTheme();

  if (!options) {
    throw new Error("Options is not set");
  }

  const officeOptions = useMemo(() => {
    const result = options.map<IOfficeDropdownOption>((o) => ({
      data: { icon: "Cancel" },
      key: o.key,
      selected: selectedKey === o.key,
      text: o.text || "",
      title: o.text,
      formatValue: props?.formatValue,
    }));

    return result;
  }, [options, selectedKey]);

  useEffect(() => {
    officeOptions?.length && onChange && onChange({} as any, officeOptions[0]);
  }, []);

  const { onRenderLabel, targetRef } = useCustomLabel();

  const onRenderOption: IRenderFunction<ISelectableOption> = (p): JSX.Element => {
    return <>{p?.text}</>;
  };

  const onServerRenderSelectedItem: IRenderFunction<IOfficeDropdownOption[]> = (
    options
  ): JSX.Element => {
    const option = options?.find((o) => o.selected);

    return onRenderOption(option)!;
  };

  let DropDownConponent = (
    <OfficeDropDown
      label={props.label}
      componentRef={targetRef}
      onRenderTitle={onServerRenderSelectedItem}
      onRenderLabel={(p) => onRenderLabel(p, props.tooltip)}
      onRenderOption={onRenderOption}
      required={props.required}
      onChange={onChange}
      placeholder={props.placeHolder}
      options={officeOptions}
      styles={styles(props, false, theme)}
      selectedKey={props.selectedKey}
      disabled={props.disabled}
    />
  );

  if (props.note) {
    DropDownConponent = (
      <Stack horizontal={false} tokens={{ childrenGap: 12 }}>
        <StyledMessageBar messageBarType={MessageBarType.warning}>
          <Html value={props.note} />
        </StyledMessageBar>
        {DropDownConponent}
      </Stack>
    );
  }

  return DropDownConponent;
};

export default DropDown;
