import { SearchBox as OfficeSearchBox } from "@fluentui/react";
import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";

interface ISearchBoxProps {
  value?: string;
  disabled?: boolean;
  setValue: (value: string | undefined) => void;
  placeholder?: string | undefined;
  width?: number;
}

const SearchBox: FC<ISearchBoxProps> = (props) => {
  const intl = useIntl();
  const key = "sb-" + props.value ?? "";

  return (
    <OfficeSearchBox
      key={key}
      defaultValue={props.value}
      onSearch={(value) => {
        props.setValue(value);
      }}
      onClear={() => {
        props.setValue("");
      }}
      onChange={(_, value) => {
        if (value === "") {
          props.setValue("");
        }
      }}
      styles={{ root: { width: props.width } }}
      placeholder={props.placeholder || intl.formatMessage(messages.search)}
      disabled={props.disabled}
      maxLength={100}
    />
  );
};

const messages = defineMessages({ search: { id: "search-box.search", defaultMessage: "Search" } });

export default SearchBox;
