import {
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IModalProps,
  IModalStyles,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { LoadingOverlay } from "cayo.ui";
import React, { FC, Fragment, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import logger from "../../../libs/logger";
import { useSchemes } from "../../App/hooks";
import dashboardMessages from "../../Dashboard/messages";
import { WidgetTitle } from "../../Widgets/common";
import StartPage from "./StartPage";
import useInitialConfiguration from "./hook";
import messages from "./messages";

const dialogStyles: Partial<IModalStyles> = { main: { maxWidth: "100% !important" } };

const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
};

const InitialConfigurationWizard: FC<{ isLoggedIn?: boolean; isVisible?: boolean }> = ({
  isLoggedIn,
  isVisible,
}) => {
  const { showConfigWizard, configuration } = useInitialConfiguration(isLoggedIn);

  const { configInfo, actions } = configuration;
  const labelId: string = useId("dialogLabel");
  const subTextId: string = useId("subTextLabel");
  const intl = useIntl();
  const isComplete = useMemo(
    () => configInfo?.hasDomains && configInfo?.hasLicense && configInfo?.hasTenants,
    [configInfo?.hasDomains, configInfo?.hasLicense, configInfo?.hasTenants]
  );
  const isGoToWebPortalDisabled = !(configInfo.hasLicense && configInfo.databaseConfigured);

  const { clientComponents } = useSchemes();
  //const [doNotShowWizardAgain, { toggle: toggleDoNotShowWizardAgain }] = useBoolean(false);

  const modalProps = useMemo<IModalProps>(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions,
    }),
    [labelId, subTextId, clientComponents]
  );

  const title = useMemo(() => {
    return (
      <Stack>
        {intl.formatMessage(dashboardMessages.welcome)}
        <WidgetTitle style={{ flexGrow: 0, paddingTop: 4 }}>
          <FormattedMessage
            id="start-page.title"
            defaultMessage="Before using the product, complete these configuration tasks."
          />
        </WidgetTitle>
      </Stack>
    );
  }, []);

  const ButtonComponent = isComplete ? PrimaryButton : DefaultButton;

  if (showConfigWizard === undefined) {
    return <LoadingOverlay text="Loading configuration..." />;
  }

  if (!showConfigWizard) {
    return <Fragment />;
  }

  return (
    <Dialog
      dialogContentProps={{
        type: DialogType.normal,
        title,
        showCloseButton: false,

        styles: {
          subText: {
            marginTop: 8,
          },
          innerContent: { height: "100%", width: "100%" },
        },
      }}
      styles={{
        main: {
          minWidth: "600px !important",
          //maxWidth: "100% !important",
        },
        // root: {
        //   background: `url('${"themes/backgrounds/logo.png"}') no-repeat fixed center`,
        // },
      }}
      hidden={!isVisible}
      modalProps={modalProps}
      onDismiss={() => {
        logger.debug("d");
      }}
    >
      <StartPage actions={actions} configInfo={configInfo} />
      <DialogFooter
        styles={{
          actions: { display: "flex" },
          actionsRight: { display: "flex", width: "100%" },
          action: { width: "100%" },
        }}
      >
        <Stack
          horizontal={true}
          verticalAlign="center"
          horizontalAlign="end"
          verticalFill={false}
          grow={true}
          style={{ width: "100%" }}
        >
          {/* <Checkbox
            checked={doNotShowWizardAgain}
            onChange={toggleDoNotShowWizardAgain}
            label={intl.formatMessage(commonMessages.doNotShowThisAgain)}
          ></Checkbox> */}
          <ButtonComponent
            disabled={isGoToWebPortalDisabled}
            onClick={() => actions.onConfigureLater()}
          >
            <Stack horizontal={true}>{intl.formatMessage(messages.goToWebPortal)}</Stack>
          </ButtonComponent>
        </Stack>
      </DialogFooter>
    </Dialog>
  );
};

export default InitialConfigurationWizard;
