// eslint-disable-next-line no-useless-escape
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// const downLevelLogonRegex = /^[a-zA-Z0-9][a-zA-Z0-9.]{0,14}\\[^"\/\\\[\]:;|=,+*?<>]{1,104}$/

// eslint-disable-next-line no-useless-escape
const domainAccountRegex =
  /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))|([a-zA-Z0-9][a-zA-Z0-9.]{0,14}\\[^"/\\[\]:;|=,+*?<>]{1,104})$/;

const dnRegex =
  /^(([Cc][Nn]=([^,]*)),)?((((?:[Cc][Nn]|[Oo][Uu])=[^,]+,?)+),)?(([Dd][Cc]=[^,]+,?)+)([Dd][Cc]=[^,]+)$/gim;

const hrefRegex = /href="(.*?)"/;

const typeRegex = /type="(.*?)"/;

const hrefBodyRegex = /<a[^>]*>(.*?)<\/a>/;

const anchorTagRegex = /<a[^>]* href="(.*?)">(.*?)<\/a>/gim;

const entireAnchorRegex = /<a[^>].*?<\/a>/gim;

const actionRegex = /action=(.*)/;

const afterEG = /([e]\.[g](\.)(.*))/;

const positiveInt32Regex =
  /^([0-9]{1,9}|1[0-9]{9}|2(0[0-9]{8}|1([0-3][0-9]{7}|4([0-6][0-9]{6}|7([0-3][0-9]{5}|4([0-7][0-9]{4}|8([0-2][0-9]{3}|3([0-5][0-9]{2}|6([0-3][0-9]|4[0-7])))))))))$/;

const monthDaysRange = /^(?:[1-9]|[12]\d|3[01])$/;
const monthsRange = /^(1[0-2]|[1-9])$/;

export const regexs = {
  actionRegex,
  domainAccountRegex,
  emailRegex,
  dnRegex,
  hrefBodyRegex,
  hrefRegex,
  afterEG,
  positiveInt32Regex,
  anchorTagRegex,
  entireAnchorRegex,
  typeRegex,
  monthDaysRange,
  monthsRange,
};
