import { Picker } from "../InplaceObjectPicker";
import ExpressionForm from "./components/ExpressionForm";
import { ComparisonOperator } from "../../api/cayo-graph";
import { IFilterDependency } from "../../api/schema.api";
import {
  ConditionType,
  ExpressionGroupCombinationType,
  ExpressionLogicType,
} from "../GridContainer/grid-model/Query";
import { LogicalExpressionType, OperandModelType, ExpressionType } from "./types";

export const withCustomExpression: Array<string> = ["modifiedProperties"];

export const relatedFieldsMap: { [key: string]: string } = {
  modifiedProperties: "nestedType",
};

export const labels = {
  Properties: "Modified properties"
}

export const withDependency: IFilterDependency = {
  nestedType: {
    dependencyKey: "objectName",
    dependentKey: "declaringType",
    deps: [],
  },
};

export const components = {
  "object-picker": Picker,
  "modified-property-value": ExpressionForm,
};

export const selectTypes = [
  { key: "any", text: "Any value" },
  { key: "_added", text: "New/added" },
  { key: "_removed", text: "Old/removed" },
];

export const postfixMap = {
  any: "",
  _added: "_added",
  _removed: "_removed",
};

export const MapConditionToText: Record<ComparisonOperator, string> = {
  equals: "equals",
  notEquals: "not equals",
  contains: "contains",
  noContains: "not contains",
  present: "present",
  notPresent: "not present",
  greaterThan: "greater than",
  greaterThanOrEqual: "greater than or equal",
  lessThan: "less than",
  lessThanOrEqual: "less than or equal",
  startsWith: "starts with",
  endsWith: "ends with",
  notContains: "not contains",
};

export const logicalExpressionModel: LogicalExpressionType = {
  objectType: ExpressionLogicType.LogicalExpression,
  operator: ExpressionGroupCombinationType.OR,
  enabled: true,
  operands: [],
};

export const operandModel: OperandModelType = {
  objectType: ExpressionLogicType.PropertyExpression,
  property: "",
  value: "",
  enabled: true,
  operator: "contains",
  metadata: null,
};

export type OperandType = typeof operandModel;

export const expressionModel: ExpressionType = {
  kind: ConditionType.Expression,
  objectType: ExpressionLogicType.LogicalExpression,
  operator: ExpressionGroupCombinationType.AND,
  operands: [],
  enabled: true,
};
