const uniqueByKey = <T>(arr: T[], key?: string) => {
  return [...new Map(arr.map((item) => [key ? (item as any)[key] : item, item])).values()];
};
const unique = <T>(arr: any[]) => {
  const result = Array.from(new Set(arr.map((i) => i.id))).map((i) =>
    arr.find((m) => m.id === i)
  ) as T[];

  return result;
};

const arrayUtils = {
  unique,
  uniqueByKey,
};

export default arrayUtils;
