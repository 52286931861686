import { defineMessages } from "react-intl";

export const NetworkMessages = defineMessages({
  connectionRefusedError: {
    defaultMessage:
      "Your have lost connection with the server. Check your internet connection and try again.",
    id: "network-errors.connection_refused",
  },
  internalServerError: {
    defaultMessage: "Internal server error",
    id: "network-errors.internal-server-error",
  },
  serverUnavailable: {
    defaultMessage: "Server is unavailable",
    id: "network-errors.server-unavailable",
  },
});
