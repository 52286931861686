import LogicalODataExpression from "./odata-logical-expression-evaluator";

class ODataFilterParser {
  private expression: string | null;

  constructor(url: string) {
    const match = url.match(matchFilterRegex);
    const expression = match && match[1];

    this.expression = expression;
  }

  public parse(object: any) {
    if (!this.expression) {
      return true;
    }

    const evaluator = new LogicalODataExpression(this.expression);
    return evaluator.parse(object);
  }
}

const matchFilterRegex = /\$filter=([^&]*)/;

export default ODataFilterParser;
