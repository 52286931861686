import { useTheme } from "cayo.ui";
import React, { FC } from "react";
import Icon from "./Icon";

const GearsIcon: FC = () => {
  const { cayoTheme } = useTheme();
  const iconColor = cayoTheme.brandColors.disabled;
  return (
    <div>
      <Icon
        style={{ fontWeight: 500, fontSize: "18px", color: iconColor }}
        rotateSpeed={"16s"}
        iconName="Settings"
        rotate={"true"}
        backward={true}
      ></Icon>
      <div style={{ marginTop: -45, marginLeft: 14 }}>
        <Icon
          rotateSpeed={"16s"}
          style={{ fontSize: "30px", color: iconColor }}
          iconName="Settings"
          rotate={"true"}
        ></Icon>
      </div>
    </div>
  );
};

export default GearsIcon;
