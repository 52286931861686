import { Link, Stack } from "@fluentui/react";
import { MessageBar } from "cayo.ui";
import styled from "styled-components";

export const MessageWrapper = styled.div`
  margin-right: 0px;
  margin-left: 6px;
`;

export const NextWrapper = styled(Stack)`
  user-select: none;
`;

export const NextLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const StyledMessageBar = styled(MessageBar)<{ isVisible: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  display: ${(props) => (props.isVisible ? "flex" : "none")};
`;

export const StaticAlertsContainer = styled.div`
  position: relative;
`;
