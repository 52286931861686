import { concatStyleSets } from "@fluentui/merge-styles";
import { IButtonStyles, ActionButton as OfficeActionButton } from "@fluentui/react";
import React, { CSSProperties, FC } from "react";
import styled from "styled-components";
import { IActionButton } from "../../api/schema.api";
import { getMenuButtonStyles } from "../Alerts/Alerts.styles";

const ActionButton: FC<
  IActionButton & {
    onClick?: () => void;
    styles?: IButtonStyles;
    style?: CSSProperties;
    themeId?: string;
  }
> = (props) => {
  const { icon, styles, style, themeId, text, id, type, onClick } = props;

  const btnStyle = concatStyleSets(
    themeId === "topMenu" ? getMenuButtonStyles() : undefined,
    styles
  );

  return (
    <StyledOfficeActionButton
      onClick={async (e) => {
        e.stopPropagation();

        onClick!();
      }}
      text={text}
      ariaLabel={text || id}
      id={id}
      type={type}
      styles={btnStyle}
      additionalStyle={style}
      iconProps={{
        iconName: icon?.iconName,
        style: {
          fontSize: (style && style.fontSize) || undefined,
          lineHeight: "16px",
          marginLeft: "4px",
        },
      }}
    />
  );
};

const StyledOfficeActionButton = styled(OfficeActionButton)<{ additionalStyle?: CSSProperties }>`
  padding: 0px;
  white-space: nowrap;
  ${(props) => ({ ...props.additionalStyle })}
`;

export default ActionButton;
