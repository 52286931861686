import { IGroup } from "@fluentui/react";
import { logger } from "cayo.ui";
import { IntlShape } from "react-intl";
import { Entity, JobRecord } from "../../api/cayo-graph";

const log = logger.getLogger("TreeController");
class TreeController {
  private groups: IGroup[] = [];
  private updateKey = "";
  private requestExecuting = false;
  private readonly MaxItems = 2000;
  private fields = [
    "id",
    "objectPath",
    "objectName",
    "name",
    "duration",
    "executionState",
    "executionResult",
    "executionPath",
    "executionDepth",
  ].join(",");

  constructor(
    readonly rootUrl: string,
    readonly fetchFn: (url: string) => Promise<Entity[] | undefined>,
    readonly intl: IntlShape
  ) {}

  private getUrl() {
    return `${this.rootUrl}?$select=${this.fields}&$top=${this.MaxItems}`;
  }

  public loadTree = async (updateKey: string) => {
    if (updateKey === this.updateKey && this.requestExecuting) {
      //pooling
      return null;
    }
    this.updateKey = updateKey;
    try {
      this.requestExecuting = true;
      const flatItems: JobRecord[] = (await this.fetchFn(this.getUrl())) || [];

      flatItems.forEach((itm) => {
        itm.id = itm.id?.replace(/-/g, "");
        itm.executionPath = itm.executionPath?.replace(/-/g, "");
      });

      const depthVector: readonly number[] = [
        ...flatItems.reduce((acc, value) => {
          const numValue = Number(value.executionDepth);
          if (!isNaN(numValue)) acc.add(numValue);
          return acc;
        }, new Set<number>()),
      ].sort();
      log.debug("loadTree -> depthVector", depthVector);

      const result = { groups: [] as IGroup[], items: [] as any[] };

      const makeRowItem = (item: any) => ({
        ...item,
        name: item.name || item.objectName || "",
        key: item.id,
        url: item.objectPath,
      });
      const makeGroup = (item: any) => {
        const parentPath = `${item.executionPath}/${item.id}`;
        const childDepth = item.executionDepth + 1;
        const childItems = flatItems.filter(
          (itm) => itm.executionDepth === childDepth && itm.executionPath?.startsWith(parentPath)
        );
        const childsIsGroup = childDepth !== depthVector.at(-1);

        const group: IGroup = {
          data: item,
          key: item.id,
          name: item.objectName ?? "",
          isSelected: false,
          level: depthVector.indexOf(item.executionDepth),
          startIndex: childsIsGroup ? -1 : result.items.length,
          count: childItems.length,
          children: childsIsGroup ? childItems.map((itm) => makeGroup(itm)) : [],
        };
        // push items
        if (!childsIsGroup) {
          result.items.push(...childItems.map((itm) => makeRowItem(itm)));
        }
        return group;
      };
      // no groups
      if (depthVector.length === 1) {
        flatItems.forEach((v) => result.items.push(makeRowItem(v)));
      } else {
        flatItems
          .filter((itm) => itm.executionDepth === depthVector[0])
          .forEach((itm) => result.groups.push(makeGroup(itm)));
      }

      this.syncGroups(result.groups);
      this.groups = result.groups;
      //this.items = result.items;

      return result;
    } finally {
      this.requestExecuting = false;
    }
  };

  private syncGroups = (newGroups: IGroup[]) => {
    const collapsedGroups: IGroup[] = [];
    this.walkGroups(this.groups, (g) => g.isCollapsed && collapsedGroups.push(g));

    this.walkGroups(newGroups, (g) => {
      const existingGroup = collapsedGroups.find((cg) => cg.key === g.key);
      if (existingGroup) {
        g.isCollapsed = true;
      }
    });
  };

  private walkGroups = (groups: IGroup[], callback: (g: IGroup) => void) => {
    groups.forEach((g) => {
      callback(g);
      if (g?.children?.length) {
        this.walkGroups(g.children, callback);
      }
    });
  };
}

export default TreeController;
