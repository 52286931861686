import { useLogger } from "cayo.ui";
import React, { FC, useMemo, useState } from "react";
import InitialConfigurationWizard from "../Configuration/InitialConfigurationWizard";
import useManifest from "../GlobalHooks/useManifest";
import WizardsSchemeHolder from "../Schemes/WizardScheme/WizardsSchemeHolder";
import { ClientWizardsHolder } from "./ClientWizardsHolder";

type WizardStuffProps = { isLoggedIn?: boolean };

export const WizardStuff: FC<WizardStuffProps> = ({ isLoggedIn }) => {
  const [isWizardOpened, setIsWizardOpened] = useState(false);
  const log = useLogger("WizardStuff");

  const manifest = useManifest();

  const showInitialConfig = useMemo(() => {
    const hideInitialConfig = process.env.REACT_APP_HIDE_INITIAL_CONFIG;
    if (hideInitialConfig) {
      return false;
    }

    const initialConfigDisabled =
      manifest?.features && manifest.features["initialConfig"] === "disabled";

    const result = !!manifest?.name && !initialConfigDisabled;

    log.debug("showInitialConfig", result);
    return result;
  }, [manifest]);

  return (
    <>
      <WizardsSchemeHolder onWizardAction={(show) => setIsWizardOpened(show)} />
      <ClientWizardsHolder onWizardAction={(show) => setIsWizardOpened(show)} />
      {showInitialConfig && (
        <InitialConfigurationWizard isLoggedIn={isLoggedIn} isVisible={!isWizardOpened} />
      )}
    </>
  );
};
