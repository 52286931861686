import { useCallback, useRef, useState } from "react";

const useCallout = () => {
  const tooltipButtonRef = useRef(null);
  const calloutRef = useRef(null);
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const onDismiss = useCallback(() => {
    setIsCalloutVisible(false);
  }, []);

  const onShowTooltip = () => {
    setIsCalloutVisible(true);
  };

  return { isCalloutVisible, onShowTooltip, onDismiss, tooltipButtonRef, calloutRef };
};

export default useCallout;
