import { DefaultButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { domUtils } from "cayo.ui";
import React, { CSSProperties, FC } from "react";
import { FormattedMessage } from "react-intl";
import { IRunnableButton } from "../../api/schema.api";
import useRunnableObject from "../GlobalHooks/runnable-object.hook";

type RunnableButtonProps = IRunnableButton & { onRefresh?: () => void; style?: CSSProperties };

const RunnableButton: FC<RunnableButtonProps> = (props) => {
  const { name, url, stateProp, runStateValue, loadingText, onRefresh, style, text } = props;

  const { onUpdate, objectState } = useRunnableObject({
    actionPath: name!,
    objectPath: url,
    stateProp,
    onRefresh,
  });

  const isRunning = objectState === runStateValue;

  return isRunning ? (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      grow={false}
      verticalFill={true}
      style={style}
    >
      <Spinner size={SpinnerSize.small} label={loadingText} labelPosition="right" />
    </Stack>
  ) : (
    <DefaultButton
      style={style}
      onClick={(e) => {
        domUtils.stopMouseDown(e);
        onUpdate();
      }}
      onMouseDown={domUtils.stopMouseDown}
      ariaLabel={text || 'Run button'}
    >
      {text || <FormattedMessage id="runnable-button.run" defaultMessage="Run" />}
    </DefaultButton>
  );
};

export default RunnableButton;
