import { IPieChart, PieChart } from "cayo.ui";
import React from "react";
import GaugeWidget from "../Widgets/GaugeWidget";

const chartRenderers = {
  piechart: (props: IPieChart) => <PieChart {...props} />,
  gauge: GaugeWidget,
};

export default chartRenderers;
