import { PossibleValueItem, SoftLink } from "../../api/cayo-graph";
import {
  IAdvancedObjectPicker,
  IDateTimeRange,
  IFormField,
  IObjectPicker,
  ITextField,
} from "../../api/schema.api";

import {
  Condition,
  ConditionType,
  IAdvancedCondition,
  IAnrCondition,
  IExpressionAdvancedPicker,
  IPropCondition,
  IQuery,
  ITimeIntervalCondition,
  PropertyDataType,
  TimeIntervalConditionValue,
  TimeIntervalType,
  newQuery,
  removeCondition,
  setCondition,
} from "./grid-model/Query";

export type ItemAnnotation = IObjectPicker | IDateTimeRange | IFormField | ITextField;

export const pickerValueMap = {
  fromConditions(
    conditions: Readonly<Condition[]>,
    annotation: IFormField,
    possibleValues: PossibleValueItem[] = []
  ) {
    if (isDateTimeRangeAnnotation(annotation)) {
      return makeTimeIntervalValue(conditions, annotation);
    }
    if (isPickerAnnotation(annotation)) {
      return makePickerValue(conditions, annotation, possibleValues);
    }
    if (isAdvancedFilterAnnotation(annotation)) {
      return makeAdvancedFilterValue(conditions);
    }
    if (isAnrFilterAnnotation(annotation)) {
      return makeAnrFilterValue(conditions);
    }
    if (isAdvancePicker(annotation)) {
      return makeAdvancePickerValue(conditions, annotation);
    }
    // unknown annotation
    throw Error(`unknown annotation ${JSON.stringify(annotation, null, 2)}`);
    //return undefined;
  },
  toQuery(
    value: unknown,
    annotation: IFormField,
    query: IQuery,
    expression?: IExpressionAdvancedPicker
  ): IQuery {
    if (isAdvancedFilterAnnotation(annotation)) {
      return setCondition(query, makeAdvancedCondition(value));
    }
    if (isAnrFilterAnnotation(annotation)) {
      return setCondition(query, makeAnrCondition(value));
    }
    if (isPickerAnnotation(annotation)) {
      return setCondition(query, makePropertyCondition(value, annotation));
    }
    if (isDateTimeRangeAnnotation(annotation)) {
      const condition = makeTimeIntervalCondition(value, annotation);
      if (!!condition) {
        return setCondition(query, condition);
      } else {
        return removeCondition(query, {
          kind: ConditionType.TimeInterval,
          propName: annotation.name!,
          value: { objectType: TimeIntervalType.LastHour },
        });
      }
    }
    if (isAdvancePicker(annotation)) {
      return setCondition(query, expression!);
    }
    return query;
  },
};

export function makeQueryFromAnnotations(annotations: Array<ItemAnnotation> | undefined): IQuery {
  return !annotations
    ? newQuery()
    : annotations.reduce(
        (query, annotation) => pickerValueMap.toQuery(undefined, annotation, query),
        newQuery()
      );
}

// add empty value from picker annotations
export function withEmptyConditions(
  query: IQuery,
  annotations: Array<ItemAnnotation> | undefined
): IQuery {
  return !annotations
    ? query
    : annotations.reduce((query, annotation) => {
        if (!isPickerAnnotation(annotation)) {
          return query;
        }
        if (query.conditions.some((q) => q.kind === "property" && q.propName === annotation.name)) {
          return query;
        }
        return pickerValueMap.toQuery(undefined, annotation, query);
      }, query);
}

function isDateTimeRangeAnnotation(annotation: ItemAnnotation): annotation is IDateTimeRange {
  return !!(annotation as IDateTimeRange).predefinedRange;
}

function isPickerAnnotation(annotation: ItemAnnotation): annotation is IObjectPicker {
  return !!annotation.isPicker;
}
function isAdvancedFilterAnnotation(annotation: ItemAnnotation): boolean {
  return annotation.name === "advancedFilter";
}

function isAdvancePicker(annotation: ItemAnnotation): boolean {
  return annotation.type === "advanced-object-picker";
}

function isAnrFilterAnnotation(annotation: ItemAnnotation): boolean {
  return annotation.name === "anrFilter";
}

function makeTimeIntervalValue(
  conditions: Readonly<Condition[]>,
  annotation: IDateTimeRange
): TimeIntervalConditionValue | undefined {
  const condition = conditions.find(
    (c) => c.kind === ConditionType.TimeInterval && c.propName === annotation.name
  ) as ITimeIntervalCondition | undefined;
  if (!condition) {
    return undefined;
  }

  const value = condition.value;
  switch (value.objectType) {
    case TimeIntervalType.LastDay:
      return { objectType: TimeIntervalType.LastDays, number: 1 };
    case TimeIntervalType.LastWeek:
      return { objectType: TimeIntervalType.LastDays, number: 7 };
    case TimeIntervalType.LastMonth:
      return { objectType: TimeIntervalType.LastDays, number: 30 };
    default:
      return { ...value };
  }
}

function makeAdvancePickerValue(
  conditions: Readonly<Condition[]>,
  annotation: IAdvancedObjectPicker
) {
  let values: {
    [key: string]: any;
  }[] = [];

  const extractValuesFromOperands = (operands: any[], annotation: IAdvancedObjectPicker) => {
    for (const operand of operands) {
      if (/(#)?cayo\.graph\.logicalExpression/.test(operand.objectType)) {
        extractValuesFromOperands(operand.operands, annotation);
      } else if (
        /(#)?cayo\.graph\.propertyExpression/.test(operand.objectType) &&
        annotation.items
      ) {
        if (annotation.items.some((item) => item.name === operand?.metadata?.annotationName)) {
          let value = parseValue(operand?.metadata);
          values.push(value);
        }
      }
    }
  };

  const expression = conditions.find((c) => c.kind === "expression") as IExpressionAdvancedPicker;
  if (expression) {
    extractValuesFromOperands(expression.operands, annotation);
  }

  values = values.filter(
    (value, index, self) =>
      index === self.findIndex((v) => JSON.stringify(v) === JSON.stringify(value))
  );

  return values;
}

function removeProperties(obj: { [key: string]: any }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { possibleValues, queryOperators, syntax, columns, expressionForm, ...rest } = obj;
  return rest;
}

function parseValue(metadata: { [key: string]: any }) {
  const parsedValue = removeProperties(metadata);
  let result = {
    value: parsedValue,
    expressionForm: null,
  };
  if (isValidJSON(metadata.link)) result.value.link = JSON.parse(metadata.link);
  if (isValidJSON(metadata?.possibleValues))
    result.value.possibleValues = JSON.parse(metadata.possibleValues);
  if (isValidJSON(metadata?.queryOperators))
    result.value.queryOperators = JSON.parse(metadata.queryOperators);
  if (isValidJSON(metadata?.expressionForm))
    result.expressionForm = JSON.parse(metadata.expressionForm);
  result.value.syntax = metadata?.syntax;

  return result;
}

function isValidJSON(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

function makePickerValue(
  conditions: Readonly<Condition[]>,
  annotation: IObjectPicker,
  possibleValues: PossibleValueItem[]
): any {
  const condition = conditions.find(
    (c) => c.kind === ConditionType.Property && c.propName === annotation.name
  ) as IPropCondition | undefined;
  if (!!condition) {
    switch (condition.dataType) {
      case PropertyDataType.StringCollection:
        return condition.value.map((v) => {
          const pv = possibleValues.find((pv) => pv.value === v);

          return !pv ? { id: v, text: v, link: v } : { id: v, text: pv.name, link: v };
        });
      case PropertyDataType.SoftLinkCollection:
        return condition.value.map((v) => ({ ...v }));
    }
  }
  return [];
}
function makeAdvancedFilterValue(conditions: Readonly<Condition[]>) {
  const condition = conditions.find((c) => c.kind === ConditionType.Advanced) as
    | IAdvancedCondition
    | undefined;
  if (!!condition) {
    return condition.value;
  }
  return undefined;
}
function makeAnrFilterValue(conditions: Readonly<Condition[]>) {
  const condition = conditions.find((c) => c.kind === ConditionType.Anr) as
    | IAnrCondition
    | undefined;
  if (!!condition) {
    return condition.value;
  }
  return undefined;
}

function makeAdvancedCondition(value: unknown): Condition {
  return { kind: ConditionType.Advanced, value: typeof value === "string" ? value : "" };
}
function makeAnrCondition(value: unknown): Condition {
  return { kind: ConditionType.Anr, value: typeof value === "string" ? value : "" };
}
function makePropertyCondition(value: unknown, annotation: IObjectPicker): Condition {
  if (!!annotation.selectPropertyName || !annotation.columns) {
    function makeStringValue(value: unknown): string[] {
      if (!Array.isArray(value)) {
        return [];
      } else {
        return value.reduce((acc, current) => {
          if (typeof current.link === "string") {
            acc.push(current.link);
          }
          return acc;
        }, [] as string[]);
      }
    }
    return {
      kind: ConditionType.Property,
      propName: annotation.name!,
      dataType: PropertyDataType.StringCollection,
      value: makeStringValue(value),
    };
  } else {
    function makeSoftLinkValue(value: unknown): SoftLink[] {
      if (!Array.isArray(value)) {
        return [];
      } else {
        return value.map(({ link }) => ({ ...link })) as SoftLink[];
      }
    }
    return {
      kind: ConditionType.Property,
      propName: annotation.name!,
      dataType: PropertyDataType.SoftLinkCollection,
      value: makeSoftLinkValue(value),
    };
  }
}
function makeTimeIntervalCondition(
  value: unknown,
  annotation: IFormField
): ITimeIntervalCondition | undefined {
  if (!!value) {
    const v = value as TimeIntervalConditionValue;

    switch (v.objectType) {
      case TimeIntervalType.LastHour:
        return {
          kind: ConditionType.TimeInterval,
          propName: annotation.name!,
          value: { objectType: TimeIntervalType.LastHour },
        };
      case TimeIntervalType.LastDay:
        return {
          kind: ConditionType.TimeInterval,
          propName: annotation.name!,
          value: { objectType: TimeIntervalType.LastDays, number: 1 },
        };
      case TimeIntervalType.LastWeek:
        return {
          kind: ConditionType.TimeInterval,
          propName: annotation.name!,
          value: { objectType: TimeIntervalType.LastDays, number: 7 },
        };
      case TimeIntervalType.LastMonth:
        return {
          kind: ConditionType.TimeInterval,
          propName: annotation.name!,
          value: { objectType: TimeIntervalType.LastDays, number: 30 },
        };
      case TimeIntervalType.LastDays:
        return {
          kind: ConditionType.TimeInterval,
          propName: annotation.name!,
          value: { objectType: TimeIntervalType.LastDays, number: v.number },
        };
      case TimeIntervalType.Custom:
        return {
          kind: ConditionType.TimeInterval,
          propName: annotation.name!,
          value: {
            objectType: TimeIntervalType.Custom,
            startDateTime: v.startDateTime,
            endDateTime: v.endDateTime,
          },
        };
    }
  }
  return undefined;
}
