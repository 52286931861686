import { Stack, Text } from "@fluentui/react";
import { stringUtils } from "cayo.ui";
import React, { FC } from "react";
import { IEmptyItem } from "../../api/schema.api";
import { allRendereres } from "../Schemes/renderers";

const EmptyItem: FC<IEmptyItem> = (props) => {
  const itemType = stringUtils.dashedToLowCase(props.addButton!.type);

  return (
    <Stack verticalAlign={"center"} horizontalAlign={"center"}>
      {allRendereres[itemType](props.addButton, props)}
      <Stack>
        {props.title?.split("\n").map((t, i) => (
          <Text key={i} variant={"smallPlus"}>
            {t}
          </Text>
        ))}
      </Stack>
    </Stack>
  );
};

export default EmptyItem;
