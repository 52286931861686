import { useCallback } from "react";
import { singletonHook } from "react-singleton-hook";
import { Icon, TypeAnnotation } from "../../api/cayo-graph";
import ajax from "../../libs/ajax";
import { collectionTypeRegex, objectUtils } from "../../utils/object-utils";

const _useTypeAnnotations = () => {
  const { data, isLoading, reloadData } = ajax.useGet(
    `v0/schema/annotations/cayo.graph.typeAnnotation?$select=icon,collectionIcon,target,helpLink,missingDataText&$filter=effective eq true and (icon ne null or collectionIcon ne null or (helpLink ne null and helpLink ne '')or (missingDataText ne null and missingDataText ne ''))&$top=10000`,
    { compressResponse: true }
  );

  const getTypeAnnotation = useCallback(
    (type?: string) => {
      const annotations = data as TypeAnnotation[];

      if (!annotations?.length || !type) {
        return undefined;
      }

      const match = type.match(collectionTypeRegex);
      let annotation: TypeAnnotation | undefined;

      if (match?.length === 2) {
        const collectionType = match[1];

        annotation = annotations?.find((a) => a.target === collectionType);
        annotation = {
          ...annotation,
          collectionIcon: undefined,
          icon: annotation?.collectionIcon || annotation?.icon,
        };
      } else {
        annotation = annotations?.find((a) => a.target === type);
      }

      return annotation;
    },
    [data]
  );

  const getIconAnnotation: IGetIconAnnotation = useCallback(
    (type, dontUseDefaultIcon) => {
      const annotation = getTypeAnnotation(type);

      if (annotation?.icon?.iconId) {
        return annotation.icon;
      }

      if (!annotation?.icon?.iconId && !dontUseDefaultIcon) {
        const defaultIcon = type && objectUtils.isCollection(type) ? "FabricFolder" : "Document";
        return { iconId: defaultIcon };
      }

      return {};
    },
    [getTypeAnnotation]
  );

  return { isLoading, getTypeAnnotation, getIconAnnotation, reloadData };
};

export type IGetTypeAnnotation = (type: string) => TypeAnnotation | undefined;
export type IGetIconAnnotation = (type?: string, dontUseDefaultIcon?: boolean) => Icon;

const useTypeAnnotations = singletonHook(
  {
    isLoading: true,
    getTypeAnnotation: () => {
      return undefined;
    },
    getIconAnnotation: () => ({} as Icon),
    reloadData: () => null,
  },
  _useTypeAnnotations
);

export default useTypeAnnotations;
