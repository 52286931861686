import { useResponsiveMode } from "@fluentui/react";
import { LoadingOverlay, useComponentLogger } from "cayo.ui";
import React, { FC, Fragment, useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import { useIntl } from "react-intl";
import { HashRouter as Router, useHistory, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Entity } from "../../api/cayo-graph";
import { DashboardClient, IScheme, NavTreeClient } from "../../api/schema.api";
import { UserProfileClient } from "../../api/user-profile.api";
import { localeStore } from "../../i18n/locale-store";
import ActionBuilder from "../../scheme/actions";
import { endpoints } from "../../services/endpoints.service";
import notificationService, { mergeInteractiveItems } from "../../services/notification.service";
import ServicesContext, { IServices } from "../../services/services.context";
import tokenStorage from "../../settings/token-storage";
import viewStorageFactory from "../../settings/view-storage-factory";
import { useServerTheme } from "../../themes";
import { objectUtils } from "../../utils/object-utils";
import { IAlertItem } from "../Alerts/alerts.api";
import ServerStateComponent from "../AppState/ServerStateComponent";
import { HealthCheckClient, IApiAccessState } from "../AppState/health-check.api";

import ConfirmationModal from "../ConfirmationModal";
import ErrorModal from "../ErrorModal";
import globalHooks from "../GlobalHooks";
import AppRouting from "../routing";
import GlobalStyle from "./GlobalStyle";
import { appEvents } from "./app-events";
import AppContext, { RightPanelType } from "./app.context";
import { appReducer, useSchemes } from "./hooks";
import { IShowControlHandler, IUserMessage, ServerError } from "./types";
import { appUtils } from "./utils";

const LocalizedApp: FC = () => {
  const rootRef = useRef(null);
  const intl = useIntl();
  const log = useComponentLogger(LocalizedApp);
  const history = useHistory();
  const location = useLocation();
  const uiInteraction = globalHooks.useUIInteraction(log);

  const newResponsiveMode = useResponsiveMode(rootRef);

  const services = useMemo<IServices>(
    () => ({
      actionBuilder: new ActionBuilder(intl, { history, location }, uiInteraction),
      uiInteraction,

      tokenStorage,
      viewStorageFactory,

      healthCheck: new HealthCheckClient(endpoints.publicUrl),
      notificationService,

      navTreeClient: new NavTreeClient(endpoints.publicUrl),
      userProfileClient: new UserProfileClient<IScheme>(endpoints.publicUrl),
      dashboardClient: new DashboardClient(endpoints.publicUrl),
    }),
    []
  );

  useEffect(() => {
    if (appUtils.isStaticPage()) {
      log.debug("static page. notification service will not start");
      return;
    }

    notificationService.start();

    window.onbeforeunload = () => {
      notificationService.stop();
    };

    return () => {
      //  document.removeEventListener("mousedown", onMouseDown);

      notificationService.stop();
    };
  }, []);

  const {
    propertiesPanels,
    clientComponents,
    hideClientComponents,
    removeClientComponent,
    showControl,
    showAction,
    isLoading: isSchemeLoading,
    updatePropertiesPanel,
    closePropertiesPanel,
  } = useSchemes();

  const { userSettings, setUserSettings } = globalHooks.useUserSettings();
  const hideConfirmation = () => {
    setState({ confirmationMessage: undefined });
  };

  const setBreadcrumbInfo = (info: any[] | undefined): void => {
    setState({ breadcrumbInfo: info });
  };

  const setLocale = (locale: string): void => {
    localeStore.setLocale(locale);
    setUserSettings({ lang: locale });
  };

  const setStaticAlerts = (alerts: IAlertItem[] | undefined): void => {
    if (alerts) {
      const newAertItems = (state.staticAlerts && [...state.staticAlerts]) || [];
      mergeInteractiveItems(
        newAertItems,
        alerts,
        true,
        (alert) => (alert as IAlertItem)?.status !== "new"
      );

      setState({ staticAlerts: newAertItems.filter((a: any) => a.status === "new") });
    }
  };

  const showConfirmation = (
    confirmationMessage: string | undefined,
    onConfirmed: () => Promise<void>,
    confirmationWarning?: string | undefined
  ): void => {
    setState({ confirmationMessage, onConfirmed, confirmationWarning });
  };

  const showError = (error: string | undefined): void => {
    // TODO:
    if (error === "invalid_grant") {
      return;
    }

    setState({ message: { text: error || "Unknown error", severity: "error" } });
  };

  const showMessage = (message: IUserMessage | undefined): void => {
    // TODO:
    if (message?.text === "invalid_grant") {
      return;
    }

    setState({ message });
  };

  const showRightPanel = (rightPanelType?: RightPanelType): void => {
    setState({ rightPanelType });
  };

  const showServerUnavailable = (serverError: ServerError | null | undefined): void => {
    log.debug("showServerUnavailable", serverError);
    setState({ serverError });
  };

  useEffect(() => {
    setState({ responsiveMode: newResponsiveMode });
  }, [newResponsiveMode]);

  const [state, setState] = useReducer(appReducer, {
    breadcrumbInfo: [],
    hideConfirmation,
    responsiveMode: newResponsiveMode,
    setLocale,
    showConfirmation,

    rightPanelType: undefined,
    showRightPanel,

    setBreadcrumbInfo,
    setStaticAlerts,
    showError,
    showMessage,

    showServerUnavailable,
  });

  const isServerAvailable = state.serverError === null;

  useEffect(() => {
    const handleAppEventsHander = (evt?: IAppEvents | undefined) => {
      if (!evt) {
        return;
      }

      if (evt.hideClientComponent) {
        hideClientComponents();
        return;
      }

      if (evt.showControl !== undefined) {
        showControl(evt.showControl);
        return;
      }

      if (evt.action !== undefined) {
        showAction(evt.action);
        return;
      }

      if (evt.showError !== undefined) {
        showError(evt.showError);
        return;
      }

      if (evt.showMessage !== undefined && evt.showMessage.text) {
        showMessage(evt.showMessage);
        return;
      }

      if (evt.webSocketConnectionLost !== undefined) {
        setState({ webSockerConnectionLost: evt.webSocketConnectionLost });
        if (evt.webSocketConnectionLost === false && !appUtils.isStaticPage()) {
          // showServerUnavailable(false);
          log.debug("reload page, webSocketConnectionLost==false");
          if (!/^#\/login\?/i.test(document.location.href)) {
            document.location.reload();
          }
        }
        return;
      }

      if (evt.serverError !== undefined) {
        showServerUnavailable(evt.serverError);
        return;
      }

      if (evt.updateProps) {
        if (evt.updateProps.objects?.length === 1) {
          updatePropertiesPanel({
            id: evt.updateProps.objects[0].objectPath,
            parentId: evt.updateProps.id,
          });
        } else {
          closePropertiesPanel(evt.updateProps.id);
        }
      }
    };

    return appEvents.on(handleAppEventsHander);
  }, [clientComponents, propertiesPanels]);

  const renderClientComponents = useCallback(() => {
    return (
      <Fragment>
        {clientComponents?.map((clientComponent, i) => {
          const onClose = (result: boolean) => {
            removeClientComponent();
            if (!!clientComponent?.parameters?.onClose) {
              clientComponent.parameters.onClose(result);
            }

            if (result && clientComponent?.parameters?.reloadAfterClose) {
              log.debug("reload page, reloadAfterClose");
              document.location.reload();
            } else if (result !== false && (clientComponent?.parameters as any).onRefresh) {
              (clientComponent?.parameters as any).onRefresh();
            }
          };

          const componentProps = {
            ...clientComponent.parameters,
            ...(objectUtils.cloneObject(clientComponent.parameters) || {}),
            onClose,
          };

          var ClientComponent = clientComponent.component as any;
          return (
            <ClientComponent
              {...componentProps}
              key={
                (clientComponent?.parameters as any)?.url ||
                clientComponent?.component?.displayName ||
                `clientCmp${i}`
              }
            />
          );
        })}
      </Fragment>
    );
  }, [clientComponents]);

  const { theme, isLoading: isThemeLoading } = useServerTheme(userSettings.theme || "default");

  if (isThemeLoading) {
    return <LoadingOverlay text={"Loading themes..."} />;
  }

  const isMessageVisible = isServerAvailable && state.message && location.pathname !== "/login";

  return (
    <ServicesContext.Provider value={services}>
      <ThemeProvider theme={theme}>
        <GlobalStyle {...theme} />

        {isMessageVisible && (
          <ErrorModal message={state.message!} onDismiss={() => showMessage(undefined)} />
        )}
        {isServerAvailable && state.confirmationMessage && (
          <ConfirmationModal
            onClose={state.hideConfirmation}
            onConfirmed={state.onConfirmed}
            confirmationMessage={state.confirmationMessage}
            confirmationWarning={state.confirmationWarning}
            showError={state.showError}
            objects={(state as any)?.objects}
          />
        )}

        <AppContext.Provider value={state}>
          <>
            {/* ServerStateComponent must always be created to listen for app events */}
            <ServerStateComponent
              onServerUnavailable={showServerUnavailable}
              serverError={state.serverError}
            />

            {isServerAvailable && (
              <>
                {renderClientComponents()}
                <Router>
                  {uiInteraction.loadingInfo && (
                    <LoadingOverlay
                      text={
                        typeof uiInteraction.loadingInfo === "string"
                          ? uiInteraction.loadingInfo
                          : undefined
                      }
                    />
                  )}
                  <AppRouting>{isSchemeLoading && <LoadingOverlay />}</AppRouting>
                </Router>
              </>
            )}
          </>
        </AppContext.Provider>
      </ThemeProvider>
    </ServicesContext.Provider>
  );
};

export interface IAppEvents {
  serverError?: IApiAccessState | undefined;
  showControl?: IShowControlHandler | undefined;
  action?: { schemeId: string; gridId?: string | undefined };
  showError?: string | undefined;
  showMessage?: IUserMessage | undefined;
  hideClientComponent?: boolean | undefined;
  webSocketConnectionLost?: boolean | undefined;
  updateProps?: { id: string; objects?: Entity[] };
}

export default LocalizedApp;
