import { PanelType as OfficePanelType } from "@fluentui/react";
import { LoadingOverlay, useLogger, useTheme } from "cayo.ui";
import React, { FC, Fragment, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { IAlertPanel } from "../../../api/schema.api";
import { NotificationAction } from "../../../services/notification.service";
import { RightPanelType } from "../../App/app.context";
import Badge from "../../Badge";
import ActionButton from "../../Buttons/ActionButton";
import { animationEffects } from "../../Common/Effects";
import { CenteredPanel, Column } from "../../CommonLayout";
import { useContextPanel } from "../../GlobalHooks/context-panel.hook";
import { AlertType, alertDispatcher } from "../AlertDispatcher";
import AlertItem from "../AlertItem";
import { getAlertsPanelStyles, getMenuButtonStyles } from "../Alerts.styles";
import { IAlertItem } from "../alerts.api";
import hightlightedAlerts from "../hightlighted-alerts";
import useAlerts from "../hooks";
import {
  BadgeWrapper,
  BoldText,
  CloseIcon,
  DismissAllAlertsLink,
  ForwardIcon,
  LargeLink,
  RightPanel,
  Row1,
  Row2,
} from "./styles";

type AlertPanelProps = IAlertPanel & {
  panelType: RightPanelType;
  category?: string;
  excludedCategories?: string[];
  icon: string;
};

const getPanelType = (name: string) => {
  let type: RightPanelType;
  switch (name) {
    case "systemalerts":
      type = "systemAlerts";
      break;

    case "changealerts":
      type = "changeAlerts";
      break;

    case "riskassessment":
      type = "riskAssessment";
      break;

    default:
      throw new Error("Invalid panel type");
  }

  return type;
};

const AlertPanel: FC<AlertPanelProps> = (props) => {
  const panelType = getPanelType(props.name!);
  const log = useLogger(panelType);
  const { category, excludedCategories, icon, viewAllAlertsUrl } = props;

  const {
    alertItems,
    isLoading,
    setIsLoading,
    alertsService,
    bagePos: bageProps,
    bageText,
    badgeColor,
    rightPanelType,
    showRightPanel,
    setClickedItem,
    setUpdateTime,
    dismissAlert,
    clickedAlertRef,
  } = useAlerts(panelType as AlertType, props);

  const { hideContextPanel, contextPanelVisible } = useContextPanel();
  const theme = useTheme();
  const history = useHistory();

  useEffect(() => {
    if (contextPanelVisible) {
      showRightPanel(undefined);
    }
  }, [contextPanelVisible]);

  const dismissAll = useCallback(() => {
    setIsLoading(true);

    let dismissAll = excludedCategories
      ? alertsService.dismissAll(excludedCategories, true)
      : alertsService.dismissAll([category!]);

    dismissAll
      .then(() => {
        setUpdateTime(new Date());

        alertDispatcher.send("popupAlerts", {
          action: NotificationAction.Remove,
          newItems: alertItems,
        });
      })
      .catch((e) => {
        log.log("alertsService.dismissAll -> error", e);
      })
      .finally(() => setIsLoading(false));
  }, [alertItems]);

  return (
    <>
      <ActionButton
        id={panelType}
        text=""
        type={"button"}
        onClick={() => {
          hideContextPanel();
          rightPanelType === panelType ? showRightPanel(undefined) : showRightPanel(panelType);
        }}
        styles={getMenuButtonStyles(rightPanelType === panelType)}
        style={{ height: "100%", width: "50px" }}
        icon={{ iconName: icon }}
      />
      {alertItems && alertItems.length > 0 && (
        <BadgeWrapper>
          <Badge fontSize={10} color={badgeColor} {...bageProps}>
            {bageText}
          </Badge>
        </BadgeWrapper>
      )}

      {rightPanelType === panelType && (
        <RightPanel
          styles={getAlertsPanelStyles(theme)}
          onRenderHeader={() => <Fragment />}
          onRenderNavigation={() => (
            <Column>
              <Row1>
                <BoldText variant={"xLarge"}>{props.title}</BoldText>
                <CloseIcon
                  iconProps={{ iconName: "cancel" }}
                  onClick={() => showRightPanel(undefined)}
                />
              </Row1>
              <Row2>
                <LargeLink
                  variant={"xLarge"}
                  onClick={() => {
                    history.push(viewAllAlertsUrl);
                    showRightPanel(undefined);
                  }}
                >
                  <FormattedMessage id="alerts.view-all" defaultMessage="View all alerts" />
                  <ForwardIcon iconName={"Forward"} />
                </LargeLink>
                {props.dismissAllAlertsUrl && (
                  <DismissAllAlertsLink
                    disabled={!alertItems || !alertItems.length}
                    variant={"xLarge"}
                    onClick={dismissAll}
                  >
                    <FormattedMessage id="alerts.dismiss-all" defaultMessage="Dismiss all" />
                  </DismissAllAlertsLink>
                )}
              </Row2>
            </Column>
          )}
          isBlocking={false}
          type={OfficePanelType.custom}
          customWidth={"400px"}
          onDismissed={() => showRightPanel(undefined)}
          isOpen={true}
        >
          {isLoading && <LoadingOverlay />}
          {!alertItems!.length && <CenteredPanel>No alerts found</CenteredPanel>}
          {alertItems!.length > 0 &&
            alertItems!
              .map((a) => a as IAlertItem)
              .map((a, i) => {
                const isClicked = hightlightedAlerts.isClicked(a);
                let c = (
                  <AlertItem
                    {...a}
                    key={a.id}
                    index={i}
                    showDetails={() => setClickedItem(a)}
                    onDismiss={props.dismissAlertsUrl ? () => dismissAlert(a) : null}
                  />
                );
                if (isClicked) {
                  c = (
                    <animationEffects.pulse key={a.id} ref={isClicked ? clickedAlertRef : null}>
                      {c}
                    </animationEffects.pulse>
                  );
                }

                return c;
              })}
        </RightPanel>
      )}
    </>
  );
};

export default AlertPanel;
