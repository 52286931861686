import { IButtonProps } from "@fluentui/react";
import React, { FC, Fragment, memo, useMemo } from "react";
import { Entity } from "../../../api/cayo-graph";
import { IAjaxAction, IFormField, IObjectLink } from "../../../api/schema.api";
import useService from "../../../services/services.hook";
import { useSchemes } from "../../App/hooks";
import ResolvedObjectLink from "./ResolvedObjectLink";
import UnresolvedObjectLink from "./UnresolvedObjectLink";
import useObjectLink from "./hooks";
import { appSignals } from "../../App/utils";

type ObjectLinkProps = Omit<IObjectLink, "type"> & {
  onEdit?: () => void;
  onDelete?: () => void;
  object?: Entity;
};

const ObjectLink: FC<ObjectLinkProps> = (props) => {
  const { name, object, isSoftLink, hideWhenEmpty, commands } = props;

  const { isLoading, navObject, objectName, objIcon } = useObjectLink({ name, object, isSoftLink });
  const { showPropertiesPanel, refreshPropertiesPanel } = useSchemes();
  const actionBuilder = useService("actionBuilder");

  const buttonCommands = useMemo<IButtonProps[] | undefined>(() => {
    return commands?.map<IButtonProps>((c) => ({
      name: c.name,
      iconProps: { iconName: c.icon?.iconName },
      disabled: c.ajaxAction?.method === "DELETE" && !navObject,
      onClick: () => {
        if (c.action) {
          actionBuilder.buildAction(c.action!, { objects: [navObject!] });
        } else if (c.ajaxAction && (navObject || object)) {
          actionBuilder.buildAjaxAction(
            c.ajaxAction as IAjaxAction,
            [navObject || object!],
            undefined,
            undefined,
            () => {
              refreshPropertiesPanel();
              return Promise.resolve();
            }
          );
        }
      },
    }));
  }, [commands, navObject]);

  if (isLoading || !navObject || !objectName) {
    if (hideWhenEmpty && !isLoading) {
      return <Fragment />;
    }
    return (
      <UnresolvedObjectLink
        {...(props as IFormField)}
        isLoading={isLoading}
        commands={buttonCommands}
      />
    );
  }

  return (
    <ResolvedObjectLink
      {...(props as IFormField)}
      navObject={navObject}
      objectName={objectName}
      objIcon={objIcon}
      onClick={() => {
        if (props.name === "savedQuery") {
          return appSignals.send("showSavedQueryForm", { parameters: navObject });
        }
        showPropertiesPanel({
          id: navObject?.objectPath,
          parentId: props.id || props.name,
        });
      }}
      commands={buttonCommands}
    />
  );
};

export default memo(ObjectLink);
