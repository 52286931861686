import { Calendar, DayOfWeek, List, PrimaryButton, Stack } from "@fluentui/react";
import { DropDownPicker, TimePicker, dateTimeUtils, themeUtils, useTheme } from "cayo.ui";
import React, { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { IDateTimeRange, IDropdownOption } from "../../api/schema.api";
import logger from "../../libs/logger";
import { dateRangeConverters } from "../../scheme/converters/date-range-converters";
import { Row } from "../CommonLayout";
import { useDateTime } from "../DateTimeBox/hooks";
import DatePickerStrings from "../DateTimeBox/pickerStrings";
import { IFormComponent } from "../QueryFilterForm/IFormComponent";
import { commonMessages } from "../common-messages";
import { onRenderCell } from "./renderers";
import { classNames } from "./styles";

const log = logger.getLogger("InplaceDateTimeRange");

const InplaceDateTimeRange: FC<IDateTimeRange & IFormComponent> = (props) => {
  const intl = useIntl();
  const { cayoTheme } = useTheme();
  const { brandColors } = cayoTheme;

  const [isCustomInterval, setIsCustomInterval] = useState(true);

  useEffect(() => {
    setIsCustomInterval(props.predefinedRange === "Custom");
  }, [props.predefinedRange]);

  const intervalItems = useMemo<IDropdownOption[] | undefined>(() => {
    const result = props.predefinedRanges!.map<IDropdownOption>((r) => ({
      key: r.value!,
      text: r.name!,
      value: r.value!,
      selected: isCustomInterval ? r.value === "Custom" : r.value === props.predefinedRange,
    }));
    log.debug("props.predefinedRanges, intervalItems -> ", props.predefinedRanges, result);
    return result;
  }, [props.predefinedRange, isCustomInterval]);

  const {
    handleDateChanged: handleBeginDateChanged,
    handleTimeChanged: handleBeginTimeChanged,
    interval: beginInterval,
    setInterval: setBeginInterval,
    time: beginTime,
    date: beginDate,
    timeError: beginTimeError,
  } = useDateTime({
    ...props,
    value: props.begin,
  });

  const {
    handleDateChanged: handleEndDateChanged,
    handleTimeChanged: handleEndTimeChanged,
    interval: endInterval,
    setInterval: setEndInterval,
    time: endTime,
    date: endDate,
    timeError: endTimeError,
  } = useDateTime({
    ...props,
    value: props.end,
  });

  const fullDate = useMemo(
    () =>
      dateTimeUtils.getDisplayDate(beginInterval || props.begin) +
      " - " +
      dateTimeUtils.getDisplayDate(endInterval || props.end),
    [beginInterval, endInterval, props.begin, props.end]
  );

  const title = useMemo(() => {
    if (props.predefinedRange !== "Custom") {
      if (props.predefinedRange === "0" && !props.label) {
        return props.shortName || props.name;
      }
      return props.predefinedRanges?.find((r) => r.value === props.predefinedRange)?.name || "";
    } else {
      return fullDate;
    }
  }, [props.predefinedRange, fullDate]);

  const toggleDropDownPicker = useRef<() => void>();

  return (
    <DropDownPicker
      title={title}
      initToggle={(f) => (toggleDropDownPicker.current = f)}
      calloutPadding={0}
      disabled={props.disabled}
      label={props.label}
      onClose={() => {
        if (props.predefinedRange !== "Custom") {
          setIsCustomInterval(false);
        }
      }}
      isActive={props.predefinedRange !== "0" || !!props.begin || !!props.end}
    >
      <Stack horizontal={true} verticalAlign="start" verticalFill={true}>
        <List
          className={classNames.optionsList}
          items={intervalItems}
          onClick={(event) => {
            const clickedItem = props.predefinedRanges?.find(
              (r) => r.name === (event.target as any).innerText
            )?.value;
            log.debug("List.onClick -> item, clickedItem", event, clickedItem);
            if (!clickedItem) {
              return;
            }

            if (clickedItem !== "Custom") {
              setIsCustomInterval(false);
              setBeginInterval(undefined);
              setEndInterval(undefined);
              if (log.isDebugEnabled()) {
                log.debug(
                  "List.onClick -> setValue -> clickedItem, value",
                  clickedItem,
                  dateRangeConverters.fromPredefinedRange(clickedItem)
                );
              }
              props.setValue(dateRangeConverters.fromPredefinedRange(clickedItem), props);
              toggleDropDownPicker?.current && toggleDropDownPicker.current();
            } else {
              if (!beginInterval) {
                setBeginInterval(dateTimeUtils.getTodayBeginDate());
                setEndInterval(dateTimeUtils.getTodayEndDate());
              }
              setIsCustomInterval(true);
            }
          }}
          onRenderCell={onRenderCell}
        ></List>

        <Stack
          horizontal={false}
          style={{ borderLeft: `1px solid ${brandColors.divider}`, position: "relative" }}
        >
          {!isCustomInterval && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: themeUtils.addAlpha("#FFFFFF", 0.5),
                zIndex: 1,
              }}
            >
              &nbsp;
            </div>
          )}
          <Stack
            horizontal
            verticalAlign={"center"}
            verticalFill
            className={classNames.calendarsWrapper}
          >
            <Stack horizontal={false} horizontalAlign="center" className={classNames.calendar}>
              <Calendar
                // eslint-disable-next-line react/jsx-no-bind
                onSelectDate={handleBeginDateChanged}
                isMonthPickerVisible={false}
                value={beginDate}
                firstDayOfWeek={DayOfWeek.Sunday}
                strings={DatePickerStrings()}
                isDayPickerVisible={true}
                highlightCurrentMonth={true}
                highlightSelectedMonth={true}
                showMonthPickerAsOverlay={true}
                showGoToToday={false}
                allFocusable={true}
              />
              <TimePicker
                setValue={(v) => handleBeginTimeChanged(v, true)}
                value={beginTime}
                defaultValue="00:00"
              />
            </Stack>
            <Stack horizontal={false} horizontalAlign="center" className={classNames.calendar}>
              <Calendar
                // eslint-disable-next-line react/jsx-no-bind
                onSelectDate={handleEndDateChanged}
                isMonthPickerVisible={false}
                value={endDate}
                firstDayOfWeek={DayOfWeek.Sunday}
                strings={DatePickerStrings()}
                isDayPickerVisible={true}
                highlightCurrentMonth={true}
                highlightSelectedMonth={true}
                showMonthPickerAsOverlay={true}
                showGoToToday={false}
              />
              <TimePicker
                setValue={(v) => handleEndTimeChanged(v)}
                value={endTime}
                defaultValue="23:59"
              />
            </Stack>
          </Stack>
          <Row
            style={{
              justifyContent: "space-between",
              padding: 10,
              marginTop: 12,
              borderTop: `1px solid ${brandColors.divider}`,
              alignItems: "center",
            }}
          >
            <div style={{ paddingLeft: 8 }}>{isCustomInterval ? fullDate : <Fragment />}</div>
            <PrimaryButton
              disabled={
                isCustomInterval &&
                (!!beginTimeError ||
                  !!endTimeError ||
                  (beginInterval && endInterval && beginInterval.getTime() > endInterval.getTime()))
              }
              text={intl.formatMessage(commonMessages.select)}
              onClick={() => {
                props.setValue(
                  dateRangeConverters.fromRange(
                    beginInterval || props.begin,
                    endInterval || props.end
                  ),
                  props
                );
                toggleDropDownPicker?.current && toggleDropDownPicker.current();
              }}
            />
          </Row>
        </Stack>
      </Stack>
    </DropDownPicker>
  );
};

export default InplaceDateTimeRange;
