import {
  CheckboxVisibility,
  ConstrainMode,
  ContextualMenu,
  DefaultButton,
  DetailsHeader,
  DetailsList,
  DetailsListLayoutMode,
  IColumnReorderOptions,
  IContextualMenuItem,
  IContextualMenuProps,
  IDetailsGroupRenderProps,
  IDetailsHeaderProps,
  IDetailsListStyles,
  IGroup,
  IObjectWithKey,
  IColumn as IOfficeColumn,
  Link,
  ScrollablePane,
  Selection,
  SelectionMode,
  Sticky,
  StickyPositionType,
} from "@fluentui/react";
import { LoadingOverlay, debounce, useTheme } from "cayo.ui";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Entity, PropertyAnnotation } from "../../api/cayo-graph";
import { IDetailsList } from "../../api/schema.api";
import logger from "../../libs/logger";
import { gridUtils } from "../../utils/grid-utils";
import { objectUtils } from "../../utils/object-utils";
import { useSchemes } from "../App/hooks";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
import { BlankView, EmptyContainer } from "../Widgets/common";
import ContextualMenuItem from "./components/ContextualMenuItem";
import { IQuery } from "./grid-model/Query";
import { DispatchAction } from "./grid-model/grid.controller.actions";
import { useAdjustColumns } from "./hooks/useAdjustColumns";
import renderGridCell from "./renderers/gridCellRenderer";
import { renderRow } from "./renderers/renderRow";

export type GridDetailsProps = {
  propertyAnnotations: PropertyAnnotation[] | undefined;
  columns: Array<IOfficeColumn>;
  ajustColumnsToken: string;
  query: IQuery;
  items: Array<any>;
  groups?: Array<IGroup>;
  itemMenu?: Array<IContextualMenuItem>;
  dispatch: DispatchAction;
  isLoading: boolean;
  schema: IDetailsList;
  gridSettingsKey: string;
  skipToken: string | null;
  selectedItems?: Entity[];
};

const log = logger.getLogger("QUICK-FILTER-NEW GridDetails");

export function GridDetails(props: GridDetailsProps): JSX.Element {
  const intl = useIntl();
  const theme = useTheme();
  const typeAnnotations = useTypeAnnotations();
  const { showPropertiesPanel } = useSchemes();
  const { noItemsText } = props.schema;

  const [contextualMenuProps, setContextualMenuProps] = useState<IContextualMenuProps>();

  const { ref: gridRef, adjustColumns } = useAdjustColumns(props.columns);

  const onItemClicked = useCallback(
    (path?: string, query?: string, item?: any) => {
      log.debug("onItemClicked -> path, query, item", path, query, item);

      showPropertiesPanel({ id: item, parentId: props.schema?.id });

      return Promise.resolve();
    },
    [props.schema?.id]
  );

  function onColumnHeaderClick(_?: React.MouseEvent<HTMLElement>, column?: IOfficeColumn) {
    if (!column) {
      return;
    }
    log.debug("onColumnHeaderClick.sort -> column", column);
    props.dispatch({
      kind: "sort",
      orderBy: [column.fieldName!, !column.isSorted ? true : !!column.isSortedDescending],
    });
  }

  const handleColumnReorder = (fromIndex: number, toIndex: number) => {
    const fields = props.columns.map((c) => c.fieldName!);
    log.debug("handleColumnReorder -> fromIndex, toIndex, fields", fromIndex, toIndex, [...fields]);
    const draggedField = fields[fromIndex];
    // insert before the dropped item
    fields.splice(fromIndex, 1);
    fields.splice(toIndex, 0, draggedField);
    log.debug("handleColumnReorder -> reordered fields", [...fields]);
    props.dispatch({ kind: "applyColumns", columns: fields });
  };

  const getColumnReorderOptions = (): IColumnReorderOptions => {
    return { handleColumnReorder };
  };

  function onItemClick(item?: any) {
    props.dispatch({
      kind: "showProps",
      item,
      parentId: props.schema.id!,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onClose: (updateItem?: Entity) => {
        // TODO:  if (updateItem?.objectType !== item?.objectType) {
        if (props.schema.notificationMode !== "off") {
          props.dispatch({ kind: "refresh" });
        }
        // }
      },
    });
  }

  function onColumnResize(column?: IOfficeColumn, newWidth?: number) {
    const existingColumn = props.columns.find((cc) => cc.fieldName === column?.fieldName);
    if (!!existingColumn) {
      existingColumn.currentWidth = existingColumn.calculatedWidth = newWidth;
      gridUtils.saveGridColumns(props.gridSettingsKey, props.columns); //TODO: move to controller
    }
  }

  useEffect(function mount() {
    log.debug(".mount", props);
  }, []);

  useEffect(
    function ajustColumns() {
      log.debug("usf.resetColumns -> props.resetColumnsToken", props.ajustColumnsToken);
      if (props.ajustColumnsToken.length === 0 || props.items.length === 0) return;

      debounce(
        () =>
          adjustColumns(
            (adjustedColumns) => {
              log.debug(
                "usf.resetColumns -> adjustColumns.onAjusted -> adjustedColumns",
                adjustedColumns
              );
              gridUtils.saveGridColumns(props.gridSettingsKey, adjustedColumns);
            },
            (value) => log.debug("usf.resetColumns -> adjustColumns.onLoading -> value", value)
          ),
        100
      )();
    },
    [props.ajustColumnsToken]
  );
  useEffect(
    function clearSelected() {
      log.debug("usf.clearSelected -> props.selectedItems.length", props.selectedItems?.length);
      if (!props.selectedItems?.length) {
        selection.setAllSelected(false);
      }
    },
    [props.selectedItems?.length]
  );

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          const selectedItems = selection.getSelection();
          log.debug("selection.onSelectionChanged -> ", selectedItems);
          props.dispatch({ kind: "changedSelectedItems", items: selectedItems });
          if (props.schema?.invokeItemByOneClick && selectedItems.length === 1) {
            onItemClick(selectedItems[0]);
          }
        },
        getKey: (item: IObjectWithKey) => (item as any)["id"],
      }),
    []
  );

  const blankViewCmp = useMemo(() => {
    if (!props.isLoading && noItemsText) {
      return <EmptyContainer>{noItemsText}</EmptyContainer>;
    }

    return (
      <BlankView style={{ fontSize: 14 }}>
        {props.query.conditions.length ? (
          <div>
            <FormattedMessage
              id="details-list.no-found"
              defaultMessage="No objects were found. Would you like to "
            />
            <Link
              onClick={() => {
                props.dispatch({ kind: "clearQuery" });
              }}
            >
              <FormattedMessage id="details-list.reset-filter" defaultMessage="reset query" />
            </Link>
            ?
          </div>
        ) : (
          !props.isLoading && (
            <FormattedMessage id="details-list.no-items" defaultMessage="This page is empty" />
          )
        )}
      </BlankView>
    );
  }, [props.dispatch, props.isLoading]);

  const onItemContextMenu = useCallback(
    (item?: any, index?: number, ev?: Event): boolean => {
      if ((ev as MouseEvent)?.ctrlKey) {
        return true;
      }

      const menuItems = objectUtils.deepClone(props?.itemMenu);

      const contextualMenuProps: IContextualMenuProps = {
        target: ev?.target as HTMLElement,
        items:
          menuItems?.filter((item) => {
            const hasNoButtonStyles = !item.buttonStyles;
            const hasMultiselectOrDependsOnSelection =
              item.data.multiselect !== undefined || item.data.dependsOnSelection;
            const isNotDisabled = !item.disabled;

            if (hasNoButtonStyles && hasMultiselectOrDependsOnSelection && isNotDisabled) {
              item.onRender = (item) => (
                <ContextualMenuItem
                  item={item}
                  afterClickCB={() => setContextualMenuProps(undefined)}
                />
              );
            }

            return hasNoButtonStyles && hasMultiselectOrDependsOnSelection && isNotDisabled;
          }) || [],
        onDismiss: () => {
          setContextualMenuProps(undefined);
        },
      };

      if (typeof index === "number" && index > -1) {
        setContextualMenuProps(contextualMenuProps);
      }

      return false;
    },
    [props?.itemMenu]
  );

  function renderCell(item: any, index: number | undefined, column: IOfficeColumn | undefined) {
    return renderGridCell(item, index!, column, onItemClicked, {
      intl,
      theme,
      getTypeAnnotation: typeAnnotations.getTypeAnnotation,
      getIconAnnotation: typeAnnotations.getIconAnnotation,
      propertyAnnotations: props.propertyAnnotations,
      additionalProps: { invokeItemByOneClick: props.schema.invokeItemByOneClick },
    });
  }
  return (
    <>
      {props.isLoading && <LoadingOverlay />}
      {props.items.length !== 0 && (
        <ScrollablePane
          scrollbarVisibility="auto"
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            overflow: "auto hidden",
            flexGrow: 1,
          }}
        >
          <DetailsList
            setKey="id"
            columns={props.columns}
            items={props.items}
            groups={props.groups}
            selection={selection}
            groupProps={groupProps}
            columnReorderOptions={getColumnReorderOptions()}
            onItemContextMenu={onItemContextMenu}
            compact={props?.schema?.compact}
            componentRef={gridRef}
            selectionMode={SelectionMode[props.schema?.selectionMode] || SelectionMode.multiple}
            checkboxVisibility={
              props?.schema?.compact ? CheckboxVisibility.hidden : CheckboxVisibility.onHover
            }
            constrainMode={ConstrainMode.unconstrained}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            styles={detailsListStyles(props.schema.compact, props.schema.invokeItemByOneClick)}
            onColumnHeaderClick={onColumnHeaderClick}
            onItemInvoked={onItemClick}
            onRenderDetailsHeader={onRenderDetailsHeader}
            onRenderRow={(p, r) => renderRow(p!, r!, theme.cayoTheme)}
            onRenderItemColumn={renderCell}
            onRenderDetailsFooter={() => {
              if (!props.skipToken || props.schema.noPumping) return null;

              return (
                <DefaultButton
                  style={{ width: "100%", border: "none", position: "absolute", bottom: 0 }}
                  onClick={() => props.dispatch({ kind: "loadMore" })}
                >
                  <Link
                    style={{
                      fontSize: 13,
                      textDecoration: "underline",
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                      pointerEvents: "none",
                    }}
                  >
                    <FormattedMessage
                      id="details-list.footer.get-more"
                      defaultMessage="Load more"
                    />
                  </Link>
                </DefaultButton>
              );
            }}
            onColumnResize={onColumnResize}
          ></DetailsList>
          {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
        </ScrollablePane>
      )}
      {props.items.length === 0 && blankViewCmp}
    </>
  );
}

const groupProps: IDetailsGroupRenderProps = {
  headerProps: {
    styles: {
      title: { fontWeight: 600, fontSize: 12 },
      groupHeaderContainer: { background: "rgba(37, 198, 255, 0.2)", height: 38 },
      expand: { height: 38 },
    },
  },
};

const onRenderDetailsHeader = (
  propsHeader?: IDetailsHeaderProps | undefined // ,  defaultRender?: IRenderFunction<IDetailsHeaderProps>
): JSX.Element => {
  const newPropsHeader = { ...propsHeader };

  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      <DetailsHeader
        styles={{
          root: {
            paddingTop: 0,
            fontSize: "12px !important",
            selectors: {
              ".ms-DetailsHeader-cellName": {
                fontSize: 12,
              },
              ".ms-DetailsHeader-filterChevron": {
                display: "none",
              },
              ".ms-DetailsRow-check": {
                width: "32px !important",
              },
              ".ms-DetailsHeader-cellIsCheck": {
                width: "32px !important",
              },
            },
          },
        }}
        {...newPropsHeader}
        layoutMode={propsHeader?.layoutMode || DetailsListLayoutMode.fixedColumns}
      />
    </Sticky>
  );
};

const detailsListStyles = (
  compact?: boolean,
  invokeItemByOneClick?: boolean
): Partial<IDetailsListStyles> => {
  const paddingTop = compact ? "8px !important" : "12px !important";
  const paddingBottom = compact ? "0px !important" : "8px !important";
  return {
    root: {
      paddingTop: "0 !important",
      selectors: {
        ".ms-DetailsHeader-filterChevron": {
          display: "none",
        },
        ".ms-DetailsRow-check": {
          width: "32px !important",
        },

        ".ms-DetailsHeader-cellIsCheck": {
          width: "32px !important",
        },
        ".ms-DetailsRow-fields": {
          display: "block",
          cursor: invokeItemByOneClick ? "pointer" : undefined,
          selectors: {
            ".ms-DetailsRow-cell": {
              paddingTop: paddingTop,
              paddingBottom: paddingBottom,
              paddingLeft: 8,
              paddingRight: 8,
              height: "100%",
            },
          },
        },
      },
    },

    contentWrapper: {
      selectors: {
        ".ms-DetailsRow": {
          width: "100%",
          selectors: {},
        },
      },
    },

    headerWrapper: {
      fontSize: "8px",
      selectors: {
        ".ms-DetailsHeader-cell": {
          selectors: {
            ":hover": {
              border: "1px solid rgba(0, 0, 0, 0.2)",
            },
          },
        },
        ".ms-DetailsHeader-cellSizer": {
          selectors: {
            ":after": { width: 3 },
          },
        },
        ".ms-DetailsHeader": {
          paddingTop: 0,
          width: "100%",
        },
      },
    },
  };
};

// function setSort(columns: IOfficeColumn[], fieldName: string, asc: boolean) {
//   log.debug("setSort -> fieldName, asc", fieldName, asc);
//   columns.forEach((c) => {
//     const cc = c as unknown as IColumn;
//     if ((cc?.sortingFieldName ?? c.fieldName) === fieldName) {
//       c.isSorted = true;
//       c.isSortedDescending = !asc;
//     } else {
//       c.isSorted = false;
//       c.isSortedDescending = undefined;
//     }
//   });
// }
