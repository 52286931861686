import { MessageBarTypeExtended } from "cayo.ui";
import { Severity } from "../../../api/cayo-graph";

const severityToMessageBarType = (s?: Severity): MessageBarTypeExtended => {
  let messageBarType: MessageBarTypeExtended = "info";
  switch (s) {
    case "high":
    case "critical":
    case "error":
      messageBarType = "error";
      break;

    case "warning":
    case "medium":
      messageBarType = "warning";
      break;

    case "success":
      messageBarType = "success";
      break;

    default:
      messageBarType = "info";
      break;
  }

  return messageBarType;
};

export default severityToMessageBarType;
