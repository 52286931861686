import React, { FC, useCallback, useMemo, useState } from "react";
import RGL, { Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { IGridLayout } from "../../api/schema.api";
import { ILayoutHierarchy } from "../Schemes/renderers/renderers.layout";
import { dataSetWidgetSignals } from "../Widgets/dataset-signals.hook";
import GridCell from "./GridCell";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export interface IResizableGrid extends IGridLayout {
  onDeleteCell?: (key: string) => void;
  onLayoutChange?: (layouts: Layouts) => Promise<void>;
  onBreakPointChange?: (newBreakpoint: string, newCols: number) => void;
  cells: ILayoutHierarchy[];
}

const Grid: FC<IResizableGrid> = (props) => {
  const layouts = useMemo(() => props.sizes as Layouts, [props.items, props.sizes]);

  const [currentBreakpoint, setCurrentBreakpoint] = useState("");

  const onLayoutChange = (newlayout: RGL.Layout[], layouts: Layouts) => {
    props.onLayoutChange && props.onLayoutChange(layouts);
  };

  const onDeleteGridItem = useCallback(
    (itemKey: string) => {
      props.onDeleteCell && props.onDeleteCell(itemKey);
    },
    [layouts]
  );

  const generateDOM = useCallback(() => {
    const l = layouts["lg"];

    return props!.cells!.map((item, i) => {
      const width = layouts[currentBreakpoint]?.find((l) => l.i === item.name!)?.w ?? "";
      const height = layouts[currentBreakpoint]?.find((l) => l.i === item.name!)?.h ?? "";

      return (
        <div key={item.name!} data-grid={l[item.name!]}>
          <GridCell
            key={i?.toString() + width + height}
            name={item.name}
            render={item.render}
            onDelete={onDeleteGridItem}
          />
        </div>
      );
    });
  }, [layouts, currentBreakpoint, onDeleteGridItem, props?.items?.length]);

  return (
    <>
      <ResponsiveReactGridLayout
        layouts={layouts}
        isBounded={false}
        {...props}
        cols={{ lg: 12, md: 10, sm: 6, xs: 3, xxs: 1 }}
        onBreakpointChange={(newBreakpoint: string, newCols: number) => {
          setCurrentBreakpoint(newBreakpoint);
          props.onBreakPointChange && props.onBreakPointChange(newBreakpoint, newCols);
        }}
        // rowHeight={100}
        onLayoutChange={onLayoutChange}
        style={{
          display: "flex",
          flexGrow: 1,
          height: "100%",
          width: "100%",
        }}
        autoSize={true}
        onResizeStop={() => {
          dataSetWidgetSignals.send("refresh");
          setTimeout(() => window.dispatchEvent(new Event("resize")), 10);
        }}
      >
        {generateDOM()}
      </ResponsiveReactGridLayout>
    </>
  );
};

export default Grid;
