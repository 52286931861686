import { ChoiceGroup, IChoiceGroupOption, Stack, Text } from "@fluentui/react";
import { ITheme, useTheme } from "cayo.ui";
import React, { FC, useCallback, useMemo } from "react";
import styled from "styled-components";
import { EditModes } from "./types";

type EditModeSwitchProps = { mode: EditModes; setMode: (mode: EditModes) => void };

const EditModeSwitch: FC<EditModeSwitchProps> = ({ setMode, mode }) => {
  const { cayoTheme } = useTheme();
  const { brandColors } = cayoTheme;

  const renderLabel = useCallback((o: IChoiceGroupOption, color: string) => {
    return (
      <Stack
        horizontal={true}
        verticalFill={true}
        verticalAlign={"center"}
        style={{ margin: "0 0 0 28px" }}
      >
        <Square style={{ backgroundColor: color, marginRight: 4 }} />
        <Text variant={"small"}>{o.text}</Text>
      </Stack>
    );
  }, []);

  const options = useMemo<IChoiceGroupOption[]>(() => {
    return [
      {
        key: "allowed",
        text: "Allowed",
        value: "allowed",

        onRenderLabel: (p) => renderLabel(p!, brandColors.primaryHighlight),
      },
      {
        key: "blocked",
        text: "Blocked",
        value: "blocked",
        onRenderLabel: (p) => renderLabel(p!, brandColors.primaryBackground),
        styles: { root: { marginLeft: 8 } },
      },
    ];
  }, []);

  return (
    <ChoiceGroup
      styles={{
        flexContainer: { display: "flex", width: "100%", justifyContent: "center" },
        root: {
          selectors: {
            ".ms-ChoiceFieldLabel": { fontSize: 13 },
            ".ms-ChoiceField-field": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
        },
      }}
      options={options}
      defaultSelectedKey={mode}
      onChange={(ev, o) => setMode(o!.key! as EditModes)}
    />
  );
};

const Square = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => (props.theme as ITheme).cayoTheme.brandColors.divider};
`;

export default EditModeSwitch;
