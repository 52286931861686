import { OdataQuery } from "odata";
import { IDetailsList } from "../api/schema.api";

const defaultPageSize = 100;

const getQuery = (list?: IDetailsList) => {
  if (!list?.sortingOptions) {
    return undefined;
  }

  const orderBy =
    list?.sortingOptions?.fieldName +
    " " +
    (list?.sortingOptions?.isSortedDescending ? "desc" : "asc");

  const query: OdataQuery = {
    $select: list?.columns?.map((c) => c.fieldName).join(","),
    $orderby: orderBy,
    $top: list?.pageSize || defaultPageSize,
  };

  return query;
};

const getQueryParam = (name: string, query?: string | undefined) => {
  const realQuery = query || window.location.hash;
  const pos = realQuery.indexOf("?");
  const queryParams = new URLSearchParams(realQuery.substring(pos));
  return queryParams.get(name);
};

const queryUtils = { getQuery, getQueryParam };

export default queryUtils;
