import { debounce } from "cayo.ui";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { commonMessages } from "../../common-messages";

type messageType = "error" | "success";

export type messageProps = { type: messageType; message?: string } | undefined;

const successMessages: { [id: string]: messageProps } = {};

const successMessageDisappearTimeout = 3000;

const useFormMessageBar = (formId: string) => {
  const intl = useIntl();
  const [message, _setMessage] = useState<messageProps>(successMessages[formId]);

  useEffect(() => {
    if (message?.type === "success") {
      debounce(() => {
        delete successMessages[formId];
        _setMessage(undefined);
      }, successMessageDisappearTimeout)();
    }

    return () => {
      debounce(() => {
        delete successMessages[formId];
      }, successMessageDisappearTimeout)();
    };
  }, [message?.message, formId]);

  const setMessage = useCallback(
    (msg: messageProps) => {
      if (msg?.type === "success") {
        msg.message =
          msg.message ||
          intl.formatMessage(commonMessages.propertiesUpdated, { objectName: formId });

        Object.keys(successMessages).forEach((k) => delete successMessages[k]);
        successMessages[formId] = msg;
      }
      _setMessage(msg);
    },
    [_setMessage]
  );

  return { message, setMessage };
};

export default useFormMessageBar;
