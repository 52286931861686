import { dateTimeUtils } from "cayo.ui";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { IDateTimeRange } from "../../api/schema.api";
import { IFormComponent } from "../QueryFilterForm/IFormComponent";
import { commonMessages } from "../common-messages";

export const useDateTime = (
  props: IFormComponent & IDateTimeRange & { value?: Date | string | undefined }
) => {
  const intl = useIntl();

  const [date, setDate] = useState<Date | undefined>(undefined);
  const [time, setTime] = useState<string | undefined>(undefined);
  const [interval, setInterval] = useState<Date | undefined>(undefined);

  useEffect(() => {
    setDate(dateTimeUtils.initDate(props.value));
    setTime(
      (props.value as string)?.length === 5
        ? (props.value as string)
        : dateTimeUtils.getTimeFromDate(dateTimeUtils.initDate(props.value))
    );
  }, [props.value]);

  const [timeError, setTimeError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const newDate = date && new Date(date);
    const newTime = dateTimeUtils.parseTime(time)?.value;

    const predefinedRange = Number.parseInt(props.predefinedRange || "0") || 0;
    if (newTime && newDate) {
      newDate.setHours(newTime!.getHours());
      newDate.setMinutes(newTime!.getMinutes());
      newDate.setSeconds(newTime!.getSeconds());

      setInterval(newDate);
    } else if (predefinedRange < 1) {
      setInterval(undefined);
    }
  }, [date, time]);

  const handleTimeChanged = (value: string | undefined, validate?: boolean | undefined) => {
    if (!validate) {
      setTime(value);
      return;
    }

    const parseResult = dateTimeUtils.parseTime(value);
    const newTime = parseResult?.value && value;
    if (newTime) {
      setTime(dateTimeUtils.getTimeFromDate(parseResult?.value));
      setTimeError(undefined);
      props.setError(props.name!, undefined);
    } else {
      setTime(value);

      const error = parseResult.isFormatInvalid
        ? intl.formatMessage(commonMessages.matchFormat)
        : intl.formatMessage(commonMessages.inputRequired);

      setTimeError(error);

      props.setError(error);
    }
  };

  const handleDateChanged = (date: Date | undefined | null) => {
    if (date) {
      setDate(date);
    }
  };

  return {
    interval,
    setInterval,
    date,
    time,
    handleDateChanged,
    handleTimeChanged,
    timeError,
  };
};
