import { debounce, LoadingOverlay } from "cayo.ui";
import React, { FC, Fragment, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import logger from "../../libs/logger";
import { ServerError } from "../App/types";
import { useServerState } from "./hook";
import ServerUnavailable from "./ServerUnavailable";

interface IServerStateComponent {
  serverError?: ServerError | undefined | null;
  onServerUnavailable: (error: ServerError | null | undefined) => void;
}

const restartServiceTimeout = 60000; // 1 min

const ServerStateComponent: FC<IServerStateComponent> = ({
  serverError: serverUnavailableError,
  onServerUnavailable,
}) => {
  const intl = useIntl();

  const { isLoading, healthCheckError, setServiceState, runHealthCheck } = useServerState();

  useEffect(() => {
    log.debug("health check error", healthCheckError);

    switch (healthCheckError?.state) {
      case "restarting":
        debounce(() => {
          setServiceState("stopped");
        }, restartServiceTimeout)();

        const error = healthCheckError?.message
          ? healthCheckError
          : ({
              message: intl.formatMessage(appStateMessages.serverRestarting),
              state: "restarting",
            } as ServerError);

        onServerUnavailable(error);
        break;

      case "stopped":
        onServerUnavailable({ message: "", state: "stopped" });
        break;
      case "stopping":
        {
          const error: ServerError = healthCheckError?.message
            ? healthCheckError
            : { message: intl.formatMessage(appStateMessages.serverStopping), state: "stopping" };

          onServerUnavailable(error);
        }
        break;

      case "starting":
        {
          const error: ServerError = healthCheckError?.message
            ? healthCheckError
            : { message: intl.formatMessage(appStateMessages.serverStarting), state: "starting" };

          onServerUnavailable(error);
        }
        return;

      case "started":
        if (serverUnavailableError && healthCheckError?.operatingMode === "normal") {
          log.debug("reload page, server started");
          document.location.reload();
        }

        if (healthCheckError?.operatingMode !== "normal") {
          const error: ServerError = healthCheckError?.message
            ? healthCheckError
            : {
                message: intl.formatMessage(appStateMessages.serverNotOperationalMode),
                state: "none",
                operatingMode: healthCheckError?.operatingMode,
              };

          onServerUnavailable(error);
        } else {
          onServerUnavailable(null);
        }
        break;

      case "failed":
        {
          const error: ServerError = healthCheckError?.message
            ? healthCheckError
            : {
                message: intl.formatMessage(appStateMessages.serverFailed),
                isCritical: true,
                state: "failed",
              };

          onServerUnavailable(error);
        }
        break;
      default:
        //   onRetry();
        break;
    }
  }, [
    healthCheckError?.message,
    healthCheckError?.description,
    healthCheckError?.state,
    healthCheckError?.operatingMode,
  ]);

  useEffect(() => {
    onServerUnavailable(healthCheckError);
  }, [healthCheckError]);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return serverUnavailableError ? (
    <ServerUnavailable
      key={serverUnavailableError?.message ?? "ServerUnavailable"}
      error={serverUnavailableError}
      onRetry={runHealthCheck}
    />
  ) : (
    <Fragment />
  );
};

const appStateMessages = defineMessages({
  serverRestarting: {
    defaultMessage: "The service is being restarted, please wait.",
    id: "app-state-messages.server-is-being-restarted",
  },
  serverStarting: {
    defaultMessage: "The service is being started, please wait.",
    id: "app-state-messages.server-is-being-started",
  },
  serverStopping: {
    defaultMessage: "The service is being stopped, please wait.",
    id: "app-state-messages.server-is-being-stopped",
  },
  serverFailed: {
    defaultMessage: "Service failed to start.",
    id: "app-state-messages.server-failed-to-start",
  },

  serverNotOperationalMode: {
    defaultMessage: "The service is currently not operational.",
    id: "app-state-messages.server-not-operational",
  },
});

const log = logger.getLogger("ServerStateComponent");

export default ServerStateComponent;
