import { IColumn as IOfficeColumn, IStackTokens, Stack } from "@fluentui/react";
import { Html, clipboardUtils, stringUtils } from "cayo.ui";
import React, { Fragment } from "react";
import styled from "styled-components";
import logger from "../../libs/logger";
import { objectUtils } from "../../utils/object-utils";
import LabeledContainer from "../Common/LabeledContainer";
import LinkedObject from "../GridContainer/components/LinkedObject";
import { oDataTypes } from "../ObjectPicker/odata-converters";
import { AuxRenderProps, IActionPromise } from "./cell-renderers";

const suffixes = {
  displayName: "@cayo.schema.displayName",
  type: "@odata.type",
  position: "@cayo.schema.propertiesFormPosition",
};

type PropNames = keyof typeof suffixes;

const getOdataProp = (prop: string, suffix: PropNames) => {
  return `${prop}${suffixes[suffix]}`;
};

const renderLabeledObject = (
  o: any,
  label: string,
  key: string,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  return (
    <LabeledContainer
      key={key}
      label={label}
      labelSize={"small"}
      onCopy={() =>
        clipboardUtils.copyToClipboard(Array.isArray(o) ? (o as []).join("\n") : (o as string))
      }
    >
      {renderObject(o, onItemClicked, auxProps)}
    </LabeledContainer>
  );
};

const renderObject = (
  o: any,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  if (Array.isArray(o)) {
    return renderArray(o, onItemClicked, auxProps);
  }

  if (objectUtils.isPrimitive(o)) {
    return <Html value={o} noWrap={true} truncated={true} />;
  }

  return <Fragment />;
};

const renderArray = (
  o: any[],
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  return <Fragment>{o.map((oo) => renderObject(oo, onItemClicked, auxProps))}</Fragment>;
};

const odataCell = (
  item: any,
  index: number,
  column: IOfficeColumn,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  logger.debug(item);

  const obj = item[column.fieldName!];
  if (!obj) {
    return <Fragment />;
  }
  return odataObject(index.toString(), obj, onItemClicked, auxProps);
};

const odataObject = (
  parentName: string,
  obj: any,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps,
  tokens?: IStackTokens
) => {
  if (!obj) {
    return <Fragment />;
  }

  const props = Object.keys(obj)
    .filter((k) => k.indexOf("@") === -1)
    .sort((a, b) => {
      const posA = getOdataProp(a, "position");
      const posB = getOdataProp(b, "position");

      return (obj[posA] ?? 0) - (obj[posB] ?? 0);
    });

  const result = props.map((p, i) => {
    const type = getOdataProp(p, "type");
    const displayNameProp = getOdataProp(p, "displayName");
    const displayName = obj[displayNameProp] ?? stringUtils.toSpaceDelimitedSentenceCase(p);
    const isSoftLink = type ? obj[type] === oDataTypes.softLink : false;

    let comp: any;

    logger.debug("wellknown", obj[p]);
    const key = p + parentName + i;
    const isArray = objectUtils.isArray(obj[p]);
    if (isArray) {
      const nestedObj = obj[p] as any[];
      return renderLabeledObject(nestedObj, displayName, key, onItemClicked, auxProps);
    } else if (isSoftLink) {
      comp = (
        <LinkedObject
          key={key}
          {...obj[p]}
          onItemClicked={onItemClicked!}
          getIconAnnotation={auxProps?.getIconAnnotation!}
        />
      );
    } else {
      comp = renderObject(obj[p], onItemClicked, auxProps);
    }

    return (
      <LabeledContainer key={key} label={displayName} labelSize={"small"}>
        {comp}
      </LabeledContainer>
    );
  });
  return <OdataContainer tokens={tokens}>{result}</OdataContainer>;
};

const OdataContainer = styled(Stack)``;

const odataRenderers = {
  odataCell,
  odataObject,
};
export default odataRenderers;
