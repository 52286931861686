import { Pivot as OfficePivot, PivotItem, Stack } from "@fluentui/react";
import React, { FC, Fragment, useCallback, useMemo, useState } from "react";
import { Entity, PropertyAnnotation } from "../../../api/cayo-graph";
import { HttpMethods, IPivot, IPivotItem } from "../../../api/schema.api";
import { IPropertiesPanelSignals } from "../../../services/properties-panel-signal.service";
import { appSettings } from "../../../settings/app-settings";
//import globals from "../../../settings/globals";
import MessageCarousel from "../../Alerts/MessageCarousel";
import globalConstants from "../../App/constants";
import odataAnnotationsUtils from "../../Form/odata-annotations.utils";
import ODataScheme from "../../Schemes/ODataScheme";
import SchemeLoader from "../../Schemes/SchemeLoader";
import { allRendereres } from "../../Schemes/renderers";

type PivotProps = Omit<IPivot, "type"> & {
  object: Entity;
  signals?: IPropertiesPanelSignals;
  propertyAnnotations?: PropertyAnnotation[];
  parentContainerName?: string;
};

export type TabEx = IPivotItem & {
  isDataset: boolean;
  toRender?: boolean;
};

const isOdataScheme = (item: IPivotItem) => {
  const isFormOrCustomControl =
    item?.schemePath?.toLowerCase()?.indexOf("propertyset") > 0 ||
    item?.schemePath?.toLowerCase()?.indexOf("custom/") === 0;

  return !isFormOrCustomControl;
};

const findSelectedTabIndex = (tabs: TabEx[], selectedItem?: string) => {
  let index = (tabs.length && tabs && tabs.findIndex((i) => i.headerText === selectedItem)) || 0;
  index = Math.max(index, 0);

  return index;
};

const Pivot: FC<PivotProps> = (props) => {
  const { signals } = props;
  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    appSettings.panelSetting.get()?.selectedTabKey
  );

  const tabs = useMemo<TabEx[]>(() => {
    let result = props.items
      ?.filter((i) => i.customView?.toLowerCase() !== MessageCarouselView)
      .map<TabEx>((i) => ({ ...i, isDataset: !isOdataScheme(i) }));

    result.push(...odataAnnotationsUtils.toTabs(props.object));

    result = result.sort((a, b) => a.order - b.order);

    const index = result?.length && findSelectedTabIndex(result, selectedItem);
    if (index >= 0) {
      result[index].toRender = true;
      setSelectedItem(result[index].headerText);
    }

    return result;
  }, [props.items]);

  const auxProps = useMemo(
    () => ({
      object: props?.object,
      signals,
      propertyAnnotations: props?.propertyAnnotations,
    }),
    [props?.object, signals, props?.propertyAnnotations, props?.parentContainerName]
  );

  const handleItemClick = useCallback(
    (pivotItem?: PivotItem): void => {
      const headerText = pivotItem?.props?.headerText;

      const clickedTab = tabs.find((t) => t.headerText === headerText);
      if (clickedTab) {
        clickedTab.toRender = true;
      }

      appSettings.panelSetting.update({ selectedTabKey: headerText });

      setSelectedItem(headerText);
    },
    [tabs]
  );

  const customControlsProps = useMemo(() => {
    const customViewTabs = tabs?.filter((t) => t.customView);
    const result = {};

    customViewTabs?.forEach((t) => {
      const renderer = allRendereres[t.customView!.toLowerCase()];
      const prop = t.schemePath.substring(t.schemePath.lastIndexOf("/") + 1);
      const url = "v0/" + t.schemePath.substring(0, t.schemePath.lastIndexOf("/"));

      result[t.schemePath] = {
        renderer,
        props: {
          key: props.object.objectPath,
          rootUrl: "v0/" + t.schemePath,
          url,
          method: "PATCH" as HttpMethods,
          parameters: t.parameters,
          isCustomView: true,
          object: props.object[prop],
          parentObjectName: props.object.objectName,
          parentContainerName: t.headerText,
          style: {
            display: selectedItem === t.headerText ? "flex" : "none",
            height: "100%",
          },
        },
      };
    });
    return result;
  }, [tabs, selectedItem, props.object]);

  const generateContent = useCallback(() => {
    return tabs.map((item) => {
      if (item.customView) {
        const tabProps = customControlsProps[item.schemePath];

        if (tabProps?.renderer) {
          const C = tabProps.renderer;
          return <C key={tabProps.props.key} {...tabProps.props} />;
        }
      }

      if (!item.toRender) {
        return <Fragment key={`${props.id}_${item.headerText}_fragment`} />;
      }

      return (
        <div
          key={`${props.id}_${item.headerText}_pc`}
          style={{
            display: selectedItem === item.headerText ? "flex" : "none",
            height: "100%",
            paddingTop: 0,
            flexFlow: "column",
          }}
        >
          {item.isDataset ? (
            <SchemeLoader
              schemePath={item.schemePath}
              auxProps={{ ...auxProps, parentContainerName: item.headerText }}
            />
          ) : (
            <ODataScheme schemePath={item.schemePath} />
          )}
        </div>
      );
    });
  }, [props?.object, tabs, selectedItem, customControlsProps]);

  const lastVerificationMessagesUrl = useMemo(() => {
    const lastVerificationTab = props.items?.find(
      (i) => i.customView?.toLowerCase() === MessageCarouselView
    );
    if (lastVerificationTab) {
      let url = globalConstants.apiVersion + lastVerificationTab.schemePath;
      return url;
    }

    return undefined;
  }, []);

  return (
    //
    <Stack style={{ height: "100%" }}>
      {lastVerificationMessagesUrl && <MessageCarousel url={lastVerificationMessagesUrl} />}
      {tabs.length > 1 && (
        <OfficePivot
          style={{ paddingLeft: "8px", paddingRight: "8px" }}
          selectedKey={selectedItem}
          overflowBehavior={"menu"}
          onLinkClick={handleItemClick}
          headersOnly={true}
        >
          {tabs?.map((t) => (
            <PivotItem
              key={`${t!.headerText}`}
              headerText={t.headerText}
              itemKey={`${t!.headerText}`}
            />
          ))}
        </OfficePivot>
      )}
      <div
        style={{
          position: "relative",
          height: "100%",
          display: "flex",
          flexFlow: "column",
          overflow: "hidden",
        }}
      >
        {generateContent()}
      </div>
    </Stack>
  );
};

const MessageCarouselView = "messagecarousel";

export default Pivot;
