import { oClient } from "../../libs/odata.client";

export class HealthCheckClient implements IHealthCheckClient {
  constructor(
    baseUrl?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
  ) {
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  private readonly baseUrl: string;

  public getReadyStatus(): Promise<IHealthCheckResponse | null> {
    return oClient(this.baseUrl)
      .get("health/ready")
      .query()
      .then((response: IHealthCheckResponse) => {
        return response;
      });
  }
}
interface IServiceInfo {
  uiScheme?: string | undefined;
  certificateError?: string | undefined;
  apiState?: IApiAccessState | undefined;
}

export interface IApiAccessState {
  isAccessible?: boolean;
  serverVersion?: string | undefined;
  state: ApplicationState;
  operatingMode?: ApplicationOperatingMode;
  message?: string | undefined;
  description?: string | undefined;
}

export type ApplicationState =
  | "none"
  | "starting"
  | "started"
  | "stopping"
  | "stopped"
  | "restarting"
  | "failed";

export type ApplicationOperatingMode =
  | "normal"
  | "maintenance"
  | "migration"
  | "upgrade"
  | "shutdown";

const nonOperableModes = [
  "maintenance",
  "migration",
  "upgrade",
  "shutdown",
] as ApplicationOperatingMode[];

interface IServiceStatus {
  data: IServiceInfo;
  description: string;
  status: string;
}

interface IDatabaseHealthCheckData {
  version: number;
  size: number;
}

interface IDatabaseStatus {
  data: IDatabaseHealthCheckData;
  description: string;
  status: string;
}

interface IHealthCheckResults {
  database: IDatabaseStatus;
  service: IServiceStatus;
}

interface IHealthCheckResponse {
  results: IHealthCheckResults;
  status: string;
}

export type IHealthCheckClient = InstanceType<typeof HealthCheckClient>;

const isNonOperableMode = (mode: ApplicationOperatingMode | undefined) => {
  if (mode) {
    return nonOperableModes.includes(mode);
  }

  return false;
};

export const healthCheckUtils = {
  isNonOperableMode,
};
