import { IIconProps, Stack, Text } from "@fluentui/react";
import { Html, ITheme, htmlUtils, stringUtils, useTheme } from "cayo.ui";
import React, { Fragment, useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { IActivityItem, IIcon, IImage } from "../../api/schema.api";
import { bindingsUtils } from "../../scheme/bindings";
import { FCC } from "../../types/react";
import Border from "../Common/Border";
import { defaultLabelStyle } from "../CommonLayout";
import Column from "../Layout/Column";
import { allRendereres } from "../Schemes/renderers";

const ActivityItem: FCC<IActivityItem & { data: any }> = (props) => {
  const intl = useIntl();
  const { data } = props;
  const theme = useTheme();

  const resolvedTitle = useMemo(() => {
    return bindingsUtils.resolveExpression(data, props.title!);
  }, [data]);

  const iconProps = useMemo<
    IIconProps & Omit<IIcon, "type"> & { fallbackIcon?: IIcon | undefined }
  >(() => {
    const result: any = (props.icon && bindingsUtils.resolveIcon(props.icon, data, theme)) || {};
    if (props.valign === false) {
      if (!result.style) {
        result.style = {};
      }

      result.style.marginTop = 4;
      result.style.marginRight = 4;
    }
    const fallbackIcon = (props.icon as IImage)?.fallbackIcon;
    if (fallbackIcon) {
      result.fallbackIcon = fallbackIcon;
    }

    return { ...props.icon, ...result };
  }, [props.icon, data]);

  const descriptions = useMemo(() => {
    return props.descriptions
      ?.map((d) => {
        const formattedControl = bindingsUtils.resolveFormatterControl(data, d);
        const result = formattedControl || bindingsUtils.resolveExpression(data, d, intl);

        // eslint-disable-next-line eqeqeq
        if (formattedControl) {
          return formattedControl;
        }

        return result?.toString();
      })
      ?.map((d) => (typeof d === "string" || !d ? stringUtils.trim(d, ",") : d))
      ?.filter((d) => !!d)
      ?.map((v) => (typeof v === "string" ? htmlUtils.sanitize(v) : v));
  }, [data]);

  const item = (
    <StyledItem
      verticalAlign={props.valign !== false ? "center" : "start"}
      horizontal={true}
      disableShrink={true}
      tokens={{ childrenGap: props.childrenGap }}
    >
      {props.icon?.type && allRendereres[props.icon!.type](iconProps)}
      <StyledColumn>
        <Text variant="medium" block={true} nowrap={false} style={defaultLabelStyle}>
          {resolvedTitle}
        </Text>
        {descriptions?.map((d, i) => {
          if (typeof d === "string") {
            return (
              <DescriptionText variant="small" block={true} nowrap={true} key={i} title={d}>
                <Html value={d} />
              </DescriptionText>
            );
          }

          const formatterControl = Object.keys(d)[0];
          const Control = allRendereres[formatterControl.toLowerCase()];
          if (Control) {
            return <Control key={i} {...d[formatterControl]} />;
          }
          return <Fragment key={i} />;
        })}
      </StyledColumn>
    </StyledItem>
  );

  if (props.border === "shadow") {
    return <ShadowBorder>{item}</ShadowBorder>;
  }

  return item;
};

const ShadowBorder = styled(Border)`
  margin-bottom: 8px;
`;

const DescriptionText = styled(Text)`
  color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.disabled};
`;

const StyledColumn = styled(Column)`
  padding-left: 8px;
  padding-right: 10px;
`;

const StyledItem = styled(Stack)`
  max-width: 100%;
  margin-bottom: 6px;
  width: 100%;
`;

export default ActivityItem;
