import logger, { ILogger } from "../libs/logger";
import { objectUtils } from "../utils/object-utils";
import { urlUtils } from "../utils/url-utils";
import LiteEvent from "./lite-event";

export default class SettingsStore<T, SubT> {
  public get SaveEvent() {
    return this.onSave.expose();
  }
  log: ILogger;
  public constructor(private key: string, private storage: Storage) {
    this.log = logger.getLogger(`SettingsStore-${key}`);
  }
  private readonly onSave = new LiteEvent<T>();

  public deleteSubKey(subKey: string) {
    const oldValue = this.get();
    if (oldValue) {
      delete (oldValue as any)[urlUtils.trim(subKey)];
    }

    this.storage.setItem(this.key, JSON.stringify(oldValue));
  }

  public get(): T | undefined {
    const value = this.storage.getItem(this.key);
    let result;
    try {
      result = value ? (JSON.parse(value) as T) : undefined;
    } catch {}
    // if (this.key === "user") {
    //   this.log.debug("UserSettingsStore -> get", result);
    // }
    return result;
  }

  public getSubKey(subKey: string): SubT | undefined {
    const value = this.storage.getItem(this.key);

    try {
      const deserializedValue = value ? (JSON.parse(value) as T) : undefined;
      if (deserializedValue && subKey) {
        return (deserializedValue as any)[urlUtils.trim(subKey)] as SubT;
      }
      return undefined;
    } catch {
      return undefined;
    }
  }

  public set(value: T | undefined) {
    if (value === undefined) {
      this.storage.removeItem(this.key);
    } else {
      this.storage.setItem(this.key, JSON.stringify(value));
    }
    if (this.key === "user") {
      this.log.debug("UserSettingsStore -> set", value);
    }
    this.onSave.trigger(value);
  }

  public update(value: T): T {
    const newValue = objectUtils.mergeDeep(this.get() || {}, value);
    // if (this.key === "user") {
    //   this.log.debug("UserSettingsStore -> update -> value, newValue", value, newValue);
    // }
    this.storage.setItem(this.key, JSON.stringify(newValue));

    this.onSave.trigger(newValue);

    return newValue as T;
  }

  public updateSubKey(subKey: string, value: SubT, triggerEvent?: boolean | undefined): T {
    // eslint:disable-next-line: no-explicit-any
    const subValue = { [urlUtils.trim(subKey)]: value } as any;
    const newValue = objectUtils.mergeDeep(this.get() || {}, subValue) as T;
    this.storage.setItem(this.key, JSON.stringify(newValue));

    if (triggerEvent !== false) {
      this.onSave.trigger(newValue);
    }

    return newValue as T;
  }

  public setSubKey(subKey: string, value: SubT, triggerEvent?: boolean | undefined): T {
    // eslint:disable-next-line: no-explicit-any
    const subValue = { [urlUtils.trim(subKey)]: value } as any;
    const newValue = { ...(this.get() || {}), ...subValue } as T;
    this.storage.setItem(this.key, JSON.stringify(newValue));

    if (triggerEvent !== false) {
      this.onSave.trigger(newValue);
    }

    return newValue as T;
  }
}
