import { IDetailsRowProps, Icon } from "@fluentui/react";
import { ICayoTheme } from "cayo.ui";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import MaskedIcon from "../../Graphics/MaskedIcon";

export const messages = defineMessages({
  rollback: {
    defaultMessage: "Rollback disabled",
    id: "render-row.rollback",
  },
});

const renderRestoreDisabledCheckbox = (cayoTheme: ICayoTheme) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();

  return (
    <MaskedIcon
      maskIcon={"DRM"}
      maskPosition="rightBottom"
      color={cayoTheme.brandColors.disabled}
      title={intl.formatMessage(messages.rollback)}
    >
      <Icon
        iconName={"Redo"}
        color={"red"}
        styles={{ root: { color: cayoTheme.brandColors.disabled } }}
      />
    </MaskedIcon>
  );
};

export const renderRow = (
  props: IDetailsRowProps,
  defaultRender: (props?: IDetailsRowProps) => JSX.Element | null,
  cayoTheme: ICayoTheme
): JSX.Element | null => {
  const isRestoreDisabled = (props?.item?.categories as any[])?.find(
    (c) => c.name === "Restore" && c.value === "Disabled"
  );
  const onRenderDetailsCheckbox = isRestoreDisabled
    ? () => renderRestoreDisabledCheckbox(cayoTheme)
    : undefined;
  return defaultRender ? defaultRender({ ...props, onRenderDetailsCheckbox } as any) : null;
};
