import { useEffect, useState } from "react";

const useInterval = (milliseconds?: number) => {
  const [updateTime, setUpdateTime] = useState(new Date());

  useEffect(() => {
    const hasInterval = milliseconds && milliseconds > 0;
    const interval =
      hasInterval &&
      setInterval(() => {
        setUpdateTime(new Date());
      }, milliseconds);

    return () => {
      interval && clearInterval(interval);
    };
  }, []);

  return { updateTime };
};

export default useInterval;
