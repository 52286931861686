import { defineMessages } from "react-intl";

export const commonMessages = defineMessages({
  example: {
    defaultMessage: "Example: ",
    id: "common-messages.example",
  },
  back: {
    defaultMessage: "Back",
    id: "common-messages.back",
  },
  closeWizardConfirmationTitle: {
    defaultMessage: "Are you sure you want to close?",
    id: "common-messages.close-wizard-title",
  },
  finish: {
    defaultMessage: "Finish",
    id: "common-messages.finish",
  },
  next: {
    defaultMessage: "Next",
    id: "common-messages.next",
  },
  nextTip: {
    defaultMessage: "Next tip",
    id: "common-messages.nextTip",
  },

  inputRequired: {
    defaultMessage: "This field is required.",
    id: "logon-messages.errors.input-required",
  },
  loading: {
    defaultMessage: "Loading...",
    id: "common-messages.loading",
  },
  matchFormat: {
    defaultMessage: "Please match the requested format.",
    id: "logon-messages.errors.match-format",
  },

  no: {
    defaultMessage: "No",
    id: "common-messages.no",
  },
  more: {
    defaultMessage: "More...",
    id: "common-messages.more",
  },
  seeMore: {
    defaultMessage: "See more",
    id: "common-messages.see-more",
  },
  noResults: {
    defaultMessage: "No results",
    id: "common-messages.no-results",
  },
  yes: {
    defaultMessage: "Yes",
    id: "common-messages.yes",
  },
  close: {
    defaultMessage: "Close",
    id: "common-messages.close",
  },
  cancel: {
    defaultMessage: "Cancel",
    id: "common-messages.cancel",
  },
  apply: {
    defaultMessage: "Apply",
    id: "common-messages.apply",
  },
  select: {
    defaultMessage: "Select",
    id: "common-messages.select",
  },
  save: {
    defaultMessage: "Save",
    id: "common-messages.save",
  },
  accessIsDenied: {
    defaultMessage: "Access is denied",
    id: "common-messages.access-denied",
  },

  accessIsDeniedMessage: {
    defaultMessage:
      'Account "{account}" does not have access to Cayosoft Guardian. Click OK and sign in with a different account.\r\rTo grant access, sign in to Guardian Web Portal, navigate to Settings > Delegation and add the account "{account}".',
    id: "common-messages.access-denied-message",
  },
  changesWereSaved: {
    defaultMessage: "The changes were saved",
    id: "common-messages.changes-were-saved",
  },
  submittingData: {
    defaultMessage: "Submitting data...",
    id: "common-messages.submitting-data",
  },
  properties: {
    defaultMessage: "Properties",
    id: "common-messages.properties",
  },
  delete: {
    defaultMessage: "Delete",
    id: "common-messages.delete",
  },
  new: {
    defaultMessage: "New",
    id: "common-messages.new",
  },

  add: {
    defaultMessage: "Add",
    id: "common-messages.add",
  },
  ok: {
    defaultMessage: "OK",
    id: "common-messages.ok",
  },
  retry: {
    defaultMessage: "Retry",
    id: "common-messages.retry",
  },
  invalidUrlAction: {
    defaultMessage: "The action url is invalid.",
    id: "common-messages.invalid-url",
  },
  builtIn: {
    defaultMessage: "Built-in",
    id: "common-messages.built-in",
  },
  custom: {
    defaultMessage: "Custom",
    id: "common-messages.custom",
  },
  never: {
    defaultMessage: "Never",
    id: "common-messages.never",
  },
  years: {
    defaultMessage: "years",
    id: "common-messages.years",
  },
  year: {
    defaultMessage: "year",
    id: "common-messages.year",
  },
  shortYears: {
    defaultMessage: "y",
    id: "common-messages.short-years",
  },
  months: {
    defaultMessage: "months",
    id: "common-messages.months",
  },
  month: {
    defaultMessage: "month",
    id: "common-messages.month",
  },
  shortMonths: {
    defaultMessage: "m",
    id: "common-messages.short-months",
  },
  days: {
    defaultMessage: "days",
    id: "common-messages.days",
  },
  day: {
    defaultMessage: "day",
    id: "common-messages.day",
  },
  shortDays: {
    defaultMessage: "d",
    id: "common-messages.short-days",
  },
  hours: {
    defaultMessage: "hours",
    id: "common-messages.hours",
  },
  hour: {
    defaultMessage: "hour",
    id: "common-messages.hour",
  },
  shortHours: {
    defaultMessage: "h",
    id: "common-messages.short-hours",
  },
  minutes: {
    defaultMessage: "minutes",
    id: "common-messages.minutes",
  },
  minute: {
    defaultMessage: "minute",
    id: "common-messages.minute",
  },
  shortMinutes: {
    defaultMessage: "m",
    id: "common-messages.short-minutes",
  },
  shortSeconds: {
    defaultMessage: "s",
    id: "common-messages.short-seconds",
  },
  rename: {
    defaultMessage: "Rename",
    id: "common-messages.rename-dialog",
  },
  name: {
    defaultMessage: "Name",
    id: "common-messages.rename",
  },
  description: {
    defaultMessage: "Description",
    id: "common-messages.description",
  },
  unknownSize: {
    defaultMessage: "Unknown size",
    id: "common-messages.unknown-size",
  },
  usedSpace: {
    defaultMessage: "{freeSpace} free of {capacity}",
    id: "common-messages.unknown",
  },
  remove: {
    defaultMessage: "Remove",
    id: "common-messages.remove",
  },
  notset: {
    defaultMessage: "[Not set]",
    id: "common-messages.not-set",
  },
  copy: {
    defaultMessage: "Copy",
    id: "common-messages.copy",
  },
  confirm: {
    defaultMessage: "Confirm",
    id: "common-messages.confirm",
  },
  noDataAvailable: {
    defaultMessage: "No data available yet",
    id: "common-messages.no-data-available",
  },
  noData: {
    defaultMessage: "No data",
    id: "common-messages.no-data",
  },
  detectingThreats: {
    defaultMessage: "Detecting threats...",
    id: "common-messages.detecting-threats",
  },
  adustColumns: {
    defaultMessage: "Adjust columns",
    id: "common-messages.adjust-columns",
  },
  doNotShowThisAgain: {
    defaultMessage: "Do not show this again",
    id: "common-messages.do-not-show-again",
  },
  passwordIsSet: {
    defaultMessage: "Password is set",
    id: "common-messages.password-is-set",
  },
  propertiesUpdated: {
    defaultMessage: "Properties updated for object ''{objectName}''",
    id: "common-messages.properties-updated",
  },
  confirmDeleteItems: {
    defaultMessage: "Are you sure you want to delete the selected item(s)?",
    id: "common-messages.confirm-delete-items",
  },
  moreItems: {
    defaultMessage: "...{count } item(s) more.",
    id: "common-messages.more-items",
  },
  previewQuery: {
    defaultMessage: "Preview Query",
    id: "common-messages.preview-query",
  },
});
