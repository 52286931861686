import { ITheme } from "cayo.ui";
import React from "react";
import styled from "styled-components";

const CompanyLogo = () => {
  return <StyledLogo>Cayosoft</StyledLogo>;
};

const StyledLogo = styled.div`
  padding: 8;
  height: 100%;
  font-size: 27px;
  font-weight: bold;
  color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.primaryHighlight};
`;

export default CompanyLogo;
