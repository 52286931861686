import { IContentScheme } from "../../api/schema.api";
import logger from "../../libs/logger";
import { urlUtils } from "../../utils/url-utils";
import {
  GridContainerProps,
  GridController,
  IGridState,
  UIInteraction,
} from "./grid-model/GridController";
import { IQuery, assignQuery, newQuery } from "./grid-model/Query";
import { GridNotification } from "./repo/GridNotification";
import { ItemsStore } from "./repo/ItemsStore";
import { QueryStore, QueryStoreConfig } from "./repo/QueryStore";
import { UserSettingsStore } from "./repo/UserSettingsStore";

const log = logger.getLogger("QUICK-FILTER-NEW createGridController.factory");

export function createController(props: GridContainerProps, uiInteraction: UIInteraction) {
  const { scheme, gridSettingsKey } = props;
  const dl = scheme.detailsList;
  log.debug("details-list component", dl);
  if (!dl) {
    throw new Error("DetailsList not found");
  }
  const savedQueryConfig = getQueryConfig(scheme);

  const columns = (dl.columns ?? []).filter((c) => !!c);
  let url = dl.url ?? "";
  if (!url) {
    throw new Error("Url for load items not exists");
  }

  const initialState: IGridState = {
    id: dl.id!,
    columns: [],
    ajustColumnsToken: "",
    searchResult: { items: [], skipToken: null },
    loading: true,
    query: createQuery(scheme),
    savedQueries: [],
    recentQueries: [],
    showQueryPicker: false,
    showChooseColumns: false,
    selectedItems: [],
    hasChanges: false,
    gridSettingsKey,
    queryMode: "normal",
  };

  return new GridController(
    {
      allColumns: columns,
      initialQueryId: scheme.savedQueries?.initialQueryId,
      defaultGroupBy: scheme.detailsList?.groupBy ?? "",
      onSelect: props.onSelect,
    },
    initialState,
    new ItemsStore(url, scheme.detailsList?.pageSize),
    new QueryStore(savedQueryConfig),
    new UserSettingsStore(gridSettingsKey),
    uiInteraction,
    GridNotification.createFn(dl.notificationMode, dl.notificationSource)
  );
}

function createQuery(schema: IContentScheme): IQuery {
  const sortedColumn = schema.detailsList!.columns?.find((c) => c.isSorted === true);
  const groupBy = schema.detailsList?.groupBy ?? "";
  let result: IQuery = assignQuery(newQuery(), { groupBy });

  if (!!sortedColumn) {
    result = assignQuery(result, {
      orderBy: {
        field: sortedColumn.fieldName!,
        direction: sortedColumn.isSortedDescending === true ? "descending" : "ascending",
      },
    });
  }
  return result;
}

function getQueryConfig(schema: IContentScheme): QueryStoreConfig | undefined {
  if (!!schema.savedQueries?.picker?.path && !!schema.targetType) {
    return {
      targetType: schema.targetType,
      queryUrl: schema.savedQueries.picker.path,
      expand: schema.savedQueries.picker.expand,
      objectPath: urlUtils.trim(window.location.hash),
      allowSavedQueries: schema.filterBar?.showSavedQueries === true,
      favoritesEnabled: schema.filterBar?.favoritesEnabled ?? false,
    };
  }

  return undefined;
}
