import { stringUtils } from "cayo.ui";
import { IFormModel } from ".";
import bindingsRegexes from "../../../scheme/bindings/regexes";
import { objectUtils } from "../../../utils/object-utils";

function removeAuxProperties(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(removeAuxProperties);
  } else if (typeof obj === "object" && obj !== null && !(obj instanceof Date)) {
    const result: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (!key.startsWith("_") && !key.endsWith("_switch")) {
          result[key] = removeAuxProperties(obj[key]);
        }
      }
    }
    return result;
  } else {
    return obj;
  }
}

const setPropDisabled = (model: IFormModel, propName: string) => {
  const propDisabledName = "_disabled_" + propName;
  const hasChanges = !model[propDisabledName];
  model[propDisabledName] = true;

  return hasChanges;
};

const setPropEnabled = (model: IFormModel, propName: string) => {
  const propDisabledName = "_disabled_" + propName;
  const hasChanges = !!model[propDisabledName];
  delete model[propDisabledName];

  return hasChanges;
};

const isPropDisabled = (model: IFormModel, propName: string) => {
  const propDisabledName = "_disabled_" + propName;
  return model[propDisabledName] === true;
};

const hasValue = (o: any, type?: string) => {
  return (
    !!o ||
    typeof o === "boolean" ||
    typeof o === "number" ||
    typeof o === "string" ||
    type === "number"
  );
};

export const splitPropPath = (property: string) => {
  const parts = property.split(".");

  if (
    parts.length > 1 &&
    parts[parts.length - 1] === "type" &&
    parts[parts.length - 2].endsWith("@odata")
  ) {
    parts.splice(parts.length - 1);
    parts[parts.length - 1] = parts[parts.length - 1] + ".type";
  }

  return parts;
};

export const resolveProperyExpression = (propertyExpression: string, target: any) => {
  const matches = Array.from(propertyExpression.matchAll(bindingsRegexes.allInsideCurlyBrackets));

  if (matches.length === 0) {
    return propertyExpression;
  }

  let expression = matches[0][1];
  let result: string = "";
  if (expression.indexOf(" ") === -1 && expression.indexOf("||") === -1) {
    result = resolvePropertyValue(expression, target);
  } else {
    result = propertyExpression.replace(
      bindingsRegexes.insideCurlyBrackets,
      function (match, token) {
        const parts = (token.split("||") as string[]).map((v) => stringUtils.trim(v, " "));
        const values = parts.map((v) => resolvePropertyValue(v, target));
        const r = values.find((v) => !!v);

        return r ?? "?";
      }
    );
  }

  return result;
};

export const resolvePropertyValue = (property: string, target: any, type?: string) => {
  const parts = splitPropPath(property);

  if (parts.length === 1) {
    return target[property];
  }

  let current = target;
  let maxLevel: number = parts.length - 1;
  let level: number = 0;
  for (const part of parts) {
    const temp = current[part];

    if (objectUtils.isPrimitive(temp) && level === parts.length - 1) {
      current = temp;
      break;
    }

    const createParent = !!type
      ? !hasValue(temp, type) && level !== maxLevel
      : !current[part]; /* remove this condition when new UI scheme*/
    if (createParent) {
      current[part] = {};
    }

    current = current[part];
    level++;
  }

  return current;
};

const auxPropsUtils = {
  removeAuxProperties,
  setPropDisabled,
  setPropEnabled,
  isPropDisabled,
};

export default auxPropsUtils;
