import { stringUtils } from "cayo.ui";
import globalConstants from "../components/App/constants";
import bindingsRegexes from "../scheme/bindings/regexes";

const hashUrlBeginPattern = new RegExp("(^#/)|(^/)|^#");

const trim = (url?: string | undefined) => {
  return (url && url.replace(hashUrlBeginPattern, "")) || "";
};

const getSchemeId = (url?: string) => {
  let parts = url?.split("?");
  if (!parts || parts.length < 1) {
    return undefined;
  }

  const schemeId = parts[0].split("/").slice(1);
  return schemeId.join("/");
};

const addRequestTime = (url: string) => {
  let resultUrl = url;
  if (url) {
    const time = `&time=${new Date().getTime()}`;
    if (url.indexOf("?") < 0) {
      resultUrl += "?";
    }

    resultUrl += time;
  }

  return resultUrl;
};

const makeApiPath = (url: string) => {
  let result = globalConstants.apiVersion;
  if (!url.startsWith("/")) {
    result = "/" + result;
  }

  return result + url;
};

const buildRelativePath = (...args: string[]) => {
  const [first, ...other] = args;

  const trimmed = other.map((v) => stringUtils.trim(v, "/"));
  const result = first + (first.endsWith("/") ? "" : "/") + trimmed.join("/");
  return result;
};

const hasBindings = (url: string) => {
  const result = url.match(bindingsRegexes.insideCurlyBrackets);
  return !!result;
};

const getRouteFromApiPath = (s: string) => {
  const groups = s.match(/^v0\/([^?]*)/);
  return groups && groups[1];
};

const isLastSegmentTypeCast = (s: string) => {
  const match = s.match(/([^/]+$)/);

  return match && match.length === 1 && match[0].indexOf(".") > 0;
};

const replaceLastSegment = (s: string, newSegment: string) => {
  return s.replace(/([^/]+$)/, newSegment);
};

function getRoute(url: string) {
  const index = url.indexOf("?");
  if (index > 0) {
    return url.substring(0, index);
  }

  return url;
}

const makeRelativePath = (path: string) => {
  if (!path.startsWith("/")) {
    return "/" + path;
  }
  return path;
};

export const urlUtils = {
  trim,
  getSchemeId,
  addRequestTime,
  makeApiPath,
  hasBindings,
  buildRelativePath,
  getRouteFromApiPath,
  replaceLastSegment,
  getRoute,
  makeRelativePath,
  isLastSegmentTypeCast,
};
