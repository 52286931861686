import { dateTimeUtils } from "cayo.ui";
import { AvailabilitySchedule, DayOfWeek } from "../../api/cayo-graph";
import logger from "../../libs/logger";
import numberUtils from "../../utils/number-utils";
import { objectUtils } from "../../utils/object-utils";
import { EditModes, SelectedHour, TimeWindow } from "./types";

const beginSelection = (current: TimeWindow[], week: number, hour: number, editMode: EditModes) => {
  const window = current[week];
  // for (var i = 0; i < window.timeLimit.length; i++) {
  //   window.timeLimit[i] = 0;
  // }

  window.timeLimit[hour] = editMode === "allowed" ? 1 : 0;
};

const moveSelection = (
  current: TimeWindow[],
  last: TimeWindow[],
  beginHour: SelectedHour,
  endHour: SelectedHour,
  editMode: EditModes
) => {
  const realHourBegin = Math.min(beginHour.hour, endHour.hour);
  const realWeekBegin = Math.min(beginHour.week, endHour.week);
  const realHourEnd = Math.max(beginHour.hour, endHour.hour);
  const realWeekEnd = Math.max(beginHour.week, endHour.week);
  logger.debug("moveSelection", realWeekBegin, realHourBegin, realWeekEnd, realHourEnd);

  const setValue: number = editMode === "allowed" ? 1 : 0;
  for (var week = 0; week < current.length; week++) {
    for (var hour = 0; hour < current[week].timeLimit.length; hour++) {
      if (
        hour >= realHourBegin &&
        week >= realWeekBegin &&
        hour <= realHourEnd &&
        week <= realWeekEnd
      ) {
        current[week].timeLimit[hour] = setValue;
      } else {
        current[week].timeLimit[hour] = last[week].timeLimit[hour];
      }
    }
  }
};

const adjustSelection = (current: TimeWindow[]) => {
  for (var week = 0; week < current.length; week++) {
    const firstSet = current[week].timeLimit.findIndex((t) => t === 1);
    const revertedWeek = [...current[week].timeLimit].reverse();
    const lastSet = current[week].timeLimit.length - 1 - revertedWeek.findIndex((t) => !!t);

    if (firstSet >= 0 && firstSet !== lastSet) {
      for (var i = firstSet; i <= lastSet; i++) {
        current[week].timeLimit[i] = 1;
      }
    }
  }
};

const initTimeWindows = (weekDays: string[], value?: AvailabilitySchedule[]) => {
  const result: TimeWindow[] = [];

  weekDays.forEach((weekDay) => {
    const timeLimit = Array<number>(24).fill(0);
    const lowWeekDay = weekDay.toLocaleLowerCase();
    const schedule = value?.find((s) => s?.days?.find((d) => d.toLocaleLowerCase() === lowWeekDay));
    if (schedule) {
      const beginHour = dateTimeUtils.parseTime(schedule.startTime, false)?.value?.getHours();
      const endHour = dateTimeUtils.parseTime(schedule.endTime, false)?.value?.getHours() ?? 24;

      if (numberUtils.between(0, 23, beginHour)) {
        timeLimit[beginHour!] = 1;
      }

      if (endHour === 24 || numberUtils.between(0, 23, endHour)) {
        timeLimit[endHour! - 1] = 1;
      }
      logger.debug(beginHour, endHour);
    }

    result.push({ weekDay, timeLimit });
  });

  timeWindowsUtils.adjustSelection(result);

  return result;
};

const convertToAvailabilitySchedule = (value: TimeWindow[]) => {
  const aux = value
    .map((v) => {
      const t = v.timeLimit;
      const firstSet = t.findIndex((t) => t === 1);
      const revertedWeek = [...t].reverse();
      let lastSet = t.length - 1 - revertedWeek.findIndex((t) => !!t);

      if (firstSet < 0 && lastSet > 23) {
        return undefined;
      }

      lastSet = lastSet + 1;

      return {
        range: `${firstSet}_${lastSet}`,
        weekDay: v.weekDay.toLowerCase(),
        beginHour: firstSet,
        endHour: lastSet,
      };
    })
    .filter((v) => !!v);
  const groups = objectUtils.groupBy(aux, (o) => o.range);

  logger.debug(groups);
  const result: AvailabilitySchedule[] = [];
  for (const group of groups) {
    const item = group[1];
    const weekDays = (item as any[]).map((i) => i.weekDay as string) as DayOfWeek[];
    const startTime = item[0].beginHour;
    const endTime = item[0].endHour;
    logger.debug(group[1]);

    let endTimeStr = endTime === 24 ? undefined : `${endTime}:00:00`; //endTime === 24 ? "23:59:00" :

    result.push({ days: weekDays, startTime: `${startTime}:00:00`, endTime: endTimeStr });
  }
  return result;
  // for (const group of groups) {
  //   const schedule:AvailabilitySchedule = {days: group}
  // }
};

const timeWindowsUtils = {
  adjustSelection,
  moveSelection,
  beginSelection,
  initTimeWindows,
  convertToAvailabilitySchedule,
};

export default timeWindowsUtils;
