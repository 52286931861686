import { useAsync } from "@fluentui/react-hooks";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { NetworkMessages } from "../../i18n/messages/network-messages";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import { getError } from "../../libs/odata.client";
import { ServiceFactory } from "../../services";
import { INotificationMessage } from "../../services/notification.service";
import useService from "../../services/services.hook";
import { appSettings } from "../../settings/app-settings";
import { objectUtils } from "../../utils/object-utils";
import { IServerEvents, ServerError } from "../App/types";
import { ApplicationState, IApiAccessState, healthCheckUtils } from "./health-check.api";

const log = logger.getLogger("useServerState.hook");

export const useServerState = () => {
  const [isLoading, setIsLoading] = useState(true);
  const intl = useIntl();
  const [healthCheckError, setHealthCheckError] = useState<ServerError | null | undefined>();

  const [apiState, setApiState] = useState<IApiAccessState | undefined>(undefined);
  const [healthCheckTime, setHealthCheckTime] = useState(new Date());
  const async = useAsync();
  const notificationService = useService("notificationService");

  useEffect(() => {
    const onAppEvents = (messages: INotificationMessage[]) => {
      try {
        if (apiState?.state === "restarting") {
          log.debug("Server restarting, state:", apiState?.state);
          return;
        }

        const msgGroups = objectUtils.groupBy(messages, (j) => j.action);
        for (const group of msgGroups) {
          const serverEvents = (group[1] as any[]).map((m) => JSON.parse(m.body) as IServerEvents);
          const restartEvent = serverEvents.find((e) => e.state === "restarting");

          log.debug(
            "App events",
            "state: " + apiState?.state,
            ", operating mode: ",
            apiState?.operatingMode
          );

          if (restartEvent) {
            log.debug("restartEvent:", restartEvent);
            setApiState({ state: restartEvent.state, operatingMode: restartEvent.operatingMode });
            break;
          } else {
            const lastEvent = serverEvents[serverEvents.length - 1];
            log.debug("lastEvent:", lastEvent);
            setApiState({ state: lastEvent.state, operatingMode: lastEvent.operatingMode });

            if (healthCheckUtils.isNonOperableMode(lastEvent.operatingMode)) {
              log.debug("server is non-operable");
              // document.location.reload();
              if (lastEvent.operatingMode === "migration") {
                setHealthCheckError({
                  state: lastEvent.state,
                  operatingMode: lastEvent.operatingMode,
                });
              }
            }
          }
        }
      } catch (e) {
        logger.error("Failed to process app event", e);
      }
    };

    const unsubcribeHandler = notificationService.subscribe("appevents", onAppEvents);

    return () => {
      unsubcribeHandler();
    };
  }, [apiState?.state, apiState?.operatingMode]);

  useEffect(() => {
    ServiceFactory.HealthCheckService.getReadyStatus()
      .then((response) => {
        const apiState = response!.results.service.data.apiState!;

        setApiState(apiState);
        let schemeChanged = false;

        const appState = apiState?.state;
        const operatingMode = apiState?.operatingMode;

        log.debug("state:", appState, ", operating mode:", operatingMode);

        if (
          apiState &&
          (appState !== "started" || healthCheckUtils.isNonOperableMode(operatingMode))
        ) {
          setHealthCheckError({
            ...apiState,
            isCritical: false,
          });
          setIsLoading(false);

          return;
        }

        if (response?.status === "Healthy") {
          const currentUiSchemeVersion = localStorage.getItem("ui-scheme");
          if (
            !currentUiSchemeVersion ||
            // eslint-disable-next-line eqeqeq
            response?.results?.service?.data?.uiScheme != localStorage.getItem("ui-scheme")
          ) {
            appSettings.viewSetting.set(undefined);

            localStorage.setItem("ui-scheme", response?.results?.service?.data?.uiScheme || "1");
            schemeChanged = true;
          }

          if (response?.results?.service?.data?.certificateError) {
            setHealthCheckError({
              ...apiState,
              message: response?.results?.service?.data?.certificateError,
              isCritical: true,
            });
          } else {
            if (apiState.state === "started") {
              setHealthCheckError({ ...apiState });
            }
          }
        } else {
          if (response?.results?.database?.status !== "Healthy") {
            setHealthCheckError({
              ...apiState,
              message: response?.results?.database?.data["error"] || "Database is unavailable",
              isCritical: true,
            });
          }
        }

        if (schemeChanged) {
          ajax
            .getClient("profile/dashboard/default", "DELETE")
            .then(() => {
              setIsLoading(false);
            })
            .catch((e) => {
              log.error("Failed to delete default dashboard", e);
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        getError(e).catch((error) => {
          setIsLoading(false);

          const errorMsg =
            e.status === 404 || error?.toString()?.toLowerCase() === "failed to fetch"
              ? intl.formatMessage(NetworkMessages.serverUnavailable)
              : error || e.message || "Unknown error occurred";
          setHealthCheckError({
            state: "stopped",
            message: errorMsg,
            isCritical: false,
          });
        });
      });
  }, [healthCheckTime]);

  const runHealthCheck = useCallback(() => setHealthCheckTime(new Date()), []);

  useEffect(() => {
    let timer: number = -1;
    if (healthCheckError !== null && healthCheckError !== undefined) {
      timer = async.setTimeout(() => runHealthCheck(), 5000);
      log.debug(`Service is not ready. Start postponed health check. timer=${timer}`);
    }

    return () => {
      if (timer >= 0) {
        log.debug(`Clear timer=${timer}`);
        async.clearTimeout(timer);
      }
    };
  }, [healthCheckError]);

  useEffect(() => {
    if (healthCheckError?.state === "started" && healthCheckError.operatingMode === "normal") {
      log.debug("Server started, state:", apiState?.state);
      setHealthCheckError(null);
    }
  }, [healthCheckError?.state, healthCheckError?.operatingMode]);

  return {
    isLoading,
    healthCheckError,
    setServiceState: (state: ApplicationState) => setApiState({ state }),
    runHealthCheck,
  };
};
