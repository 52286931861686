import { useCallback, useReducer, useRef } from "react";
import logger from "../../../libs/logger";

interface scrollResizeProps {
  isDragging?: boolean;
  isHovered?: boolean;
}

const scrollResizeReducer = (oldState: scrollResizeProps, newState: scrollResizeProps) => {
  return { ...oldState, ...newState };
};

const useScrollResizer = (onWidthChanged: (width: number, isFullScreen: boolean) => void) => {
  const [scrollResizeState, setScrollResizeState] = useReducer(scrollResizeReducer, {});
  const resizingTargetRef = useRef<HTMLDivElement>(null);

  const onMouseOver = useCallback(() => {
    setScrollResizeState({ isHovered: true });
  }, []);

  const onMouseLeave = useCallback(() => {
    setScrollResizeState({ isHovered: false });
  }, []);

  const onMouseMove = useCallback(
    (e: any) => {
      if (!scrollResizeState.isDragging) {
        return;
      }

      let offsetRight = document.body.clientWidth - e.clientX;

      onWidthChanged(offsetRight, e.clientX === 0);
    },
    [scrollResizeState.isDragging]
  );

  const onPointerDown = useCallback((e: any) => {
    e?.target?.setPointerCapture(e.pointerId);
    setScrollResizeState({ isDragging: true });
  }, []);

  const onPointerUp = useCallback((e: any) => {
    logger.debug(e);

    if (e.pointerId !== undefined) {
      e?.target?.releasePointerCapture(e.pointerId);
    }
    setScrollResizeState({ isDragging: false });
  }, []);

  return {
    isHovered: scrollResizeState.isHovered,
    resizingTargetRef,
    onPointerDown,
    onMouseOver,
    onMouseLeave,
    onPointerUp,
    onMouseMove,
  };
};

export default useScrollResizer;
