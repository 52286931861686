import React, { FC } from "react";
import { IPivot, IScheme } from "../../../api/schema.api";
import chartRenderers from "../../Charts";
import graphicsRenderers from "../../Graphics";

import Pivot from "../../Navigation/Pivot";

import { buttonRenderers } from "../../Form/renderers/renderers.buttons";
import surfaceRenderers from "../../Surfaces";

import { MessageBarType } from "@fluentui/react";
import { IMessageBar, MessageBar, Separator } from "cayo.ui";
import cardsRenderers from "../../Cards";
import formRenderers from "../../Form/renderers/form-renderers";
import listRenderers from "../../Lists/lists.renderers";
import Spacer from "../../Utilities/Spacer";
import widgetRenderers from "../../Widgets";
import GenericScheme from "../GenericScheme";
import customFormRenderers from "./custom-form.renderers";
import formatterControls from "./formatter.renderers";
import { layoutRenderers } from "./renderers.layout";
import userControlRenderers from "./user-control.renderers";

const Scheme: FC<IScheme> = (props: IScheme) => {
  return <GenericScheme {...(props as any)} />;
};

const Pixels: FC = (props: any) => {
  return <div style={{ width: props.width }} />;
};

const separator: FC = (props) => {
  return <Separator {...props} />;
};

const App = (props: IScheme) => {
  return (
    <div style={{ position: "relative", width: "100%", height: "100%", overflow: "hidden" }}>
      {allRendereres.scheme(props)}
    </div>
  );
};

const pivot = (props: IPivot) => {
  return <Pivot {...(props as any)} />;
};

const navigationRendereres = {
  pivot,
};

const messagebar = (props: IMessageBar) => {
  const messageBarType = MessageBarType[props.messageBarType as unknown as string];
  return <MessageBar {...props} messageBarType={messageBarType} />;
};

const notificaitionsRendereres = {
  messagebar,
};

export const allRendereres = {
  ...customFormRenderers,
  ...layoutRenderers,
  ...buttonRenderers,
  ...widgetRenderers,
  ...chartRenderers,
  ...listRenderers,
  ...cardsRenderers,
  ...userControlRenderers,
  ...graphicsRenderers,
  ...surfaceRenderers,
  ...formatterControls,
  ...navigationRendereres,
  ...notificaitionsRendereres,
  ...formRenderers,
  separator,
  scheme: Scheme,

  spacer: Spacer,
  pixels: Pixels,

  app: App,
};

export type rendererNames = keyof typeof allRendereres;
