import { IIconSubset } from "@fluentui/react";
import { LightningBoltSolidIcon } from "@fluentui/react-icons-mdl2";
import { themeUtils } from "cayo.ui";
import React, { Fragment, createElement } from "react";
import styled from "styled-components";
import { ReactComponent as AwsIcon } from "../../images/aws.svg";
import { endpoints } from "../../services/endpoints.service";

const StyledLightningBoltSolidIcon = styled(LightningBoltSolidIcon)`
  display: flex;
`;

const customIcons: IIconSubset = {
  icons: {
    LightningBolt: <StyledLightningBoltSolidIcon />,
    Aws: createElement(AwsIcon),
    // suppress warning from fluentui: The icon "script+lightningbolt" was used but not registered
    "script+lightningbolt": <Fragment />,
    "server+download": <Fragment />,
  },
};

const initTheme = () => {
  const fontIconPath = process.env.NODE_ENV === "production" ? endpoints.PUBLIC_URL : undefined;
  themeUtils.setNonce(window["NONCE_ID"]);
  themeUtils.initFonts(fontIconPath || "/", customIcons);
};

export default initTheme;
