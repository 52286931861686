import {
  ActivationWizard,
  ImportLicenseWizard,
  NewAWSSubscriptionWizard,
  NewManagedDomainWizard,
  NewManagedPartitionWizard,
  NewManagedSubscriptionWizard,
  NewManagedTenantWizard,
} from "cayo.ui.wizards";
import DatabaseConfigurationWizard from "../../Configuration/DatabaseConfigurationWizard";

export const clientWizardsRenderers = {
  NewManagedDomainWizard,
  NewManagedPartitionWizard,
  ActivationWizard,
  ImportLicenseWizard,
  NewAWSSubscriptionWizard,
  NewManagedSubscriptionWizard,
  NewManagedTenantWizard,
  DatabaseConfigurationWizard,
};
