import { alertAction } from "../components/Alerts/alerts.api";
import { urlUtils } from "./url-utils";

const extractUrlFromAnchor = (url: string | undefined) => {
  if (!url) {
    return undefined;
  }

  const pattern = /.*href *= *"(.*)".*/;
  url = url.replace(pattern, "$1");

  if (url.endsWith("$value")) {
    return { type: "link", url };
  }

  const parts = url.split("#");
  if (parts && parts.length > 1) {
    return {
      type: "scheme",
      url: url && parts[1].toLowerCase().replace("/configuration/", "/history/"),
    };
  }

  return undefined;
};

const getActionUrl = (url: string | undefined) => {
  if (!url) {
    return undefined;
  }

  const pos = url.indexOf("?");
  const rootUrl = url.substring(0, pos);
  const queryParams = new URLSearchParams(url.substring(pos));
  const id = queryParams.get("props");
  let type = queryParams.get("type") || "";
  if (id) {
    let result = urlUtils.trim(rootUrl) + `/${id}${type ? "/" + type : ""}`;
    return result;
  }

  return undefined;
};

const getFirstActionUrl = (actions?: alertAction[]) => {
  if (actions?.length) {
    return actions[0].path;
  }

  return undefined;
};

const customUrlUtils = { getActionUrl, extractUrlFromAnchor, getFirstActionUrl };

export default customUrlUtils;
