import { debounce, logger } from "cayo.ui";
import { ITokenStorage } from "../settings/token-storage";

export class NewAccessTokenRequestService {
  private currentRequest: any = undefined;
  private log = logger.getLogger("NewAccessTokenRequestService");
  private renewToken: Promise<void> | undefined;

  constructor(
    readonly tokenEndpoint: string,
    readonly tokenStorage: ITokenStorage,
    readonly originalFetch: typeof fetch
  ) {
    this.log.debug("service created");
  }

  public refreshToken = async () => {
    if (!this.renewToken) {
      this.renewToken = new Promise((resolve) => {
        resolve(this._refreshToken());

        debounce(() => {
          this.renewToken = undefined;
          this.log.debug("renewToken set to undefined");
        }, 5000)();
      });
    } else {
      this.log.debug("New token request is in process");
    }

    return await this.renewToken;
  };

  private _refreshToken = async () => {
    const { tokenEndpoint, originalFetch, tokenStorage, log } = this;

    const token = tokenStorage.getToken();
    const refresh_token = token?.refresh_token;

    const body = {
      client_id: "3adb260d-f8db-4eb6-a663-e5a86069a2dd",
      grant_type: "refresh_token",
      refresh_token,
      scope: "adminapi+offline_access",
      "User-Agent": "web",
    };
    const rawData = new URLSearchParams(
      Object.keys(body).map((key) => [key, body[key]])
    ).toString();

    var newRequestInit = {
      body: rawData,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    } as RequestInit;

    try {
      log.debug("Getting new access token", "refresh token", refresh_token);

      this.currentRequest = originalFetch.apply(undefined, [tokenEndpoint, newRequestInit]);

      const response: Response = await this.currentRequest;

      if (response.status >= 400) {
        const error = await response.text();
        tokenStorage.clear();
        log.debug("Failed to get new access token", error);
      } else {
        const newToken = await response.json();
        log.debug("Saving new access token", newToken);
        tokenStorage.setToken(newToken);
      }
    } catch (e) {
      log.debug("Failed to get new access token", e);
      tokenStorage.clear();
      throw e;
    }
  };
}
