const isObject = (item: any) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

const isArray = (o: any) => {
  return Object.prototype.toString.call(o) === "[object Array]";
};

const isComplexObject = (o: any): boolean => {
  if (!o) {
    return false;
  }

  const objects = Object.keys(o).filter(
    (k) => Object.prototype.toString.call(o[k]) === "[object Object]"
  );

  const arrays = Object.keys(o).filter(
    (k) => Object.prototype.toString.call(o[k]) === "[object Array]"
  );

  if (objects.length || arrays.length) {
    const objResult = objects.filter(
      (k) => !!Object.keys(o[k]).find((kk) => kk === "linkType")
    ).length;
    const arrResult = arrays.filter((k) => {
      var r =
        o[k]?.length === 0 ? true : !!(o[k] as any[]).filter((oo) => !isComplexObject(oo)).length;
      return r;
    }).length;

    return !objResult && !arrResult;
  }

  return false;
};

const hasValue = (o: any, required?: boolean) => {
  if (isArray(o)) {
    return required ? (o as any[]).filter((oo) => !!oo).length > 0 : true;
  }
  const hasValue = typeof o == "number" || !!o;
  return !required || hasValue;
};

const mergeDeep = (target: any, ...sources: any): any => {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

const groupBy = (list: any, keyGetter: (k: any) => any): Map<any, any> => {
  const map = new Map();
  list.forEach((item: any) => {
    let key = keyGetter(item);
    if (key instanceof Date) {
      key = (key as Date).toLocaleString();
    }

    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
};

const arrayDiff = (a: any[], b: any[]) => {
  return a.filter((i) => b.indexOf(i) < 0);
};

const parseJsonAndFixDateTime = (o: string) => {
  const item = JSON.parse(o);
  if (!item) {
    return undefined;
  }
  Object.keys(item).forEach((prop) => {
    if (prop.toLowerCase().indexOf("datetime") >= 0) {
      item[prop] = new Date(item[prop]);
    }
  });

  return item;
};

const cloneObject = <T>(o: T) => {
  return JSON.parse(JSON.stringify(o)) as T;
};

export const collectionTypeRegex = /Collection\(([^)]+)\)/;
const isCollection = (type: string) => type.match(collectionTypeRegex)?.length === 1;

export const nameofFactory =
  <T>() =>
  (name: keyof T) =>
    name;

function isPrimitive(test: any) {
  return test !== Object(test);
}

const areObjectsEqual = (a: any, b: any): boolean => {
  if (a === b) {
    return true;
  }

  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }

  if (!a || !b || (typeof a !== "object" && typeof b !== "object")) {
    return a === b;
  }

  if (a === null || a === undefined || b === null || b === undefined) {
    return false;
  }

  if (a.prototype !== b.prototype) {
    return false;
  }

  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) {
    return false;
  }

  return keys.every((k) => {
    const result = areObjectsEqual(a[k], b[k]);

    return result;
  });
};

function removeNullAndEmptyProperties(obj: any): any {
  if (typeof obj !== "object" || obj === null) {
    // Base case: if obj is not an object or is null, return as is
    return obj;
  }

  if (Array.isArray(obj)) {
    // If obj is an array, iterate through its items and recursively remove null/empty properties
    return obj.map(removeNullAndEmptyProperties);
  }

  // If obj is an object, iterate through its properties and recursively remove null/empty properties
  const result: any = {};
  for (const key in obj) {
    const value = removeNullAndEmptyProperties(obj[key]);

    if (Array.isArray(value) && value.length === 0) {
      continue;
    }

    if (value !== null && value !== "") {
      // Exclude null and empty values
      result[key] = value;
    }
  }
  return result;
}

function getObjectSizeInBytes(obj: any) {
  let size = 0;

  function calculateSize(val: any) {
    const type = typeof val;
    if (type === "number") {
      size += 8; // Assuming 8 bytes for a JavaScript number
    } else if (type === "string") {
      size += val.length * 2; // Assuming 2 bytes per character for a JavaScript string
    } else if (type === "boolean") {
      size += 4; // Assuming 4 bytes for a JavaScript boolean
    } else if (type === "object" && !Array.isArray(val)) {
      for (const key in val) {
        if (val.hasOwnProperty(key)) {
          size += key.length * 2; // Assuming 2 bytes per character for a JavaScript object key
          calculateSize(val[key]);
        }
      }
    } else if (type === "object" && Array.isArray(val)) {
      for (let i = 0; i < val.length; i++) {
        calculateSize(val[i]);
      }
    }
  }

  calculateSize(obj);

  return size;
}

function deepClone<T>(obj: T): T {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj) as T;
  }

  if (obj instanceof RegExp) {
    return new RegExp(obj) as T;
  }

  if (obj instanceof Array) {
    const newArr = [];
    for (let i = 0; i < obj.length; i++) {
      newArr[i] = deepClone(obj[i]);
    }
    return newArr as T;
  }

  const newObj: Record<string, any> = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = deepClone(obj[key]);
    }
  }

  return newObj as T;
}

export const objectUtils = {
  mergeDeep,
  isObject,
  isArray,
  hasValue,
  groupBy,
  arrayDiff,
  isComplexObject,
  isCollection,
  parseJsonAndFixDateTime,
  cloneObject,
  isPrimitive,
  areObjectsEqual,
  removeNullAndEmptyProperties,
  getObjectSizeInBytes,
  deepClone,
};
