import { IconButton } from "@fluentui/react";
import { LoadingOverlay, useComponentLogger, useTheme } from "cayo.ui";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Layouts } from "react-grid-layout";
import { useIntl } from "react-intl";
import { IGridItemSizes, IGridLayout, IScheme, IWidget, ScreenWidth } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import useService from "../../services/services.hook";
import arrayUtils from "../../utils/array-utils";
import { objectUtils } from "../../utils/object-utils";
import ErrorBoundary from "../Common/ErrorBoundary";
import useRefreshKey from "../GlobalHooks/use-refresh-key.hook";
import Icon from "../Graphics/Icon";
import Column from "../Layout/Column";
import { IResizableGrid } from "../Layout/Grid";
import Row from "../Layout/Row";
import { useToggleNavCollapsed } from "../Nav/nav-hooks";
import GenericScheme from "../Schemes/GenericScheme";
import schemeParts from "../Schemes/scheme-parts";
import { WidgetTitle } from "../Widgets/common";
import DashboardError from "./DashboardError";
import EmptyDashboard from "./EmptyDashboard";
import WidgetsList from "./WidgetsList";
import dashboardMessages from "./messages";
import dashboardUtils from "./utils";

const dashboardId = schemeParts.ids.dashboard;

type DashboardProps = IScheme & { feature?: string };

const Dashboard: FC<DashboardProps> = (props) => {
  const intl = useIntl();
  const { feature } = props;
  const log = useComponentLogger(Dashboard);
  const dashboardName = feature || "";
  const dashboardClient = useService("dashboardClient");
  const { officeTheme } = useTheme();

  const [dashboardScheme, setDashboardScheme] = useState<IScheme | undefined>(undefined);
  const [isSelectWidgetsVisible, setSelectWidgetsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { key: refreshKey, forceRefresh } = useRefreshKey(dashboardId);
  const { isLoading: areWidgetsLoading, data: allWidgetsScheme } = ajax.useGet<IScheme | undefined>(
    "scheme/dashboard/widgets"
  );

  const allWidgets = useMemo<IWidget[] | undefined>(() => {
    return allWidgetsScheme?.items as IWidget[];
  }, [allWidgetsScheme]);

  const onGridLayoutChanged = async (scheme: IScheme, layouts: Layouts, changeLayout?: boolean) => {
    const gridLayout = scheme?.layout?.find((l) => l.type === "grid") as IGridLayout;
    if (gridLayout) {
      gridLayout.sizes = layouts;

      const prevSize = gridLayout.sizes["lg"]?.length;
      (["lg", "md", "sm", "xs", "xxs"] as ScreenWidth[]).forEach((size) => {
        gridLayout.sizes[size] = arrayUtils.uniqueByKey(gridLayout.sizes[size!]!, "i");
      });

      const curSize = gridLayout.sizes["lg"]?.length;
      if (prevSize !== curSize) {
        logger.debug("duplicates found");
      }

      gridLayout.items = arrayUtils.uniqueByKey(gridLayout.items!);

      const firstKey = Object.keys(layouts)[0];
      const newItems = layouts[firstKey].map((i) => i.i);
      const toDeleteItems = objectUtils.arrayDiff(gridLayout.items!, newItems);
      gridLayout.items = objectUtils.arrayDiff(gridLayout.items!, toDeleteItems);

      if (toDeleteItems?.length) {
        scheme.items = scheme.items?.filter((i) => toDeleteItems.find((ii) => ii !== i.name));
      }

      await dashboardClient.saveDashboard(dashboardName, scheme.layout![0] as IGridLayout);

      if (changeLayout) {
        setDashboardScheme(addLayoutChangedHandler(scheme));
      }
    }
  };

  const addLayoutChangedHandler = (scheme: IScheme) => {
    const gridLayout = scheme?.layout?.find((l) => l.type === "grid") as IResizableGrid;
    if (gridLayout) {
      gridLayout.onLayoutChange = (layouts) => onGridLayoutChanged(scheme, layouts, false);
      gridLayout.onDeleteCell = (key) => {
        const layouts = gridLayout.sizes;
        Object.keys(layouts).forEach((resolution) => {
          layouts[resolution] = layouts[resolution].filter((l: any) => l.i !== key);
        });

        onGridLayoutChanged(scheme, layouts as Layouts, true);
      };
    }

    return { ...scheme };
  };

  useEffect(() => {
    const loadDashboardScheme = async () => {
      log.debug("Loading dashboard...");

      let fallbackScheme = { ...props };

      try {
        const scheme = await dashboardClient.getDashboard(dashboardName);
        log.debug("Dashboard load complete -> scheme", scheme);

        setDashboardScheme(addLayoutChangedHandler(scheme!));
      } catch (e) {
        log.debug("Failed to load dashboard", e);

        fallbackScheme = (fallbackScheme as IScheme).layout?.length
          ? JSON.parse(JSON.stringify(fallbackScheme!))
          : [];
        setDashboardScheme(addLayoutChangedHandler(fallbackScheme));
      }
    };

    loadDashboardScheme();
  }, [refreshKey, dashboardName]);

  const title = useMemo(() => {
    if (dashboardScheme?.layout?.length) {
      return dashboardScheme.layout[0].name;
    }

    return undefined;
  }, [dashboardScheme]);

  const findOriginalSize = useCallback(
    (widgetName: string, size: ScreenWidth) => {
      const originalGridLayout = allWidgetsScheme!.layout![0] as IGridLayout;
      const originalSize = originalGridLayout.sizes[size]?.find((s) => s.i === widgetName);

      return originalSize;
    },
    [allWidgetsScheme]
  );

  const onAddWidget = useCallback(
    (widget: IWidget) => {
      if (!dashboardScheme!.layout) {
        dashboardScheme!.layout = [{ sizes: {} } as any];
      }
      const gridLayout = dashboardScheme!.layout![0] as IGridLayout;

      try {
        (["lg", "md", "sm", "xs", "xxs"] as ScreenWidth[]).forEach((size) => {
          const sizes = gridLayout.sizes[size];

          if (sizes) {
            let originalSize = findOriginalSize(widget.name!, size);

            if (originalSize) {
            } else {
              originalSize = dashboardUtils.getDefaultSize(widget.name!, size);
            }

            sizes!.push({
              ...originalSize,
              x: 0,
              y: Infinity,
              i: widget.name,
            } as IGridItemSizes);
          }
        });
        gridLayout.items!.push(widget.name!);

        dashboardScheme!.items?.push(widget);
        dashboardScheme!.items = [...dashboardScheme!.items!];
        dashboardScheme!.layout![0] = gridLayout;

        onGridLayoutChanged(dashboardScheme!, gridLayout.sizes as Layouts, true);
      } catch (e) {
        log.error("failed to add widget", widget, "error", e);
        return;
      }
    },
    [dashboardScheme]
  );

  const onResetBoard = useCallback(() => {
    setIsLoading(true);
    setDashboardScheme(undefined);
    ajax
      .getClient(`profile/dashboard/${dashboardName || "default"}`, "DELETE")
      .then(() => {
        setSelectWidgetsVisible(false);
        setIsLoading(false);

        forceRefresh();
      })
      .catch((e) => {
        log.error("Failed to delete default dashboard", e);
        setIsLoading(false);
      });
  }, [forceRefresh, dashboardName]);

  const { collapsed } = useToggleNavCollapsed();

  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event("resize")), 10);
  }, [collapsed]);

  if (isLoading || areWidgetsLoading || !dashboardScheme) {
    return <LoadingOverlay />;
  }

  const isDashboardEmpty = dashboardScheme && !dashboardScheme.items?.length && !isLoading;

  const dashboard = (
    <Column style={{ position: "relative", height: "100%", width: "100%" }}>
      <Row style={{ padding: 8 }} justifyContent="space-between" valign={true}>
        <WidgetTitle style={{ fontSize: 20, fontWeight: "normal", padding: "8px 20px 0 14px" }}>
          {title || intl.formatMessage(dashboardMessages.welcome)}
        </WidgetTitle>
        <IconButton
          onClick={() => setSelectWidgetsVisible(true)}
          onRenderIcon={(icnprops) => (
            <Icon
              iconName="GridViewSmall+EditSolid12"
              {...(icnprops as any)}
              style={{ color: officeTheme.semanticColors.link }}
            />
          )}
        ></IconButton>
      </Row>

      {!isDashboardEmpty && (
        <GenericScheme {...dashboardScheme} style={{ overflow: "hidden auto" }} />
      )}

      {isDashboardEmpty && <EmptyDashboard onResetView={onResetBoard} />}

      {isSelectWidgetsVisible && allWidgets?.length && (
        <WidgetsList
          widgets={allWidgets}
          currentWidgets={(dashboardScheme?.items as IWidget[]) || []}
          onWidgetSelected={(widget) => {
            log.debug("widget selected", widget);
          }}
          onClose={() => setSelectWidgetsVisible(false)}
          onAddWidget={onAddWidget}
          onResetBoard={onResetBoard}
        />
      )}
    </Column>
  );

  return (
    <ErrorBoundary fallbackComponent={() => <DashboardError onResetView={onResetBoard} />}>
      {dashboard}
    </ErrorBoundary>
  );
};

export default Dashboard;
