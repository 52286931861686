import { IColumn } from "cayo.ui";
import {
  AlertRule,
  ComparisonOperator,
  Job,
  OrderByDirection,
  SavedQuery,
  SoftLink,
} from "../../../api/cayo-graph";

export enum ConditionType {
  Anr = "anr",
  Advanced = "advanced",
  Property = "property",
  TimeInterval = "timeInterval",
  Expression = "expression",
}

export enum ExpressionLogicType {
  LogicalExpression = "cayo.graph.logicalExpression",
  PropertyExpression = "cayo.graph.propertyExpression",
  OdataQueryExpression = "cayo.graph.rawExpression",
}

export const logicalExpressionRegex = new RegExp(`(#)?${ExpressionLogicType.LogicalExpression}`);
export const propertyExpressionRegex = new RegExp(`(#)?${ExpressionLogicType.PropertyExpression}`);
export const odataQueryExpressionRegex = new RegExp(
  `(#)?${ExpressionLogicType.OdataQueryExpression}`
);

export enum ExpressionGroupCombinationType {
  AND = "and",
  OR = "or",
}

export enum ExpressionOperandOperator {
  equals = "equals",
  notEquals = "notEquals",
  contains = "contains",
  noContains = "noContains",
  present = "present",
  notPresent = "notPresent",
  greaterThan = "greaterThan",
  greaterThanOrEqual = "greaterThanOrEqual",
  lessThan = "lessThan",
  lessThanOrEqual = "lessThanOrEqual",
  startsWith = "startsWith",
}

export enum TimeIntervalType {
  LastHour = "cayo.graph.lastHourInterval",
  LastDay = "cayo.graph.lastDayInterval",
  LastWeek = "cayo.graph.lastWeekInterval",
  LastMonth = "cayo.graph.lastMonthInterval",
  LastDays = "cayo.graph.lastDaysInterval",
  Custom = "cayo.graph.customInterval",
}

export enum PropertyDataType {
  StringCollection = "#Collection(String)",
  SoftLinkCollection = "#Collection(cayo.graph.softLink)",
}

export interface IQueryOrderBy {
  field: string;
  direction: OrderByDirection;
}

export interface IQuery {
  readonly id?: EntityId;
  readonly name?: string;
  readonly conditions: Readonly<Array<Condition>>;
  readonly isSystem: boolean;
  readonly orderBy?: Readonly<IQueryOrderBy>;
  readonly isTemp?: boolean;
  readonly isDefault?: boolean;
  readonly groupBy?: string;
  readonly effectiveFilter?: string;
  readonly alertRule?: AlertRule;
  readonly job?: Job;
}

export type SavedQueryItem = Pick<SavedQuery, "id" | "name" | "builtIn" | "default">;

export type PropertyObjectType = {
  id: string;
  type: string;
  key: string;
};

export type PropertyOperandType = {
  objectType: ExpressionLogicType.PropertyExpression;
  property: string;
  operator: ComparisonOperator;
  propertyObjectType: PropertyObjectType;
  enabled: boolean;
  value: string;
  columns?: IColumn[];
  valueLink?: { [key: string]: any };
  metadata?: { [key: string]: any };
};

export type OdataQueryOperandType = {
  objectType: ExpressionLogicType.OdataQueryExpression;
  enabled: boolean;
  filter: string;
  columns?: IColumn[];
};

export type LogicalOperandType = {
  objectType: ExpressionLogicType.LogicalExpression;
  operator: ExpressionGroupCombinationType;
  enabled: boolean;
  columns?: IColumn[];
  operands: Array<LogicalOperandType | PropertyOperandType | OdataQueryOperandType>;
};

export function isPropertyOperandType(
  el: PropertyOperandType | LogicalOperandType | OdataQueryOperandType
): el is PropertyOperandType {
  return (
    el.objectType === ExpressionLogicType.PropertyExpression ||
    (el.objectType.startsWith("#") &&
      el.objectType.slice(1) === ExpressionLogicType.PropertyExpression)
  );
}

export function isOdataQueryOperandType(
  el: PropertyOperandType | LogicalOperandType | OdataQueryOperandType
): el is OdataQueryOperandType {
  return (
    el.objectType === ExpressionLogicType.OdataQueryExpression ||
    (el.objectType.startsWith("#") &&
      el.objectType.slice(1) === ExpressionLogicType.OdataQueryExpression)
  );
}

export interface IExpressionCondition {
  kind: ConditionType.Expression;
  objectType: ExpressionLogicType;
  operator: ExpressionGroupCombinationType;
  operands: Array<LogicalOperandType | PropertyOperandType | OdataQueryOperandType>;
}

export interface IExpressionAdvancedPicker extends IExpressionCondition {
  operands: Array<
    LogicalOperandType & {
      name: string;
    }
  >;
}

export interface IAnrCondition {
  kind: ConditionType.Anr;
  value: string;
}
export interface IAdvancedCondition {
  kind: ConditionType.Advanced;
  value: string;
}
export type IPropCondition = IStringPropCondition | ISoftLinkPropCondition;

export interface IStringPropCondition {
  kind: ConditionType.Property;
  propName: string;
  dataType: PropertyDataType.StringCollection;
  value: Readonly<Array<string>>;
}

export interface ISoftLinkPropCondition {
  kind: ConditionType.Property;
  propName: string;
  dataType: PropertyDataType.SoftLinkCollection;
  value: Readonly<Array<SoftLink & { displayName?: string }>>;
}

export interface ITimeIntervalCondition {
  kind: ConditionType.TimeInterval;
  propName: string;
  value: TimeIntervalConditionValue;
}

export type Condition =
  | Readonly<IAnrCondition>
  | Readonly<IAdvancedCondition>
  | Readonly<IPropCondition>
  | Readonly<ITimeIntervalCondition>
  | Readonly<IExpressionCondition>;

type FixedInterval = {
  objectType:
    | TimeIntervalType.LastHour
    | TimeIntervalType.LastDay
    | TimeIntervalType.LastWeek
    | TimeIntervalType.LastMonth;
};

type LastDaysInterval = {
  objectType: TimeIntervalType.LastDays;
  number: Integer;
};
type CustomInterval = {
  objectType: TimeIntervalType.Custom;
  startDateTime: DateISO;
  endDateTime: DateISO;
};

export type TimeIntervalConditionValue =
  | Readonly<FixedInterval>
  | Readonly<LastDaysInterval>
  | Readonly<CustomInterval>;

export function setCondition(query: IQuery, condition: Condition): IQuery {
  const predicate = makeFilterPredicate(condition);
  const result = {
    ...query,
    conditions: query.conditions.filter(predicate).concat([{ ...condition }]),
  };
  return result;
}
export function clone(query: IQuery): IQuery {
  return JSON.parse(JSON.stringify(query));
}
function makeFilterPredicate(inp: Condition) {
  return (c: Condition) => {
    if (c?.kind !== inp?.kind) return true;

    switch (c.kind) {
      case ConditionType.Expression:
        return false;
      case ConditionType.TimeInterval:
      case ConditionType.Property:
        return c.propName !== (inp as ITimeIntervalCondition).propName;
      default:
        return false;
    }
  };
}

export function setOrderBy(query: IQuery, order: IQueryOrderBy | undefined): IQuery {
  return { ...query, orderBy: order };
}

export function assignQuery(query: IQuery, props: Partial<IQuery>): IQuery {
  return { ...query, ...props };
}

export function newQuery(): IQuery {
  return { isSystem: false, conditions: [] };
}

export function removeCondition(query: IQuery, condition: Condition): IQuery {
  return { ...query, conditions: query.conditions.filter(makeFilterPredicate(condition)) };
}

// TODO: deep object comparing for expressions
export function isConditionsEqual(
  a: Readonly<Array<Condition>>,
  b: Readonly<Array<Condition>>
): boolean {
  //remove empty conditions
  const one = a.filter((c) => !isEmptyCondition(c));
  const two = b.filter((c) => !isEmptyCondition(c));
  if (one.length !== two.length) {
    return false;
  }
  while (!!one.length) {
    const oneItem = one.shift();
    const twoItem = two.find((item) => {
      if (item.kind === oneItem?.kind) {
        if (item.kind === ConditionType.Property) {
          return item.propName === (oneItem as IPropCondition).propName;
        }
        return true;
      }
      return false;
    });
    if (!twoItem) return false;
    switch (oneItem?.kind) {
      case ConditionType.Anr:
        if (!anrEquals(oneItem, twoItem as IAnrCondition)) return false;
        break;
      case ConditionType.Expression:
        if (!expressionEquals(oneItem, twoItem as IExpressionCondition)) return false;
        break;
      case ConditionType.Advanced:
        if (!advancedEquals(oneItem, twoItem as IAdvancedCondition)) return false;
        break;
      case ConditionType.TimeInterval:
        if (!timeIntervalsEqual(oneItem, twoItem as ITimeIntervalCondition)) return false;
        break;
      case ConditionType.Property:
        if (!propertyConditionEquals(oneItem, twoItem as IPropCondition)) return false;
        break;
    }
  }
  return true;
}

function expressionEquals(a: IExpressionCondition, b: IExpressionCondition): boolean {
  return JSON.stringify(a.operands) === JSON.stringify(b.operands);
}

function anrEquals(a: IAnrCondition, b: IAnrCondition): boolean {
  return a.value === b.value;
}

function advancedEquals(a: IAdvancedCondition, b: IAdvancedCondition): boolean {
  return a.value === b.value;
}

function timeIntervalsEqual(a: ITimeIntervalCondition, b: ITimeIntervalCondition): boolean {
  if (a.propName !== b.propName) {
    return false;
  }
  if (a.value.objectType !== b.value.objectType) {
    return false;
  }
  switch (a.value.objectType) {
    case TimeIntervalType.LastDays:
      return a.value.number === (b.value as LastDaysInterval).number;
    case TimeIntervalType.Custom:
      return (
        a.value.endDateTime === (b.value as CustomInterval).endDateTime &&
        a.value.startDateTime === (b.value as CustomInterval).startDateTime
      );
    default:
      return true;
  }
}

function propertyConditionEquals(a: IPropCondition, b: IPropCondition): boolean {
  if (a.propName !== b.propName) return false;
  return JSON.stringify(a.value) === JSON.stringify(b.value);
}

function isEmptyCondition(condition: Condition): boolean {
  switch (condition.kind) {
    case ConditionType.Expression:
      return condition.operands.length === 0;
    case ConditionType.Advanced:
    case ConditionType.Anr:
      return !condition.value;
    case ConditionType.Property:
      return condition.value.length === 0;
    case ConditionType.TimeInterval:
      return false;
  }
}

export function filterDisabledOperandsFromExpression(
  conditionElements: Array<LogicalOperandType | PropertyOperandType | OdataQueryOperandType>
): Array<LogicalOperandType | PropertyOperandType | OdataQueryOperandType> {
  return conditionElements?.filter((c) => {
    if (
      c.objectType === ExpressionLogicType.PropertyExpression ||
      propertyExpressionRegex.test(c.objectType) ||
      c.objectType === ExpressionLogicType.OdataQueryExpression ||
      odataQueryExpressionRegex.test(c.objectType)
    ) {
      return c.enabled;
    } else {
      return c.enabled ? filterDisabledOperandsFromExpression(c.operands) : false;
    }
  });
}
