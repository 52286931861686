import { stringUtils } from "cayo.ui";

const apiPrefix = "v0";

const rootPaths = {
  settings: "settings",
  system: "system",
  configuration: "configuration",
  userInterface: "userInterface",
};

const entitySets = {
  subscriptions: "subscriptions",
  productDatabases: "productDatabases",
  settings: "settings",
  serviceSettings: "serviceSettings",
};

export class ApiPath {
  parts: string[];
  constructor(...parts: string[]) {
    this.parts = parts;
    this.parts.unshift(apiPrefix);
  }

  public toString = (): string => {
    return this.parts.map((s) => stringUtils.trim(s, "/")).join("/");
  };
}

const apiPaths = {
  managedTenants: new ApiPath(rootPaths.configuration, "managedTenants").toString(),
  settings: new ApiPath(rootPaths.settings),
  historyDatabaseSettings: new ApiPath(
    rootPaths.system,
    entitySets.productDatabases,
    "adbe4f48b8084c2db9a7b72098de44d4/cayo.graph.historyDatabase?$select=*"
  ).toString(),
  uiSettings: new ApiPath(
    rootPaths.userInterface,
    entitySets.settings,
    "246795A5-D750-4FF8-8909-C9BDC08243FA"
  ).toString(),
  managedDomains: new ApiPath(rootPaths.configuration, "managedDomains").toString(),
  subscriptions: new ApiPath(rootPaths.settings, "subscriptions").toString(),
  serverSubscriptions: new ApiPath(rootPaths.settings, "serverSubscriptions").toString(),
  networkSettings: new ApiPath(
    rootPaths.settings,
    entitySets.serviceSettings,
    "88c035a40e02498c9118c9689b2f3e83/cayo.graph.networkSettings?$select=*"
  ).toString(),
};

export default apiPaths;
