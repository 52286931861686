import { PrimaryButton, Stack } from "@fluentui/react";
import React, { FC } from "react";
import { alertAction } from "../alerts.api";
import useActionButton from "./hooks";

type ActionsProps = { actions: alertAction[] };

const Actions: FC<ActionsProps> = ({ actions }) => {
  const { primaryButtons } = useActionButton(actions);

  return (
    <Stack
      horizontal={true}
      styles={{ root: { selectors: { ".ms-Button": { margin: "0 !important" } } } }}
      tokens={{ childrenGap: 4 }}
    >
      {primaryButtons.map((b, i) => (
        <div key={i}>
          <PrimaryButton {...b} />
        </div>
      ))}
    </Stack>
  );
};

export default Actions;
