import { useMemo } from "react";
import { PossibleValueItem } from "../../api/cayo-graph";
import { IPickerItem } from "../ObjectPicker/IPickerItem";

export const useQuickFieldTitle = (
  shortName: string,
  value: IPickerItem[],
  possibleValues?: PossibleValueItem[]
) => {
  const title = useMemo(() => {
    if (!value || !value.length) {
      return { title: "", shortName };
    }

    const firstValue = value[0].link as any; // TODO: remove any
    const length = value.length;
    let title =
      typeof firstValue === "string"
        ? (firstValue as string)
        : firstValue.displayName || firstValue.objectName;

    title = possibleValues?.find((p) => p.value === title)?.name || title;
    if (length === 1) {
      return { title };
    }

    return { title: `${title} (+${length - 1})` };
  }, [value]);

  return title;
};
