import { ITheme, themeUtils } from "cayo.ui";
import styled, { keyframes } from "styled-components";

const Border = styled.div<{
  disappeared?: boolean;
  highlighted?: boolean;
}>`
  overflow: hidden;
  display: flex;
  flex-flow: column;
  max-width: 100%;
  width: 100%;
  padding-right: 16px !important;
  padding-left: 16px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px, rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px;
  border: ${(props) =>
    props.highlighted
      ? `2px solid ${themeUtils.addAlpha(
          (props.theme as ITheme).cayoTheme.brandColors.warning,
          0.5
        )}`
      : "unset"};
  background-color: ${(props) => (props.theme as ITheme).officeTheme.semanticColors.bodyBackground};
  transition: width 600ms ease-out, height 600ms ease-out;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.21) 0px 0.3px 0.9px, rgba(0, 0, 0, 0.233) 0px 1.6px 3.6px;
  }

  position: relative;
  right: ${(props) => (props.disappeared ? "-150%" : 0)};
  animation-duration: 0.5s;

  animation-name: ${(props) => (props.disappeared ? disappearEffect : "none")};

  animation-delay: 0s;
  animation-timing-function: cubic-bezier(0.1, 0.3, 0.1, 0.1);
`;

const disappearEffect = keyframes`
  0% {
    right: 0%;
  }

  100% {
    right: -120%;
  }
`;

export default Border;
