import { IconButton } from "@fluentui/react";
import React from "react";
import { getMenuButtonStyles } from "../Alerts/Alerts.styles";
import { useToggleNavCollapsed } from "../Nav/nav-hooks";

const ToggleNavCollapseButton = () => {
  const { toggleCollapsed } = useToggleNavCollapsed();
  return (
    <IconButton
      aria-label="Menu button"
      iconProps={{ iconName: "GlobalNavButton" }}
      onClick={toggleCollapsed}
      styles={getMenuButtonStyles(false, 50)}
    />
  );
};

export default ToggleNavCollapseButton;
