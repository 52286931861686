import { Icon, IconButton, Link, Panel as OfficePanel, Text } from "@fluentui/react";
import styled from "styled-components";
import { Row } from "../../CommonLayout";

export const RightPanel = styled(OfficePanel)`
  margin-top: 65px;
  padding: 0px 4px !important;
`;

export const DismissAllAlertsLink = styled(Link)`
  align-items: center;
  display: flex;
  padding-right: 10px;
`;

export const ForwardIcon = styled(Icon)`
  font-size: 10px;
  font-weight: bold;
  padding-left: 4px;
  padding-top: 2px;
`;

export const LargeLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const Row2 = styled(Row)`
  align-content: center;
  justify-content: space-between;
  padding: 14px 10px 10px 18px;
`;

export const CloseIcon = styled(IconButton)`
  margin-right: 10px;
`;

export const BoldText = styled(Text)`
  font-weight: bold;
`;

export const Row1 = styled(Row)`
  align-content: center;
  justify-content: space-between;
  padding: 14px 0px 10px 16px;
`;

export const BadgeWrapper = styled.div`
  position: relative;
  height: 100%;
`;
