import React from "react";
import {
  ICounter,
  ICounterSeries,
  IDatasetWidget,
  IODataWidget,
  IProtectedObjects,
  ITimeSeries,
  IWidget,
} from "../../api/schema.api";
import Counter from "./Counter";
import CounterSeries from "./CounterSeries";
import DatasetWidget from "./DatasetWidget";
import ODataWidget from "./ODataWidget";
import ProtectedObjects from "./ProtectedObjects";
import TimeSeries from "./TimeSeries";
import Widget from "./Widget";

const recentchanges = (props: ITimeSeries) => {
  return <TimeSeries {...props} />;
};

const timeseries = (props: ITimeSeries) => {
  return <TimeSeries {...props} />;
};

const counter = (props: ICounter) => {
  return <Counter {...props} />;
};

const counterseries = (props: ICounterSeries) => {
  return <CounterSeries {...props} />;
};

const protectedobjects = (props: IProtectedObjects) => {
  return <ProtectedObjects {...props} />;
};

const widget = (props: IWidget) => {
  return <Widget key={props.name} {...props} />;
};

const datasetwidget = (props: IDatasetWidget) => {
  return <DatasetWidget key={props.name} {...props} />;
};

const odatawidget = (props: IODataWidget) => {
  return <ODataWidget key={props.name} {...props} />;
};

const widgetRenderers = {
  recentchanges,
  protectedobjects,
  datasetwidget,
  widget,
  counter,
  counterseries,
  timeseries,
  odatawidget,
};

export default widgetRenderers;
