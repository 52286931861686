import { useEffect, useState } from "react";
import { endpoints } from "../../services/endpoints.service";
import { urlUtils } from "../../utils/url-utils";

type Manifest = {
  name: string;
  version: string;
  customVariable: string;
  features: { [key: string]: string };
};

const useManifest = () => {
  const [manifest, setManifest] = useState<Manifest | null>(null);

  useEffect(() => {
    const fetchManifest = async () => {
      const manifestUrl = urlUtils.buildRelativePath(endpoints.PUBLIC_URL, "/manifest.json");
      const response = await fetch(manifestUrl);
      const data = await response.json();
      setManifest(data);
    };

    fetchManifest();
  }, []);

  return manifest;
};

export default useManifest;
