import { DirectionalHint, getId, Stack, Text, TooltipDelay, TooltipHost } from "@fluentui/react";
import React from "react";
import styled from "styled-components";
import { ExecutionError } from "../../api/cayo-graph";
import { FCC } from "../../types/react";
import clipboardUtils from "../../utils/clipboard-utils";
import CopyButton from "../Utilities/CopyButton";

const ErrorTooltip: FCC<{ errors: ExecutionError[]; id: string }> = ({ id, errors, children }) => {
  const hostId: string = getId("tooltipHost");

  return (
    <TooltipHost
      tooltipProps={{
        onRenderContent: () => {
          return (
            <Stack horizontal={true} verticalAlign={"center"}>
              <ErrorsWrapper horizontal={false} key={id}>
                {errors?.map((e, i) => (
                  <Text key={i}>{e.message}</Text>
                ))}
              </ErrorsWrapper>
              <CopyButton
                onClick={() =>
                  errors &&
                  clipboardUtils.copyArrayToClipboard(
                    errors.filter((e) => !!e).map((e) => e.message!)
                  )
                }
              />
            </Stack>
          );
        },
      }}
      delay={TooltipDelay.zero}
      id={hostId}
      directionalHint={DirectionalHint.bottomCenter}
      styles={{
        root: { display: "inline-block", cursor: "pointer", width: "100%", height: "100%" },
      }}
    >
      {children}
    </TooltipHost>
  );
};

const ErrorsWrapper = styled(Stack)`
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
`;

export default ErrorTooltip;
