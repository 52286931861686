import { defineMessages } from "react-intl";

export const commandBarMessages = defineMessages({
  filter: {
    defaultMessage: "Filter",
    id: "command-bar.filter",
  },
  RefreshTitle: {
    defaultMessage: "Click to refresh your current view",
    id: "command-bar.refresh-btn.title",
  },
  RefreshTitleNewData: {
    defaultMessage: "There are new or updated records. Click to refresh your current view.",
    id: "command-bar.refresh-btn.title-new-data",
  },
  ResetView: {
    defaultMessage: "Reset columns",
    id: "command-bar.reset-view",
  },
  NoGrouping: {
    defaultMessage: "No grouping",
    id: "command-bar.no-grouping",
  },
  browseAll: {
    defaultMessage: "Saved queries",
    id: "command-bar.browse",
  },
  newFilter: {
    defaultMessage: "New query",
    id: "command-bar.new-filter",
  },
  clearFilter: {
    defaultMessage: "Clear query",
    id: "command-bar.clear-filter",
  },
  editFilter: {
    defaultMessage: "Edit query",
    id: "command-bar.edit-filter",
  },
  copyFilter: {
    defaultMessage: "Copy query",
    id: "command-bar.copy-filter",
  },
  saveFilter: {
    defaultMessage: "Save",
    id: "command-bar.save-filter",
  },
  saveAsFilter: {
    defaultMessage: "Save As...",
    id: "command-bar.saveas-filter",
  },
  saveNewFilterConfirmation: {
    defaultMessage: "There are unsaved changes in your query. Do you want to save changes?",
    id: "command-bar.save-new-filter-confirmation",
  },
  standardQueries: {
    defaultMessage: "Standard Queries",
    id: "command-bar.standard-queries",
  },
  deleteFilterConfirmation: {
    defaultMessage: 'Are you sure you want to delete "{name}" saved query?',
    id: "command-bar.delete-filter-confirmation",
  },
  searchFilter: {
    defaultMessage: "Search for more queries",
    id: "command-bar.search-filter-placeholder",
  },
  recentQueries: {
    defaultMessage: "Recent queries",
    id: "quick-filter-bar.query-list-recent-header",
  },
  queryNotFound: {
    defaultMessage: "No query found",
    id: "quick-filter-bar.not-found-message",
  },
  chooseColumns: {
    defaultMessage: "Choose columns",
    id: "quick-filter-bar.choose-columns",
  },
  selectedCount: {
    defaultMessage: "{count} selected",
    id: "command-bar.selected-count",
  },
});
