import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { IContentScheme } from "../../../api/schema.api";
import ajax from "../../../libs/ajax";
import { urlUtils } from "../../../utils/url-utils";

const useOdataScheme = (schemeId?: string) => {
  const { data: propertyAnnotations, isLoading: isAnnotationsLoading } =
    ajax.usePropertyAnnotations(`v0/{schemeId}`, { schemeId });

  const {
    data: scheme,
    isLoading,
    error,
  } = ajax.useGet<IContentScheme>(`scheme/content/{schemeId}`, {
    schemeId,
  });

  return { isLoading: isAnnotationsLoading || isLoading, scheme, propertyAnnotations, error };
};

export const useGridSettingsKey = (componentId?: string) => {
  const location = useLocation();
  const gridSettingsKey = useMemo(
    () => urlUtils.trim(location.pathname + componentId),
    [location.pathname, componentId]
  );

  return gridSettingsKey;
};

export default useOdataScheme;
