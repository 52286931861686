import { ActionButton, Callout as OfficeCallout, DirectionalHint } from "@fluentui/react";
import { themeUtils, useTheme } from "cayo.ui";
import React, { FC } from "react";
import { ICallout } from "../../api/schema.api";
import useCallout from "../GlobalHooks/callout.hook";
import GenericScheme from "../Schemes/GenericScheme";
import menuButtonStyles from "./common";

const Callout: FC<ICallout> = (props) => {
  const theme = useTheme();
  const { isCalloutVisible, onShowTooltip, onDismiss } = useCallout();

  const id = props.id || props.name;
  return (
    <>
      <ActionButton
        id={id}
        ariaLabel={id}
        text=""
        onClick={() => (isCalloutVisible ? onDismiss() : onShowTooltip())}
        styles={menuButtonStyles.getNavHeaderStyles(theme, false)}
        iconProps={themeUtils.getIconProps(props.icon)}
        style={{ height: "100%", width: "50px" }}
      />

      {isCalloutVisible && (
        <OfficeCallout
          target={`#${id}`}
          setInitialFocus={false}
          directionalHint={DirectionalHint.topCenter}
          onDismiss={onDismiss}
          gapSpace={-10}
          role="alertdialog"
          style={{ minWidth: "250px", padding: "20px 24px" }}
        >
          <GenericScheme {...props} />
        </OfficeCallout>
      )}
    </>
  );
};

export default Callout;
