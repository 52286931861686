import { IBreadcrumbItem, INavLink, INavLinkGroup } from "@fluentui/react";
import { stringUtils } from "cayo.ui";
import { INavLink as IAdminNavLink } from "../../api/schema.api";
import logger from "../../libs/logger";
import arrayUtils from "../../utils/array-utils";

const log = logger.getLogger("NavController");

export function parsePath(value: string): string[] {
  const result = value
    .split("/")
    .slice(1)
    .reduce((acc, _, i, arr) => {
      acc.push(arr.slice(0, i + 1).join("/"));
      return acc;
    }, [] as string[]);

  return result;
}

function toPathname(nodeId: string): string {
  return nodeId === "/" ? nodeId : `/${nodeId}`;
}

export function toNodeId(pathname: string): string {
  return pathname === "/" ? pathname : pathname.replace(/^\//, "");
}

function makePath(parentNode: IAdminNavLink, fetchResult: FetchResult): string {
  return `${parentNode.path}/${fetchResult.name}`;
}

function makeRouteUrl(node: IAdminNavLink): string {
  return node.isSelectable === false ? "" : `#${node.id}`;
}

function makeChildLinksSourceUrl(
  parentNode: IAdminNavLink,
  fetchResult: EffectiveNavigationProperty,
  isAdminMode: boolean
): string | undefined {
  const isCollection = fetchResult.objectType.startsWith("Collection(");

  if (isCollection && isAdminMode) {
    return undefined;
  }
  const path = `v0/${makePath(parentNode, fetchResult)}`;
  return isCollection ? path : `${path}/effectiveNavigationProperties`;
}

function makeEntityLinkName(item: Entity, isAdminMode: boolean): string {
  if (isAdminMode) {
    return item.displayName ?? item.name ?? item.objectName;
  } else {
    return item.displayName ?? stringUtils.toSpaceDelimitedPascalCase(item.name) ?? item.objectName;
  }
}

type childLinksSourceUrl = string;
export class NavController extends EventTarget {
  nodesTree: IAdminNavLink[];
  index: Map<string, IAdminNavLink>;
  loading: Map<childLinksSourceUrl, null>;
  static lookapedNodesEvt = "nodesLookuped";
  static locationChangedEvent = "";

  constructor(private readonly isAdminMode: boolean) {
    super();
    this.nodesTree = [];
    this.index = new Map<string, IAdminNavLink>();
    this.loading = new Map<childLinksSourceUrl, null>();
    //this.makeNavLink = this.makeNavLink.bind(this);
    this.addToIndex = this.addToIndex.bind(this);
    this.processNavPropSubnodes = this.processNavPropSubnodes.bind(this);
    this.getSubNodes = this.getSubNodes.bind(this);
    this.getFullName = this.getFullName.bind(this);
  }

  public setRootNodes(nodes?: IAdminNavLink[] | null) {
    log.debug("setRootNodes -> nodes", nodes);
    this.index.clear();
    this.nodesTree = nodes ?? [];
    this.addToIndex(nodes);
  }

  private addToIndex(node: IAdminNavLink | IAdminNavLink[] | undefined | null) {
    if (!node) {
      return;
    }
    if (Array.isArray(node)) {
      node.forEach(this.addToIndex);
    } else {
      if (this.index.has(node.id)) {
        log.debug(
          "NavController.addToIndex node",
          node,
          "exists in index",
          this.index.get(node.id)
        );
      }
      this.index.set(node.id, node);
      this.addToIndex(node.links);
    }
  }

  public getNavGroups(): INavLinkGroup[] {
    return [{ links: this.nodesTree.map(this.makeNavLink) }];
  }

  makeNavLink = (node: IAdminNavLink): INavLink => {
    const isCustomIcon = node.icon && node.icon.indexOf("+") > 0;
    const icon = node.icon;

    return {
      url: makeRouteUrl(node),
      name: node.name,
      icon,
      isCustomIcon,
      key: node.id,
      isExpanded: node.expanded,
      links:
        !node.links && !!node.childLinksSourceUrl
          ? this.createLoadingStub(node.id)
          : node.links?.map(this.makeNavLink),
    };
  };
  private createLoadingStub(keyPrefix: string): INavLink[] {
    return [{ key: `${keyPrefix}loading-link`, name: "Loading...", url: "" }];
  }

  public getById(id: string): IAdminNavLink | undefined {
    return this.index.get(id);
  }

  appendLinks(node: IAdminNavLink, nodes: IAdminNavLink[]) {
    //#9604 filter if links not exists in childrenOrders

    if (node.childrenOrders?.length) {
      nodes = nodes.filter((n) => node.childrenOrders?.indexOf(n.id) !== -1);
    }

    let joinedLinks = arrayUtils.unique<IAdminNavLink>((node.links ?? []).concat(nodes));

    if (node.childrenOrders?.length) {
      const idx = node.childrenOrders;
      // log.debug(
      //   "appendLinks sort by childOrder",
      //   node.id,
      //   idx,
      //   joinedLinks.map((l) => l.id)
      // );
      joinedLinks.sort((a, b) => idx.indexOf(a.id) - idx.indexOf(b.id));

      // log.debug(
      //   "appendLinks sorted",
      //   joinedLinks.map((l) => l.id)
      // );
    }
    node.links = joinedLinks;
    this.addToIndex(nodes);
  }

  public async loadSubNodes(
    parentNode: IAdminNavLink,
    fetchFn: FetchSublinks,
    getIcon: IconResolver
  ): Promise<boolean> {
    log.debug("loadSubNodes -> parentNode", parentNode);
    if (!parentNode.childLinksSourceUrl) {
      return false;
    }
    if (parentNode.childLinksLoaded === true) {
      return false;
    }
    if (this.loading.has(parentNode.childLinksSourceUrl)) {
      return false;
    }
    this.loading.set(parentNode.childLinksSourceUrl, null);
    const links = await this.getSubNodes(parentNode, fetchFn, getIcon);
    this.loading.delete(parentNode.childLinksSourceUrl);

    this.appendLinks(parentNode, links);
    parentNode.childLinksLoaded = true;
    return true;
  }

  private async getSubNodes(
    parentNode: IAdminNavLink,
    fetchFn: FetchSublinks,
    getIcon: IconResolver
  ) {
    let result: IAdminNavLink[] = [];

    try {
      const fetchResult = await fetchFn(parentNode.childLinksSourceUrl ?? "");

      if (fetchResult.length === 0) {
        return result;
      } else if ("objectPath" in (fetchResult[0] as any)) {
        return this.processEntitySubnodes(fetchResult as Entity[], parentNode, getIcon);
      } else if ("navigationHideContainer" in (fetchResult[0] as any)) {
        return await this.processNavPropSubnodes(
          fetchResult as EffectiveNavigationProperty[],
          parentNode,
          fetchFn,
          getIcon
        );
      }
    } catch (err) {
      log.error("Get subnodes error -> parentNode, err", parentNode, err);
    }
    return result; // ?maybe throw error?
  }

  private async processNavPropSubnodes(
    fetchResult: EffectiveNavigationProperty[],
    parentNode: IAdminNavLink,
    fetchFn: FetchSublinks,
    getIcon: IconResolver
  ): Promise<IAdminNavLink[]> {
    const filtered = fetchResult.filter(
      (v) => (v.navigationOrder ?? Number.MAX_SAFE_INTEGER) > 0 && !v.navigationHideContainer
    );

    filtered.sort(
      (one, two) =>
        (one.navigationOrder ?? Number.MAX_SAFE_INTEGER) -
        (two.navigationOrder ?? Number.MAX_SAFE_INTEGER)
    );

    const childLinks: IAdminNavLink[] = filtered.map((item) => ({
      icon: getIcon(item.objectType),
      id: `${parentNode.id}/${item.name}`,
      name: item.displayName ?? stringUtils.toSpaceDelimitedPascalCase(item.name)!,
      isSelectable: item.objectType.startsWith("Collection("),
      path: makePath(parentNode, item),
      childLinksSourceUrl: makeChildLinksSourceUrl(parentNode, item, this.isAdminMode),
    }));

    const hiddenNodes = fetchResult.filter((v) => v.navigationHideContainer);
    if (hiddenNodes.length !== 0) {
      //load subnodes

      for (const node of hiddenNodes) {
        const virtParentNode = {
          ...parentNode,
          path: makePath(parentNode, node),
          childLinksSourceUrl: makeChildLinksSourceUrl(parentNode, node, false),
        };
        const subNodes = await this.getSubNodes(virtParentNode, fetchFn, getIcon);
        childLinks.push(...subNodes);
      }
    }

    return childLinks;
  }

  private processEntitySubnodes(
    fetchResult: Entity[],
    parentNode: IAdminNavLink,
    getIcon: IconResolver
  ): IAdminNavLink[] {
    const childLinks: IAdminNavLink[] = fetchResult.map((item) => ({
      icon: getIcon(item.objectType),
      id: `${parentNode.id}/${item.id}`,
      name: makeEntityLinkName(item, this.isAdminMode),
      path: item.objectPath,
      isSelectable: false,
      childLinksSourceUrl: `v0/${item.objectPath}/effectiveNavigationProperties`,
    }));
    return childLinks;
  }
  public async lookupNodetree(
    path: string,
    fetchFn: FetchSublinks,
    getIcon: IconResolver
  ): Promise<LookupNodeTreeResult> {
    const treePath = parsePath(path);
    log.debug("lookupNodetree treePath", treePath);
    const result: LookupNodeTreeResult = { nodeId: "", needRerender: false };

    for (let i = 0; i < treePath.length; i++) {
      const node = this.getById(treePath[i]);
      log.debug("lookupNodetree -> node", node);
      if (!node) {
        break;
      }
      const success = await this.loadSubNodes(node, fetchFn, getIcon);
      result.nodeId = node.id;
      result.needRerender = result.needRerender || success;
    }
    return result;
  }
  public getValidPath(path: string): string {
    if (!path || path === "/") return "/";
    const id = path.replace(/^\//, "");
    return !!this.getById(id) ? path : "/";
  }

  public async loadRootSubnodes(fetchFn: FetchSublinks, getIcon: IconResolver) {
    const rootSubnodes = this.nodesTree.filter((n) => n.id !== "/");
    log.debug(
      "loadRootSubnodes -> rootSubnodes",
      rootSubnodes.map((n) => n.id)
    );
    const result = await Promise.all(
      rootSubnodes.map((n) => this.loadSubNodes(n, fetchFn, getIcon))
    );
    log.debug("loadRootSubnodes -> result", result);
  }

  public makeBreadcrumbs(path: string, rootName?: string): IBreadcrumbItem[] {
    const treePath = parsePath(path);

    log.debug("makeBredcrumbs -> treePath", treePath);
    const result = treePath
      .map((id) => {
        const node = this.getById(id);
        return node
          ? { key: node.id, text: node.name, href: makeRouteUrl(node) }
          : (null as unknown as IBreadcrumbItem);
      })
      .filter((it) => it !== null);
    log.debug("makeBredcrumbs -> result", result);
    if (path === "/") {
      const dashboardNode = this.getById("/")!;
      result.push({ key: "/", text: dashboardNode.name, href: makeRouteUrl(dashboardNode) });
    }
    // remove href for last item
    if (result.length !== 0) {
      result[result.length - 1].href = "";
    }
    return [{ key: "guardian", href: "#/", text: rootName || "Guardian" }, ...result];
  }

  public expandNodesByPath(path: string): boolean {
    const treePath = parsePath(path);
    log.debug("expandNodesByPath -> treePath", treePath);
    const needReRender = treePath.reduce((pv, id) => {
      const node = this.getById(id);
      log.debug("expandNodesByPath -> node", node);
      if (!!node && !node.expanded) {
        node.expanded = true;
        return true;
      }
      return pv;
    }, false);
    return needReRender;
  }

  public async handleLocation(
    location: Location,
    fetchFn: FetchSublinks,
    getIcon: IconResolver
  ): Promise<HandleLocationResult> {
    // try find node
    log.debug("handleLocation -> location", location);
    if (this.index.has(toNodeId(location.pathname))) {
      const indexedNode = this.getById(toNodeId(location.pathname));

      log.debug("handleLocation node in index", indexedNode);
      const loadedSubnodes = await this.loadSubNodes(indexedNode!, fetchFn, getIcon);
      this.dispatchEvent(
        new CustomEvent(NavController.locationChangedEvent, { detail: location.pathname })
      );
      return {
        needRerender: this.expandNodesByPath(location.pathname) || loadedSubnodes,
        location,
        selectedKey: indexedNode!.id,
      };
    }
    // try find node by path
    const node = Array.from(this.index.values()).find(
      (v) => v.path === toNodeId(location.pathname)
    );
    if (!!node) {
      log.debug("handleLocation node by path", node);
      const loadedSubnodes = await this.loadSubNodes(node, fetchFn, getIcon);
      return {
        needRerender: false || loadedSubnodes,
        location: { ...location, pathname: toPathname(node.id) },
        selectedKey: "/",
      };
    }

    // try load subtree
    const { needRerender, nodeId } = await this.lookupNodetree(location.pathname, fetchFn, getIcon);
    log.debug("handleLocation load node subtree -> result ", needRerender, nodeId);
    if (nodeId !== toNodeId(location.pathname)) {
      log.debug("handleLocation subtree loaded partially -> result ", needRerender, nodeId);
      return {
        needRerender: this.expandNodesByPath(location.pathname) || needRerender,
        location: { pathname: toPathname(nodeId), search: location.search },
        selectedKey: nodeId,
      };
    }
    log.debug("handleLocation subtree loaded full -> result ", needRerender, nodeId);
    this.dispatchEvent(
      new CustomEvent(NavController.locationChangedEvent, { detail: location.pathname })
    );

    return {
      needRerender: this.expandNodesByPath(location.pathname) || needRerender,
      location,
      selectedKey: nodeId,
    };
  }

  public getFullName(path: string): string {
    const treePath = parsePath(toPathname(path));
    // log.debug("getFullName -> path, treePath", path, treePath, this.index);
    const names = treePath.map((p) => this.getById(p)?.name);
    return names.join(" > ");
  }
}

type HandleLocationResult = {
  needRerender: boolean;
  location: Location;
  selectedKey: string;
};

type Location = Readonly<{ pathname: string; search: string }>;

type IconResolver = (objectType: string) => string;
export type FetchSublinks = (url: string) => Promise<unknown[]>;

type FetchResult = {
  name: string;
  objectType: string;
  displayName?: string;
};

type EffectiveNavigationProperty = FetchResult & {
  navigationOrder?: number;
  expandOrder: number;
  navigationHideContainer: boolean;
};

type Entity = FetchResult & { id: string; objectPath: string; objectName: string };

type LookupNodeTreeResult = { nodeId: string; needRerender: boolean };
