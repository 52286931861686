import { Severity } from "../api/cayo-graph";
import { ErrorInfo } from "../api/error-info.model";
import { appUtils } from "../components/App/utils";
import logger from "../libs/logger";

const log = logger.getLogger("ajax-utils");
const parseResponse = (responseText: string) => {
  const resultData200 = undefined;
  try {
    return JSON.parse(responseText);
  } catch {
    return resultData200;
  }
};

const getError = (e: any): Promise<ErrorInfo> => {
  log.error(e);

  if (typeof e === "string") {
    return Promise.resolve({ error_description: e });
  }

  if (e.message) {
    return Promise.resolve({ error_description: e.message });
  }

  let text: any;
  try {
    if (e instanceof Response) {
      text = e.text();
    } else {
      const firstError = Object.values(e).shift() as Array<string>;
      let errorDesc: ErrorInfo = { error_description: JSON.stringify(e) };
      if (Array.isArray(firstError) && firstError.length) {
        const firstErrorString = firstError[0];
        errorDesc = {
          error_description:
            typeof firstError[0] === "string" ? firstErrorString : JSON.stringify(firstErrorString),
        };
        return Promise.resolve(errorDesc);
      }

      return Promise.resolve(errorDesc);
    }
  } catch (ee) {
    log.error("Failed to parse response", ee);
    return Promise.resolve({ error_description: e.statusText ?? "Unknown error" });
  }

  return text.then((responseText: string) => {
    const resultData200 = parseResponse(responseText);

    let errorInfo = resultData200 as ErrorInfo;
    if (!errorInfo) {
      errorInfo = {};
    }

    if (!errorInfo.error_description) {
      const resultKeys = resultData200 && Object.keys(resultData200);

      errorInfo.error_description =
        (resultData200 &&
          (resultData200?.message ||
            (resultKeys?.length > 0 ? resultData200[resultKeys[0]] : undefined))) ||
        e.statusText ||
        e.status ||
        responseText ||
        "Unknown error";
    }

    log.debug("response parsed", errorInfo);

    if (errorInfo.severity) {
      errorInfo.severity = fromOdataSeverity(errorInfo.severity);
    }
    return Promise.resolve(errorInfo);
  });
};

const fromOdataSeverity = (severity?: string): Severity | undefined => {
  if (!severity) {
    return undefined;
  }
  severity = severity.toLowerCase();

  if (severity === "info") {
    return "informational";
  }

  return severity as Severity;
};

const handleError = async (e: any) => {
  const error = await ajaxUtils.getError(e);
  let msg = error?.error_description;
  if (Array.isArray(msg)) {
    log.debug(msg);

    msg = msg[0];
  }
  appUtils.showError(msg);
};

const getHeaders = (response: any) => {
  let headers: any = {};
  if (response.headers && response.headers.forEach) {
    response.headers.forEach((v: any, k: any) => (headers[k] = v));
  }

  return headers;
};

export const ajaxUtils = {
  getError,
  handleError,
  getHeaders,
};
