import React from "react";
import styled from "styled-components";
import { FCC } from "../types/react";

const StyledBadge = styled.div<{
  fontSize?: string | number;
  height?: number;
  position?: string;
  right?: number;
  top?: number;
  color?: string;
  width?: number;
}>`
  position: ${(props) => props.position || "absolute"};
  top: ${(props) => props.top ?? 8}px;
  right: ${(props) => props.right ?? 8}px;
  border-radius: ${(props) => (props.width === props.height ? "50%" : "3px")};
  background-color: ${(props) => props.color};
  width: ${(props) => props.width ?? 16}px;
  height: ${(props) => props.height ?? 16}px;
  color: white;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize + (typeof props.fontSize === "number" ? "px" : "") : "8px"};
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: none;
`;

const Badge: FCC<{
  fontSize?: string | number;
  height?: number;
  position?: string;
  right?: number;
  top?: number;
  color?: string;
  width?: number;
}> = (props) => <StyledBadge {...props}>{props.children} </StyledBadge>;

export default Badge;
