import { ResponsiveMode } from "@fluentui/react";
import { createContext } from "react";
import { Entity } from "../../api/cayo-graph";
import { AlertType } from "../Alerts/AlertDispatcher";
import { IAlertItem } from "../Alerts/alerts.api";
import { IUserMessage, ServerError } from "./types";

export type RightPanelType = "help" | AlertType;

export interface IClientComponentParameters {
  object?: Entity;
  refreshAfterClose?: boolean;
  reloadAfterClose?: boolean;
  onClose?: (result?: boolean) => void;
  onOk?: (result?: any) => Promise<void>;
  onSaveComplete?: () => Promise<void>;
}

export interface IClientComponent {
  component: any;
  parameters?: IClientComponentParameters | undefined;
}

export interface IAppContext {
  breadcrumbInfo?: any[] | undefined;
  closeWizardConfirmMessage?: string | undefined;
  confirmationMessage?: string | undefined;
  confirmationWarning?: string | undefined;
  message?: IUserMessage | undefined;
  hideConfirmation: () => void;
  onConfirmed?: () => Promise<void>;

  responsiveMode?: ResponsiveMode;
  rightPanelType?: RightPanelType;
  serverError?: ServerError | undefined | null;

  setBreadcrumbInfo: (info: any[] | undefined) => void;

  setLocale: (locale: string) => void;
  setStaticAlerts: (alerts?: IAlertItem[]) => void;

  showConfirmation: (message: string, onConfirmed: () => Promise<void>, warning?: string) => void;
  showError: (error: string | undefined) => void;
  showMessage: (msg: IUserMessage | undefined) => void;

  showRightPanel: (type?: RightPanelType) => void;

  showServerUnavailable: (error: ServerError | null) => void;

  staticAlerts?: IAlertItem[] | undefined;

  webSockerConnectionLost?: boolean | undefined;
}

const AppContext = createContext<IAppContext>({
  hideConfirmation: () => null,
  onConfirmed: () => new Promise(() => null),
  setBreadcrumbInfo: () => null,
  setLocale: () => null,
  setStaticAlerts: () => null,
  showConfirmation: () => null,
  showRightPanel: () => null,

  showError: () => null,
  showMessage: () => null,
  showServerUnavailable: () => null,
  staticAlerts: [],
});

export default AppContext;
