import { arrayUtils } from "cayo.ui";
import { IAlertItem } from "./alerts.api";

export function alertsReducer(state: IAlertItem[], action: IAlertItem[]) {
  const alertsEx = action.map((a) => ({
    ...a,
    lastModifiedDateTime: new Date(a.lastModifiedDateTime),
    severity_n: severities[a.severity],
  }));

  const result = arrayUtils.sortByMultipleProperties(
    alertsEx,
    ["ownerId", "desc"],
    ["severity_n", "desc"],
    ["lastModifiedDateTime", "desc"]
  );

  return result as IAlertItem[];
}

const severities = {
  none: 0,
  debug: 1000,
  informational: 2000,
  success: 3000,
  warning: 4000,
  error: 5000,
  low: 6000,
  medium: 7000,
  high: 8000,
  critical: 9000,
};
