import { IDateTimeBox, ITextField, dateTimeUtils } from "cayo.ui";
import { IntlShape } from "react-intl";
import { DailySchedule } from "../../api/cayo-graph";
import { IFormField } from "../../api/schema.api";
import { nameofFactory } from "../../utils/object-utils";
import { regexs } from "../../utils/regexs";
import { formComponentTypes } from "../Form/renderers/form-renderers";
import messages from "./messages";

const dailyNames = nameofFactory<DailySchedule>();

const dailyForm = (intl: IntlShape, schedule?: DailySchedule) => {
  const startTime = dateTimeUtils.convertUtcTimeToLocal(
    dateTimeUtils.extractTime(schedule?.startTime) || dateTimeUtils.convertTimeToUtc("00:00")
  );

  return {
    title: intl.formatMessage(messages.daily),
    items: [
      {
        type: "datetimebox" as formComponentTypes,
        label: intl.formatMessage(messages.startTime),
        autoFocus: true,
        required: true,
        editMode: "onlyTime",
        name: dailyNames("startTime"),
        value: startTime as any,
      } as IDateTimeBox,
      {
        type: "number" as formComponentTypes,
        label: intl.formatMessage(messages.repeatEvery),
        required: true,
        pattern: regexs.monthDaysRange.source,
        name: dailyNames("period"),
        value: schedule?.period?.toString() || "1",
        suffix: intl.formatMessage(messages.daysTip),
      } as ITextField,
    ] as IFormField[],
  };
};

export default dailyForm;
