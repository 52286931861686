import { getId } from "@fluentui/utilities";
import { useCallback, useReducer, useState } from "react";
import { Entity } from "../../api/cayo-graph";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import { ajaxUtils } from "../../utils/ajax-utils";
import { urlUtils } from "../../utils/url-utils";

import { singletonHook } from "react-singleton-hook";
import panelReducer from "../AppRoots/PropertiesPanelHolder/properties-panel.reducer";
import { IPropertiesPanel } from "../AppRoots/PropertiesPanelHolder/types";
import { IAppContext, IClientComponent } from "./app.context";
import { IShowControlHandler } from "./types";
import { appSignals, appUtils, showActionParams } from "./utils";

export const appReducer = (old: IAppContext, newModel: Partial<IAppContext>) => {
  return { ...old, ...newModel } as IAppContext;
};

const initialPanels: IPropertiesPanel[] = [];

const _useDetachedSchemes = () => {
  const [propertiesPanels, setPropertiesPanels] = useReducer(panelReducer, []);

  const [clientComponents, setClientComponents] = useState<IClientComponent[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const hideClientComponents = useCallback(() => setClientComponents([]), []);

  const showControl = useCallback(
    (prms: IShowControlHandler) => {
      const id = prms.controlId!;

      if (prms.folder && prms.folder.indexOf(".") > 0) {
        appSignals.send("showClientWizard", {
          schemeId: prms.controlId!,
          package: prms.folder,
          reloadAfterClose: prms.reloadAfterClose,
        });
        return;
      }

      if (id === "scheme") {
        if (prms.parameters) {
          showPropertiesPanel(
            typeof prms?.parameters === "string"
              ? { id: prms.parameters, parentId: (prms as any).parentContextId }
              : prms.parameters
          );
        } else {
          closePropertiesPanel();
        }

        return;
      }

      // TODO: delete this bullshit
      const reloadAfterClose =
        prms.reloadAfterClose === false
          ? false
          : prms.reloadAfterClose ||
            id.toLowerCase().indexOf("license") >= 0 ||
            id.toLowerCase().indexOf("activation") >= 0;

      const folder = prms.folder ? `${prms.folder}/` : "";

      import("../../components/" + folder + id)
        .then((module) => {
          try {
            const clientParameters: any =
              Object.prototype.toString.call(prms?.parameters) === "[object Array]"
                ? { items: prms.parameters }
                : prms.parameters;

            setClientComponents([
              ...(clientComponents || []),
              {
                component: module.default,
                parameters: {
                  reloadAfterClose: reloadAfterClose,
                  onRefresh: (prms as any)?.onRefresh,
                  ...clientParameters,
                  onClose: function onCloseHandler(result: boolean) {
                    hideClientComponents();

                    prms.parameters?.onClose && prms.parameters.onClose(result);
                    prms.refreshAfterClose && refreshPropertiesPanel();

                    const isDeleteAction = (prms?.parameters?.url as string)?.endsWith("/delete");
                    const closeHandler = prms.parameters?.closeHandler;
                    if (
                      (closeHandler && prms.controlId !== "LegacyCollectActionParametersDialog") ||
                      isDeleteAction
                    ) {
                      closePropertiesPanel();
                    }
                  },
                },
              },
            ]);
          } catch (e) {
            logger.log(e);
          }
        })
        .catch((e) => {
          if (e.code === "MODULE_NOT_FOUND") {
            appSignals.send("showClientWizard", {
              schemeId: prms.controlId!,
              package: prms.folder,
              reloadAfterClose: prms.reloadAfterClose,
            });
          } else {
            appUtils.showError(e);
            logger.error(e);
          }

          return;
        });
    },
    [clientComponents, propertiesPanels]
  );

  const loadObject = async (objectPath: string, noRetry?: boolean | undefined): Promise<any> => {
    setIsLoading(true);

    try {
      const object = await ajax.getClient("v0/" + objectPath, "GET", undefined, undefined, {
        $select: "*",
      });

      return object;
    } catch (e: any) {
      if (noRetry) {
        return undefined;
      }

      if (e.status === 404 && urlUtils.isLastSegmentTypeCast(objectPath)) {
        const fallbackPath = urlUtils.replaceLastSegment(objectPath, "");
        const fallbackObject = await loadObject(fallbackPath, true);
        if (fallbackObject) {
          return fallbackObject;
        }
      }

      logger.debug(e);
      await ajaxUtils.handleError(e);
    } finally {
      setIsLoading(false);
    }

    return undefined;
  };

  const showPropertiesPanel = useCallback(
    (scheme: IPropertiesPanel) => {
      scheme.key = new Date().toISOString();
      if (scheme.id) {
        const existingPanel = propertiesPanels.find(
          (p) => p.id === scheme.id && p.parentId === scheme.parentId
        );

        // const lastViewState = new LastViewStateStorage(scheme.id);
        // if (!scheme.isPicker) {
        //   lastViewState.saveModel(undefined);
        // }

        if (existingPanel) {
          setPropertiesPanels({ type: "show", panel: existingPanel });
          return;
        }

        if (scheme.isNewObject || scheme.id.endsWith("/new")) {
          if (scheme.isNewObject) {
            scheme.parentId = getId("new_panel");
          }
          setPropertiesPanels({ type: "show", panel: scheme });
        } else {
          const isComplex = scheme.id.indexOf("returnParameters=") > 0 || !!scheme.property?.name;
          setIsLoading(true);

          if (scheme.isPicker) {
            if (!scheme.parentId && propertiesPanels.length) {
              scheme.parentId = [...propertiesPanels].reverse().find((p) => !!p.id)?.id;
            }
            setPropertiesPanels({ type: "show", panel: scheme });
            setIsLoading(false);
          } else {
            (isComplex ? Promise.resolve() : loadObject(scheme.id))
              .then((object) => {
                if (object || isComplex) {
                  const objects = (scheme as any).objects as Entity[];
                  if (!object && objects?.length) {
                    object = objects[0];
                  }
                  scheme.object = object;
                  scheme.id = object.objectPath || scheme?.property?.parentObjectPath;
                  if (isComplex && scheme?.property) {
                    scheme.isNewObject = true;
                  }
                  setPropertiesPanels({ type: "show", panel: scheme });
                }
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        }
      }
    },
    [propertiesPanels]
  );

  const updatePropertiesPanel = useCallback(
    (scheme: IPropertiesPanel) => {
      if (!scheme?.id) {
        return;
      }

      const existingPanel = propertiesPanels.find((p) => p.parentId === scheme?.parentId);
      if (existingPanel) {
        loadObject(scheme.id).then((object) => {
          if (object) {
            scheme.id = object.objectPath;
            scheme.object = object;
            setPropertiesPanels({ type: "update", panel: scheme });
          }
        });
      }
    },
    [propertiesPanels]
  );

  const refreshPropertiesPanel = useCallback(() => {
    const existingPanel =
      propertiesPanels.length > 0 ? propertiesPanels[propertiesPanels.length - 1] : undefined;
    if (existingPanel && existingPanel.id) {
      return loadObject(existingPanel.id!).then((object) => {
        if (object) {
          existingPanel.object = object;
          existingPanel.id = object.objectPath;
          setPropertiesPanels({ type: "update", panel: existingPanel });
        }
      });
    }

    return Promise.resolve();
  }, [propertiesPanels]);

  const removeClientComponent = useCallback(() => {
    if (clientComponents?.length && clientComponents.length > 0) {
      clientComponents.splice(clientComponents.length - 1);
      setClientComponents([...clientComponents]);
    }
  }, [clientComponents]);

  const closePropertiesPanel = useCallback(
    (parentId?: string) => {
      setPropertiesPanels({ type: "close", panel: { parentId } });

      // if (propertiesPanels?.length && propertiesPanels.length > 0) {
      //   propertiesPanels.splice(propertiesPanels.length - 1);

      // }
    },
    [propertiesPanels]
  );

  const showAction = useCallback((prms: showActionParams & { gridId?: string | undefined }) => {
    appSignals.send("showAction", {
      schemeId: prms.schemeId,
      objects: prms.objects,
      independent: prms?.gridId === "dummy",
      onSaveComplete: prms.onSaveComplete,
      actionParameters: prms.actionParameters,
    });

    return Promise.resolve();
  }, []);

  return {
    propertiesPanels,
    clientComponents,
    showPropertiesPanel,
    updatePropertiesPanel,
    refreshPropertiesPanel,
    closePropertiesPanel,
    closeAllPropertiesPanels: () => setPropertiesPanels({ type: "closeAll" }),
    removeClientComponent,
    showControl,
    showAction,
    hideClientComponents,
    isLoading,
  };
};

export const useSchemes = singletonHook(
  {
    isLoading: false,
    clientComponents: [],
    propertiesPanels: initialPanels,
    showPropertiesPanel: () => null,
    updatePropertiesPanel: () => null,
    refreshPropertiesPanel: () => Promise.resolve(),
    closePropertiesPanel: () => null,
    closeAllPropertiesPanels: () => null,
    showControl: () => null,
    showAction: () => Promise.resolve(),
    removeClientComponent: () => null,
    hideClientComponents: () => null,
  },
  _useDetachedSchemes
);
