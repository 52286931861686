import { useTheme } from "cayo.ui";
import { useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useIntl } from "react-intl";
import { Severity } from "../../api/cayo-graph";
import { IAlertPanel } from "../../api/schema.api";
import logger from "../../libs/logger";
import { endpoints } from "../../services/endpoints.service";
import {
  ALERTS_PANE_MAX_ITEMS,
  NotificationAction,
  updateInteractiveItemsReducer,
} from "../../services/notification.service";
import customUrlUtils from "../../utils/custom-url-utils";
import AppContext from "../App/app.context";
import AlertsService, { IAlertItem } from "./alerts.api";

import downloadUtils from "../../utils/download-utils";
import { useSchemes } from "../App/hooks";
import { appUtils } from "../App/utils";
import { commonMessages } from "../common-messages";
import { alertDispatcher, AlertType, IUpdateNewAlerts } from "./AlertDispatcher";
import { FirstAlertButtonId } from "./constants";
import hightlightedAlerts from "./hightlighted-alerts";
import alertUtils from "./utils";

const useAlerts = (alertType: AlertType, props: IAlertPanel) => {
  const intl = useIntl();
  const { getColor } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [alertItems, dispatchAlerts] = useReducer(
    updateInteractiveItemsReducer,
    [] as IAlertItem[]
  );
  const [updateTime, setUpdateTime] = useState<Date | undefined>(undefined);
  const [clickedItem, setClickedItem] = useState<IAlertItem | undefined>(undefined);
  const { rightPanelType, showRightPanel } = useContext(AppContext);

  const alertsService = useMemo(
    () =>
      new AlertsService(
        endpoints.apiEndpoint,
        props.newAlertsStatusUrl!,
        props.newAlertsUrl!,
        props.dismissAlertsUrl!,
        props.dismissAllAlertsUrl
      ),
    []
  );

  const badgeProps = useMemo(() => {
    const bageText = !alertItems
      ? ""
      : alertItems.length < ALERTS_PANE_MAX_ITEMS
      ? `${alertItems.length}`
      : `${ALERTS_PANE_MAX_ITEMS - 1}+`;

    const badgePos =
      bageText.length > 2
        ? {
            height: 18,
            right: 10,
            top: 16,
            width: 18,
          }
        : {
            right: 10,
            top: 16,
          };

    return { bageText, badgePos };
  }, [alertItems.length]);

  const badgeColor = useMemo(() => {
    const alerts = alertItems as IAlertItem[];
    let highestSeverity: Severity = "success";
    for (const priority of severityPriorities) {
      if (alerts.find((a) => a.severity === priority)) {
        highestSeverity = priority;
        break;
      }
    }

    const color = getColor(highestSeverity, "Severity");
    return color;
  }, [alertItems]);

  const dismissAlert = (a: IAlertItem) => {
    setIsLoading(true);

    return alertsService
      .dismissAlert(a)
      .then(() => {
        alertDispatcher.send("popupAlerts", { action: NotificationAction.Remove, newItems: [a] });
      })
      .catch((e) => {
        log.error("dismissAlert -> error", e);
      })
      .finally(() => setIsLoading(false));
  };

  // subscribe on notifications and get initial alerts
  useEffect(() => {
    return alertDispatcher.subscribe(alertType, (data) => {
      if (!data) {
        setUpdateTime(new Date());
        return;
      }

      dispatchAlerts(data!);

      const newAlertItems =
        data?.newItems
          ?.map((a) => a as IAlertItem)
          .filter((a) => {
            return !a.disappeared;
          }) ?? [];

      if (
        newAlertItems.length &&
        (data!.action === NotificationAction.Add || data!.action === NotificationAction.Modify)
      ) {
        alertDispatcher.send("popupAlerts", {
          action: NotificationAction.Modify,
          newItems: newAlertItems,
          buttonId: FirstAlertButtonId,
        } as IUpdateNewAlerts);
      }
    });
  }, []);

  useEffect(() => {
    return hightlightedAlerts.subscribe("highlightAlerts", (data) => {
      if (data && alertItems.find((i) => i.id === data[0].id)) {
        dispatchAlerts({ action: NotificationAction.Modify, newItems: data });
      }
    });
  }, []);

  useEffect(() => {
    alertsService.getNewAlerts().then((response) => {
      // !!! use for debugging !!!
      // alertDispatcher.send("popupAlerts", {
      //   action: NotificationAction.Modify,
      //   newItems: response.alerts,
      //   buttonId: ChangeAlertButtonId,
      // } as IUpdateNewAlerts);

      dispatchAlerts({
        action: NotificationAction.Replace,
        newItems: alertUtils.hightlightAlerts(response.alerts),
      });
    });
  }, [updateTime, rightPanelType]);

  const { showPropertiesPanel } = useSchemes();

  // handle alert click
  useEffect(() => {
    if (!clickedItem) {
      return;
    }

    if (rightPanelType !== "changeAlerts") {
      alertItems!.map<IAlertItem>((a) => a as IAlertItem).forEach((a) => (a.disappeared = true));

      dispatchAlerts({
        action: NotificationAction.Modify,
        newItems: alertItems,
      });
    }

    const urlInfo = clickedItem.action
      ? customUrlUtils.extractUrlFromAnchor(clickedItem.action)
      : { url: (clickedItem as any)?.objectPath, type: "scheme" };

    if (!urlInfo || !urlInfo.url) {
      setClickedItem(undefined);
      appUtils.showError(intl.formatMessage(commonMessages.invalidUrlAction));
      return;
    }

    if (urlInfo?.type === "scheme") {
      const action =
        clickedItem && clickedItem.action
          ? customUrlUtils.getActionUrl(urlInfo?.url)
          : customUrlUtils.getFirstActionUrl(clickedItem.actions) || urlInfo?.url;

      showPropertiesPanel({ id: action, parentId: "alerts" });
    } else {
      downloadUtils.downloadODataFile(urlInfo!.url);
    }

    setClickedItem(undefined);
  }, [clickedItem]);

  const clickedAlertRef = useRef(null);

  useEffect(() => {
    const el = clickedAlertRef.current
      ? (ReactDOM.findDOMNode(clickedAlertRef.current) as HTMLElement)
      : null;
    if (el) {
      el.scrollIntoView({ behavior: "smooth", inline: "nearest" });
    }

    return () => {
      if (rightPanelType !== alertType) {
        clickedAlertRef.current = null;
      }
    };
  }, [alertItems]);

  return {
    isLoading,
    setIsLoading,
    alertItems,
    dispatchAlerts,
    alertsService,
    bageText: badgeProps.bageText,
    bagePos: badgeProps.badgePos,
    badgeColor,
    rightPanelType,
    showRightPanel,
    dismissAlert,
    setClickedItem,
    setUpdateTime,
    clickedAlertRef,
  };
};

const severityPriorities: Severity[] = [
  "critical",
  "high",
  "error",
  "medium",
  "warning",
  "low",
  "informational",
];

const log = logger.getLogger("useAlerts.hook");

export default useAlerts;
