import {
  IDateTimeBox,
  IFormField,
  ITextField,
  dateTimeUtils,
  mvConverters,
  stringUtils,
} from "cayo.ui";
import { IUIItem } from "cayo.ui/lib/esm/interfaces/common/IUIItem";
import React, { Fragment } from "react";
import { IJsonForm, IObjectPicker } from "../../../api/schema.api";
import logger from "../../../libs/logger";

import { SoftLink } from "../../../api/cayo-graph";
import { IActionBuilder } from "../../../scheme/actions";
import { evaluateODataLogicalExpression } from "../../../scheme/transforms/odata-logical-expression-evaluator";
import { nameofFactory, objectUtils } from "../../../utils/object-utils";
import { IPickerItem } from "../../ObjectPicker/IPickerItem";
import { StringsToEntities } from "../../ObjectPicker/logic";
import IAuxRenderParams from "../../Schemes/AuxRenderProps";
import { ILayoutHierarchy, layoutRenderers } from "../../Schemes/renderers/renderers.layout";
import modelUtils from "../modelUtils";
import auxPropsUtils, { resolveProperyExpression } from "../modelUtils/aux-props-utils";
import { isPicker, isPropertyGroup } from "../modelUtils/build-model";
import { deleteModelProperty, setModelProperty } from "../modelUtils/utils";
import { IAdditionalFormFieldProps, IAdditionalFormProps } from "../types";
import formRenderers from "./form-renderers";
import pickerRenderers from "./picker-renderers";
import { buttonRenderers } from "./renderers.buttons";

type ComplexItem = IUIItem & {
  items?: IUIItem[];
};

const itemPropNames = nameofFactory<IFormField>();

const renderForm = (
  props: IJsonForm & { actionBuilder?: IActionBuilder },
  addProps: IAdditionalFormProps
) => {
  if (!props?.items?.length) {
    return <Fragment />;
  }

  const { actionBuilder } = props;

  const visibleItems = modelUtils.getVisibleItems(
    props.items,
    addProps.model,
    addProps?.parentModel
  );

  if (
    visibleItems.length === 0 &&
    isPropertyGroup(props) &&
    (props as ITextField).nullValueDecorator
  ) {
    return (props as ITextField).nullValueDecorator;
  }

  let result = visibleItems!.map<ILayoutHierarchy>((a: ComplexItem, i) => ({
    render: () => {
      const { model, errors, setErrorCallback, setModel, propertyAnnotations } = addProps;

      const type = a!.type;

      const buttonRenderer = a.type && buttonRenderers[stringUtils.dashedToLowCase(a.type)];
      if (buttonRenderer) {
        // a.type === "submit-button" ? addProps.isSaveDisabled : undefined,
        return buttonRenderer(a, {
          theme: addProps.theme,
          actionBuilder,
          handleResponse: addProps.handleResponse,
          signals: addProps.signals,
        } as IAuxRenderParams);
      }

      const renderKey = stringUtils.dashedToLowCase(type);
      const formFieldRenderer =
        (type && (formRenderers[renderKey] || pickerRenderers[renderKey])) ?? formRenderers.text;
      if (formFieldRenderer) {
        const picker = isPicker(a) ? (a as unknown as IObjectPicker) : undefined;
        const childItems = a.items;

        const selectPropertyName = picker?.selectPropertyName;

        const name = (a as any).modelProperty || a.name;
        let value = selectPropertyName
          ? StringsToEntities(model[name!], selectPropertyName)
          : model[name!];

        if (isPropertyGroup(a) && a.bindings && childItems?.length) {
          childItems.forEach((childItem) => (childItem.bindings = a.bindings));
        }

        if (typeof value === "string" && value.indexOf("{") >= 0 && addProps?.parentModel) {
          const unresolvedValue = value;
          value = resolveProperyExpression(unresolvedValue, addProps?.parentModel);
          log.debug(
            `Resolve value expression, name:`,
            a.name,
            "unresolvedValue:",
            unresolvedValue,
            "model:",
            addProps?.parentModel,
            "result:",
            value
          );
        }

        const addFormFieldProps: IAdditionalFormFieldProps = {
          value,
          error: errors[name],
          disabled: (() => {
            // if (errors[a.name!]) {
            //   setErrorCallback(a.name!, undefined);
            // }

            if (a.bindings) {
              const disabledKey = Object.keys(a.bindings).find(
                (p) => p === itemPropNames("disabled")
              );
              const expression = disabledKey && a.bindings[disabledKey];
              if (expression) {
                const result = evaluateODataLogicalExpression(expression, model);

                let hasChanges = false;
                if (result) {
                  hasChanges = auxPropsUtils.setPropDisabled(model, name!);
                } else {
                  hasChanges = auxPropsUtils.setPropEnabled(model, name!);
                }

                if (hasChanges) {
                  setModel({ type: "update", model });
                }
                return result;
              }
            }
            return false;
          })(),
          setValue: (v, annotation) => {
            const name = (annotation as any).modelProperty || annotation.name;
            if (picker) {
              model![name!] =
                (v && (v as IPickerItem[])?.map<SoftLink>((ii) => ii.link as any)) || [];
            } else {
              model![name!] = v;
            }

            const original = addProps.object;
            if (original) {
              let originalValue = modelUtils.resolvePropertyValue(name, original);

              let currentValue = v;
              const converter = mvConverters.from[annotation.type];
              if (converter) {
                currentValue = converter(currentValue);
              }
              if (
                annotation.type === "datetimebox" &&
                (annotation as IDateTimeBox)?.editMode === "onlyTime"
              ) {
                // date time box
                originalValue = originalValue.slice(0, 5);
                try {
                  currentValue = dateTimeUtils.convertTimeToUtc(currentValue);
                } catch (e) {
                  log.error(e);
                }
              }

              if (!annotation.isStandalone) {
                if (objectUtils.areObjectsEqual(originalValue, currentValue)) {
                  deleteModelProperty(name, model);
                } else {
                  setModelProperty(name, true, model);
                }
              }
            }

            setModel({ type: "update", model });
          },
          setError: setErrorCallback,
          intl: addProps.intl,
          object: addProps?.object,
          propertyAnnotations: propertyAnnotations,
          setMessage: addProps?.setMessage,
          getIconAnnotation: addProps?.getIconAnnotation,
          theme: addProps?.theme,
          signals: addProps?.signals,
        };

        try {
          return formFieldRenderer(a, addFormFieldProps, addProps);
        } catch (e) {
          return formRenderers.text(a as any, addFormFieldProps);
        }
      }
    },
    index: i,
    name: a.name,
  }));

  props.layout?.forEach((l, lIndex) => {
    const intersection = visibleItems?.filter((f) => l.items?.includes(f.name!));
    if (!intersection || !intersection.length) {
      throw new Error("Invalid schema");
    }

    log.debug("layout " + l.type + " will contain ", intersection);

    const index = visibleItems!.findIndex((f) => f.name! === intersection[0].name);
    const excludedIds = intersection.map((i) => i.name);

    const childrenRenderers = result.filter((f) => excludedIds.includes(f.name));

    const lConfig: ILayoutHierarchy = {
      render: () => layoutRenderers[l.type!](l, childrenRenderers, lIndex.toString() + index),
      index,
    };

    const resultIndex = result.findIndex((r) => r.index === index);
    result[resultIndex] = lConfig;

    result = result.filter((f) => !excludedIds.includes(f.name));
  });

  return result.map((r) => r.render());
};

const log = logger.getLogger("form.renderer");

export default renderForm;
