import { IButtonStyles } from "@fluentui/react";
import { useTheme } from "cayo.ui";
import { useMemo } from "react";

export function useQuickFilterBarButtonStyle(): IButtonStyles {
  const { officeTheme } = useTheme();

  return useMemo(
    () =>
      ({
        root: {
          background: "transparent",
          height: 40,
          minWidth: 32,
          paddingLeft: 4,
          display: "flex",
          selectors: {
            ":hover": {
              background: officeTheme.semanticColors.buttonBackgroundHovered,
            },
          },
        },
        rootChecked: {
          color: officeTheme.semanticColors.link,
          selectors: {
            ".ms-Button-label": {
              fontWeight: 600,
            },
          },
        },

        textContainer: {
          selectors: {
            ":hover": {
              color: officeTheme.semanticColors.link,
            },
          },
        },
        flexContainer: { flexWrap: "nowrap", whiteSpace: "nowrap" },
        splitButtonMenuButton: {
          background: "transparent",
        },
      } as IButtonStyles),
    [officeTheme]
  );
}

export const hideIcon = { styles: { root: { display: "none" } } };
export const hideCheckmark = { styles: { checkmarkIcon: { display: "none" } } };
