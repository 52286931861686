import { IButtonProps, IContextualMenuItem } from "@fluentui/react";
import { builtinFormatters } from "cayo.ui";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Entity } from "../../../api/cayo-graph";
import { endpoints } from "../../../services/endpoints.service";
import { ajaxUtils } from "../../../utils/ajax-utils";
import downloadUtils from "../../../utils/download-utils";
import { urlUtils } from "../../../utils/url-utils";
import { useSchemes } from "../../App/hooks";
import { alertAction } from "../alerts.api";
import { showActionParams } from "../../App/utils";

const maxPrimaryActions = 2;
type controlPath = { controlId: string; folder: string };

const wellknownActions = {
  activate: { controlId: "ActivationWizard", folder: "Configuration" } as controlPath,
};

const useActionButton = (actions: alertAction[]) => {
  const { showPropertiesPanel, showControl, showAction } = useSchemes();
  const location = useLocation();
  const history = useHistory();

  const actionClick = useCallback((action: alertAction) => {
    const relativePath = urlUtils.makeRelativePath(action.path);
    switch (action.type) {
      case "properties":
        history.push(relativePath);
        showPropertiesPanel({ id: action.path });

        // TODO: virtual path

        break;

      case "action":
        showAction({
          schemeId: action.path,
          gridId: "dummy",
          objects: urlUtils.hasBindings(action.path)
            ? [{ objectPath: builtinFormatters.cutLastSegment(action.path) } as Entity]
            : undefined,
        } as showActionParams).catch((e) => {
          ajaxUtils.handleError(e);
        });

        break;
      case "customView":
        const parts = action.path.split("/");

        let controlPath: controlPath | undefined;
        if (parts.length === 1) {
          controlPath = wellknownActions[parts[0].toLowerCase()];
        } else if (parts.length === 2) {
          const folder = parts[0];
          const controlId = parts[1];
          controlPath = { controlId, folder };
        } else {
          throw new Error(`Unknown control ${action.path}`);
        }

        showControl(controlPath!);

        break;

      case "media":
        let downloadUrl = endpoints.publicUrl + "/v0/" + urlUtils.trim(action.path);
        downloadUtils.downloadODataFile(downloadUrl);
        break;

      case "externalLink":
        window.open(action.path);
        break;

      case "entitySet":
        if (location.pathname !== relativePath) {
          history.push(relativePath);
        }
        break;
    }
  }, []);

  const primaryButtons = useMemo<IButtonProps[]>(() => {
    const primaryActions = actions.slice(0, maxPrimaryActions);
    const menuActions = actions.slice(maxPrimaryActions);
    const menuProps = menuActions.map<IContextualMenuItem>((a) => ({
      key: a.text,
      text: a.text,
      onClick: () => actionClick(a),
    }));

    const result = primaryActions.map<IButtonProps>((a) => ({
      key: a.text,
      text: a.text,
      onClick: () => actionClick(a),
    }));

    if (menuProps.length >= 1) {
      const lastButton = result[result.length - 1];
      lastButton.menuProps = { items: menuProps };

      lastButton.split = true;
      lastButton.styles = { splitButtonDivider: { margin: "0px !important" } };
    }

    return result;
  }, [actions]);

  return { primaryButtons };
};

export default useActionButton;
