const ids = {
  dashboard: "dashboard",
};

const paths = {
  effectiveAnnotations:
    "effectiveAnnotations(terms=['formatValue','nullValueDecorator','icons','icon','collectionIcon','possibleValues','helpLink'])",
};

const schemeParts = {
  ids,
  paths,
};

export default schemeParts;
