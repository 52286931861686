import { stringUtils } from "cayo.ui";
import { OdataQuery } from "odata";
import ajax from "../../../libs/ajax";
import logger from "../../../libs/logger";
import { getErrorMessage } from "../../../libs/odata.client";
import reportingUtils from "../../../utils/reporting-utils";
import { ConditionType, IQuery, SavedQueryItem } from "../grid-model/Query";
import { fromSavedQuery, toSavedQuery } from "../grid-model/savedQuery.portal";
import { IQueryRepo } from "./IQueryRepo";
import { RecentQueriesStore } from "./RecentQueriesStore";

const { getClient } = ajax;

export type QueryStoreConfig = {
  targetType?: string;
  queryUrl?: string;
  objectPath?: string;
  expand?: string; //"reportingJobs,alertRules"
  allowSavedQueries: boolean;
  favoritesEnabled: boolean;
};

const savedQueryNotSupportedError = "QueryStore: Saved Queries not supported";
const notConfigured = "QueryStore: not configured";

export class QueryStore implements IQueryRepo {
  private readonly _logger = logger.getLogger("QUICK-FILTER-NEW QueryStore");
  recentStore: RecentQueriesStore | undefined;
  private savedQueriesIds: Array<string> = [];
  private deletedQueriesIds: Array<string> = [];
  private log(...msg: any[]) {
    this._logger.log(...msg);
  }

  constructor(private readonly config: QueryStoreConfig | undefined) {
    this.log(".ctor -> config", config);
    if (config?.targetType && config.allowSavedQueries === true) {
      this.recentStore = new RecentQueriesStore(config.targetType);
    }
  }
  // TODO: add expression support
  public async getEffectiveFilter(query: IQuery): Promise<string> {
    const conditions = query.conditions;
    if (conditions.length === 0 || !this.config) {
      if (conditions.length === 1 && conditions[0].kind === ConditionType.Anr) {
        // TODO: move to annotations! required for new web admin ui
        const value = conditions[0].value?.toLowerCase();
        const filter = `contains(tolower(Name), '${value}')`;
        return Promise.resolve(filter);
      }
      return Promise.resolve("");
    }
    const savedQuery = toSavedQuery(query);
    const body = {
      queryBody: {
        commonConditions: savedQuery.commonConditions,
        targetType: this.config.targetType,
        expression: savedQuery.expression,
        advancedFilter: stringUtils.trim(savedQuery.advancedFilter || ""),
        anrFilter: stringUtils.trim(savedQuery.anrFilter || ""),
      },
    };
    try {
      const result = await getClient(
        this.config.queryUrl + "/buildQueryFilter()",
        "POST",
        undefined,
        body
      );

      this.log("getEffectiveFilter -> query, body, result", query, body, result);
      if (typeof result === "string") {
        return result;
      } else if (typeof result.value === "string") {
        return result.value;
      }
      return "";
    } catch (e) {
      const msg = await getErrorMessage(e);
      throw Error(msg);
    }
  }

  async get(id: string): Promise<IQuery> {
    if (!this.config) {
      throw Error(notConfigured);
    }
    // ??? if (!this.config.allowSavedQueries) {
    //   throw Error(savedQueryNotSupportedError);
    // }

    this.log("get -> id", id);
    try {
      const q: OdataQuery = { $select: "*" };
      if (!!this.config?.expand) {
        q.$expand = this.config.expand;
      }
      const response = await getClient(
        `${this.config?.queryUrl}/${id}`,
        "GET",
        undefined,
        undefined,
        q
      );

      if (!!this.config?.expand) {
        response.job =
          response.job ||
          (this.config.targetType &&
            reportingUtils.findReportingJob(
              response,
              this.config.objectPath!,
              this.config.targetType
            ));
        response.alertRule =
          response.alertRule || reportingUtils.findAlertingRule(response, this.config.objectPath!);
      }
      const result = fromSavedQuery(response);
      this.log("get -> response, result", response, result);
      return result;
    } catch (error) {
      const msg = await getErrorMessage(error);
      throw Error(msg);
    }
  }
  // TODO: add expression support
  async saveNew(query: IQuery): Promise<SavedQueryItem> {
    if (!this.config) {
      throw Error(notConfigured);
    }
    if (!this.config.allowSavedQueries) {
      throw Error(savedQueryNotSupportedError);
    }

    const savedQuery = toSavedQuery(query);

    const body = {
      name: savedQuery.name,
      commonConditions: savedQuery.commonConditions,
      targetType: this.config.targetType,
      advancedFilter: stringUtils.trim(savedQuery.advancedFilter || ""),
      anrFilter: stringUtils.trim(savedQuery.anrFilter || ""),
      orderBy: savedQuery.orderBy,
      orderDirection: savedQuery.orderDirection,
      expression: savedQuery.expression,
    };

    this.log("saveNew -> query, savedQuery, body", query, savedQuery, body);
    try {
      const response = await getClient(this.config.queryUrl!, "POST", undefined, body);

      const result = {
        id: response.id,
        name: response.name,
        default: response.default,
        builtIn: response.builtIn,
      };
      this.log("saveNew -> response, result", response, result);
      return result;
    } catch (error) {
      const msg = await getErrorMessage(error);
      throw Error(msg);
    }
  }
  // TODO: add expression support
  async update(query: IQuery): Promise<SavedQueryItem> {
    if (!this.config) {
      throw Error(notConfigured);
    }
    if (!this.config.allowSavedQueries) {
      throw Error(savedQueryNotSupportedError);
    }

    const savedQuery = toSavedQuery(query);
    const body = {
      name: savedQuery.name,
      id: savedQuery.id,
      commonConditions: savedQuery.commonConditions,
      targetType: this.config.targetType,
      advancedFilter: stringUtils.trim(savedQuery.advancedFilter || ""),
      anrFilter: stringUtils.trim(savedQuery.anrFilter || ""),
      orderBy: savedQuery.orderBy,
      orderDirection: savedQuery.orderDirection,
      expression: savedQuery.expression,
    };

    this.log("update -> query, savedQuery, body", query, savedQuery, body);
    try {
      const response = await getClient(
        `${this.config.queryUrl!}/${query.id}`,
        "PATCH",
        undefined,
        body
      );
      const result = {
        id: response.id,
        name: response.name,
        default: response.default,
        builtIn: response.builtIn,
      };
      this.log("saveNew -> result", response);
      return result;
    } catch (error) {
      const msg = await getErrorMessage(error);
      throw Error(msg);
    }
  }

  public async getList(): Promise<SavedQueryItem[]> {
    if (!this.config) {
      return [];
    }
    const odq = {
      $filter: `targetType eq '${this.config.targetType}'`,
      $select: "id,name,default,builtIn",
      $orderby: "name",
    };
    const response: SavedQueryItem[] = await getClient(
      this.config.queryUrl!,
      "GET",
      undefined,
      undefined,
      odq
    );
    //exclude deleteted becouse server can return it
    const result = response.filter((q) => !this.deletedQueriesIds.some((id) => id === q.id));
    this.savedQueriesIds = result.map((v) => v.id!);
    this.log("getList -> result, deletedQueriesIds", result, this.deletedQueriesIds);
    return result;
  }

  async remove(query: IQuery): Promise<void> {
    if (!this.config || !this.config.allowSavedQueries) return;

    try {
      const result = await getClient(`${this.config.queryUrl!}/${query.id}`, "DELETE");
      this.removeRecentQuery(query.id!);
      this.deletedQueriesIds.push(query.id!);
      this.log("remove -> query, result, deletedQueriesIds", query, result, this.deletedQueriesIds);
    } catch (err) {
      const msg = await getErrorMessage(err);
      throw Error(msg);
    }
  }

  setQueryCalled(id?: string): Array<string> {
    if (!!this.recentStore) {
      return this.appendQueriesToRecents(this.recentStore.setQueryCalled(id));
    }
    return [];
  }
  private appendQueriesToRecents(recents: string[]): string[] {
    const result = recents.filter((v) => this.savedQueriesIds.some((id) => v === id)); //recents can contains deleted queries
    for (let i = 0; i < this.savedQueriesIds.length && result.length < this.recentStore!.top; i++) {
      const id = this.savedQueriesIds[i];
      if (!recents.some((rid) => rid === id)) {
        result.push(id);
      }
    }
    return result;
  }
  async getRecentIds(): Promise<string[]> {
    if (!this.recentStore) {
      return [];
    }
    const recents = await this.recentStore.getRecentQueries();

    const result = this.appendQueriesToRecents(recents);
    this.log(
      "getRecentIds -> recents, savedQueriesIds, result",
      recents,
      this.savedQueriesIds,
      result
    );
    return recents;
  }
  public removeRecentQuery(id: string): void {
    if (!!this.recentStore) {
      this.recentStore.remove(id);
    }
  }

  public async getFavoriteIds(): Promise<Array<string> | undefined> {
    if (this.config?.favoritesEnabled && !!this.recentStore) {
      return this.recentStore.getFavoriteIds();
    }
    return undefined;
  }
  public async setFavoriteIds(ids: Array<string>): Promise<string[] | undefined> {
    if (this.config?.favoritesEnabled && !!this.recentStore) {
      return this.recentStore.setFavoriteIds(ids);
    }
    return undefined;
  }
}
