import { builtinFormatters } from "cayo.ui";

export function sidToString(base64?: string) {
  if (!base64) {
    return base64;
  }

  const array = builtinFormatters.binary(base64);
  const G = array.toString().match(/.{1,2}/g)!;

  /* G array
    [
      '01', '05', '00', '00', '00',
      '00', '00', '05', '15', '00',
      '00', '00', 'e9', '67', 'bb',
      '98', 'd6', 'b7', 'd7', 'bf',
      '82', '05', '1e', '6c', '28',
      '06', '00', '00'
    ]
    */

  const BESA2 = `${G[8]}${G[9]}${G[10]}${G[11]}`;
  const BESA3 = `${G[12]}${G[13]}${G[14]}${G[15]}`;
  const BESA4 = `${G[16]}${G[17]}${G[18]}${G[19]}`;
  const BESA5 = `${G[20]}${G[21]}${G[22]}${G[23]}`;
  const BERID = `${G[24]}${G[25]}${G[26]}${G[27]}`;
  const LESA1 = `${G[2]}${G[3]}${G[4]}${G[5]}${G[6]}${G[7]}`;

  const LESA2 = `${BESA2.substr(6, 2)}${BESA2.substr(4, 2)}${BESA2.substr(2, 2)}${BESA2.substr(
    0,
    2
  )}`;
  const LESA3 = `${BESA3.substr(6, 2)}${BESA3.substr(4, 2)}${BESA3.substr(2, 2)}${BESA3.substr(
    0,
    2
  )}`;
  const LESA4 = `${BESA4.substr(6, 2)}${BESA4.substr(4, 2)}${BESA4.substr(2, 2)}${BESA4.substr(
    0,
    2
  )}`;
  const LESA5 = `${BESA5.substr(6, 2)}${BESA5.substr(4, 2)}${BESA5.substr(2, 2)}${BESA5.substr(
    0,
    2
  )}`;
  const LERID = `${BERID.substr(6, 2)}${BERID.substr(4, 2)}${BERID.substr(2, 2)}${BERID.substr(
    0,
    2
  )}`;

  const LE_SID_HEX = `${LESA1}-${LESA2}-${LESA3}-${LESA4}-${LESA5}-${LERID}`;

  const ADDR = LE_SID_HEX.split("-");

  const SID =
    "S-1-" +
    ADDR.map((x) => parseInt(x, 16))
      .filter((v) => !isNaN(v))
      .join("-");

  return SID;
}
