import { Reducer } from "react";
import { objectUtils } from "../../utils/object-utils";
import { EditModes, SelectedHour, TimeWindow } from "./types";
import timeWindowsUtils from "./utils";

type TimeLimitState = {
  timeWindows: TimeWindow[];
  lastTimeWindows?: TimeWindow[];
  beginHour?: SelectedHour;
  endHour?: SelectedHour;
  editMode?: EditModes;
};

type BeginTimeLimitSelectionAction = {
  kind: "beginSelection";
  selection: SelectedHour;
};

type MoveTimeLimitSelectionAction = {
  kind: "moveSelection";
  selection: SelectedHour;
};

type SetEditModeAction = {
  kind: "setEditMode";
  mode: EditModes;
};

type CancelTimeLimitSelectionAction = {
  kind: "cancelSelection";
};

type InitTimeLimitAction = { kind: "init"; initialWindows: TimeWindow[] };

type TimeLimitActions =
  | MoveTimeLimitSelectionAction
  | InitTimeLimitAction
  | CancelTimeLimitSelectionAction
  | BeginTimeLimitSelectionAction
  | SetEditModeAction;

const timeLimitReducer: Reducer<TimeLimitState, TimeLimitActions> = (
  state: TimeLimitState,
  newState: TimeLimitActions
) => {
  switch (newState.kind) {
    case "init":
      state = { timeWindows: [...newState.initialWindows!] };
      break;
    case "beginSelection":
      {
        const { week, hour } = newState.selection;
        state.beginHour = { hour, week };

        timeWindowsUtils.beginSelection(state.timeWindows, week, hour, state.editMode ?? "allowed");
        state.lastTimeWindows = objectUtils.cloneObject(state.timeWindows);
      }
      break;
    case "moveSelection":
      timeWindowsUtils.moveSelection(
        state.timeWindows,
        state.lastTimeWindows!,
        state.beginHour!,
        newState.selection,
        state.editMode ?? "allowed"
      );
      break;
    case "cancelSelection":
      delete state.beginHour;
      delete state.endHour;
      delete state.lastTimeWindows;
      timeWindowsUtils.adjustSelection(state.timeWindows);
      break;

    case "setEditMode":
      state.editMode = newState.mode;
      break;

    default:
      break;
  }

  return { ...state };
};

export default timeLimitReducer;
