import { ITheme, themeUtils } from "cayo.ui";
import styled from "styled-components";

const StyledThumb = styled.div<{ isHovered?: boolean }>`
  height: 100%;
  border-left-width: ${(props) => (props.isHovered ? "6px" : "1px")};
  border-left-style: ${(props) => (props.isHovered ? "solid" : "dotted")};
  border-left-color: ${(props) =>
    props.isHovered
      ? themeUtils.addAlpha((props.theme as ITheme).cayoTheme.brandColors.primaryHighlight, 0.6)
      : (props.theme as ITheme).cayoTheme.brandColors.secondaryBackground};
  width: 6px;
  top: 0px;
  cursor: col-resize;
  position: absolute;
  left: 0;
  transition: all 0.2s;
  z-index: 1;
`;

export default StyledThumb;
