import { ApiException } from "./schema.api";

export class UserProfileClient<T> {
  private http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(
    baseUrl?: string,
    http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
  ) {
    this.http = http ? http : (window as any);
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
  }

  getElements(elementClass: string): Promise<string[] | null> {
    let url_ = this.baseUrl + "/profile/{elementClass}";
    if (elementClass === undefined || elementClass === null)
      throw new Error("The parameter 'elementClass' must be defined.");
    url_ = url_.replace("{elementClass}", encodeURIComponent("" + elementClass));
    url_ = url_.replace(/[?&]$/, "");

    let options_: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetElements(_response);
    });
  }

  protected processGetElements(response: Response): Promise<string[] | null> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === ""
            ? null
            : (JSON.parse(_responseText, this.jsonParseReviver) as string[]);
        return result200;
      });
    } else if (status === 401) {
      return response.text().then((_responseText) => {
        return throwException("A server side error occurred.", status, _responseText, _headers);
      });
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException("A server side error occurred.", status, _responseText, _headers);
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<string[] | null>(null as any);
  }

  getElement(elementClass: string, name: string): Promise<T | null> {
    let url_ = this.baseUrl + "/profile/{elementClass}/{name}";
    if (elementClass === undefined || elementClass === null)
      throw new Error("The parameter 'elementClass' must be defined.");
    url_ = url_.replace("{elementClass}", encodeURIComponent("" + elementClass));
    if (name === undefined || name === null)
      throw new Error("The parameter 'name' must be defined.");
    url_ = url_.replace("{name}", encodeURIComponent("" + name));
    url_ = url_.replace(/[?&]$/, "");

    let options_: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processGetElement(_response);
    });
  }

  protected processGetElement(response: Response): Promise<T | null> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === "" ? null : (JSON.parse(_responseText, this.jsonParseReviver) as T);
        return result200;
      });
    } else if (status === 401) {
      return response.text().then((_responseText) => {
        return throwException("A server side error occurred.", status, _responseText, _headers);
      });
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException("A server side error occurred.", status, _responseText, _headers);
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<T | null>(null as any);
  }

  saveElement(elementClass: string, name: string, scheme: any): Promise<T | null> {
    let url_ = this.baseUrl + "/profile/{elementClass}/{name}";
    if (elementClass === undefined || elementClass === null)
      throw new Error("The parameter 'elementClass' must be defined.");
    url_ = url_.replace("{elementClass}", encodeURIComponent("" + elementClass));
    if (name === undefined || name === null)
      throw new Error("The parameter 'name' must be defined.");
    url_ = url_.replace("{name}", encodeURIComponent("" + name));
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(scheme);

    let options_: RequestInit = {
      body: content_,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processSaveElement(_response);
    });
  }

  protected processSaveElement(response: Response): Promise<T | null> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === "" ? null : (JSON.parse(_responseText, this.jsonParseReviver) as T);
        return result200;
      });
    } else if (status === 401) {
      return response.text().then((_responseText) => {
        return throwException("A server side error occurred.", status, _responseText, _headers);
      });
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException("A server side error occurred.", status, _responseText, _headers);
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<T | null>(null as any);
  }

  deleteElement(elementClass: string, name: string): Promise<void> {
    let url_ = this.baseUrl + "/profile/{elementClass}/{name}";
    if (elementClass === undefined || elementClass === null)
      throw new Error("The parameter 'elementClass' must be defined.");
    url_ = url_.replace("{elementClass}", encodeURIComponent("" + elementClass));
    if (name === undefined || name === null)
      throw new Error("The parameter 'name' must be defined.");
    url_ = url_.replace("{name}", encodeURIComponent("" + name));
    url_ = url_.replace(/[?&]$/, "");

    let options_: RequestInit = {
      method: "DELETE",
      headers: {},
    };

    return this.http.fetch(url_, options_).then((_response: Response) => {
      return this.processDeleteElement(_response);
    });
  }

  protected processDeleteElement(response: Response): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then(() => {
        return;
      });
    } else if (status === 401) {
      return response.text().then((_responseText) => {
        return throwException("A server side error occurred.", status, _responseText, _headers);
      });
    } else if (status === 404) {
      return response.text().then((_responseText) => {
        return throwException("A server side error occurred.", status, _responseText, _headers);
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<void>(null as any);
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  if (result !== null && result !== undefined) throw result;
  else throw new ApiException(message, status, response, headers, null);
}
