import { IDropDown, ITextField, mvConverters, useTheme } from "cayo.ui";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { Entity } from "../../../api/cayo-graph";
import { IFormField } from "../../../api/schema.api";
import logger from "../../../libs/logger";
import { bindingsUtils } from "../../../scheme/bindings";
import { evaluateODataLogicalExpression } from "../../../scheme/transforms/odata-logical-expression-evaluator";
import { objectUtils } from "../../../utils/object-utils";
import modelUtils from "../modelUtils";
import { getPropValue } from "../modelUtils/build-model";
import { itemPropNames } from "../modelUtils/get-model";
import { isModelChanged } from "../modelUtils/utils";
import odataAnnotationsUtils from "../odata-annotations.utils";
import { FormProps } from "../types";
import { formUtils } from "../utils";
import useFormErrors from "./useFormErrors";

const log = logger.getLogger("form.hooks");

export type UpdateModelAction = { type: "reset" | "update"; model: any };

const formModelReducer = (old: any, updateAction: UpdateModelAction) => {
  if (updateAction.type === "reset") {
    return { ...updateAction.model };
  }

  return { ...old, ...updateAction.model };
};

const useFormModel = (props: FormProps, object?: Entity) => {
  const [model, setModel] = useReducer(formModelReducer, {});

  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  const extendedProps = useMemo(() => {
    const result = { ...props };
    const items = result.items as IFormField[];

    const decoratedDropDowns = items?.filter((i) => i.type === "drop-down") as IDropDown[];
    if (decoratedDropDowns?.length) {
      decoratedDropDowns.forEach((dd) => {
        dd.options.forEach((oo) => {
          const propAnnotation = props?.propertyAnnotations?.find(
            (a) => a.target === props?.targetType + "/" + dd.name
          );

          if (propAnnotation?.icons?.length) {
            const icon = propAnnotation?.icons?.find((ic) => ic.key === oo?.key);

            const resolvedColor =
              icon?.iconStyle?.color && theme.resolveNamedColor(icon?.iconStyle?.color);

            oo.decorator = { icon: icon?.iconId!, color: resolvedColor! };
          }
        });
      });
    }

    if (object) {
      for (const a of items!.filter((a) => !!a.valueDependsOn)) {
        const value = getPropValue(a, object);
        const resolved = bindingsUtils.resolveValueDependsOnReverse(a.valueDependsOn!, value);

        if (resolved) {
          object[resolved.prop] = resolved.reverseValue;
          const resolvedPropIndex = items.findIndex((i) => i.name === resolved.prop);
          if (resolvedPropIndex >= 0) {
            (items[resolvedPropIndex] as any).value = resolved.reverseValue;
          }
        }
      }
    }

    const itemsWithBindings = items?.filter((i) => !!i.bindings);
    if (itemsWithBindings?.length && object) {
      itemsWithBindings.forEach((item) => {
        [itemPropNames("hidden")].forEach((prop) => {
          const propKey = Object.keys(item.bindings!).find((p) => p === prop);
          const expression = propKey && item.bindings![propKey];
          if (expression) {
            const result = evaluateODataLogicalExpression(expression, object);
            item[prop] = result;
          }
        });
      });
    }

    const odata =
      props?.showDynamicProps && object
        ? odataAnnotationsUtils.toUIAnnotations(object, props?.disabled)
        : undefined;

    if (!odata?.length) {
      result?.items
        ?.filter((i) => (i as ITextField).isConfidential)
        .forEach((p) => {
          const textField = p as ITextField;
          if (textField.required && formUtils.isPasswordSet(p, object)) {
            textField.required = false;
          }
        });
      return result;
    }

    if (odata?.length && items) {
      const resultItems = [...items, ...odata].sort(
        (a, b) => (a.order || Number.MAX_VALUE) - (b.order || Number.MAX_VALUE)
      );
      result.items = resultItems;
    }

    return result;
  }, [props?.items, object, props["@odata.type"]]);

  // const lastViewStateCtrl = useMemo(() => {
  //   return props?.object?.objectPath && props?.parentContainerName
  //     ? new LastViewStateStorage(props.object.objectPath, props.parentContainerName)
  //     : null;
  // }, [props?.object?.objectPath, props?.parentContainerName]);

  useEffect(() => {
    let { model: newModel, hasAsyncPickers } = modelUtils.buildModel(extendedProps);

    //newModel = lastViewStateCtrl ? lastViewStateCtrl.load(newModel) : newModel;

    const initModel = async () => {
      if (await modelUtils.initPickers(extendedProps, newModel)) {
        setModel({ type: "reset", model: newModel });
      }
    };

    setModel({ type: "reset", model: newModel });
    setIsLoading(true);

    const initComplete = () => {
      log.debug("form model", newModel);
      setIsLoading(false);
    };
    if (hasAsyncPickers) {
      initModel().finally(initComplete);
    } else {
      initComplete();
    }
  }, [extendedProps, object /*lastViewStateCtrl*/]);

  const { setErrorCallback, errors, countErrors, clearErrors } = useFormErrors(extendedProps);

  const variables = useMemo(() => {
    if (props.bindings) {
      const result: { [v: string]: string | undefined } = {};

      Object.keys(props.bindings).forEach((k) => {
        const v = bindingsUtils.resolveExpression(model, props.bindings![k]);
        result[k!] = v?.toString();
        log.debug(`props: ${props.name}, variable ${k} resolved: ` + v);
      });

      return result;
    }

    return undefined;
  }, [model, props]);

  const isSaveDisabled = useMemo(() => {
    if (!props?.items?.length) {
      return false;
    }

    const editableItems = modelUtils.getEditableItems(props!.items, model);
    for (const a of editableItems) {
      const converter = mvConverters.from[a.type];
      const name = (a as any).modelProperty || a.name;
      let value = model[name!];
      if (converter) {
        value = converter(value);
      }

      if (!objectUtils.hasValue(value, (a as IFormField)?.required)) {
        log.debug("OK is disabled");
        return true;
      }
    }

    if (props.hasChanges === true) {
      return false;
    }

    let hasChanges = isModelChanged(model);
    const isDisabled = hasChanges === undefined ? !!object : !hasChanges;
    log.debug("OK is enabled", isDisabled);

    return isDisabled;
  }, [model]);

  const setModelWithLatestState = useCallback(
    (action: UpdateModelAction) => {
      // if (lastViewStateCtrl && action.type === "update") {
      //   lastViewStateCtrl.saveModel(action.model);
      // }
      setModel(action);
    },
    [/*lastViewStateCtrl*/ setModel]
  );

  return {
    extendedProps,
    model,
    setModel: setModelWithLatestState,
    variables,
    isSaveDisabled,
    setErrorCallback,
    errors,
    countErrors,
    clearErrors,
    isLoading,
  };
};

export default useFormModel;
