import { defineMessages } from "react-intl";

const messages = defineMessages({
  filterNameLabel: {
    id: "query-filter-form.filter-name-label",
    defaultMessage: "Name your query",
  },
  advancedFilterLabel: {
    id: "query-filter-form.advanced-filter-label",
    defaultMessage: "Advanced filter",
  },
  searchLabel: {
    id: "query-filter-form.search-label",
    defaultMessage: "Search",
  },
  filterNameTooltip: {
    id: "query-filter-form.filter-name-tooltip",
    defaultMessage: "Give the view a unique name that lets other admins know what this view does.",
  },
  clearFilter: {
    id: "query-filter-form.clear-filter",
    defaultMessage: "Clear",
  },
  deleteFilter: {
    id: "query-filter-form.delete-filter",
    defaultMessage: "Delete",
  },
  effectiveFilter: {
    id: "query-filter-form.effective-filter",
    defaultMessage: "Effective query",
  },
  createdBy: {
    id: "query-filter-form.created-by",
    defaultMessage: "Created By",
  },
});

export default messages;
