import React, { FormEvent } from "react";
import { Dropdown, TextField, Checkbox, Icon } from "@fluentui/react";
import { Box, Wrapper, Reset, FooterWrap } from "./ui";
import { IExpressionFormState, ModifiedType, IExFormState } from "./types";
import { useTheme } from "cayo.ui";
import { messages } from "./messages";

interface IExpressionForm {
  expressionDisabled: boolean;
  disabledCheckBox: boolean;
  expression: IExpressionFormState;
  selectTypes: Array<{ key: string; text: string; postFix: string }>;
  defaultOperators: Array<{ key: string; text: string }>;
  exFormState: Omit<IExFormState, "disable">;
  formErrorMsg: string;
  onChange: (key: string, value: string | boolean) => void;
  reset: () => void;
}

export const ExpressionForm = ({
  expressionDisabled,
  disabledCheckBox,
  expression,
  selectTypes,
  defaultOperators,
  exFormState,
  formErrorMsg,
  onChange,
  reset,
}: IExpressionForm) => {
  const { officeTheme } = useTheme();
  const { operators, selectOptions, component } = exFormState;
  const partialDisable = expression.postFix === ModifiedType.ANY;
  const disableValue = expression.operator === "present" || expression.operator === "notPresent";
  const value = disableValue ? "" : expression.value;

  return (
    <Wrapper>
      <Box>
        <Dropdown
          disabled={expressionDisabled}
          options={selectTypes}
          selectedKey={expression.postFix}
          onChange={(_, option) => {
            onChange("postFix", option?.key as string);
          }}
        />
        <Dropdown
          disabled={expressionDisabled || partialDisable}
          options={operators.length ? operators : defaultOperators}
          selectedKey={expression.operator}
          placeholder={messages.operatorPlaceholder.defaultMessage}
          onChange={(_, option) => {
            onChange("operator", option?.key as string);
          }}
        />
        {component === "select" ? (
          <Dropdown
            disabled={expressionDisabled || partialDisable || disableValue}
            options={selectOptions}
            selectedKey={value}
            placeholder={messages.valuePlaceholder.defaultMessage}
            onChange={(_, option) => {
              onChange("value", option?.key as string);
            }}
          />
        ) : (
          <TextField
            disabled={expressionDisabled || partialDisable || disableValue}
            className="text-field"
            placeholder={messages.valuePlaceholder.defaultMessage}
            errorMessage={formErrorMsg}
            value={value}
            onChange={(e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              onChange("value", (e.target as HTMLInputElement | HTMLTextAreaElement).value);
            }}
          />
        )}
      </Box>
      <FooterWrap>
        <Reset color={officeTheme.semanticColors.link} onClick={reset}>
          {messages.reset.defaultMessage}
          <Icon iconName="cancel" />
        </Reset>
        <Checkbox
          checked={expression.addColumns}
          label={messages.checkbox.defaultMessage}
          className="column-checkbox"
          disabled={disabledCheckBox}
          onChange={(_?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
            onChange("addColumns", checked!);
          }}
        />
      </FooterWrap>
    </Wrapper>
  );
};
