import { memoizeFunction } from "@fluentui/react";
import { IDateTimeBox, IFormField, IFormatableField, dateTimeUtils, mvConverters } from "cayo.ui";
import { IUIItem } from "cayo.ui/lib/esm/interfaces/common/IUIItem";
import modelUtils, { IFormModel } from ".";
import { IJsonForm, IObjectLink, IObjectPicker } from "../../../api/schema.api";
import { bindingsUtils } from "../../../scheme/bindings";
import { evaluateODataLogicalExpression } from "../../../scheme/transforms/odata-logical-expression-evaluator";
import { nameofFactory } from "../../../utils/object-utils";
import odataAnnotationsUtils from "../odata-annotations.utils";
import { formComponentTypes } from "../renderers/form-renderers";
import auxPropsUtils from "./aux-props-utils";
import { isPicker, isPropertyGroup } from "./build-model";
import fieldTypes from "./fieldTypes";
import { isPropChanged } from "./utils";

export const itemPropNames = nameofFactory<IFormField>();

const isVisible = (item: IUIItem, model: any) => {
  const formField = item as IFormField;

  if (
    formField.readOnly &&
    formField.hideWhenEmpty &&
    !formField.isStandalone &&
    !(formField as IObjectLink).isSoftLink &&
    !isPropertyGroup(item)
  ) {
    const propPath = formField.modelProperty ?? formField.name!;
    const value = model[propPath];
    if (value === undefined || (Array.isArray(value) && value.length === 0)) {
      return false;
    }
  }

  let result = true;
  const keys = item.bindings && Object.keys(item.bindings);
  const key = keys?.find((p) => p === itemPropNames("visible"));

  if (key) {
    const expression = key && item.bindings![key];
    if (expression) {
      result = evaluateODataLogicalExpression(expression, model);
    }
  }

  return result;
};

export const getVisibleItems = memoizeFunction(
  (items: IUIItem[], model: any, parentModel?: any) => {
    const result = items?.filter((i) => {
      const result = isVisible(i, parentModel || model);
      return result;
    });
    return result;
  }
);

export const getEditableItems = (items: IFormField[], model: IFormModel) => {
  let result = items?.filter(
    (i) =>
      !i.readOnly &&
      (!i.disabled || i.name === "@odata.type") &&
      i.type !== "text" &&
      !i.isStandalone &&
      !auxPropsUtils.isPropDisabled(model, i.name!) &&
      !isPropertyGroup(i)
  );

  result = getVisibleItems(result, model);

  const propGroups = items?.filter(isPropertyGroup) as IFormatableField[];
  if (propGroups?.length) {
    for (const group of propGroups.filter((g) => !g.formatter)) {
      const items = getEditableItems((group as any)?.items, model);
      const index = result.findIndex((i) => i === group);
      result.splice(index, 0, ...items);
      //result.splice(index, 0, );
    }
  }

  return result || [];
};

const createProps = (model: any, propPath: string, propValue: any) => {
  const paths = modelUtils.splitPropPath(propPath);
  let newModel = model;
  for (let i = 0; i < paths.length - 1; i++) {
    const path = paths[i];

    if (!newModel[path]) {
      newModel[path] = {};
    }

    newModel = newModel[path];
  }

  newModel[paths[paths.length - 1]] = propValue;
};

const flatModel = (model: any) => {
  const flattingKeys = Object.keys(model).filter((k) => k.indexOf(".") > 0);
  for (const key of flattingKeys) {
    createProps(model, key, model[key]);
    if (!(key.split(".").length === 2 && key.endsWith("@odata.type"))) {
      delete model[key];
    }
  }
};

export const getModel = (form: IJsonForm, model: IFormModel) => {
  if (!model || !form.items?.length) {
    return model;
  }

  const editableItems = getEditableItems(form.items, model);

  let newModel = {};
  for (const item of editableItems) {
    const { name, type, dataType } = item;
    const modelProperty = (item as any).modelProperty || name;
    const value = model[modelProperty!];
    const compType = type as formComponentTypes;

    const isSimpleProp = modelProperty.indexOf(".") === -1;

    if (!form.sendAllFields && isSimpleProp && isPropChanged(model, modelProperty) === false) {
      continue;
    }

    const converter = mvConverters.from[item.type];
    if (converter) {
      const convertedValue = converter(value);
      if (item.dataType && convertedValue) {
        const dataTypeProp = odataAnnotationsUtils.getOdataProp(name!, "odata.type");
        newModel![dataTypeProp] = item.dataType;
      }
      newModel[modelProperty!] = convertedValue;
    } else {
      const picker = isPicker(item) ? (item as IObjectPicker) : undefined;
      const dateTimeBox = item as IDateTimeBox;

      if (
        (dataType === fieldTypes.string ||
          (dataType === fieldTypes.softLink && picker?.multiselect === false)) &&
        Array.isArray(value)
      ) {
        // picker
        const isSimplePicker = picker?.isPicker && !picker?.columns?.length;

        newModel[modelProperty!] =
          value.length > 0 ? model[modelProperty!][0] : isSimplePicker ? "" : undefined;
      } else if (compType === "checkbox" && !value) {
        // boolean
        newModel[modelProperty!] = false;
      } else if (compType === "datetimebox" && dateTimeBox?.editMode === "onlyTime") {
        // date time box
        newModel[modelProperty!] = dateTimeUtils.convertTimeToUtc(model[modelProperty!]);
      } else {
        newModel[modelProperty!] = value;
      }
    }
  }

  for (const a of editableItems!.filter((a) => !!a.valueDependsOn)) {
    const resolvedValue = bindingsUtils.resolveExpression(model, a.valueDependsOn!);
    newModel[a.name!] = resolvedValue;
  }

  newModel = auxPropsUtils.removeAuxProperties(newModel);

  flatModel(newModel);

  if (form.submit?.method === "POST" && form.targetType && !newModel["@odata.type"]) {
    newModel["@odata.type"] = form.targetType;
  }

  return newModel;
};
