import {
  ActionButton,
  CommandBarButton,
  DefaultButton,
  IButtonStyles,
  IContextualMenuItem,
  IStyle,
  PrimaryButton,
} from "@fluentui/react";
import { ButtonStyleProps, themeUtils } from "cayo.ui";
import IIcon from "cayo.ui/lib/esm/interfaces/basic/icon";
import { IButton as ICayoButton } from "cayo.ui/lib/esm/interfaces/buttons";
import React, { CSSProperties } from "react";
import {
  IActionButton,
  IButton,
  ICommandBarButton,
  IRunnableButton,
} from "../../../api/schema.api";
import RunnableButton from "../../Buttons/RunnableButton";
import IAuxRenderParams from "../../Schemes/AuxRenderProps";

export const renderButtonContent = (text?: string, image?: string) => {
  return (
    <>
      {image && <img alt={text} style={{ marginRight: 6 }} src={image} />}
      {text}
    </>
  );
};

const getButtonThemeStyles = (props: ButtonStyleProps): IButtonStyles => {
  return {
    root: props.normal as any,
    rootHovered: props.hover as any,
    rootPressed: props.pressed as any,
  };
};

const button = (
  annotation: IButton,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auxParams: IAuxRenderParams
): JSX.Element => {
  return (
    <DefaultButton
      iconProps={themeUtils.getIconProps(annotation.icon)}
      // style={themeUtils.getButtonStyle(annotation)}
      styles={getButtonThemeStyles(auxParams.theme.getButtonThemeStyles(annotation))}
      disabled={annotation.disabled}
      key={annotation.name}
      id={annotation.id || annotation.name}
      ariaLabel={annotation.id || annotation.name}
      onClick={async (e: any) => {
        e.stopPropagation();
        if (annotation.action) {
          await auxParams.actionBuilder.buildAction(annotation.action!, {
            signals: auxParams.signals,
          });
        } else if (annotation.ajaxAction) {
          const response = await auxParams.actionBuilder.buildAjaxAction(annotation.ajaxAction);
          if (auxParams?.handleResponse && annotation.ajaxAction.response) {
            await auxParams?.handleResponse(annotation.ajaxAction.response, response);
          }
        }
      }}
    >
      {renderButtonContent(annotation.text, annotation.image)}
    </DefaultButton>
  );
};

const submitbutton = (
  annotation: IButton,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auxParams: IAuxRenderParams
): JSX.Element => {
  return (
    <PrimaryButton
      type="submit"
      iconProps={themeUtils.getIconProps(annotation.icon)}
      style={themeUtils.getButtonStyle(annotation)}
      disabled={annotation.disabled}
      key={annotation.name}
      id={annotation.id || annotation.name}
      data-testid="submit-btn"
      ariaLabel={'Submit Button'}
    >
      {renderButtonContent(annotation.text, annotation.image)}
    </PrimaryButton>
  );
};

const actionbutton = (
  annotation: IActionButton,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auxParams: IAuxRenderParams
): JSX.Element => {
  const { style } = getButtonVisibilityStyle(annotation?.permittedRole, auxParams?.userRole);
  const styles = getButtonStyles(annotation);
  const themeStyle = themeUtils.getButtonStyle(annotation as ICayoButton);

  let button = (
    <ActionButton
      disabled={annotation.disabled}
      key={annotation.name}
      ariaLabel={annotation.name}
      id={auxParams.parentName + "_" + (annotation.id || annotation.name)}
      iconProps={themeUtils.getIconProps(annotation.icon as IIcon)}
      styles={styles}
      style={{
        ...themeStyle,
        color: auxParams.theme.officeTheme.semanticColors.link,
        ...style,
      }}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={async (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (annotation.action) {
          await auxParams.actionBuilder.buildAction(annotation.action!, {
            signals: auxParams.signals,
          });
        } else if (annotation.ajaxAction) {
          const response = await auxParams.actionBuilder.buildAjaxAction(annotation.ajaxAction);

          if (auxParams.handleResponse && annotation.ajaxAction.response) {
            await auxParams.handleResponse(
              annotation.ajaxAction.response,
              response,
              annotation.isStandalone
            );
          }
        }
      }}
    >
      {renderButtonContent(annotation.text, annotation.image)}
    </ActionButton>
  );

  if (annotation.iconAtRight) {
    button = <div style={{ margin: 0 }}>{button}</div>;
  }

  return button;
};

const commandbarbutton = (
  annotation: ICommandBarButton,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auxParams: IAuxRenderParams
): JSX.Element => {
  const getMenuProps = () => {
    const menuProps = annotation.actions!.map<IContextualMenuItem>((a) => ({
      key: a.text!,
      text: a.text,
      action: a.action,
      onClick: (e, item) => {
        e?.preventDefault();
        e?.stopPropagation();
        auxParams.actionBuilder.buildAction(item!.action!, { signals: auxParams.signals });
      },
    }));
    return { items: menuProps };
  };

  const { style } = getButtonVisibilityStyle(annotation.permittedRole, auxParams.userRole);

  return (
    <CommandBarButton
      text={annotation.text}
      ariaLabel={annotation.text}
      disabled={annotation.disabled}
      key={annotation.name}
      id={annotation.id || annotation.name}
      iconProps={themeUtils.getIconProps(annotation.icon)}
      menuProps={getMenuProps()}
      style={{
        color: auxParams.theme.officeTheme.semanticColors.link,
        width: "auto !important",
        ...style,
      }}
      styles={getButtonStyles(annotation)}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    />
  );
};

const runnablebutton = (
  annotation: IRunnableButton,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auxParams: IAuxRenderParams
): JSX.Element => {
  const { style } = getButtonVisibilityStyle(annotation?.permittedRole, auxParams?.userRole);

  return (
    <RunnableButton
      style={style}
      {...annotation}
      onRefresh={() => auxParams.signals?.send("refresh")}
    />
  );
};

const getButtonVisibilityStyle = (permittedRole?: string, userRole?: string) => {
  const style: Partial<CSSProperties> = {};
  if (permittedRole && userRole !== permittedRole) {
    style.display = "none";
  }
  return { style };
};

const getButtonStyles = (a: ICommandBarButton | IActionButton) => {
  const cssStyle = themeUtils.getCSSStyle(a);

  const iconStyle = (a?.icon?.fontSize || a?.icon?.fontWeight) && themeUtils.getCSSStyle(a.icon);
  let additionalStyle: IStyle | undefined = undefined;
  if (a.theme === "externalLink") {
    additionalStyle = { padding: 0 };
  }

  const buttonStyles: IButtonStyles = {
    icon: iconStyle as any,
    root: { height: 40, ...additionalStyle },
    label: cssStyle as any,
  };

  if (a.iconAtRight) {
    buttonStyles.flexContainer = { flexDirection: "row-reverse" };
  }

  return buttonStyles;
};

export const buttonRenderers = {
  button,
  submitbutton,
  actionbutton,
  commandbarbutton,
  runnablebutton,
};
