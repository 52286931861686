import React, { FC } from "react";
/* eslint-disable import/no-webpack-loader-syntax */
import SpinnerIcon from "./spinner.svg";

/* eslint-enable import/no-webpack-loader-syntax */

interface SpinnerProps {
  size?: number;
}

const Spinner: FC<SpinnerProps> = ({ size }) => {
  return (
    <div style={{ display: "flex", marginLeft: 8 }}>
      <img alt="spinner" src={SpinnerIcon} height={`${size || 100}px`} />
    </div>
  );
};

export default Spinner;
