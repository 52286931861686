import { Toggle } from "@fluentui/react";
import React, { Fragment } from "react";
import styled from "styled-components";
import { IDetailsList } from "../../../api/schema.api";
import { getFormatterFromRenderOptions } from "../../../utils/formatter-utils";
import { nameofFactory, objectUtils } from "../../../utils/object-utils";
import ErrorTooltip from "../../Common/ErrorTooltip";
import Icon from "../../Graphics/Icon";
import MaskedIcon from "../../Graphics/MaskedIcon";
import { IconEx } from "../../LegacyDetailsList/cell-renderers";
import RenderOptionsFormatter from "../utils/field-formatter";
import { objectTypeToTitle } from "../utils/object-type-to-title";
import CloudIcon from "./../../../images/cloud.svg";
import GridCell from "./GridCell";
import { formatValue, lookupIcon, propertyIcon } from "./common";
import { RenderCellFn } from "./types";

export const renderIconAndTextCell: RenderCellFn = ({ item, column, auxProps, onItemClicked }) => {
  const renderOptions = column.renderOptions || {};
  const fieldFormatter = new RenderOptionsFormatter(renderOptions, getFormatterFromRenderOptions);
  const invokeItemByOneClick =
    auxProps?.additionalProps &&
    auxProps?.additionalProps[detailsListNames("invokeItemByOneClick")];

  const isNone =
    item[column.fieldName!] === "none" && column.fieldName !== "lastVerificationStatus";
  if (isNone) {
    return <Fragment />;
  }

  let isColumnWithImage =
    !(column as any).noIcon &&
    ((column.fieldName === "name" && item.objectType) ||
      (column.fieldName === "objectName" && (item.objectType || item.nestedType)) ||
      (column.fieldName === "displayName" && item.objectType && !item.name) ||
      fieldFormatter.isIconWithText());

  if (column.fieldName === "displayName" && !!item.objectName && !!item.objectType) {
    isColumnWithImage = false;
  }

  if (!isColumnWithImage) {
    return null;
  }

  let objType = item.targetObjectType || item.nestedType || item.objectType;
  const addSwitch = !!column.renderOptions?.switchProperty;

  const isCloud =
    objType.toLowerCase().indexOf("microsoft.graph") !== -1 ||
    objType.toLowerCase().indexOf("cayo.azurerm") !== -1 ||
    (objType === "cayo.graph.authorityPrincipal" && item.source === "azureAD") ||
    objType.toLowerCase().indexOf("microsoft.exo") !== -1;

  const typeAnnotation = auxProps?.getTypeAnnotation!(objType);

  const iconSource = fieldFormatter.iconSource;

  let addCloudIcon = !iconSource && isCloud;
  //HARDCODE: BUG 11193 : show cloud icon for threat alert
  if (item.objectType === "cayo.graph.riskAlert" && column.fieldName === "targetObjectName") {
    addCloudIcon = isCloud;
  }

  let iconAnnotation: IconEx | undefined = lookupIcon(
    item,
    auxProps!,
    iconSource ?? column.fieldName
  );
  let isIconType = false;
  if (!iconAnnotation) {
    iconAnnotation = typeAnnotation?.icon;
    isIconType = true;
  }

  const iconTitle =
    iconAnnotation?.title ||
    (column.fieldName === "targetObjectName" && iconSource !== "targetObjectType" // TODO: remove hardcode
      ? objectTypeToTitle(objType)
      : iconSource
      ? item[iconSource]
      : undefined);

  let icon =
    (iconAnnotation && propertyIcon(iconAnnotation, iconTitle)) ||
    propertyIcon(
      {
        iconId: objectUtils.isCollection(objType) ? "FabricFolder" : "Document",
      },
      iconTitle
    );

  if (addCloudIcon) {
    icon = (
      <MaskedIcon maskIcon={<img alt={item.name} src={CloudIcon} width={9} height="9" />}>
        {icon}
      </MaskedIcon>
    );
  }

  //error
  if (item?.errors?.length > 0 && !isIconType) {
    icon = (
      <ErrorTooltip id={item.id} errors={item.errors}>
        {icon}
      </ErrorTooltip>
    );
  }
  if (column.fieldName === "name" && item.disabled) {
    icon = (
      <Icon
        iconName={"DRM"}
        style={{
          padding: "0 4px 0 0",
          fontSize: "14px",
          color: auxProps?.theme?.cayoTheme.brandColors.disabled,
        }}
      />
    );
  }

  const rawValue = item[column.fieldName!];
  let formattedValue = formatValue(
    item,
    column.fieldName!,
    { itemFormatValue: item.formatValue, ...renderOptions },
    auxProps
  );

  return (
    <GridCell key={item.id || item.index} renderer="text-with-icon">
      <div style={{ display: "flex", flexDirection: "row", maxWidth: "100%" }}>
        {addSwitch && (
          <Toggle
            onChange={async () => {
              return (
                onItemClicked &&
                (await onItemClicked(
                  undefined,
                  undefined,
                  (() => {
                    item._hasToggle = true;
                    return item;
                  })()
                ))
              );
            }}
            disabled={item.mandatory}
            checked={!item.disabled}
            styles={{ root: { marginBottom: 0, marginRight: 8 } }}
          />
        )}
        <div style={{ display: "flex", marginRight: 4 }}>{icon}</div>
        <HoveredItem underline={invokeItemByOneClick} data-to-copy>
          {formattedValue || rawValue}
        </HoveredItem>
      </div>
    </GridCell>
  );
};

const detailsListNames = nameofFactory<IDetailsList>();

const HoveredItem = styled.span<{ underline?: boolean }>`
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 4;

  &:hover {
    text-decoration: ${(props) => (props.underline ? "underline" : undefined)};
  }
`;
