import { Link } from "@fluentui/react";
import React, { FC } from "react";
import { Icon as IconProps } from "../../../api/cayo-graph";
import { defaultLabelStyle } from "../../CommonLayout";
import Icon from "../../Graphics/Icon";
import { IActionPromise } from "../../LegacyDetailsList/cell-renderers";

const LinkedObject: FC<{
  objectName: string;
  objectType: string;
  linkType: string;
  originalObjectPath?: string;
  objectPath?: string;
  onItemClicked: IActionPromise;
  getIconAnnotation: (type: string) => IconProps;
}> = ({
  objectType,
  linkType,
  originalObjectPath,
  objectPath,
  objectName,
  onItemClicked,
  getIconAnnotation,
}) => {
  const path = originalObjectPath ?? objectPath;

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        height: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {/* {name && <div style={{ display: "inline-flex" }}>{name}</div>} */}
      <Icon iconName={getIconAnnotation(objectType ?? linkType).iconId} />
      <Link
        disabled={!path || path === "undefined"}
        onClick={() => {
          onItemClicked(undefined, undefined, path);
        }}
        style={{ paddingLeft: 4, ...defaultLabelStyle }}
      >
        {objectName}
      </Link>
    </div>
  );
};

export default LinkedObject;
