import {
  FontSizes,
  FontWeights,
  IBreadcrumbStyles,
  Breadcrumb as OfficeBreadcrumb,
  mergeStyleSets,
} from "@fluentui/react";
import { ITheme, useComponentLogger } from "cayo.ui";
import React, { FC, useContext, useMemo } from "react";
import styled, { ThemeContext } from "styled-components";
import { useBreadcumbs } from "./Nav/nav-hooks";

type BreadcrumbsProps = {
  rootName?: string;
};

const Breadcrumb: FC<BreadcrumbsProps> = ({ rootName }) => {
  const items = useBreadcumbs(rootName);
  const theme = useContext<ITheme>(ThemeContext);
  const log = useComponentLogger(Breadcrumb);

  const styles = useMemo(() => mergeStyleSets(bcStyle(theme)), [theme]);

  log.debug("Nav.breadcrumbs -> items", items);

  return (
    <BreadcrumbContainer>
      <OfficeBreadcrumb items={items} styles={styles} />
    </BreadcrumbContainer>
  );
};

const BreadcrumbContainer = styled.div`
  flex-grow: 1;
  min-width: 30px;
`;

const bcStyle = (theme: ITheme): Partial<IBreadcrumbStyles> => {
  const colors = theme.cayoTheme.brandColors;
  const { semanticColors } = theme.officeTheme;

  return {
    chevron: {
      color: colors.primaryBackground,
      fontSize: "16px",
    },
    item: {
      color: colors.divider,
      fontSize: "18px",
      fontWeight: FontWeights.regular,
    },
    listItem: {
      selectors: {
        ":last-child .ms-Breadcrumb-item": {
          color: colors.primaryBackground,
          fontWeight: FontWeights.regular,
        },
      },
    },
    itemLink: {
      background: "transparent",
      color: colors.primaryBackground,
      fontSize: "18px",
      fontWeight: FontWeights.regular,
      outline: "none",
      selectors: {
        ":focus": {
          background: "transparent",
          color: `${colors.primaryHighlight} !important`,
          textDecoration: "underline",
        },

        ":hover": {
          background: "transparent",
          color: `${colors.primaryHighlight} !important`,
          textDecoration: "underline",
        },

        ":last-child": {
          color: `${colors.primaryBackground} !important`,
        },
        ":hover:focus": {
          background: "transparent",
          color: `${colors.primaryHighlight} !important`,
          textDecoration: "underline",
        },
      },
    },
    overflow: {
      fontSize: FontSizes.medium,
      fontWeight: FontWeights.regular,
    },
    overflowButton: {
      color: `${colors.primaryBackground} !important`,
      fontSize: FontSizes.medium,
      fontWeight: FontWeights.regular,
      selectors: {
        "&:hover": {
          backgroundColor: colors.primaryHighlight,
          color: semanticColors.buttonTextHovered,
        },
        ":focus": {
          backgroundColor: colors.primaryHighlight,
          color: semanticColors.buttonTextPressed,
        },
        ":hover:focus": {
          backgroundColor: colors.primaryHighlight,
          color: semanticColors.buttonTextPressed,
        },
      },
    },
    root: {
      fontSize: FontSizes.medium,
      fontWeight: FontWeights.regular,
      margin: "auto 0",
    },
  };
};

export default Breadcrumb;
