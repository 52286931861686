import { DepsType, IColumn } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import {
  ConditionType,
  ExpressionGroupCombinationType,
  ExpressionLogicType,
  ExpressionOperandOperator,
  IQuery,
} from "../GridContainer/grid-model/Query";
import { IExpressionFormState, ModifiedType } from "./components/ExpressionForm";
import { ExpressionType, OperandModelType, SyntaxEnum, ValueType, syntaxRegex } from "./types";
import {
  MapConditionToText,
  expressionModel,
  logicalExpressionModel,
  operandModel,
  postfixMap,
  relatedFieldsMap,
} from "./utils";

const { getClient } = ajax;

const log = logger.getLogger("AdvancedPicker service");

const annotationsGroup = {
  auditCategories_exclude: "Action",
  auditCategories: "Action",
  changeType: "Action",
  initiatorName: "Who",
  initiatorName_exclude: "Who",
  modifiedProperties: "ModifiedProperties",
  targetObjectLocations: "Where",
  targetObjectLocations_exclude: "Where",
  nestedType: "NestedType",
  targetObjectName: "What",
  targetObjectName_exclude: "What",
};

const annotationExpressionBuilders = {
  ModifiedProperties: modifiedPropertiesExpressionBuilder,
  Action: regularExpressionBuilder,
  Where: regularExpressionBuilder,
  Who: regularExpressionBuilder,
  NestedType: regularExpressionBuilder,
  What: regularExpressionBuilder,
};

function regularExpressionBuilder(values: ValueType[]) {
  let logicalExpression = structuredClone(logicalExpressionModel);
  logicalExpression.enabled = true;
  logicalExpression.operands = [];
  logicalExpression.operator = ExpressionGroupCombinationType.OR;

  values.forEach((value) => {
    let operand = structuredClone(operandModel);
    operand.operator = ExpressionOperandOperator.equals;
    const isChangedType = value.annotationName === "changeType";

    operand.property = value.declaringType
      ? `${value.declaringType}/${value?.link?.objectName}`
      : value.propertyName;
    operand.value = isChangedType ? value.link : value.link.objectName || value.text;
    operand.metadata = normalizeMetaData(value);

    if (value.isExclude) {
      operand.operator = ExpressionOperandOperator.notEquals;
    }

    logicalExpression.operands.push(operand);
  });

  return logicalExpression;
}

function modifiedPropertiesExpressionBuilder(
  values: ValueType[],
  expressionForm: IExpressionFormState
) {
  if (values.length > 1) {
    let logicalExpression = structuredClone(logicalExpressionModel);
    logicalExpression.enabled = true;
    logicalExpression.operands = values.reduce((accumulator, currentValue) => {
      let currentOperands = contraryOperands(
        currentValue.declaringType,
        currentValue.link.objectName,
        currentValue,
        expressionForm
      );
      return accumulator.concat(currentOperands.flat(1));
    }, []) as OperandModelType[];

    return logicalExpression;
  } else if (expressionForm.postFix === ModifiedType.ANY && values.length === 1) {
    let value = values[0];
    let logicalExpression = structuredClone(logicalExpressionModel);
    logicalExpression.enabled = true;
    logicalExpression.operands = contraryOperands(
      value.declaringType,
      value.link.objectName,
      value,
      expressionForm
    );

    return logicalExpression;
  } else {
    let value = values[0];
    let operand = structuredClone(operandModel);
    let columns = undefined;

    if (expressionForm.addColumns) columns = getColumns(value.link.objectName, value.text);
    operand.metadata = normalizeMetaData(value, expressionForm, columns);
    operand.property = `${value.declaringType}/${value.link.objectName}${
      postfixMap[expressionForm.postFix]
    }`;
    operand.operator = expressionForm.operator;
    operand.value = expressionForm.value;
    operand.enabled = true;

    return operand;
  }
}

function filterOperands(operands: any[], operandNamesToRemove: string[], toRemove: string[]) {
  return operands
    .filter((operand) => {
      if (operand.operands && operand.operands.length > 0) {
        operand.operands = filterOperands(operand.operands, operandNamesToRemove, toRemove);
      }
      return !(
        operand.metadata &&
        (operandNamesToRemove.includes(operand?.metadata?.annotationName) ||
          toRemove.includes(operand?.metadata?.annotationName))
      );
    })
    .filter((o) => (o.operands ? !!o.operands.length : true));
}

export function builtExpression(
  values: ValueType[],
  toRemove: string[],
  query: IQuery,
  expressionForm: IExpressionFormState
) {
  const queryExpression = query.conditions.find((c) => c.kind === ConditionType.Expression);
  const expression = (queryExpression || structuredClone(expressionModel)) as ExpressionType;

  const operandNamesToRemove = values.map((element) => element.annotationName);

  expression.operands = filterOperands(expression.operands, operandNamesToRemove, toRemove);

  const groupedData = values.reduce((acc, obj) => {
    const key = obj.annotationName;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  for (const key in groupedData) {
    const exBuilderKey = annotationsGroup[key];
    const exBuilder = annotationExpressionBuilders[exBuilderKey];
    if (exBuilder) {
      const operand = exBuilder(groupedData[key], expressionForm);
      expression.operands.push(operand);
    } else {
      log.error(`No expression builder found for annotation group: ${key}`);
    }
  }

  return expression;
}

function generateUniqueId() {
  return Math.random().toString(36).slice(2, 9);
}

function contraryOperands(
  declaringType: string,
  objectName: string,
  value: ValueType,
  expressionForm: IExpressionFormState
) {
  let columns = undefined;

  if (expressionForm.addColumns) columns = getColumns(value.link.objectName, value.text);

  return [
    {
      objectType: ExpressionLogicType.PropertyExpression,
      operator: expressionForm.operator,
      property: `${declaringType}/${objectName}_added`,
      value: expressionForm.value,
      enabled: true,
      metadata: normalizeMetaData(value, expressionForm, columns),
    },
    {
      objectType: ExpressionLogicType.PropertyExpression,
      operator: expressionForm.operator,
      property: `${declaringType}/${objectName}_removed`,
      value: expressionForm.value,
      enabled: true,
      metadata: normalizeMetaData(value, expressionForm, columns),
    },
  ];
}

function getColumns(propertyName: string, text: string): IColumn[] {
  const columnsText = removeBracketsAndContents(text);
  return [
    {
      displayName: `${columnsText} (old value)`,
      key: generateUniqueId(),
      fieldName: `${propertyName}_removed`,
      minWidth: 80,
      columnActionsMode: "disabled",
      position: 400,
    },
    {
      displayName: `${columnsText} (new value)`,
      key: generateUniqueId(),
      fieldName: `${propertyName}_added`,
      minWidth: 80,
      columnActionsMode: "disabled",
      position: 450,
    },
  ];
}

function removeBracketsAndContents(text: string) {
  return text?.replace(/\([^)]*\)/g, "")?.trim() || "";
}

function removeProperties(obj: { [key: string]: any }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { possibleValues, queryOperators, syntax, columns, expressionForm, ...rest } = obj;
  return rest;
}

function normalizeMetaData(
  value: ValueType,
  expressionForm?: IExpressionFormState,
  columns?: IColumn[]
) {
  const parsedValue = removeProperties(value);
  let result = {
    ...parsedValue,
    columns: null,
    expressionForm: null,
    syntax: null,
    possibleValues: null,
    queryOperators: null,
  } as ValueType;

  if (typeof parsedValue.link === "object") result.link = JSON.stringify(parsedValue.link);
  if (expressionForm) result.expressionForm = JSON.stringify(expressionForm);
  if (columns) result.columns = JSON.stringify(columns);
  if (value.possibleValues) result.possibleValues = JSON.stringify(value.possibleValues);
  if (value.queryOperators) result.queryOperators = JSON.stringify(value.queryOperators);
  if (value.syntax) result.syntax = value.syntax;

  return result;
}

export function normalizeToConditionOptions(conditions: Array<string>, isDefault?: boolean) {
  const operators =
    conditions?.map((element: string) => ({
      key: element,
      text: MapConditionToText[element],
    })) || [];

  if (isDefault) {
    operators.push({
      key: ExpressionOperandOperator.present,
      text: ExpressionOperandOperator.present,
    });
  }

  return operators;
}

function normalizeSelectList(arr: Array<{ name: string; value: string }>) {
  const booleanNameMap = {
    true: "Yes",
    false: "No",
  };
  return (
    arr?.map((v: { name: string; value: string }) => ({
      key: v.value,
      text: booleanNameMap[v.name] || v.name,
    })) || []
  );
}

function checkEquality(arr: any[]) {
  if (arr.length === 1) return true;

  const firstItem = arr[0];
  for (let i = 1; i < arr.length; i++) {
    const currentItem = arr[i];
    if (
      firstItem.syntax !== currentItem.syntax ||
      JSON.stringify(firstItem.possibleValues) !== JSON.stringify(currentItem.possibleValues)
    ) {
      return false;
    }
  }
  return true;
}

export function validateExpressionForm(properties: any[]): {
  disable: boolean;
  operators: Array<{ key: string; text: any }>;
  selectOptions: Array<{ key: string; text: string }>;
  syntax: string;
  component: string;
} {
  let result = {
    disable: true,
    operators: [],
    selectOptions: [],
    syntax: SyntaxEnum.string,
    component: "text",
  };
  const allIsEqual = checkEquality(properties);

  if (allIsEqual) {
    let res = {
      disable: false,
      operators: normalizeToConditionOptions(properties[0].queryOperators),
      selectOptions: normalizeSelectList(properties[0].possibleValues),
      syntax: properties[0].syntax,
      component: "text",
    };

    if (res.selectOptions.length) {
      res.component = "select";
    }

    return res;
  }

  return result;
}

export function createFilterDeps(values: any, depsObject: DepsType): DepsType {
  values.forEach((v: any) => {
    v?.link?.objectName && depsObject.deps.push(v?.link?.objectName);
  });

  return depsObject;
}

export function validateRelatedFields(values: any[]): boolean {
  let isValid = true;
  for (const key in relatedFieldsMap) {
    const dependencyName = relatedFieldsMap[key];
    const dependentName = key;

    if (!dependencyName || !dependentName) {
      continue;
    }

    const dependenciesSet = new Set(
      values.filter((v) => v.annotationName === dependencyName).map((v) => v.link.objectName)
    );

    const dependentsSet = new Set(
      values.filter((v) => v.annotationName === dependentName).map((v) => v.declaringType)
    );

    if (!dependenciesSet.size || !dependentsSet.size) {
      continue;
    }

    for (const dependent of dependentsSet) {
      if (![...dependenciesSet].some((dependency) => dependency === dependent)) {
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      break;
    }
  }

  return isValid;
}

const syntaxList = ["Edm.Int32", "Edm.Int64"];

export function validateExpressionFormValue(
  value: string,
  syntax: string,
  operator: string
): string {
  let errorMsg = "";

  if (operator !== "present" && syntaxList.includes(syntax)) {
    const regexOoj = syntaxRegex[syntax];

    const isValid = regexOoj.regex.test(value);

    return isValid ? "" : regexOoj.errorMessage;
  }

  return errorMsg;
}

function replaceObjectTypeWithOdataType(obj: any): any {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => replaceObjectTypeWithOdataType(item));
  }

  const newObj: any = {};
  for (const key in obj) {
    if (key === "objectType") {
      newObj["@odata.type"] = obj[key];
    } else if (key !== "metadata") {
      newObj[key] = replaceObjectTypeWithOdataType(obj[key]);
    }
  }
  return newObj;
}

export async function validateFilter(
  queryUrl: string,
  targetType: string,
  expression: ExpressionType
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { kind, ...rest } = expression;
  const body = {
    queryBody: {
      targetType: targetType,
      expression: replaceObjectTypeWithOdataType(rest),
    },
  };

  return getClient(`${queryUrl}/buildQueryFilter()`, "POST", undefined, body);
}
