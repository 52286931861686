/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alignment, Stack } from "@fluentui/react";
import React from "react";
import { CSSProperties } from "styled-components";
import { IGridLayout, IRowLayout } from "../../../api/schema.api";
import logger from "../../../libs/logger";
import Grid from "../../Layout/Grid";

export interface ILayoutHierarchy {
  render: () => JSX.Element;
  name?: string;
  index: number;
}

const row = (props: IRowLayout, cells: ILayoutHierarchy[]): JSX.Element => {
  const actualAlign = props.justifyContent as unknown as Alignment;

  const stretchStyles: CSSProperties = props.stretch
    ? { flexGrow: 1, height: "100%", maxWidth: "100%" }
    : {};

  return (
    <Stack
      verticalAlign={props?.valign ? "center" : undefined}
      horizontal={true}
      style={{ display: "flex", width: "100%", ...stretchStyles }}
      horizontalAlign={actualAlign}
      tokens={{ childrenGap: props.childrenGap }}
      key={"row" + props.name}
    >
      {cells.map((r) => {
        if (!r.render) {
          logger.debug("dd");
        }
        return r.render();
      })}
    </Stack>
  );
};

const grid = (props: IGridLayout, cells: ILayoutHierarchy[]): JSX.Element => {
  return <Grid key={"grid" + props.name} {...props} cells={cells} />;
};

export const layoutRenderers = {
  row,
  grid,
};
