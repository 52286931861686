import { DefaultButton, Stack, Text } from "@fluentui/react";
import { GroupBox, ITheme, Separator } from "cayo.ui";
import React, { FC, Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { ILoginScheme } from "../../api/schema.api";
import SSO from "../../api/sso";
import useService from "../../services/services.hook";
import { CenteredPanel } from "../CommonLayout";
import Form from "../Form";
import { renderButtonContent } from "../Form/renderers/renderers.buttons";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";

const LoginScheme: FC<ILoginScheme> = (props) => {
  const { background, passwordFlow, title, sso, copyrightText } = props;
  const protocol = window.location.protocol;
  const [isinitialized, setIsInitialized] = useState(false);

  const isSSOSupported = !!sso && protocol !== "http:";
  const actionBuilder = useService("actionBuilder");
  const { getIconAnnotation } = useTypeAnnotations();

  useEffect(() => {
    if (!props.passwordFlow) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      SSO.useOffice365Auth();
      return;
    }

    setIsInitialized(true);
  }, []);

  if (!isinitialized) {
    return <Fragment />;
  }

  return (
    <BackgroundImage src={background!}>
      <StyledCenteredPanel dropShadow={true}>
        <GroupBox title={title!}>
          <Form
            actionBuilder={actionBuilder}
            {...passwordFlow!}
            className="static-form"
            getIconAnnotation={getIconAnnotation}
          ></Form>
          {isSSOSupported && (
            <>
              <Stack tokens={{ childrenGap: 10 }}>
                <Separator title="or" preset="small" />
                <DefaultButton onClick={SSO.useOffice365Auth}>
                  {renderButtonContent(sso?.title, sso?.image)}
                </DefaultButton>
              </Stack>
            </>
          )}
        </GroupBox>
        {copyrightText && <StyledCopyrightText variant="tiny">{copyrightText}</StyledCopyrightText>}
      </StyledCenteredPanel>
    </BackgroundImage>
  );
};

const StyledCopyrightText = styled(Text)`
  width: 100%;
  margin-top: 4px;
`;

const StyledCenteredPanel = styled(CenteredPanel)`
  width: 450px;
  height: auto;
  background-color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.primaryBackground};
  padding: 1em 1.4em;
  @media (max-width: 576px) {
    max-width: 500px;
  }
`;

const BackgroundImage = styled.div<{ src: string }>`
  background: ${(props) => `url('${props.src}') no-repeat fixed center`};
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-size: cover;
`;

export default LoginScheme;
