import { Dialog, DialogFooter, DialogType, getId, Icon, PrimaryButton } from "@fluentui/react";
import { Html, htmlUtils, stringUtils, useTheme } from "cayo.ui";
import React, { FC, Fragment, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { isString } from "../types/type-guards";
import clipboardUtils from "../utils/clipboard-utils";
import { IUserMessage } from "./App/types";
import { commonMessages } from "./common-messages";
import Row from "./Layout/Row";

interface IErrorModalProps {
  isDraggable?: boolean;
  message: IUserMessage;
  onDismiss: () => void;
  title?: string | undefined | null;
  showCloseAtBottom?: boolean | undefined;
}

const ErrorModal: FC<IErrorModalProps> = ({ message, onDismiss, title, showCloseAtBottom }) => {
  const { text, severity } = message;

  const { getColor, getIcon } = useTheme();
  const intl = useIntl();

  const labelId: string = getId("dialogLabel");
  const subTextId: string = getId("subTextLabel");

  const sanitizedMessage = useMemo(() => {
    let msg: string | undefined = text;
    if (Array.isArray(msg)) {
      msg = (msg as string[]).join("\r");
    }

    msg = htmlUtils.sanitize(msg);

    if (isString(msg)) {
      msg = stringUtils.replaceReturnsWithBreaks(msg);
    }
    return msg;
  }, [text, severity]);

  const isInformational = severity === "informational";
  const showTitle = severity === "error" || severity === "warning";
  return (
    <Fragment>
      {message && (
        <Dialog
          minWidth="450px"
          onDismiss={onDismiss}
          hidden={false}
          isBlocking={true}
          dialogContentProps={{
            styles: {
              subText: { fontSize: 16, fontWeight: 400 },
              title: { fontWeight: 600, color: getColor(severity, "severity") },
            },
            showCloseButton: !showCloseAtBottom,
            title: showTitle ? (
              <DialogTitle>
                <StyledIcon iconName={getIcon(severity, "severity")} />
                <SeverityWrapper>
                  {(severity ? stringUtils.makePascal(severity) : title) || (
                    <FormattedMessage id="error-modal.error-title" defaultMessage="Error" />
                  )}
                </SeverityWrapper>
              </DialogTitle>
            ) : (
              <Fragment />
            ),
            type: DialogType.normal,
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
            subtitleAriaId: subTextId,
            titleAriaId: labelId,
          }}
        >
          <StyledRow showTitle>
            {!showTitle && (
              <StyledIcon2
                iconName={getIcon(severity, "severity")}
                color={getColor(severity, "severity")}
              />
            )}
            <Html breakSpaces={true} sanitizedValue={sanitizedMessage} truncated={true} />
          </StyledRow>
          {isInformational ? (
            <DialogFooter styles={{ actionsRight: { textAlign: "center" } }}>
              <InformationalPrimaryButton onClick={onDismiss} text="OK" />
            </DialogFooter>
          ) : (
            <DialogFooter styles={{ actionsRight: { textAlign: "right" } }}>
              <NonInformationalPrimaryButton
                onClick={() => {
                  clipboardUtils.copyToClipboard(sanitizedMessage);
                  onDismiss();
                }}
                text={intl.formatMessage(commonMessages.copy)}
              />
            </DialogFooter>
          )}
        </Dialog>
      )}
    </Fragment>
  );
};

const SeverityWrapper = styled.div`
  margin: 4px 0px 8px 8px;
`;

const StyledIcon = styled(Icon)`
  font-size: 26px;
`;

const StyledIcon2 = styled(StyledIcon)<{ color: string }>`
  color: ${(props) => props.color};
  padding-right: 8px;
`;

const DialogTitle = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
`;

const StyledRow = styled(Row)<{ showTitle: boolean }>`
  align-items: center;
  margin-top: ${(props) => (props.showTitle ? 0 : 36)};
`;

const InformationalPrimaryButton = styled(PrimaryButton)`
  margin: 10px;
  min-width: 100px;
`;

const NonInformationalPrimaryButton = styled(PrimaryButton)`
  margin: 0px;
  min-width: 100px;
`;

export default ErrorModal;
