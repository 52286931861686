import { IFormField, logger } from "cayo.ui";
import { IAjaxAction, IWizard, IWizardStep } from "../../../api/schema.api";
import { evaluateODataLogicalExpression } from "../../../scheme/transforms/odata-logical-expression-evaluator";
import { IActionBuilder } from "../../../scheme/actions";
import bindingsRegexes from "../../../scheme/bindings/regexes";
import { ajaxUtils } from "../../../utils/ajax-utils";
import { nameofFactory } from "../../../utils/object-utils";
import { resolvePropertyValue } from "../../Form/modelUtils/aux-props-utils";
import { getVisibleItems } from "../../Form/modelUtils/get-model";
import { modelUtils } from "../../Form/modelUtils/utils";

const formFieldNames = nameofFactory<IFormField>();
const wizardContext = "context";
export class WizardController {
  private log = logger.getLogger("WizardController");
  private modelCache: { [key: string]: { model: any; isNextDisabled: boolean } } = {};

  constructor(readonly wizard: IWizard, readonly actionBuilder: IActionBuilder) {
    this.log.debug("ctor");
  }

  public init = async () => {
    const autoRunActions = this.wizard.auxActions?.filter((a) => a.autoRun);
    if (!autoRunActions?.length) {
      return;
    }

    for (const a of autoRunActions) {
      const model = this.getModel();
      await this.executeAction(a.ajaxAction!, model, "init");
    }
  };

  public isHidden = (stepName: string): boolean => {
    const step = this.getStep(stepName);
    var result = !getVisibleItems([{ ...step }], this.getModel())?.length;
    return result;
  };

  public isInputValid = (stepName: string): boolean => {
    const step = this.getStep(stepName);

    if (!this.modelCache[stepName]) {
      const hasInputControls = !step.items?.find((i) => (i as IFormField).readOnly !== true);
      return hasInputControls;
    }

    const isNextDisabled = this.modelCache[stepName].isNextDisabled;

    const disabledExpression =
      step.nextAction?.bindings && step.nextAction.bindings[formFieldNames("disabled")];

    if (disabledExpression) {
      const model = this.modelCache[stepName].model;
      const isDisabled = evaluateODataLogicalExpression(disabledExpression, model);
      return !isDisabled;
    }

    return !isNextDisabled;
  };

  public updateSteps = (stepName: string, model: any, isNextDisabled: boolean) => {
    this.log.debug("updateSteps", stepName, "model", model);
    this.modelCache[stepName] = { model, isNextDisabled };
  };

  public getModel = () => {
    let model = {};
    Object.keys(this.modelCache).forEach((key) => {
      if (this.modelCache[key].model) {
        model = { ...model, ...this.modelCache[key].model };
      }
    });

    return model;
  };

  public onNext = async (stepName: string) => {
    const step = this.getStep(stepName);

    const result = await this.processStep(step);
    return result;
  };

  public getActionButtons = () => {
    if (this.wizard.auxActions?.length) {
      return getVisibleItems(this.wizard.auxActions, this.getModel());
    }

    return undefined;
  };

  public handleResponse(
    response: { [key: string]: string },
    aResult: any,
    model: any,
    stepName: string
  ) {
    if (
      aResult &&
      aResult["@odata.context"] &&
      (aResult.value || typeof aResult.value === "boolean")
    ) {
      aResult = aResult.value;
    }

    for (const ra of Object.values(response)) {
      if (ra.startsWith("model.")) {
        const ra2 = ra.replace(/^model./, "");
        const parts2 = ra2.split("=");
        if (parts2.length === 2) {
          const propName = parts2[0];
          let unresolvedValue = parts2[1];

          const matches = Array.from(
            unresolvedValue.matchAll(bindingsRegexes.allInsideCurlyBrackets)
          );

          if (matches.length) {
            unresolvedValue = matches[0][1];
          }
          const resolvedValue = resolvePropertyValue(unresolvedValue, {
            response: aResult,
          });

          const originalModel = this.modelCache[stepName]?.model || {};

          modelUtils.createProps(
            originalModel,
            propName,
            resolvedValue === undefined ? unresolvedValue : resolvedValue
          );

          this.modelCache[stepName] = {
            model: originalModel,
            isNextDisabled: this.modelCache[stepName]?.isNextDisabled,
          };
        }
      }
    }
  }

  public getStepByTitle = (title: string) => {
    const result = this.wizard.steps.find((s) => s.title === title)?.name;
    return result;
  };

  private processStep = async (step: IWizardStep) => {
    const model = this.getModel();

    this.log.debug("processStep", step, "model", model);

    let result: any;
    if (step.nextAction?.ajaxAction) {
      result = await this.actionBuilder.buildSubmit(
        step.nextAction?.ajaxAction,
        model,
        true,
        model
      );

      this.log.debug("buildSubmit", step.nextAction?.ajaxAction, "result", result);

      if (step.nextAction?.ajaxAction?.response) {
        this.handleResponse(step.nextAction?.ajaxAction?.response, result, model, wizardContext);

        this.log.debug(
          "handleResponse",
          step.nextAction?.ajaxAction?.response,
          "model",
          this.getModel()
        );
      }
    }

    if (step.conditionalActions?.length) {
      for (const a of step.conditionalActions.filter((a) => !a.condition && !!a.ajaxAction)) {
        try {
          const aResult = await this.executeAction(a.ajaxAction!, model, wizardContext);

          this.log.debug("conditional action executed", aResult);
        } catch (e) {
          const error = await ajaxUtils.getError(e);
          throw new Error(error?.error_description);
        }
      }

      for (const a of step.conditionalActions.filter((a) => !!a.condition)) {
        try {
          const evalExpression = evaluateODataLogicalExpression(a.condition!, model);
          if (!evalExpression) {
            continue;
          }

          let aResult = await this.executeAction(a.ajaxAction!, model, wizardContext);
          this.log.debug("conditional action executed", aResult);
        } catch (e) {
          const error = await ajaxUtils.getError(e);
          throw new Error(error?.error_description);
        }
      }
    }

    return result;
  };

  private getStep = (name: string) => this.wizard.steps.find((s) => s.name === name) as IWizardStep;

  private async executeAction(a: IAjaxAction, model: any, stepName: string) {
    let aResult: any = false; // TODO: add handler for 500 error
    try {
      aResult = await this.actionBuilder.buildSubmit(a, model, true, model);
    } catch (e) {
      const error = await ajaxUtils.getError(e);
      this.log.error("Failed to execute action", error);
    }

    if (a.response) {
      this.handleResponse(a.response, aResult, model, stepName);
    }

    return aResult;
  }
}
