import { stringUtils } from "cayo.ui";
import { IColumnRenderOptions } from "../../../api/schema.api";

const iconAndText = "iconAndText";
const iconOnly = "iconOnly";
const jobState = "jobState";
const spaceDelimitedSentenceCase = "spaceDelimitedSentenceCase";
const iconSource = "iconSource:";

type OptionsNormalizer = (renderOptions: IColumnRenderOptions) => string;

class RenderOptionsFormatter {
  private _iconAndText: boolean = false;
  private _iconOnly: boolean = false;
  private _iconSource?: string;
  private _useSpaceDilimeterSentenceCase = false;
  private _isJobState = false;
  private _otherFormatter?: string;

  public constructor(
    readonly renderOptions: IColumnRenderOptions | undefined,
    private optionsNormalizer: OptionsNormalizer
  ) {
    const formatter = renderOptions ? this.optionsNormalizer(renderOptions).split(",") : null;

    if (!formatter) return;

    formatter.forEach((f) => {
      switch (true) {
        case f === iconAndText:
          this._iconAndText = true;
          break;
        case f === iconOnly:
          this._iconOnly = true;
          break;
        case f.startsWith(iconSource):
          this._iconSource = f.split(":")[1];
          break;
        case f === jobState:
          this._isJobState = true;
          this._useSpaceDilimeterSentenceCase = true;
          break;
        case f === spaceDelimitedSentenceCase:
          this._useSpaceDilimeterSentenceCase = true;
          break;
        default:
          this._otherFormatter = f;
      }
    });
  }

  // public get firstFormatter() {
  //   return this.formatters.length ? this.formatters[0] : undefined;
  // }

  public isIconWithText() {
    return this._iconAndText;
  }

  public get otherFormatter() {
    return this._otherFormatter;
  }
  public isIconOnly() {
    return this._iconOnly;
  }
  public get iconSource() {
    return this._iconSource;
  }

  public isJobState() {
    return this._isJobState;
  }

  public getStringValue(value: string) {
    if (this._useSpaceDilimeterSentenceCase) {
      return stringUtils.toSpaceDelimitedSentenceCase(value);
    }

    const array = Array.isArray(value) && (value as string[]);
    if (array && array.length) {
      return array.join("; ");
    }

    return undefined;
  }
}

export default RenderOptionsFormatter;
