import { FocusZone, Icon, Label, Link, Stack } from "@fluentui/react";
import { useTheme } from "cayo.ui";
import { IUIItem } from "cayo.ui/lib/esm/interfaces/common/IUIItem";
import React, { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { AvailabilitySchedule, Entity } from "../../api/cayo-graph";
import { IInplaceAvailabilitySchedule, IJsonForm } from "../../api/schema.api";
import CollectActionParametersDialog from "../../components/CollectActionParametersDialog";
import { endpoints } from "../../services/endpoints.service";
import useService from "../../services/services.hook";
import { useSchemes } from "../App/hooks";
import { commonMessages } from "../common-messages";
import availabilityScheduleFormatter from "./availability-schedule-formatter";
import messages from "./messages";

type AvailabilityScheduleProps = {
  object?: Entity;
  style?: CSSProperties;
};

const InplaceAvailabilitySchedule: FC<AvailabilityScheduleProps & IInplaceAvailabilitySchedule> = (
  props
) => {
  const { object, label, style, required, serverTimeZone } = props;
  const { refreshPropertiesPanel } = useSchemes();
  const [actionProps, setActionProps] = useState<IJsonForm | null>(null);
  const actionBuilder = useService("actionBuilder");

  const theme = useTheme();
  const intl = useIntl();

  let realSchedule: AvailabilitySchedule[] = (object as any)?.availability;
  let realObject: any = object;

  if (!realSchedule) {
    realSchedule = realObject?.availability;
  }

  const readableText = useMemo(() => {
    let result = availabilityScheduleFormatter.toReadableText(intl, realSchedule);

    return result;
  }, [realSchedule]);

  const showScheduleDialog = useCallback(() => {
    const url = endpoints.makeFullApiPath(realObject!.objectPath!);

    const params: IJsonForm = {
      title: intl.formatMessage(messages.subtitle),
      items: [
        {
          type: "timelimit",
          name: "availability",
          value: realSchedule,
          serverTimeZone,
          required: props.required,
        } as IUIItem,
      ],
      type: "",
      submit: {
        url,
        method: "PATCH",
        response: {
          "200,204": "close",
        },
      },
    };

    setActionProps(params);
  }, [realObject?.objectPath, realSchedule]);

  const scheduleControl = (
    <Link
      styles={{
        root: {
          textDecoration: "none !important",
          width: "100%",
        },
      }}
      onClick={showScheduleDialog}
    >
      <Stack horizontal={true} verticalAlign="center">
        <Icon iconName={"DateTime"} styles={{ root: { fontSize: 16 } }} />
        <div style={{ paddingLeft: 6 }}>{readableText}</div>
      </Stack>
    </Link>
  );

  return (
    <Stack style={style}>
      <Label required={required}>{label}</Label>
      <FocusZone
        style={{
          border: `1px solid ${theme.officeTheme.semanticColors.buttonBorder}`,
          padding: "6px 10px",
        }}
      >
        {scheduleControl}
      </FocusZone>
      {actionProps && (
        <CollectActionParametersDialog
          {...actionProps}
          onClose={() => setActionProps(null)}
          onSubmitCB={() => {
            refreshPropertiesPanel();
            setActionProps(null);
          }}
          actionBuilder={actionBuilder}
          footer={{
            okText: intl.formatMessage(commonMessages.save),
            cancelText: intl.formatMessage(commonMessages.cancel),
          }}
        />
      )}
    </Stack>
  );
};

export default InplaceAvailabilitySchedule;
