import { ActionButton } from "@fluentui/react";
import { themeUtils, useTheme } from "cayo.ui";
import React, { FC, useCallback, useRef, useState } from "react";
import { IContextPanel } from "../../api/schema.api";
import logger from "../../libs/logger";
import { useContextPanel } from "../GlobalHooks/context-panel.hook";
import menuButtonStyles from "./common";

const ContextPanel: FC<IContextPanel> = (props) => {
  const theme = useTheme();

  const [isSelected, setSelected] = useState(false);
  const buttonRef = useRef(null);

  const { showСontextPanel, hideContextPanel } = useContextPanel();

  const getTopOffset = () => {
    const div = buttonRef.current! as HTMLDivElement;
    logger.debug(div!.clientHeight);
    return div!.clientHeight;
  };

  const onClose = useCallback(() => {
    hideContextPanel();
  }, []);

  const onButtonClick = useCallback(() => {
    if (isSelected) {
      hideContextPanel();
      return;
    }

    setSelected(true);
    showСontextPanel({
      ...props,
      onClose,
      onClosed: () => setSelected(false),
      panelType: "smallFixedFar",
      style: { marginTop: getTopOffset() },
      type: "panel",
    });
  }, [isSelected]);

  return (
    <>
      <div ref={buttonRef} style={{ height: "100%" }}>
        <ActionButton
          id={props.name}
          ariaLabel={props.name}
          text=""
          onClick={onButtonClick}
          styles={menuButtonStyles.getNavHeaderStyles(theme, isSelected)}
          iconProps={themeUtils.getIconProps(props.icon)}
          style={{ height: "100%", width: "50px" }}
        />
      </div>
    </>
  );
};

export default ContextPanel;
