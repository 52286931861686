import { useCallback, useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";
import { CSSProperties } from "styled-components";
import { IContextPanel } from "../../api/schema.api";
import { IClosableComponent } from "../Surfaces/Panel";

const _useContextPanel = () => {
  const [contextPanel, setContextPanel] = useState<
    (IContextPanel & IClosableComponent & { style?: CSSProperties }) | undefined
  >(undefined);

  const [contextPanelVisible, setContextPanelVisible] = useState(false);

  const showСontextPanel = useCallback(
    (panel?: IContextPanel & IClosableComponent & { style?: CSSProperties }) => {
      setContextPanel(panel);
      setContextPanelVisible(panel !== undefined);
    },
    [contextPanel]
  );

  useEffect(() => {
    return () => contextPanel?.onClosed && contextPanel!.onClosed();
  }, [contextPanel]);

  return {
    contextPanel,
    showСontextPanel,
    hideContextPanel: () => showСontextPanel(undefined),
    contextPanelVisible,
  };
};

export const useContextPanel = singletonHook(
  {
    contextPanel: undefined,
    showСontextPanel: () => null,
    hideContextPanel: () => null,
    contextPanelVisible: false,
  },
  _useContextPanel
);
