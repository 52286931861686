import { FocusZone, Icon, Link, Stack } from "@fluentui/react";
import { IFormField, Label, useTheme } from "cayo.ui";
import React, { CSSProperties, FC, Fragment, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScheduledRule } from "../../api/cayo-graph";
import { useSchemes } from "../App/hooks";
import messages from "./messages";
import scheduleFormatters from "./schedule-formatters";
import scheduleTypes from "./types";

type InplaceScheduleProps = {
  object?: ScheduledRule;
  style?: CSSProperties;
  compact?: boolean;
} & IFormField;

const InplaceSchedule: FC<InplaceScheduleProps> = (props) => {
  const { object, style, compact, tooltip, label, hidden } = props;
  const { refreshPropertiesPanel, showControl } = useSchemes();

  const theme = useTheme();
  const intl = useIntl();

  let realSchedule = object?.schedule;
  let realObject = object;

  // TEMP

  if (!realSchedule) {
    realSchedule = realObject?.schedule;
  }

  const readableText = useMemo(() => {
    let result = null;
    const scheduleType = realSchedule?.objectType as scheduleTypes;

    const formatFunc = realSchedule && scheduleFormatters[scheduleType];
    if (formatFunc) {
      result = formatFunc(realSchedule!);
    } else {
      result = <FormattedMessage id="inplace-schedule.not-set" defaultMessage="Not set" />;
    }

    return result;
  }, [realSchedule]);

  const showScheduleDialog = useCallback(() => {
    showControl({
      controlId: "Schedule",
      parameters: {
        onRefresh: refreshPropertiesPanel,
        //onClose: hideClientComponents,
        method: "PATCH",
        url: "v0/" + realObject?.objectPath,
        object: realSchedule,
      },
    } as any);
  }, [realObject?.objectPath, realSchedule]);

  if (hidden) {
    return <Fragment />;
  }

  const scheduleControl = (
    <Link
      styles={{
        root: {
          textDecoration: "none !important",
          width: "100%",
        },
      }}
      onClick={showScheduleDialog}
    >
      <Stack horizontal={true} verticalAlign="center">
        <Icon iconName={"DateTime"} styles={{ root: { fontSize: 16 } }} />
        <div style={{ paddingLeft: 6 }}>{readableText}</div>
      </Stack>
    </Link>
  );

  if (compact) {
    return scheduleControl;
  }

  return (
    <Stack style={style}>
      <Label
        required={true}
        tooltip={tooltip}
        label={label ?? intl.formatMessage(messages.schedule)}
      ></Label>
      <FocusZone
        style={{
          border: `1px solid ${theme.officeTheme.semanticColors.buttonBorder}`,
          padding: "6px 10px",
        }}
      >
        {scheduleControl}
      </FocusZone>
    </Stack>
  );
};

export default InplaceSchedule;
