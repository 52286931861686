import pckg from "../../../package.json";
import { endpoints } from "../../services/endpoints.service";

const clientVariablePrefix = "__client__";

const wellKnownVars = {
  timeZone: () => (-new Date().getTimezoneOffset()).toString(),
  publicUrl: () => endpoints.publicUrl,
};

const resolve = (expression: string) => {
  if (expression === "client.timeZone") {
    return (-new Date().getTimezoneOffset()).toString();
  }

  if (!expression.startsWith(clientVariablePrefix)) {
    return undefined;
  }

  const [, scope, version] = expression.split(".");

  if (scope === "package" && version === "version") {
    return pckg.version;
  }

  const func = (wellKnownVars as any)[scope];
  if (func) {
    return func();
  }

  return undefined;
};

const clientVarsResolver = {
  resolve,
};

export default clientVarsResolver;
