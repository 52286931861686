import { UserProfileClient } from "../../../api/user-profile.api";
import logger from "../../../libs/logger";
import { endpoints } from "../../../services/endpoints.service";

const log = logger.getLogger("QUICK-FILTER-NEW RecentQueriesStore");

type RecentQueryItem = { id: string; time: number };
type RecentQueries = { items: Array<RecentQueryItem> };
type FavoriteQueries = { items: Array<string> };
class RecentQueriesStore {
  private _recentQueriesCache?: Array<RecentQueryItem>;
  // private _favoriteQueryCache: Array<string> = [];

  private readonly _name: string;
  private readonly _recentClass = "recentQueries";
  private readonly _favoriteClass = "favoriteQueries";
  private readonly _recentClient: UserProfileClient<RecentQueries>;
  private readonly _favoriteClient: UserProfileClient<FavoriteQueries>;

  constructor(targetType: string, public readonly top: number = 10) {
    this._name = targetType.replaceAll(".", "_");
    log.debug(".ctor -> targetType, name", targetType, this._name);
    this._recentClient = new UserProfileClient(endpoints.publicUrl);
    this._favoriteClient = new UserProfileClient(endpoints.publicUrl);
  }

  public async getRecentQueries(): Promise<Array<string>> {
    if (!this._recentQueriesCache) {
      this._recentQueriesCache = (await this.loadRecentQueries()).items ?? [];
    }
    log.debug("getRecentQueries -> _recentQueriesCache", this._recentQueriesCache);
    return this._recentQueriesCache.map((v) => v.id);
  }

  private async loadRecentQueries(): Promise<RecentQueries> {
    try {
      const result = await this._recentClient.getElement(this._recentClass, this._name);
      log.debug("loadRecentQueries -> result", result);
      if (!result) {
        return { items: [] };
      }
      return { items: result.items.filter((itm) => !!itm.id) };
    } catch (error) {
      log.error("loadRecentQueries -> error", error);
      return { items: [] };
    }
  }

  public setQueryCalled(id?: string): Array<string> {
    if (!this._recentQueriesCache) return [];
    if (!!id) {
      this._recentQueriesCache = [{ id, time: Date.now() }]
        .concat(this._recentQueriesCache.filter((v) => v.id !== id))
        .slice(0, this.top);
    }
    // save in profile
    this._recentClient.saveElement(this._recentClass, this._name, {
      items: this._recentQueriesCache,
    });
    return this._recentQueriesCache.map((v) => v.id);
  }

  public remove(id: string): void {
    if (!this._recentQueriesCache) return;
    this._recentQueriesCache = this._recentQueriesCache.filter((v) => v.id !== id);
    this._recentClient.saveElement(this._recentClass, this._name, {
      items: this._recentQueriesCache,
    });
  }

  public async getFavoriteIds(): Promise<Array<string> | undefined> {
    // let t = true;
    // if (t) {
    //   return [...this._favoriteQueryCache];
    // }
    try {
      const result = await this._favoriteClient.getElement(this._favoriteClass, this._name);
      log.debug("getFavoriteIds -> result", result);
      if (!result) {
        return [];
      }
      return result.items;
    } catch (error) {
      log.error("getFavoriteIds -> error", error);
      return [];
    }
  }

  public async setFavoriteIds(ids: Array<string>): Promise<Array<string> | undefined> {
    log.debug("setFavoriteIds -> ids", ids);
    // let t = true;
    // if (t) {
    //   this._favoriteQueryCache = [...ids];
    //   return [...ids];
    // }
    await this._favoriteClient.saveElement(this._favoriteClass, this._name, {
      items: ids,
    });
    return [...ids];
  }
}

export { RecentQueriesStore };
