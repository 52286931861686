import { IFormField, IFormatableField, IText, logger } from "cayo.ui";
import { Entity } from "../../api/cayo-graph";
import { Formatters } from "../../api/schema.api";
import { objectUtils } from "../../utils/object-utils";
import { TabEx } from "../Navigation/Pivot";
import { formComponentTypes } from "./renderers/form-renderers";

type odataTypes = "#Edm.DateTimeOffset" | "#Edm.Duration";

const odataTypeToComponentTypeMap: { [key: string]: formComponentTypes } = {
  "#Int32": "number",
  "#Collection(String)": "mv.text",
  "#Edm.Boolean": "checkbox",
  "#Edm.Enum": "dropdown",
  "cayo.graph.customSchedule": "inplaceschedule",
  "#Collection(cayo.graph.softLink)": "objectlinks",
  "#cayo.graph.softLink": "objectlink",
};

type FormFieldProps = keyof Pick<
  IFormField,
  "label" | "required" | "disabled" | "order" | "placeHolder" | "dataType" | "tooltip" | "readOnly"
>;

const odataTypeFormatters: { [key in odataTypes]: Formatters } = {
  "#Edm.DateTimeOffset": "dateTime",
  "#Edm.Duration": "duration",
};

const componentPropNames: { [key in FormFieldProps | "pattern"]: string } = {
  label: "cayo.schema.displayName",
  required: "cayo.schema.required",
  disabled: "cayo.schema.disabled",
  readOnly: "cayo.schema.readOnly",
  order: "cayo.schema.propertiesFormPosition",
  pattern: "cayo.schema.pattern",
  placeHolder: "cayo.schema.placeholder",
  tooltip: "cayo.schema.toolTip",
  dataType: "odata.type",
};

const getOdataProp = (propName: string, suffix: string) => {
  return `${propName}@${suffix}`;
};

const getOdataType = (object: Entity, propName: string) => {
  const typeProp = getOdataProp(propName, "odata.type");
  const result = object[typeProp];
  return result;
};

const toTabs = (object: Entity) => {
  const result: TabEx[] = [];
  const hasSchedule = object["schedule@odata.type"] === "cayo.graph.customSchedule";
  const parameters =
    object["schedule@cayo.schema.parameters"] ?? "oneTime|daily|weekly|monthly|continuous|custom";
  if (hasSchedule) {
    result.push({
      headerText: "Schedule",
      customView: `schedule`,
      parameters,
      schemePath: object["objectPath"] + "/schedule",
      isDataset: false,
      order: object["schedule@cayo.schema.navigationPropertyPosition"] ?? Number.MAX_VALUE,
    });
  }
  return result;
};

const toUIAnnotations = (object: Entity, disabled?: boolean) => {
  const odataKeys = Object.keys(object).filter((k) => k.indexOf("@") > 0);
  const groupedOdataProps = objectUtils.groupBy(odataKeys, (k) => k.split("@")[0]);
  let result: IFormField[] = [];
  for (const group of groupedOdataProps) {
    const propName = group[0];
    const otype = getOdataType(object, propName);
    const uiType = odataTypeToComponentTypeMap[otype] ?? "text";

    if (!uiType) {
      continue;
    }

    const uiComp: IFormField = { type: uiType, name: propName };
    // const oDataPropValue = object[propName];
    // if (oDataPropValue && uiType !== "mv.text") {
    //   (uiComp as unknown as any).value = oDataPropValue;
    // } else if (disabled && uiType === "mv.text") {
    //   (uiComp as unknown as any).value = mvConverters.to[uiType as string](oDataPropValue);
    // }

    if (propName === "pwdLastSetDT") {
      logger.debug("ddd");
    }

    Object.entries(componentPropNames).forEach((e) => {
      const [k, v] = e;

      const odataPropName = getOdataProp(propName, v);
      const propValue = object[odataPropName];
      if (propValue) {
        uiComp[k] = propValue;
      }
    });

    if (uiComp.label) {
      if (uiComp.type === "text" && !(uiComp as IText).nullValueDecorator) {
        (uiComp as IText).nullValueDecorator = "[Not set]"; // TODO: use intl
      }

      if (disabled) {
        uiComp.readOnly = disabled;
      }

      const formattedField = uiComp as IFormatableField;
      let formatter =
        formattedField.formatter ?? (uiComp.dataType && odataTypeFormatters[uiComp.dataType!]);

      if (formatter) {
        (uiComp as IFormatableField).formatter = formatter;
      }

      result.push(uiComp);
    }
  }

  result.sort((a, b) => (a.order ?? Number.MAX_VALUE) - (b.order ?? Number.MAX_VALUE));

  return result;
};

const odataAnnotationsUtils = {
  getOdataProp,
  toUIAnnotations,
  toTabs,
};

export default odataAnnotationsUtils;
