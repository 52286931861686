import { DefaultButton, Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import { useAsync } from "@fluentui/react-hooks";
import { RefreshTime, domUtils, useComponentLogger } from "cayo.ui";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { IComponentStatus } from "../api/schema.api";
import ajax from "../libs/ajax";
import { bindingsUtils } from "../scheme/bindings";
import { endpoints } from "../services/endpoints.service";
import { ajaxUtils } from "../utils/ajax-utils";
import { appUtils } from "./App/utils";
import { Stack100pw } from "./CommonLayout";
import { commonMessages } from "./common-messages";

type ComponentStatusProps = IComponentStatus & { onRefresh?: () => void };

const ComponentStatus: FC<ComponentStatusProps> = ({ data, updateButton, version }) => {
  const log = useComponentLogger(ComponentStatus);
  const async = useAsync();
  const intl = useIntl();

  const [statusData, setStatusData] = useState(data);

  const items = useMemo(() => {
    const versionValue = version && bindingsUtils.resolveExpression(statusData, version);
    const lastCheckUpdateTime = statusData?.lastCheckUpdateTime;
    return [
      { name: intl.formatMessage(messages.version), value: versionValue },
      {
        name: intl.formatMessage(messages.lastChecked),
        value: lastCheckUpdateTime,
      },
      {
        name: intl.formatMessage(messages.status),
        value: statusData.updateState,
      },
    ];
  }, [statusData]);

  const [versionInfo, updateTime, status] = items;

  const updateObject = useCallback(() => {
    const path = endpoints.buildRelativeApiPath(data.objectPath);
    ajax.getClient(path).then((response) => {
      setStatusData(response);
    });
  }, []);

  const onUpdate = useCallback(() => {
    if (!data.objectPath || !updateButton?.updateAction) {
      appUtils.showError("Invalid scheme");
      return;
    }

    const updateFunc = async () => {
      try {
        setStatusData({ ...statusData, updateState: "running" });
        const objectPath = data.objectPath;
        const actionPath = endpoints.buildRelativeApiPath(objectPath, updateButton!.updateAction!);
        await ajax.getClient(actionPath, "POST");
      } catch (e) {
        const error = await ajaxUtils.getError(e);
        log.error(error);
        appUtils.showError(`Unable to update component: ${error?.error_description}`);
      } finally {
        // async.debounce(updateObject, 2000)();
      }
    };

    updateFunc();
  }, [updateButton?.updateAction, statusData]);

  useEffect(() => {
    const updateInterval = statusData.updateState === "running" ? 2000 : 1000 * 30;
    const timer = async.setInterval(updateObject, updateInterval);
    return () => async.clearInterval(timer);
  }, [statusData.updateState]);

  return (
    <Stack100pw
      horizontal={false}
      onMouseDown={domUtils.stopMouseDown}
      horizontalAlign="stretch"
      verticalFill={true}
    >
      <Stack tokens={{ childrenGap: 4 }} grow={true}>
        <NameValue name={versionInfo.name} value={versionInfo.value} />
        <NameValue
          name={updateTime.name}
          value={
            updateTime.value ? (
              <RefreshTime start={updateTime.value} />
            ) : (
              intl.formatMessage(commonMessages.never)
            )
          }
        />
      </Stack>
      {status.value === "running" ? (
        <Stack verticalAlign="center" horizontalAlign="center" grow={true} verticalFill={true}>
          <Spinner
            size={SpinnerSize.medium}
            label={intl.formatMessage(messages.checkingForUpdates)}
            labelPosition="right"
          />
        </Stack>
      ) : (
        <UpdateButton
          iconProps={{ iconName: "DrillDown" }}
          text={updateButton?.title}
          onClick={onUpdate}
        />
      )}
    </Stack100pw>
  );
};

const UpdateButton = styled(DefaultButton)`
  font-size: 13px;
  white-space: nowrap;
  margin-top: 4px;
`;

function NameValue(props: any) {
  const { name, value } = props;
  return (
    <Stack horizontal={true} horizontalAlign="space-between">
      <NameText variant="small" block={true} nowrap={true}>
        {name}
      </NameText>
      <Text variant="small" block={true} nowrap={true}>
        {value}
      </Text>
    </Stack>
  );
}

const NameText = styled(Text)`
  font-weight: 600;
  flex-shrink: 0;
  margin-right: 4px;
`;

const messages = defineMessages({
  checkingForUpdates: {
    defaultMessage: "Checking for updates...",
    id: "component-status.checking-for-updated",
  },
  version: {
    defaultMessage: "Version",
    id: "component-status.version",
  },
  status: {
    defaultMessage: "Status",
    id: "component-status.status",
  },
  lastChecked: {
    defaultMessage: "Last checked",
    id: "component-status.last-checked",
  },
});

export default ComponentStatus;
