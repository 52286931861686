import React from "react";
import { getFormatterFromRenderOptions } from "../../../utils/formatter-utils";
import ErrorTooltip from "../../Common/ErrorTooltip";
import formatterControls from "../../Schemes/renderers/formatter.renderers";
import RenderOptionsFormatter from "../utils/field-formatter";
import { lookupIcon, propertyIcon } from "./common";
import { RenderCellFn } from "./types";

//const log = logger.getLogger("RENDER-CELL icon-only");

export const renderIconOnlyCell: RenderCellFn = ({ item, column, auxProps }) => {
  const fieldFormatter = new RenderOptionsFormatter(
    column.renderOptions,
    getFormatterFromRenderOptions
  );

  if (!(column.isIconOnly || fieldFormatter.isIconOnly())) {
    return null;
  }

  const isNone =
    item[column.fieldName!] === "none" && column.fieldName !== "lastVerificationStatus";
  if (isNone) {
    return <></>;
  }
  let formatter = column.renderOptions?.formatter;

  const FormatterControl = formatter && formatterControls[formatter.toLowerCase()];
  if (FormatterControl) {
    return <FormatterControl {...item} />;
  }

  const icon = lookupIcon(item, auxProps!, column.fieldName);
  //log.debug("lookupIcon -> ", column.fieldName, icon);
  if (!icon || !icon?.isIconOnly) {
    return null;
  }

  let iconComponent = propertyIcon(icon, item[column.fieldName!]);
  if (item?.errors?.length > 0) {
    iconComponent = (
      <ErrorTooltip id={item.id} errors={item.errors}>
        {iconComponent}
      </ErrorTooltip>
    );
  }
  return iconComponent;
};
