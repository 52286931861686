import { useCallback, useState } from "react";
import { IExpressionFormHook, IExpressionFormState, ModifiedType, IExFormState } from "./types";
import { SyntaxEnum } from "../../types";

const expressionForm = {
  postFix: "any",
  operator: "present",
  value: "",
  addColumns: false,
};

const formState = {
  operators: [],
  selectOptions: [],
  syntax: SyntaxEnum.string,
  component: "text",
};

export const useExpressionForm = (): IExpressionFormHook => {
  const [expression, setExpression] = useState<IExpressionFormState>({ ...expressionForm });
  const [disabled, setDisabled] = useState<boolean>(false);
  const [disabledCheckBox, setDisabledCheckBox] = useState<boolean>(false);
  const [formErrorMsg, setFormErrorMsg] = useState<string>("");
  const [exFormState, setExFormState] = useState<Omit<IExFormState, "disable">>(() =>
    structuredClone(formState)
  );

  const onChange = useCallback((key: string, value: string | boolean) => {
    setExpression((prevState) => {
      let newState = {
        ...prevState,
        [key]: value,
      };

      if (newState.postFix === ModifiedType.ANY) {
        newState = {
          ...newState,
          operator: "present",
          value: "",
        };
      }

      return newState;
    });
  }, []);

  const setDisabledState = useCallback((disabled: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { addColumns: _, ...rest } = expressionForm;
    disabled && setExpression((prevState) => ({ ...prevState, ...rest }));
    setDisabled(disabled);
  }, []);

  const setFormState = useCallback(
    ({ disable, ...rest }: IExFormState) => {
      setDisabledState(disable);

      if (!disable) {
        setExFormState(rest);
        setExpression((prevState) => {
          if (rest.component !== exFormState.component) {
            return {
              ...prevState,
              operator: "present",
              value: "",
            };
          }

          return prevState;
        });
      }
    },
    [setDisabledState, exFormState]
  );

  const reset = useCallback(() => {
    setExFormState(formState);
    setExpression(expressionForm);
  }, []);

  const handleCheckBoxState = useCallback(
    (disabled: boolean) => {
      setDisabledCheckBox(disabled);

      if (disabled) {
        onChange("addColumns", false);
      }
    },
    [onChange]
  );

  return {
    exFormState,
    expression,
    disabled,
    formErrorMsg,
    disabledCheckBox,
    setFormErrorMsg,
    setFormState,
    setDisabledState,
    onChange,
    setExpression,
    handleCheckBoxState,
    reset,
  };
};
