import { LoadingOverlay, debounce, stringUtils, useComponentLogger, useTheme } from "cayo.ui";

import { Wizard, WizardPage } from "cayo.ui.wizards";
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Entity } from "../../../api/cayo-graph";
import { IWizard, IWizardStep } from "../../../api/schema.api";
import ajax from "../../../libs/ajax";
import useService from "../../../services/services.hook";
import useSignals from "../../../services/signal-service-base";
import { ajaxUtils } from "../../../utils/ajax-utils";
import { appSignals, showActionParams } from "../../App/utils";
import Form from "../../Form";
import { getVisibleItems } from "../../Form/modelUtils/get-model";
import { buttonRenderers } from "../../Form/renderers/renderers.buttons";
import useTypeAnnotations from "../../GlobalHooks/type-annotations.hook";
import { WizardController } from "./WizardController";
import { useUserInput } from "./useUserInput";

type WizardsSchemeHolderProps = { onWizardAction: (show: boolean) => void };

const WizardsSchemeHolder: FC<WizardsSchemeHolderProps> = ({ onWizardAction }) => {
  const [wizardParams, setWizardParams] = useState<showActionParams>();
  const [wizard, setWizard] = useState<{ scheme: IWizard; object?: Entity }>();
  const actionBuilder = useService("actionBuilder");
  const [isFinished, setIsFinished] = useState(false);
  const log = useComponentLogger(WizardsSchemeHolder);
  const [isInitializing, setIsInitializing] = useState<boolean | undefined>(undefined);
  const activeStep = useRef<string>();
  const { getIconAnnotation } = useTypeAnnotations();
  const theme = useTheme();

  const [count, forceUpdate] = useReducer((x) => x + 1, 0);

  const onClose = useCallback(
    (isComplete: boolean) => {
      setWizard(undefined);
      setWizardParams(undefined);
      onWizardAction(false);

      if (isComplete && wizardParams?.reloadAfterClose) {
        log.debug("reload page, reloadAfterClose");
        document.location.reload();
      }
    },
    [setWizard, setWizardParams, wizardParams]
  );

  useEffect(() => {
    if (!wizardParams?.schemeId) {
      return;
    }

    const loadScheme = async () => {
      onWizardAction(true);
      try {
        const scheme = (await ajax.fetchFn("scheme/wizards/" + wizardParams?.schemeId)) as IWizard;
        setWizard({ scheme });
      } catch (e) {
        onWizardAction(false);
        onClose(false);
        ajaxUtils.handleError(e);
      }
    };

    loadScheme();
  }, [wizardParams?.schemeId, onClose]);

  const ctrl = useMemo(() => {
    const result = wizard?.scheme ? new WizardController(wizard.scheme, actionBuilder) : null;
    return result;
  }, [wizard?.scheme]);

  useEffect(() => {
    return appSignals.subscribe("showWizardScheme", (prms) => {
      setWizardParams(prms);
    });
  }, []);

  const handleResponse = useCallback(
    (action: { [key: string]: string } | undefined, response: any, isStandalone?: boolean) => {
      log.debug(action, response);

      if (action && activeStep.current) {
        const model = ctrl?.getModel();
        const stepName = ctrl?.getStepByTitle(activeStep.current);
        ctrl?.handleResponse(action, response, model, isStandalone ? "init" : stepName!);
        forceUpdate();
      }

      return Promise.resolve();
    },
    [ctrl]
  );

  const signals = useSignals<"close">([{ s: "close", handler: onClose }]);

  const [loadingText, setLoadingText] = React.useState<string | undefined>(undefined);

  const allowUpdates = useRef(false);

  const allowUpdateCallback = useCallback(() => {
    allowUpdates.current = true;
  }, []);

  useUserInput(allowUpdateCallback);

  const updateStepsCallback = useCallback(
    (stepName: string, model: any, isSaveDisabled: boolean) => {
      ctrl?.updateSteps(stepName, model, isSaveDisabled);

      if (allowUpdates.current) {
        forceUpdate();
      }
    },
    [ctrl]
  );

  const model = useMemo(() => {
    return isInitializing ? undefined : ctrl?.getModel();
  }, [isInitializing, count]);

  useEffect(() => {
    const asyncInit = async () => {
      if (ctrl && wizard?.scheme?.steps?.length) {
        try {
          setIsInitializing(true);
          await ctrl.init();
        } catch (e) {
          await ajaxUtils.handleError(e);
        } finally {
          setIsInitializing(false);
          debounce(forceUpdate, 500)();
        }
      }
    };

    if (wizard?.scheme?.steps?.length) {
      asyncInit();
    }
  }, [ctrl, wizard?.scheme]);

  const auxActions = useMemo(() => ctrl?.getActionButtons(), [model]);

  const onNextCallback = useCallback(
    async (s: IWizardStep) => {
      setLoadingText(undefined);

      const result = await ctrl!.onNext(s.name);
      const visibleSteps = getVisibleItems(wizard!.scheme.steps, ctrl?.getModel());
      const prevBeforeLastStep = visibleSteps[visibleSteps.length - 2];
      if (prevBeforeLastStep.name === s.name) {
        setIsFinished(true);
      }

      forceUpdate();
      return result;
    },
    [wizard?.scheme?.steps]
  );

  if (isInitializing === true) {
    return <LoadingOverlay />;
  }

  if (!wizardParams?.schemeId || !wizard?.scheme?.steps?.length) {
    return <Fragment />;
  }

  return (
    <Wizard
      loadingText={loadingText}
      title={wizard.scheme.title!}
      isFinished={isFinished}
      activePage={0}
      data={model}
      closeConfirmationMessage={wizard.scheme.closeConfirmationMessage}
      onClose={onClose}
      showStepError={true}
      getError={ajaxUtils.getError}
      AuxActionsButtons={() =>
        auxActions?.map((a) => {
          const renderName = stringUtils.dashedToLowCase(a.type);
          const Control = buttonRenderers[renderName];
          return Control(a, { theme, actionBuilder, signals, handleResponse });
        })
      }
      onActiveStepChanged={(name) => {
        activeStep.current = name;
      }}
    >
      {wizard?.scheme?.steps?.map((s) => (
        <WizardPage
          key={s.name}
          isInputValid={ctrl!.isInputValid(s.name)}
          isHidden={ctrl?.isHidden(s.name)}
          onNext={async () => {
            const result = await onNextCallback(s);
            return result;
          }}
          title={s.title!}
          stepName={s.title!}
          subTitle={""}
          error={s.error}
        >
          <Form
            {...s}
            title=""
            actionBuilder={actionBuilder}
            isInsidePropPanel={true}
            noContentPadding={true}
            parentModel={model}
            handleResponse={handleResponse}
            getIconAnnotation={getIconAnnotation}
            renderForm={false}
            updateModel={(model, isNextDisabled) =>
              updateStepsCallback(s.name, model, isNextDisabled)
            }
          />
        </WizardPage>
      ))}
    </Wizard>
  );
};

export default WizardsSchemeHolder;
