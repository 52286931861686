import { defineMessages } from "react-intl";

const actionMessages = defineMessages({
  fromUrlNotSpecified: {
    defaultMessage: "Form can't be submitted: url is not specified.",
    id: "actions.form-url-not-specified",
  },
});

export default actionMessages;
