import { CommandBar as OfficeCommandBar } from "@fluentui/react";
import { domUtils } from "cayo.ui";
import React, { CSSProperties, FC, Fragment, useEffect } from "react";
import styled from "styled-components";
import { Entity } from "../../api/cayo-graph";
import { ICommandBar } from "../../api/schema.api";
import { IPropertiesPanelSignals } from "../../services/properties-panel-signal.service";
import AnrSearchBox from "../AnrSearchBox";
import { GroupConfig } from "../GridContainer/GridFilterBar";
import { IGridState, UIInteraction } from "../GridContainer/grid-model/GridController";
import { DispatchAction } from "../GridContainer/grid-model/grid.controller.actions";
import useResetColumnsAndGroupButton from "../GridContainer/hooks/useResetColumnsAndGroupButton";
import useCommandBar from "./hooks";
import { useFarItems } from "./useFarItems";

//const log = logger.getLogger("QUICK-FILTER-NEW CommandBar2");

export type CommandBarProps = {
  scheme: Omit<ICommandBar, "type">;
  dispatch?: DispatchAction;
  gridState?: IGridState;
  uiInteraction: UIInteraction;
  groupConfig?: GroupConfig[];
  showFilterBarItems: boolean;
  object?: Entity;
  style?: CSSProperties;
  signals?: IPropertiesPanelSignals;
};

const CommandBar: FC<CommandBarProps> = (props) => {
  const { style, scheme } = props;
  const { items: officeItems } = useCommandBar(props);

  useEffect(() => {
    props.dispatch && props.dispatch({ kind: "setContextMenu", menu: officeItems });
  }, [officeItems]);

  const resetBtn = useResetColumnsAndGroupButton({
    query: props?.gridState?.query || ({} as any),
    dispatch: props?.dispatch,
    groupConfig: props.groupConfig,
    hide: props.showFilterBarItems !== true,
  });

  const farItems = useFarItems(
    resetBtn,
    scheme.showSelectionCounter,
    props?.dispatch,
    props.gridState?.selectedItems
  );

  if (!farItems?.length && !officeItems?.length) {
    return <Fragment />;
  }

  return (
    <CommandBarContainer additionalStyle={style}>
      {props.showFilterBarItems && props.dispatch && (
        <AnrSearchBox query={props.gridState?.query} dispatch={props.dispatch} width={200} />
      )}
      <StyledOfficeCommandBar
        onMouseDown={domUtils.stopMouseDown}
        items={officeItems}
        farItems={farItems}
        styles={{ root: { padding: 0 } }}
      ></StyledOfficeCommandBar>
    </CommandBarContainer>
  );
};

const StyledOfficeCommandBar = styled(OfficeCommandBar)`
  flex-grow: 1;
  min-width: 1px;
`;

const CommandBarContainer = styled.div<{ additionalStyle?: CSSProperties }>`
  display: flex;
  padding: 0 5px;
  flex-shrink: 0;
  align-items: center;
  ${(props) => ({ ...props.additionalStyle })}
`;

export default CommandBar;
