import { IFontStyles, Stack } from "@fluentui/react";
import { Label, useTheme } from "cayo.ui";
import React from "react";
import { FCC } from "../../types/react";

type LabeledContainerProps = { label: string; labelSize: keyof IFontStyles; onCopy?: () => void };

const LabeledContainer: FCC<LabeledContainerProps> = ({ label, labelSize, children, onCopy }) => {
  const { officeTheme } = useTheme();

  return (
    <Stack horizontal={false}>
      <Label
        label={label}
        style={{ fontSize: officeTheme.fonts[labelSize].fontSize }}
        onCopy={onCopy}
      />
      {children}
    </Stack>
  );
};

export default LabeledContainer;
