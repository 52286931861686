import { IconButton, Stack, Text } from "@fluentui/react";
import { RefreshTime, Separator, useTheme } from "cayo.ui";
import React, { useMemo } from "react";
import { IHelpInfo } from "../../api/schema.api";

import { domUtils } from "cayo.ui";
import { FCC } from "../../types/react";
import globalHooks from "../GlobalHooks";
import Column from "../Layout/Column";
import Row from "../Layout/Row";
import { WidgetTitle } from "./common";

type WidgetHeaderProps = {
  title: string;
  updateTime?: Date | undefined;
  titleSeparator?: boolean;
  onRefresh?: () => void;
  showClock?: boolean;
  help?: IHelpInfo;
};

const WidgetHeader: FCC<WidgetHeaderProps> = ({
  title,
  updateTime,
  titleSeparator: contentSeparator,
  showClock,
  onRefresh,
  children,
  help,
}) => {
  const { cayoTheme } = useTheme();

  const { hovered, eventHandlers } = globalHooks.useHover();

  const titleComp = useMemo(() => {
    let result = <div {...eventHandlers}>{title}</div>;
    if (help?.link) {
      result = (
        <Stack horizontal={true} verticalAlign="start" verticalFill={true}>
          {result}
          <IconButton
            {...eventHandlers}
            title={help?.title}
            onMouseDown={domUtils.stopMouseDown}
            iconProps={{ iconName: "Help" }}
            styles={{
              icon: { fontSize: 10, fontWeight: hovered ? "bold !important" : "unset" },
              iconHovered: { fontWeight: "bold" },
              root: { height: 20, width: 24 },
              rootHovered: { background: "none" },
              rootPressed: { background: "none" },
            }}
            href={help.link}
            target="_blank"
          />
        </Stack>
      );
    }

    return result;
  }, [title, help?.link, hovered]);

  return (
    <Column style={{ flexShrink: 0 }}>
      <Row valign={true}>
        <WidgetTitle nowrap={true} variant={"mediumPlus"}>
          <Stack
            horizontal={true}
            verticalFill={true}
            verticalAlign="center"
            grow={true}
            tokens={{ childrenGap: 4 }}
          >
            {titleComp}
            {children}
          </Stack>
        </WidgetTitle>
        {showClock !== false && onRefresh && (
          <IconButton
            iconProps={{ iconName: "Refresh" }}
            styles={{
              icon: { fontSize: 12 },
              root: { fontSize: 12, height: 12, width: 12, marginRight: 6 },
            }}
            onClick={onRefresh}
            onMouseDown={(e) => e.stopPropagation()}
          />
        )}
        {updateTime && showClock !== false && (
          <Text
            variant="small"
            block={true}
            nowrap={true}
            style={{ color: cayoTheme.brandColors.disabled }}
          >
            <RefreshTime start={updateTime} />
          </Text>
        )}
      </Row>
      {contentSeparator && <Separator preset="small" />}
    </Column>
  );
};

export default WidgetHeader;
