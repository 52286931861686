import { Html, stringUtils } from "cayo.ui";
import React, { Fragment } from "react";
import styled from "styled-components";
import { objectUtils } from "../../../utils/object-utils";
import LabeledContainer from "../../Common/LabeledContainer";

import { AuxRenderProps, IActionPromise } from "../../LegacyDetailsList/cell-renderers";
import { oDataTypes } from "../../ObjectPicker/odata-converters";
import LinkedObject from "../components/LinkedObject";
import GridCell from "./GridCell";
import { RenderCellFn } from "./types";

export const renderOdataCell: RenderCellFn = ({ item, index, column, auxProps, onItemClicked }) => {
  if (column.renderOptions?.formatter !== "odata") {
    return null;
  }

  const obj = item[column.fieldName!];
  if (!obj) {
    return <></>;
  }

  const props = Object.keys(obj)
    .filter((k) => k.indexOf("@") === -1)
    .sort((a, b) => {
      const posA = getOdataProp(a, "position");
      const posB = getOdataProp(b, "position");

      return (obj[posA] ?? 0) - (obj[posB] ?? 0);
    });
  const result = props.map((p, i) => {
    const type = getOdataProp(p, "type");
    const displayNameProp = getOdataProp(p, "displayName");
    const displayName = obj[displayNameProp] ?? stringUtils.toSpaceDelimitedSentenceCase(p);
    const isSoftLink = type ? obj[type] === oDataTypes.softLink : false;

    let comp: any;

    // logger.debug("wellknown", obj[p]);
    const key = p + index + i;
    const isArray = objectUtils.isArray(obj[p]);
    if (isArray) {
      const nestedObj = obj[p] as any[];
      return renderLabeledObject(nestedObj, displayName, key, onItemClicked, auxProps);
    } else if (isSoftLink) {
      comp = (
        <LinkedObject
          key={key}
          {...obj[p]}
          onItemClicked={onItemClicked!}
          getIconAnnotation={auxProps?.getIconAnnotation!}
        />
      );
    } else {
      comp = renderObject(obj[p], onItemClicked, auxProps);
    }

    return (
      <LabeledContainer key={key} label={displayName} labelSize={"small"}>
        {comp}
      </LabeledContainer>
    );
  });
  return (
    <GridCell renderer="odata-cell" multiline toCopyValue={() => odataToCopy(obj)}>
      <OdataContainer>{result}</OdataContainer>
    </GridCell>
  );
};

const suffixes = {
  displayName: "@cayo.schema.displayName",
  type: "@odata.type",
  position: "@cayo.schema.propertiesFormPosition",
};
type PropNames = keyof typeof suffixes;

const getOdataProp = (prop: string, suffix: PropNames) => {
  return `${prop}${suffixes[suffix]}`;
};

const renderLabeledObject = (
  o: any,
  label: string,
  key: string,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  return (
    <LabeledContainer key={key} label={label} labelSize={"small"}>
      {renderObject(o, onItemClicked, auxProps)}
    </LabeledContainer>
  );
};

const renderObject = (
  o: any,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  if (Array.isArray(o)) {
    return renderArray(o, onItemClicked, auxProps);
  }

  if (objectUtils.isPrimitive(o)) {
    return <Html value={o} noWrap={true} truncated={true} />;
  }

  return <Fragment />;
};
const renderArray = (
  o: any[],
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  return <Fragment>{o.map((oo) => renderObject(oo, onItemClicked, auxProps))}</Fragment>;
};

const OdataContainer = styled.div`
  margin-top: -5px;
`;

export const odataToCopy = (value: any): string => {
  const props = Object.keys(value)
    .filter((k) => k.indexOf("@") === -1)
    .sort((a, b) => {
      const posA = getOdataProp(a, "position");
      const posB = getOdataProp(b, "position");

      return (value[posA] ?? 0) - (value[posB] ?? 0);
    });

  const buffer: Array<string> = [];

  props.forEach((p) => {
    const type = getOdataProp(p, "type");
    const displayNameProp = getOdataProp(p, "displayName");
    const displayName = value[displayNameProp] ?? stringUtils.toSpaceDelimitedSentenceCase(p);
    const isSoftLink = type ? value[type] === oDataTypes.softLink : false;
    buffer.push(`${displayName}:`);
    const propValue = value[p];
    switch (true) {
      case objectUtils.isPrimitive(propValue):
        buffer.push(`  ${propValue}`);
        break;
      case isSoftLink:
        buffer.push(`  ${propValue.objectName}`);
        break;
      case Array.isArray(propValue):
        // console.log("ARRAY", propValue);
        propValue.forEach((v: any) => {
          buffer.push(`  ${v}`);
        });
        break;
    }
  });
  return buffer.join("\n");
};
