import { IPanelStyles, Panel as OfficePanel, PanelType as OfficePanelType } from "@fluentui/react";
import React, { useMemo } from "react";
import { Entity } from "../../api/cayo-graph";
import { IPanel, PanelType } from "../../api/schema.api";
import { FCC } from "../../types/react";
import Resizer from "../Common/Resizer";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
import { IClosableComponent } from "../Surfaces/Panel";
import NavigationCommands from "./NavigationCommands";
import useResizer from "./resizer.hook";

type PanelProps = IPanel & {
  hideCloseButton?: boolean | undefined;
  onRefresh?: () => void;
  object?: Entity;
} & IClosableComponent;

const Panel: FCC<PanelProps> = (props) => {
  const { actualType, panelWidth, showResizer, onWidthChanged, handleFullscreenToggled } =
    useResizer(props.type as PanelType, props.customWidth);

  const isConfirmModal = props.id?.endsWith("confirm-modal");

  const { getTypeAnnotation } = useTypeAnnotations();

  const helpLink = useMemo(() => {
    return props?.object?.objectType && getTypeAnnotation(props.object.objectType)?.helpLink;
  }, [props?.object?.objectType, getTypeAnnotation]);

  const closeAction = props.onClose;

  return (
    <OfficePanel
      styles={styles(props, isConfirmModal)}
      type={actualType}
      isOpen={props.isOpen}
      isBlocking={props.isBlocking}
      customWidth={
        actualType === OfficePanelType.custom
          ? panelWidth
            ? panelWidth.toString() + "px"
            : undefined
          : undefined
      }
      style={{ marginTop: 65 }}
      hasCloseButton={isConfirmModal}
      onOuterClick={(e) => e && e.stopPropagation()}
      onDismiss={() => {
        closeAction!();
      }}
      onRenderNavigation={(p, r) => {
        if (isConfirmModal) {
          return r!(p);
        }

        return (
          <NavigationCommands
            actualType={actualType}
            handleFullscreenToggled={handleFullscreenToggled}
            onClose={closeAction}
            onRefresh={props?.onRefresh}
            helpLink={helpLink}
          />
        );
      }}
      onRenderBody={(p, renderer) => {
        if (!showResizer) {
          return renderer!(p);
        }

        return <Resizer onWidthChanged={onWidthChanged}>{renderer!(p)}</Resizer>;
      }}
      onDismissed={async () => {
        const clickAction: any = (props as any)?.onClose;
        if (clickAction) {
          return clickAction();
        }

        return Promise.resolve();
      }}
    >
      {props.children}
    </OfficePanel>
  );
};

const styles = (props: IPanel, isConfirmModal?: boolean): Partial<IPanelStyles> => {
  return {
    content: {
      paddingBottom: "0px !important",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",

      width: "100%",
      display: "flex",
      flexFlow: "column",
    },
    navigation: isConfirmModal && {
      position: isConfirmModal && "absolute",
      right: isConfirmModal && 0,
    },
    commands: { position: "relative" },
    // main: {
    //   maxWidth: props.style?.maxWidth,
    // },
    // root: {
    //   ...props.style,
    // },

    scrollableContent: {
      display: "flex",
      overflow: "hidden",
      height: "100%",
      flexFlow: "column",
    },
  };
};

export default Panel;
