import { IButtonStyles, IconButton } from "@fluentui/react";
import { domUtils } from "cayo.ui";
import React, { CSSProperties, FC, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

interface ICopyButton {
  onClick: () => void;
  isAbsolutePosition?: boolean;
  isInsideCopyContainer?: boolean;
  hasBackground?: boolean | undefined;
  style?: CSSProperties;
  disabled?: boolean;
}

const CopyButton: FC<ICopyButton> = ({
  onClick,
  isAbsolutePosition,
  isInsideCopyContainer,
  hasBackground,
  style,
  disabled,
}) => {
  const intl = useIntl();
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div
      style={{
        ...(style || {}),
        ...(isAbsolutePosition ? btnAbsPosytionStyle : {}),
        display: "flex",
        alignItems: "center",
        right: 0,
      }}
    >
      <IconButton
        className={isInsideCopyContainer ? "copy-button" : undefined}
        style={style}
        iconProps={{ iconName: "Copy" }}
        title={isCopied ? intl.formatMessage(messages.copied) : intl.formatMessage(messages.copy)}
        onClick={(e) => {
          domUtils.stopMouseDown(e);
          onClick();
          setIsCopied(true);
        }}
        onDoubleClick={domUtils.stopMouseDown}
        styles={hasBackground === false ? noBackgroundStyle : undefined}
        disabled={disabled}
        onMouseEnter={domUtils.stopMouseDown}
        onMouseDown={domUtils.stopMouseDown}
      />
    </div>
  );
};

const messages = defineMessages({
  copy: {
    defaultMessage: "Copy to clipboard",
    id: "copy-btn.copy-to-clipboard",
  },
  copied: {
    defaultMessage: "Copied to clipboard",
    id: "copy-btn.copied-to-clipboard",
  },
});
const noBackgroundStyle: IButtonStyles = {
  rootHovered: { background: "none" },
  rootPressed: { background: "none" },
};

const btnAbsPosytionStyle: CSSProperties = { position: "absolute" };

export default CopyButton;
