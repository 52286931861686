import { IColumnRenderOptions } from "../api/schema.api";

type RenderOptionsType = IColumnRenderOptions & {
  itemFormatValue?: string | undefined;
};

export function getFormatterFromRenderOptions(renderOptions: RenderOptionsType) {
  let formatter = renderOptions?.itemFormatValue
    ? renderOptions?.itemFormatValue
    : renderOptions?.formatter || "";

  if (renderOptions?.additionalParameters) {
    formatter = `${formatter},${renderOptions?.additionalParameters}`;
  }

  return formatter;
}
