import { useAsync } from "@fluentui/react-hooks";
import { useLogger } from "cayo.ui";
import { useCallback, useEffect, useState } from "react";
import globalHooks from ".";
import { ExecutionState } from "../../api/cayo-graph";
import ajax from "../../libs/ajax";
import { endpoints } from "../../services/endpoints.service";
import { ajaxUtils } from "../../utils/ajax-utils";
import { appUtils } from "../App/utils";

type RunnableObjectProps = {
  objectPath: string;
  actionPath: string;
  stateProp: string;
  data?: any;
  onRefresh?: () => void;
};

const useRunnableObject = (props: RunnableObjectProps) => {
  const log = useLogger("useRunnableObject");
  const async = useAsync();

  const { actionPath, objectPath, stateProp, data, onRefresh } = props;

  const [objectData, setObjectData] = useState(data || {});

  const unmounted = globalHooks.useUnmounted();

  const updateObject = useCallback(() => {
    const path = endpoints.buildRelativeApiPath(data?.objectPath ?? objectPath);
    const prevStatus = (objectData && objectData[stateProp]) as ExecutionState;
    ajax.getClient(path).then((response) => {
      if (unmounted.current) {
        return;
      }

      setObjectData(response);
      if (prevStatus === "running" && response[stateProp] !== "running") {
        if (onRefresh) {
          onRefresh();
          // async.debounce(onRefresh, 1000)();
        }
      }
    });
  }, [objectData]);

  const onUpdate = useCallback(() => {
    if (!objectPath || !actionPath) {
      appUtils.showError("Invalid scheme");
      return;
    }

    const updateFunc = async () => {
      try {
        const actualObjectPath = objectPath ?? (data.objectPath as string);
        const actionFullPath = endpoints.buildRelativeApiPath(actualObjectPath, actionPath!);

        if (unmounted.current) {
          return;
        }

        setObjectData({ ...objectData, [stateProp]: "running" });
        const result = await ajax.getClient(actionFullPath, "POST", undefined, {
          waitForStart: true,
        });

        if (unmounted.current) {
          return;
        }

        setObjectData(result);
      } catch (e: any) {
        const error = await ajaxUtils.getError(e);
        log.error(error);
        appUtils.showMessage(
          error?.error_description ?? "",
          e?.status === 402 ? "warning" : "error"
        );
      } finally {
        if (!unmounted.current) {
          async.debounce(updateObject, 1000)();
        }
      }
    };

    updateFunc();
  }, [actionPath, objectData]);

  useEffect(() => {
    if (!data?.objectPath) {
      updateObject();
    }
  }, []);

  useEffect(() => {
    const updateInterval = objectData[stateProp] === "running" ? 2000 : 1000 * 30;
    const timer = async.setInterval(updateObject, updateInterval);
    return () => async.clearInterval(timer);
  }, [objectData[stateProp]]);

  return { onUpdate, objectState: (objectData && objectData[stateProp]) as ExecutionState };
};

export default useRunnableObject;
