import { Entity, SoftLink } from "../../api/cayo-graph";

export const oDataTypes = {
  softLink: "#cayo.graph.softLink",
};

const entityToSoftLink = (entity: Entity): SoftLink => {
  return {
    "@odata.type": oDataTypes.softLink,
    id: entity.id,
    objectType: entity.objectType,
    objectName: entity.objectName,
    objectPath: entity.objectPath,
    linkType: entity.linkType || entity.objectType,
    displayName: (entity as any).displayName,
  } as any;
};

const oDataConverters = {
  entityToSoftLink,
};

export default oDataConverters;
