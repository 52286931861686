import {
  Checkbox,
  ChoiceGroup,
  ComboBox,
  DateTimeBox,
  DropDown,
  ExpandableContainer,
  Html,
  ICheckbox,
  IComboBox,
  IDateTimeBox,
  IDropDown,
  IFormField,
  IHtml,
  IImportFile,
  ILink,
  IMessageBar,
  IMultiCheckbox,
  IMultiValue,
  IObjectDisplay,
  IText,
  ITextField,
  IToggle,
  ImportFile,
  Link,
  LoadingOverlay,
  MessageBar,
  MessageBarTypeExtended,
  MultiCheckbox,
  MultiValue,
  ObjectDisplay,
  Text,
  TextField,
  Toggle,
  stringUtils,
} from "cayo.ui";

import { IChoiceGroup } from "cayo.ui/lib/esm/interfaces/basic/choiceGroup";
import { IUIItem } from "cayo.ui/lib/esm/interfaces/common/IUIItem";
import React, { Fragment } from "react";
import renderForm from ".";
import { AvailabilitySchedule } from "../../../api/cayo-graph";
import {
  IEula,
  IFixedList,
  IInplaceAvailabilitySchedule,
  IObjectLinks,
} from "../../../api/schema.api";

import ObjectLink from "../../Basic/ObjectLink";
import InplaceAvailabilitySchedule from "../../InplaceAvailabilitySchedule";
import TimeLimit from "../../InplaceAvailabilitySchedule/TimeLimit";
import { AuxRenderProps } from "../../LegacyDetailsList/cell-renderers";
import { ODataFixedList } from "../../Lists/ODataFixedList";
import InplaceSchedule from "../../Schedule/InplaceSchedule";
import SoftLinkCollection from "../../SoftLinkCollection";
import { commonMessages } from "../../common-messages";
import ReadonlyODataContainer from "../ReadonlyODataContainer";
import { IAdditionalFormFieldProps, IAdditionalFormProps } from "../types";
import { formUtils } from "../utils";

const text = (annotation: IText, addProps: IAdditionalFormFieldProps): JSX.Element => {
  const { value } = addProps;

  return (
    <Text
      key={annotation.name || annotation.label}
      {...annotation}
      value={value || annotation.value}
      style={{ overflowWrap: annotation.formatter === "binary" ? "anywhere" : undefined }}
    />
  );
};

const textfield = (annotation: ITextField, addProps: IAdditionalFormFieldProps): JSX.Element => {
  const { value, error, disabled, setValue, setError, intl, object } = addProps;
  const type = annotation.isConfidential ? "password" : annotation.type;
  let placeHolder = annotation.placeHolder;

  if (annotation.isConfidential && object) {
    const isPasswordSet = formUtils.isPasswordSet(annotation, object);

    placeHolder = isPasswordSet
      ? intl.formatMessage(commonMessages.passwordIsSet)
      : annotation.placeHolder;
  }

  let realValue: string | number | undefined = "";
  if (value === undefined) {
    realValue = annotation.value;
  } else {
    realValue = value;
  }

  const name = formUtils.getName(annotation);

  return (
    <TextField
      key={name}
      id={annotation.id || name}
      name={name}
      type={type}
      value={realValue}
      errorMessage={disabled ? undefined : error?.errorMessage}
      pattern={annotation.pattern}
      required={annotation.required}
      placeHolder={disabled ? undefined : placeHolder}
      disabled={annotation.disabled || disabled}
      multiline={annotation.multiline}
      autoFocus={annotation.autoFocus}
      nullValueDecorator={annotation.nullValueDecorator}
      tooltip={annotation.tooltip}
      tip={annotation.tip}
      hidden={annotation.hidden}
      suffix={annotation.suffix}
      prefix={annotation.prefix}
      copyEnabled={annotation.copyEnabled}
      hideWhenEmpty={annotation.hideWhenEmpty}
      hideRequiredAsterix={annotation.hideRequiredAsterix}
      isConfidential={annotation.isConfidential}
      autocomplete={annotation.autocomplete}
      setValue={(v: any) => {
        setValue(v, annotation);
        if (error) {
          formUtils.checkError(annotation, v as string, setError, intl);
        }
      }}
      onBlur={() => {
        formUtils.checkError(annotation, value, setError, intl);
      }}
      label={annotation.label}
      formatter={annotation.formatter}
      readOnly={annotation.readOnly}
      note={annotation.note}
    />
  );
};
const choicegroup = (
  annotation: IChoiceGroup,
  addProps: IAdditionalFormFieldProps
): JSX.Element => {
  const { value, disabled, setValue } = addProps;
  const name = formUtils.getName(annotation);

  return (
    <ChoiceGroup
      key={annotation.name}
      id={annotation.id || name}
      value={value}
      tooltip={annotation.tooltip}
      disabled={disabled}
      label={annotation.label}
      setValue={(v) => setValue(v, annotation)}
      readOnly={annotation.readOnly}
      visible={annotation?.visible}
      note={annotation.note}
      tip={annotation.tip}
      options={annotation.options}
    />
  );
};

const checkbox = (annotation: ICheckbox, addProps: IAdditionalFormFieldProps): JSX.Element => {
  const { value, disabled, setValue } = addProps;
  const name = formUtils.getName(annotation);

  return (
    <Checkbox
      key={name}
      id={annotation.id || name}
      value={!!value}
      tooltip={annotation.tooltip}
      disabled={disabled}
      hidden={annotation.hidden}
      label={annotation.label || stringUtils.toSpaceDelimitedPascalCase(annotation.name!)}
      setValue={(v) => setValue(v, annotation)}
      readOnly={annotation.readOnly}
      visible={(annotation as ICheckbox)?.visible}
      note={annotation.note}
      tip={annotation.tip}
    />
  );
};
const toggle = (annotation: IToggle, addProps: IAdditionalFormFieldProps): JSX.Element => {
  const { value, disabled, setValue } = addProps;
  const name = formUtils.getName(annotation);

  return (
    <Toggle
      key={name}
      id={annotation.id || name}
      value={!!value}
      tooltip={annotation.tooltip}
      disabled={disabled}
      label={annotation.label}
      setValue={(v) => setValue(v, annotation)}
      readOnly={annotation.readOnly}
      visible={(annotation as ICheckbox)?.visible}
      note={annotation.note}
      tip={annotation.tip}
      onText={annotation.onText}
      offText={annotation.offText}
    />
  );
};

const dropdown = (annotation: IDropDown, addProps: IAdditionalFormFieldProps): JSX.Element => {
  const { disabled, setValue } = addProps;

  const hasBindings = annotation?.bindings ? !!Object.keys(annotation?.bindings)?.length : false;
  return (
    <DropDown
      key={annotation.name}
      disabled={hasBindings ? disabled : annotation.disabled}
      setValue={(v) => setValue(v, annotation)}
      // onBlur={(e) => setValue(e.currentTarget.value, annotation)}
      label={annotation.label}
      options={(annotation as IDropDown).options!}
      required={annotation.required}
      readOnly={annotation.readOnly}
      value={addProps.value}
      tooltip={annotation.tooltip}
      placeHolder={annotation.placeHolder}
      note={annotation.note}
      tip={annotation.tip}
    />
  );
};

const combobox = (annotation: IComboBox, addProps: IAdditionalFormFieldProps): JSX.Element => {
  const { disabled, setValue } = addProps;

  const hasBindings = annotation?.bindings ? !!Object.keys(annotation?.bindings)?.length : false;
  return (
    <ComboBox
      key={annotation.name}
      disabled={hasBindings ? disabled : annotation.disabled}
      setValue={(v) => setValue(v, annotation)}
      // onBlur={(e) => setValue(e.currentTarget.value, annotation)}
      label={annotation.label}
      options={(annotation as IDropDown).options!}
      required={annotation.required}
      readOnly={annotation.readOnly}
      value={addProps.value}
      tooltip={annotation.tooltip}
      placeHolder={annotation.placeHolder}
      note={annotation.note}
      tip={annotation.tip}
      allowFreeInput={annotation.allowFreeInput}
    />
  );
};

const mvText = (annotation: IMultiValue, addProps: IAdditionalFormFieldProps): JSX.Element => {
  const { value, disabled, setValue, error, setError, intl } = addProps;

  let realValue: string[] | number[] | undefined = [];
  if (value === undefined) {
    realValue = annotation.value;
  } else {
    realValue = value;
  }

  const name = formUtils.getName(annotation);

  const type = annotation!.type?.split(".")[1];
  return (
    <MultiValue
      id={annotation.id}
      key={name}
      name={name}
      type={type}
      value={realValue}
      readOnly={annotation?.readOnly}
      error={error || annotation.error}
      pattern={annotation.pattern}
      required={annotation.required}
      placeHolder={disabled ? undefined : annotation.placeHolder}
      disabled={annotation.disabled || disabled}
      autoFocus={annotation.autoFocus}
      addButtonTitle={annotation.addButtonTitle}
      setValue={(v: any) => {
        setValue(v, annotation);
        if (error) {
          formUtils.checkError(annotation, v, setError, intl);
        }
      }}
      onBlur={() => {
        formUtils.checkError(annotation, value, setError, intl);
      }}
      validateValue={(v: string) => {
        return !formUtils.checkError(annotation, v, setError, intl);
      }}
      label={annotation.label || stringUtils.toSpaceDelimitedPascalCase(annotation.name!)}
      tooltip={annotation.tooltip}
      formatter={annotation.formatter}
      nullValueDecorator={annotation.nullValueDecorator}
    />
  );
};

const mvCheckbox = (
  annotation: IMultiCheckbox,
  addProps: IAdditionalFormFieldProps
): JSX.Element => {
  const { value, disabled, setValue } = addProps;

  return (
    <MultiCheckbox
      key={annotation.name}
      setValue={(v) => {
        setValue(v, annotation);
      }}
      value={value}
      required={annotation.required}
      disabled={annotation.disabled || disabled}
      autoFocus={annotation.autoFocus}
      label={annotation.label || stringUtils.toSpaceDelimitedPascalCase(annotation.name!)}
      tooltip={annotation.tooltip}
      possibleValues={annotation.possibleValues}
      horizontal={annotation.horizontal}
      readOnly={annotation.readOnly}
    />
  );
};

const datetimebox = (
  annotation: IDateTimeBox,
  addProps: IAdditionalFormFieldProps
): JSX.Element => {
  const { value, disabled, setValue, setError } = addProps;

  return (
    <DateTimeBox
      key={annotation.name}
      {...annotation}
      setError={setError}
      setValue={(v: any) => {
        setValue(v, annotation);
      }} // TODO: remove IFormFieldAnnotation
      editMode={annotation.editMode}
      label={annotation.label!}
      value={value === undefined ? annotation.value : value}
      disabled={disabled}
    />
  );
};

const inplaceavailabilityschedule = (
  annotation: IInplaceAvailabilitySchedule,
  addProps: IAdditionalFormFieldProps
): JSX.Element => {
  const { object } = addProps;

  return <InplaceAvailabilitySchedule key={annotation.name} {...annotation} object={object} />;
};

const inplaceschedule = (
  annotation: IFormField,
  addProps: IAdditionalFormFieldProps
): JSX.Element => {
  const { object } = addProps;

  return <InplaceSchedule key={annotation.name} {...annotation} object={object} />;
};

const objectlink = (annotation: IFormField, addProps: IAdditionalFormFieldProps): JSX.Element => {
  const { object } = addProps;

  return <ObjectLink key={annotation.name} {...annotation} object={object} />;
};

const link = (annotation: ILink) => {
  return (
    <Link
      key={annotation.name}
      name={annotation.name!}
      href={annotation.href}
      title={annotation.title}
    />
  );
};

const objectdisplay = (annotation: IObjectDisplay, addProps: IAdditionalFormFieldProps) => {
  return (
    <ObjectDisplay
      name={annotation.name!}
      value={addProps.value || annotation.value}
      format={annotation.format}
      label={annotation.label}
    />
  );
};

const html = (annotation: IHtml, addProps: IAdditionalFormFieldProps): JSX.Element => {
  const { value } = addProps;

  return <Html key={annotation.name} {...annotation} value={annotation.value || value} />;
};

type multiValueComponentTypes = "mv.text" | "mv.checkbox";

const multiValueRenderers: { [values in multiValueComponentTypes]: any } = {
  "mv.checkbox": mvCheckbox,
  "mv.text": mvText,
};

const fixedlist = (annotation: IFixedList, addProps: IAdditionalFormFieldProps) => {
  return <ODataFixedList key={annotation.name} {...annotation} addProps={addProps} />;
};

const objectlinks = (annotation: IObjectLinks, addProps: IAdditionalFormFieldProps) => {
  return (
    <SoftLinkCollection key={annotation.name} {...annotation} addProps={addProps} style={{}} />
  );
};

const propertygroup = (
  annotation: ITextField & { items?: IUIItem[] },
  addProps: IAdditionalFormFieldProps,
  addFormProps?: IAdditionalFormProps
) => {
  const { items, name } = annotation;

  const isEmptyOdataObject = !items?.length && !addProps.value;
  if (isEmptyOdataObject) {
    return <Fragment />;
  }

  const isComplex = formUtils.isComplexItem(annotation);
  return (
    <ExpandableContainer
      label={annotation.label!}
      tooltip={annotation.tooltip}
      border={isComplex ? "dashed" : undefined}
      style={!isComplex ? { margin: "16px 0 0 0" } : undefined}
      key={"group_" + name}
    >
      {!!items?.length && <Fragment>{renderForm(annotation, addFormProps!)}</Fragment>}
      {!items?.length && <ReadonlyODataContainer object={addProps.value} />}
    </ExpandableContainer>
  );
};

// use lazy loading otherwise the tests fail
const AgreementPage = React.lazy(async () => {
  const module = await import("cayo.ui.wizards");
  return { default: module.AgreementPage };
});

const eula = (annotation: IEula, addProps: IAdditionalFormFieldProps): JSX.Element => {
  const { value, setValue } = addProps;

  return (
    <React.Suspense fallback={<LoadingOverlay />}>
      <AgreementPage accepted={value} setAccepted={(v) => setValue(v, annotation)} framed />
    </React.Suspense>
  );
};

const messagebar = (annotation: IMessageBar): JSX.Element => {
  const { messageBarType, ...other } = annotation;

  const officeMessageBarType: MessageBarTypeExtended = messageBarType || "info";
  return <MessageBar key={annotation.name} {...other} messageBarType={officeMessageBarType} />;
};

const importfile = (annotation: IImportFile, addProps: IAdditionalFormFieldProps): JSX.Element => {
  const { value, setValue } = addProps;

  return (
    <ImportFile
      key={annotation.name}
      value={value}
      fileExt={annotation.fileExt}
      setValue={(v: any) => {
        setValue(v, annotation);
      }}
      label={annotation.label}
      formatter={annotation.formatter}
    />
  );
};

const timelimit = (
  annotation: ITextField,
  value: any,
  // error: string | undefined,
  // disabled: boolean | undefined,
  //setValue: (value: any, annotation: IFormFieldAnnotation) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setError: (error: string | undefined) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auxProps: AuxRenderProps
): JSX.Element => {
  const props = annotation as IInplaceAvailabilitySchedule;
  const { value: fieldValue, setValue } = value;

  return (
    <TimeLimit
      name={annotation.name!}
      value={fieldValue as unknown as AvailabilitySchedule[]}
      setValue={setValue}
      serverTimeZone={props.serverTimeZone}
    />
  );
};

const formRenderers = {
  textfield,
  text,
  number: textfield,
  objectdisplay,
  checkbox,
  toggle,
  dropdown,
  combobox,
  datetimebox,
  inplaceschedule,
  inplaceavailabilityschedule,
  objectlink,
  link,
  html,
  ...multiValueRenderers,
  fixedlist,
  objectlinks,
  propertygroup,
  eula,
  choicegroup,
  importfile,
  messagebar,
  timelimit,
};

export default formRenderers;

export type formComponentTypes = keyof typeof formRenderers;
