import { IButtonProps, Link } from "@fluentui/react";
import { Label } from "cayo.ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { IFormField } from "../../../api/schema.api";
import SmallSpinner from "../../Utilities/SmallSpinner";
import { ObjectLinkContainer } from "./styles";

const UnresolvedObjectLink: FC<IFormField & { isLoading?: boolean; commands?: IButtonProps[] }> = ({
  isLoading,
  tooltip,
  label,
  commands,
}) => {
  return (
    <ObjectLinkContainer>
      <Label label={label} tooltip={tooltip} commands={commands} />
      {isLoading ? (
        <SmallSpinner />
      ) : (
        <Link
          disabled={true}
          styles={{
            root: {
              display: "block",
              alignItems: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            },
          }}
        >
          <FormattedMessage id="navlink-notset" defaultMessage="[Not set]" />
        </Link>
      )}
    </ObjectLinkContainer>
  );
};

export default UnresolvedObjectLink;
