import { ColumnActionsMode, IColumn } from "@fluentui/react";
import { useMemo, useState } from "react";
import { appSettings } from "../../../settings/app-settings";
import { StoredColumn } from "../../../settings/stored-column";
import { gridUtils } from "../../../utils/grid-utils";
import { urlUtils } from "../../../utils/url-utils";

function resolveInitialSortFieldName(columns: IColumn[]) {
  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    if (column.columnActionsMode !== ColumnActionsMode.disabled) {
      return column.fieldName!;
    }
  }
  return "name";
}

export const useStatefulGrid = (controlId: string, initialColumns: IColumn[]) => {
  const gridViewKey = urlUtils.trim(window.location.hash + controlId);

  const storedColumns = useMemo(() => {
    const columns =
      gridUtils.getStoredColumns(gridViewKey) ||
      initialColumns.map<StoredColumn>((c) => ({
        fieldName: c.fieldName,
        isSorted: c.isSorted,
        isSortedDescending: c.isSortedDescending,
      }));

    return columns;
  }, [controlId]);

  const [sortBy, setSortBy] = useState<{ fieldName: string; desc?: boolean }>({
    fieldName:
      storedColumns?.find((c) => c.isSorted)?.fieldName ||
      resolveInitialSortFieldName(initialColumns),
    desc: storedColumns?.find((c) => c.isSorted)?.isSortedDescending,
  });

  const columns = useMemo(() => {
    initialColumns.forEach((c) => {
      delete c.isSorted;
      delete c.isSortedDescending;
    });

    const sortedColumn = initialColumns.find((c) => c.fieldName === sortBy.fieldName);
    if (sortedColumn) {
      sortedColumn.isSorted = true;
      sortedColumn.isSortedDescending = sortBy.desc;
    }

    gridUtils.saveGridColumns(gridViewKey, initialColumns);

    return [...initialColumns];
  }, [sortBy]);

  return {
    sortBy,
    setSortBy,
    columns,
    clear: () => appSettings.viewSetting.deleteSubKey(gridViewKey),
  };
};
