import logger from "../libs/logger";

const lazyProps: { path?: string | undefined; actionUrl?: string | undefined } = {};

const log = logger.getLogger("lazyProps");
export const getLazyProps = (path: string) => {
  if (lazyProps.path === path) {
    lazyProps.path = undefined;
    const actionUrl = lazyProps.actionUrl;
    log.debug(`get ${actionUrl}`);
    lazyProps.actionUrl = undefined;
    return actionUrl;
  }

  return undefined;
};

export const showLazyProps = (path: string, actionUrl: string) => {
  log.debug(`show ${path}, ${actionUrl}`);
  lazyProps.path = path;
  lazyProps.actionUrl = actionUrl;
};
