import { IDetailsListStyles } from "@fluentui/react";

export const getDetailsListSyles = (showBorder?: boolean): Partial<IDetailsListStyles> => {
  return {
    root: {
      paddingTop: "0 !important",
      border: showBorder ? "1px dashed #A8A8A8" : undefined,
      marginBottom: showBorder ? 12 : undefined,
      overflowY: showBorder ? "hidden" : undefined,
      selectors: {
        ".ms-DetailsHeader-cell": {
          selectors: {
            ":hover": {
              border: "1px solid rgba(0, 0, 0, 0.2)",
            },
          },
        },
        ".ms-DetailsHeader-cellSizer": {
          selectors: {
            ":after": { width: 3 },
          },
        },
        ".ms-DetailsHeader-filterChevron": {
          display: "none",
        },
        ".ms-DetailsRow-fields": {
          display: "block",
          selectors: {
            ".ms-DetailsRow-cell": {
              paddingTop: "12px !important",
              paddingLeft: 8,
              paddingRight: 8,
              height: "100%",
            },
          },
        },
      },
    },

    contentWrapper: {
      selectors: {
        ".ms-DetailsRow": {
          width: "100%",
        },
        ".ms-DetailsRow .cs-ShowOnHover": {
          visibility: "hidden",
        },
        ".ms-DetailsRow:hover .cs-ShowOnHover": {
          visibility: "visible",
        },
      },
    },

    headerWrapper: {
      fontSize: "8px",
      selectors: {
        ".ms-DetailsHeader": {
          paddingTop: 0,
          width: "100%",
        },
        ".ms-DetailsHeader-cellName": {
          fontSize: 12,
        },
      },
    },
  };
};
