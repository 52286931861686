import { LoadingOverlay } from "cayo.ui";
import React, { FC, Fragment, useEffect } from "react";
import { GridContainer } from "../../GridContainer/GridContainer";
import { EmptyContainer } from "../../Widgets/common";
import GenericScheme from "../GenericScheme";
import useOdataScheme, { useGridSettingsKey } from "./hook";

interface ODataSchemeProps {
  schemePath: string;
  restorePropPanel?: boolean;
}

const ODataScheme: FC<ODataSchemeProps> = ({ schemePath, restorePropPanel }) => {
  const { isLoading, propertyAnnotations, scheme } = useOdataScheme(schemePath);
  const gridSettingsKey = useGridSettingsKey(scheme?.detailsList?.id);
  // const { pathname } = useLocation();
  // const log = useComponentLogger(ODataScheme);
  // const { showPropertiesPanel } = useSchemes();

  useEffect(() => {
    if (!restorePropPanel || isLoading || !scheme?.detailsList?.id) {
      return;
    }

    // const objectPath = LastViewStateStorage.getLastOpenedObject(pathname);
    // if (objectPath) {
    //   log.debug("Unsaved props detected", objectPath);
    //   showPropertiesPanel({ id: objectPath, parentId: scheme?.detailsList?.id });
    // }
  }, [isLoading]);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (!scheme?.detailsList) {
    if (scheme?.items?.length) {
      return (
        <EmptyContainer style={{ fontSize: 14, maxWidth: "80%" }}>
          <GenericScheme {...scheme} />
        </EmptyContainer>
      );
    }
    return <Fragment />;
  }

  return (
    <GridContainer
      scheme={scheme!}
      propertyAnnotations={propertyAnnotations}
      gridSettingsKey={gridSettingsKey}
    />
  );
};

export default ODataScheme;
