import { DefaultButton, DialogFooter, PrimaryButton } from "@fluentui/react";
import { ErrorRecord } from "cayo.ui";
import React, { CSSProperties, FC } from "react";
import { useIntl } from "react-intl";
import { IFormFooter } from "../../api/schema.api";
import { commonMessages } from "../common-messages";

type FormFooterProps = {
  footer?: IFormFooter | undefined;
  isOKDisabled?: boolean;
  errors: { [id: string]: ErrorRecord };
  saveError?: string;
  onSubmit?: () => Promise<void> | undefined;
  onClose?: () => void;
  style?: CSSProperties;
};

const FormFooter: FC<FormFooterProps> = ({
  isOKDisabled,
  errors,
  saveError,
  onSubmit,
  onClose,
  footer,
  style,
}) => {
  const intl = useIntl();

  return (
    <DialogFooter
      styles={{ actions: { flexWrap: "nowrap", padding: 0, ...((style as any) || {}) } }}
    >
      <PrimaryButton
        onClick={onSubmit}
        text={
          saveError
            ? intl.formatMessage(commonMessages.retry)
            : footer?.okText || intl.formatMessage(commonMessages.yes)
        }
        disabled={Object.keys(errors).length > 0 || isOKDisabled}
      />
      {onClose && (
        <DefaultButton
          onClick={onClose}
          text={footer?.cancelText || intl.formatMessage(commonMessages.no)}
        />
      )}
    </DialogFooter>
  );
};

export default FormFooter;
