import { Stack } from "@fluentui/react";
import { stringUtils, useComponentLogger, useTheme } from "cayo.ui";
import React, { CSSProperties, FC, Fragment, useMemo } from "react";
import { Entity, PropertyAnnotation } from "../../api/cayo-graph";
import { IScheme } from "../../api/schema.api";
import regexes from "../../scheme/bindings/regexes";
import useService from "../../services/services.hook";
import { ISignalServiceBase } from "../../services/signal-service-base";
import { allRendereres, rendererNames } from "./renderers";
import { ILayoutHierarchy, layoutRenderers } from "./renderers/renderers.layout";

type IGenericSchemeProps = IScheme & {
  data?: any;
  signals?: ISignalServiceBase<any, any>;
  refreshKey?: string;
  onRefresh?: () => void;
  propertyAnnotations?: PropertyAnnotation[];
};

const GenericScheme: FC<IGenericSchemeProps & { object?: Entity; style?: CSSProperties }> = (
  props
) => {
  const {
    items,
    layout,
    data,
    type,
    childrenGap,
    signals,
    userRole,
    style,
    onRefresh,
    name,
    propertyAnnotations,
  } = props;

  const log = useComponentLogger(GenericScheme, name!);
  const actionBuilder = useService("actionBuilder");
  const theme = useTheme();

  const auxRenderParams = useMemo(
    () => ({
      actionBuilder,
      theme,
      signals,
      refreshKey: props.refreshKey,
      userRole,
      parentName: props?.name,
      propertyAnnotations,
    }),
    [props?.refreshKey, userRole, props?.name, signals, propertyAnnotations]
  );

  const result = useMemo(() => {
    if (!items?.length) {
      return [];
    }

    let result = items.map<ILayoutHierarchy>((c, i) => ({
      render: () => {
        const renderer = allRendereres[stringUtils.dashedToLowCase(c.type!) as rendererNames];

        // TODO: optimize. move this object to a cache
        const cProps = c as any;
        cProps.key = (c.name || c?.type) + (data?.id || "");
        cProps.data = data;
        cProps.object = props?.object;
        cProps.signals = signals;
        cProps.onRefresh = onRefresh;
        cProps.propertyAnnotations = props?.propertyAnnotations;

        if (userRole) {
          cProps.userRole = userRole;
        }

        return renderer && <Fragment key={i}>{renderer(c, auxRenderParams)}</Fragment>;
      },
      name: c.name,
      index: i,
    }));

    layout?.forEach((l) => {
      const intersection = items?.filter((f) => l.items?.includes(f.name!));
      if (!intersection || !intersection.length) {
        throw new Error("Invalid schema");
      }

      log.debug("layout " + l.type + " will contain ", intersection);

      const index = items!.findIndex((f) => f.name! === intersection[0].name);
      const excludedIds = intersection.map((i) => i.name);
      const resultIndex = result.findIndex((r) => r.index === index);

      let children = result?.filter((f) => excludedIds.includes(f.name)); //.map((r) => r.render);

      if (children && children.length > 0 && children.length !== l.items?.length) {
        children = l.items!.map<ILayoutHierarchy>((i, ii) => {
          let item = children!.find((c) => c.name === i) as ILayoutHierarchy;
          if (item) {
            item.index = ii;
          } else {
            const spacerProps = { key: "spacer" + ii };

            let wellknownLayoutType = i === "-" ? "spacer" : "";

            if (!wellknownLayoutType) {
              const match = i.match(regexes.pixels);
              if (match) {
                wellknownLayoutType = "pixels";
                (spacerProps as any).width = match[0];
              } else {
                wellknownLayoutType = stringUtils.dashedToLowCase(i.toLowerCase());
              }
            }

            item = {
              render: () => {
                const r = allRendereres[wellknownLayoutType];
                if (!r) {
                  log.error(`renderer ${wellknownLayoutType} not found`);
                }
                return r && r(spacerProps);
              },
              index: ii,
              name: wellknownLayoutType + ii,
            };
          }
          return item;
        });
      }

      const lConfig: ILayoutHierarchy = {
        render: () => layoutRenderers[l.type!](l, children),
        index,
      };

      result[resultIndex] = lConfig;

      result = result.filter((f) => !excludedIds.includes(f.name));
    });

    return result;
  }, [items, layout]);

  const stackProps = useMemo(() => {
    return { tokens: { childrenGap: childrenGap } };
  }, [childrenGap]);

  const stackStyle = useMemo(
    () => ({
      maxHeight: "100%",
      width: "100%",
      height: "100%",
      ...(style || {}),
    }),
    [style]
  );

  if (!items?.length) {
    const renderer = type && allRendereres[stringUtils.dashedToLowCase(type)];
    return renderer ? renderer(props) : null;
  }

  return (
    <Stack style={stackStyle} horizontal={false} tokens={stackProps.tokens}>
      {result?.map((r) => r.render())}
    </Stack>
  );
};

export default GenericScheme;
