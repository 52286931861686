import { builtinFormatters, useTheme } from "cayo.ui";
import React, { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Row } from "../../CommonLayout";
import { IAlertItem } from "../alerts.api";
import {
  AlertFooter,
  AlertItemContainer,
  DateText,
  DetailsText,
  DismissIconButton,
  SeverityIcon,
  SmallLink,
  StyledColumn,
  Wrapper,
} from "./styles";

const AlertItem: FC<
  IAlertItem & {
    onDismiss: ((item: IAlertItem) => Promise<void>) | null;
    showDetails: () => void;
    index: number;
  }
> = (props) => {
  const intl = useIntl();
  const { getColor, getIcon } = useTheme();
  //const isError = false;
  const relativeDate = builtinFormatters.relativeTime(props.lastModifiedDateTime, intl);
  const isRisk = props?.objectType === "cayo.graph.riskAlert";

  const [isHovered, setIsHovered] = useState(false);
  const [disappeared, setIsDisappeared] = useState<boolean>(false);

  return (
    <AlertItemContainer
      disappeared={disappeared}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      highlighted={props.highlighted}
      isFirst={props.index === 0}
    >
      <Wrapper>
        <SeverityIcon
          styles={{ root: { color: getColor(props.severity, "severity") } }}
          iconName={getIcon(props.severity, "severity")}
        />

        <StyledColumn>
          <Row onClick={props.showDetails}>
            <DetailsText
              variant="medium"
              block={true}
              nowrap={true}
              styles={{
                root: { textOverflow: "ellipsis", whiteSpace: "break-spaces" },
              }}
              isHovered={isHovered}
            >
              {props.subject}
            </DetailsText>

            {!isRisk && props.onDismiss && (
              <DismissIconButton
                styles={{ icon: { fontSize: "10px" } }}
                iconProps={{ iconName: "Clear" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDisappeared(true);
                  setTimeout(() => {
                    props.onDismiss!(props);
                  }, 500);
                }}
                isHovered={isHovered}
              />
            )}
          </Row>
        </StyledColumn>
      </Wrapper>
      <AlertFooter>
        <SmallLink onClick={props.showDetails}>
          <FormattedMessage
            id="alert-item.click-here-for-detials"
            defaultMessage="Click for details"
          />
        </SmallLink>
        {relativeDate && (
          <DateText variant="small" block={true} nowrap={true}>
            {relativeDate}
          </DateText>
        )}
      </AlertFooter>
    </AlertItemContainer>
  );
};

export default AlertItem;
