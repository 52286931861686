import { Stack, Text } from "@fluentui/react";
import { ITheme, useComponentLogger } from "cayo.ui";
import React, { FC, useCallback, useEffect, useMemo, useReducer } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { AvailabilitySchedule } from "../../api/cayo-graph";
import { IFormField } from "../../api/schema.api";
import calendarUtils from "../../utils/calendar-utils";
import EditModeSwitch from "./EditModeSwitch";
import messages from "./messages";
import timeLimitReducer from "./reducer";
import { EditModes } from "./types";
import timeWindowsUtils from "./utils";

type TimeLimitProps = {
  name: string;
  value?: AvailabilitySchedule[];
  setValue: (value: any, annotation: Omit<IFormField, "type">) => void;
  serverTimeZone: string;
};

const TimeLimit: FC<TimeLimitProps> = (props) => {
  const { setValue, value, name, serverTimeZone } = props;
  const log = useComponentLogger(TimeLimit);
  const intl = useIntl();

  const weekDays = useMemo(
    () => calendarUtils.getWeekdays(navigator.language),
    [navigator.language]
  );

  const [timeLimitState, dispatch] = useReducer(timeLimitReducer, {
    timeWindows: timeWindowsUtils.initTimeWindows(weekDays, value),
  });

  const cancelEditingMode = useCallback(() => {
    if (!timeLimitState.beginHour) {
      return;
    }
    dispatch({ kind: "cancelSelection" });
    log.debug("cancelSelection");

    const schedule = timeWindowsUtils.convertToAvailabilitySchedule(timeLimitState.timeWindows);
    setValue(schedule, { name });
  }, [timeLimitState.timeWindows, timeLimitState.beginHour]);

  useEffect(() => {
    document.addEventListener("mouseup", cancelEditingMode, true);

    return () => {
      document.removeEventListener("mouseup", cancelEditingMode, true);
    };
  }, [cancelEditingMode]);

  return (
    <Stack horizontal={false} horizontalAlign={"start"}>
      <TimeWindowTable>
        <tr>
          <td colSpan={25} height="60px" valign="top">
            <Text style={{ margin: "0 0 0 0px" }} variant={"medium"}>
              {intl.formatMessage(messages.serverTimeZone, { serverTimeZone })}
            </Text>
          </td>
        </tr>
        <tr>
          <td></td>
          {timeLimitState.timeWindows[0].timeLimit.map((l, i) => (
            <Tick key={i}>
              {i === 0 && <TimeInfo>{intl.formatMessage(messages.midnight)}</TimeInfo>}
              {i === 12 && <TimeInfo>{intl.formatMessage(messages.noon)}</TimeInfo>}
              <Label>{(i === 0 || i === 12 ? 12 : i % 12).toString()}</Label>
              {i === 23 && <LastLabel>12</LastLabel>}
            </Tick>
          ))}
        </tr>

        {timeLimitState.timeWindows.map((w, wi) => (
          <WeekRow key={w.weekDay}>
            <WeekTitle>{w.weekDay}</WeekTitle>
            {w.timeLimit.map((l, i) => (
              <Cell
                isSelected={!!l}
                mode={timeLimitState.editMode ?? "allowed"}
                key={i}
                onMouseMove={() =>
                  timeLimitState.beginHour &&
                  dispatch({ kind: "moveSelection", selection: { week: wi, hour: i } })
                }
                onMouseDown={() => {
                  dispatch({ kind: "beginSelection", selection: { week: wi, hour: i } });
                }}
              />
            ))}
          </WeekRow>
        ))}
        <tr>
          <td colSpan={25}>
            <EditModeSwitch
              mode={timeLimitState.editMode ?? "allowed"}
              setMode={(mode) => dispatch({ kind: "setEditMode", mode })}
            />
          </td>
        </tr>
      </TimeWindowTable>
    </Stack>
  );
};

const TimeWindowTable = styled.table`
  font-size: 12px;
  border-collapse: collapse;
  margin-top: 0px;
  user-select: none;
`;

const WeekRow = styled.tr`
  padding: 4px;
`;
const WeekTitle = styled.td`
  padding: 4px 8px;
`;

const Cell = styled.td<{ isSelected?: boolean; mode: EditModes }>`
  border: 1px solid #cacaca;
  width: 20px;
  height: 20px;
  position: relative;
  background-color: ${(props) =>
    props.isSelected && (props.theme as ITheme).cayoTheme.brandColors.primaryHighlight};
  &:hover {
    background-color: ${(props) =>
      props.mode === "allowed"
        ? (props.theme as ITheme).cayoTheme.brandColors.primaryHighlight
        : (props.theme as ITheme).cayoTheme.brandColors.divider};
  }
`;

const Tick = styled.td`
  border-left: 1px solid ${(props) => (props.theme as ITheme).cayoTheme.brandColors.divider};
  border-right: 1px solid ${(props) => (props.theme as ITheme).cayoTheme.brandColors.divider};
  height: 4px;
  width: 20px;
  position: relative;
  user-select: none;
`;

const Label = styled.div`
  position: absolute;
  font-size: 9px;
  top: -14px;
  left: -11px;
  width: 20px;
  text-align: center;
`;

const TimeInfo = styled.div`
  position: absolute;
  font-size: 10px;
  top: -28px;
  left: -5px;
  width: auto;
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
  color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.disabled};
`;

const LastLabel = styled.div`
  position: absolute;
  font-size: 9px;
  top: -14px;
  right: -11px;
  width: 20px;
  text-align: center;
`;

export default TimeLimit;
