import StorageSpace from "../../Cards/StorageSpace";
import RiskIndicator from "../../Graphics/RiskIndicator";

const formatterControls = {
  storagespace: StorageSpace,
  riskindicator: RiskIndicator,
};

export type formatterNames = keyof typeof formatterControls;

export default formatterControls;
