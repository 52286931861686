import React from "react";
import { singletonHook } from "react-singleton-hook";
import { appSettings } from "../../settings/app-settings";
import { useUserSettings } from "./userSettingsHook";

interface ITeachingBubbles {
  teachingStep?: number | undefined;
  showNextTeachingBubble: () => void;
  showPrevTeachingBubble: () => void;
  hideTeachingBubble: () => void;
}

const initialTeachingStep = (() => {
  let teachingStep = (appSettings.userSetting.get() || {})?.teachingStep;
  if (teachingStep === undefined) {
    teachingStep = 0;
    appSettings.userSetting.update({ teachingStep: 0 });
  }

  return teachingStep;
})();

const _useTeachingBubbles = (): ITeachingBubbles => {
  const [teachingStep, setTeachingStep] = React.useState(initialTeachingStep);
  const { userSettings, setUserSettings } = useUserSettings();

  const showNextTeachingBubble = React.useCallback((): void => {
    const settings = appSettings.userSetting.get();
    if (typeof settings?.teachingStep !== "number") {
      return;
    }

    const newTeachingStep = settings.teachingStep + 1;
    setTeachingStep(newTeachingStep);
    setUserSettings({ teachingStep: newTeachingStep });
  }, [userSettings?.teachingStep]);

  const showPrevTeachingBubble = React.useCallback((): void => {
    const settings = appSettings.userSetting.get();
    if (typeof settings?.teachingStep !== "number") {
      return;
    }

    const newTeachingStep = settings.teachingStep - 1;
    setTeachingStep(newTeachingStep);
    setUserSettings({ teachingStep: newTeachingStep });
  }, [userSettings?.teachingStep]);

  const hideTeachingBubble = React.useCallback((): void => {
    const settings = appSettings.userSetting.get();
    if (typeof settings?.teachingStep !== "number") {
      return;
    }

    setTeachingStep(-1);
    setUserSettings({ teachingStep: -1 });
  }, [userSettings?.teachingStep]);

  return {
    teachingStep,
    showNextTeachingBubble,
    showPrevTeachingBubble,
    hideTeachingBubble,
  };
};

export const useTeachingBubbles = singletonHook(
  {
    teachingStep: initialTeachingStep,
    showNextTeachingBubble: () => null,
    showPrevTeachingBubble: () => null,
    hideTeachingBubble: () => null,
  },
  _useTeachingBubbles
);
