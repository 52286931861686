import { Spinner, SpinnerSize } from "@fluentui/react";
import React, { memo } from "react";
import { useIntl } from "react-intl";
import { commonMessages } from "../common-messages";

const SmallSpinner = () => {
  const intl = useIntl();
  return (
    <Spinner
      size={SpinnerSize.small}
      label={intl.formatMessage(commonMessages.loading)}
      labelPosition="right"
      styles={{ label: { fontSize: 13, fontWeight: "normal" } }}
    />
  );
};

export default memo(SmallSpinner);
