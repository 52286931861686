import { IconButton, IIconProps } from "@fluentui/react";
import React, {
  CSSProperties,
  FC,
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

export const getHtmlElememt = (r: RefObject<HTMLElement>) =>
  r?.current ? (r.current as HTMLElement) : null;

export const useChevron = (
  textAreaRef: RefObject<HTMLElement>,
  minTextAreaRef: RefObject<HTMLElement>
) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isChevronVisible, setIsChevronVisible] = useState<boolean>(true);

  const calcChevronVisibility = useCallback(() => {
    const htmlElement = getHtmlElememt(textAreaRef);
    if (htmlElement) {
      const isTextClipped = htmlElement.scrollWidth > htmlElement.offsetWidth;
      const minHtmlElement = getHtmlElememt(minTextAreaRef);
      const isSingleLine = htmlElement.clientHeight === minHtmlElement?.clientHeight;

      setIsChevronVisible(isTextClipped || !isSingleLine);
    }
  }, [textAreaRef?.current, minTextAreaRef?.current]);

  useLayoutEffect(() => {
    calcChevronVisibility();
  });

  const textAreaStyle = useMemo<CSSProperties>(() => {
    const htmlElement = textAreaRef?.current ? (textAreaRef.current as HTMLElement) : null;
    if (htmlElement && isExpanded) {
      return {};
    }

    return { whiteSpace: "nowrap", overflow: "hidden" };
  }, [isExpanded]);

  return {
    onChevronClick: () => setIsExpanded(!isExpanded),
    isChevronVisible,
    textAreaRef,
    isExpanded,
    textAreaStyle,
  };
};

const ChevronButton: FC<{
  isExpanded: boolean;
  isChevronVisible: boolean;
  onChevronClick: () => void;
  chevronRef?: RefObject<HTMLElement>;
}> = ({ isChevronVisible, onChevronClick, isExpanded }) => {
  const iconProps = useMemo<IIconProps>(() => {
    return { iconName: isExpanded ? "DoubleChevronUp" : "DoubleChevronDown" };
  }, [isExpanded]);

  return (
    <IconButton
      iconProps={iconProps}
      onClick={onChevronClick}
      styles={{ root: { visibility: isChevronVisible ? "visible" : "collapse" } }}
    />
  );
};

export default ChevronButton;
