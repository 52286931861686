import { debounce, logger, stringUtils } from "cayo.ui";
import { appSettings } from "../settings/app-settings";
import { deleteCookie, setCookie } from "./cookie";

const log = logger.getLogger("download-utils");

export const matchFileNameRegex = /filename=([^;]+)/;

const downloadStream = async (response: Blob | Response, headers: any) => {
  const blob = response instanceof Blob ? response : await response.blob();

  const contentDisposition = headers["content-disposition"] as string;
  let fileName: string = "response.txt";
  if (contentDisposition) {
    const match = contentDisposition.match(matchFileNameRegex);
    if (match) {
      fileName = stringUtils.trim(match[1], '"');
    }
  }

  // Create an <a> element to simulate the download
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName; // Set the filename

  // Simulate a click on the link to trigger the download
  link.click();

  URL.revokeObjectURL(link.href);

  //document.body.removeChild(link);
  log.debug(`blod downloaded, fileName: ${fileName}`);
};

const downloadODataFile = (href: string) => {
  const token = appSettings.accessToken;
  if (token) {
    setCookie("token", token, 2.31481e-5);
  }

  let a = document.createElement("a");
  a.setAttribute("download", "");
  a.href = href;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  debounce(() => deleteCookie("token"), 2000)();
};

const downloadODataFiles = (hrefs: string[]) => {
  setTimeout(() => {
    const token = appSettings.accessToken;
    if (token) setCookie("token", token, 2.31481e-5);

    let a = document.createElement("a");
    a.setAttribute("download", "");
    a.style.display = "none";

    document.body.appendChild(a);

    hrefs.forEach((href) => {
      a.setAttribute("href", href);
      a.click();
    });

    document.body.removeChild(a);

    debounce(() => deleteCookie("token"), 2000)();
  }, 0); // TODO: discuss with Harut
};

const downloadUtils = {
  downloadODataFile,
  downloadODataFiles,
  downloadStream,
};

export default downloadUtils;
