import logger from "../../../libs/logger";

const log = logger.getLogger("model-utils");

const changesPrefix = "__changes";
const getChangedPath = (property: string) => `${changesPrefix}.${property}`;

export const deleteModelProperty = (property: string, target: any) => {
  property = getChangedPath(property);

  if (!target) {
    log.debug("deleteModelProperty: target is empty");
    return;
  }

  if (!property) {
    log.debug("deleteModelProperty: property is empty");
    return;
  }

  if (property.indexOf(".") === -1) {
    delete target[property];
  } else {
    const parts = property.split(".");
    var changes = target[parts[0]];
    if (changes) {
      delete changes[parts[1]];
    }
  }
};

export const setModelProperty = (property: string, value: any, target: any) => {
  property = getChangedPath(property);

  if (property.indexOf(".") === -1) {
    target[property] = value;
  } else {
    const parts = property.split(".");
    let current = target;
    const cProp = parts[parts.length - 1];
    const isLastArray = !isNaN(parseInt(cProp, 10));
    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];
      if (!current[part]) {
        const isArray = !isNaN(parseInt(parts[i + 1], 10));
        current[part] = isArray ? [] : {};
      }

      current = current[part];
    }

    if (isLastArray) {
      if (value !== undefined) {
        current.push(value);
      }
    } else {
      current[cProp] = value;
    }
  }
};

export const isModelChanged = (model: any) => {
  if (!model) {
    return undefined;
  }

  const changes = model[changesPrefix];
  return changes ? !!Object.keys(changes)?.length : undefined;
};

export const isPropChanged = (model: any, prop: string) => {
  if (!model) {
    return undefined;
  }

  const changes = model[changesPrefix];
  return changes ? !!Object.keys(changes)?.find((k) => k === prop) : undefined;
};

const createProps = (model: any, propPath: string, propValue: any) => {
  const paths = propPath.split(".");
  let newModel = model;
  for (let i = 0; i < paths.length - 1; i++) {
    const path = paths[i];

    if (!newModel[path]) {
      newModel[path] = {};
    }

    newModel = newModel[path];
  }

  newModel[paths[paths.length - 1]] = propValue;
};

export const modelUtils = {
  createProps,
};
