import { Entity, Severity } from "../../api/cayo-graph";
import { ActionParameters } from "../../api/schema.api";
import { SignalServiceBase } from "../../services/signal-service-base";
import { nameofFactory } from "../../utils/object-utils";
import { urlUtils } from "../../utils/url-utils";
import { IConfirmationModal } from "../ConfirmationModal";
import { FormProps } from "../Form/types";
import { appEvents } from "./app-events";

const showError = (err: string | undefined, severity?: Severity | undefined) => {
  // appEvents.trigger({ showError: err });

  showMessage(err ?? "", severity ?? "error");
};

const showMessage = (msg: string, severity?: Severity | undefined) => {
  appEvents.trigger({ showMessage: { text: msg, severity: severity || "informational" } });
};

const showConfirmation = (parameters: IConfirmationModal) => {
  appEvents.trigger({
    showControl: {
      controlId: "ConfirmationModal",
      parameters,
    },
  });
};

const isStaticPage = () => {
  const hash = urlUtils.trim(window.location.hash).toLowerCase();

  return hash.endsWith("$value") || hash.endsWith("download");
};

export type showActionParams = {
  schemeId?: string;
  form?: FormProps;
  objects?: Entity[];
  independent?: boolean;
  onSaveComplete?: () => Promise<void>;
  reloadAfterClose?: boolean;
  package?: string;
  parameters?: any;
  actionParameters?: ActionParameters;
};

export const showActionParamsNames = nameofFactory<showActionParams>();

export const appSignals = new SignalServiceBase<
  "showAction" | "showWizardScheme" | "showClientWizard" | "showForm" | "showSavedQueryForm",
  showActionParams
>();

export const appUtils = {
  showError,
  showMessage,
  showConfirmation,
  isStaticPage,
};
