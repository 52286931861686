import { IButtonStyles } from "@fluentui/react";
import { ITheme, themeUtils } from "cayo.ui";

const getNavHeaderStyles = (theme: ITheme, isSelected: boolean = false) => {
  const hoverBackColor = themeUtils.lightenDarkenColor(
    theme.cayoTheme.brandColors.thirdBackground,
    -20
  );

  const selectedBackColor = theme.cayoTheme.brandColors.thirdBackground;
  const backColor = theme.cayoTheme.brandColors.primaryBackground;

  const style: IButtonStyles = {
    icon: {
      color: isSelected ? selectedBackColor : backColor,
    },
    label: {
      display: "none",
    },

    flexContainer: {
      justifyContent: "center",
    },

    root: {
      backgroundColor: isSelected ? backColor : "transparent",
      borderRadius: 0,
    },
    rootHovered: {
      background: isSelected ? backColor : hoverBackColor,
    },
    iconHovered: {
      color: isSelected ? selectedBackColor : backColor,
    },
    iconPressed: {
      color: isSelected ? backColor : hoverBackColor,
    },
  };

  return style;
};

const menuButtonStyles = {
  getNavHeaderStyles,
};

export default menuButtonStyles;
