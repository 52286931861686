const commandBarButtonIds = {
  newFilter: "new_filter",
  editFilter: "edit_filter",
  clearFilter: "clear_filter",
  propertiesFilter: "properties_filter",
  deleteFilter: "delete_filter",
  saveFilter: "save_filter",
  saveAsFilter: "saveas_filter",
};

export default commandBarButtonIds;
