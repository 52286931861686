import { MessageBarType, PrimaryButton } from "@fluentui/react";
import { useTheme } from "cayo.ui";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import messages from "../../Configuration/InitialConfigurationWizard/messages";
import { getMessageBarStyles } from "../Alerts.styles";
import { IAlertItem } from "../alerts.api";
import { StyledMessageBar } from "./styles";

const InitialConfigAlert: FC<IAlertItem> = (props) => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <StyledMessageBar
      isMultiline={false}
      styles={getMessageBarStyles(false, MessageBarType.warning, theme)}
      messageBarType={MessageBarType.warning}
      actions={<PrimaryButton text={"Configure"} onClick={props.onActionClicked} />}
    >
      {intl.formatMessage(messages.description)}
    </StyledMessageBar>
  );
};

export default InitialConfigAlert;
