import { Icon as OfficeIcon } from "@fluentui/react";
import { useTheme } from "cayo.ui";
import React, { CSSProperties, FC } from "react";
import styled, { keyframes } from "styled-components";
import { IIcon } from "../../api/schema.api";
import GearIcon from "./../../images/gear.svg";
import MaskedIcon from "./MaskedIcon";

const Icon: FC<
  Omit<IIcon, "type"> & { rotateSpeed?: string | undefined; smallIconSize?: number } & {
    style?: CSSProperties;
    maskedIconStyle?: CSSProperties;
  }
> = (props) => {
  const { cayoTheme, resolveNamedColor } = useTheme();
  const icons = props.iconName?.split("+");
  const iconName = icons && icons[0];
  const maskedIconName = icons?.length && icons.length > 1 ? icons![1] : 0;
  const isGearedIcon = maskedIconName === "Gear";
  const defaultColor =
    (props?.style?.color && resolveNamedColor(props?.style?.color)) ||
    cayoTheme.brandColors.disabled;

  const clipPathStyle: Partial<CSSProperties> = {};
  const maskedIconStyle = props.maskedIconStyle || {};

  if (maskedIconName) {
    clipPathStyle.clipPath = "polygon(100% 57%, 100% 0, 0 0, 0 100%, 49% 100%, 59% 87%, 74% 71%);";
  }

  const mainIcon = (
    <OfficeIcon
      iconName={iconName}
      color={defaultColor}
      styles={{
        root: {
          color: defaultColor,
          //  fontSize: props.style?.fontSize,
          //  fontWeight: props.style?.fontWeight as any,
          display: "flex",
          ...((props.style || {}) as any),
          ...clipPathStyle,
        },
      }}
    />
  );

  if (maskedIconName) {
    return (
      <MaskedIcon
        maskIcon={
          !isGearedIcon ? maskedIconName : <img alt="" src={GearIcon} width="9" height="8" />
        }
        maskPosition="rightBottom"
        color={props.style?.color ?? defaultColor}
        fontSize={props?.smallIconSize}
        style={{ ...maskedIconStyle }}
      >
        {isGearedIcon ? (
          <div
            style={{
              clipPath: isGearedIcon
                ? "polygon(100% 0, 100% 42%, 40% 100%, 0 100%, 0 0)"
                : undefined,
            }}
          >
            {mainIcon}
          </div>
        ) : (
          mainIcon
        )}
      </MaskedIcon>
    );
  } else {
    let icon = mainIcon;
    if (props.rotate) {
      icon = (
        <Rotate speed={props.rotateSpeed} backward={props.backward}>
          {icon}
        </Rotate>
      );
    }

    return icon;
  }
};

const rotateForward = keyframes`
  from {
    transform: rotate(0deg); 
  }

  to {
    transform: rotate(360deg);
  }
`;

const rotateBackward = keyframes`
   from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const Rotate = styled.div<{ speed?: string | undefined; backward?: boolean | undefined }>`
  display: inline-block;
  animation: ${(props) => (props.backward ? rotateBackward : rotateForward)}
    ${(props) => props.speed || "2s"} linear infinite;
`;

export default Icon;
