const conditionalOperatorRegex = /(([^?]*)\?([^:]*):([^;]*)|(\s*=\s*)[^;]*)/;
const insideCurlyBrackets = /{(.*?)}/;
const allInsideCurlyBrackets = /{(.*?)}/g;
const specialCharacters = /[!{}[\]]/;
const insideSquareBruckets = /\[([^[\]]*)\]/;
const onlyDigit = /^\d+$/;
const pixels = /([0-9]{1,})px$/;

const bindingsRegexes = {
  conditionalOperatorRegex,
  insideCurlyBrackets,
  allInsideCurlyBrackets,
  specialCharacters,
  insideSquareBruckets,
  onlyDigit,
  pixels,
};

export default bindingsRegexes;
