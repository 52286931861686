import { IColumn as IOfficeColumn } from "@fluentui/react";
import React, { Fragment } from "react";
import { IColumn } from "../../../api/schema.api";
import logger from "../../../libs/logger";
import cellRenderers, {
  AuxRenderProps,
  IActionPromise,
} from "../../LegacyDetailsList/cell-renderers";
import odataRenderers from "../../LegacyDetailsList/odata-renderes";

export const renderItemColumn = (
  item: any,
  index: number,
  column: IOfficeColumn | undefined,
  onItemClicked?: IActionPromise,
  auxProps?: AuxRenderProps
): JSX.Element => {
  if (!column || !column.fieldName) {
    return <React.Fragment />;
  }

  const formatValue = (column as unknown as IColumn)?.renderOptions?.formatter;
  const renderer = formatValue && odataRenderers[formatValue];
  if (renderer) {
    return renderer(item, index, column, onItemClicked, auxProps);
  }

  if (formatValue === "html") {
    const component = cellRenderers.html(item, index, column);
    if (component) {
      return component;
    }
  } else if (formatValue?.toLowerCase() === "datetime") {
    const component = cellRenderers.date(item, index, column, onItemClicked, auxProps);
    if (component) {
      return component;
    }
  }

  try {
    for (let renderer of Object.keys(cellRenderers)) {
      const component = cellRenderers[renderer](item, index, column, onItemClicked, auxProps);
      if (component !== null) {
        return component;
      }
    }
  } catch (e) {
    logger.log("Invalid rendeder", e);

    return <Fragment>{"Invalid rendeder!"}</Fragment>;
  }

  return <Fragment />;
};
