// TODO: move object loading from PropertiesPanelHolder to this component

import { LoadingOverlay } from "cayo.ui";
import React, { FC, useMemo } from "react";
import ajax from "../../libs/ajax";
import SchemeLoader, { CommonSchemeLoaderProps } from "./SchemeLoader";

const ObjectSchemeLoader: FC<CommonSchemeLoaderProps> = ({ schemePath, style, auxProps }) => {
  const { object, parentContainerName, onSubmit } = auxProps || {};
  const { data: propertyAnnotations, isLoading } = ajax.usePropertyAnnotations(`v0/{schemeId}`, {
    schemeId: object?.objectPath,
  });

  const auxPropsCached = useMemo(
    () => ({
      ...auxProps,
      propertyAnnotations: propertyAnnotations,
      parentContainerName,
      onSubmit,
    }),
    [auxProps, propertyAnnotations]
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return <SchemeLoader schemePath={schemePath} style={style} auxProps={auxPropsCached} />;
};

export default ObjectSchemeLoader;
