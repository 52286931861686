import { FixedList, IColumn, useComponentLogger } from "cayo.ui";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { FixedListProps, ListItemClicked } from "./ODataFixedList";

import { IColumn as IOfficeColumn } from "@fluentui/react";
import ISelectionDependentCommandBarItemProps from "cayo.ui/lib/esm/interfaces/commands/selectionDependentCommandBarItemProps";
import { Entity } from "../../../api/cayo-graph";
import ajax from "../../../libs/ajax";
import { actionHandlerNames } from "../../../scheme/actions/action-handlers";
import { bindingsUtils } from "../../../scheme/bindings";
import { endpoints } from "../../../services/endpoints.service";
import useService from "../../../services/services.hook";
import { Dictionary } from "../../../types/dictionary";
import { ajaxUtils } from "../../../utils/ajax-utils";
import { objectUtils } from "../../../utils/object-utils";
import odataUtils from "../../../utils/odata-utils";
import { useSchemes } from "../../App/hooks";
import auxPropsUtils from "../../Form/modelUtils/aux-props-utils";
import { commonMessages } from "../../common-messages";

const maxItemsInDeleteForm = 5;

const useODataFixedList = (props: FixedListProps) => {
  const { addProps, name, actions } = props;
  const { object, setValue, setMessage } = addProps;

  const { showPropertiesPanel } = useSchemes();

  const intl = useIntl();
  const log = useComponentLogger(FixedList, props.name!);
  const uiInteraction = useService("uiInteraction");

  const items = useMemo<any[]>(() => {
    return props.items?.length ? props.items : addProps?.value ? addProps?.value : [];
  }, [props.items, addProps?.value]);

  const missingDataText = useMemo(() => {
    if (items?.length || (addProps?.object && addProps.object[name!]?.length)) {
      return undefined;
    }

    return intl.formatMessage(commonMessages.noData);
  }, [items]);

  const onSaveArray = useCallback(
    (newValue: Entity[], skipErrorHandling?: boolean) => {
      const objectPath = props.addProps?.object?.objectPath;
      if (!objectPath) {
        return Promise.reject("The object path is not set");
      }
      const url = endpoints.buildRelativeApiPath(objectPath);

      newValue = auxPropsUtils.removeAuxProperties(newValue);
      uiInteraction.setLoading("submit");
      return ajax
        .getClient(url, "PATCH", undefined, {
          [props.name!]: newValue,
        })
        .catch((e) => {
          return ajaxUtils.getError(e).then((e) => {
            const error = odataUtils.errorToString(e);
            if (!skipErrorHandling) {
              setMessage && setMessage({ type: "error", message: error });
            }
            return Promise.reject(error);
          });
        })
        .then((response) => {
          log.debug(response);
          setValue(response[props.name!], props);
          setMessage &&
            setMessage({
              type: "success",
              message: intl.formatMessage(commonMessages.propertiesUpdated, {
                objectName: object?.objectName,
              }),
            });
          return Promise.resolve(response);
        })
        .finally(() => {
          uiInteraction.setLoading(false);
        });
    },
    [props?.addProps?.value, setValue]
  );

  const onSaveItem = useCallback(
    (item: Entity, index: number, skipErrorHandling?: boolean) => {
      let newValue = objectUtils.cloneObject((props.addProps.value as Entity[]) ?? []);
      newValue[index] = { ...newValue[index], ...item };

      return onSaveArray(newValue, skipErrorHandling);
    },
    [onSaveArray]
  );

  const onDeleteItems = useCallback(
    (items: Entity[], skipErrorHandling?: boolean) => {
      let newValue = objectUtils.cloneObject((props.addProps.value as Entity[]) ?? []);

      newValue = newValue.filter(
        (_, index) => items.findIndex((i) => (i as any)._index === index) === -1
      );

      const deletingItems =
        items
          .slice(0, maxItemsInDeleteForm)
          .map((i) => {
            var objectName = (i as any).displayName || i.objectName || (i as any).name;
            if (!objectName && !!object) {
              objectName = bindingsUtils.resolveSimpleExpression(
                i as Dictionary,
                "component.objectName"
              );
            }
            return "<b>" + objectName + "</b>";
          })
          .join("<br>") + (items.length > maxItemsInDeleteForm ? "<br>..." : "");

      return uiInteraction
        .confirm(intl.formatMessage(commonMessages.confirmDeleteItems), deletingItems)
        .then((ok) => {
          return ok ? onSaveArray(newValue, skipErrorHandling) : Promise.reject();
        });
    },
    [onSaveArray]
  );

  const commands = useMemo<ISelectionDependentCommandBarItemProps[] | undefined>(() => {
    return actions?.map((a) => {
      const result: ISelectionDependentCommandBarItemProps = {
        key: a.name!,
        ...a,

        iconProps: { iconName: a.icon?.iconName },

        onClick: (e) => {
          e?.preventDefault();
          e?.stopPropagation();
          log.debug("dd");
        },
        onClickWithSelection(selectedItems) {
          const showPropsAction = "show.props" as actionHandlerNames;
          const showTypeAction = "show.type" as actionHandlerNames;

          // !!! TODO: refactoring
          if (a.action === "selectedItems:delete") {
            selectedItems && onDeleteItems(selectedItems, true);
          } else if (
            a.action?.startsWith(showPropsAction) ||
            a.action?.startsWith(showTypeAction)
          ) {
            const isShowTypeAction = a.action?.startsWith(showTypeAction);
            let type = isShowTypeAction ? a?.action?.split("=")[1] : undefined;
            const typeParts = type?.split("/");
            let isNewObject = false;
            if (typeParts?.length === 2 && typeParts[1] === "new") {
              isNewObject = true;
            }

            const index = isNewObject
              ? (props?.addProps?.value as Array<any>)?.length ?? 0
              : selectedItems![0]._index;

            log.debug("action", a.action, "index", index, "isNewObject", isNewObject);

            uiInteraction.actionHandlers["show.props"](object!.objectPath!, {
              property: {
                name: props.name!,
                index,
                parentObjectPath: addProps!.object!.objectPath!,
                type,
              },
              parentId: props.id,
              object: { ...(isNewObject ? {} : (selectedItems![0] as Entity)) },
              objects: [{ ...(isNewObject ? {} : (selectedItems![0] as Entity)) }],
              onSubmit: (item) => onSaveItem(item, index, true),
            });
          }

          log.debug("onClickWithSelection", selectedItems);
        },
      };

      delete (result as any).type;
      return result;
    });
  }, [actions, items]);

  const onItemClicked = useCallback<ListItemClicked>(
    (item: any, index: number, officeColumn: IOfficeColumn) => {
      const column = officeColumn as unknown as IColumn;
      if (item && item._hasToggle && typeof index === "number" && column) {
        const newItem = objectUtils.cloneObject(item);
        const newSwitchValue = !newItem[column.renderOptions!.switchProperty!];
        newItem[column.renderOptions!.switchProperty!] = newSwitchValue;
        onSaveItem(newItem, index)
          .catch((e) => {
            log.debug(e);
            return Promise.reject(e);
          })
          .then(() => {
            item[column.renderOptions!.switchProperty!] = newSwitchValue;
          });
        log.debug(newItem, index);
      } else {
        if (typeof item === "string") {
          showPropertiesPanel({ id: item, parentId: props.name });
        }
      }
      return Promise.resolve();
    },
    [onSaveItem]
  );

  return { items, commands, onItemClicked, missingDataText };
};

export default useODataFixedList;
