import { Entity } from "../api/cayo-graph";

const MaxInteractiveItems = 100;

class InteractiveList {
  private items: Entity[];

  constructor(items: Entity[], readonly maxItems?: number, readonly insertNewItems?: boolean) {
    this.items = items;
  }

  public push(newItems: Entity[]) {
    for (const newItem of newItems as any[]) {
      const existingItem = this.items.find((j) => j.id === newItem.id) as any;
      if (existingItem) {
        for (const prop of Object.getOwnPropertyNames(newItem)) {
          if (newItem[prop] && newItem[prop] !== undefined && newItem[prop] !== null) {
            newItem[prop] && (existingItem[prop] = newItem[prop]);
          }
        }
      } else if (this.insertNewItems !== false) {
        this.items.push(newItem);
      }
    }
  }

  public delete(newItems: Entity[]) {
    this.items = this.items.filter((i) => !newItems.find((ii) => ii.id === i.id));
  }

  public reset() {
    this.items = [];
  }

  public get Items() {
    const maxItems = this.maxItems || MaxInteractiveItems;
    return this.items.slice(0, maxItems);
  }
}

export default InteractiveList;
