import React, { FC, Fragment, useMemo } from "react";
import { IScheme } from "../../api/schema.api";

import { StretchedContainer } from "../CommonLayout";
import { useNavSelectedSchemeId } from "../Nav/nav-hooks";
import ODataScheme from "./ODataScheme";
import schemeParts from "./scheme-parts";
import SchemeLoader from "./SchemeLoader";

const PageContent: FC<IScheme> = () => {
  const navSchemeId = useNavSelectedSchemeId();
  const dashboardId = schemeParts.ids.dashboard;

  const dashboardInfo = useMemo(() => {
    return {
      isDashboard: navSchemeId.toLowerCase().endsWith(dashboardId),
      dashboardSchemeId: navSchemeId.split("/").reverse().join("/"),
    };
  }, [navSchemeId]);

  if (!navSchemeId) {
    return <Fragment />;
  }

  const { isDashboard } = dashboardInfo;

  return (
    <StretchedContainer>
      {isDashboard && <SchemeLoader schemePath={dashboardInfo.dashboardSchemeId} />}
      {!isDashboard && (
        <ODataScheme schemePath={navSchemeId} key={navSchemeId} restorePropPanel={true} />
      )}
      {/* {isDashboard && <GaugeChart />} */}
    </StretchedContainer>
  );
};

export default PageContent;
