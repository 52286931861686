import { IconButton } from "@fluentui/react";
import { ITheme, themeUtils } from "cayo.ui";
import styled from "styled-components";
import { Column, Row } from "../../CommonLayout";

export const ClearIconButton = styled(IconButton)<{ isMouseOver: boolean }>`
  margin-right: 4px;
  z-index: 1;
  visibility: ${(props) => (props.isMouseOver ? "visible" : "hidden")};
`;

export const StyledColumn = styled(Column)`
  padding-right: 0px;
  padding-left: 6px;
`;

export const StyledRow = styled(Row)`
  height: 100%;
  background-color: white;
`;

export const AnimationContainer = styled.div`
  height: 100%;
  flex-grow: 1;
  margin-left: 8px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled(Row)`
  height: 100%;
  min-width: 42px;
  max-width: 42px;
  background-color: ${(props) =>
    themeUtils.addAlpha((props.theme as ITheme).cayoTheme.brandColors.disabled, 0.1)};
`;
