import { IColumn } from "@fluentui/react";
import { debounce } from "cayo.ui";
import { useCallback, useRef } from "react";

const columnsSizesMap = new WeakMap();

type MeasureProcessProps = {
  column: IColumn;
  index: number;
  measureCell: (index: number, onMeasureDone: (width: number) => void) => void;
  onColumnResized: (column: IColumn, width: number, index: number) => void;
};

function measureCellProcess({
  column,
  index,
  measureCell,
  onColumnResized,
}: MeasureProcessProps): Promise<IColumn> {
  return new Promise((resolve) => {
    measureCell(index, (width: number) => {
      if (!columnsSizesMap.has(column)) columnsSizesMap.set(column, width);
      if (columnsSizesMap.get(column) < width) columnsSizesMap.set(column, width);

      onColumnResized(column, columnsSizesMap.get(column), index);
      resolve(column);
    });
  });
}

export const useAdjustColumns = (columns: IColumn[]) => {
  const ref = useRef(null);

  const adjustColumns = useCallback(
    async (onAdjusted: (columns: IColumn[]) => void, onLoading?: (loading: boolean) => void) => {
      const onColumnResized = (ref?.current as any)?._onColumnResized;
      const batchSize = 20;

      if (onColumnResized) {
        onLoading && onLoading(true);
        let activeRows = (ref?.current as any)?._activeRows as any[];

        for (let i = 0; i < batchSize; i++) {
          if (activeRows.hasOwnProperty(i)) {
            for (let index = 0; index < columns.length; index++) {
              const column = columns[index];
              const currentRow = activeRows[i];
              const measureCell = (index: number, onMeasureDone: (width: number) => void) =>
                currentRow.measureCell(index, onMeasureDone);
              await measureCellProcess({ column, index, measureCell, onColumnResized });
            }
          }
        }

        debounce(
          () => {
            onAdjusted(columns);
            onLoading && onLoading(false);
          },
          onLoading ? 1000 : 0
        )();
      }
    },
    [columns]
  );

  return { adjustColumns, ref };
};
