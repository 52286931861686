import { defineMessages } from "react-intl";

const messages = defineMessages({
  noon: {
    defaultMessage: "Noon (PM)",
    id: "time-limit.noon",
  },
  midnight: {
    defaultMessage: "Midnight (AM)",
    id: "time-limit.midnight",
  },
  fullDay: {
    defaultMessage: "full day",
    id: "time-limit.fullday",
  },
  timeRange: {
    defaultMessage: "from {startTime} to {endTime}",
    id: "time-limit.fullday",
  },
  subtitle: {
    defaultMessage: "Select one continuous interval per a week day",
    id: "time-limit.subtitle",
  },
  serverTimeZone: {
    defaultMessage: "Server time zone: {serverTimeZone}",
    id: "time-limit.server-time-zone",
  },
});

export default messages;
