import { Stack } from "@fluentui/react";
import React from "react";
import ReactJson from "react18-json-view";
import { objectUtils } from "../../../utils/object-utils";
import LinkedObject from "../components/LinkedObject";
import GridCell from "./GridCell";
import { isObjectLink, renderObjectProp } from "./common";
import { RenderCellFn } from "./types";

export const renderObjectCell: RenderCellFn = ({ item, column, onItemClicked, auxProps }) => {
  const value = item[column.fieldName ?? ""];
  if (Object.prototype.toString.call(value) !== "[object Object]") {
    return null;
  }

  if (isObjectLink(value)) {
    return (
      <GridCell multiline renderer="object-link" toCopyValue={() => value.objectName}>
        <LinkedObject
          key={item.id}
          {...value}
          onItemClicked={onItemClicked}
          getIconAnnotation={auxProps?.getIconAnnotation}
        />
      </GridCell>
    );
  }
  if (objectUtils.isComplexObject(value)) {
    return (
      <GridCell
        multiline
        renderer="object-complex"
        toCopyValue={() => JSON.stringify(value, null, 2)}
      >
        <Stack verticalAlign="start" style={{ height: "100%" }}>
          <ReactJson key={item.id} src={value} collapsed={true} enableClipboard={true} />
        </Stack>
      </GridCell>
    );
  }
  return (
    <GridCell renderer="object-cell" multiline toCopyValue={() => objectToCopy(value)}>
      <React.Fragment key={item.id}>
        {renderObjectProp(column.fieldName!, value, undefined, onItemClicked, auxProps)}
      </React.Fragment>
    </GridCell>
  );
};

export function objectToCopy(value: any): string {
  if (Object.prototype.toString.call(value) !== "[object Object]") {
    return "";
  }
  if (isObjectLink(value)) {
    return value.objectName as string;
  }
  if (objectUtils.isComplexObject(value)) {
    return JSON.stringify(value, null, 2);
  }
  const resultArr = Object.keys(value).map((key) => {
    const val = value[key];
    switch (true) {
      case objectUtils.isPrimitive(val):
        return `${key}: ${val.toString()}`;
      case isObjectLink(val):
        return `${key}: ${val.objectName}`;
      case objectUtils.isArray(val):
        return `${key}: ${createPropertyArrayString(val)}`;
      default:
        throw new Error(`Unknown type: ${JSON.stringify(val)}`);
    }
  });
  return resultArr.join("\n");
}

function createPropertyArrayString(value: Array<any>) {
  return value.join(",");
}
