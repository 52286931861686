import React from "react";
import Callout from "./Callout";
import ContextPanel from "./ContextPanel";
import Panel from "./Panel";

const contextpanel = (props: any) => <ContextPanel {...props} />;
const panel = (props: any) => <Panel {...props} />;
const callout = (props: any) => <Callout {...props} />;

const surfaceRenderers = {
  contextpanel,
  panel,
  callout,
};

export default surfaceRenderers;
