import { ErrorRecord, IFormField } from "cayo.ui";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { IJsonForm } from "../../../api/schema.api";
import modelUtils from "../modelUtils";
import { formUtils } from "../utils";

const useFormErrors = (form: IJsonForm) => {
  const intl = useIntl();
  const [errors, setErrors] = useState<{ [id: string]: ErrorRecord }>({});

  const setErrorCallback = useCallback(
    (name: string, e?: ErrorRecord) => {
      if (e?.errorMessage) {
        errors[name!] = e;
      } else if (errors) {
        delete errors[name!];
      }
      setErrors({ ...errors });
    },
    [errors]
  );

  const countErrors = useCallback(
    (model: any) => {
      let totalErrors = 0;
      if (!form?.items?.length) {
        return totalErrors;
      }
      const editableItems = modelUtils.getEditableItems(form.items as IFormField[], model);

      for (const a of editableItems) {
        const name = (a as any).modelProperty || a.name;
        totalErrors += formUtils.checkError(a, model[name!], setErrorCallback, intl) ? 1 : 0;
      }

      return totalErrors;
    },
    [form]
  );

  return { errors, setErrors, setErrorCallback, countErrors, clearErrors: () => setErrors({}) };
};

export default useFormErrors;
