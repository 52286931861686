import {
  ActionButton,
  IconButton,
  Panel as OfficePanel,
  PanelType as OfficePanelType,
  Stack,
  Text,
} from "@fluentui/react";
import { useTheme } from "cayo.ui";
import React, { FC, Fragment, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { IWidget } from "../../api/schema.api";
import Border from "../Common/Border";
import { Column, Row } from "../CommonLayout";
import Icon from "../Graphics/Icon";
import { IClosableComponent } from "../Surfaces/Panel";
import dashboardMessages from "./messages";
import { LayerDiv } from "./styles";

import { getAlertsPanelStyles } from "../Alerts/Alerts.styles";

interface IWidgetListProps {
  onWidgetSelected: (widget: IWidget) => void;
  widgets: IWidget[];
}
const WidgetsList: FC<
  IWidgetListProps &
    IClosableComponent & {
      currentWidgets: IWidget[];
      onAddWidget: (widget: IWidget) => void;
      onResetBoard: () => void;
    }
> = (props) => {
  const intl = useIntl();
  const { currentWidgets, onAddWidget, widgets } = props;

  const theme = useTheme();
  const { cayoTheme, officeTheme } = theme;

  const isWidgetEnabled = useCallback(
    (widget: IWidget) => {
      return !currentWidgets?.find((w) => w.name === widget.name!);
    },
    [currentWidgets]
  );

  const [hoveredWidget, setHoveredWidget] = useState("");

  return (
    <OfficePanel
      title={intl.formatMessage(dashboardMessages.widgetsTitle)}
      type={OfficePanelType.custom}
      customWidth={"400px"}
      isOpen={true}
      styles={getAlertsPanelStyles(theme)}
      onRenderHeader={() => <Fragment />}
      onRenderNavigation={() => (
        <Column style={{ zIndex: 100, width: "100%" }}>
          <Row
            style={{
              alignContent: "center",
              justifyContent: "space-between",
              padding: "14px 0px 10px 16px",
            }}
          >
            <Text variant={"xLarge"} style={{ fontWeight: "bold" }}>
              {intl.formatMessage(dashboardMessages.widgetsTitle)}
            </Text>
            <IconButton
              iconProps={{ iconName: "cancel" }}
              style={{ marginRight: 10 }}
              onClick={props.onClose}
            />
          </Row>
          <Row
            style={{
              alignContent: "center",
              justifyContent: "space-between",
              padding: "14px 10px 10px 18px",
            }}
          >
            <ActionButton
              iconProps={{ iconName: "ViewOriginal" }}
              onClick={props.onResetBoard}
              text={intl.formatMessage(dashboardMessages.resetView)}
              style={{ color: officeTheme.semanticColors.link, padding: 0, width: "100%" }}
            />
          </Row>
        </Column>
      )}
      isBlocking={false}
    >
      <Stack horizontal={false}>
        <Stack horizontal={false} tokens={{ childrenGap: 8 }}>
          {widgets.map((d) => (
            <Border
              key={d.name}
              style={{ position: "relative", cursor: isWidgetEnabled(d) ? "cell" : "no-drop" }}
              onMouseEnter={() => setHoveredWidget(d.name!)}
              onMouseLeave={() => setHoveredWidget("")}
            >
              {!isWidgetEnabled(d) && <LayerDiv style={{ zIndex: 0 }} />}
              <Stack
                tokens={{ childrenGap: 8, padding: 8 }}
                horizontal={true}
                verticalAlign={"center"}
                onClick={() => onAddWidget(d)}
                style={{ position: "relative" }}
              >
                <Icon
                  iconName={d.galleryImage?.iconName || "GridViewLarge"}
                  smallIconSize={16}
                  style={{
                    fontSize: 38,
                    color:
                      hoveredWidget === d.name && isWidgetEnabled(d)
                        ? cayoTheme.brandColors.primaryHighlight
                        : cayoTheme.brandColors.disabled,
                  }}
                />
                <Text>{d.title}</Text>
              </Stack>
            </Border>
          ))}
        </Stack>
      </Stack>
    </OfficePanel>
  );
};

export default WidgetsList;
