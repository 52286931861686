import React from "react";
import ActivityItem from "./ActivityItem";
import EmptyItem from "./EmptyItem";

const activityitem = (props: any) => <ActivityItem {...props} />;
const emptyitem = (props: any) => <EmptyItem {...props} />;

const cardsRenderers = {
  activityitem,
  emptyitem,
};

export default cardsRenderers;
