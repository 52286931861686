import {
  DefaultButton,
  Dialog,
  DialogFooter,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { getId } from "@fluentui/utilities";
import { Html, LoadingOverlay, MultiValue, css, stringUtils, useTheme } from "cayo.ui";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Entity } from "../api/cayo-graph";
import { IActionItemDisplayDescription } from "../api/schema.api";
import { bindingsUtils } from "../scheme/bindings";
import logger from "./../libs/logger";
import { Column, StyledMessageBar } from "./CommonLayout";
import { commonMessages } from "./common-messages";

export interface IConfirmationModal {
  confirmationMessage: string | undefined;
  confirmationMessage2?: string | undefined;
  confirmationWarning?: string | undefined;
  onClose?: (result: boolean) => void;
  onConfirmed?: () => Promise<void>;
  showError: (error: string | undefined) => void;
  title?: string | undefined;
  okText?: string | undefined;
  objects?: Entity[];
  actionItemDisplayDescription?: IActionItemDisplayDescription | undefined;
}

export const ConfirmationBody: FC<{
  confirmationMessage: string;
  objects?: Entity[];
  confirmationWarning?: string;
  confirmationMessage2?: string;
  actionItemDisplayDescription?: IActionItemDisplayDescription | undefined;
}> = ({
  confirmationMessage,
  objects,
  confirmationMessage2,
  confirmationWarning,
  actionItemDisplayDescription,
}) => {
  const intl = useIntl();
  const { cayoTheme } = useTheme();

  const deletingItems = useMemo(() => {
    if (!objects?.length || !actionItemDisplayDescription?.format) {
      return undefined;
    }

    let result: Array<{ key: number; value: any }> = [];

    const totalItems = objects.length;
    result = (objects as any[])?.slice(0, 5)?.map((o, i) => {
      const value = bindingsUtils.resolveExpression(o, actionItemDisplayDescription!.format!);
      return {
        key: i,
        value,
      };
    });

    if (result.length < totalItems) {
      const diff = totalItems - result.length;
      const moreMsg = intl.formatMessage(commonMessages.moreItems, { count: diff });
      result.push({ key: result.length, value: moreMsg });
    }

    // else {
    //   //const top10Object = objects?.slice(0, 10);
    //   result = (objects as any[])?.map((o, i) => ({
    //     key: i,
    //     value:
    //       o?.objectName ||
    //       o?.name ||
    //       o?.displayName ||
    //       o?.subject ||
    //       o?.targetObjectName ||
    //       (o?.backupDateTime ? new Date(o.backupDateTime).toLocaleString() : ""),
    //   }));
    // }

    return result;
  }, [objects]);

  const actualConfirmationMessage = useMemo(() => {
    let result = confirmationMessage;
    if (deletingItems?.length === 1 && result.startsWith("{objects.length}")) {
      const pos = result.indexOf(".", "{objects.length}".length) + 1;
      result = stringUtils.trim(result.substring(pos));
    }

    return result;
  }, [confirmationMessage]);

  return (
    <Stack tokens={cayoTheme.metrics.stackTokens} className={css.mb6}>
      {actualConfirmationMessage?.split("\r\n").map((m, i) => {
        let resolvedMessage = bindingsUtils.resolveExpression({ objects }, m);
        if (typeof resolvedMessage !== "string") {
          resolvedMessage = m; // TODO: fix regex in resolveConditionalOperator
        }
        //const hasCount = m.indexOf("objects.length") > 0;
        //let resolvedMessage = m;
        // if (!!objects?.length && hasCount && resolvedMessage) {
        //   resolvedMessage = stringUtils.toSpaceDelimitedSentenceCase(
        //     resolvedMessage.replace("objects.length", objects!.length.toString())
        //   )!;
        // }
        const html = <Html key={i} value={resolvedMessage} />;

        return m.startsWith("NOTE:") ? (
          <StyledMessageBar key={"m" + i} messageBarType={MessageBarType.warning}>
            {html}
          </StyledMessageBar>
        ) : (
          html
        );
      })}
      {confirmationMessage2 && <Html value={confirmationMessage2} className={css.mt10} />}
      {confirmationWarning && (
        <MessageBar styles={{ root: { marginTop: 20 } }} messageBarType={MessageBarType.warning}>
          <Html value={confirmationWarning} />
        </MessageBar>
      )}
      {!!deletingItems?.length && (
        <MvContainer>
          <MvStyled
            formatter="html"
            readOnly={true}
            value={deletingItems}
            setValue={() => null}
            type="text"
            fontSize={"small"}
          />
        </MvContainer>
      )}
    </Stack>
  );
};

const ConfirmationModal: FC<IConfirmationModal> = ({
  confirmationMessage,
  confirmationMessage2,
  confirmationWarning,
  onClose,
  onConfirmed,
  showError,
  title,
  okText,
  objects,
  actionItemDisplayDescription,
}) => {
  const intl = useIntl();
  const labelId: string = getId("dialogLabel");
  const subTextId: string = getId("subTextLabel");

  const [isLoading, setIsLoading] = React.useState(false);

  const onConfirmClicked = () => {
    onClose && onClose(true);
    setIsLoading(true);

    return onConfirmed
      ? onConfirmed()
          .then((r) => {
            logger.log(r);
            setIsLoading(false);
          })
          .catch((e) => {
            setIsLoading(false);
            showError(e.message);
          })
      : Promise.resolve();
  };

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <React.Fragment>
      {confirmationMessage && (
        <Dialog
          styles={{ root: { minWidth: 400 } }}
          onDismiss={() => onClose && onClose(false)}
          hidden={false}
          minWidth={400}
          maxWidth={"100%"}
          dialogContentProps={{
            showCloseButton: false,
            styles: {
              innerContent: {
                alignItems: "center",
                display: "flex",
                minHeight: "50px",
              },
              subText: { fontSize: 16, fontWeight: 400 },
              title: { fontWeight: 600 },
            },

            title: title || intl.formatMessage(commonMessages.confirm),
          }}
          modalProps={{
            isBlocking: false,
            styles: {
              main: { maxWidth: 450 },
              scrollableContent: { height: "100%" },
            },
            subtitleAriaId: subTextId,
            titleAriaId: labelId,
          }}
        >
          <Column>
            <ConfirmationBody
              objects={objects}
              confirmationMessage={confirmationMessage}
              confirmationMessage2={confirmationMessage2}
              confirmationWarning={confirmationWarning}
              actionItemDisplayDescription={actionItemDisplayDescription}
            />
          </Column>

          <DialogFooter>
            <PrimaryButton
              onClick={() => onConfirmClicked()}
              text={okText ?? commonMessages.yes.defaultMessage}
            />
            <DefaultButton
              onClick={() => onClose && onClose(false)}
              text={commonMessages.no.defaultMessage}
            />
          </DialogFooter>
        </Dialog>
      )}
    </React.Fragment>
  );
};

const MvContainer = styled.div`
  max-height: 400px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 6px;
`;

const MvStyled = styled(MultiValue)`
  gap: 6px;
`;

export default ConfirmationModal;
