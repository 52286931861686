import { ChoiceGroup, Stack } from "@fluentui/react";
import { Html, MessageBar, useComponentLogger } from "cayo.ui";
import { IWizardState, Wizard, WizardPage, wizardStyles } from "cayo.ui.wizards";
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { Database } from "../../../api/cayo-graph";
import ajax from "../../../libs/ajax";
import logger from "../../../libs/logger";
import useService from "../../../services/services.hook";
import { ajaxUtils } from "../../../utils/ajax-utils";
import { urlUtils } from "../../../utils/url-utils";
import AppContext from "../../App/app.context";
import useForm from "../../Form/hooks/useForm";
import auxPropsUtils from "../../Form/modelUtils/aux-props-utils";
import { FormProps } from "../../Form/types";
import DatabaseSettingsReview from "./DatabaseSettingsReview";
import { DbType, IDatabaseConfigurationWizardState } from "./IDatabaseConfigurationWizardState";
import azureSqlForm from "./azure-sql.form";
import { messages } from "./messages";
import onPremSqlForm from "./on-prem-sql.form";
import { wizardStateReducer } from "./reducer";

const DatabaseConfigurationWizard: FC<{
  databaseSettings?: Database;
  onUseBuiltinDatabase: () => Promise<void>;
  onClose: () => void;
}> = (props) => {
  const intl = useIntl();
  const { databaseSettings, onUseBuiltinDatabase, onClose } = props;
  const log = useComponentLogger(DatabaseConfigurationWizard);

  const { showServerUnavailable } = useContext(AppContext);

  const [loadingText, setLoadingText] = useState<string | undefined>(undefined);
  // const [isFinished, setIsFinished] = useState(false);
  const actionBuilder = useService("actionBuilder");
  const initialState: IDatabaseConfigurationWizardState = {
    dbType: "azure",
    currentStep: 0,
    isDbParamsInputValid: false,
  };
  const [wizardState, dispathWizardState] = useReducer(wizardStateReducer, initialState);

  const finishTitleMap: { [key in DbType]: string } = useMemo(
    () => ({
      builtin: intl.formatMessage(messages.builtinFinishDescription),
      "on-prem": intl.formatMessage(messages.onpremFinishDescription),
      azure: intl.formatMessage(messages.azureFinishDescription),
    }),
    []
  );

  const formProps = useMemo<FormProps>(() => {
    const result = {
      actionBuilder,
      ...(wizardState.dbType === "azure"
        ? azureSqlForm
        : wizardState.dbType === "on-prem"
        ? onPremSqlForm
        : { type: "json-form" }),
    };

    return result;
  }, [wizardState.dbType]);

  const { renderer, errors, isSubmitting, onSubmit, isInputValid, model } = useForm(formProps);

  useEffect(() => {
    setLoadingText(isSubmitting ? intl.formatMessage(messages.validatingDBParameters) : undefined);
  }, [isSubmitting]);

  useEffect(() => {
    dispathWizardState({
      action: "setDbParams",
      newState: {
        dbParams: model,
        onValidateParams: onSubmit,
        onSaveParams: async () => {
          const url = urlUtils.replaceLastSegment(formProps.submit!.url!, "configureDatabase");

          try {
            const sendingModel = auxPropsUtils.removeAuxProperties(model);

            setLoadingText("Restarting server...");
            await ajax.getClient(url, "POST", undefined, {
              ...sendingModel,
              restart: true,
              copyData: true,
              overrideOwner: true,
              instruction: undefined, // !!! dirty fix
            });
          } catch (e) {
            setLoadingText(undefined);
            await ajaxUtils.handleError(e);
            throw new Error();
          }
        },

        isDbParamsInputValid: isInputValid,
      },
    });
  }, [onSubmit, model, isInputValid]);

  const onFinish = useCallback(
    (data: IWizardState) => {
      const asyncFunc = async () => {
        const state = data.data as IDatabaseConfigurationWizardState;

        log.debug("Finishing wizard", state);

        if (state.dbType === "builtin") {
          await onUseBuiltinDatabase();
          onClose();
        } else if (state.onSaveParams) {
          await state.onSaveParams();

          await onUseBuiltinDatabase();

          log.debug("Params saved", state);

          log.debug("Closing wizard", state);

          onClose();

          showServerUnavailable({
            state: "started",
            operatingMode: "migration",
            message: "Starting DB migration...",
          });

          log.debug("reload page", state);

          document.location.reload();
        }
      };

      return asyncFunc;
    },
    [wizardState.dbType, wizardState.onSaveParams]
  );

  const { dbType } = wizardState;

  const DbParamsRenderer = (
    <Stack
      horizontal={false}
      wrap={false}
      tokens={wizardStyles.stackTokens}
      styles={{ root: { paddingLeft: 26 } }}
    >
      {wizardState.validateDbParamsError && (
        <MessageBar messageBarType="error">{wizardState.validateDbParamsError}</MessageBar>
      )}
      {renderer()}
    </Stack>
  );

  return (
    <Wizard
      loadingText={loadingText}
      title={intl.formatMessage(messages.wizardTitle)}
      isFinished={wizardState.isFinished}
      data={wizardState}
      activePage={0}
      closeConfirmationMessage={intl.formatMessage(messages.closeConfirmation)}
      {...props}
    >
      <WizardPage
        isInputValid={wizardState.dbType === "builtin" ? true : wizardState.isDbParamsInputValid}
        onNext={async (data) => {
          const state = data as IDatabaseConfigurationWizardState;
          dispathWizardState({
            action: "setValidateDbParamsError",
            newState: { validateDbParamsError: undefined },
          });

          setLoadingText(undefined);

          logger.debug("model", model);
          try {
            if (state.dbType !== "builtin") {
              await state.onValidateParams!();
            }

            dispathWizardState({
              action: "setStep",
              newState: { currentStep: state.currentStep! + 1, isFinished: true },
            });
          } catch (e) {
            const error = await ajaxUtils.getError(e);
            dispathWizardState({
              action: "setValidateDbParamsError",
              newState: { validateDbParamsError: error?.error_description },
            });

            throw e;
          } finally {
            logger.debug(model);
            logger.debug(errors);
          }

          // return Promise.resolve();
        }}
        title={intl.formatMessage(messages.firstStepTitle)}
        stepName={intl.formatMessage(messages.firstStepName)}
        subTitle={""}
      >
        {/* <StyledForm> */}

        <ChoiceGroup
          selectedKey={dbType}
          options={[
            {
              key: "azure" as DbType,
              text: intl.formatMessage(messages.azureDatabase),
            },
          ]}
          onChange={(e) => {
            e?.stopPropagation();
            dispathWizardState({ action: "setDbType", newState: { dbType: "azure" } });
          }}
        />
        {dbType === "azure" && DbParamsRenderer}
        <ChoiceGroup
          selectedKey={dbType}
          options={[
            {
              key: "on-prem" as DbType,
              text: intl.formatMessage(messages.onPremDatabase),
            },
          ]}
          onChange={(e) => {
            e?.stopPropagation();
            dispathWizardState({ action: "setDbType", newState: { dbType: "on-prem" } });
          }}
        />
        {dbType === "on-prem" && DbParamsRenderer}
        <ChoiceGroup
          selectedKey={dbType}
          options={[
            {
              key: "builtin" as DbType,
              text: intl.formatMessage(messages.builtInDatabase),
            },
          ]}
          onChange={(e) => {
            e?.stopPropagation();
            dispathWizardState({ action: "setDbType", newState: { dbType: "builtin" } });
          }}
        />
        {dbType === "builtin" && (
          <MessageBar messageBarType="info">
            <Html value={intl.formatMessage(messages.databaseConfigurationBuiltinWarning)} />
          </MessageBar>
        )}

        {/* </StyledForm> */}
      </WizardPage>

      <WizardPage
        isInputValid={wizardState.isDbParamsInputValid}
        isNextEnabled={wizardState.dbType === "builtin" ? true : wizardState.isDbParamsInputValid}
        onNext={async (data) => await onFinish(data)()}
        title={intl.formatMessage(messages.finishStepName)}
        stepName={intl.formatMessage(messages.finishStepName)}
        subTitle=""
        nextButtonName={(data) => {
          return data.dbType === "builtin"
            ? intl.formatMessage(messages.confirm)
            : intl.formatMessage(messages.connect);
        }}
      >
        {finishTitleMap[wizardState.dbType!]}
        <br />
        <br />
        <DatabaseSettingsReview
          defaultStorageSettings={databaseSettings}
          customStorageSettings={wizardState.dbType !== "builtin" ? model : undefined}
        />
      </WizardPage>
    </Wizard>
  );
};

export default DatabaseConfigurationWizard;
