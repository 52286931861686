import { useLogger, useTheme } from "cayo.ui";
import { useCallback, useMemo, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Entity } from "../../../api/cayo-graph";
import { ajaxUtils } from "../../../utils/ajax-utils";
import downloadUtils from "../../../utils/download-utils";
import odataUtils from "../../../utils/odata-utils";
import scheduleTypes from "../../Schedule/types";
import { commonMessages } from "../../common-messages";
import modelUtils from "../modelUtils";
import renderForm from "../renderers";
import { FormProps, IAdditionalFormProps } from "../types";
import useFormMessageBar from "./useFormMessageBar";
import useFormModel from "./useFormModel";

const useForm = (props: FormProps, object?: Entity) => {
  const intl = useIntl();
  const log = useLogger("form.hook");
  const theme = useTheme();
  const [hasFooter, setHasFooter] = useState(false);

  const {
    actionBuilder,
    propertyAnnotations,
    onSubmit: onParentSubmit,
    bodyProperty,
    ...formProps
  } = props;

  const { buildSubmit, processResponse } = actionBuilder;
  const {
    extendedProps,
    model,
    setModel,
    isSaveDisabled,
    variables,
    errors,
    setErrorCallback,
    countErrors,
    clearErrors,
    isLoading: isFormInitializing,
  } = useFormModel(props, object);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { message, setMessage } = useFormMessageBar(props?.submit?.url || ""); // TODO: use something else as formId

  const onSubmit = useCallback(() => {
    log.debug("Begin form submittion");

    let sendingModel = modelUtils.getModel(extendedProps, model);

    if (object) {
      const model = modelUtils.normalizeModelForComplexType(object, sendingModel);
      const oDatatypes = modelUtils.getODataTypes(object, sendingModel);

      sendingModel = {
        ...model,
        ...oDatatypes,
      };
    }

    let totalErrors = countErrors(model);

    if (totalErrors) {
      const msg = `Total ${totalErrors} errors found`;
      log.debug(msg);
      setMessage({ type: "error", message: msg });

      return Promise.reject();
    }

    if (props["@odata.type"]) {
      sendingModel["@odata.type"] = props["@odata.type"];
    }

    if (bodyProperty) {
      const isDummyType =
        sendingModel["@odata.type"] === "#" + ("cayo.graph.notSetSchedule" as scheduleTypes);

      sendingModel = { [bodyProperty]: isDummyType ? null : sendingModel };
    }

    if (/*!extendedProps.submit && */ onParentSubmit) {
      return onParentSubmit(sendingModel, true)
        .then(() => {
          extendedProps.signals?.send("close");
        })
        .catch((e) => {
          ajaxUtils
            .getError(e)
            .then((err) => setMessage({ type: "error", message: err?.error_description }));
        });
    }

    setIsSubmitting(true);
    log.debug("Model is ok", sendingModel);

    return buildSubmit(extendedProps.submit!, sendingModel)
      ?.then((response) => {
        log.debug("Form has submitted, response", response);

        setIsSubmitting(false);

        if (formProps.submit?.isStream) {
          const headers = ajaxUtils.getHeaders(response);
          downloadUtils.downloadStream(response, headers);
        }

        if (formProps.submit?.response) {
          processResponse(
            response,
            model,
            formProps.submit?.response,
            variables,
            extendedProps.signals
          );
        } else if (!props.isInsidePropPanel) {
          props.onClose && props.onClose(response);
        }

        setMessage({
          type: "success",
          message: intl.formatMessage(commonMessages.propertiesUpdated, {
            objectName: object?.objectName ?? props?.parentObjectName,
          }),
        });

        if (props?.onSubmitCB) {
          props?.onSubmitCB(response);
        }
      })
      .catch((e) => {
        return ajaxUtils.getError(e).then((ee) => {
          log.error("Failed to submit form", ee);

          const errorMessage = odataUtils.errorToString(ee);

          setMessage({ type: "error", message: errorMessage });

          return Promise.reject(errorMessage);
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [model, object, onParentSubmit]);

  const addProps = useMemo(() => {
    return {
      model,
      setModel,
      errors,
      setErrorCallback,
      intl,
      isSaveDisabled,
      object,
      propertyAnnotations,
      setMessage,
      theme,
      getIconAnnotation: props?.getIconAnnotation,
      signals: props?.signals as any,
      parentModel: props?.parentModel,
      handleResponse: props?.handleResponse,
    } as IAdditionalFormProps;
  }, [
    model,
    setModel,
    errors,
    setErrorCallback,
    isSaveDisabled,
    object,
    propertyAnnotations,
    setMessage,
    props?.getIconAnnotation,
    props?.signals,
    props?.parentModel,
  ]);

  useEffect(() => {
    if (extendedProps.footer) {
      if (!extendedProps?.items?.length) return setHasFooter(true);

      const hasEditableField = extendedProps?.items?.some((i) => !i.readOnly && !i.isStandalone);
      setHasFooter(!!hasEditableField);
    }
  }, [extendedProps]);

  const renderer = useCallback(() => {
    return renderForm(extendedProps, addProps);
  }, [addProps, extendedProps]);

  return {
    isSubmitting,
    isSaveDisabled,
    onSubmit,
    errors,
    clearErrors,
    message: !props?.isInsidePropPanel && message?.type === "success" ? undefined : message,
    renderer,
    isInputValid: !errors || !Object.keys(errors).length,
    model,
    isFormInitializing,
    hasFooter,
  };
};

export default useForm;
