import { IDatePickerStrings } from "@fluentui/react";
import { IValidation } from "../../api/schema.api";

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const DatePickerStrings = (dateValidation?: IValidation | undefined): IDatePickerStrings => {
  return {
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],

    days: weekDays,

    shortDays: ["S", "M", "T", "W", "T", "F", "S"],

    closeButtonAriaLabel: "Close date picker",
    goToToday: "Go to today",
    nextMonthAriaLabel: "Go to next month",
    nextYearAriaLabel: "Go to next year",
    prevMonthAriaLabel: "Go to previous month",
    prevYearAriaLabel: "Go to previous year",

    isRequiredErrorMessage:
      (dateValidation && dateValidation.errors && dateValidation.errors.required) ||
      "Field is required.",
  };
};

export default DatePickerStrings;
