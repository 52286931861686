import { IntlShape } from "react-intl";
import { IFormField } from "../../api/schema.api";
import messages from "./messages";

const notSetForm = (intl: IntlShape) => {
  return {
    title: intl.formatMessage(messages.notSet),
    items: [] as IFormField[],
  };
};

export default notSetForm;
