import {
  CommandButton,
  Dropdown,
  IContextualMenuProps,
  IDropdownOption,
  IIconProps,
  PrimaryButton,
} from "@fluentui/react";
import { DropDownPicker } from "cayo.ui";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import { IColumn, IFormField, IObjectPicker } from "../../api/schema.api";

import logger from "../../libs/logger";
import {
  ConditionType,
  ExpressionGroupCombinationType,
  ExpressionLogicType,
  IExpressionCondition,
  IQuery,
  LogicalOperandType,
  OdataQueryOperandType,
  PropertyOperandType,
} from "../GridContainer/grid-model/Query";
import { DispatchAction } from "../GridContainer/grid-model/grid.controller.actions";
import {
  ConditionGroup,
  ConditionRow,
  GroupCombinationsOptionsMock,
  ODataQueryRow,
} from "./Controls";
import { ConditionBuilderDropdown } from "./Dropdown";
import useConditionBuilder from "./useConditionBuilder";

const log = logger.getLogger("InplaceConditionBuilder");

export function isConditionRow(
  el: PropertyOperandType | LogicalOperandType | OdataQueryOperandType
): el is PropertyOperandType {
  return el.objectType === ExpressionLogicType.PropertyExpression;
}

export function isODataQueryRow(
  el: PropertyOperandType | LogicalOperandType | OdataQueryOperandType
): el is OdataQueryOperandType {
  return el.objectType === ExpressionLogicType.OdataQueryExpression;
}

enum DropDownPickerVariants {
  Default = "Default",
  Large = "Large",
}

type InplaceConditionBuilderProps = {
  dispatch?: DispatchAction;
  query?: IQuery;
  columns?: IColumn[];
  filterAnnotations?: IFormField[];
  objectTypesUrl?: string;
  objectTypesOptionsUrl?: string;
  onPickerStateChanged?: (isOpened: boolean) => void;
};

export const InplaceConditionBuilder: FC<InplaceConditionBuilderProps> = (props) => {
  const { onPickerStateChanged, dispatch, query, objectTypesUrl, objectTypesOptionsUrl } = props;
  const {
    getColumnsOptions,
    buildAndFetchQuery,
    getObjectTypesOptions,
    getObjectTypePropertyOptions,
  } = useConditionBuilder({
    dispatch,
    query,
    objectTypesUrl,
    objectTypesOptionsUrl,
  });
  const [columns, setColumns] = useState<Array<IColumn & IObjectPicker>>([]);
  const [objectTypes, setObjectTypes] = useState<Array<IDropdownOption>>([]);

  const methods = useForm<IExpressionCondition>({
    defaultValues: {
      kind: ConditionType.Expression,
      objectType: ExpressionLogicType.LogicalExpression,
      operator: ExpressionGroupCombinationType.AND,
      operands: [
        {
          objectType: ExpressionLogicType.PropertyExpression,
          property: "",
          propertyObjectType: {
            id: "",
            key: "",
            type: "",
          },
          value: "",
          enabled: true,
          operator: "contains",
        },
      ],
    },
  });
  const { control, handleSubmit } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "operands",
  });

  useEffect(() => {
    if (props?.query) {
      const expression = props.query?.conditions.find(
        (c) => c.kind === ConditionType.Expression
      ) as IExpressionCondition | undefined;

      if (expression) {
        methods.setValue("kind", ConditionType.Expression);
        methods.setValue("objectType", ExpressionLogicType.LogicalExpression);
        methods.setValue("operator", expression.operator);
        methods.setValue("operands", expression.operands);
      } else {
        methods.setValue("kind", ConditionType.Expression);
        methods.setValue("objectType", ExpressionLogicType.LogicalExpression);
        methods.setValue("operator", ExpressionGroupCombinationType.AND);
        methods.setValue("operands", [
          {
            objectType: ExpressionLogicType.PropertyExpression,
            property: "",
            propertyObjectType: {
              id: "",
              key: "",
              type: "",
            },
            value: "",
            enabled: true,
            operator: "contains",
          },
        ]);
      }
    }
  }, [props?.query]);

  const columnsOptions: IDropdownOption[] = useMemo(() => {
    if (columns.length > 0) {
      return columns.map<IDropdownOption>((c) => {
        return {
          text: c?.displayName || "",
          key: c.fieldName,
        };
      });
    }
    return [];
  }, [columns]);

  const objectTypeOptions = useMemo(() => objectTypes, [objectTypes]);

  const addRow = () => {
    append({
      objectType: ExpressionLogicType.PropertyExpression,
      property: "",
      value: "",
      operator: "contains",
      propertyObjectType: {
        id: "",
        key: "",
        type: "",
      },
      enabled: true,
    } as PropertyOperandType);
  };

  const addGroup = () => {
    append({
      objectType: ExpressionLogicType.LogicalExpression,
      operator: ExpressionGroupCombinationType.AND,
      enabled: true,
      operands: [
        {
          objectType: ExpressionLogicType.PropertyExpression,
          property: "",
          propertyObjectType: {
            id: "",
            key: "",
            type: "",
          },
          value: "",
          enabled: true,
          operator: "contains",
        },
      ],
    } as LogicalOperandType);
  };

  const addOdataQuery = () => {
    append({
      objectType: ExpressionLogicType.OdataQueryExpression,
      filter: "",
      enabled: true,
    } as OdataQueryOperandType);
  };

  const pickerOpened = useRef<boolean>(false);
  const toggleDropDownPicker = useRef<() => void>();
  const pickerStateHandler = useCallback(
    function (isOpened: boolean) {
      if (!!props.onPickerStateChanged) {
        pickerOpened.current = isOpened;
        props.onPickerStateChanged(isOpened);
      }
    },
    [props.onPickerStateChanged]
  );

  useEffect(() => {
    return () => {
      if (pickerOpened.current) {
        onPickerStateChanged && onPickerStateChanged(false);
      }
    };
  }, []);

  useEffect(() => {
    getColumnsOptions(props.columns, props.filterAnnotations as Array<IFormField>).then((columns) =>
      setColumns(columns)
    );
    getObjectTypesOptions().then((objectTypes) => setObjectTypes(objectTypes));
  }, []);

  return (
    <DropDownPicker
      title={"Condition Builder"}
      initToggle={(f) => (toggleDropDownPicker.current = f)}
      isActive={pickerOpened.current}
      onPickerStateChanged={pickerStateHandler}
      onClose={() => {
        log.debug("DropDownPicker.onClose");
      }}
      variant={DropDownPickerVariants.Large}
    >
      <ConditionBuilderDropdown>
        <ConditionBuilderContainer>
          <FormProvider {...methods}>
            <Controller
              name={`operator`}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  options={GroupCombinationsOptionsMock}
                  onChange={(event, option) => {
                    onChange(option?.key);
                  }}
                  selectedKey={value}
                  styles={{ dropdown: { width: "70px" } }}
                />
              )}
            />
            {fields.map((field, index) => {
              if (isConditionRow(fields[index])) {
                return (
                  <ConditionRow<IExpressionCondition>
                    key={field.id}
                    name={`operands.${index}`}
                    propertyName={`operands.${index}.property`}
                    valueName={`operands.${index}.value`}
                    propertyObjectTypeName={`operands.${index}.propertyObjectType`}
                    enabledName={`operands.${index}.enabled`}
                    columnsOptions={columnsOptions}
                    columns={columns}
                    index={index}
                    deleteRow={() => remove(index)}
                    deleteDisabled={fields.length === 1}
                    objectTypeOptions={objectTypeOptions}
                    getObjectTypePropertyOptions={getObjectTypePropertyOptions}
                  />
                );
              } else if (isODataQueryRow(fields[index])) {
                return (
                  <ODataQueryRow<IExpressionCondition>
                    key={field.id}
                    name={`operands.${index}`}
                    filterName={`operands.${index}.filter`}
                    enabledName={`operands.${index}.enabled`}
                    index={index}
                    deleteRow={() => remove(index)}
                    deleteDisabled={fields.length === 1}
                  />
                );
              } else {
                return (
                  <ConditionGroup
                    key={field.id}
                    deleteGroup={() => remove(index)}
                    deleteDisabled={fields.length === 1}
                    index={index}
                    columnsOptions={columnsOptions}
                    objectTypeOptions={objectTypeOptions}
                    getObjectTypePropertyOptions={getObjectTypePropertyOptions}
                    columns={columns}
                  />
                );
              }
            })}
            <CommandButton
              menuProps={menuProps(addRow, addGroup, addOdataQuery)}
              text="Add"
              iconProps={addIcon}
            />
          </FormProvider>
          <ApplyButtonContainer>
            <PrimaryButton text="Apply" onClick={handleSubmit(buildAndFetchQuery)} />
          </ApplyButtonContainer>
        </ConditionBuilderContainer>
      </ConditionBuilderDropdown>
    </DropDownPicker>
  );
};

const menuProps = (
  addRow: () => void,
  addGroup: () => void,
  addOdataQuery: () => void
): IContextualMenuProps => {
  return {
    items: [
      {
        key: "addRow",
        text: "Add Row",
        iconProps: { iconName: "AddTo" },
        onClick: addRow,
      },
      {
        key: "addGroup",
        text: "Add Group",
        iconProps: { iconName: "List" },
        onClick: addGroup,
      },
      {
        key: "addExpression",
        text: "Add Expression",
        iconProps: { iconName: "Code" },
        onClick: addOdataQuery,
      },
    ],
    shouldFocusOnMount: false,
  };
};

const addIcon: IIconProps = { iconName: "Add" };

const ConditionBuilderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ApplyButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
