import {
  CommandBar,
  CommandBarButton,
  ICommandBarItemProps,
  ICommandBarStyles,
  mergeStyles,
  PrimaryButton,
} from "@fluentui/react";
import { useAsync } from "@fluentui/react-hooks";
import { themeUtils, useTheme } from "cayo.ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PropertyAnnotation } from "../../api/cayo-graph";
import { IColumn, IObjectPicker, IQuickFilterBar } from "../../api/schema.api";
import logger from "../../libs/logger";
import AnrSearchBox from "../AnrSearchBox";
import { commandBarMessages } from "../CommandBar/messages";
import { InplaceConditionBuilder } from "../ConditionBuilder/ConditionBuilder";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
import renderComponent from "../QuickFilterBar/renderers";
import { DispatchAction } from "./grid-model/grid.controller.actions";
import { clone, IQuery, isConditionsEqual, SavedQueryItem } from "./grid-model/Query";
import { ManageQuery } from "./GridFilterBar.ManageQuery";
import { useQuickFilterBarButtonStyle } from "./GridFilterBar.style";
import { pickerValueMap } from "./GridFilterBar.utils";
import { resizeAndGroupsItems } from "./hooks/useResetColumnsAndGroupButton";

const log = logger.getLogger("QUICK-FILTER-NEW GridFilterBar");

export type GroupConfig = {
  key: string;
  text: string;
};

type GridFilterBarProps = {
  schema: IQuickFilterBar;
  columns?: IColumn[];
  propertyAnnotations: PropertyAnnotation[] | undefined;
  dispatch: DispatchAction;
  query: IQuery;
  savedQueries: Array<SavedQueryItem>;
  recentQueries: Array<string>;
  favoriteQueries?: Array<string>;
  groupConfig?: GroupConfig[];
  targetType?: string;
  isSearchSupported?: boolean;
  readonly?: boolean;
  gridSettingsKey: string;
  queryMode: GridStateQueryMode;
  queryUrl: string;
};

export function GridFilterBar(props: GridFilterBarProps): JSX.Element {
  const intl = useIntl();
  const theme = useTheme();
  const async = useAsync();
  const brandColors = theme.cayoTheme.brandColors;
  const { getIconAnnotation, getTypeAnnotation } = useTypeAnnotations();
  const pickerAnnotations = props.schema.annotations ?? [];
  const [innerQuery, setInnerQuery] = useState<IQuery>(() => ({ conditions: [], isSystem: false }));
  const [isDirty, setIsDirty] = useState(false);
  const [isPickerOpened, setIsPickerOpened] = useState(false);
  const [pickers, setPickers] = useState<ICommandBarItemProps[]>([]);

  const allowClear = !props.query.id
    ? !!props.query.isTemp
    : !!props.query.isTemp || !props.query.isDefault;

  useEffect(() => {
    log.debug(".mount -> props, query", props, innerQuery);
  }, []);

  useEffect(
    function initInnerQuery() {
      setInnerQuery(clone(props.query));
      setIsDirty(false);
    },
    [props.query]
  );

  const apply = useCallback(() => {
    async.setTimeout(() => {
      setIsPickerOpened(false);
      setIsDirty(false);
      props.dispatch({
        kind: "changeQuery",
        query: innerQuery,
      });
    }, 20);
  }, [props.dispatch, innerQuery]);

  const cacheKeyHash = useMemo(() => Date.now().toString(), [innerQuery]);

  const btnStyle = useQuickFilterBarButtonStyle();

  const clearFilterBtn: ICommandBarItemProps = useMemo(() => {
    return {
      key: "clearButton",
      onRender: () => (
        <CommandBarButton
          primaryDisabled={!allowClear}
          iconProps={{ iconName: "ClearFilter" }}
          ariaLabel="Clear filter"
          splitButtonAriaLabel="See options"
          title={intl.formatMessage(commandBarMessages.clearFilter)}
          styles={btnStyle}
          onClick={() => (allowClear ? props.dispatch({ kind: "clearQuery" }) : null)}
          split
          menuProps={{ items: clearButtonMenu }}
        />
      ),
    };
  }, [props.dispatch, props.query]);

  const clearButtonMenu = useMemo(() => {
    return resizeAndGroupsItems(
      intl,
      props.dispatch,
      props.groupConfig ?? [],
      props.query,
      true,
      allowClear
    );
  }, [intl, props.dispatch, props.groupConfig, props.query.groupBy, allowClear]);

  // TODO: remove it
  // const queryList: ICommandBarItemProps = useMemo(() => {
  //   const openPicker = () => {
  //     props.dispatch({ kind: "openQueryPicker" });
  //   };
  //   const selectQuery = (q: SavedQueryItem) => {
  //     props.dispatch({ kind: "selectQuery", queryId: q.id });
  //   };
  //   const toggleFavorite = (id: string) => {
  //     log.debug("toggleFavorite -> id", id);
  //     props.dispatch({ kind: "toggleFavorite", id });
  //   };
  //   return {
  //     key: "queryList",
  //     cacheKey: `queryList-${cacheKeyHash}`,
  //     onRender: () => (
  //       <QueryList
  //         key="ql"
  //         currentQuery={{ id: props.query.id, name: props.query.name }}
  //         onOpenQueryPicker={openPicker}
  //         onSelectQuery={selectQuery}
  //         savedQueries={props.savedQueries}
  //         recentQueriesIds={props.recentQueries}
  //         favoriteQueriesIds={props.favoriteQueries}
  //         onToggleFavorite={toggleFavorite}
  //       />
  //     ),
  //   };
  // }, [
  //   props.schema,
  //   props.dispatch,
  //   props.savedQueries,
  //   props.recentQueries,
  //   props.favoriteQueries,
  //   cacheKeyHash,
  // ]);

  const currentQuery: ICommandBarItemProps = {
    key: "currentQuery",
    cacheKey: `currentQuery-${props.query.name}`,
    text: props.query.name || intl.formatMessage(commandBarMessages.filter),
    iconProps: { iconName: resolveIcon(props.queryMode) },
    buttonStyles: btnStyle,
    onClick: () => {
      props.dispatch({ kind: "openQueryPicker" });
    },
  };

  const manageQuery: ICommandBarItemProps = useMemo(() => {
    return {
      key: "manageQuery",
      cacheKey: `manageQuery-${cacheKeyHash}`,
      onRender: () => (
        <ManageQuery
          key="mq"
          currentQuery={props.query}
          dispatch={props.dispatch}
          // makeForEdit={makeForEdit}
        ></ManageQuery>
      ),
    };
  }, [cacheKeyHash, props.schema, props.query, props.dispatch]);

  const anrFilter: ICommandBarItemProps = useMemo(() => {
    return {
      key: "search-box",

      onRender: () => (
        <div style={{ padding: "0 8px" }}>
          <AnrSearchBox key="anr-sb" width={200} query={innerQuery} dispatch={props.dispatch} />
        </div>
      ),
    };
  }, [innerQuery, props.dispatch]);

  useEffect(() => {
    function generatePickers() {
      const result = [];

      for (const annotation of pickerAnnotations) {
        let pickerItem;

        if (
          annotation.name === "advancedFilter" &&
          process.env.REACT_APP_ENABLE_CONDITION_BUILDER === "true"
        ) {
          pickerItem = {
            key: `picker-condition-builder`,
            cacheKey: `picker-condition-builder-${cacheKeyHash}`,
            onRender: () => (
              <div className={pickerContainerClass}>
                <InplaceConditionBuilder
                  query={props.query}
                  dispatch={props.dispatch}
                  columns={props.columns}
                  objectTypesUrl={props.schema["typeInfoPath"]}
                  objectTypesOptionsUrl={props.schema["typeDetailsPath"]}
                  filterAnnotations={props.schema.annotations}
                  onPickerStateChanged={(isOpened) => {
                    log.debug(
                      "picker.onPickerStateChanged -> name, isOpened",
                      annotation.name,
                      isOpened
                    );
                    setIsPickerOpened(isOpened);
                  }}
                />
              </div>
            ),
          };
        } else {
          const possibleValues =
            props.propertyAnnotations?.find(
              (p) => p.target === `${props.targetType}/${annotation.name}`
            )?.possibleValues ?? [];

          const value = pickerValueMap.fromConditions(
            innerQuery.conditions,
            annotation,
            possibleValues
          );

          log.debug(
            `renderComponent "${annotation.name}" -> value, query, annotation, possibleValues"`,
            value,
            innerQuery,
            annotation,
            possibleValues
          );

          const title = (annotation as unknown as IObjectPicker).title;
          pickerItem = {
            key: `picker-${annotation.name ?? title}`,
            cacheKey: `picker-${annotation.name ?? title}-${cacheKeyHash}`,
            onRender: () => (
              <div className={pickerContainerClass}>
                {renderComponent(
                  annotation,
                  value,
                  (v, annotation, expression) => {
                    const q = pickerValueMap.toQuery(
                      v,
                      annotation as any /*TODO: remove cast*/,
                      innerQuery,
                      expression
                    );
                    log.debug(
                      `picker.setValue "${annotation.name}"-> value, annotation, query, innerQuery, props.query"`,
                      v,
                      annotation,
                      q,
                      innerQuery,
                      props.query
                    );
                    setIsDirty(!isConditionsEqual(props.query.conditions, q.conditions));
                    setInnerQuery(q);
                  },
                  () => {},
                  {
                    intl,
                    getIconAnnotation,
                    getTypeAnnotation,
                    theme,
                    onApply: () => {
                      apply();
                    },
                    onPickerStateChanged: (isOpened) => {
                      log.debug(
                        "picker.onPickerStateChanged -> name, isOpened",
                        annotation.name,
                        isOpened
                      );
                      setIsPickerOpened(isOpened);
                    },
                    targetType: props.schema.targetType || props.targetType,
                    propertyAnnotations: props.propertyAnnotations,
                    possibleValues,
                    innerQuery,
                    queryUrl: props.queryUrl,
                  }
                )}
              </div>
            ),
          };
        }
        result.push(pickerItem);
      }

      setPickers(result);
    }

    generatePickers();
  }, [
    cacheKeyHash,
    pickerAnnotations,
    props.schema,
    props.propertyAnnotations,
    innerQuery,
    props.queryUrl,
    apply,
    setIsDirty,
    setInnerQuery,
    setIsPickerOpened,
  ]);

  const farItems: ICommandBarItemProps[] = [clearFilterBtn];

  const getItems = () => {
    const result = [];
    if (props.schema.showSavedQueries) {
      // result.push(queryList);
      result.push(currentQuery);
      if (!props.readonly) {
        result.push(manageQuery);
      }
    }
    if (props.isSearchSupported) {
      result.push(anrFilter);
    }
    return result.concat(pickers);
  };

  const commandBarItems: ICommandBarItemProps[] = getItems();

  const commandBarStyles: ICommandBarStyles = useMemo(
    () => ({
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        margin: "0 5px",
        background: themeUtils.addAlpha(brandColors.secondaryBackground, 0.7),
        borderRadius: 4,
        height: 40,
        marginBottom: 8,

        selectors: {
          "& > .ms-CommandBar-primaryCommand": { alignItems: "center" },
          ":after": {
            position: "absolute",
            height: 1,
            left: 5,
            right: 5,
            bottom: -8,
            backgroundColor: brandColors.divider,
            content: "''",
          },
        },
      },
    }),
    [brandColors]
  );

  return (
    <>
      <CommandBar
        items={commandBarItems}
        farItems={farItems}
        styles={commandBarStyles}
        overflowButtonProps={{ styles: btnStyle, ariaLabel: "More commands" }}
        style={{
          minWidth: "100%",
          width: 1,
          alignSelf: "stretch",
        }}
      />
      {isDirty && !isPickerOpened && (
        <PrimaryButton
          onClick={apply}
          styles={{ root: { border: 0, flex: "0 0 32px", margin: "0 5px" } }}
        >
          <FormattedMessage id="quick-filter.apply-filter" defaultMessage="Run query" />
        </PrimaryButton>
      )}
    </>
  );
}

const pickerContainerClass = mergeStyles({
  maxWidth: 200,
  selectors: {
    ".ms-Button-textContainer": {
      width: "calc(100% - 20px)",
    },
  },
});

function resolveIcon(mode: GridStateQueryMode) {
  switch (mode) {
    case "normal":
      return "FilterSolid";
    case "preview":
      return "RedEye";
  }
}
