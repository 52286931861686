import { IDropdownOption } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { Entity } from "../../api/cayo-graph";

import { IScope } from "../../api/schema.api";
import { oClient } from "../../libs/odata.client";
import { bindingsUtils } from "../../scheme/bindings";
import { endpoints } from "../../services/endpoints.service";

const useScope = (queryTemplatePath: string, scope?: IScope) => {
  const [scopes, setScopes] = useState<IDropdownOption[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedScopeKey, setSelectedScopeKey] = useState("");

  useEffect(() => {
    if (!scope?.path) {
      setScopes([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    oClient(endpoints.publicUrl)
      .get("v0/" + scope?.path)
      .query()
      .then((response: Entity[]) => {
        setIsLoading(false);
        const loadedScopes = response.map<IDropdownOption>((o) => ({
          key: o.objectPath!,
          text: o.objectName!,
        }));
        setScopes(loadedScopes);

        if (loadedScopes?.length) {
          setSelectedScopeKey(loadedScopes[0].key as string);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [scope?.path]);

  const queryPath = useMemo(() => {
    let model: any = {};

    if (scopes && scopes?.length > 0) {
      model.scope = { objectPath: selectedScopeKey || scopes[0].key };
    }

    const path = model.scope?.objectPath
      ? bindingsUtils.resolveExpression(model, queryTemplatePath!)?.toString()
      : queryTemplatePath;
    return path;
  }, [scopes, selectedScopeKey]);

  return {
    queryPath: scopes ? queryPath : undefined,
    selectedScopeKey,
    setSelectedScopeKey,
    isLoading,
    scopes,
  };
};

export default useScope;
