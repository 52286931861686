import { INavStyleProps, INavStyles, ISearchBoxStyles } from "@fluentui/react";
import { ITheme } from "cayo.ui";

export const navTreeWidth = 300;

export function navTreeStyle(theme: ITheme) {
  return function (navStyleProps: INavStyleProps): Partial<INavStyles> {
    return makeNavStyle(theme, navStyleProps);
  };
}

function calcLeftPadding(value?: number): number | undefined {
  if (typeof value === "undefined") {
    return value;
  }
  const initialPadding = 3;
  const level = (value - initialPadding) / 14;
  return initialPadding + level * 26;
}

function makeNavStyle(theme: ITheme, navStyleProps: INavStyleProps): Partial<INavStyles> {
  const colors = {
    color: theme.cayoTheme.brandColors.secondaryBackground,
    background: theme.cayoTheme.brandColors.thirdBackground,
    highlite: theme.cayoTheme.brandColors.primaryHighlight,
    hoverBg: theme.cayoTheme.brandColors.disabled,
  };

  return {
    root: [
      {
        width: navTreeWidth,
        height: "100%",
        backgroundColor: colors.background,
        flexGrow: 1,
        inset: "0",
        overflowY: "auto",
        overflowX: "hidden",
      },
    ],

    link: [
      {
        color: colors.color,
        borderRadius: 0,
        selectors: {
          ".ms-Nav-compositeLink:hover &": {
            color: colors.color,
            backgroundColor: colors.hoverBg,
            textDecoration: "none",
          },
          ".ms-Button-icon": {
            color: `${colors.color}!important`,
          },
        },
      },
      navStyleProps.isSelected && {
        color: colors.color,
        backgroundColor: colors.highlite,
        selectors: {
          ".ms-Nav-compositeLink:hover &": {
            backgroundColor: colors.highlite,
          },
          ":after": {
            borderLeft: "none",
          },
        },
      },
      !!navStyleProps.leftPadding && {
        paddingLeft: calcLeftPadding(navStyleProps.leftPadding),
      },
    ],
    chevronIcon: {
      color: colors.color,
    },
    chevronButton: [
      { left: `${navTreeWidth - 44}px` },
      navStyleProps.isSelected && {
        backgroundColor: colors.highlite,
        borderColor: "transparent",
        selectors: {
          ":after": { borderLeft: "none" },
        },
      },
    ],
  };
}

export const getSearchBoxStyle = (theme: ITheme): Partial<ISearchBoxStyles> => {
  const { cayoTheme } = theme;

  return {
    root: {
      height: 32,
      padding: "1px 0px 1px 4px",
      backgroundColor: cayoTheme.brandColors.thirdBackground,
      selectors: {
        "input::placeholder": { color: cayoTheme.brandColors.secondaryBackground },
      },
      color: cayoTheme.brandColors.primaryBackground,
    },
    field: {
      color: cayoTheme.brandColors.secondaryBackground,
    },
    icon: {
      color: cayoTheme.brandColors.secondaryBackground,
    },
    clearButton: { color: "white !important" },
  };
};
