import React, { FC, useCallback, useEffect, useId, useRef } from "react";
import useService from "../../services/services.hook";
import { ajaxUtils } from "../../utils/ajax-utils";
import { appSignals } from "../App/utils";
import CollectActionParametersDialog from "../CollectActionParametersDialog";
import { FormProps } from "../Form/types";
import useForceUpdate from "../GlobalHooks/useForceUpdate";

export const FormsHolder: FC = () => {
  const formProps = useRef<Array<FormProps>>([]);
  const { forceUpdate } = useForceUpdate();
  const actionBuilder = useService("actionBuilder");

  const baseFromId = useId();

  useEffect(() => {
    return appSignals.subscribe("showForm", (data) => {
      if (data?.form) {
        formProps.current.push({ ...data.form, actionBuilder });
        forceUpdate();
      }
    });
  }, []);

  const removeFormCb = useCallback((p: FormProps) => {
    const index = formProps.current.indexOf(p);
    formProps.current.splice(index, 1);
    forceUpdate();
  }, []);

  return (
    <>
      {formProps?.current?.map((p, i) => (
        <CollectActionParametersDialog
          key={baseFromId + i}
          {...p}
          onClose={(result) => {
            removeFormCb(p);
            if (result !== undefined) {
              p.onClose && p.onClose(result);
            }
          }}
          onSubmit={
            p.onSubmit
              ? async (result) => {
                  try {
                    var r = await p.onSubmit!(result);
                    removeFormCb(p);
                    return r;
                  } catch (e) {
                    await ajaxUtils.handleError(e);
                  }
                }
              : null
          }
          actionBuilder={actionBuilder}
        />
      ))}
    </>
  );
};
