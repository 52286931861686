import React from "react";
import { commonMessages } from "../../common-messages";
import GridCell from "./GridCell";

import { RenderCellFn } from "./types";

export const renderBooleanCell: RenderCellFn = ({ item, column, auxProps }) => {
  const value = item[column?.fieldName ?? ""];
  const isBool = typeof value === "boolean";
  if (!isBool) {
    return null;
  }
  let strValue = "";
  if (column?.fieldName === "builtIn") {
    strValue =
      auxProps?.intl?.formatMessage(
        value === true ? commonMessages.builtIn : commonMessages.custom
      ) ?? "";
  } else {
    strValue =
      auxProps?.intl?.formatMessage(value === true ? commonMessages.yes : commonMessages.no) ?? "";
  }
  return (
    <GridCell noCopy={true}>
      <div>{strValue}</div>
    </GridCell>
  );
};
