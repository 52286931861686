import { IContextualMenuItem, IColumn as IOfficeColumn, getId } from "@fluentui/react";
import { IntlShape } from "react-intl";
import { Entity, PropertyAnnotation, Severity } from "../../../api/cayo-graph";
import { IColumn, IContentScheme } from "../../../api/schema.api";
import logger from "../../../libs/logger";
import { IActionHandlers } from "../../../scheme/actions/action-handlers";
import { mergeInteractiveItems } from "../../../services/notification.service";
import { QueryColumnsRegex, columnsToHide, gridUtils } from "../../../utils/grid-utils";
import { commandBarMessages } from "../../CommandBar/messages";
import { IShowEditFormProps } from "../../QueryFilterForm/types";
import { detailsListConverters, getSortedGroups } from "../converters";
import { GridNotification } from "../repo/GridNotification";
import { IItemsStore, ISearchResult } from "../repo/IItemsStore";
import { IQueryRepo } from "../repo/IQueryRepo";
import { UserSettingsStore } from "../repo/UserSettingsStore";
import {
  IExpressionCondition,
  IQuery,
  IQueryOrderBy,
  SavedQueryItem,
  assignQuery,
  isConditionsEqual,
  newQuery,
  setOrderBy,
} from "./Query";
import { GridControllerAction } from "./grid.controller.actions";
import { Ploc } from "./ploc";
import { fromSavedQuery, toSavedQuery } from "./savedQuery.portal";

export type GridContainerProps = {
  scheme: IContentScheme;
  propertyAnnotations: PropertyAnnotation[] | undefined;
  gridSettingsKey: string;
  onSelect?: (item: Entity | undefined, submit?: boolean) => void;
};

export interface IGridState {
  id: string;
  loading: boolean;
  columns: Array<IOfficeColumn>;
  ajustColumnsToken: string;
  searchResult: ISearchResult;
  query: IQuery;
  savedQueries: Array<SavedQueryItem>;
  recentQueries: Array<string>;
  favoriteQueries?: Array<string>;
  showQueryPicker: boolean;
  showEditForm?: IShowEditFormProps;
  showChooseColumns: boolean;
  selectedItems: Array<any>;
  hasChanges: boolean;
  gridSettingsKey: string;
  itemMenu?: Array<IContextualMenuItem>;
  queryMode: GridStateQueryMode;
}

export type LoadingType = "submit";

export type UIInteraction = {
  alertError: (message: string, severity?: Severity) => void;
  confirm: (text: string, text2?: string) => Promise<boolean>;
  prompt: (text: string) => Promise<string>;
  actionHandlers: IActionHandlers;
  intl: IntlShape;
  loadingInfo?: boolean | string;
  setLoading: (v: boolean | string | LoadingType) => void;
};

export type CreateNotificationFn = (controller: GridController) => GridNotification;

type ControllerConfig = {
  allColumns: Array<IColumn>;
  readonly initialQueryId?: string;
  readonly defaultGroupBy: string;
  readonly onSelect?: (item: Entity | undefined, submit?: boolean) => void;
};

export class GridController extends Ploc<IGridState> {
  private readonly _logger = logger.getLogger("QUICK-FILTER-NEW GridController");
  notification: GridNotification;
  private _requestExecuting = false;
  private _needAjustColumns: boolean = false;
  private _beforePreview: IQuery | null = null;
  // private readonly schemaColumns: IColumn[];
  private log(...msg: any[]) {
    this._logger.log(...msg);
  }

  constructor(
    private readonly config: ControllerConfig,
    initialState: IGridState,
    private readonly itemsStore: IItemsStore,
    private readonly queryStore: IQueryRepo,
    private readonly userSettingsStore: UserSettingsStore,
    private readonly uiInteraction: UIInteraction,

    createNotification: CreateNotificationFn
  ) {
    super(initialState);
    this.log(".ctor -> initialState", initialState);
    this.notification = createNotification(this);
  }

  public get requrestExecuting() {
    return this._requestExecuting;
  }
  private get defaultColumns(): IColumn[] {
    return this.config.allColumns.filter((c) => (c.position === undefined ? true : c.position > 0));
  }

  private ajustToken(hasItems: boolean): string {
    if (!hasItems) return "";
    if (this._needAjustColumns) {
      this._needAjustColumns = false;
      return getId("ajust-");
    }
    return "";
  }

  public async initialize() {
    this.log("initialize -> start");
    try {
      this.assignState({ loading: true });
      this._requestExecuting = true;
      const savedQueries = await this.queryStore.getList();

      let query = await this.resolveInitialQuery(savedQueries);

      const additionalColumns = this.extractFilterColumns(query);
      const effectiveFilter = await this.queryStore.getEffectiveFilter(query);
      query = assignQuery(query, { effectiveFilter });

      const columns = await this.resolveColumns(query.orderBy, additionalColumns);

      await this.queryStore.getRecentIds();
      const recentQueries = this.queryStore.setQueryCalled(query.id);
      const favoriteQueries = await this.queryStore.getFavoriteIds();
      this.assignState({ query, recentQueries, savedQueries, favoriteQueries, columns });
      const searchResult = await this.execute(query);
      this.assignState({
        searchResult,
        loading: false,
        ajustColumnsToken: this.ajustToken(searchResult.items.length > 0),
      });
    } catch (error) {
      this._logger.error("ERROR", error);
      this.uiInteraction.alertError((error as Error).message);
    } finally {
      this.assignState({ loading: false });
      this._requestExecuting = false;
    }
    this.log("initialize -> end");
  }

  private async resolveInitialQuery(savedQueries: SavedQueryItem[]): Promise<IQuery> {
    const initialQuery = this.state.query;

    const userQuery = this.userSettingsStore.getQuery();
    //try get default query and use it
    if (!userQuery) {
      const defaultQueryId = this.config.initialQueryId ?? getDefaultQueryId(savedQueries);
      const defaultQuery = !!defaultQueryId
        ? await this.queryStore.get(defaultQueryId)
        : initialQuery;
      return defaultQuery;
    }

    //query without id
    if (!userQuery.id) {
      return assignQuery(userQuery, { isTemp: true });
    }

    const baseQueryId = savedQueries.find((q) => q.id === userQuery.id)?.id;

    //try use default query
    if (!baseQueryId) {
      const defaultQueryId = getDefaultQueryId(savedQueries) ?? this.config.initialQueryId;
      const defaultQuery = !!defaultQueryId
        ? await this.queryStore.get(defaultQueryId)
        : initialQuery;
      return defaultQuery;
    }

    const baseQuery = await this.queryStore.get(baseQueryId);
    this.log("resolveInitialQuery -> userQuery, baseQuery", userQuery, baseQuery);
    return assignQuery(baseQuery, {
      isTemp:
        !isConditionsEqual(baseQuery.conditions, userQuery.conditions) ||
        (userQuery.groupBy ?? "") !== this.config.defaultGroupBy,
      isDefault: baseQuery?.isDefault,
      conditions: userQuery.conditions,
      groupBy: userQuery.groupBy,
      orderBy: userQuery.orderBy,
    });
  }

  private async resolveColumns(
    orderBy?: IQueryOrderBy,
    additionalColumns?: Array<IColumn>
  ): Promise<IOfficeColumn[]> {
    let fields: string[];

    let userColumns = (await this.userSettingsStore.getColumns()).filter(
      (c) => !QueryColumnsRegex.test(c)
    );

    this.config.allColumns = this.config.allColumns.filter(
      (c) => !QueryColumnsRegex.test(c.fieldName)
    );

    const userColumnsIsEmpty = userColumns.length === 0;

    if (additionalColumns && additionalColumns.length) {
      userColumns = [
        ...userColumns.filter((c) => !columnsToHide.includes(c)),
        ...(additionalColumns.map((c) => c.fieldName) as string[]),
      ];
      this.config.allColumns.push(...additionalColumns);
    }

    if (userColumnsIsEmpty) {
      // use default columns if column.position === 0 -> hide it
      fields = this.defaultColumns.map((c) => c.fieldName);
    } else {
      fields = userColumns.filter((f) => this.config.allColumns.some((c) => c.fieldName === f));
    }

    // make columns
    const officeColumns = this.sortColumns(
      [this.getColumnField(orderBy?.field ?? ""), orderBy?.direction === "ascending"],
      this.makeColumns(fields)
    );

    return officeColumns;
  }

  private async refresh(hideLoading?: boolean, keepSelection?: boolean) {
    try {
      if (!hideLoading) {
        this.assignState({ loading: true });
      }

      this._requestExecuting = true;
      const searchResult = await this.execute(
        this.state.query,
        null,
        this.state.searchResult.items.length
      );
      this.assignState({
        searchResult,
        loading: false,
        hasChanges: false,
        ajustColumnsToken: this.ajustToken(searchResult.items.length > 0),
        selectedItems: keepSelection ? this.state.selectedItems : [],
      });
    } catch (error) {
      this._logger.error("ERROR", error);
      this.uiInteraction.alertError((error as Error).message);
    } finally {
      this._requestExecuting = false;
      this.assignState({ loading: false });
    }
  }

  private async execute(
    query: IQuery,
    skipToken: string | null = null,
    top: number = 0
  ): Promise<ISearchResult> {
    const effectiveFilter =
      query?.effectiveFilter || (await this.queryStore.getEffectiveFilter(query));
    const selectColumns = this.state.columns.map((c) => c.fieldName!);
    const result = await this.itemsStore.execute(
      query,
      effectiveFilter,
      selectColumns,
      skipToken,
      top
    );
    gridUtils.fixDateTime(result.items);
    if (!!skipToken) {
      //join items with existed
      result.items = this.state.searchResult.items.concat(result.items);
    }
    //group if need
    return this.groupResultItems(query, result);
  }

  private groupResultItems(query: IQuery, searchResult: ISearchResult): ISearchResult {
    if (searchResult.items.length === 0 || !query.groupBy) {
      return searchResult;
    }
    const gr = getSortedGroups(searchResult.items, query.groupBy, undefined);
    return { ...searchResult, items: gr.newResponse, groups: gr.newGroups };
  }

  private async selectQuery(options: {
    queryId?: string;
    reloadSavedList?: boolean;
    useDefaultGroup?: boolean;
    previewQuery?: boolean;
  }) {
    try {
      this.log("selectQuery -> args", options);
      if (!!options.previewQuery && this._beforePreview === null) {
        // save current query
        this._beforePreview = this.state.query;
      } else if (!options.previewQuery) {
        this._beforePreview = null;
      }
      this.assignState({ loading: true });
      this._requestExecuting = true;
      const q = !options.queryId ? newQuery() : await this.queryStore.get(options.queryId);
      const effectiveFilter = await this.queryStore.getEffectiveFilter(q);
      const additionalColumns = this.extractFilterColumns(q);
      const columns = await this.resolveColumns(q.orderBy, additionalColumns);
      const query =
        options.useDefaultGroup === true
          ? assignQuery(q, { effectiveFilter, groupBy: this.config.defaultGroupBy })
          : this.applyQuery(assignQuery(q, { effectiveFilter }));

      if (!options.previewQuery) {
        this.userSettingsStore.saveQuery(query);
      }
      this.assignState({ query, columns, queryMode: !options.previewQuery ? "normal" : "preview" });

      const searchResult = await this.execute(query);
      const newState: Partial<IGridState> = {
        searchResult,
        loading: false,
        hasChanges: false,
        ajustColumnsToken: this.ajustToken(searchResult.items.length > 0),
      };
      if (!options.previewQuery) {
        newState.recentQueries = this.queryStore.setQueryCalled(query.id);
      }
      if (options.reloadSavedList) {
        const savedQueries = await this.queryStore.getList();
        newState.savedQueries = savedQueries;
      }
      this.assignState(newState);
    } catch (error) {
      this._logger.error("ERROR", error);
      this.uiInteraction.alertError((error as Error).message);
    } finally {
      this.assignState({ loading: false });
      this._requestExecuting = false;
    }
  }

  private async restoreQuery() {
    if (this._beforePreview === null) {
      return;
    }
    const query = this._beforePreview;
    this._beforePreview = null;

    this.assignState({ loading: true });
    this._requestExecuting = true;
    try {
      const columns = await this.resolveColumns(query.orderBy, this.extractFilterColumns(query));
      this.assignState({ query, columns });
      const searchResult = await this.execute(query);
      const newState: Partial<IGridState> = {
        searchResult,
        loading: false,
        hasChanges: false,
        ajustColumnsToken: this.ajustToken(searchResult.items.length > 0),
        queryMode: "normal",
      };
      this.assignState(newState);
    } catch (error) {
      this._logger.error("ERROR", error);
      this.uiInteraction.alertError((error as Error).message);
    } finally {
      this.assignState({ loading: false });
      this._requestExecuting = false;
    }
  }

  private async changeQuery(changedQuery: IQuery) {
    try {
      this.assignState({ loading: true });
      this._requestExecuting = true;
      let isTemp = true;
      const effectiveFilter = await this.queryStore.getEffectiveFilter(changedQuery);
      const query = assignQuery(changedQuery, { isTemp, effectiveFilter });

      const additionalColumns = this.extractFilterColumns(query);

      const columns = await this.resolveColumns(query.orderBy, additionalColumns);

      this.userSettingsStore.saveQuery(query);
      this.assignState({ query, columns });

      const recentQueries = this.queryStore.setQueryCalled(query.id);
      const searchResult = await this.execute(query);
      const newState: Partial<IGridState> = {
        searchResult,
        recentQueries,
        loading: false,
        hasChanges: false,
        ajustColumnsToken: this.ajustToken(searchResult.items.length > 0),
      };
      this.assignState(newState);
    } catch (error) {
      this._logger.error("ERROR", error);
      this.uiInteraction.alertError((error as Error).message);
    } finally {
      this.assignState({ loading: false });
      this._requestExecuting = false;
    }
  }

  private async reloadQuery() {
    const current = this.state.query;

    if (!current?.id) {
      return;
    }

    this.assignState({ loading: true });
    try {
      const reload = await this.queryStore.get(current.id!);
      this.log("reloadQuery -> reload ", reload);
      this.assignState({
        query: assignQuery(current, { job: reload.job, alertRule: reload.alertRule }),
      });
    } catch (ex) {
      this.log("reloadQuery -> error", ex);
      // reload saved and recent Queries
      const savedQueries = await this.queryStore.getList();
      const recentQueries = await this.queryStore.getRecentIds();
      this.assignState({ savedQueries, recentQueries });
      this.clearQuery();
    } finally {
      this.assignState({ loading: false });
    }
  }

  public dispatch = (action: GridControllerAction): void | Promise<void> => {
    this.log("dispatch -> action", action);
    switch (action.kind) {
      case "sort":
        this.sortBy(action.orderBy);
        break;
      case "changeQuery":
        this.changeQuery(action.query);
        break;
      case "closeQueryPicker":
        this.assignState({ showQueryPicker: false });
        this.restoreQuery();
        break;
      case "openQueryPicker":
        this.assignState({ showQueryPicker: true });
        break;
      case "openEditForm":
        this.openEditForm(action.query, action.refreshCallback);
        break;
      case "closeEditForm":
        this.assignState({ showEditForm: undefined });
        break;
      case "createNewQuery":
        this.createNewQuery(action.newQuery, action.refreshCallback);
        break;
      case "copyQuery":
        this.copyQuery(action.query);
        break;
      case "deleteQuery":
        this.deleteQuery(action.query, action.onComleted);
        break;
      case "querySaveAs":
        this.querySaveAs(action.query);
        break;
      case "save":
        // do not delete 'return'!
        // TODO: add a callback to action parameters
        return this.saveQuery(action.query);

      case "clearQuery":
        this.clearQuery();
        break;
      case "groupBy":
        this.groupBy(action.fileld);
        break;
      case "changedSelectedItems":
        this.changedSelectedItems(action.items);
        break;
      case "refresh":
        this.refresh(action.hideLoading, action.keepSelection);
        break;
      case "showProps":
      case "showPicker":
        this.showProps(this.state.id, action.item, action.onClose);
        break;
      case "loadMore":
        this.loadMore();
        break;
      case "hasChanges":
        this.hasChanges();
        break;
      case "mergeItems":
        this.mergeItems(action.items);
        break;
      case "selectQuery":
        return this.selectQuery({
          queryId: action.queryId,
          reloadSavedList: action.reloadSavedList,
          previewQuery: action.previewQuery,
        });
      case "reloadQuery":
        return this.reloadQuery();
      case "openChooseColumnsDialog":
        this.assignState({ showChooseColumns: true });
        break;
      case "closeChooseColumnsDialog":
        this.assignState({ showChooseColumns: false });
        break;
      case "applyColumns":
        this.applyColumns(action.columns);
        break;
      case "resetColumns":
        this.resetColumns();
        break;
      case "loading":
        this.assignState({ loading: action.loading });
        break;
      case "setContextMenu":
        this.assignState({ itemMenu: action.menu });
        break;
      case "toggleFavorite":
        this.toggleFavorite(action.id);
    }
  };

  mergeItems(items: any[]) {
    const mergingItems = this.state.searchResult.items;
    const updatedItems = items.filter((i) => !!mergingItems.find((ii) => ii.id === i.id));
    if (updatedItems.length) {
      gridUtils.fixDateTime(updatedItems);
      mergeInteractiveItems(mergingItems, updatedItems);
      this.assignState({ searchResult: { ...this.state.searchResult, items: [...mergingItems] } });
    } else {
      this.log("mergeItems -> skip items update");
    }
  }

  hasChanges() {
    if (this.state.hasChanges === true) return;
    this.assignState({ hasChanges: true });
  }

  async loadMore(): Promise<void> {
    if (!this.state.searchResult.skipToken) {
      return;
    }
    try {
      this._requestExecuting = true;
      this.assignState({ loading: true });
      const searchResult = await this.execute(this.state.query, this.state.searchResult.skipToken);
      this.assignState({ searchResult, loading: false });
    } catch (error) {
      this._logger.error("ERROR", error);
      this.uiInteraction.alertError((error as Error).message);
    } finally {
      this.assignState({ loading: false });
      this._requestExecuting = false;
    }
  }

  changedSelectedItems(items: Array<any>) {
    this.assignState({ selectedItems: items });

    if (this.config.onSelect) {
      this.config.onSelect(items?.length ? items[0] : undefined);
    } else {
      this.uiInteraction.actionHandlers["update.props"](this.state.id, items);
    }
  }

  groupBy(field: string) {
    if (this.state.query.groupBy === field) {
      return;
    }
    this.changeQuery(assignQuery(this.state.query, { groupBy: field }));
  }

  clearQuery() {
    const defaultQueryId = getDefaultQueryId(this.state.savedQueries); //TODO: need optimization
    this.log("clearQuery -> defaultQueryId", defaultQueryId);
    let defaultQuery = this.state.savedQueries.find((q) => q.id === defaultQueryId);
    this.selectQuery({ queryId: defaultQuery?.id, useDefaultGroup: true });
  }

  private async saveQuery(query: IQuery) {
    this.log("saveQuery -> query", query);
    if (query.isSystem || !query.id) {
      return await this.querySaveAs(query);
    }

    const ok = await this.uiInteraction.confirm(
      this.uiInteraction.intl.formatMessage(commandBarMessages.saveNewFilterConfirmation)
    );

    if (!ok) {
      return Promise.reject();
    }

    try {
      this.assignState({ loading: true });
      const updated = await this.queryStore.update(query);
      return this.dispatch({ kind: "selectQuery", queryId: updated.id, reloadSavedList: true });
    } catch (error) {
      this._logger.error("ERROR", error);
      this.uiInteraction.alertError((error as Error).message);
      return Promise.reject();
    } finally {
      this.assignState({ loading: false });
    }
  }

  async querySaveAs(query: IQuery) {
    const name = await this.uiInteraction.prompt(
      this.uiInteraction.intl.formatMessage(commandBarMessages.saveNewFilterConfirmation)
    );
    this.log("Prompt result", name);
    const toAdd = assignQuery(query, { name: name, isSystem: false, id: undefined });
    try {
      this.assignState({ loading: true });
      const newQuery = await this.queryStore.saveNew(toAdd);
      return this.dispatch({ kind: "selectQuery", queryId: newQuery.id, reloadSavedList: true });
    } catch (error) {
      this._logger.error("ERROR", error);
      this.uiInteraction.alertError((error as Error).message);
      return Promise.reject();
    } finally {
      this.assignState({ loading: false });
    }
  }

  private async deleteQuery(queryToRemove: IQuery, onCompleted?: (item?: Entity) => void) {
    const ok = await this.uiInteraction.confirm(
      this.uiInteraction.intl.formatMessage(commandBarMessages.deleteFilterConfirmation, {
        name: queryToRemove.name,
      })
    );
    if (!ok) return;
    try {
      this.assignState({ loading: true });
      await this.queryStore.remove(queryToRemove);

      if (!!onCompleted) {
        onCompleted();
      }

      const savedQueries = await this.queryStore.getList();
      const recentQueries = await this.queryStore.getRecentIds();
      this.assignState({ savedQueries, recentQueries });

      if (queryToRemove.id === this.state.query.id) {
        //current query removed
        this.clearQuery();
      }
    } catch (error) {
      this._logger.error("ERROR", error);
      this.uiInteraction.alertError((error as Error).message);
    } finally {
      this.assignState({ loading: false });
    }
  }

  private copyQuery(query: IQuery) {
    const showEditForm: IShowEditFormProps = {
      type: "new",
      prms: {
        doNotFetch: true,
        saveOnServerOnly: true,
        filter: toSavedQuery(assignQuery(query, { id: undefined, isSystem: false })),
      },
    };
    this.assignState({ showEditForm });
  }

  private createNewQuery(query: IQuery, refreshCallback?: (createdItem?: Entity) => void) {
    const showEditForm: IShowEditFormProps = {
      type: "new",
      prms: {
        doNotFetch: true,
        saveOnServerOnly: true,
        filter: toSavedQuery(query),
        onFilterSaved: (item) => {
          if (refreshCallback) {
            refreshCallback(item);
          }
          if (!item) return;
          this.dispatch({ kind: "selectQuery", queryId: item.id, reloadSavedList: true });
        },
      },
    };
    this.assignState({ showEditForm });
  }

  private async openEditForm(query: IQuery, refreshCallback?: (createdItem?: Entity) => void) {
    if (!query.id) return;
    const isEditCurrentQuery = query.id === this.state.query.id;
    const editQueryId = query.id;

    if (isEditCurrentQuery && this.state.query.isTemp === true) {
      //current query not saved
      const ok = await this.uiInteraction.confirm(
        this.uiInteraction.intl.formatMessage(commandBarMessages.saveNewFilterConfirmation)
      );

      if (ok) {
        try {
          const updated = await this.queryStore.update(query);
          this.dispatch({ kind: "selectQuery", queryId: updated.id, reloadSavedList: true });
        } catch (error) {
          this._logger.error("ERROR", error);
          this.uiInteraction.alertError((error as Error).message);
          return;
        }
      } else {
        return;
      }
    }
    const q = await this.queryStore.get(editQueryId);
    const filter = toSavedQuery(q);

    this.log(
      "openEditForm -> query, ifEditCurrentQuery, filter, needRefresh",
      query,
      isEditCurrentQuery,
      filter,
      !!refreshCallback
    );

    const formProps: IShowEditFormProps = {
      type: "edit",
      prms: {
        doNotFetch: isEditCurrentQuery,
        filter: filter,
        saveOnServerOnly: true,
        onFilterSaved: (item) => {
          this.log("onFilterSaved -> item", item);
          if (refreshCallback) {
            refreshCallback();
          }
          if (!item || !isEditCurrentQuery) return;
          // update current if it edited
          this.dispatch({
            kind: "selectQuery",
            queryId: item.id,
            reloadSavedList: true,
          });
        },
        deleteFilter: (item) => {
          this.log("deleteFilter -> item", item);
          if (!item) return;
          this.dispatch({
            kind: "deleteQuery",
            query: fromSavedQuery(item),
            onComleted: () => {
              this.log("deleteFilter completed");
              this.assignState({ showEditForm: undefined });
              if (refreshCallback) {
                refreshCallback();
              }
            },
          });
        },
      },
    };
    this.assignState({ showEditForm: formProps });
  }

  private sortColumns(
    orderBy: [field: string, asc: boolean] | IQueryOrderBy | undefined,
    columns: Array<IOfficeColumn>
  ): Array<IOfficeColumn> {
    this.log("sortColumns -> orderBy, columns", orderBy, columns);
    let field: string;
    let asc: boolean;
    if (Array.isArray(orderBy)) {
      [field, asc] = orderBy;
    } else if (!orderBy) {
      field = ""; //clear sorting
      asc = true;
    } else {
      field = this.getColumnField(orderBy.field);
      asc = orderBy.direction === "ascending";
    }

    const result = columns.map((c) => {
      if (c.fieldName === field) {
        c.isSortedDescending = !asc;
        c.isSorted = true;
      } else if (c.isSorted) {
        delete c.isSorted;
        delete c.isSortedDescending;
      }
      return c;
    });
    this.log("sortColumns -> result", result);
    return result;
  }

  private getSortingField(field: string): string {
    const column = this.config.allColumns.find((c) => c.fieldName === field);
    return !column ? "" : column.sortingFieldName || column.fieldName;
  }
  private getColumnField(sortingField: string) {
    const column = this.config.allColumns.find(
      (c) => c.sortingFieldName === sortingField || c.fieldName === sortingField
    );
    return !column ? "" : column.fieldName;
  }
  private async sortBy(orderBy: [field: string, asc: boolean]) {
    const columns = this.sortColumns(orderBy, this.state.columns);
    const [field, asc] = orderBy;
    const sortedField = this.getSortingField(field);
    if (!sortedField) {
      return;
    }
    const q = setOrderBy(this.state.query, {
      field: sortedField,
      direction: !asc ? "descending" : "ascending",
    });
    try {
      this._requestExecuting = true;
      this.userSettingsStore.saveQuery(q);
      this.assignState({ loading: true, query: q });
      const searchResult = await this.execute(q);
      this.assignState({ searchResult, columns, loading: false });
    } catch (error) {
      this._logger.error("ERROR", error);
      this.uiInteraction.alertError((error as Error).message);
    } finally {
      this.assignState({ loading: false });
      this._requestExecuting = false;
    }
  }

  private applyQuery(query: IQuery): IQuery {
    return assignQuery(query, {
      groupBy: "groupBy" in query ? query.groupBy : this.state.query.groupBy,
    });
  }

  private showProps(parentId: string, object: Entity, onClose?: (updateItem?: Entity) => void) {
    if (this.config.onSelect) {
      this.config.onSelect(object, true);
    } else {
      this.uiInteraction.actionHandlers["show.props"](object.objectPath!, { parentId, onClose });
    }
  }

  private applyColumns(fields: string[]) {
    this.userSettingsStore.saveColumns(fields);
    this.log("applyColumns -> fields", fields);
    const officeColumns = this.sortColumns(this.state.query.orderBy, this.makeColumns(fields));
    this.log("applyColumns -> columns", officeColumns);
    this.state.columns = officeColumns; // set state.columns without emit event
    this.assignState({
      showChooseColumns: false,
    });
    this.refresh();
  }

  resetColumns() {
    const isDefaultColumns =
      this.defaultColumns.map((c) => c.fieldName).join() ===
      this.state.columns.map((c) => c.fieldName).join();
    if (isDefaultColumns) {
      this._needAjustColumns = true;
      this.assignState({
        ajustColumnsToken: this.ajustToken(this.state.searchResult.items.length > 0),
      });
    } else {
      const fields = this.defaultColumns.map((c) => c.fieldName);
      const columns = this.sortColumns(this.state.query.orderBy, this.makeColumns(fields));

      this.userSettingsStore.saveColumns(columns.map((c) => c.fieldName!));
      this.assignState({ columns });
      this.refresh();
    }
  }

  async toggleFavorite(id: string) {
    if (this.state.favoriteQueries === undefined) {
      return;
    }
    const toSave = this.state.favoriteQueries.includes(id)
      ? this.state.favoriteQueries.filter((v) => v !== id)
      : this.state.favoriteQueries.concat([id]);
    const favorites = await this.queryStore.setFavoriteIds(toSave);

    this.assignState({ favoriteQueries: favorites });
  }

  private makeColumns(fields: string[]): IOfficeColumn[] {
    const schemaColumns = fields
      .map((f) => this.config.allColumns.find((c) => c.fieldName === f))
      .filter((c) => !!c);
    this.log("makeColumns -> fields", schemaColumns);

    const officeColumns = detailsListConverters.convertToOfficeColumns(schemaColumns as any);
    //TODO: refactor it
    const storedColumns = gridUtils.getStoredColumns(this.state.gridSettingsKey);
    if (storedColumns.length === 0 || storedColumns.some((c) => !c.width)) {
      this._needAjustColumns = true;
    }
    officeColumns.forEach((c) => {
      if (c.minWidth) {
        c.currentWidth = c.calculatedWidth = c.minWidth;
      }
    });
    gridUtils.restoreGridColumns(this.state.gridSettingsKey, officeColumns);
    return officeColumns;
  }

  private extractFilterColumns(query: IQuery): Array<IColumn> {
    const extractColumnsFromOperands = (operands: any[]): Array<IColumn> => {
      let allColumns: Array<IColumn> = [];
      for (const operand of operands) {
        if (operand?.metadata?.columns) {
          const columns = JSON.parse(operand?.metadata?.columns);
          allColumns.push(...columns);
        }
        if (operand.operands && operand.operands.length > 0) {
          const nestedColumns = extractColumnsFromOperands(operand.operands);
          allColumns.push(...nestedColumns);
        }
      }
      return allColumns;
    };

    const expression = query?.conditions?.find(
      (c) => c.kind === "expression"
    ) as IExpressionCondition;
    if (expression && expression.operands && expression.operands.length > 0) {
      const allColumns = extractColumnsFromOperands(expression.operands);

      const uniqueColumns: IColumn[] = [];
      const uniqueDisplayNames: Set<string> = new Set();
      for (const column of allColumns) {
        if (!uniqueDisplayNames.has(column.displayName!)) {
          uniqueDisplayNames.add(column.displayName!);
          uniqueColumns.push(column);
        }
      }

      return uniqueColumns;
    }
    return [];
  }

  dispose(): void {
    this.log("dispose");
    this.notification.dispose();
    super.dispose();
  }
}

function getDefaultQueryId(savedQueries: SavedQueryItem[]): string | undefined {
  return savedQueries.length === 0 ? undefined : savedQueries.find((q) => q.default === true)?.id;
}
