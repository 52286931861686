import {
  Checkbox,
  CommandButton,
  Dropdown,
  IDropdownOption,
  IconButton,
  TextField,
} from "@fluentui/react";
import { DateTimeBox } from "cayo.ui";
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import { ComparisonOperator } from "../../api/cayo-graph";
import {
  ExpressionGroupCombinationType,
  ExpressionLogicType,
  IExpressionCondition,
  OdataQueryOperandType,
  PropertyOperandType,
} from "../GridContainer/grid-model/Query";
import { isConditionRow } from "./ConditionBuilder";
import { ConditionGroupProps, ConditionRowProps, ODataQueryRowProps } from "./types";
import useConditionBuilder, {
  ObjectTypeOptionData,
  SupportedObjectTypeOptionTypes,
} from "./useConditionBuilder";

export const ConditionRow = <IExpressionCondition extends FieldValues>({
  name,
  propertyName,
  valueName,
  propertyObjectTypeName,
  enabledName,
  enabledGroupName,
  columnsOptions,
  objectTypeOptions,
  getObjectTypePropertyOptions,
  columns,
  deleteRow,
  deleteDisabled,
}: ConditionRowProps<IExpressionCondition>) => {
  const [valueOptions, setValueOptions] = useState<IDropdownOption[]>([]);
  const [propertyOptions, setPropertyOptions] = useState<IDropdownOption<ObjectTypeOptionData>[]>(
    []
  );

  const { control, setValue } = useFormContext<IExpressionCondition>();
  const propertyValue = useWatch({ control, name: propertyName });
  const value = useWatch({ control, name: valueName });
  const enabled = useWatch({ control, name: enabledName });
  const enabledGroup = useWatch({ control, name: enabledGroupName || enabledName });
  const propertyObjectType = useWatch({ control, name: propertyObjectTypeName });

  const { getColumnOptions } = useConditionBuilder();

  const filteredOperatorsOptions = useMemo(() => {
    if (propertyOptions.length > 0 && propertyValue) {
      const selectedObjectTypeProperty = propertyOptions.find((o) => o.key === propertyValue);
      const allowedList = selectedObjectTypeProperty?.data
        ?.allowedOperators as ComparisonOperator[];
      return ConditionToSelectOptionsMock.filter((o) =>
        allowedList.includes(o.key as ComparisonOperator)
      );
    }
    return ConditionToSelectOptionsMock;
  }, [propertyValue]);

  const handleValueChange = (newValue: any) => {
    setValue(valueName, newValue);
  };

  const valueField = useMemo(() => {
    const selectedObjectTypeProperty = propertyOptions.find((o) => o.key === propertyValue);

    if (objectTypeOptions?.length >= 1 && selectedObjectTypeProperty) {
      if (selectedObjectTypeProperty.data?.type === SupportedObjectTypeOptionTypes.BOOLEAN) {
        return (
          <Dropdown
            options={ConditionToSelectOptionsBoolean}
            onChange={(event, option) => {
              handleValueChange(option?.key);
            }}
            disabled={!enabled}
            selectedKey={value}
            styles={{
              dropdown: { minWidth: "250px", maxWidth: "300px" },
              callout: { minWidth: "300px", maxHeight: "350px" },
            }}
            label="Value"
          />
        );
      } else if (
        selectedObjectTypeProperty.data?.type === SupportedObjectTypeOptionTypes.DATETIMEOFFSET
      ) {
        return (
          <DateTimeBox
            value={value || undefined}
            setValue={(v) => {
              handleValueChange(v?.toISOString());
            }}
            label="Value"
            disabled={!enabled}
          />
        );
      } else {
        return (
          <TextField
            disabled={!enabled}
            onChange={(event, newValue) => {
              handleValueChange(newValue);
            }}
            value={value}
            styles={{
              field: { minWidth: "250px", maxWidth: "300px" },
            }}
            label="Value"
          />
        );
      }
    }
    if (valueOptions.length > 0) {
      return (
        <Dropdown
          options={valueOptions}
          onChange={(event, option) => {
            handleValueChange(option?.key);
          }}
          disabled={!enabled}
          selectedKey={value}
          styles={{
            dropdown: { minWidth: "250px", maxWidth: "300px" },
            callout: { minWidth: "300px", maxHeight: "350px" },
          }}
          label="Value"
        />
      );
    } else {
      return (
        <TextField
          disabled={!enabled}
          onChange={(event, newValue) => {
            handleValueChange(newValue);
          }}
          value={value}
          styles={{
            field: { minWidth: "250px", maxWidth: "300px" },
          }}
          label="Value"
        />
      );
    }
  }, [propertyOptions, objectTypeOptions, valueOptions, value, propertyValue, enabled]);

  useEffect(() => {
    if (objectTypeOptions?.length === 0) {
      getColumnOptions(propertyValue, columns).then((options) => {
        setValueOptions(options);
      });
    }
  }, [propertyValue]);

  useEffect(() => {
    propertyObjectType &&
      getObjectTypePropertyOptions(propertyObjectType.id).then((options) => {
        setPropertyOptions(options);
      });
  }, [propertyObjectType]);

  useEffect(() => {
    if (objectTypeOptions?.length === 1 && propertyObjectTypeName) {
      setValue(propertyObjectTypeName, { id: objectTypeOptions[0].data } as PathValue<
        IExpressionCondition,
        Path<IExpressionCondition>
      >);
    }
  }, [objectTypeOptions]);

  useEffect(() => {
    enabledGroupName && setValue(enabledName, enabledGroup);
  }, [enabledGroup]);

  return (
    <ConditionRowContainer>
      <ConditionEnableControlContainer>
        <Controller
          name={`${name}.enabled`}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              onChange={(e, checked) => {
                onChange(checked);
              }}
            />
          )}
        />
      </ConditionEnableControlContainer>
      {objectTypeOptions?.length > 1 && (
        <Controller
          name={`${name}.propertyObjectType`}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              options={objectTypeOptions}
              onChange={(event, option) => {
                onChange({ key: option?.key, id: option?.data });
              }}
              disabled={!enabled}
              selectedKey={value.key}
              styles={{
                dropdown: { minWidth: "200px", maxWidth: "240px" },
                callout: { minWidth: "260px" },
              }}
              label="Object Type"
            />
          )}
        />
      )}
      <Controller
        name={`${name}.property`}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            options={objectTypeOptions?.length >= 1 ? propertyOptions : columnsOptions}
            onChange={(event, option) => {
              onChange(option?.key);
            }}
            disabled={!enabled}
            selectedKey={value}
            styles={{
              dropdown: { minWidth: "220px", maxWidth: "260px" },
              callout: { minWidth: "250px" },
            }}
            label="Property"
          />
        )}
      />
      <Controller
        name={`${name}.operator`}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            options={filteredOperatorsOptions}
            onChange={(event, option) => {
              onChange(option?.key);
            }}
            disabled={!enabled}
            selectedKey={value}
            styles={{
              dropdown: { minWidth: "90px", maxWidth: "150px" },
              callout: { minWidth: "150px" },
            }}
            label="Operator"
          />
        )}
      />
      <Controller name={`${name}.value`} render={() => valueField} />
      <ConditionDeleteControlContainer>
        <IconButton
          iconProps={{ iconName: "Delete" }}
          title="Delete row"
          ariaLabel="Delete"
          disabled={deleteDisabled}
          onClick={deleteRow}
        />
      </ConditionDeleteControlContainer>
    </ConditionRowContainer>
  );
};

export const ConditionGroup: FC<ConditionGroupProps> = ({
  deleteGroup,
  deleteDisabled,
  index,
  columnsOptions,
  objectTypeOptions,
  getObjectTypePropertyOptions,
  columns,
}) => {
  const { control } = useFormContext<IExpressionCondition>();
  const { fields, append, remove } = useFieldArray({ control, name: `operands.${index}.operands` });

  return (
    <ConditionGroupContainer>
      <ConditionGroupHeaderContainer>
        <ConditionGroupHeaderControlGroup>
          <Controller
            name={`operands.${index}.enabled`}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value}
                onChange={(e, checked) => {
                  onChange(checked);
                }}
              />
            )}
          />
          <Controller
            name={`operands.${index}.operator`}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                options={GroupCombinationsOptionsMock}
                onChange={onChange}
                selectedKey={value}
                styles={{ dropdown: { width: "70px" } }}
              />
            )}
          />
        </ConditionGroupHeaderControlGroup>
        <IconButton
          iconProps={{ iconName: "Delete" }}
          title="Delete group"
          ariaLabel="Delete"
          onClick={deleteGroup}
          disabled={deleteDisabled}
        />
      </ConditionGroupHeaderContainer>
      <ConditionGroupBodyContainer>
        {fields.map((field, i) => {
          if (isConditionRow(fields[i])) {
            return (
              <ConditionRow
                key={field.id}
                name={`operands.${index}.operands.${i}`}
                propertyName={`operands.${index}.operands.${i}.property`}
                valueName={`operands.${index}.operands.${i}.value`}
                enabledName={`operands.${index}.operands.${i}.enabled`}
                propertyObjectTypeName={`operands.${index}.operands.${i}.propertyObjectType`}
                enabledGroupName={`operands.${index}.enabled`}
                getObjectTypePropertyOptions={getObjectTypePropertyOptions}
                columns={columns}
                columnsOptions={columnsOptions}
                objectTypeOptions={objectTypeOptions}
                index={i}
                deleteRow={() => remove(i)}
                deleteDisabled={fields.length === 1}
              />
            );
          } else {
            return (
              <ODataQueryRow
                key={field.id}
                name={`operands.${index}.operands.${i}`}
                filterName={`operands.${index}.operands.${i}.filter`}
                enabledName={`operands.${index}.operands.${i}.enabled`}
                index={i}
                deleteRow={() => remove(index)}
                deleteDisabled={fields.length === 1}
              />
            );
          }
        })}
        <CommandButton
          menuProps={{
            items: [
              {
                key: "addRow",
                text: "Add Row",
                iconProps: { iconName: "AddTo" },
                onClick: () =>
                  append({
                    objectType: ExpressionLogicType.PropertyExpression,
                    property: "",
                    value: "",
                    operator: "contains",
                    propertyObjectType: {
                      id: "",
                      key: "",
                      type: "",
                    },
                    enabled: true,
                  } as PropertyOperandType),
              },
              {
                key: "addExpression",
                text: "Add Expression",
                iconProps: { iconName: "Code" },
                onClick: () =>
                  append({
                    objectType: ExpressionLogicType.OdataQueryExpression,
                    filter: "",
                    enabled: true,
                  } as OdataQueryOperandType),
              },
            ],
            shouldFocusOnMount: false,
          }}
          text="Add"
          iconProps={{ iconName: "Add" }}
        />
      </ConditionGroupBodyContainer>
    </ConditionGroupContainer>
  );
};

export const ODataQueryRow = <IExpressionCondition extends FieldValues>({
  name,
  enabledName,
  enabledGroupName,
  deleteRow,
  deleteDisabled,
}: ODataQueryRowProps<IExpressionCondition>) => {
  const { control, setValue } = useFormContext<IExpressionCondition>();
  const enabled = useWatch({ control, name: enabledName });
  const enabledGroup = useWatch({ control, name: enabledGroupName || enabledName });

  useEffect(() => {
    enabledGroupName && setValue(enabledName, enabledGroup);
  }, [enabledGroup]);

  return (
    <ConditionRowContainer>
      <Controller
        name={`${name}.enabled`}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            checked={value}
            onChange={(e, checked) => {
              onChange(checked);
            }}
          />
        )}
      />
      <Controller
        name={`${name}.filter`}
        render={({ field: { onChange, value } }) => (
          <TextField
            disabled={!enabled}
            onChange={(event, newValue) => {
              onChange(newValue);
            }}
            placeholder="name eq 'any"
            value={value}
            styles={{
              field: { minWidth: "400px", maxWidth: "600px" },
            }}
          />
        )}
      />
      <IconButton
        iconProps={{ iconName: "Delete" }}
        title="Delete expression"
        ariaLabel="Delete"
        onClick={deleteRow}
        disabled={deleteDisabled}
      />
    </ConditionRowContainer>
  );
};

const ConditionRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;
  padding-left: 5px;
  min-height: 66px;
`;

const ConditionEnableControlContainer = styled.div`
  display: flex;
  align-self: end;
  margin-bottom: 12px;
`;

const ConditionDeleteControlContainer = styled.div`
  display: flex;
  align-self: end;
  margin-bottom: 6px;
`;

const ConditionGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #f2f2f2;
`;

const ConditionGroupBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 25px;
  gap: 15px;
`;

const ConditionGroupHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0px 0px 5px;
`;

const ConditionGroupHeaderControlGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const MapConditionToText: Record<ComparisonOperator, string> = {
  equals: "is equal to",
  notEquals: "is not equal to",
  contains: "contains",
  noContains: "not containing",
  present: "present",
  notPresent: "not presenting",
  greaterThan: "greater than",
  greaterThanOrEqual: "greater than or equal to",
  lessThan: "less than",
  lessThanOrEqual: "less than or equal to",
  startsWith: "starts with",
  endsWith: "ends with",
  notContains: "not contains",
};

const ConditionToSelectOptionsMock: IDropdownOption[] = Object.keys(MapConditionToText).map(
  (v) => ({
    key: v,
    text: MapConditionToText[v],
  })
);

const ConditionToSelectOptionsBoolean: IDropdownOption[] = [
  { key: "true", text: "True" },
  { key: "false", text: "False" },
];

export const GroupCombinationsOptionsMock: IDropdownOption[] = [
  {
    key: ExpressionGroupCombinationType.AND,
    text: "And",
  },
  { key: ExpressionGroupCombinationType.OR, text: "Or" },
];
