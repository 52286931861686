import useHover from "./mouse-hover.hook";
import { useTeachingBubbles } from "./teachingBubblesHook";
import useInterval from "./timer.hook";
import useUIInteraction from "./ui-interaction.hook";
import usePreventRequestDuplication from "./use-prevent-qequest-duplication.hook";
import useUnmounted from "./use-unmounted.hook";
import useForceUpdate from "./useForceUpdate";
import { useUserSettings } from "./userSettingsHook";

const globalHooks = {
  useUserSettings,
  useTeachingBubbles,
  useHover,
  usePreventRequestDuplication,
  useInterval,
  useUIInteraction,
  useUnmounted,
  useForceUpdate,
};

export default globalHooks;
