import { IButtonProps, Link } from "@fluentui/react";
import { Label, useTheme } from "cayo.ui";
import React, { FC } from "react";
import { Entity } from "../../../api/cayo-graph";
import { IFormField } from "../../../api/schema.api";
import clipboardUtils from "../../../utils/clipboard-utils";
import { Row } from "../../CommonLayout";
import Icon from "../../Graphics/Icon";
import { ObjectLinkContainer } from "./styles";

const ResolvedObjectLink: FC<
  IFormField & {
    navObject: Entity;
    objectName: string;
    objIcon?: string;
    onClick?: () => void;
    commands?: IButtonProps[];
  }
> = (props) => {
  const { officeTheme } = useTheme();

  const { navObject, objectName, objIcon, onClick, commands } = props;

  return (
    <ObjectLinkContainer>
      <Label
        label={props.label}
        tooltip={props.tooltip}
        onCopy={
          !!navObject.objectName
            ? () => clipboardUtils.copyToClipboard(navObject.objectName ?? "")
            : undefined
        }
        commands={commands}
      />

      <Row valign={true}>
        {objIcon && <Icon iconName={objIcon} style={{ marginRight: 4 }} />}

        <Link
          onClick={onClick}
          styles={{
            root: {
              display: "block",
              alignItems: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            },
          }}
        >
          {objectName}
        </Link>
        <Icon
          iconName={"NavigateExternalInline"}
          style={{
            paddingLeft: 2,
            fontSize: 13,
            color: officeTheme.semanticColors.link,
          }}
        />
      </Row>
    </ObjectLinkContainer>
  );
};

export default ResolvedObjectLink;
