import { Separator, Stack, Text } from "@fluentui/react";
import React, { FC, Fragment, useMemo } from "react";
import { useIntl } from "react-intl";
import { ICounterSeries } from "../../api/schema.api";
import { commonMessages } from "../common-messages";
import Counter from "./Counter";

type CountersProps = ICounterSeries & { data?: any[] };

const CounterSeries: FC<CountersProps> = ({ nameKey, valueKey, dataKeys, data, colors }) => {
  const intl = useIntl();

  const actualData = useMemo(() => {
    if (!data?.length) {
      return new Array(dataKeys!.length).fill(0);
    }
    return data;
  }, [data, dataKeys!.length]);

  const total = useMemo(() => {
    if (!actualData?.length || !valueKey) {
      return 0;
    }
    const result = data?.length ? actualData.map((d) => d[valueKey]).reduce((a, b) => a + b, 0) : 0;
    return result;
  }, [actualData, dataKeys]);

  return (
    <Stack
      horizontal={true}
      horizontalAlign="space-evenly"
      verticalFill={true}
      verticalAlign="center"
      grow={true}
      style={{ overflowX: "auto", marginLeft: 2 }}
    >
      {Array.isArray(actualData) && actualData.length ? (
        <>
          {dataKeys?.map((k, i) => {
            const counter = actualData.find((d) => d[nameKey] === k);
            const name = k;
            const value = counter ? counter[valueKey!] ?? 0 : 0;

            return (
              <Fragment key={i}>
                <Counter
                  label={name}
                  //labelColor={c?.dataColor}
                  linearIndicator={{ color: colors![i] }}
                  data={value ?? 0}
                />
                {/* {i !== dataKeys.length - 1 && <Separator />} */}
              </Fragment>
            );
          })}
          <Separator vertical={true} />
          <Counter
            horizontalAlign="center"
            label={"Total"}
            //labelColor={c?.dataColor}
            data={total}
          />
        </>
      ) : (
        <Text>{intl.formatMessage(commonMessages.noDataAvailable)}</Text>
      )}
    </Stack>
  );
};

export default CounterSeries;
