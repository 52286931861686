import { useCallback, useMemo, useState } from "react";

const genKey = (id: string, time: number) => id + time;

const useRefreshKey = (id: string) => {
  const [updateTime, setUpdateTime] = useState(new Date());

  const key = useMemo(() => {
    return genKey(id, updateTime.getTime());
  }, [updateTime]);

  const forceRefresh = useCallback(() => {
    setUpdateTime(new Date());
  }, []);

  return {
    key,
    updateTime,
    forceRefresh,
  };
};

export default useRefreshKey;
