import { useCallback, useState } from "react";
import { singletonHook } from "react-singleton-hook";
import { IAlertItem } from "./alerts.api";

const _useInitialConfigAlert = () => {
  const [alert, setAlert] = useState<IAlertItem | undefined>(undefined);

  const showInitialConfigAlert = useCallback(
    (onActionClicked: () => void) => {
      setAlert({
        autoHide: false,
        id: "initial-configure",
        severity: "warning",
        message: "",
        disappeared: false,
        lastModifiedDateTime: new Date(),
        status: "new",
        onActionClicked,
      });
    },
    [setAlert]
  );

  const hideInitialConfigAlert = useCallback(() => {
    setAlert(undefined);
  }, [setAlert]);

  return {
    intialConfigAlert: alert,
    showInitialConfigAlert,
    hideInitialConfigAlert,
  };
};

export const useInitialConfigAlert = singletonHook(
  {
    intialConfigAlert: undefined,
    showInitialConfigAlert: () => null,
    hideInitialConfigAlert: () => null,
  },
  _useInitialConfigAlert
);
