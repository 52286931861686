import { DefaultButton, IButtonProps, Icon, Link } from "@fluentui/react";
import { useTheme } from "cayo.ui";
import React, { CSSProperties, FC } from "react";
import styled from "styled-components";

const ConfigureButton: FC<
  {
    icon: string;
    text: string;
    onClick: () => void;
    completed?: boolean;
    width: number;
    helpLink: string;
    style?: CSSProperties;
  } & IButtonProps
> = ({ icon, text, onClick, width, style, helpLink, disabled }) => {
  const { cayoTheme } = useTheme();
  return (
    <div style={{ position: "relative" }}>
      <LargeButton
        toggle={true}
        onClick={onClick}
        disabled={disabled}
        style={{ position: "relative", width, height: width, ...style }}
        styles={{
          flexContainer: { flexFlow: "column", justifyContent: "space-evenly" },
          textContainer: { flexGrow: 0 },
          rootHovered: { color: cayoTheme.brandColors.primaryHighlight },
        }}
        // iconProps={{
        //   iconName: icon,

        //   style: {
        //     fontSize: 56,
        //     // marginBottom: 20,
        //     //   color: hovered ? cayoTheme.brandColors.primaryHighlight : undefined,
        //   },
        // }}
      >
        <Icon iconName={icon} styles={{ root: { fontSize: 56 } }} />
        <span style={{ whiteSpace: "pre-line" }}>{text}</span>
      </LargeButton>
      {helpLink && (
        <Link
          target="_blank"
          href={helpLink}
          onMouseDown={(e) => e.stopPropagation()}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Icon
            iconName="Unknown"
            style={{
              fontSize: 16,

              color: cayoTheme.brandColors.disabled,
            }}
          ></Icon>
        </Link>
      )}
    </div>
  );
};

const LargeButton = styled(DefaultButton)`
  // white-space: nowrap;
  font-size: 12px;
  padding: 0;
`;

export default ConfigureButton;
