import logger from "../../libs/logger";
import { urlUtils } from "../../utils/url-utils";

const redirectParameterName = "redirectUrl";

const log = logger.getLogger("redirect-utils");

const getLoginUrl = () => {
  return `/login?${redirectParameterName}=` + urlUtils.trim(window.location.hash);
};

const getIdToken = (url: string) => {
  const parts = url.split("id_token=");
  if (parts.length > 1) {
    return parts[1].split("&")[0];
  }

  return undefined;
};

const getRelativeRedirectUrl = () => {
  const redirectUrl = getRedirectUrl();
  if (redirectUrl?.startsWith("http://") || redirectUrl?.startsWith("https://")) {
    return new URL(redirectUrl).hash;
  }

  return redirectUrl;
};

const getRedirectUrl = () => {
  const pos = window.location.hash.indexOf("?");
  const queryParams = new URLSearchParams(window.location.hash.substring(pos));
  const redirectUrl = queryParams.get(redirectParameterName);
  log.log("redirectUrl: " + redirectUrl);

  return queryParams.get(redirectParameterName) || "/";
};

const redirectUtils = {
  getLoginUrl,
  redirectParameterName,
  getRelativeRedirectUrl,
  getRedirectUrl,
  getIdToken,
};

export default redirectUtils;
