import { DirectionalHint, getId, ICalloutContentStyles, Icon, Text } from "@fluentui/react";
import { debounce, useComponentLogger, useTheme } from "cayo.ui";
import React, { FC, useContext, useEffect, useMemo, useReducer, useState } from "react";
import {
  NotificationAction,
  updateInteractiveItemsReducer,
} from "../../../services/notification.service";
import AppContext, { RightPanelType } from "../../App/app.context";
import { animationEffects } from "../../Common/Effects";
import { Row } from "../../CommonLayout";
import RiskIndicator from "../../Graphics/RiskIndicator";
import { alertDispatcher } from "../AlertDispatcher";
import { IAlertItem, IInteractiveItem } from "../alerts.api";
import { NewAlertCallout, NewAlertItemContainer } from "../Alerts.styles";
import { FirstAlertButtonId } from "../constants";
import hightlightedAlerts from "../hightlighted-alerts";
import alertUtils from "../utils";
import {
  AnimationContainer,
  ClearIconButton,
  IconWrapper,
  StyledColumn,
  StyledRow,
} from "./styles";

const NewAlertsPopup: FC = () => {
  const log = useComponentLogger(NewAlertsPopup);
  const { getColor, getIcon } = useTheme();
  const descriptionId: string = getId("description");

  const [alerts, dispatchAlerts] = useReducer(updateInteractiveItemsReducer, [] as IAlertItem[]);

  const [isMouseOver, setIsMouseOver] = useState(false);
  const { showRightPanel } = useContext(AppContext);

  // subscribe to new alerts
  useEffect(() => {
    return alertDispatcher.subscribe("popupAlerts", (data) => {
      if (!data) {
        dispatchAlerts({ newItems: [] });
        hightlightedAlerts.setLastErrorAlert(undefined);
        return;
      }

      if (
        (data?.action === NotificationAction.Remove ||
          data?.action === NotificationAction.Replace) &&
        data?.newItems?.find((i) => hightlightedAlerts.isLastErrorAlert(i as IAlertItem))
      ) {
        hightlightedAlerts.setLastErrorAlert(undefined);
      }
      dispatchAlerts({ ...data!, addNewToTail: true, noSort: true });
    });
  }, []);

  // get an alert to display
  const alert = useMemo(() => {
    const currentAlert = alerts.length && alerts[0];
    if (!currentAlert) {
      log.debug("not alerts to display");
      return undefined;
    }

    log.debug("alert is being displayed", currentAlert);

    return currentAlert as IAlertItem;
  }, [alerts?.length]);

  const isRisk = false; //alert?.objectType === "cayo.graph.riskAlert";

  useEffect(() => {
    let lastAlert = alerts.length ? (alerts[0] as IAlertItem) : undefined;

    const isErrorAlert =
      lastAlert?.severity === "high" ||
      lastAlert?.severity === "error" ||
      lastAlert?.severity === "warning";

    if (isErrorAlert && lastAlert) {
      lastAlert.disappeared = false;
      log.debug("last error alert", lastAlert);
      hightlightedAlerts.setLastErrorAlert(lastAlert);
    }

    if (alert && (alerts.length > 1 || (alerts.length === 1 && !isErrorAlert))) {
      log.debug("alert is being disappeared", lastAlert);

      debounce(() => {
        if (!isMouseOver) {
          log.debug("alert has disappeared", lastAlert);
          dispatchAlerts({
            action: NotificationAction.Remove,
            newItems: [lastAlert as IInteractiveItem],
          });
        } else {
          log.debug("skip alert disappearing", lastAlert);
        }
      }, showPopupTime)();
    }
  }, [alert, alerts?.length, isMouseOver]);

  useEffect(() => {
    if (alerts.length === 0 && hightlightedAlerts.lastErrorAlert) {
      dispatchAlerts({
        action: NotificationAction.Add,
        newItems: [hightlightedAlerts.lastErrorAlert],
        addNewToTail: true,
      });
      hightlightedAlerts.setLastErrorAlert(undefined);
    }
  }, [alerts.length]);

  const icon = useMemo(() => {
    const alertType = alert && alertUtils.getAlertType(alert);
    switch (alertType) {
      case "systemAlerts":
        return "Ringer";
      case "riskAssessment":
        return "LightningBolt";
      default:
        return "Warning";
    }
  }, [alert]);

  return (
    <>
      {alert && (
        <NewAlertCallout
          target={"#" + FirstAlertButtonId}
          setInitialFocus={false}
          dismissOnTargetClick={false}
          directionalHint={DirectionalHint.leftCenter}
          // onDismiss={() => setNewAlertsData(undefined)}
          ariaDescribedBy={descriptionId}
          role="alertdialog"
          styles={getStyles}
          onClick={() => {
            showRightPanel(alertUtils.getAlertType(alert) as RightPanelType);
            hightlightedAlerts.add(alert);

            debounce(() => {
              hightlightedAlerts.resetClickedItem();
            }, 2000)();
          }}
        >
          <NewAlertItemContainer
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
          >
            <IconWrapper valign={true} nostretch={true} justifyContent={"center"}>
              <Icon iconName={icon} />
            </IconWrapper>
            <AnimationContainer>
              <animationEffects.slideInUp key={alert?.id}>
                <StyledRow valign={true} key={alert.id}>
                  {isRisk ? (
                    <RiskIndicator severity={alert?.severity} style={{ marginTop: 2 }} />
                  ) : (
                    <Icon
                      styles={{ root: { color: getColor(alert.severity, "severity") } }}
                      iconName={getIcon(alert.severity, "severity")}
                    />
                  )}
                  <StyledColumn>
                    <Row valign={true} nostretch={false}>
                      <Text
                        variant="xSmall"
                        block={true}
                        nowrap={true}
                        styles={{
                          root: {
                            flexGrow: 1,
                            maxHeight: 46,
                            maxWidth: 200,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "break-spaces",
                          },
                        }}
                      >
                        {alert.subject}
                      </Text>
                    </Row>
                  </StyledColumn>
                </StyledRow>
              </animationEffects.slideInUp>
            </AnimationContainer>
            <ClearIconButton
              styles={{ icon: { fontSize: "10px" } }}
              iconProps={{ iconName: "Clear" }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                hightlightedAlerts.setLastErrorAlert(undefined);
                dispatchAlerts({ action: NotificationAction.Remove, newItems: [alerts[0]] });
              }}
              isMouseOver={isMouseOver}
            />
          </NewAlertItemContainer>
        </NewAlertCallout>
      )}
    </>
  );
};

const getStyles = (): Partial<ICalloutContentStyles> => {
  return {
    root: {
      width: 300,
      maxWidth: 400,
      top: "4px !important",
      zIndex: "10000 !important",
    },
    calloutMain: { overflow: "hidden" },
  };
};

const showPopupTime = 5000;

export default NewAlertsPopup;
