import { ReportingJob, RunQueryActionConfig, SavedQuery } from "../api/cayo-graph";
import { resolveNodePath } from "../components/Nav/nav-hooks";

const findTarget = (job: ReportingJob, path: string, linkType: string) => {
  const objectPath = resolveNodePath(path);
  return job.actions?.find((a) => {
    const rqa = a as RunQueryActionConfig;
    return rqa?.target?.linkType === linkType && rqa?.target?.objectPath === objectPath;
  });
};

const findReportingJob = (query: SavedQuery, path: string, linkType: string) => {
  return query.reportingJobs && query.reportingJobs.find((r) => findTarget(r, path, linkType));
};

const findAlertingRule = (query: SavedQuery, path: string) => {
  const objectPath = resolveNodePath(path);
  return query.alertRules && query.alertRules.find((r) => r.path === objectPath);
};

const reportingUtils = {
  findReportingJob,
  findAlertingRule,
};

export default reportingUtils;
