import { IWizardCommonProps } from "cayo.ui.wizards";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";

import { LoadingOverlay, useComponentLogger } from "cayo.ui";
import { NetworkSettings } from "../../api/cayo-graph";
import { settingsEndpoints } from "../../api/settings-endpoints";
import ajax from "../../libs/ajax";
import actionHandlers from "../../scheme/actions/action-handlers";
import useUIInteraction from "../GlobalHooks/ui-interaction.hook";
import { clientWizardsRenderers } from "../Schemes/renderers/client-wizards.renderers";
import { appSignals } from "./utils";

type ClientWizardsHolderProps = { onWizardAction: (show: boolean) => void };

export const ClientWizardsHolder: FC<ClientWizardsHolderProps> = ({ onWizardAction }) => {
  const [ClientWizard, setClientWizard] = useState<{
    Comp: FC<IWizardCommonProps>;
    parameters: any;
  }>();
  const [reloadAfterClose, setReloadAfterClose] = useState<boolean>();
  const log = useComponentLogger(ClientWizardsHolder);

  const { data: networkSettings, isLoading } = ajax.useGet<NetworkSettings>(
    settingsEndpoints.networkSettings
  );

  const { alertError } = useUIInteraction();

  //useEffect(() => actionHandlers["show.wizard"]("product-activation,reloadAfterClose"), []);

  useEffect(() => {
    return appSignals.subscribe("showClientWizard", async (prms) => {
      if (!networkSettings?.useLegacyLicensing && prms?.schemeId === "ActivationWizard") {
        actionHandlers["show.wizard"]("product-activation,reloadAfterClose");
        return;
      }

      let wizard = clientWizardsRenderers[prms!.schemeId!];

      if (!wizard) {
        alertError(`${prms!.schemeId} not implemented.`);
        return;
      }
      setClientWizard({ Comp: wizard, parameters: prms?.parameters });
      setReloadAfterClose(prms?.reloadAfterClose);

      onWizardAction(true);
    });
  }, [networkSettings]);

  const onClose = useCallback(
    (result: boolean) => {
      setClientWizard(undefined);
      setReloadAfterClose(undefined);
      onWizardAction(false);

      if (result && reloadAfterClose) {
        log.debug("reload page");

        window.location.reload();
      }
    },
    [reloadAfterClose]
  );

  if (!ClientWizard) {
    return <Fragment />;
  }

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return <ClientWizard.Comp onClose={onClose} {...ClientWizard.parameters} />;
};
