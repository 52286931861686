import { useCallback, useMemo, useReducer, useState } from "react";
import { ExecutionMessage } from "../../../api/cayo-graph";
import ajax from "../../../libs/ajax";

const useMessages = (url: string) => {
  const { data, isLoading } = ajax.useGet(url);
  const messages = data as ExecutionMessage[];

  const [messageIndex, setMessageIndex] = useState(0);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const currentMessage = useMemo(() => {
    if (!data?.length) {
      return undefined;
    }

    const msg = messages[messageIndex];
    return msg;
  }, [data, ignored, messageIndex]);

  const showNextMessage = useCallback(() => {
    const totalMessages = messages?.length || -1;
    if (totalMessages >= 0) {
      setMessageIndex((messageIndex + 1) % totalMessages);
    }
  }, [messageIndex, messages]);

  const reloadMessages = useCallback(() => {
    setMessageIndex(0);
    forceUpdate();
  }, []);

  return {
    isLoading,
    currentMessage,
    showNextMessage,
    reloadMessages,
    totalMessages: messages?.length,
    currentMessageIndex: messageIndex,
  };
};

export default useMessages;
