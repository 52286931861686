import { createContext } from "react";
import { DashboardClient, IScheme, NavTreeClient } from "../api/schema.api";
import { UserProfileClient } from "../api/user-profile.api";
import { IHealthCheckClient } from "../components/AppState/health-check.api";
import { UIInteraction } from "../components/GridContainer/grid-model/GridController";
import { IActionBuilder } from "../scheme/actions";
import { ViewStorageFactory } from "../settings/view-storage-factory";
import { INotificationService } from "./notification.service";

export interface IServices {
  actionBuilder: IActionBuilder;
  uiInteraction: UIInteraction;

  navTreeClient: NavTreeClient;
  userProfileClient: UserProfileClient<IScheme>;
  dashboardClient: DashboardClient;

  viewStorageFactory: ViewStorageFactory;

  healthCheck: IHealthCheckClient;
  notificationService: INotificationService;
}

export default createContext<IServices>({} as IServices);
