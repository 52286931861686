import { IAdvancedFilterInfo } from "../../utils/grid-utils";

import { SavedQuery } from "../../api/cayo-graph";
import { IFormField, IObjectPicker } from "../../api/schema.api";
import logger from "../../libs/logger";
import { getError, oClient } from "../../libs/odata.client";
import { endpoints } from "../../services/endpoints.service";
import reportingUtils from "../../utils/reporting-utils";
import { urlUtils } from "../../utils/url-utils";

const log = logger.getLogger("QUICK-FILTER QueryFilterForm.logic");

const _delete = (path: string, id: string): Promise<void> => {
  const handler = oClient(endpoints.publicUrl);
  log.debug("delete -> path, id", path, id);
  return handler
    .delete(path + "/" + id)
    .query()
    .catch((e) => {
      return getError(e);
    });
};

const save = (path: string, model: SavedQuery, overwrite?: boolean): Promise<SavedQuery> => {
  log.debug("save -> path, model, overwrite", path, model, overwrite);

  const handler = oClient(endpoints.publicUrl);

  const forceCreate = !model.id;

  const name = model.name!.replace(/'/g, "%27%27");
  // const name = encodeURIComponent(model.name!).replace(/'/g, "%27%27");

  let filter = `name eq '${name}'`;
  if (!forceCreate) {
    filter += ` or id eq '${model.id}'`;
  }

  delete (model as any).isTemp;
  log.debug("save -> filter", filter);
  return handler
    .get(path)
    .query({ $filter: filter })
    .then((response: SavedQuery[]) => {
      if ((!forceCreate || overwrite) && response.length >= 1) {
        const id = response[0].id;
        if (!model.id) {
          model.id = id;
        }

        const updatedQuery = {
          commonConditions: model.commonConditions,
          id: model.id,
          anrFilter: model.anrFilter,
          objectName: model.objectName,
          name: model.name,
          advancedFilter: model.advancedFilter,
          targetType: model.targetType,
        } as SavedQuery;

        delete (updatedQuery as any).isTemp;
        log.debug("save -> update", updatedQuery);
        return oClient(endpoints.publicUrl)
          .patch(path + "/" + id, updatedQuery)
          .query();
      } else {
        delete (model as IAdvancedFilterInfo).builtIn;
        log.debug("save -> add new", model);
        return oClient(endpoints.publicUrl).post(path, model).query();
      }
    })
    .then((response: SavedQuery) => {
      return handler
        .get(path + "/" + response.id)
        .query({ $select: "*", $expand: "reportingJobs,alertRules" })
        .then((response2: SavedQuery) => {
          const objectPath = urlUtils.trim(window.location.hash);
          if (!response2.job && model.targetType) {
            response2.job = reportingUtils.findReportingJob(
              response2,
              objectPath,
              model.targetType
            );
          }

          response2.alertRule =
            response2.alertRule || reportingUtils.findAlertingRule(response2, objectPath);

          return Promise.resolve(response2);
        });
    })
    .catch((e) => {
      return getError(e);
    });
};

const getClearFilter = (
  annotations: IFormField[],
  targetType?: string,
  defaultQuery?: SavedQuery
): SavedQuery => {
  log.debug(
    "getClearFilter -> annotations, targetType, defaultQuery",
    annotations,
    targetType,
    defaultQuery
  );
  const resetQuery: SavedQuery = {
    commonConditions: {},
    advancedFilter: undefined,
    targetType,
    ...defaultQuery,
  };

  annotations?.forEach((a) => {
    const pickerAnnotation = a as unknown as IObjectPicker;
    const isPicker = pickerAnnotation.isPicker;

    if (isPicker) {
      resetQuery.commonConditions![`${a.name}@odata.type`] =
        pickerAnnotation.selectPropertyName || !pickerAnnotation?.columns?.length
          ? "#Collection(String)"
          : "#Collection(cayo.graph.softLink)";
    }

    if (isPicker) {
      resetQuery.commonConditions![a.name!] = [];
    } else {
      if (a.type === "Edm.String" || a.dataType === "Edm.String") {
        if (a.name !== "advancedFilter") {
          resetQuery[a.name!] = "";
        }
      } else {
        resetQuery.commonConditions![a.name!] = undefined;
      }
    }
  });
  log.debug("getClearFilter -> resetQuery", resetQuery);
  return resetQuery;
};

const makeCustomSavedQuery = (q: SavedQuery) => {
  delete q.id;
  delete q.builtIn;
  delete q.templateId;
  delete q.default;

  return q;
};
const normalizeCommonConditions = (sq: SavedQuery | undefined, annotations: IFormField[]) => {
  log.debug("normalizeCommonConditions -> sq, annotations", sq, annotations);
  const cc = getClearFilter(annotations)?.commonConditions || {};
  const newSq: SavedQuery = {
    ...(sq || {}),
    commonConditions: { ...cc, ...(sq?.commonConditions || {}) },
  };
  log.debug("normalizeCommonConditions -> result", newSq);
  return newSq;
};
//???

export function makeErrorMessage(e: any): string {
  if (typeof e === "string") {
    return e;
  }
  if (!!e.message) {
    return e.message;
  }
  return JSON.stringify(e);
}

export const savedQueries = {
  save,
  delete: _delete,
  getClearFilter,
  normalizeCommonConditions,
  makeCustomSavedQuery,
};
