import { useId } from "@fluentui/react-hooks";
import { Facts, LoadingOverlay } from "cayo.ui";
import React, { FC, useMemo } from "react";
import { IFacts } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import { bindingsUtils } from "../../scheme/bindings";

const ODataFacts: FC<Omit<IFacts, "type"> & { externalData?: any }> = ({
  items,
  url,
  externalData,
  ...other
}) => {
  const { isLoading, data } = ajax.useGet(url!, other);
  const factsId = useId("facts");

  const odataItems = useMemo(() => {
    const result = items?.map((item) => {
      const { name, value } = item;
      return { name, value: bindingsUtils.resolveExpression(data || externalData, value!) };
    });

    return result;
  }, [items, data, externalData]);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return <Facts key={factsId} items={odataItems} />;
};

export default ODataFacts;
