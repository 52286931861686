import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import logger from "../../../libs/logger";
import {
  IPropertiesPanelSignals,
  PropertiesPanelSignalService,
} from "../../../services/properties-panel-signal.service";
import { useSchemes } from "../../App/hooks";
import ErrorBoundary from "../../Common/ErrorBoundary";
import ErrorModal from "../../ErrorModal";
import Panel from "../../Panel/Panel";
import ObjectSchemeLoader from "../../Schemes/ObjectSchemeLoader";
import PickerSchemeLoader from "../../Schemes/PickerSchemeLoader";
import { AuxSchemeProps } from "../../Schemes/SchemeLoader";
import { IPropertiesPanel } from "./types";
import propertiesPanelUtils from "./utils";

const PropertiesPanelHolder = () => {
  const location = useLocation();
  //const { cayoTheme } = useTheme();
  const signals = useMemo<IPropertiesPanelSignals>(() => new PropertiesPanelSignalService(), []);
  // const { confirm } = useUIInteraction();
  // const intl = useIntl();

  const {
    propertiesPanels,
    closePropertiesPanel,
    closeAllPropertiesPanels,
    updatePropertiesPanel,
  } = useSchemes();

  useEffect(() => {
    let scheme: IPropertiesPanel;
    if (propertiesPanels.length) {
      scheme = propertiesPanels[propertiesPanels.length - 1];
    }

    const handlers = [
      signals.subscribe("refresh", () => {
        scheme && updatePropertiesPanel(scheme);
      }),
      signals.subscribe("close", closePropertiesPanel),
    ];

    return () => handlers.forEach((h) => h());
  }, [updatePropertiesPanel, closePropertiesPanel]);

  useEffect(() => {
    closeAllPropertiesPanels();
  }, [location.pathname]);

  const generatePropertiesPanels = useCallback(() => {
    return propertiesPanels
      ?.filter((s) => !!s.id)
      ?.map((s, i) => {
        let realId = s.id!;
        const isNewScheme = realId.endsWith("/new") || s.property;
        if (!isNewScheme) {
          realId += "/properties";
        }

        const addProps = {
          onClose: async () => {
            logger.debug("close panel", s?.parentId);

            let closeFunc = () => closePropertiesPanel(s?.parentId);
            // const hasChanges = LastViewStateStorage.hasChanges;
            // if (hasChanges) {
            //   const result = await confirm(intl.formatMessage(messages.confirmChangesWillBeLost));
            //   if (!result) {
            //     return;
            //   }
            // }

            // LastViewStateStorage.clear();
            closeFunc();
          },
          onRefresh: async () => {
            if (!(s.isNewObject || s.isPicker)) {
              const closeFunc = async () =>
                updatePropertiesPanel({
                  parentId: s?.parentId,
                  id: s.id,
                });
              // const hasChanges = LastViewStateStorage.hasChanges;
              // if (hasChanges) {
              //   const result = await confirm(intl.formatMessage(messages.confirmChangesWillBeLost));
              //   if (!result) {
              //     return;
              //   }
              // }

              // LastViewStateStorage.clearModels();
              closeFunc();
            }
          },
        };

        const auxProps: AuxSchemeProps = {
          object: s?.object,
          signals: s?.signals || signals,
          onSubmit: s?.onSubmit,
        };

        logger.debug("render panel", s, realId);
        return (
          <Panel
            key={"panel_" + i}
            isBlocking={s.isNewObject || false}
            type={"medium"}
            isOpen={true}
            {...addProps}
            object={s?.object}
          >
            {s?.isPicker && (
              <PickerSchemeLoader key={propertiesPanelUtils.generateKey(s)} navPropPath={s.id!} />
            )}
            {!s?.isPicker && (
              <ObjectSchemeLoader
                key={propertiesPanelUtils.generateKey(s)}
                schemePath={propertiesPanelUtils.getShemePath(s)}
                auxProps={auxProps}
                //style={s?.isNewObject ? { margin: cayoTheme.metrics.container.margin } : undefined}
              />
            )}
          </Panel>
        );
      });
  }, [propertiesPanels]);

  return (
    <ErrorBoundary
      fallbackComponent={(resetError: () => void) => (
        <ErrorModal
          message={{
            text: "An error occurred while rendering the properties panel",
            severity: "error",
          }}
          onDismiss={function (): void {
            closeAllPropertiesPanels();
            resetError();
          }}
        />
      )}
    >
      {generatePropertiesPanels()}
    </ErrorBoundary>
  );
};

export default PropertiesPanelHolder;
