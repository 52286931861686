import { PanelType as OfficePanelType, ResponsiveMode } from "@fluentui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { PanelType } from "../../api/schema.api";
import { appSettings } from "../../settings/app-settings";
import AppContext from "../App/app.context";

const useResizer = (type: PanelType, customWidth?: number, minWidth?: number) => {
  const { responsiveMode } = useContext(AppContext);
  const [panelSetting, setPanelSettings] = useState(appSettings.panelSetting.get());

  let actualType: OfficePanelType = OfficePanelType[panelSetting?.type || type];

  let showResizer = true;
  if (responsiveMode && responsiveMode < ResponsiveMode.large) {
    actualType = OfficePanelType.smallFluid;
    showResizer = false;
  }

  let actualWidth = panelSetting?.customWidth || customWidth;
  const actualMinWidth = minWidth || 480;

  const onWidthChanged = (w: number, f: boolean) => {
    window.dispatchEvent(new Event("resize"));
    if (f) {
      setPanelSettings({
        customWidth: actualMinWidth,
        type: "smallFluid",
      });
    } else {
      if (w > actualMinWidth) {
        setPanelSettings({
          customWidth: w,
          type: "custom",
        });
      }
    }
  };

  const handleFullscreenToggled = useCallback(() => {
    if (actualType !== OfficePanelType.smallFluid) {
      setPanelSettings({ type: "smallFluid" });
    } else {
      if (actualWidth) {
        setPanelSettings({ type: "custom", customWidth: actualWidth });
      } else {
        setPanelSettings({ type: "medium" });
      }
    }
  }, [actualType, actualWidth]);

  useEffect(() => {
    panelSetting && appSettings.panelSetting.update(panelSetting);
  }, [panelSetting]);

  return {
    panelWidth: actualWidth,
    showResizer,
    actualType,
    onWidthChanged,
    handleFullscreenToggled,
  };
};

export type IResizerProps = {
  actualType: OfficePanelType;
  handleFullscreenToggled: () => void;
  helpLink?: string;
};

export default useResizer;
