import {
  ICheckbox,
  IDateTimeBox,
  IDropDown,
  ITextField,
  dateTimeUtils,
  stringUtils,
} from "cayo.ui";
import { IntlShape } from "react-intl";
import {
  FixedMonthDay,
  FloatingMonthDay,
  MonthDayNumber,
  MonthlySchedule,
} from "../../api/cayo-graph";
import { IFormField } from "../../api/schema.api";
import { nameofFactory } from "../../utils/object-utils";
import { regexs } from "../../utils/regexs";
import { formComponentTypes } from "../Form/renderers/form-renderers";
import messages from "./messages";
import { monthDayType } from "./types";
import { possibleWeekDays } from "./weeklyForm";

const monthlyNames = nameofFactory<MonthlySchedule>();
const fixedDayNames = nameofFactory<FixedMonthDay>();
const floatingDayNames = nameofFactory<FloatingMonthDay>();
const fixedMonthDayType: monthDayType = "cayo.graph.fixedMonthDay";
const floatingMonthDayType: monthDayType = "cayo.graph.floatingMonthDay";

const monthDayNumbers: Array<MonthDayNumber> = ["first", "second", "third", "fourth", "last"];
const objectTypeSwitchName = monthlyNames("monthDay") + "_switch";

const numberFieldWidth = 176;

const monthlyForm = (intl: IntlShape, schedule?: MonthlySchedule) => {
  const startTime = dateTimeUtils.convertUtcTimeToLocal(
    dateTimeUtils.extractTime(schedule?.startTime) || dateTimeUtils.convertTimeToUtc("00:00")
  );
  const fixedDay = schedule?.monthDay as FixedMonthDay;
  const floatingDay = schedule?.monthDay as FloatingMonthDay;
  const currentFloatingDay = floatingDay?.weekDay || "sunday";
  const currentFloatingNumber = floatingDay?.number || "first";

  return {
    title: intl.formatMessage(messages.monthly),
    items: [
      {
        type: "datetimebox" as formComponentTypes,
        label: intl.formatMessage(messages.startTime),
        autoFocus: true,
        required: true,
        editMode: "onlyTime",
        name: monthlyNames("startTime"),
        value: startTime,
      } as IDateTimeBox,
      {
        type: "number" as formComponentTypes,
        label: intl.formatMessage(messages.repeatEvery),
        required: true,
        pattern: regexs.monthsRange.source,
        name: monthlyNames("period"),
        value: schedule?.period?.toString() || "1",
        suffix: intl.formatMessage(messages.monthsTip),
      } as ITextField,
      // switch
      {
        type: "toggle" as formComponentTypes,
        name: objectTypeSwitchName,
        offText: intl.formatMessage(messages.floatingDay),
        onText: intl.formatMessage(messages.fixedDay),
        label: undefined,
        required: false,
        style: { width: numberFieldWidth },
        value:
          (fixedDay?.objectType as monthDayType) === "cayo.graph.floatingMonthDay" ? false : true,
      } as ICheckbox,
      // objectType
      {
        type: "number" as formComponentTypes,
        name: `${monthlyNames("monthDay")}.${fixedDayNames("objectType")}`,
        required: true,
        hidden: true,
        valueDependsOn: `${objectTypeSwitchName} ? ${fixedMonthDayType} : ${floatingMonthDayType} `,
        style: { width: numberFieldWidth },
        value:
          (fixedDay?.objectType as monthDayType) === "cayo.graph.fixedMonthDay"
            ? ("cayo.graph.floatingMonthDay" as monthDayType)
            : ("cayo.graph.fixedMonthDay" as monthDayType),
      } as ITextField,
      // fixed day
      {
        type: "number" as formComponentTypes,
        name: `${monthlyNames("monthDay")}.${fixedDayNames("day")}`,
        bindings: {
          visible: `${objectTypeSwitchName} eq true`,
        },
        required: true,
        label: intl.formatMessage(messages.dayOfMonth),
        collapseIfDisabled: true,
        value: fixedDay?.day?.toString() || "1",
        style: { width: numberFieldWidth },
      } as ITextField,
      // floating day number
      {
        type: "dropdown" as formComponentTypes,
        name: `${monthlyNames("monthDay")}.${floatingDayNames("number")}`,
        label: "",
        bindings: {
          visible: `${objectTypeSwitchName} eq false`,
        },
        required: true,
        collapseIfDisabled: true,
        style: { width: numberFieldWidth },
        options: monthDayNumbers.map((d) => ({
          key: d,
          text: stringUtils.makePascal(d),
          value: d,
          selected: currentFloatingNumber === d,
        })),
      } as IDropDown,
      {
        type: "dropdown" as formComponentTypes,
        name: `${monthlyNames("monthDay")}.${floatingDayNames("weekDay")}`,
        label: intl.formatMessage(messages.selectWeekDayTitle),
        bindings: {
          visible: `${objectTypeSwitchName} eq false`,
        },
        required: true,
        collapseIfDisabled: true,
        selectedKey: possibleWeekDays[0].value,
        style: { width: numberFieldWidth },
        options: possibleWeekDays.map((d) => ({
          key: d.value,
          text: d.name,
          value: d.value,
          selected: currentFloatingDay === d.value,
        })),
      } as IDropDown,
    ] as IFormField[],
  };
};

export default monthlyForm;
