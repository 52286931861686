import { getFocusStyle, getTheme, ITheme, mergeStyleSets } from "@fluentui/react";

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

export const classNames = mergeStyleSets({
  container: {
    overflow: "auto",
    maxHeight: 500,
    minWidth: 400,
  },
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 40,
      padding: "0px 10px",
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      whiteSpace: "nowrap",
      selectors: {
        "&:hover": { background: palette.neutralLight },
      },
    },
  ],
  itemCellSelected: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 40,
      padding: "0px 10px",
      boxSizing: "border-box",
      background: palette.themePrimary,
      color: palette.white,
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      whiteSpace: "nowrap",
    },
  ],
  itemImage: {
    flexShrink: 0,
  },
  itemContent: {
    marginLeft: 10,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    fonts.xLarge,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  callout: {
    minWidth: 300,
  },
  buttonArea: {
    verticalAlign: "top",
    display: "inline-block",
    textAlign: "center",
    minWidth: 130,
    height: 32,
  },
  optionsList: {
    minWidth: "100px",
    height: "100%",
  },

  calendarsWrapper: {
    alignItems: 'stretch'
  },
  calendar: {
    justifyContent: 'space-between'
  }
});
