import { MessageBar, Stack } from "@fluentui/react";
import { ITheme } from "cayo.ui";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { FCC } from "../types/react";

export const Row = styled.div<{
  justifyContent?: string | undefined;
  valign?: boolean;
  nostretch?: boolean;
}>`
  display: flex;
  flex-flow: row;

  max-width: ${(props) => (props.nostretch ? "auto" : "100%")};
  width: ${(props) => (props.nostretch ? "auto" : "100%")};
  align-items: ${(props) => (props.valign ? "center" : "flex-start")};
  align-content: center;
  justify-content: ${(props) => props.justifyContent || "unset"};
`;

export const Column = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 100%;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
`;

export const MvPropValue = styled.div`
  font-family: "Consolas", "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  width: 100%;
  margin-left: 0;
`;

export const PropValue = styled.span`
  font-family: "Consolas", "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
`;

export const defaultLabelStyle: Partial<CSSProperties> = { fontWeight: 600 };

const CenteredPanelInternal = styled.div`
  position: absolute;
  left: 0;
  /* TODO: get offset from server */
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const CenteredPanelContent = styled.div<{ dropShadow?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.disabled};
  flex-flow: column;
  padding: 10px;
  box-shadow: ${(props) => props.dropShadow && "0 5px 15px rgba(0, 0, 0, 0.5)"};
  border: ${(props) => props.dropShadow && "1px solid rgba(0, 0, 0, 0.2)"};
`;

export const Stack100pw = styled(Stack)`
  width: 100%;
`;

export const StackStretched = styled(Stack)`
  width: 100%;
  height: 100%;
  flex-grow: 1;
`;

export const CenteredPanel: FCC<{ dropShadow?: boolean; style?: CSSProperties }> = (props) => {
  return (
    <CenteredPanelInternal>
      <CenteredPanelContent
        dropShadow={props.dropShadow}
        style={props.style}
        className={(props as any).className}
      >
        {props.children}
      </CenteredPanelContent>
    </CenteredPanelInternal>
  );
};

export const StretchedContainer = styled.div`
  overflow: "hidden auto";
  flex-grow: 1;
  height: 100%;
  width: 100%;
  min-width: 0;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledMessageBar = styled(MessageBar)`
  span a:last-child {
    padding-left: 0 !important;
  }
`;
