import { logger } from "cayo.ui";
import modelUtils from "../../components/Form/modelUtils";
import { objectUtils } from "../../utils/object-utils";

class LogicalODataExpression {
  private logicalOperators = ["and", "or"];
  private object: any;
  private expression: string;
  private readonly log = logger.getLogger("LogicalODataExpression");
  constructor(expression: string) {
    if (!expression.startsWith("(")) {
      expression = "(" + expression + ")";
    }

    this.expression = expression;
  }

  private calcSimpleExpression = (prop: string, op: string, value: string | null) => {
    try {
      const propValue = Object.keys(this.object).find((k) => k === prop)
        ? this.object[prop]
        : modelUtils.resolvePropertyValue(prop, this.object);
      switch (op) {
        case "ne":
          return value === null ? propValue !== value : propValue?.toString() !== value;
        case "eq":
          return value === null ? propValue === value : propValue?.toString() === value;
        case "gt":
          return value != null ? propValue > value : false;
        case "lt":
          return value != null ? propValue < value : false;
        default:
          throw new Error("Unsupported");
      }
    } catch (e) {
      this.log.error(`Failed to calc expression. Prop: ${prop}, op: ${op}, value: ${value}`);
      throw e;
    }
  };

  private calcNotExpression = (subExpression: any) => {
    const result = this.calc(subExpression);
    return !result;
  };

  private calc = (data: any[] | undefined) => {
    if (!data?.length) {
      return false;
    }

    const tempResult = [];
    for (let i = 0; i < data.length; i++) {
      const d = data[i];

      if (Array.isArray(d)) {
        tempResult.push(this.calc(d));
      } else if (this.logicalOperators.find((o) => o === d)) {
        tempResult.push(d);
      } else if (d === "not") {
        const subExpression = data[++i];
        tempResult.push(this.calcNotExpression(subExpression));
      } else {
        const prop = d;
        const op = data[++i];
        const tempValue = data[++i] as string;
        const value = trim(tempValue as string);

        tempResult.push(this.calcSimpleExpression(prop, op, tempValue === "null" ? null : value));
      }
    }

    let result = tempResult[0] as number;
    for (let i = 1; i < tempResult.length; i++) {
      if (tempResult[i] === "and") {
        result &= tempResult[++i];
      } else if (tempResult[i] === "or") {
        result |= tempResult[++i];
      }
    }

    return !!result;
  };

  public parse(object: any) {
    this.object = objectUtils.deepClone(object);

    const temp = groupParentheses(this.expression);
    const result = this.calc(temp);

    return result;
  }
}

export function groupParentheses(str: string) {
  var i = 0;
  const main = (): any => {
    var arr = [];
    var startIndex = i;
    function addWord() {
      if (i - 1 > startIndex) {
        arr.push(str.slice(startIndex, i - 1));
      }
    }
    while (i < str.length) {
      switch (str[i++]) {
        case " ":
          addWord();
          startIndex = i;
          continue;
        case "(":
          arr.push(main());
          startIndex = i;
          continue;
        case ")":
          addWord();
          return arr;
      }
    }
    addWord();
    return arr;
  };
  return main();
}

// class LogicalODataExpression {
//   private logicalOperators = ["and", "or"];
//   private object: any;

//   constructor(readonly expression: string) {}

//   private calcSimpleExpression = (prop: string, op: string, value: string) => {
//     switch (op) {
//       case "ne":
//         return this.object[prop]?.toString() !== value;
//       case "eq":
//         return this.object[prop]?.toString() === value;
//       case "gt":
//         return this.object[prop] > value;
//       case "lt":
//         return this.object[prop] < value;
//       default:
//         throw new Error("Unsupported");
//     }
//   };

//   private calc = (data: any[] | undefined) => {
//     if (!data?.length) {
//       return false;
//     }

//     const tempResult = [];
//     for (let i = 0; i < data.length; i++) {
//       const d = data[i];

//       if (Array.isArray(d)) {
//         tempResult.push(this.calc(d));
//       } else if (this.logicalOperators.find((o) => o === d)) {
//         tempResult.push(d);
//       } else {
//         const prop = d;
//         const op = data[++i];
//         const value = trim(data[++i] as string);

//         tempResult.push(this.calcSimpleExpression(prop, op, value));
//       }
//     }

//     let result = tempResult[0] as number;
//     for (let i = 1; i < tempResult.length; i++) {
//       if (tempResult[i] === "and") {
//         result &= tempResult[++i];
//       } else if (tempResult[i] === "or") {
//         result |= tempResult[++i];
//       }
//     }

//     return !!result;
//   };

//   public parse(object: any) {
//     this.object = object;

//     const temp = group(this.expression);
//     const result = this.calc(temp);

//     return result;
//   }
// }

// function group(str: string) {
//   var i = 0;
//   const main = (): any => {
//     var arr = [];
//     var startIndex = i;
//     function addWord() {
//       if (i - 1 > startIndex) {
//         arr.push(str.slice(startIndex, i - 1));
//       }
//     }
//     while (i < str.length) {
//       switch (str[i++]) {
//         case " ":
//           addWord();
//           startIndex = i;
//           continue;
//         case "(":
//           arr.push(main());
//           startIndex = i;
//           continue;
//         case ")":
//           addWord();
//           return arr;
//       }
//     }
//     addWord();
//     return arr;
//   };
//   return main();
// }

const trim = (s: string) => {
  return s?.replace(/^'|'$/g, "");
};

export const evaluateODataLogicalExpression = (expression: string, object: any) => {
  if (expression.startsWith("not(")) {
    expression = expression.replace("not(", "not (");
  }
  return new LogicalODataExpression(expression).parse(object);
};

export default LogicalODataExpression;
