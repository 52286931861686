import { JobState } from "../../services/job-state.service";
import { IAppTheme } from "../../themes";

const jobstate = (theme: IAppTheme, prms: JobState) => {
  return theme.getJobState(prms);
};

export const buildInIconFormatters = {
  jobstate,
};
