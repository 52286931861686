import { ProgressIndicator } from "@fluentui/react";
import { stringUtils, useTheme } from "cayo.ui";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { FileStorage } from "../../api/cayo-graph";
import { commonMessages } from "../common-messages";

const StorageSpace: FC<FileStorage> = ({ freeSpace, capacity }) => {
  const intl = useIntl();
  const { cayoTheme } = useTheme();

  const indicatorInfo = useMemo(() => {
    try {
      const description = intl.formatMessage(commonMessages.usedSpace, {
        freeSpace: stringUtils.humanFileSize(freeSpace!),
        capacity: stringUtils.humanFileSize(capacity!),
      });
      if (capacity) {
        const percentUsed = 1 - freeSpace! / capacity!;
        let color = cayoTheme.brandColors.primaryHighlight;
        if (percentUsed >= 0.9) {
          color = cayoTheme.brandColors.critical;
        }
        return { description, percentUsed, color };
      }
    } catch {}

    return { description: intl.formatMessage(commonMessages.unknownSize), percentUsed: 0 };
  }, [freeSpace, capacity]);

  return (
    <ProgressIndicator
      description={indicatorInfo.description}
      percentComplete={indicatorInfo.percentUsed}
      barHeight={4}
      styles={{
        itemProgress: { padding: "8px 0px 4px 0" },
        progressBar: { backgroundColor: indicatorInfo.color },
      }}
    />
  );
};

export default StorageSpace;
