export interface IExpressionFormState {
  postFix: string;
  operator: string;
  value: string;
  addColumns: boolean;
}

export enum ModifiedType {
  ANY = "any",
  REMOVED = "_removed",
  ADDED = "_added",
}

export interface IExFormState {
  disable: boolean;
  operators: Array<{ key: string; text: string }>;
  selectOptions: Array<{ key: string; text: string }>;
  syntax: string;
  component: string;
}

export interface IExpressionFormHook {
  exFormState: Omit<IExFormState, "disable">;
  expression: IExpressionFormState;
  disabled: boolean;
  disabledCheckBox: boolean;
  formErrorMsg: string;
  setFormErrorMsg: (nsg: string) => void;
  setDisabledState: (disabled: boolean) => void;
  handleCheckBoxState: (disabled: boolean) => void;
  onChange: (key: string, value: string) => void;
  setExpression: (expressionForm: IExpressionFormState) => void;
  setFormState: (expressionForm: IExFormState) => void;
  reset: () => void;
}
