import { stringUtils } from "cayo.ui";
import { Alert, AlertStatus, ObjectCategory, Severity } from "../../api/cayo-graph";
import logger from "../../libs/logger";
import { parseResponse } from "../../libs/odata.client";

export interface IInteractiveItem {
  disabled?: boolean;
  id: string;
  modificationTime?: Date | undefined;
  disappearing?: boolean | undefined;
}

type alertActionType =
  | "properties"
  | "entitySet"
  | "action"
  | "externalLink"
  | "customView"
  | "media";

export type alertAction = {
  text: string;
  path: string;
  type: alertActionType;
};

export interface IAlertItem extends IInteractiveItem {
  action?: string;
  actions?: alertAction[];
  autoHide?: boolean;
  message?: string;
  disappeared: boolean;
  dismissable?: boolean;
  lastModifiedDateTime: Date;
  severity: Severity;
  status: AlertStatus;
  subject?: string;
  categories?: ObjectCategory[];
  highlighted?: boolean;
  clicked?: boolean;
  objectType?: string;
  onActionClicked?: () => void;
  ownerId?: string;
}

const log = logger.getLogger("AlertsService");

class AlertsService {
  constructor(
    readonly baseUrl: string,
    readonly selectCountUrl: string,
    readonly selectAlertsUrl: string,
    readonly dismissAlertUrl: string,
    readonly dismissAllAlertsUrl?: string
  ) {
    this.dismissAlert = this.dismissAlert.bind(this);
    this.dismissAll = this.dismissAll.bind(this);
    if (log.isDebugEnabled())
      log.debug(".ctor -> args", { baseUrl, selectCountUrl, selectAlertsUrl, dismissAlertUrl });
  }

  public dismissAlert(item: IAlertItem): Promise<void> {
    const url = stringUtils.format(this.dismissAlertUrl, [item.id!]);

    const content = JSON.stringify({ status: "dismissed" });

    return fetch(url, {
      body: content,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PATCH",
    }).then(() => {
      return;
    });
  }

  public dismissAll(alertTypes: string[] | undefined, exclude?: boolean): Promise<void> {
    log.debug("dismissAll -> alertType", alertTypes);
    const content = alertTypes?.length
      ? JSON.stringify(
          exclude
            ? { excludedAlertTypes: alertTypes, hiddenOnly: true }
            : { alertTypes, hiddenOnly: true }
        )
      : undefined;

    return fetch(this.dismissAllAlertsUrl!, {
      body: content,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
    }).then(() => {
      return;
    });
  }

  public getNewAlerts(): Promise<{ alerts: IAlertItem[]; errors: number }> {
    return fetch(this.selectAlertsUrl + "&time=" + new Date().getTime(), {
      headers: {},
    }).then((response) => {
      return response
        .text()
        .then((responseText) => {
          const result = parseResponse(responseText);

          const alerts = result && result.value && (result.value as any as IAlertItem[]);
          alerts.forEach((a: any) => {
            const modificationTime = (a as any as Alert).lastModifiedDateTime;
            a.modificationTime = modificationTime ? new Date(modificationTime) : new Date();
          });

          return {
            alerts: alerts || [],
            errors: AlertsService.countErrors(alerts),
          };
        })
        .catch((e) => {
          logger.log(e);

          return { alerts: [], errors: 0 };
        });
    });
  }

  public getNewAlertsCount(): Promise<{ count: number; errors: number }> {
    return fetch(this.selectCountUrl + "&time=" + new Date().getTime(), {
      headers: {},
    }).then((response) => {
      return response.text().then((responseText) => {
        const result = parseResponse(responseText);

        const alerts = result && result.value && (result.value as any as IAlertItem[]);
        alerts.forEach((a: any) => {
          const modificationTime = (a as any as Alert).lastModifiedDateTime;
          a.modificationTime = modificationTime ? new Date(modificationTime) : new Date();
        });

        return {
          count: (alerts && (alerts as []).length) || 0,
          // tslint:disable-next-line: triple-equals
          errors: alerts ? AlertsService.countErrors(alerts) : 0,
        };
      });
    });
  }

  public static countErrors(items: IAlertItem[]) {
    let sum: number = 0;
    items.forEach((a) => {
      if (a.severity === "high" || a.severity === "error" || a.severity === "critical") {
        sum++;
      }
    });

    return sum;
  }

  public static countWarnings(items: IAlertItem[]) {
    let sum: number = 0;
    items.forEach((a) => {
      if (a.severity === "warning" || a.severity === "medium") {
        sum++;
      }
    });

    return sum;
  }

  public static countNew(items: IAlertItem[]) {
    let sum: number = 0;
    items.forEach((a) => {
      if (!a.disappeared) {
        sum++;
      }
    });

    return sum;
  }
}

export default AlertsService;
