import globalConstants from "../components/App/constants";
import logger from "../libs/logger";
import globals from "../settings/globals";
import { urlUtils } from "../utils/url-utils";

const log = logger.getLogger("endpoint.service");

log.debug("NODE_ENV", process.env.NODE_ENV);
log.debug("process.env.PUBLIC_URL", process.env.PUBLIC_URL);

const PUBLIC_URL =
  process.env.NODE_ENV === "production" ? window["PUBLIC_URL"] : process.env.PUBLIC_URL || "/";

const apiEndpoint = `${
  process.env.REACT_APP_API_ENDPOINT || window.location.origin + window.location.pathname || ""
}`.replace(new RegExp("/$"), "");

const publicUrl = `${
  process.env.REACT_APP_API_ENDPOINT_ROOT || window.location.origin + PUBLIC_URL || ""
}`.replace(new RegExp("/$"), "");

const redirectUri =
  window.location.origin + urlUtils.buildRelativePath(PUBLIC_URL, "redirect.html");

const buildRelativeApiPath = (...args: string[]) => {
  const path = urlUtils.buildRelativePath(
    endpoints.PUBLIC_URL,
    globalConstants.apiVersion,
    ...args
  );
  return path;
};

const makeFullApiPath = (relativePath: string) => {
  return endpoints.publicUrl + urlUtils.makeApiPath(relativePath);
};

log.info("apiEndpoint: ", apiEndpoint);
log.info("publicUrl: ", publicUrl);
log.info("redirectUri: ", redirectUri);
log.info("PUBLIC_URL: ", `${PUBLIC_URL}`);
log.info("useGraphScheme: ", globals.useGraphScheme);

const adminEndpoint = urlUtils.buildRelativePath(PUBLIC_URL, `admin`);
const graphEndpoint = urlUtils.buildRelativePath(PUBLIC_URL, `graph`);

let isAdminMode = !globals.useGraphScheme;

// const makeAdminUrl = (url: string) => {
//   return url.replace(graphEndpoint, adminEndpoint);
// };

(() => {
  if (window.location.pathname.toLowerCase().startsWith(graphEndpoint)) {
    document.getElementById("title")!.innerText = "Graph Editor";
    isAdminMode = false;
  }
})();

export const endpoints = {
  adminEndpoint,
  graphEndpoint,
  //rootEndpoint,
  PUBLIC_URL,
  publicUrl,
  apiEndpoint,
  tokenEndpoint: publicUrl + "/connect/token",
  redirectUri,
  buildRelativeApiPath,
  makeFullApiPath,
  get isAdminMode() {
    return isAdminMode;
  },
};
