import { Icon, MessageBar, MessageBarType, PrimaryButton } from "@fluentui/react";
import { DropDownPicker, stringUtils } from "cayo.ui";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PropertyIcon } from "../../api/cayo-graph";
import { DepsType, IFormField, IObjectPicker, IScheme } from "../../api/schema.api";
import { getErrorMessage } from "../../libs/odata.client";
import useService from "../../services/services.hook";
import { AuxRenderProps } from "../LegacyDetailsList/cell-renderers";
import { commonMessages } from "../common-messages";
import { useExpressionForm } from "./components/ExpressionForm";
import {
  builtExpression,
  createFilterDeps,
  normalizeToConditionOptions,
  validateExpressionForm,
  validateExpressionFormValue,
  validateFilter,
  validateRelatedFields,
} from "./service";
import { FilterValuesType, SetValueType, ValueType } from "./types";
import { components, labels, selectTypes, withCustomExpression, withDependency } from "./utils";

interface IAdvanceObjectPicker {
  annotation: IScheme;
  filterValues: FilterValuesType[];
  auxProps: AuxRenderProps;
  setValue: SetValueType;
  setError: (error: string | undefined) => void;
}

export const AdvanceObjectPicker = ({
  annotation,
  filterValues,
  setValue,
  setError,
  auxProps,
}: IAdvanceObjectPicker) => {
  const uiInteraction = useService("uiInteraction");
  const intl = useIntl();
  const toggleDropDownPicker = useRef<() => void>();
  const pickerOpened = useRef(false);
  const [values, setValues] = useState<ValueType[]>([]);
  const [toRemove, setToRemove] = useState<string[]>([]);
  const [filterDeps, setFilterDeps] = useState<DepsType>();
  const [formError, setFormError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);

  const {
    disabled,
    expression: expressionForm,
    exFormState,
    formErrorMsg,
    disabledCheckBox,
    reset,
    onChange,
    setFormErrorMsg,
    handleCheckBoxState,
    setExpression,
    setFormState,
  } = useExpressionForm();

  const initValues = useCallback(
    (closeModal = false) => {
      let exForm = null;
      const nameSet = new Set(
        filterValues.map((item: FilterValuesType) => {
          if (item?.expressionForm) {
            exForm = item?.expressionForm;
          }
          return item?.value.annotationName;
        })
      );

      let filtered =
        closeModal || !filterValues?.length
          ? []
          : values.filter((item: ValueType) => !nameSet.has(item.annotationName));

      exForm && setExpression(exForm);
      setValues([
        ...filtered,
        ...filterValues?.map((filterValue: FilterValuesType) => ({
          ...filterValue.value,
          onRenderPersonaCoin: addIconRenderer(filterValue?.value, auxProps.getIconAnnotation),
        })),
      ]);
    },
    [filterValues, values]
  );

  useEffect(() => {
    initValues();
  }, [filterValues]);

  useEffect(() => {
    if (!opened && !values.length) {
      reset();
      setFilterDeps(undefined);
    }
  }, [opened, values]);

  useEffect(() => {
    const filteredValues = values.filter((value: ValueType) =>
      withCustomExpression.includes(value?.annotationName)
    );

    handleCheckBoxState(!filteredValues.length);
    if (filteredValues.length) {
      const data = validateExpressionForm(filteredValues);
      setFormState(data);
    }
  }, [values]);

  useEffect(() => {
    let valueErrorMsg = validateExpressionFormValue(
      expressionForm.value,
      exFormState.syntax,
      expressionForm.operator
    );
    setFormErrorMsg(valueErrorMsg);
  }, [expressionForm, exFormState, values]);

  const pickerStateHandler = useCallback(
    (isOpened: boolean) => {
      setOpened(isOpened);
      if (!!auxProps.onPickerStateChanged) {
        pickerOpened.current = isOpened;
        auxProps.onPickerStateChanged(isOpened);
      }
    },
    [auxProps.onPickerStateChanged]
  );

  const normalizeAndSetValue: SetValueType = useCallback(
    (value, itemAnnotation) => {
      const mappedData = value.map((value: ValueType) => ({
        ...value,
        isExclude: (itemAnnotation as IObjectPicker).isExclude,
        annotationName: itemAnnotation.name,
        propertyName: itemAnnotation.modelProperty || itemAnnotation?.name,
      }));

      if (!mappedData.length) {
        setToRemove((prev) => [...prev, itemAnnotation.name || ""]);
        if (withDependency[itemAnnotation.name!]) setFilterDeps(undefined);
      } else {
        setToRemove((prev) => [...prev.filter((p) => p !== itemAnnotation.name)]);

        if (withDependency[itemAnnotation.name!]) {
          const deps = createFilterDeps(
            value,
            structuredClone(withDependency[itemAnnotation.name!])
          );
          setFilterDeps(deps);
        }
      }

      const filtered = values.filter(
        (temp: ValueType) => temp.annotationName !== itemAnnotation.name
      );

      setFormError(false);
      setFormErrorMsg("");

      setValues([...filtered, ...mappedData]);
    },
    [annotation, values]
  );

  const handleSave = useCallback(async () => {
    let isValid = validateRelatedFields(values);
    setFormError(!isValid);
    if (isValid) {
      const expression = builtExpression(values, toRemove, auxProps.innerQuery!, expressionForm);

      try {
        if (values.length) {
          await validateFilter(auxProps.queryUrl!, auxProps.targetType!, expression);
        }
        setValue(values, annotation, expression);
      } catch (error) {
        const msg = await getErrorMessage(error);
        uiInteraction.alertError(msg);
        isValid = false;
      }
    }

    return isValid;
  }, [values, auxProps, expressionForm, toRemove]);

  return (
    <DropDownPicker
      title={(annotation as IObjectPicker)?.title}
      isActive={!!values.length}
      initToggle={(f) => (toggleDropDownPicker.current = f)}
      onPickerStateChanged={pickerStateHandler}
      onClose={() => initValues(true)}
      initialFocus={false}
    >
      {annotation?.items?.map((itemAnnotation: IFormField, index: number) => {
        const Component = components[itemAnnotation.type!] || null;
        const filteredV =
          values?.filter((value: ValueType) => {
            return value.annotationName === itemAnnotation.name;
          }) || [];

        const defaultOperators = itemAnnotation.supportedOperators
          ? normalizeToConditionOptions(itemAnnotation.supportedOperators, true)
          : [];

        const props = {
          ...itemAnnotation,
          label: stringUtils.toSpaceDelimitedSentenceCase(
            labels[itemAnnotation?.label!] || itemAnnotation?.label || ""
          ),
          value: filteredV,
          ...auxProps,
          defaultOperators,
          setError,
        };

        return (
          <Component
            key={index}
            {...props}
            selectTypes={selectTypes}
            expression={expressionForm}
            expressionDisabled={disabled}
            exFormState={exFormState}
            formErrorMsg={formErrorMsg}
            disabledCheckBox={disabledCheckBox}
            filterDependency={
              withCustomExpression.includes(itemAnnotation.name!) ? filterDeps : undefined
            }
            setValue={(value: ValueType) => normalizeAndSetValue(value, itemAnnotation)}
            onChange={onChange}
            reset={reset}
            offBlurAction
          />
        );
      })}
      {formError && (
        <MessageBar styles={{ root: { marginTop: 20 } }} messageBarType={MessageBarType.error}>
          Select matching object types and properties.
        </MessageBar>
      )}
      <PrimaryButton
        disabled={!!formErrorMsg || loading}
        text={intl.formatMessage(commonMessages.save)}
        onClick={async () => {
          setLoading(true);
          const isValid = await handleSave();

          if (isValid) {
            pickerStateHandler && pickerStateHandler(false);
            toggleDropDownPicker.current && toggleDropDownPicker.current();
          }

          setLoading(false);
        }}
        style={{ marginTop: 6, width: "100%" }}
      />
    </DropDownPicker>
  );
};

function addIconRenderer(
  v: ValueType,
  getIconAnnotation: (type?: string, dontUseDefaultIcon?: boolean) => PropertyIcon
) {
  if (
    v.objectType === "cayo.graph.objectTypeElement" ||
    v.objectType === "cayo.graph.locationElement"
  ) {
    const item = v as any;
    let propWithIcon: any = null;
    if (item.locationType) {
      propWithIcon = item.locationType;
    } else if (v.objectName) {
      propWithIcon = v.objectName;
    }

    const icon = getIconAnnotation(propWithIcon)?.iconId;
    return () => {
      return icon ? <Icon iconName={icon} /> : null;
    };
  }

  let iconParams: string | undefined;

  if (v.linkType) {
    iconParams = v.linkType;
  }

  if (v.objectType && v.objectType !== "cayo.graph.eventCategory") {
    iconParams = v.objectType === "cayo.graph.objectTypeElement" ? v.objectName : v.objectType;
  }

  return () => {
    return iconParams ? (
      <Icon iconName={getIconAnnotation ? getIconAnnotation(iconParams, true).iconId : undefined} />
    ) : null;
  };
}
