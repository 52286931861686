import { IPersonaProps, ValidationState } from "@fluentui/react";
import { OdataQuery } from "odata";
import React from "react";
import { Entity, PossibleValueItem, PropertyIcon, SoftLink } from "../../api/cayo-graph";
import { IObjectPicker } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import { oClient } from "../../libs/odata.client";
import { endpoints } from "../../services/endpoints.service";
import Icon from "../Graphics/Icon";
import { IPickerItem } from "./IPickerItem";
import oDataConverters from "./odata-converters";

const log = logger.getLogger("ObjectPicker.logic");

export function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

export function validateInput(input: string): ValidationState {
  if (input.indexOf("@") !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

type fetchObjectsParams = {
  path: string | undefined;
  searchText?: string | undefined;
  orderBy?: string | string[] | undefined;
  desc?: boolean;
  filter?: string | undefined;
  select?: string | undefined;
  expand?: string | undefined;
  additionalFilter?: string;
  body?: any;
};

export const fetchObjects: (prms: fetchObjectsParams) => Promise<Entity[]> = ({
  path,
  searchText,
  orderBy,
  desc,
  filter,
  select,
  expand,
  additionalFilter,
  body,
}) => {
  log.debug("fetchObjects -> args", {
    path,
    searchText,
    orderBy,
    desc,
    filter,
    select,
    expand,
    additionalFilter,
    body,
  });

  const [url, search] = path!.split("?");
  const params = new URLSearchParams(search);

  const c = oClient(endpoints.publicUrl);
  let q: Promise<any>;
  if (body) {
    q = c.post(url, body).query();
  } else {
    const query: OdataQuery = {
      $select: params.get("$select") ?? undefined,
      $filter: params.get("$filter") ?? undefined,
    };

    if (searchText) {
      const anr = `(contains(objectName,'${searchText}')${additionalFilter || ""})`;
      query.$filter = query.$filter ? `${query.$filter} and ${anr}` : anr;
    }
    if (filter) {
      if (query.$filter) {
        query.$filter = `(${query.$filter} and ${filter})`;
      } else {
        query.$filter = filter;
      }
    }

    if (select) {
      query.$select = "id,objectPath," + select;
    }
    if (!query.$select) {
      query.$select = "*";
    }
    if (!query.$filter) {
      delete query.$filter;
    }
    const actualOrderBy = Array.isArray(orderBy)
      ? orderBy.join(",")
      : `${orderBy ?? "objectName"} ${desc ? "desc" : "asc"}`;

    q = c.get(url).query({
      ...query,
      $orderby: actualOrderBy,
      ...(expand ? { $expand: expand } : {}),
    });
  }

  return q
    .then((response: Entity[] | undefined) => {
      return response || [];
    })
    .catch((e) => {
      logger.error(e);
      throw e;
    });
};

export const EntitiesToPickerItems = (
  entities: Entity[],
  getIconAnnotation: (type?: string, dontUseDefaultIcon?: boolean) => PropertyIcon,
  picker?: IObjectPicker | undefined,
  possibleValues?: PossibleValueItem[]
) => {
  const selectPropertyName = picker?.selectPropertyName;
  const isSimplePicker = !selectPropertyName && !picker?.columns?.length;

  return entities
    ?.map<IPersonaProps>((v) => {
      try {
        const key = (v as any)?.key;
        if (!v!.id && !selectPropertyName) {
          return {};
        }

        if (key && !v!.id && selectPropertyName) {
          v!.id = key;
        }

        const pickerItem = v as IPickerItem;
        const selectedPropertyValue = selectPropertyName && v[selectPropertyName];
        let iconParams: string | undefined;

        if (v.linkType) {
          iconParams = v.linkType;
        }

        if (v.objectType && !selectPropertyName && v.objectType !== "cayo.graph.eventCategory") {
          iconParams =
            v.objectType === "cayo.graph.objectTypeElement" ? v.objectName : v.objectType;
        }

        const result: IPickerItem = {
          id: v.id!,
          text:
            possibleValues?.find((pv) => pv.value === v.id!)?.name ||
            (v as any).displayName ||
            v.objectName ||
            selectedPropertyValue ||
            key ||
            pickerItem.text,
          link:
            selectPropertyName || isSimplePicker
              ? selectedPropertyValue || key || pickerItem.link
              : oDataConverters.entityToSoftLink(v),

          onRenderPersonaCoin: () => {
            return iconParams ? (
              <Icon
                iconName={
                  getIconAnnotation ? getIconAnnotation(iconParams, true).iconId : undefined
                }
              />
            ) : null;
          },
        };

        return result;
      } catch (e) {
        logger.error(e);
        return {};
      }
    })
    .filter((i) => !!i.id && typeof i.text === "string");
};

export const StringsToEntities = (
  value: string[] | undefined,
  selectPropertyName: string | undefined,
  possibleValues?: PossibleValueItem[]
) => {
  const actualValue = Array.isArray(value) ? value : value && [value];

  return (
    actualValue?.map<IPickerItem>((v) => {
      const item: IPickerItem = {
        id: v,
        text: possibleValues?.find((p) => p.value === v)?.name || v,
        link: v,
      };

      if (selectPropertyName) {
        item[selectPropertyName] = v;
      }
      return item;
    }) || []
  );
};

export const resolveSoftLink = async (path: string, defaultValue: string) => {
  if (!path) {
    return;
  }

  const filter = defaultValue
    .split(";")
    .map((v) => `id eq '${v}'`)
    .join(" or ");
  const r = (await ajax.getClient(endpoints.publicUrl + "/" + path, "GET", undefined, undefined, {
    $filter: filter,
  })) as SoftLink[];

  const result = r.map<SoftLink>(oDataConverters.entityToSoftLink);
  return result;
};
