import { LoadingOverlay } from "cayo.ui";
import React, { FC, Fragment, useEffect, useRef } from "react";
import { Redirect, useLocation } from "react-router-dom";
import SSO from "../../api/sso";
import logger from "../../libs/logger";
import { endpoints } from "../../services/endpoints.service";
import { appSettings } from "../../settings/app-settings";
import globals from "../../settings/globals";
import tokenStorage from "../../settings/token-storage";
import downloadUtils from "../../utils/download-utils";
import queryUtils from "../../utils/query-utils";
import { urlUtils } from "../../utils/url-utils";
import NewAlertsPopup from "../Alerts/NewAlertsPopup";
import ODataApp from "../App/ODataApp";
import { WizardStuff } from "../App/WizardStuff";
import { appUtils } from "../App/utils";
import { ContextPanelHolder } from "../AppRoots";
import ActionDialogHolder from "../AppRoots/ActionDialogHolder";
import { FormsHolder } from "../AppRoots/FormsHolder";
import PropertiesPanelHolder from "../AppRoots/PropertiesPanelHolder/PropertiesPanelHolder";
import SchemeLoader from "../Schemes/SchemeLoader";
import useAuth from "./hooks";
import redirectUtils from "./redirect-utils";

const log = logger.getLogger("Pages");

const Pages: FC = () => {
  const location = useLocation();
  const accessToken = appSettings.accessToken;
  const userSetting = appSettings.userSetting.get();
  const loginHint = userSetting?.loginHint;
  const isHttp = !globals.isSecureConnection;
  const isSSOInProgress = useRef<boolean>();

  const authMode = isHttp ? "bearer" : SSO.Instance.authMode;
  const isIFrame = window.self !== window.top;
  log.debug("authmode: " + authMode, "isIFrame: ", isIFrame);

  const { isLoggedIn } = useAuth(endpoints.tokenEndpoint, tokenStorage);

  useEffect(() => {
    log.debug("isLoggedIn changed", isLoggedIn);

    if (isLoggedIn) {
      // hide errors from previous login session
      appUtils.showMessage("");
    }
  }, [isLoggedIn]);

  if (location.key) {
    log.debug("reload page, location.key", location.key);

    window.location.reload();
    return <Fragment />;
  }

  if (authMode === "office365" && !isSSOInProgress.current) {
    if (!accessToken) {
      log.debug("search: " + location.pathname, "isIFrame", isIFrame);

      const idToken = redirectUtils.getIdToken(location.pathname);
      if (!idToken) {
        isSSOInProgress.current = true;
        const isSrvEndpoint = endpoints.apiEndpoint.toString(); // + (getRedirectUrl() || window.location.hash);

        if (!isIFrame) {
          localStorage.setItem(
            redirectUtils.redirectParameterName,
            urlUtils.trim(window.location.hash)
          );
        }

        log.debug("sso redirect, isSrvEndpoint: " + isSrvEndpoint);
        log.debug("sso redirect, hash: " + window.location.hash);

        SSO.Instance.application
          .acquireTokenSilent({ loginHint, scopes: SSO.scopes })
          .then((response) => {
            log.debug("acquireTokenSilent success");
            sessionStorage.setItem("id_token", response.idToken?.rawIdToken);
            const redirectUrl = redirectUtils.getRelativeRedirectUrl();
            let file = queryUtils.getQueryParam("file", redirectUrl);

            if (!file && localStorage.getItem("download")) {
              file = localStorage.getItem("download");
              log.debug(`get download file ${file} from local storage`);
            }

            if (file) {
              log.debug(`file to download detected: ${file}`);

              downloadUtils.downloadODataFile(file);
              localStorage.setItem("download", file);
              window.location.assign("/download");
            } else {
              log.debug("acquireTokenSilent success, redirect to", redirectUrl);
              window.location.assign(redirectUrl);
            }
          })
          .catch((e) => {
            log.debug("silent authoriation failed", e);

            // if (e.errorCode === "block_token_requests") {
            //   appSettings.userSetting.update({ loginHint: undefined });
            //   SSO.Instance.application.loginRedirect({
            //     redirectUri,
            //     prompt: "none",
            //   });
            // } else

            const file = queryUtils.getQueryParam("file");
            if (file) {
              log.debug("file", file, "hash", window.location.hash);

              localStorage.setItem(
                redirectUtils.redirectParameterName,
                urlUtils.trim(window.location.hash)
              );
            }

            log.debug("isSrvEndpoint", isSrvEndpoint);

            if (e.errorCode === "login_required") {
              SSO.Instance.application.loginRedirect({
                redirectUri: isSrvEndpoint,
                prompt: "select_account",
                loginHint,
              });
            } else {
              SSO.Instance.application.loginRedirect({
                redirectUri: isSrvEndpoint,
                prompt: loginHint ? "none" : "select_account",
                loginHint,
              });
            }
          })
          .finally(() => {
            isSSOInProgress.current = false;
            log.debug("isSSOInProgress", isSSOInProgress.current);
          });

        return <LoadingOverlay text={"Signing in..."} />;
      } else {
        log.debug("id_token: " + idToken, "isFrame", isIFrame);

        sessionStorage.setItem("id_token", idToken);

        // if (isIFrame) {
        //   log.debug("token obtained, isIFrame", isIFrame);
        //   return <LoadingOverlay />;
        // }

        const redirectUrl = "/" + (localStorage.getItem(redirectUtils.redirectParameterName) || "");
        localStorage.removeItem(redirectUtils.redirectParameterName);

        log.debug("authorized with success, redirect to: " + redirectUrl);

        const file = queryUtils.getQueryParam("file", redirectUrl);
        if (file) {
          return downloadLandingPage(file);
        }

        isSSOInProgress.current = false;
        return <Redirect to={redirectUrl} />;
      }
    }
  }

  if (isIFrame || isSSOInProgress.current) {
    log.debug("isSSOInProgress", isSSOInProgress.current, "Signing in...");
    return <LoadingOverlay text={"Signing in..."} />;
  }

  if (!accessToken && location.pathname.toLowerCase() !== "/login") {
    const loginUrl = redirectUtils.getLoginUrl();

    return <Redirect to={loginUrl} />;
  }

  if (accessToken) {
    const file = queryUtils.getQueryParam("file");
    if (file) {
      return downloadLandingPage(file);
    } else if (window.location.hash?.endsWith("$value")) {
      let filePath = queryUtils.getQueryParam(redirectUtils.redirectParameterName);
      if (!filePath) {
        filePath = urlUtils.trim(window.location.hash);
      }

      const fileUrl = endpoints.publicUrl + "/v0/" + filePath;
      return downloadLandingPage(fileUrl);
    }
  }

  if (accessToken && location.pathname.toLowerCase() === "/login") {
    log.debug("login success -> currentLocation", location);

    const urlAfterLogin = "/" + locationAfterLogin(location);
    log.debug("urlAfterLogin: ", urlAfterLogin);

    return <Redirect to={urlAfterLogin} />;
  }

  let schemeId = location.pathname.startsWith("/")
    ? location.pathname.substr(1)
    : location.pathname;

  if (schemeId !== "login") {
    schemeId = "";
  }

  if (schemeId === "login") {
    return <SchemeLoader schemePath="login" />;
  }

  return (
    <ODataApp isLoggedIn={isLoggedIn}>
      <NewAlertsPopup />
      <PropertiesPanelHolder />
      <ActionDialogHolder />
      <ContextPanelHolder />

      <SchemeLoader schemePath="home" />

      <WizardStuff isLoggedIn={isLoggedIn} />
      <FormsHolder />
    </ODataApp>
  );
};

const downloadLandingPage = (url: string) => {
  log.debug("downloadLandingPage", url);
  localStorage.setItem("download", url);
  return <Redirect to={"/download"} />;
};

const locationAfterLogin = (location: { pathname: string; search: string }) => {
  log.debug("locationAfterLogin -> location", location);
  const search = new URLSearchParams(location.search);
  const redirectUrl = search.get("redirectUrl");
  const action = search.get("action");

  let result = redirectUrl;
  if (action) {
    result += "&action=" + action;
  }
  return result ?? "/";
};

export default Pages;
