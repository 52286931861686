import { ICommandBarItemProps, IContextualMenuItem, IContextualMenuProps } from "@fluentui/react";
import { CommandBarProps } from ".";
import { AlertRule, Entity, Job, OrderByDirection, SavedQuery } from "../../api/cayo-graph";
import { IBriefGridInfo, ICommandBarItem } from "../../api/schema.api";
import logger, { ILogger } from "../../libs/logger";
import ActionBuilder from "../../scheme/actions";
import { IPropertiesPanelSignals } from "../../services/properties-panel-signal.service";
import { appSettings } from "../../settings/app-settings";
import { GridViewSettings } from "../../settings/grid-view-settings";
import { objectUtils } from "../../utils/object-utils";
import { IGridState, UIInteraction } from "../GridContainer/grid-model/GridController";
import {
  DispatchAction,
  GridControllerAction,
} from "../GridContainer/grid-model/grid.controller.actions";
import { toSavedQuery } from "../GridContainer/grid-model/savedQuery.portal";

class CommandBarController {
  public readonly items?: ICommandBarItemProps[];
  private selectedItems?: Entity[];
  private briefGridInfo?: IBriefGridInfo;
  private gridViewSettings?: GridViewSettings | undefined;
  private isInPropsPanel?: boolean;

  dispatch: DispatchAction;
  gridState?: IGridState;
  uiInteraction: UIInteraction;
  savedQuery?: SavedQuery | { alertRule?: AlertRule; job?: Job };
  log: ILogger;
  signals?: IPropertiesPanelSignals;

  constructor(props: CommandBarProps, readonly actionBuilder: ActionBuilder) {
    this.isInPropsPanel = !!props?.object;
    this.signals = props.signals;

    // TODO: do not call model.dispatch in processResponse in case toolbar in the properties panel
    this.dispatch =
      props.dispatch ??
      (props?.signals?.send
        ? (a: GridControllerAction) => {
            props?.signals?.send(a.kind as any);
            return this.dispatchStub();
          }
        : this.dispatchStub);

    this.gridState = props.gridState;
    this.uiInteraction = props.uiInteraction;
    this.items = this.mapToCommandBarItems(props.scheme?.items);
    this.log = logger.getLogger("CommandBar2Props");
  }

  public update = (gridState?: IGridState) => {
    this.gridState = gridState;

    const hasSelection = !!gridState?.selectedItems?.length;
    const multipleSelected = hasSelection && gridState?.selectedItems?.length > 1;

    this.selectedItems = gridState?.selectedItems;
    this.briefGridInfo = this.getGridBriefInfo(gridState);
    this.gridViewSettings = gridState?.gridSettingsKey
      ? appSettings.viewSetting.getSubKey(gridState?.gridSettingsKey)
      : undefined;

    this.savedQuery = gridState?.query ? toSavedQuery(gridState.query) : undefined;
    if (this.savedQuery && gridState?.query?.job) {
      this.savedQuery.job = gridState?.query?.job;
    }

    if (this.savedQuery && gridState?.query?.alertRule) {
      this.savedQuery.alertRule = gridState?.query?.alertRule;
    }

    this.items?.forEach((i) => this.updateItem(i, hasSelection, multipleSelected));
    this.hideDisabledDuplicates(multipleSelected);
  };

  private hideDisabledDuplicates = (multipleSelected: boolean) => {
    if (this.items?.length) {
      const groups = objectUtils.groupBy(this.items, (i) => i.text);
      for (const group of groups) {
        if (group[1].length < 2) {
          continue;
        }

        const duplicates = group[1] as ICommandBarItemProps[];
        duplicates
          .filter((d) => d.multiselect !== multipleSelected)
          .forEach((i) => {
            i.visible = false;
            this.updateVisibility(i, false);
          });
        logger.debug(group);
      }
    }
  };

  private updateItem = (
    item: ICommandBarItemProps,
    hasSelection: boolean,
    multiselect: boolean
  ) => {
    const data = item.data as ICommandBarItem;
    if (data.key === "removeLink") {
      logger.debug("dd");
    }
    const selectedItem = this.selectedItems?.length ? (this.selectedItems[0] as any) : {};

    if (data.multiselect !== undefined) {
      item.visible = true;
    }

    this.updateVisibility(item, data.visible);

    if (this.gridViewSettings) {
      this.actionBuilder.resolveBindings(item, data.bindings, {
        ...selectedItem,
        view: this.gridViewSettings as any,
        savedQuery: this.savedQuery,
      });

      item.subMenuProps?.items?.forEach((i) => {
        this.actionBuilder.resolveBindings(i, i.data.bindings, {
          view: this.gridViewSettings as any,
        });
        this.updateSubMenuItem(i, hasSelection, multiselect);
      });
    }

    if (data.dependsOnSelection) {
      const hasDisabledBinding =
        data.bindings && !!Object.keys(data.bindings).find((b) => b === "disabled");

      if (!hasDisabledBinding) {
        item.disabled = !hasSelection || (!data.multiselect && multiselect);
      }

      this.handleCategories(item);
    } else {
      delete item.disabled;
    }

    if (this.isInPropsPanel) {
      this.actionBuilder.resolveBindings(item, data.bindings, { ...selectedItem });
    }

    this.updateVisibility(item, item.visible);
  };

  private updateSubMenuItem = (
    submenuItem: ICommandBarItemProps,
    hasSelection: boolean,
    multiselect: boolean
  ) => {
    const submenuData = submenuItem.data as ICommandBarItem;

    if (submenuData.dependsOnSelection) {
      const hasDisabledBinding =
        submenuData.bindings && !!Object.keys(submenuData.bindings).find((b) => b === "disabled");

      if (!hasDisabledBinding) {
        submenuItem.disabled = !hasSelection || (!submenuData.multiselect && multiselect);
      }
    }
  };

  private handleCategories = (item: ICommandBarItemProps) => {
    const data = item.data as ICommandBarItem;

    if (!item.disabled && data.disabledCategories) {
      const restorableItem = this.selectedItems?.find((i) => {
        const categories = (i as any).categories;
        if (!Array.isArray(categories)) {
          return false;
        }

        return (categories as ICategoryItem[])?.find((c) =>
          data.disabledCategories?.find((ds) => {
            const catName = c?.name?.toLowerCase();
            const disCatName = ds?.toLowerCase();
            return catName === disCatName && c?.value === "Disabled";
          })
        );
      });

      if (restorableItem) {
        item.disabled = true;
      }
    }
  };

  private mapToCommandBarItems = (items?: ICommandBarItem[]) => {
    return items?.map<ICommandBarItemProps>(this.commandBarItemConverter);
  };

  private commandBarItemConverter = (
    item: ICommandBarItem,
    index: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    array: ICommandBarItem[]
  ): ICommandBarItemProps => {
    const iconName = item?.icon?.iconName;
    var checked = item.checked;

    var result: ICommandBarItemProps = {
      key: index?.toString(),
      text: item.text,
      canCheck: item.canCheck,
      checked: checked,
      disabled: item.disabled,
      data: item,
      split: item.split,
      multiselect: item.multiselect,
      ariaLabel: item.text || iconName,
      splitButtonAriaLabel: 'See options',
      subMenuProps: item.subMenuProps?.length
        ? ({
            items: item.subMenuProps && this.mapToCommandBarItems(item.subMenuProps),
          } as IContextualMenuProps)
        : undefined,

      iconProps: {
        iconName,
        styles: {
          root: { display: iconName ? "block" : "none" },
        },
      },

      onClick: (e, item) => {
        this.onItemClick(e, item);
      },
    };

    return result;
  };

  onItemClick = async (
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem
  ) => {
    const cmdBarItem = item?.data as ICommandBarItem;
    const action = cmdBarItem.action;
    const ajaxAction = cmdBarItem.ajaxAction;

    if (action) {
      await Promise.all(
        this.actionBuilder.buildAction(action!, {
          parentId: this.gridState?.id,
          objects: cmdBarItem.multiselect !== undefined ? this.selectedItems : undefined,
          confirmationText: ajaxAction?.confirmation?.description,
          gridState: this.gridState,
          actionParameters: cmdBarItem.actionParameters,
          dispatch: this.dispatch,
          onSaveComplete: () => {
            if (cmdBarItem.multiselect !== undefined) {
              if (this.isInPropsPanel && action.endsWith("delete")) {
                // TODO: make via UI scheme
                this.signals?.send("close");
                return;
              }

              return this.dispatch({ kind: "refresh" });
            }

            return undefined;
          },
        })
      );
    }

    if (ajaxAction) {
      this.actionBuilder.buildAjaxAction(
        ajaxAction,
        cmdBarItem.multiselect || cmdBarItem.dependsOnSelection ? this.selectedItems : undefined,
        {
          gridInfo: this.briefGridInfo,
          gridId: this?.gridState?.id as any,
          view: this.gridViewSettings,
          gridState: this.gridState,
          savedQuery: this.savedQuery,
          dispatch: this.dispatch,
        },
        () => this.dispatch({ kind: "loading", loading: true }),
        (error) => {
          if (!error) {
            (this.dispatch({ kind: "reloadQuery" }) as Promise<void>).finally(() => {
              if (cmdBarItem.multiselect !== undefined || cmdBarItem.key?.indexOf(".create") > 0) {
                this.dispatch({ kind: "refresh" });
              } else {
                this.dispatch({ kind: "loading", loading: false });
              }
            });
          } else {
            this.dispatch({ kind: "loading", loading: false });
          }

          return Promise.resolve();
        }
      );
    }
  };

  private updateVisibility = (item: ICommandBarItemProps, visible?: boolean) => {
    if (visible === true) {
      delete item.buttonStyles;
      delete item.itemProps;
    } else if (visible === false) {
      item.buttonStyles = { root: { display: "none" } };
      item.itemProps = { styles: { root: { display: "none" } } };
    }
  };

  private getGridBriefInfo = (gridState?: IGridState) => {
    try {
      const columns = gridState?.columns?.filter((c) => !!c);
      const sortedColumn = columns?.find((c) => c?.isSorted);
      const defaultOrderBy = sortedColumn?.fieldName;
      const defaultOrderDirection: OrderByDirection = sortedColumn?.isSortedDescending
        ? "descending"
        : "ascending";

      const result: IBriefGridInfo = {
        filter: gridState?.query?.effectiveFilter,
        orderBy: gridState?.query?.orderBy?.field ?? defaultOrderBy,
        orderDirection: gridState?.query?.orderBy?.direction ?? defaultOrderDirection,
        properties: !columns ? undefined : columns.map((c) => c.fieldName!),
      };

      return result;
    } catch (e) {
      this.log.error("Invalid grid state", gridState);
      throw e;
    }
  };

  private dispatchStub = () => {
    return Promise.resolve();
  };
}

interface ICategoryItem {
  name: string;
  value: string;
}

export default CommandBarController;
