import { Stack } from "@fluentui/react";
import { ITheme } from "cayo.ui";
import React, { FC, Fragment } from "react";
import styled from "styled-components";
import Icon from "./Graphics/Icon";

const ObjectIcon: FC<{ iconName?: string; color?: string; backgroundColor?: string }> = ({
  iconName,
  color,
  backgroundColor,
}) => {
  if (!iconName) {
    return <Fragment />;
  }

  return (
    <Wrapper verticalAlign={"center"} horizontalAlign={"center"} backgroundColor={backgroundColor}>
      <Icon iconName={iconName} style={{ color }} />
    </Wrapper>
  );
};

const Wrapper = styled(Stack)<{ backgroundColor?: string }>`
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${(props) => (props.theme as ITheme).cayoTheme.brandColors.divider};
  flex-shrink: 0;
  font-size: 18px;
  font-weight: bolder;
  background-color: ${(props) => props.backgroundColor};
  opacity: ${(props) => props.backgroundColor && "100%"};
`;

export default ObjectIcon;
