import React from "react";
import { IDropdownOption } from "../../api/schema.api";
import { classNames } from "./styles";

export const onRenderCell = (
  item?: IDropdownOption,
  index?: number,
  isScrolling?: boolean,
  onClick?: (item: IDropdownOption) => void
): JSX.Element => {
  return (
    <div
      className={item?.selected ? classNames.itemCellSelected : classNames.itemCell}
      data-is-focusable
      key={index}
      onClick={() => item && onClick && onClick(item)}
    >
      {item?.text}
    </div>
  );
};
