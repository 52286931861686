type WeekInfo = { firstDay: number };

function getWeekdays(localeName = "en") {
  const localeInfo = new Intl.Locale(localeName);
  const firstWeekDay = ((localeInfo as any)?.weekInfo as WeekInfo)?.firstDay ?? 7;

  const format = new Intl.DateTimeFormat(localeName, { weekday: "long" }).format;
  let result = [...Array(7).keys()].map((day) => format(new Date(2023, 2, day + 6)));

  if (firstWeekDay !== 1) {
    const weekend = result.splice(firstWeekDay - 1);
    result.unshift(...weekend);
  }

  return result;
}

const calendarUtils = {
  getWeekdays,
};

export default calendarUtils;
