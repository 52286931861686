import { Stack } from "@fluentui/react";
import styled, { CSSProperties, StyledComponent } from "styled-components";

export const StyledDiv = styled.div`
  visibility: collapse;
`;

export const MessageCarouselContainer = styled(Stack)`
  position: relative;
`;

export const extendEffectContainer = (
  C: StyledComponent<"div", any>,
  textAreaStyle: CSSProperties
) => styled(C)`
  flex-grow: 1;
  ${{ ...textAreaStyle }}
`;
