import { ITextField } from "cayo.ui";
import { IntlShape } from "react-intl";
import { MinutelySchedule } from "../../api/cayo-graph";
import { IFormField } from "../../api/schema.api";
import { nameofFactory } from "../../utils/object-utils";
import { regexs } from "../../utils/regexs";
import { formComponentTypes } from "../Form/renderers/form-renderers";
import messages from "./messages";

const minutelyNames = nameofFactory<MinutelySchedule>();

const minutelyForm = (intl: IntlShape, schedule?: MinutelySchedule) => {
  return {
    title: intl.formatMessage(messages.minutely),
    items: [
      {
        type: "number" as formComponentTypes,
        label: intl.formatMessage(messages.repeatEvery),
        autoFocus: true,
        required: true,
        pattern: regexs.positiveInt32Regex.source,
        name: minutelyNames("period"),
        value: schedule?.period?.toString() || "1",
        tip: intl.formatMessage(messages.minutesTip),
      } as ITextField,
    ] as IFormField[],
  };
};

export default minutelyForm;
