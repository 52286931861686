import { Html } from "cayo.ui";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { FCC } from "../../types/react";
import DownloadFile from "../DownloadFile";
import LoginError from "../LoginError";
import Pages from "./Pages";
import redirectPage from "./redirect.html";

const AppRouting: FCC = ({ children }) => {
  return (
    <>
      <Switch>
        <Route
          exact={true}
          path="/download"
          render={() => {
            return <DownloadFile />;
          }}
        />
        <Route exact={true} path="/redirect" render={() => <Html value={redirectPage} />} />
        <Route exact={true} path="/loginerror" render={() => <LoginError />} />

        <Route exact={false} path="/" render={() => <Pages />} />
      </Switch>
      {children}
    </>
  );
};

export default AppRouting;
