import { ITheme } from "cayo.ui";
import React from "react";
import styled from "styled-components";
import { FCC } from "../../types/react";

const Error: FCC = (props) => {
  return <ErrorContainer>{props.children}</ErrorContainer>;
};

const ErrorContainer = styled.span`
  color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.critical};
`;

export default Error;
