import { Html } from "cayo.ui";
import React, { FC } from "react";
import { IWidget } from "../../api/schema.api";
import useSignals from "../../services/signal-service-base";
import useRefreshKey from "../GlobalHooks/use-refresh-key.hook";
import GenericScheme from "../Schemes/GenericScheme";
import { EmptyContainer } from "./common";
import WidgetContainer from "./Container";
import WidgetHeader from "./Header";

const Widget: FC<IWidget & { data?: any; noDataMessage?: string; onRefresh?: () => void }> = (
  props
) => {
  const {
    name,
    title,
    titleSeparator,
    hideHeader,
    stick,
    noDataMessage,
    noLicenseMessage,
    onRefresh,
    showClock,
    help,
  } = props;
  const { key, forceRefresh, updateTime } = useRefreshKey(`widget_${name}`);

  const signals = useSignals<"refresh">([{ s: "refresh", handler: forceRefresh }]);

  return (
    <WidgetContainer key={key} stick={stick} style={{ alignContent: "stretch" }}>
      {title && !hideHeader && (
        <WidgetHeader
          title={title}
          titleSeparator={titleSeparator !== false}
          onRefresh={onRefresh ?? forceRefresh}
          showClock={!noLicenseMessage && showClock}
          updateTime={updateTime}
          help={help}
        />
      )}
      {noDataMessage && !noLicenseMessage && <EmptyContainer>{noDataMessage}</EmptyContainer>}
      {noLicenseMessage && (
        <EmptyContainer>
          <Html value={noLicenseMessage} />
        </EmptyContainer>
      )}
      {!noDataMessage && !noLicenseMessage && (
        <GenericScheme {...props} signals={signals} refreshKey={key} />
      )}
    </WidgetContainer>
  );
};

export default Widget;
