import { ICheckbox, IJsonForm, ITextField } from "../../../api/schema.api";

// type DbType = "builtin" | "azure" | "on-prem";

const onPremSqlForm: IJsonForm = {
  type: "json-form",
  items: [
    {
      pattern: "",
      isMultiline: false,
      disabled: false,
      required: true,
      placeHolder: "Enter a SQL Server name like Host\\Instance",
      label: "SQL Server name",
      tooltip: "",
      name: "server",
      type: "text-field",
      isConfidential: false,
      autoFocus: true,
    } as ITextField,
    {
      value: "Cayo.Guardian",
      pattern: "",
      isMultiline: false,
      disabled: false,
      required: false,
      placeHolder: "",
      label: "Database name",
      tooltip: "",
      name: "database",
      type: "text-field",
      isConfidential: false,
    } as ITextField,
    {
      pattern: "",
      isMultiline: false,
      disabled: false,
      required: false,
      placeHolder: "Enter the database directory path or the full path to the MDF file",
      label: "Database path",
      tooltip: "",
      name: "databasePath",
      type: "text-field",
      isConfidential: false,
    } as ITextField,
    {
      value: false,
      label: "SQL Server authentication",
      tooltip: "",
      name: "sqlAuthentication",
      type: "checkbox",
    } as ICheckbox,
    {
      pattern: "",
      isMultiline: false,
      disabled: false,
      required: false,
      placeHolder: "",
      label: "Login name",
      tooltip: "",
      name: "user",
      type: "text-field",
      dependsFrom: "sqlAuthentication",
      isConfidential: false,
      bindings: { disabled: "sqlAuthentication ne true" },
    } as ITextField,
    {
      pattern: "",
      isMultiline: false,
      disabled: false,
      required: false,
      placeHolder: "",
      label: "Password",
      tooltip: "",
      name: "password",
      type: "text-field",
      dependsFrom: "sqlAuthentication",
      isConfidential: true,
      bindings: { disabled: "sqlAuthentication ne true" },
    } as ITextField,
    {
      pattern: "",
      isMultiline: false,
      disabled: false,
      required: false,
      placeHolder: "Example: Connection Timeout=30;Encrypt=False",
      label: "Additional connection parameters",
      tooltip: "",
      name: "additionalParameters",
      type: "text-field",
      isConfidential: false,
    } as ITextField,

    // {
    //   value: false,
    //   label: "Restart service after the configuration is applied",
    //   tooltip: "Restart the Guardian service when the database configuration is applied.",
    //   name: "restart",
    //   type: "checkbox",
    //   visible: false,
    // } as ICheckbox,
    {
      value: true,
      label: "Copy Guardian configuration data to the new database",
      tooltip:
        "Select this option to copy managed system registrations, job configurations, saved queries, alerting rules and other configuration data. Change History, Execution History and collected events would not be copied. This Guardian Service would connect to all managed systems and start a new collection.",
      name: "copyData",
      type: "checkbox",
      visible: false,
    } as ICheckbox,
    {
      value: true,
      label: "I confirm this database is not used by another Guardian Service",
      tooltip:
        "Select this option if the target database was copied or moved from the previous installation of the Cayosoft Guardian. This Guardian Service would take the ownership over this copy of the database even if it contains information about previous owner.",
      name: "overrideOwner",
      type: "checkbox",
      visible: false,
    } as ICheckbox,
    {
      value: true,
      label: "Create the specified database if it is not present on the SQL server",
      tooltip: "",
      name: "createMissingDatabase",
      type: "checkbox",
      visible: false,
    } as ICheckbox,
  ],
  title: "Configure Database",
  submit: {
    url: "v0/settings/serviceSettings/094781f7355b4b958452716cb95380b0/cayo.graph.storageSettings/validateDatabase",
    method: "POST",
  },
};

export default onPremSqlForm;
