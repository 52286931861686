import React, { FC } from "react";
import { useContextPanel } from "../GlobalHooks/context-panel.hook";
import Panel from "../Surfaces/Panel";

const ContextPanelHolder: FC = () => {
  const { contextPanel } = useContextPanel();

  return (
    <>
      {contextPanel && (
        <Panel {...contextPanel} style={{ ...(contextPanel?.style || {}), overflowX: "hidden" }} />
      )}
    </>
  );
};

export default ContextPanelHolder;
