import { IColumn as IOfficeColumn } from "@fluentui/react";
import { Separator } from "cayo.ui";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { SavedQuery } from "../../api/cayo-graph";
import logger from "../../libs/logger";
import useService from "../../services/services.hook";
import { getLazyProps } from "../../utils/lazy-props";
import { useSchemes } from "../App/hooks";
import CommandBar from "../CommandBar";
import { commandBarMessages } from "../CommandBar/messages";
import { useToggleNavCollapsed } from "../Nav/nav-hooks";
import { SavedQueryPicker } from "../ObjectPicker/SavedQueryPicker";
import QueryFilterForm from "../QueryFilterForm";
import { commonMessages } from "../common-messages";
import { ChooseColumnsDialog } from "./ChooseColumnsDialog";
import { ColumnData } from "./ChooseColumnsDialog.controller";
import { GridDetails } from "./GridDetails";
import { GridFilterBar } from "./GridFilterBar";
import { makeQueryFromAnnotations } from "./GridFilterBar.utils";
import { createController } from "./controller-factory";
import { GridContainerProps, GridController, IGridState } from "./grid-model/GridController";
import { GridControllerAction } from "./grid-model/grid.controller.actions";
import { fromSavedQuery } from "./grid-model/savedQuery.portal";
import { appSignals } from "../App/utils";
import { fetchObjects } from "../ObjectPicker/logic";

const mainGridId = "ctx_grid_grid";

export function GridContainer(props: GridContainerProps): JSX.Element {
  const log = logger.getLogger("QUICK-FILTER-NEW GridContainer");
  const uiInteraction = useService("uiInteraction");
  const [selectedSavedQuery, setSelectedSavedQuery] = useState<SavedQuery>();

  const intl = useIntl();
  const location = useLocation();
  const { showPropertiesPanel } = useSchemes();
  const { gridSettingsKey } = props;

  const { detailsList, commandBar, filterBar, targetType, savedQueries, isSearchSupported } =
    props.scheme;

  const readonlyQueries = !!savedQueries?.readOnly;

  const groupConfig = useMemo(
    () =>
      detailsList?.columns
        ?.filter((c) => !!c.groupByTitle)
        .map((c) => ({ key: c.key, text: c.groupByTitle! })),
    [detailsList?.columns]
  );

  // TODO: move to controller
  const allColumnsData = useRef(
    detailsList?.columns?.map((c) => ({ fieldName: c.fieldName!, displayName: c.displayName! })) ??
      []
  ).current;

  const [ctrl] = useState<GridController>(() => createController(props, uiInteraction));
  const [gridState, setGridState] = useState<IGridState>(ctrl.state);
  const dispatch = useCallback((action: GridControllerAction) => ctrl.dispatch(action), [ctrl]);
  const queryUrl = props.scheme?.savedQueries?.picker?.path;

  useLayoutEffect(function mount() {
    log.debug(".mount -> gridSettingsKey,  props", gridSettingsKey, props);
    ctrl.subscribe((gs) => {
      log.debug("onStateChanged -> GridState", gs);
      setGridState(gs);
    });
    ctrl.initialize();

    const actionUrl = getLazyProps(location.pathname); // TODO: invent something more clever
    if (actionUrl) {
      showPropertiesPanel({ id: actionUrl, parentId: detailsList!.id });
    }

    return () => {
      ctrl.dispose();
      log.debug(".unmount");
    };
  }, []);

  useEffect(() => {
    return appSignals.subscribe("showSavedQueryForm", (data: any) => {
      const { parameters } = data;
      dispatch({
        kind: "loading",
        loading: true,
      });
      fetchObjects({
        path: `${savedQueries?.picker?.path}/${parameters.id}`,
        expand: "createdBy",
      })
        .then((response: any) => {
          setSelectedSavedQuery(response);
          dispatch({
            kind: "openEditForm",
            query: fromSavedQuery({
              id: parameters.id,
              builtIn: response?.builtIn,
              name: parameters.objectName,
              advancedFilter: response?.advancedFilter,
              anrFilter: response?.anrFilter,
            }),
          });
        })
        .catch((e) => {
          log.debug(e);
        })
        .finally(() => {
          dispatch({
            kind: "loading",
            loading: false,
          });
        });
    });
  }, []);

  //#region collapse left navigation tree
  //handle open saved query list
  const { collapsed, toggleCollapsed } = useToggleNavCollapsed();
  const collapsedOnQueryPicker = useRef(false);
  useEffect(
    function handleshowQueryPicker() {
      if (detailsList?.id !== mainGridId) {
        return;
      }
      if (gridState.showQueryPicker && !collapsed) {
        collapsedOnQueryPicker.current = true;
        toggleCollapsed();
      } else if (collapsedOnQueryPicker.current && !gridState.showQueryPicker && collapsed) {
        toggleCollapsed();
        collapsedOnQueryPicker.current = false;
      }
    },
    [gridState.showQueryPicker]
  );
  //#endregion
  return (
    <>
      {commandBar && (
        <CommandBar
          scheme={commandBar!}
          gridState={gridState}
          dispatch={dispatch}
          uiInteraction={uiInteraction}
          groupConfig={groupConfig}
          showFilterBarItems={!filterBar && !!isSearchSupported}
        />
      )}
      {filterBar ? (
        <GridFilterBar
          schema={filterBar}
          columns={detailsList?.columns}
          query={gridState.query}
          propertyAnnotations={props.propertyAnnotations}
          dispatch={dispatch}
          savedQueries={gridState.savedQueries}
          recentQueries={gridState.recentQueries}
          favoriteQueries={gridState.favoriteQueries}
          groupConfig={groupConfig}
          targetType={props.scheme.targetType}
          isSearchSupported={!!isSearchSupported}
          readonly={readonlyQueries}
          gridSettingsKey={props.gridSettingsKey}
          queryMode={gridState.queryMode}
          queryUrl={queryUrl!}
        />
      ) : props?.scheme?.detailsList?.compact ? (
        <Fragment />
      ) : (
        <Separator />
      )}
      <div
        style={{
          flex: "1 1 300px",
          width: "1px",
          minWidth: "100%",
          overflow: "auto",
        }}
      >
        <GridDetails
          query={gridState.query}
          columns={gridState.columns}
          ajustColumnsToken={gridState.ajustColumnsToken}
          items={gridState.searchResult.items}
          groups={gridState.searchResult.groups}
          itemMenu={gridState.itemMenu}
          skipToken={gridState.searchResult.skipToken}
          propertyAnnotations={props.propertyAnnotations}
          isLoading={gridState.loading}
          dispatch={dispatch}
          gridSettingsKey={gridSettingsKey}
          schema={detailsList!}
          selectedItems={gridState.selectedItems}
        />
      </div>
      {/* query picker */}
      {gridState.showQueryPicker && (
        <SavedQueryPicker
          id="_pm"
          closeButtonName={intl.formatMessage(commonMessages.select)}
          title={intl.formatMessage(commandBarMessages.browseAll)}
          favoriteIds={gridState.favoriteQueries}
          dispatch={dispatch}
          path={`${savedQueries?.picker?.path}?$select=*&$filter=targetType eq '${targetType}'`}
          onClose={() => dispatch({ kind: "closeQueryPicker" })}
          isPreviewImmediate={!props.scheme.filterBar?.previewQueryOnDemand}
          actions={{
            onNewClick: readonlyQueries
              ? undefined
              : (_, item) => {
                  log.debug("ObjectPickerModal.onNewClick -> item", item);
                  dispatch({
                    kind: "createNewQuery",
                    newQuery: makeQueryFromAnnotations(filterBar?.annotations),
                    refreshCallback: item?.data.onFilterSaved,
                  });
                },
            onPropsClick: (_, item, selectedFilter) => {
              log.debug("ObjectPickerModal.onPropsClick -> item.data", item?.data);
              if (!item?.data?.filter) return;
              selectedFilter && setSelectedSavedQuery(selectedFilter);
              dispatch({
                kind: "openEditForm",
                query: fromSavedQuery(item.data.filter),
                refreshCallback: item?.data.onFilterSaved,
              });
            },
            onDeleteClick: (_, item) => {
              if (!item?.data?.filter) {
                return;
              }
              const query = fromSavedQuery(item.data.filter as SavedQuery);
              log.debug("ObjectPickerModal.onDeleteClick -> item, query", item, query);
              if (query.isSystem) return; // ? show error ?

              dispatch({ kind: "deleteQuery", query, onComleted: item?.data.onFilterSaved });
            },
          }}
          onItemsSelected={async (items) => {
            log.debug("ObjectPickerModal.onItemsSelected -> items", items);
            const item = items[0] as SavedQuery;
            if (!item) return;

            dispatch({
              kind: "selectQuery",
              queryId: item.id,
            });
          }}
        />
      )}
      {gridState.showEditForm && (
        <QueryFilterForm
          items={filterBar?.annotations}
          url={savedQueries?.picker?.path}
          targetType={targetType}
          gridId={detailsList?.id}
          readonly={readonlyQueries}
          selectedSavedQuery={selectedSavedQuery}
          setSelectedSavedQuery={setSelectedSavedQuery}
          propertyAnnotations={props.propertyAnnotations}
          {...(gridState.showEditForm?.prms || {})}
          onClose={() => {
            log.debug("QueryFilterForm.onClose");
            dispatch({ kind: "closeEditForm" });
          }}
        />
      )}
      {gridState.showChooseColumns && (
        <ChooseColumnsDialog
          onClose={() => {
            log.debug("ChooseColumnsDialog.close");
            dispatch({ kind: "closeChooseColumnsDialog" });
          }}
          onSave={(columns: string[]) => {
            log.debug("ChooseColumnsDialog.save -> columns", columns);
            dispatch({ kind: "applyColumns", columns });
          }}
          allColums={allColumnsData}
          userColumns={gridState.columns.map(toColumnData)}
        />
      )}
    </>
  );
}

//TODO: move to controller
function toColumnData(column: IOfficeColumn): ColumnData {
  return { fieldName: column.fieldName!, displayName: column.name };
}
