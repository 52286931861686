import {
  DetailsRow,
  FocusZone,
  FocusZoneDirection,
  GroupedList,
  IGroupHeaderProps,
  IGroupRenderProps,
  INavLink,
  IObjectWithKey,
  List,
  Selection,
  SelectionMode,
  SelectionZone,
} from "@fluentui/react";
import { useTheme } from "cayo.ui";
import React, { FC, ReactNode, useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { PropertyAnnotation } from "../../api/cayo-graph";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
import { IActionPromise } from "../LegacyDetailsList/cell-renderers";
import CustomGroupHeader from "./GroupHeader";
import useTree from "./tree.hooks";
import treeRenderers from "./tree.renderers";
import treeStyles, { CONTAINER_WIDTH, MAX_CELL_WIDTH, TreeContainer } from "./tree.styles";

type TreeProps = {
  rootUrl: string;
  onItemSelected: IActionPromise;
  propertyAnnotations?: PropertyAnnotation[];
  refreshInterval: number;
};

const getSelectedItem = (selection: Selection) => {
  const selectedItems = selection.getSelection();
  const selectedItem = selectedItems.length ? (selectedItems[0] as INavLink) : undefined;

  return selectedItem;
};

const Tree: FC<TreeProps> = ({
  rootUrl,
  refreshInterval,
  onItemSelected,
  propertyAnnotations,

  // log,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const { items, groups, selectedKey, selectItem } = useTree(
    rootUrl,
    refreshInterval,
    intl,
    (item) => onItemSelected(undefined, undefined, item)
  );
  // log.debug("WORKFLOW render -> items, groups", items, groups);
  const typeAnnotations = useTypeAnnotations();

  const selection = useMemo(() => {
    const newSelection = new Selection({
      selectionMode: SelectionMode.single,
      items: items as IObjectWithKey[],
      onSelectionChanged: () => {
        const selectedItem = getSelectedItem(selection);
        if (selectedItem) {
          selectItem(selectedItem);
        }
      },
    });

    return newSelection;
  }, [items, selectItem]);

  useEffect(() => {
    if (!selectedKey && (groups.length || items.length)) {
      selectItem(groups[0] || items[0]);
    }
  }, [groups, selectedKey, items]);

  const onRenderItemColumn = useCallback(
    (item?: any, index?: number) => {
      return treeRenderers.workFlowItem(item, index!, selectedKey === item?.key, {
        intl,
        theme,
        getTypeAnnotation: typeAnnotations.getTypeAnnotation,
        getIconAnnotation: typeAnnotations.getIconAnnotation,
        propertyAnnotations: propertyAnnotations,
      });
    },
    [selectedKey, items]
  );

  const getRowStyle = useCallback((item?: INavLink, selectedKey?: string) => {
    return treeStyles.detailsRow(theme, MAX_CELL_WIDTH, selectedKey === item?.key);
  }, []);

  const onRenderCell = useCallback(
    (nestingDepth?: number, item?: INavLink, itemIndex?: number): ReactNode => {
      return item && typeof itemIndex === "number" && itemIndex > -1 ? (
        <DetailsRow
          key={item.url}
          columns={[
            {
              key: "key",
              name: "name",
              fieldName: "name",
              minWidth: MAX_CELL_WIDTH,
              maxWidth: MAX_CELL_WIDTH,
            },
          ]}
          groupNestingDepth={nestingDepth}
          item={item}
          itemIndex={itemIndex}
          compact={true}
          onRenderItemColumn={onRenderItemColumn}
          indentWidth={31}
          styles={getRowStyle(item, selectedKey)}
        />
      ) : null;
    },
    [selectedKey, items, groups, getRowStyle, onRenderItemColumn]
  );

  const groupProps: IGroupRenderProps = useMemo(
    () => ({
      onRenderHeader: (props?: IGroupHeaderProps): JSX.Element => {
        return (
          <CustomGroupHeader
            {...props}
            selected={selectedKey === props?.group?.key}
            onGroupSelected={(g) => {
              selectItem(g);
            }}
          />
        );
      },
      showEmptyGroups: true,
      headerProps: {
        indentWidth: 18,
        compact: true,
        styles: {
          groupHeaderContainer: {
            flexGrow: 1,
            cursor: "pointer",
            paddingRight: 8,
            selectors: {
              "& .ms-GroupSpacer": { minWidth: 18 },
              "& .ms-GroupSpacer:first-child": { display: "none !important" },
            },
          },
        },
      },
    }),
    [onItemSelected, selectedKey, selectItem]
  );

  const classNames = useMemo(() => {
    return treeStyles.flatListStyle(theme);
  }, []);

  const onRenderFlatCell = useCallback(
    (
      item?: INavLink,
      index?: number,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isScrolling?: boolean
    ): JSX.Element => {
      const isSelected = selectedKey === item?.key;
      return (
        <div
          className={classNames.itemCell + (isSelected ? " " + classNames.itemSelected : "")}
          data-is-focusable={true}
          onClick={() => {
            selectItem(item);
          }}
        >
          {treeRenderers.workFlowItem(item, index!, selectedKey === item?.key, {
            intl,
            theme,
            getTypeAnnotation: typeAnnotations.getTypeAnnotation,
            getIconAnnotation: typeAnnotations.getIconAnnotation,
            propertyAnnotations: propertyAnnotations,
          })}
        </div>
      );
    },
    [items, groups, selectedKey, selectItem]
  );

  return (
    <TreeContainer>
      <FocusZone direction={FocusZoneDirection.vertical}>
        <SelectionZone
          selection={selection}
          selectionMode={SelectionMode.single}
          selectionPreservedOnEmptyClick={true}
        >
          {groups?.length ? (
            <GroupedList
              onRenderCell={onRenderCell}
              selectionMode={SelectionMode.single}
              groups={groups}
              compact={true}
              onShouldVirtualize={() => false}
              items={items}
              styles={{
                root: {
                  width: CONTAINER_WIDTH,
                  height: "100%",
                  overflowY: "auto",
                  paddingRight: 0,
                  marginBottom: 20,
                },
                group: {},
              }}
              groupProps={groupProps}
            />
          ) : (
            <div className={classNames.container} data-is-scrollable>
              <List items={items} onRenderCell={onRenderFlatCell} />
            </div>
          )}
        </SelectionZone>
      </FocusZone>
    </TreeContainer>
  );
};

export default Tree;
