import { Link, Stack } from "@fluentui/react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { EmptyContainer } from "../Widgets/common";
import dashboardMessages from "./messages";

type EmptyDashboardProps = { onResetView: () => void };

const DashboardError = (props: EmptyDashboardProps) => {
  const { onResetView } = props;
  const intl = useIntl();
  return (
    <EmptyContainer>
      <FormattedMessage
        id="error-dashboard.message"
        defaultMessage="Sorry.. Something went wrong. "
      />
      <br />
      <br />

      <Stack horizontal={true} verticalAlign="center" tokens={{ childrenGap: 4 }}>
        <Link onClick={onResetView}>{intl.formatMessage(dashboardMessages.resetView)}</Link>
      </Stack>
    </EmptyContainer>
  );
};

export default DashboardError;
