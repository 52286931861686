import { INavLink, INavLinkGroup } from "@fluentui/react";
import { IDropdownOption } from "../../api/schema.api";

class SearchNavController {
  public static readonly maxResults = 10;

  constructor(
    readonly groups: INavLinkGroup[],
    private readonly resolveNameFn: (path: string) => string
  ) {}

  public find = (searchText?: string) => {
    if (!searchText) {
      return [];
    }

    const result: INavLink[] = [];
    searchText = searchText.toLowerCase();
    this.walkGroups(this.groups as INavLink[], (l) => {
      if (l?.name?.toLowerCase()?.indexOf(searchText!) > -1 && l?.url) {
        if (result.length < SearchNavController.maxResults) {
          result.push(l);
        }
      }
    });

    return result.map<IDropdownOption>((r) => ({
      key: r.key!,
      text: this.resolveNameFn(r.key!),
      value: r.url,
    }));
  };

  private walkGroups = (groups: INavLink[], callback: (g: INavLink) => void) => {
    groups.forEach((g) => {
      callback(g);
      if (g?.links?.length) {
        this.walkGroups(g.links, callback);
      }
    });
  };
}

export default SearchNavController;
