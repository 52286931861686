import { IconButton, PanelType as OfficePanelType, Stack } from "@fluentui/react";
import React, { FC } from "react";
import { Row } from "../CommonLayout";
import { IClosableComponent } from "../Surfaces/Panel";
import { IResizerProps } from "./resizer.hook";

const NavigationCommands: FC<IResizerProps & IClosableComponent & { onRefresh?: () => void }> = ({
  actualType,
  helpLink,
  handleFullscreenToggled,
  onClose,
  onRefresh,
}) => {
  const expanderName = actualType === OfficePanelType.smallFluid ? "BackToWindow" : "FullScreen";

  return (
    <Row
      style={{
        position: "absolute",
        right: 0,

        flexGrow: 1,
      }}
    >
      <Stack
        horizontal={true}
        horizontalAlign={"end"}
        grow={true}
        style={{ padding: "8px 8px 0 8px" }}
        tokens={{ padding: 8, childrenGap: 4 }}
      >
        {helpLink && (
          <IconButton iconProps={{ iconName: "Help" }} onClick={() => window.open(helpLink)} />
        )}
        {onRefresh && (
          <IconButton
            iconProps={{ iconName: "Refresh" }}
            onClick={onRefresh}
            ariaLabel="Refresh Button"
          />
        )}
        <IconButton
          iconProps={{
            iconName: expanderName,
          }}
          ariaLabel={`${expanderName} Button`}
          onClick={handleFullscreenToggled}
        />
        <IconButton iconProps={{ iconName: "cancel" }} ariaLabel="Close Button" onClick={onClose} />
      </Stack>
    </Row>
  );
};

export default NavigationCommands;
