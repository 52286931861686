import { DefaultButton, IconButton, Link, Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { Html, LoadingOverlay } from "cayo.ui";
import React, { FC, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { IContentScheme, IODataWidget } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import useService from "../../services/services.hook";
import { urlUtils } from "../../utils/url-utils";
import CommandBar from "../CommandBar";

import useRefreshKey from "../GlobalHooks/use-refresh-key.hook";
import { GridContainer } from "../GridContainer/GridContainer";
import { useGridSettingsKey } from "../Schemes/ODataScheme/hook";
import { EmptyContainer } from "./common";
import WidgetContainer from "./Container";
import useWidgetSignals from "./dataset-signals.hook";
import WidgetHeader from "./Header";

const ODataWidget: FC<IODataWidget> = (props) => {
  const { title, hideHeader, scheme, noLicenseMessage } = props;
  const { data: propertyAnnotations, isLoading: isAnnotationsLoading } =
    ajax.usePropertyAnnotations(`v0/{schemeId}`, { schemeId: props.scheme?.entitySetPath });

  const [isSearchButtonVisible, { toggle: toggleSearchButtonVisible }] = useBoolean(false);
  const [isConfigButtonVisible, { toggle: toggleConfigButtonVisible }] = useBoolean(false);
  const [height, setHeight] = useState<number>();
  const history = useHistory();
  const uiInteraction = useService("uiInteraction");

  const actualScheme = useMemo(() => {
    if (!scheme) {
      logger.debug("d");
      return undefined;
    }
    const result = JSON.parse(JSON.stringify(scheme)) as IContentScheme;
    delete result.commandBar;
    delete result.filterBar?.annotations;
    delete result.commandBar;

    //delete result.savedQueries;

    result.detailsList!.compact = true;
    result.detailsList!.selectionMode = "single";
    result.detailsList!.noPumping = true;
    if (height) {
      result.detailsList!.pageSize = Math.max(
        result.detailsList!.pageSize ?? 5,
        Math.round((height - 80) / 40)
      );
    }

    result.filterBar!.showSavedQueries = isConfigButtonVisible;

    if (!isSearchButtonVisible && !isConfigButtonVisible) {
      delete result.filterBar;
    }

    return result;
  }, [scheme, isSearchButtonVisible, isConfigButtonVisible, height]);

  const { key: refreshKey, forceRefresh, updateTime } = useRefreshKey("odata");

  useWidgetSignals({
    signal: "refresh",
    handler: forceRefresh,
  });

  const gridSettingsKey = useGridSettingsKey(scheme!.detailsList!.id!);
  if (isAnnotationsLoading) {
    return <LoadingOverlay />;
  }

  return (
    <WidgetContainer
      stick={"bottom"}
      style={{ alignContent: "stretch" }}
      onHeightChanged={setHeight}
    >
      {title && !hideHeader && (
        <WidgetHeader
          title={title}
          titleSeparator={isSearchButtonVisible || isConfigButtonVisible || !!noLicenseMessage}
          onRefresh={forceRefresh}
          showClock={false}
          updateTime={updateTime}
        >
          {!noLicenseMessage && (
            <IconButton
              iconProps={{ iconName: "Search" }}
              checked={isSearchButtonVisible}
              type={"button"}
              onMouseDown={(e) => {
                e.stopPropagation();
                if (!isSearchButtonVisible && isConfigButtonVisible) {
                  toggleConfigButtonVisible();
                }
                toggleSearchButtonVisible();
              }}
            />
          )}
          {!noLicenseMessage && (
            <IconButton
              iconProps={{ iconName: "Settings" }}
              checked={isConfigButtonVisible}
              type={"button"}
              onMouseDown={(e) => {
                e.stopPropagation();
                toggleConfigButtonVisible();
                if (isSearchButtonVisible) {
                  toggleSearchButtonVisible();
                }
              }}
            />
          )}
          {props.scheme?.commandBar && (
            <CommandBar
              uiInteraction={uiInteraction}
              scheme={props.scheme?.commandBar}
              showFilterBarItems={false}
              dispatch={() => {}}
              style={{ flexGrow: 1 }}
            />
          )}
        </WidgetHeader>
      )}
      {!noLicenseMessage && !isAnnotationsLoading && actualScheme && (
        <Stack
          key={refreshKey + isSearchButtonVisible?.toString() + isConfigButtonVisible?.toString()}
          onMouseDown={(e) => e.stopPropagation()}
          disableShrink={true}
          verticalFill={true}
          style={{ overflow: "hidden", position: "relative" }}
        >
          <GridContainer
            scheme={actualScheme!}
            propertyAnnotations={propertyAnnotations}
            gridSettingsKey={gridSettingsKey}
            key={actualScheme?.detailsList!.pageSize}
          />
          {actualScheme.detailsList?.viewAllTitle && (
            <DefaultButton
              style={{ width: "100%", border: "none", position: "absolute", bottom: 0 }}
              onClick={() =>
                props.scheme?.detailsList?.url &&
                history.push("/" + urlUtils.getRouteFromApiPath(props.scheme?.detailsList?.url))
              }
            >
              <Link
                style={{
                  fontSize: 13,
                  textDecoration: "underline",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  pointerEvents: "none",
                }}
              >
                {actualScheme.detailsList?.viewAllTitle}
              </Link>
            </DefaultButton>
          )}
        </Stack>
      )}{" "}
      {noLicenseMessage && (
        <EmptyContainer>
          <Html value={noLicenseMessage} />
        </EmptyContainer>
      )}
    </WidgetContainer>
  );
};

export default ODataWidget;
