import React, { Component, ErrorInfo, ReactNode } from "react";
import logger from "../../libs/logger";

interface Props {
  children: ReactNode;
  fallbackComponent?: (resetCallback: () => void) => ReactNode;
}

interface State {
  hasError: boolean;
}
const log = logger.getLogger("ErrorBoundary");

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    log.error(_);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        (this.props.fallbackComponent &&
          this.props.fallbackComponent(() => this.setState({ hasError: false }))) ?? (
          <h1>Sorry.. Something went wrong</h1>
        )
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
