import styled from "styled-components";

const Column = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 100%;
  max-height: 100%;
  width: 100%;

  /* padding-right: 0;
  padding-left: 0; */
`;

export default Column;
