import { OdataQuery, OHandler } from "odata";
import { IntlShape } from "react-intl";
import { Entity } from "../../api/cayo-graph";
import { ErrorInfo } from "../../api/error-info.model";
import { IAjaxAction } from "../../api/schema.api";
import { appUtils } from "../../components/App/utils";
import { GridControllerAction } from "../../components/GridContainer/grid-model/grid.controller.actions";
import {
  IGridState,
  UIInteraction,
} from "../../components/GridContainer/grid-model/GridController";
import logger from "../../libs/logger";
import { oClient } from "../../libs/odata.client";
import { endpoints } from "../../services/endpoints.service";
import {
  IPropertiesPanelSignals,
  PropertiesPanelSignals,
} from "../../services/properties-panel-signal.service";
import { appSettings } from "../../settings/app-settings";
import tokenStorage from "../../settings/token-storage";
import { AccessToken } from "../../types/access-token.model";
import { Dictionary } from "../../types/dictionary";
import { ajaxUtils } from "../../utils/ajax-utils";
import downloadUtils, { matchFileNameRegex } from "../../utils/download-utils";
import { objectUtils } from "../../utils/object-utils";
import { urlUtils } from "../../utils/url-utils";
import { bindingsUtils } from "../bindings";
import actionHandlers, { actionHandlerNames, IActionHandlerParams } from "./action-handlers";
import actionMessages from "./messages";
import actionRegexes from "./regexs";

class ActionBuilder {
  private log = logger.getLogger("actions");

  constructor(
    readonly intl: IntlShape,
    readonly route: { history: any; location: any },
    readonly uiInteraction: UIInteraction
  ) {}

  public buildAjaxAction = (
    ajaxAction: IAjaxAction,
    objects?: Entity[],
    externalModels?: { [key: string]: object | undefined } | undefined,
    onStart?: () => void,
    onComplete?: (error?: ErrorInfo) => Promise<void>
  ) => {
    let postponedCallback: any = undefined;

    if (objects?.length || ajaxAction.isStream) {
      postponedCallback = async () => {
        try {
          if (ajaxAction.isStream) {
            return this.processStreamAction(ajaxAction, objects);
          } else {
            const actions = ajaxAction.isBatch
              ? [this.buildSubmit(ajaxAction, undefined, true, externalModels)]
              : objects!.map((o) => this.buildSubmit(ajaxAction, o, false, externalModels));

            onStart && onStart();
            this.uiInteraction.setLoading(true);

            try {
              const responses = await Promise.all(actions);

              if (!ajaxAction.isBatch) {
                responses.forEach(async (response, i) => {
                  if (i === 0) {
                    try {
                      const responseBody = response["@odata.context"]
                        ? response
                        : await response?.json();
                      this.processResponse(
                        responseBody,
                        externalModels,
                        ajaxAction.response,
                        undefined
                      );
                    } catch (error) {
                      this.log.error("Error parsing JSON response:", error);
                    }
                  }
                });
              }
            } finally {
              this.uiInteraction.setLoading(false);
            }
          }
        } catch (e) {
          const error = await ajaxUtils.getError(e);
          this.uiInteraction.alertError(error.error_description || error.error || "Unknown error");
        } finally {
          onComplete && onComplete();
        }
      };
    } else {
      postponedCallback = async () => {
        const showGlobalLoading = !onStart && !onComplete;

        let lastError: ErrorInfo | undefined = undefined;
        try {
          onStart && onStart();

          showGlobalLoading && this.uiInteraction.setLoading(true);
          const response = await this.buildSubmit(ajaxAction, undefined, undefined, externalModels);
          if ((response as Response)?.status > 300) {
            const result = await (response as Response).json();
            return Promise.reject(result);
          }

          if (ajaxAction.response) {
            if (onComplete) {
              (externalModels as any).onComplete = onComplete; // TEMP
            }

            this.processResponse(response, externalModels, ajaxAction.response);
            return Promise.resolve(response);
          }
        } catch (e) {
          const error = await ajaxUtils.getError(e);
          lastError = error;

          const errorMsg = error.error_description || error.error || "";
          if (onComplete) {
            this.uiInteraction.alertError(errorMsg, error.severity);
          } else {
            return Promise.reject(errorMsg);
          }
        } finally {
          onComplete && onComplete(lastError);
          showGlobalLoading && this.uiInteraction.setLoading(false);
        }
      };
    }

    if (ajaxAction?.confirmation?.description) {
      // onComplete && onComplete();

      appUtils.showConfirmation({
        confirmationMessage: ajaxAction?.confirmation?.description,
        actionItemDisplayDescription: ajaxAction?.confirmation?.actionItemDisplayDescription,
        onConfirmed: postponedCallback,
        showError: (err) => appUtils.showError(err),
        title: ajaxAction?.confirmation?.title,
        okText: ajaxAction?.confirmation?.okText,
        objects,
      });
    } else {
      return postponedCallback();
    }
  };

  public buildSubmit = (
    action: IAjaxAction,
    model: any,
    sendModel?: boolean,
    externalModels?: { [key: string]: object | undefined } | undefined
  ) => {
    if (action.isStream && action.url) {
      const options: RequestInit = {
        method: action.method,
        headers: {
          "Content-Type": action?.bodyType || "application/json",
        },
      };

      if (model) {
        const body = this.evalBody(action, model);
        if (typeof body === "object" && !(body instanceof FormData)) {
          options.body = JSON.stringify(body);
        } else {
          options.body = body as BodyInit;
        }
      }

      return fetch(action.url, options).then((response) => {
        if (!response.ok) {
          return response.json().then((err) => {
            const error = new Error(err.message || "Unknown error");
            throw error;
          });
        }
        return response;
      });
    } else {
      const result = this.prepareSubmit(action, model, sendModel, externalModels);
      if (result?.handler) {
        const { handler, query } = result;
        return handler!.query(query);
      }

      return;
    }
  };

  public buildSubmitWithResponse = (
    action: IAjaxAction,
    model?: any,
    sendModel?: boolean,
    externalModels?: { [key: string]: object | undefined } | undefined
  ) => {
    const result = this.prepareSubmit(action, model, sendModel, externalModels);
    if (result?.handler) {
      const { handler, query } = result;
      return handler!.fetch(query);
    }

    return Promise.resolve();
  };

  private prepareSubmit = (
    action: IAjaxAction,
    model: any,
    sendModel?: boolean,
    externalModels?: { [key: string]: object | undefined } | undefined
  ) => {
    if (!action?.url) {
      const error = this.intl.formatMessage(actionMessages.fromUrlNotSpecified);
      this.log.error(error);
      throw new Error(error);
    }

    action = objectUtils.cloneObject(action);

    const actualUrl =
      model && urlUtils.hasBindings(action.url!)
        ? (bindingsUtils.resolveExpression(model, action.url!) as string)
        : action.url;

    const method = action.method?.toLowerCase() || "get";
    let handler: OHandler | null = null;
    let query: OdataQuery | undefined = undefined;

    switch (method) {
      case "get":
        handler = oClient(endpoints.publicUrl).get(actualUrl);
        break;

      case "delete":
        if (action.body) {
          query = this.evalBody(
            action,
            model,
            externalModels || { gridState: {} }
          ) as unknown as OdataQuery;
        }
        handler = oClient(endpoints.publicUrl).delete(actualUrl);
        break;

      case "post":
      case "put":
      case "patch": {
        const body = sendModel === false ? {} : this.evalBody(action, model, externalModels);
        handler = oClient(endpoints.publicUrl, {
          headers: {
            "Content-Type": action?.bodyType || "application/json",
          },
        })[method](actualUrl!, body) as OHandler;
        break;
      }

      case "redirect":
        window.open(actualUrl);
        return undefined;

      default:
        throw new Error("Unsupported method");
    }

    return { handler, query };
  };

  public processResponse = (
    response: any,
    model: any,
    responseExpression?: { [key: string]: string } | undefined,
    variables?: Dictionary,
    signals?: IPropertiesPanelSignals
  ) => {
    if (!responseExpression) {
      this.log.debug("no response actions");
      return;
    }

    Object.keys(responseExpression).forEach((e) => {
      const expressions = responseExpression[e].split("&");
      expressions.forEach((expression, index) => {
        const pairs = expression.split("=");
        this.log.debug(`process action #${index + 1}: `, expression);

        const leftPart = pairs[0];

        if (leftPart === "reload") {
          this.log.debug("reload page, processResponse");
          window.location.reload();
          return;
        } else if (leftPart === "redirect.to") {
          let redirectUrl = bindingsUtils.resolveExpression(response, pairs[1]);

          if (typeof redirectUrl === "string" && redirectUrl !== pairs[1]) {
            window.open(redirectUrl);
            return;
          }
          redirectUrl = pairs[1] === "/" ? window.location.hash : "#" + pairs[1];

          window.location.assign(redirectUrl);
          // this.route.history.push(pairs[1]);
        } else if (leftPart === wellknownActions.exportFormat) {
          logger.debug("set view.exportFormat", pairs[1]);
          const gridKey = (model.gridState as IGridState).gridSettingsKey;
          appSettings.viewSetting.updateSubKey(gridKey, { exportFormat: pairs[1] });
        } else if (pairs.length > 1 && leftPart) {
          const firstExpression = leftPart as actionHandlerNames;
          const actionHandler = actionHandlers[firstExpression];
          if (variables) {
            const resolvedExpression1 = bindingsUtils.resolveExpression(
              variables!,
              firstExpression
            );

            // TODO: eval expressions
            const saveToLocal = resolvedExpression1 === "localStorage.token";
            if (saveToLocal || resolvedExpression1 === "sessionStorage.token") {
              this.log.debug("saving token to " + resolvedExpression1);
              tokenStorage.setToken(response as AccessToken, saveToLocal);
            }
          } else if (actionHandler) {
            const resolvedExpression2 = bindingsUtils.resolveExpression(response, pairs[1]);
            actionHandler(resolvedExpression2, model);
          }
        } else if (model?.dispatch) {
          model.dispatch({ kind: leftPart } as GridControllerAction);
        } else if (signals) {
          signals.send(leftPart as PropertiesPanelSignals);
        }
      });
    });

    this.log.debug(response, model, responseExpression, variables);
  };

  public buildAction = (action: string, parameters: IActionHandlerParams) => {
    const { signals, dispatch } = parameters;
    const expressions = action.split("&");
    return expressions.map((expression, index) => {
      const pairs = expression.split("=");
      this.log.debug(`process action #${index + 1}: `, expression);

      const handlerName = pairs[0];
      const handler = (actionHandlers as any)[handlerName];
      if (handler) {
        const posponedHandler = () => {
          return handler(pairs[1], {
            ...parameters,
            route: this.route,
            // signals,
            // parentId: parameters.parentId,
            // object: parameters?.object,
            // gridState: parameters?.gridState
          });
        };

        if (parameters?.confirmationText) {
          appUtils.showConfirmation({
            confirmationMessage: parameters?.confirmationText,
            onConfirmed: posponedHandler,
            showError: (err) => appUtils.showError(err),
            objects: parameters?.objects,
          });
        } else {
          if (this.isPromise(posponedHandler)) {
            const asyncF = posponedHandler as () => Promise<void>;
            return asyncF();
          } else {
            return posponedHandler();
          }
        }
      } else if (signals) {
        signals.send(handlerName);
      } else if (dispatch) {
        dispatch({ kind: handlerName } as GridControllerAction);
      }

      return Promise.resolve();
    });
  };

  isPromise = (p: any) => {
    if (typeof p === "object" && typeof p.then === "function") {
      return true;
    }

    return false;
  };

  public resolveBindings = (
    item: any,
    bindings?: { [key: string]: string },
    externalModel?: Dictionary
  ) => {
    if (bindings && externalModel) {
      Object.keys(bindings).forEach((k) => {
        const expression = bindings![k];

        item[k] = this.resolveBinding(k, expression, externalModel);
      });
    }

    return item;
  };

  resolveBinding = (prop: string, expression: string, externalModel: Dictionary) => {
    logger.debug(prop, expression, externalModel);

    return bindingsUtils.resolveExpression(externalModel, expression);
  };

  private evalBody = (
    action: IAjaxAction,
    model: any,
    externalModels?: any
  ): FormData | object | string => {
    if (action.bodyType === "application/x-www-form-urlencoded" && action.body) {
      const formBody = action.body.replace(
        actionRegexes.insideCurlyBrackets,
        function (match, token) {
          let result = model[token];
          if (token === "password") {
            result = encodeURIComponent(result);
          }

          return result;
        }
      );

      this.log.debug("formBody", formBody);

      return formBody;
    } else if (externalModels) {
      let rawBody: any = undefined;
      const gridState = externalModels.gridState as IGridState;

      if (action.body) {
        rawBody = JSON.parse(action.body);
        Object.keys(rawBody).forEach((k) => {
          var unresolvedValue = rawBody[k] as string;

          if (unresolvedValue === wellknownActions.exportFormat) {
            rawBody[k] = appSettings.viewSetting.getSubKey(gridState.gridSettingsKey)?.exportFormat;
          } else {
            this.resolveObject(k, rawBody, { ...externalModels, ...model });
          }
        });
      } else if (action.isBatch) {
        rawBody = { ids: gridState.selectedItems?.map((i) => i?.id) };
      }

      this.log.debug("formBody", rawBody);

      return rawBody;
    }

    return JSON.stringify(model);
  };

  resolveObject = (k: string, body: any, externalModels: Dictionary) => {
    var unresolvedValue = body[k];
    if (typeof unresolvedValue === "string") {
      const undefinedValues: string[] = [];
      let resultWithType: any;
      var result = (unresolvedValue as unknown as string).replace(
        actionRegexes.insideCurlyBrackets,
        function (match, token) {
          const resolvedValue =
            bindingsUtils.resolvePropertyPath(externalModels, token) ??
            bindingsUtils.resolveSimpleExpression(externalModels, token);

          if (resolvedValue === undefined) {
            undefinedValues.push(match);
          }
          resultWithType = resolvedValue;
          return resolvedValue;
        }
      );

      const isUndefined = !!undefinedValues.find((v) => body[k] === v);
      if (isUndefined) {
        delete body[k];
      } else {
        body[k] = Array.isArray(resultWithType) ? resultWithType : result;
      }
    } else {
      Object.keys(unresolvedValue).forEach((kk) => {
        this.resolveObject(kk, unresolvedValue, externalModels);
      });
    }
  };

  processStreamActionViaPost = async (action: IAjaxAction) => {
    const response = await this.buildSubmitWithResponse(action);
    if (response instanceof Response) {
      await this.downloadStream(action, response);
    }
  };

  processStreamAction = async (
    action: IAjaxAction,
    objects?: (Entity & { [key: string]: any })[]
  ) => {
    switch (action.method) {
      case "GET":
        return await this.processStreamActionViaGet(action!.url!, objects);
      case "POST":
        return await this.processStreamActionViaPost(action);
      default:
        throw new Error(`Unsupported method: ${action.method}`);
    }
  };

  downloadStream = async (action: IAjaxAction, response: any) => {
    const headers: any = ajaxUtils.getHeaders(response);
    let result: any = {};

    if (
      headers["content-type"] === "application/octet-stream" ||
      headers["content-type"] === "text/plain"
    ) {
      result = await response.blob();
    } else {
      try {
        result = await response.json();
      } catch (e) {
        this.log.error("Failed to read response", e);
      }
    }

    if (response.status > 300) {
      throw result;
    }

    const blob = new Blob([result], { type: "application/octet-stream" });

    const contentDisposition = headers["content-disposition"] as string;
    let fileName: string = "response.txt";
    if (contentDisposition) {
      const match = contentDisposition.match(matchFileNameRegex);
      if (match) {
        fileName = match[1];
      }
    }

    // Create an <a> element to simulate the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName; // Set the filename

    // Simulate a click on the link to trigger the download
    link.click();
  };

  private processStreamActionViaGet = async (
    templateUrl: string,
    objects?: (Entity & { [key: string]: any })[]
  ) => {
    let hrefs: string[] | undefined = objects?.map((i) => i["@odata.mediaReadLink"]) || [
      templateUrl,
    ];

    if (!hrefs[0]) {
      hrefs = objects?.map((i) => {
        const actualUrl = i
          ? (bindingsUtils.resolveExpression(i as any, templateUrl) as string)
          : templateUrl;

        const fullUrl = endpoints.publicUrl + "/" + actualUrl;
        return fullUrl;
      });
    }

    if (hrefs && hrefs.length > 0) {
      await downloadUtils.downloadODataFiles(hrefs);
    } else {
      throw new Error("Invalid stream action");
    }
  };
}

const wellknownActions = { exportFormat: "view.exportFormat" };

export type IActionBuilder = InstanceType<typeof ActionBuilder>;

export default ActionBuilder;
