import { logger } from "cayo.ui";
import React, { FC, Fragment, useMemo } from "react";
import { Entity } from "../../api/cayo-graph";
import useService from "../../services/services.hook";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
import useForm from "./hooks/useForm";
import { FormProps } from "./types";

const ReadonlyODataContainer: FC<{ object?: Entity }> = ({ object }) => {
  const actionBuilder = useService("actionBuilder");
  const { getIconAnnotation } = useTypeAnnotations();

  const formProps = useMemo<FormProps>(() => {
    const result = { items: [] };
    return {
      type: "json-form",
      ...result,
      actionBuilder,
      object,
      showDynamicProps: true,
      disabled: true,
      getIconAnnotation,
    };
  }, [object]);

  const { renderer, model, isFormInitializing } = useForm(formProps, object!);

  logger.debug(model);
  if (isFormInitializing) {
    return <Fragment />;
  }

  return <Fragment>{renderer()}</Fragment>;
};

export default ReadonlyODataContainer;
