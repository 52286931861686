import { IGroup, INavLink } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IntlShape } from "react-intl";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import globalHooks from "../GlobalHooks";
import TreeController from "./tree.controller";

const log = logger.getLogger("workflow");

const useTree = (
  rootUrl: string,
  refreshInterval: number,
  intl: IntlShape,
  onItemSelected: (item?: IGroup | INavLink) => void
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [items, setItems] = useState<INavLink[]>([]);
  const [selectedKey, setSelectedKey] = useState<string>();

  const { fetchFn } = ajax;

  const treeCtrl = useMemo(() => new TreeController(rootUrl, fetchFn, intl), [rootUrl]);

  const { updateTime } = globalHooks.useInterval(refreshInterval);

  useEffect(() => {
    setIsLoading(false);

    const asyncLoad = async () => {
      log.debug(
        "loading Tree -> updateTime, rootUrl, selectedKey",
        updateTime,
        rootUrl,
        selectedKey
      );
      try {
        const tree = await treeCtrl.loadTree(`${selectedKey}-${rootUrl}`);
        if (tree != null) {
          setGroups(tree.groups);
          setItems(tree.items);
        } else {
          log.debug("skip loading");
        }
      } catch (e) {
        log.error(`Failed to load tree by url: ${rootUrl}`, e);
      } finally {
        setIsLoading(false);
      }
    };

    asyncLoad();
  }, [updateTime, rootUrl, selectedKey]);

  const selectItem = useCallback(
    (item: IGroup | INavLink | undefined) => {
      setSelectedKey(item?.key);
      onItemSelected(item);

      if (!groups.length) {
        setItems([...items]);
      }
    },
    [groups, items]
  );

  return { items, groups, isLoading, selectedKey, selectItem };
};

export default useTree;
