import { GroupHeader, IGroup, IGroupHeaderProps } from "@fluentui/react";
import { useTheme } from "cayo.ui";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { JobRecord } from "../../api/cayo-graph";
import treeRendereres from "./tree.renderers";
import treeStyles, { StyledWorkflowTitle } from "./tree.styles";

type CustomGroupHeaderProps = IGroupHeaderProps & { onGroupSelected: (g: IGroup) => void };

const CustomGroupHeader: FC<CustomGroupHeaderProps> = (props) => {
  const theme = useTheme();
  const intl = useIntl();
  const { onGroupSelected } = props;

  return (
    <StyledCustomGroupHeader
      onRenderGroupHeaderCheckbox={() => null}
      expandButtonProps={{
        style: {
          visibility: props?.group?.children?.length || props?.group?.count ? undefined : "hidden",
        },
      }}
      onRenderTitle={(p) => {
        const jobRecord = p?.group?.data as JobRecord;
        return (
          <div
            style={{ display: "flex", flexGrow: 1, alignItems: "center" }}
            title={jobRecord.name}
            onClick={() => onGroupSelected(p!.group!)}
          >
            {treeRendereres.jobIcon(p?.group?.data, theme)}
            <StyledWorkflowTitle isSelected={props?.selected || false}>
              {jobRecord.name}
            </StyledWorkflowTitle>
            {treeRendereres.duration(jobRecord.duration, intl)}
          </div>
        );
      }}
      styles={treeStyles.groupHeader(theme, props.selected)}
      {...props}
    />
  );
};

const StyledCustomGroupHeader = styled(GroupHeader)``;

export default CustomGroupHeader;
