import { RouteComponentProps } from "react-router-dom";
import { Entity } from "../../api/cayo-graph";
import { ActionParameters } from "../../api/schema.api";
import { appEvents } from "../../components/App/app-events";
import { IShowControlHandler } from "../../components/App/types";
import { appSignals, showActionParams, showActionParamsNames } from "../../components/App/utils";
import { IPropertiesPanel } from "../../components/AppRoots/PropertiesPanelHolder/types";
import { IGridState } from "../../components/GridContainer/grid-model/GridController";
import { DispatchAction } from "../../components/GridContainer/grid-model/grid.controller.actions";
import { ISignalServiceBase } from "../../services/signal-service-base";
import { appSettings } from "../../settings/app-settings";
import { bindingsUtils } from "../bindings";

export interface IActionHandlerParams {
  route?: RouteComponentProps;
  parentId?: string;
  signals?: ISignalServiceBase<string, any>;
  objects?: Entity[];
  object?: Entity;
  property?: {
    name: string;
    index: number;
    parentObjectPath: string;
    type?: string;
  };
  confirmationText?: string;
  dispatch?: DispatchAction;
  gridState?: IGridState;
  onClose?: (updateItem?: Entity) => void;
  onSaveComplete?: () => void;
  onSubmit?: (object: Entity) => Promise<void>;
  actionParameters?: ActionParameters;
}

const redirect = (expression: string, prms: IActionHandlerParams) => {
  prms.route!.history.push("/" + expression);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const showClientComponent = (expression: string, prms: IActionHandlerParams) => {
  const parameters = expression.split(",");

  const parts = parameters[0].split("/");
  const folder = parts[0];
  const controlId = parts[1];
  const showControlHandler: IShowControlHandler = { controlId, folder, parameters: prms };
  if (parameters.length > 1) {
    (showControlHandler as any)[parameters[1]] = true;
  }

  appEvents.trigger({
    showControl: showControlHandler,
  });
};

const showAction = (actionId: string, parameters?: IActionHandlerParams) => {
  const parts = actionId.split(",");
  const isBatch = parts.length > 1 ? parts[1] === "batch" : false;
  if (isBatch) {
    actionId = parts[0];
  }

  const actualId =
    parameters?.objects && !isBatch
      ? (bindingsUtils.resolveExpression(parameters.objects[0] as any, actionId) as string)
      : actionId;

  appEvents.trigger({
    action: { schemeId: actualId, ...parameters, gridId: parameters?.parentId },
  });
};

const showProps = (actionId: string, parameters?: IActionHandlerParams) => {
  const actualId = parameters?.objects
    ? (bindingsUtils.resolveExpression(parameters.objects[0] as any, actionId) as string)
    : actionId;

  let parentId = parameters?.parentId;
  if (!parentId && parameters?.gridState?.selectedItems?.length) {
    parentId = parameters.gridState.selectedItems[0].objectPath;
  }

  if (!parentId && !parameters?.property) {
    parentId = parameters?.gridState?.id;
  }

  appEvents.trigger({
    showControl: {
      controlId: "scheme",
      parameters: {
        id: actualId || actionId,
        ...parameters, // TEMPORARY
        parentId,
      } as IPropertiesPanel,
    },
  });
};

const showWizardScheme = (schemeId: string) => {
  const parts = schemeId.split(",");
  const prms: showActionParams = { schemeId };
  if (showActionParamsNames("reloadAfterClose") === parts[1]) {
    prms.reloadAfterClose = true;
    prms.schemeId = parts[0];
  }

  appSignals.send("showWizardScheme", prms);
};

const showPicker = (actionId: string, parameters?: IActionHandlerParams) => {
  const actualId = parameters?.objects
    ? (bindingsUtils.resolveExpression(parameters.objects[0] as any, actionId) as string)
    : actionId;

  appEvents.trigger({
    showControl: {
      controlId: "scheme",
      parameters: {
        id: actualId || actionId,
        ...parameters, // TEMPORARY
        parentId: parameters?.parentId,
        isPicker: true,
        object: undefined,
      } as IPropertiesPanel,
    },
  });
};

const showNew = (actionId: string, parameters?: IActionHandlerParams) => {
  appEvents.trigger({
    showControl: {
      controlId: "scheme",
      parameters: {
        id: actionId,
        parentId: parameters?.parentId,
        isNewObject: true,
      } as IPropertiesPanel,
    },
  });
};

const logout = () => {
  localStorage.removeItem("token");
  sessionStorage.clear();

  appSettings.userSetting.update({ loginHint: undefined, authMode: undefined });

  window.location.assign("account/logout");
};

const updateProps = (gridId: string, objects?: Entity[]) => {
  appEvents.trigger({ updateProps: { id: gridId, objects } });
};

const saveSQ = (actionId: string, parameters?: IActionHandlerParams) => {
  if (
    parameters?.gridState?.query &&
    parameters?.dispatch &&
    parameters?.gridState?.query?.isTemp
  ) {
    return parameters.dispatch({ kind: "save", query: parameters.gridState.query });
  }
};

const actionHandlers = {
  "redirect.to": redirect,
  "show.clientComponent": showClientComponent,
  "show.action": showAction,
  "show.actionUrl": showAction, // support old dashboard
  "show.props": showProps,
  "show.type": showProps,
  "show.picker": showPicker,
  "show.wizard": showWizardScheme,
  "show.new": showNew,
  "update.props": updateProps,
  "savedQuery.save": saveSQ,
  logout: logout,
};

export type IActionHandlers = typeof actionHandlers;
export type actionHandlerNames = keyof IActionHandlers;

export default actionHandlers;
