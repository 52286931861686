import { useTheme } from "cayo.ui";
import React, { ComponentClass, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { CommandBarProps } from ".";
import useService from "../../services/services.hook";
import Badge from "../Badge";
import Icon from "../Graphics/Icon";
import CommandBarController from "./controller";
import { commandBarMessages } from "./messages";

const useCommandBar = (props: CommandBarProps) => {
  const { scheme, gridState } = props;
  const actionBuilder = useService("actionBuilder");
  const intl = useIntl();
  const { officeTheme, cayoTheme } = useTheme();

  const controller = useMemo(() => new CommandBarController(props, actionBuilder), [scheme?.items]);

  useEffect(() => {
    if (gridState?.query || props?.object) {
      controller.update(gridState);
    }
  }, [gridState?.selectedItems, gridState?.loading, gridState?.query]);

  const items = useMemo(() => {
    const refreshItem = controller?.items?.find((i) => i?.data?.key === "Refresh");
    if (refreshItem) {
      if (gridState?.hasChanges) {
        refreshItem.commandBarButtonAs = (b) => {
          const DR = b.defaultRender as unknown as ComponentClass;
          refreshItem.title = intl.formatMessage(commandBarMessages.RefreshTitleNewData);

          return (
            <RefreshItem>
              <DR {...b} />
              <Badge
                right={-2}
                top={4}
                width={12}
                height={12}
                position="absolute"
                color={cayoTheme.brandColors.info}
              >
                <ExclamationMark>!</ExclamationMark>
              </Badge>
            </RefreshItem>
          );
        };
      } else {
        delete refreshItem.commandBarButtonAs;
      }
    }

    const commandsWithCompositeIcons = controller?.items?.filter(
      (i) => i?.iconProps?.iconName && i.iconProps.iconName.indexOf("+") > 0
    );

    commandsWithCompositeIcons?.forEach((c) => {
      c.onRenderIcon = (p) => {
        const data = (p as any).data;
        // const styles  = p?.styles as IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
        // styles.
        return (
          <Icon
            iconName={data?.icon?.iconName}
            style={{
              color: (p as any)?.disabled
                ? cayoTheme.brandColors.disabled
                : officeTheme.semanticColors.menuIcon,
              marginLeft: 0,
            }}
          />
        );
      };
    });

    return controller.items || [];
  }, [gridState?.selectedItems, gridState?.hasChanges, controller.items]);

  return { items };
};

const ExclamationMark = styled.span`
  font-weight: bold;
`;

const RefreshItem = styled.div`
  position: relative;
  display: flex;
  margin-right: 5px;
`;

export default useCommandBar;
