import { Link, Stack } from "@fluentui/react";
import React, { CSSProperties, FC, Fragment, useCallback, useMemo } from "react";
import { IObjectLinks } from "../api/schema.api";

import { Label, clipboardUtils } from "cayo.ui";
import { useIntl } from "react-intl";
import { useSchemes } from "./App/hooks";
import { IAdditionalFormFieldProps } from "./Form/types";
import { commonMessages } from "./common-messages";

type SoftLinkCollectionType = IObjectLinks & {
  name?: string;
  addProps: IAdditionalFormFieldProps;
  style?: CSSProperties;
};
const SoftLinkCollection: FC<SoftLinkCollectionType> = (props) => {
  const { id, name, label, tooltip, style, addProps, hideWhenEmpty, nullValueDecorator } = props;

  const parentId = id || addProps?.object?.id;

  const { showPropertiesPanel } = useSchemes();
  const intl = useIntl();

  const items = useMemo<any[]>(() => {
    return props.items?.length ? props.items : addProps?.object ? addProps.object[name!] : [];
  }, [props.items, addProps?.object]);

  const copyHandler = useCallback(() => {
    var result = items?.map((i) => i?.objectName);
    result?.length && clipboardUtils.copyArrayToClipboard(result);
  }, [items]);

  if (!items?.length && hideWhenEmpty && !nullValueDecorator) {
    return <Fragment />;
  }

  return (
    <Stack horizontal={false} style={style ?? { margin: "8px 8px 8px 8px" }}>
      <Label tooltip={tooltip} label={label!} onCopy={copyHandler} />
      {items?.length
        ? items.map((item, i) => (
            <Link
              onClick={() => {
                showPropertiesPanel({
                  id: item?.objectPath,
                  parentId: parentId!,
                });
              }}
              key={i}
            >
              {item?.objectName ?? nullValueDecorator ?? intl.formatMessage(commonMessages.notset)}
            </Link>
          ))
        : intl.formatMessage(commonMessages.notset)}
    </Stack>
  );
};

export default SoftLinkCollection;
