import AlertPanel from "../../Alerts/AlertPanel";
import StaticAlerts from "../../Alerts/StaticAlerts";
import ComponentStatus from "../../ComponentStatus";
import Dashboard from "../../Dashboard";
import Nav from "../../Nav/Nav";
import Schedule from "../../Schedule";
import Workflow from "../../Workflow/Workflow";
import CompanyLogo from "../CompanyLogo";
import NavHeader from "../NavHeader";
import PageContent from "../PageContent";

const userControlRenderers = {
  navheader: NavHeader,
  pagecontent: PageContent,
  nav: Nav,
  companylogo: CompanyLogo,
  dashboard: Dashboard,
  staticalerts: StaticAlerts,
  alertpanel: AlertPanel,
  workflow: Workflow,
  componentstatus: ComponentStatus,
  schedule: Schedule,
};

export default userControlRenderers;
