import React from "react";
import { IBasicList, IFacts } from "../../api/schema.api";
import IAuxRenderParams from "../Schemes/AuxRenderProps";
import BasicList from "./BasicList";
import ODataFacts from "./ODataFacts";

const basiclist = (props: IBasicList, auxParams: IAuxRenderParams) => (
  <BasicList {...props} {...auxParams} />
);

const facts = (props: IFacts) => <ODataFacts {...props} />;

const listRenderers = {
  basiclist,
  facts,
};

export default listRenderers;
