import { ITheme } from "cayo.ui";
import { ExecutionResult, ExecutionState } from "../api/cayo-graph";
import { IBrandColors, IClientIcons } from "../api/schema.api";
import { nameofFactory } from "../utils/object-utils";

export type IconInfo = { iconName: string; color: string };
export type JobState = { executionState: ExecutionState; lastExecutionResult: ExecutionResult };

type StateItem = { state?: ExecutionState; result?: ExecutionResult } & IconInfo;

const icon = nameofFactory<IClientIcons>();
const color = nameofFactory<IBrandColors>();

type brandColorNames = keyof IBrandColors;

class JobStateService {
  private readonly runningState: StateItem;

  private stateMap = (): StateItem[] => {
    const map: StateItem[] = [
      { state: "running", iconName: icon("running"), color: color("primaryHighlight") },
      { state: "idle", result: "success", iconName: icon("success"), color: color("success") },
      { state: "idle", result: "failure", iconName: icon("errorBadge"), color: color("error") },
      { state: "idle", result: "none", iconName: icon("unknown"), color: color("disabled") },
      { state: "idle", result: "warning", iconName: icon("warning"), color: color("warning") },
      { state: "idle", result: "cancelled", iconName: icon("cancelled"), color: color("disabled") },
      { state: "none", result: "none", iconName: icon("success"), color: color("divider") },
      { result: "skipped", iconName: icon("skipped"), color: color("disabled") },
    ];

    map.forEach((i) => {
      i.iconName = this.theme.getIcon(i.iconName as string);

      i.color = this.theme.cayoTheme.brandColors[i.color! as brandColorNames];
    });

    return map;
  };

  private stateMapCache: StateItem[] = [];

  constructor(readonly theme: ITheme) {
    this.stateMapCache = this.stateMap();
    this.runningState = this.stateMapCache[0];
  }

  public getJobIcon = (jobState: JobState): IconInfo => {
    const { executionState, lastExecutionResult } = jobState;

    const ret = this.stateMapCache.find(
      (i) =>
        executionState === "running" ||
        ((i.state === undefined || i.state === executionState) &&
          (i.result === undefined || i.result === lastExecutionResult))
    );
    return (ret as IconInfo) || this.runningState;
  };
}

export default JobStateService;
