import { Alert } from "../../api/cayo-graph";
import logger from "../../libs/logger";
import notificationService, {
  INotificationMessage,
  IUpdateInteractiveItemAction,
  NotificationAction,
} from "../../services/notification.service";
import { ISignalServiceBase, SignalServiceBase } from "../../services/signal-service-base";
import { objectUtils } from "../../utils/object-utils";
import { IAlertItem } from "./alerts.api";

const alertTypes = [
  "staticAlerts",
  "systemAlerts",
  "changeAlerts",
  "popupAlerts",
  "riskAssessment",
];

const log = logger.getLogger("AlertDispatcher");

export type AlertType = (typeof alertTypes)[number];

const popingAlertsPredicate = (alert: IAlertItem) => {
  return alert.status === "dismissed" || alert.status === "resolved";
};

const initAlertListener = () => {
  const onMessages = (messages: INotificationMessage[]) => {
    try {
      const msgGroups = objectUtils.groupBy(messages, (j) => j.action);
      for (const group of msgGroups) {
        const action = NotificationAction[group[0]];
        if (action === "Remove") {
          try {
            Object.values(alertTypes).forEach((a) => {
              const removedAlerts = (group[1] as INotificationMessage[]).map((m) =>
                JSON.parse(m.body)
              );

              alertDispatcher.send(a, {
                action: NotificationAction.Remove,
                newItems: removedAlerts,
              });
            });
          } catch (e) {
            log.error("Failed to process notifications", e, "action", action, "messages", group[1]);
          }

          continue;
        }

        const newSystemAlerts: IAlertItem[] = [];
        const newChangeAlerts: IAlertItem[] = [];
        const newStaticAlerts: IAlertItem[] = [];
        const newRiskAlerts: IAlertItem[] = [];
        for (const message of group[1]) {
          const newAlert = JSON.parse(message.body) as IAlertItem;

          const lastModifiedDateTime = (newAlert as any as Alert).lastModifiedDateTime;

          newAlert.modificationTime = lastModifiedDateTime
            ? new Date(lastModifiedDateTime)
            : new Date();

          if (newAlert.autoHide) {
            if (newAlert?.categories?.find((c) => c.name === "AlertType" && c.value === "System")) {
              newSystemAlerts.push(newAlert);
            } else if (
              newAlert?.categories?.find(
                (c) => c.name === "AlertType" && c.value === "RiskAssessment"
              )
            ) {
              newRiskAlerts.push(newAlert);
            } else {
              newChangeAlerts.push(newAlert);
            }
          } else {
            newStaticAlerts.push(newAlert);
          }
        }

        if (newStaticAlerts?.length > 0) {
          alertDispatcher.send("staticAlerts", {
            action: NotificationAction.Add,
            newItems: newStaticAlerts,
          });
        }

        // system
        const dismissedSystemAlerts = newSystemAlerts.filter(popingAlertsPredicate);
        const nonDismissedSystemAlerts = newSystemAlerts.filter((a) => !popingAlertsPredicate(a));

        if (dismissedSystemAlerts && dismissedSystemAlerts.length > 0) {
          alertDispatcher.send("systemAlerts", {
            action: NotificationAction.Remove,
            newItems: dismissedSystemAlerts,
          });

          alertDispatcher.send("popupAlerts", {
            action: NotificationAction.Remove,
            newItems: dismissedSystemAlerts,
          });
        }

        if (nonDismissedSystemAlerts && nonDismissedSystemAlerts.length > 0) {
          alertDispatcher.send("systemAlerts", {
            action: group[0],
            newItems: nonDismissedSystemAlerts,
          });
        }

        // change
        const dismissedChangeAlerts = newChangeAlerts.filter(popingAlertsPredicate);
        const nonDismissedChangeAlerts = newChangeAlerts.filter((a) => !popingAlertsPredicate(a));

        if (dismissedChangeAlerts && dismissedChangeAlerts.length > 0) {
          alertDispatcher.send("changeAlerts", {
            action: NotificationAction.Remove,
            newItems: dismissedChangeAlerts,
          });

          alertDispatcher.send("popupAlerts", {
            action: NotificationAction.Remove,
            newItems: dismissedChangeAlerts,
          });
        }

        if (nonDismissedChangeAlerts && nonDismissedChangeAlerts.length > 0) {
          alertDispatcher.send("changeAlerts", {
            action: group[0],
            newItems: nonDismissedChangeAlerts,
          });
        }

        // risk

        const dismissedRiskAlerts = newRiskAlerts.filter(popingAlertsPredicate);
        const nonDismissedRiskAlerts = newRiskAlerts.filter((a) => !popingAlertsPredicate(a));

        if (dismissedRiskAlerts && dismissedRiskAlerts.length > 0) {
          alertDispatcher.send("riskAssessment", {
            action: NotificationAction.Remove,
            newItems: dismissedRiskAlerts,
          });

          alertDispatcher.send("popupAlerts", {
            action: NotificationAction.Remove,
            newItems: dismissedChangeAlerts,
          });
        }

        if (nonDismissedRiskAlerts && nonDismissedRiskAlerts.length > 0) {
          alertDispatcher.send("riskAssessment", {
            action: group[0],
            newItems: nonDismissedRiskAlerts,
          });
        }
      }
    } catch (e) {
      log.error(e);
    }
  };
  return notificationService.subscribe("alert", onMessages);
};

export interface IUpdateNewAlerts extends IUpdateInteractiveItemAction {
  // buttonRef: MutableRefObject<null>;
  buttonId: string;
}

export const alertDispatcher: ISignalServiceBase<AlertType, IUpdateInteractiveItemAction> =
  new SignalServiceBase<AlertType, IUpdateInteractiveItemAction>(initAlertListener);
