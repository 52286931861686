import { Link, Icon as OfficeIcon, Stack, Text } from "@fluentui/react";
import { ITheme, css, htmlUtils, stringUtils, useComponentLogger } from "cayo.ui";
import React, { FC, Fragment, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { NetworkMessages } from "../../i18n/messages/network-messages";
import logger from "../../libs/logger";
import { regexs } from "../../utils/regexs";
import { ServerError } from "../App/types";
import { CenteredPanel } from "../CommonLayout";
import GearsIcon from "../Graphics/GearsIcon";
import { healthCheckUtils } from "./health-check.api";

const ServerUnavailable: FC<{
  error?: ServerError | undefined;
  onRetry: () => void;
}> = ({ onRetry, error }) => {
  const serverState = error?.state;
  const operatingMode = error?.operatingMode;

  const intl = useIntl();
  const log = useComponentLogger(ServerUnavailable);

  const errorInfo = useMemo(() => {
    if (!error?.message) {
      return null;
    }

    log.debug("Server error:", error);

    const messages = error?.message?.split("\r\n")?.map((s) => stringUtils.trim(s, "/"));

    const title =
      (messages && messages[0]) || intl.formatMessage(NetworkMessages.serverUnavailable);
    let subTitles: Array<string> = [];
    if (messages && messages.length > 1) {
      messages.splice(0, 1);
      subTitles = messages;
    }

    if (error.description) {
      const descs = error?.description?.split("\\r\\n")?.map((s) => stringUtils.trim(s, "/"));

      subTitles.push(...descs);
    }

    return { title, subTitles };
  }, [error]);

  const startingIcon = () => {
    if (!isStarting) {
      return <PlugDisconnectedBigIcon iconName="PlugDisconnected" />;
    }
    return <GearsIcon />;
  };

  const isStarting =
    serverState === "restarting" ||
    serverState === "starting" ||
    healthCheckUtils.isNonOperableMode(operatingMode);

  const anchors = useMemo(() => {
    if (errorInfo?.subTitles?.length) {
      return errorInfo.subTitles.map(htmlUtils.getAnchors);
    }

    return [];
  }, [errorInfo]);

  return (
    <StyledCenteredPanel>
      {error?.isCritical ? <IncidentTriangleIcon iconName="IncidentTriangle" /> : startingIcon()}

      {
        <LargeText variant="xLarge">
          {errorInfo?.title || intl.formatMessage(NetworkMessages.serverUnavailable)}
        </LargeText>
      }

      {errorInfo?.subTitles?.map((s, i) => {
        return (
          <Stack
            key={i}
            horizontalAlign="center"
            verticalAlign="baseline"
            className={css.w100p}
            horizontal={true}
            wrap={true}
            tokens={{ childrenGap: 10, padding: 4 }}
          >
            {s.split(regexs.entireAnchorRegex).map((v, ii) => {
              const anchor = anchors?.length && anchors[i] && anchors[i][ii];
              logger.debug(v, ii, anchors);

              return (
                <Fragment key={"anchor" + ii}>
                  <Text
                    block={true}
                    variant="mediumPlus"
                    nowrap={false}
                    styles={{ root: { marginRight: 0, textAlign: "center" } }}
                  >
                    {v}
                  </Text>
                  {anchor && (
                    // TODO: map anchor.body -> function
                    <AnchorLink onClick={() => onRetry()}>{anchor.body}</AnchorLink>
                  )}
                </Fragment>
              );
            })}
          </Stack>
        );
      })}

      {!isStarting && (
        <Link onClick={() => onRetry()}>
          <FormattedMessage id="server-unavailable.retry" defaultMessage="Try again" />
        </Link>
      )}
    </StyledCenteredPanel>
  );
};

const PlugDisconnectedBigIcon = styled(OfficeIcon)`
  font-size: 66px;
`;

const AnchorLink = styled(Link)`
  white-space: nowrap;
  padding-right: 0px;
  margin-left: 4px;
`;

const LargeText = styled(Text)`
  padding: 18px 0px;
`;

const IncidentTriangleIcon = styled(OfficeIcon)`
  font-size: 46px;
  color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.critical};
`;

const StyledCenteredPanel = styled(CenteredPanel)`
  width: 100%;
`;

export default ServerUnavailable;
