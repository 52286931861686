import { PrimaryButton, TextField } from "@fluentui/react";
import { DropDownPicker, stringUtils } from "cayo.ui";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { ITextField } from "../../api/schema.api";
import logger from "../../libs/logger";
import { IFormComponent } from "../QueryFilterForm/IFormComponent";
import { commonMessages } from "../common-messages";

type InplaceTextFieldProps = ITextField &
  IFormComponent & {
    defaultValue?: string | undefined;
    onApply?: () => void;
    onPickerStateChanged?: (isOpened: boolean) => void;
  };

const log = logger.getLogger("InplaceTextField");

const InplaceTextField: FC<InplaceTextFieldProps> = (props) => {
  const intl = useIntl();
  const toggleDropDownPicker = useRef<() => void>();
  const { onApply, onPickerStateChanged } = props;

  const pickerOpened = useRef(false);
  const pickerStateHandler = useCallback(
    function (isOpened: boolean) {
      if (!!props.onPickerStateChanged) {
        pickerOpened.current = isOpened;
        props.onPickerStateChanged(isOpened);
      }
    },
    [props.onPickerStateChanged]
  );

  useEffect(() => {
    return () => {
      if (pickerOpened.current) {
        onPickerStateChanged && onPickerStateChanged(false);
      }

      log.debug("unmount", props.name);
    };
  }, []);
  return (
    <DropDownPicker
      title={props.shortName}
      initToggle={(f) => {
        toggleDropDownPicker.current = f;
      }}
      onPickerStateChanged={pickerStateHandler}
      isActive={!!props.defaultValue}
    >
      <Text {...props} />

      {onApply && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            text={intl.formatMessage(commonMessages.apply)}
            onClick={() => {
              onApply();
              toggleDropDownPicker.current && toggleDropDownPicker.current();
            }}
            style={{ marginTop: 6, width: "100%" }}
          />
        </div>
      )}
    </DropDownPicker>
  );
};

const Text = (props: InplaceTextFieldProps) => {
  return (
    <TextField
      key={props.name}
      type={"text"}
      autoFocus={props.autoFocus}
      defaultValue={props.defaultValue}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          props.setValue(props.defaultValue, props);
        } else if (e.key === "Enter") {
          props.setValue(e.currentTarget.value, props);
        }
      }}
      onBlur={(e) => {
        logger.debug(e);
        // eslint-disable-next-line eqeqeq
        // if ((props.value || "") !== (e.currentTarget.value || "")) {
        props.setValue(e.currentTarget.value, props);
        // }
      }}
      label={props.label || stringUtils.toSpaceDelimitedPascalCase(props.name!)}
      style={{ minWidth: 300, maxWidth: 700, width: "100%" }}
    />
  );
};

export default InplaceTextField;
