import { Stack, useResponsiveMode } from "@fluentui/react";
import { ITheme } from "cayo.ui";
import React, { FC, useRef } from "react";
import styled from "styled-components";
import { IScheme } from "../../api/schema.api";
import Breadcrumb from "../Breadcrumb";
import ToggleNavCollapseButton from "../Buttons/ToggleNavCollapseButton";
import Row from "../Layout/Row";
import { navTreeWidth } from "../Nav/NavTree.Style";
import { isMedium } from "../Nav/nav-hooks";
import CompanyLogo from "./CompanyLogo";
import GenericScheme from "./GenericScheme";

type NavHeaderProps = IScheme & { rootName: string };

const NavHeader: FC<NavHeaderProps> = (props) => {
  const stubRef = useRef(null);
  const mediumMode = isMedium(useResponsiveMode(stubRef));

  return (
    <StyledNavContainer key="navheader" data-testid="nav-container">
      <Stack
        horizontal={true}
        verticalAlign="center"
        style={{ width: mediumMode ? "auto" : navTreeWidth }}
        styles={{ root: { flexShrink: mediumMode ? 1 : 0 } }}
      >
        <ToggleNavCollapseButton />
        <div
          style={{
            flexGrow: 1,
            paddingLeft: mediumMode ? 8 : 40,
            paddingRight: mediumMode ? 8 : 0,
          }}
        >
          <CompanyLogo />
        </div>
      </Stack>

      <Breadcrumb rootName={props.rootName} />
      <GenericScheme {...props} style={{ width: undefined }} />
    </StyledNavContainer>
  );
};

const StyledNavContainer = styled(Row)`
  background-color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.thirdBackground};
  width: 100%;
  height: 65px;
  display: "flex";
  align-items: center;
`;

export default NavHeader;
