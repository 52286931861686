import { Stack, Text, mergeStyleSets } from "@fluentui/react";
import { LoadingOverlay, MessageBar, css, logger, stringUtils, useTheme } from "cayo.ui";
import React, { CSSProperties, FC, useEffect, useMemo } from "react";
import styled from "styled-components";
import { IFormField } from "../../api/schema.api";
import FormFooter from "./FormFooter";
import useForm from "./hooks/useForm";
import { FormProps } from "./types";

const log = logger.getLogger("Form");
const defaultHorizontalPadding = "20px";

const Form: FC<FormProps> = (props) => {
  const { cayoTheme } = useTheme();

  const { signals, className } = props;
  const {
    isSubmitting,
    isSaveDisabled,
    errors,
    message,
    onSubmit,
    renderer,
    isFormInitializing,
    model,
    hasFooter
  } = useForm(props, props?.object);

  useEffect(() => {
    if (props.updateModel) {
      const hasErrors = !!Object.keys(errors).length;
      props.updateModel(model, isSaveDisabled || hasErrors);
    }
  }, [errors, isSaveDisabled, model]);

  const styles = useMemo(() => {
    const firstConrol = props?.items?.length === 1 ? (props?.items[0] as IFormField) : undefined;
    const isAloneControlWithEmptyLabel = firstConrol?.label
      ? !stringUtils.trim(firstConrol.label)
      : false;

    let padding: string | number = 0;
    if (!props?.noContentPadding) {
      padding =
        props?.isInsidePropPanel && !props?.noContentPadding
          ? `${
              isAloneControlWithEmptyLabel ? 0 : "8px"
            } ${defaultHorizontalPadding} 20px ${defaultHorizontalPadding}`
          : 0;
    }
    const resultStyles = {
      contentStyle: {
        padding,

        marginTop: isAloneControlWithEmptyLabel ? "-14px" : 0,
        flexGrow: 1,
      },
      titleStyle: {
        width: "100%",
        paddingRight: 60,
        paddingTop: 34,
        paddingLeft: 20,
        position: "relative",
        top: "-20px",
      } as CSSProperties,
      formStyle: { ...props?.style },
    };

    return mergeStyleSets(resultStyles);
  }, [props?.isInsidePropPanel]);

  const formContent = (
    <FormWrapper>
      {(isSubmitting || isFormInitializing) && <LoadingOverlay />}
      <FormContentWrapper>
        {message && (
          <MessageBar
            //styles={{ root: { display: "flex", marginTop: 0 } }}
            messageBarType={message?.type === "error" ? "error" : "success"}
            isMultiline={false}
            truncated={true}
          >
            {message?.message}
          </MessageBar>
        )}

        <FormContent
          horizontalAlign={"stretch"}
          overflow={props?.overflow || (props?.isInsidePropPanel ? undefined : "hidden")}
        >
          <Stack tokens={cayoTheme.metrics.stackTokens} className={styles.contentStyle}>
            {!isFormInitializing && renderer()}
          </Stack>
        </FormContent>
      </FormContentWrapper>
      {hasFooter && (
        <FormFooter
          isOKDisabled={isSaveDisabled}
          errors={errors}
          onClose={() => {
            return signals ? signals.send("close") : props.onClose && props.onClose(false);
          }}
          onSubmit={async () => {
            const result = await onSubmit();
            return result;
          }}
          saveError={message?.type === "error" ? message?.message : undefined}
          footer={props.footer}
          style={{
            padding: props.isInsidePropPanel ? `30px ${defaultHorizontalPadding}` : 0,
          }}
        />
      )}
    </FormWrapper>
  );

  return (
    <>
      {props.title && (
        <div className={styles.titleStyle}>
          <Text block={true} nowrap={false} variant={"large"}>
            {props.title}
          </Text>
        </div>
      )}
      <Stack className={`${css.relative} ${css.w100p} ${css.h100p} `}>
        {props.renderForm === false && <DummyForm>{formContent}</DummyForm>}
        {props.renderForm !== false && (
          <StyledForm
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                return await onSubmit();
              } catch (e) {
                log.error("Failed to submit form", e);
              }
            }}
            noValidate={true}
            className={`${styles.formStyle} ${className}`}
            //style={props.style}
          >
            {formContent}
          </StyledForm>
        )}
      </Stack>
    </>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const FormContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

const FormContent = styled(Stack)<{ overflow?: string }>`
  height: 100%;
  flex: 1 1 auto;
  flex-flow: column;
  overflow: ${(props) => props.overflow || "hidden auto"};
`;

const StyledForm = styled.form`
  flex-flow: column;
  inset: 0px;
  display: flex;
  height: 100%;
  position: absolute;

  &.static-form {
    position: static;
  }
`;

const DummyForm = styled.div`
  flex-flow: column;
  inset: 0px;
  display: flex;
  height: 100%;
  width: 100%;
`;

export default Form;
