import { ITextField } from "cayo.ui";
import { useState } from "react";
import { useIntl } from "react-intl";
import { ILogger } from "../../libs/logger";
import actionHandlers from "../../scheme/actions/action-handlers";
import useService from "../../services/services.hook";
import { appSignals, appUtils } from "../App/utils";
import { LoadingType, UIInteraction } from "../GridContainer/grid-model/GridController";
import { commonMessages } from "../common-messages";

const useUIInteraction = (log?: ILogger): UIInteraction => {
  const { showConfirmation, showError } = appUtils;
  const [loadingInfo, setLoading] = useState<string | boolean>(false);
  const actionBuilder = useService("actionBuilder");
  const intl = useIntl();

  return {
    loadingInfo,
    setLoading: (v: boolean | string | LoadingType) => {
      if ((v as LoadingType) === "submit") {
        v = intl.formatMessage(commonMessages.submittingData);
      }
      setLoading(v);
    },
    alertError: showError,
    confirm: (text, text2) => {
      return new Promise<boolean>((resolve) => {
        showConfirmation({
          confirmationMessage: text,
          confirmationMessage2: text2,
          onClose: resolve,
          showError: () => null,
        });
      });
    },
    prompt: (text) =>
      new Promise((resolve, reject) => {
        appSignals.send("showForm", {
          form: {
            actionBuilder,
            type: "json-form",
            description: text,
            items: [
              {
                label: "Name",
                required: true,
                name: "name",
                autoFocus: true,
                type: "text-field",
              } as ITextField,
            ],
            footer: {
              okText: intl.formatMessage(commonMessages.yes),
              cancelText: intl.formatMessage(commonMessages.no),
            },
            onSubmit: (object) => {
              log?.debug("Form onOk", object);
              resolve(object["name"]);
              return Promise.resolve();
            },
            onClose: (result) => {
              log?.debug("Form onClose", result);
              reject("ddd");
            },
          },
        });
      }),

    intl,
    actionHandlers: actionHandlers,
  };
};

export default useUIInteraction;
