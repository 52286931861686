import {
  ActionButton,
  Callout,
  classNamesFunction,
  DirectionalHint,
  IButtonStyles,
  IconButton,
  INavLink,
  INavLinkGroup,
  IStyle,
  mergeStyleSets,
  Stack,
  styled,
} from "@fluentui/react";
import { useTheme } from "cayo.ui";
import React from "react";
import logger from "../../libs/logger";
import Icon from "../Graphics/Icon";

type ItemOrChildSelected = {
  selected: boolean;
  childSelected: boolean;
  menuVisible?: boolean;
};
type LoadSubnodesFn = (ev?: React.MouseEvent<HTMLElement>, link?: INavLink) => Promise<void>;
interface INavCollapsedProps {
  groups?: INavLinkGroup[];
  selectedKey?: string;
  styles?: INavCollapsedStyles;
  expandLink: LoadSubnodesFn;
}
interface INavCollapsedStyleProps {
  color: string;
  backgroundColor: string;
  hoverBg: string;
}
interface INavCollapsedStyles {
  root?: IStyle;
  menuHeader?: IStyle;
}

function defaultNavCollapsedStyles(props: INavCollapsedStyleProps): INavCollapsedStyles {
  return {
    root: [
      {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      },
      props.backgroundColor && {
        backgroundColor: props.backgroundColor,
      },
    ],
    menuHeader: [
      {
        padding: "12px 10px",
        height: 44,
        color: "#fff",
        display: "flex",
        alignItems: "center",
      },
      props.backgroundColor && {
        backgroundColor: props.hoverBg,
      },
    ],
  };
}

const getClassNames = classNamesFunction<INavCollapsedStyleProps, INavCollapsedStyles>();

const log = logger.getLogger("NavCollapsed");

type NavLinkData = {
  link: INavLink;
  level: number;
  selected: boolean;
};

function makeLinkData(
  links: INavLink[],
  selectedKey: string,
  loadSubNodes: LoadSubnodesFn
): NavLinkData[] {
  const MAX_DEEP = 4;

  function needLoadSubnodes(link: INavLink) {
    return link.links?.length === 1 && link.links[0].key?.endsWith("loading-link");
  }

  function mapLinks(links: INavLink[], level: number): NavLinkData[] {
    log.debug("makeLinkData.mapLinks -> links, level", links, level);
    const result = links.reduce((acc, l) => {
      acc.push({
        link: l,
        level: level,
        selected: l.key === selectedKey,
      });
      // call load subnodes if need
      if (needLoadSubnodes(l)) {
        log.debug("makeLinkData.mapLinks -> need load subnodes", l);
        loadSubNodes(undefined, l);
      }
      if (!!l.links && level <= MAX_DEEP) {
        acc = acc.concat(mapLinks(l.links, level + 1));
      }
      return acc;
    }, [] as NavLinkData[]);
    return result;
  }

  if (!links) {
    return [];
  }
  const result = mapLinks(links, 0);
  log.debug("makeLinkData -> result", result);
  return result;
}

const menuWidth = 270;
const levelPadding = 22;
const labelStartWidth = menuWidth - 50;

const NavCollapsed = (props: INavCollapsedProps) => {
  log.debug("render -> props", props);
  const { groups, selectedKey } = props;
  const { cayoTheme } = useTheme();

  const {
    secondaryBackground: color,
    thirdBackground: backgroundColor,
    disabled: hoverBg,
    primaryHighlight: selectedBg,
  } = cayoTheme.brandColors;

  function checkSelected(key: string): ItemOrChildSelected {
    return {
      selected: key === selectedKey,
      childSelected: (key !== selectedKey && selectedKey?.startsWith(key)) || false,
    };
  }

  const classes = getClassNames(props.styles, { color, backgroundColor, hoverBg });

  const links = !groups || groups.length === 0 ? [] : groups[0].links;
  const rootButtonStyles: IButtonStyles = {
    root: {
      backgroundColor: backgroundColor,
      height: 44,
      width: "100%",
      borderRadius: 0,
    },
    rootHovered: {
      backgroundColor: hoverBg,
    },
    icon: {
      color: color,
    },
  };
  function selectedRootButtonStyle(args: ItemOrChildSelected): IButtonStyles {
    return {
      root: [
        (args.selected || args.childSelected) &&
          args.menuVisible === false && {
            backgroundColor: selectedBg,
          },
      ],
      rootHovered: [
        args.childSelected && {
          backgroundColor: hoverBg,
        },
        args.selected && {
          backgroundColor: selectedBg,
        },
      ],
    };
  }

  const menuButtonStyle: IButtonStyles = {
    root: {
      backgroundColor: backgroundColor,
      color: color,
      borderRadius: 0,
      height: 50,
    },
    rootHovered: {
      backgroundColor: hoverBg,
      color: color,
    },
    icon: {
      color: color,
    },
    iconHovered: {
      color: color,
    },
    label: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left",
      lineHeight: 16,
    },
  };
  function selectedMenuButtonStyle(isSelected: boolean, level: number): IButtonStyles {
    const padding = level * levelPadding;
    return {
      root: [
        isSelected && {
          backgroundColor: selectedBg,
        },
        {
          paddingLeft: padding,
        },
      ],
      rootHovered: [
        isSelected && {
          backgroundColor: selectedBg,
        },
      ],
      label: {
        width: labelStartWidth - padding,
      },
    };
  }

  // TODO: make item component and use it
  const renderItem = (link: INavLink) => {
    // eslint-disable-next-line
    const [showSubmenu, SetShowSubmenu] = React.useState(false);
    // eslint-disable-next-line
    const btnRef = React.useRef(null);

    const hasChildLinks = !!link.links && link.links?.length !== 0;
    const linkSelected = {
      ...checkSelected(link.key ?? ""),
      menuVisible: hasChildLinks && showSubmenu,
    };
    log.debug(
      "root -> key, selectedKey, selected, childSelected",
      link.key,
      selectedKey,
      linkSelected
    );
    const btnStyle = mergeStyleSets(rootButtonStyles, selectedRootButtonStyle(linkSelected));

    return (
      <div
        ref={btnRef}
        key={link.key}
        onMouseEnter={() => SetShowSubmenu(true)}
        onMouseLeave={() => SetShowSubmenu(false)}
      >
        <IconButton
          key={link.key}
          title={link.name}
          iconProps={{ iconName: link.icon }}
          href={link.url}
          styles={btnStyle}
        />
        {hasChildLinks && showSubmenu && (
          <Callout
            target={btnRef}
            isBeakVisible={false}
            directionalHint={DirectionalHint.rightTopEdge}
            calloutWidth={menuWidth}
            layerProps={{ styles: { root: { zIndex: 1000002 } } }} //loading overlay
          >
            <Stack>
              <div className={classes.menuHeader}>{link.name}</div>
              {makeLinkData(link.links ?? [], selectedKey ?? "", props.expandLink).map((ld) => (
                <ActionButton
                  key={ld.link.name}
                  iconProps={{ iconName: ld.link.icon }}
                  onRenderIcon={(p, r) => {
                    if (p?.iconProps?.iconName && p.iconProps.iconName.indexOf("+") > 0) {
                      return (
                        <>
                          <Icon
                            iconName={p.iconProps.iconName}
                            style={{ color: "white", marginLeft: 4 }}
                          />
                          <span style={{ width: 2 }} />
                        </>
                      );
                    }

                    return r!(p);
                  }}
                  href={ld.link.url}
                  text={ld.link.name}
                  title={ld.link.name}
                  styles={mergeStyleSets(
                    menuButtonStyle,
                    selectedMenuButtonStyle(ld.selected, ld.level)
                  )}
                />
              ))}
            </Stack>
          </Callout>
        )}
      </div>
    );
  };
  return <div className={classes.root}>{links.map((l) => renderItem(l))}</div>;
};

export default styled<INavCollapsedProps, INavCollapsedStyleProps, INavCollapsedStyles>(
  NavCollapsed,
  defaultNavCollapsedStyles
);
