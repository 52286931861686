import {
  ContextualMenuItemType,
  IButtonStyles,
  ICommandBarItemProps,
  IContextualMenuItem,
} from "@fluentui/react";
import { useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import { commandBarMessages } from "../../CommandBar/messages";
import { GroupConfig } from "../GridFilterBar";
import { hideCheckmark, hideIcon } from "../GridFilterBar.style";
import { IQuery } from "../grid-model/Query";
import { DispatchAction } from "../grid-model/grid.controller.actions";

type GridResetAndGroupButtonProps = {
  hide?: boolean;
  query: IQuery;
  dispatch?: DispatchAction;
  groupConfig?: GroupConfig[];
  btnStyle?: IButtonStyles;
};

export default function useResetColumnsAndGroupButton(
  props: GridResetAndGroupButtonProps
): ICommandBarItemProps | null {
  const intl = useIntl();
  return useMemo(
    function makeButton() {
      if (props.hide === true || !props.dispatch) {
        return null;
      }
      const result: ICommandBarItemProps = {
        key: "settings",
        iconProps: { iconName: "ColumnOptions" },
        buttonStyles: props.btnStyle,
        itemProps: hideIcon,
        subMenuProps: {
          items: resizeAndGroupsItems(intl, props.dispatch, props.groupConfig ?? [], props.query),
        },
      };
      return result;
    },
    [props.dispatch, props.query]
  );
}

export function resizeAndGroupsItems(
  intl: IntlShape,
  dispatch: DispatchAction,
  groupConfig: GroupConfig[],
  query: IQuery,
  addClearFilter: boolean = false,
  canClear: boolean = false
): IContextualMenuItem[] {
  const items = [resetColumns(intl, dispatch), chooseColumns(intl, dispatch)];
  if (addClearFilter) {
    items.unshift({
      key: "clear-query",
      text: intl.formatMessage(commandBarMessages.clearFilter),
      iconProps: { iconName: "ClearFilter" },
      itemProps: hideCheckmark,
      disabled: !canClear,
      onClick: () => {
        dispatch({ kind: "clearQuery" });
      },
    });
  }

  if (groupConfig?.length) {
    return items.concat(groups(intl, groupConfig, query.groupBy ?? "", dispatch));
  }
  return items;
}

function chooseColumns(intl: IntlShape, dispatch: DispatchAction): IContextualMenuItem {
  return {
    key: "chooseColumns",
    text: intl.formatMessage(commandBarMessages.chooseColumns),
    iconProps: { iconName: "CheckboxComposite" },
    itemProps: hideCheckmark,
    onClick: () => {
      dispatch({ kind: "openChooseColumnsDialog" });
    },
  };
}

function resetColumns(intl: IntlShape, dispatch: DispatchAction): IContextualMenuItem {
  return {
    key: "resetColumns",
    text: intl.formatMessage(commandBarMessages.ResetView),
    iconProps: { iconName: "FitWidth" },
    itemProps: hideCheckmark,
    onClick: () => {
      dispatch({ kind: "resetColumns" });
    },
  };
}

function groups(
  intl: IntlShape,
  groupConfig: GroupConfig[],
  groupBy: string,
  dispatch: DispatchAction
): IContextualMenuItem[] {
  const grHeader = {
    key: "groupHeader",
    text: "Grouping",
    itemType: ContextualMenuItemType.Header,
    style: { fontSize: 16, fontWeight: 600 },
    itemProps: hideCheckmark,
    iconProps: hideIcon,
  };
  const noGrouping: IContextualMenuItem = {
    key: "",
    text: intl.formatMessage(commandBarMessages.NoGrouping),
    checked: "" === groupBy,
    canCheck: true,
    iconProps: hideIcon,
    //itemProps: farItemStyle,
    onClick: () => {
      dispatch({ kind: "groupBy", fileld: "" });
    },
  };
  const gropsItems: IContextualMenuItem[] = groupConfig.map((ci) => ({
    key: ci.key,
    text: ci.text,
    checked: ci.key === groupBy,
    canCheck: true,
    iconProps: hideIcon,
    onClick: () => {
      dispatch({ kind: "groupBy", fileld: ci.key });
    },
  }));
  return [grHeader, noGrouping, ...gropsItems];
}
