import { IconButton } from "@fluentui/react";
import { ITheme } from "cayo.ui";
import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import globalHooks from "../GlobalHooks";
import { ILayoutHierarchy } from "../Schemes/renderers/renderers.layout";

type GridCellProps = Omit<ILayoutHierarchy, "index"> & {
  onDelete?: (itemKey: string) => void | undefined;
};

const GridCell: FC<GridCellProps> = (props) => {
  const { hovered: deleteButtonHovered, eventHandlers: deleteButtonEventHandlers } =
    globalHooks.useHover();
  const { hovered: gridItemHovered, eventHandlers: gridItemHoveredEventHandlers } =
    globalHooks.useHover();
  const intl = useIntl();

  const { name, render, onDelete } = props;

  return (
    <GridItemContainer {...gridItemHoveredEventHandlers}>
      {(gridItemHovered || deleteButtonHovered) && (
        <DeleteButton
          {...deleteButtonEventHandlers}
          iconProps={{ iconName: gridItemHovered ? "Clear" : "" }}
          styles={{ icon: { fontSize: 10 } }}
          style={{ height: 24, width: 24 }}
          title={intl.formatMessage(messages.removeFromDashboard)}
          onClick={(e) => {
            e.stopPropagation();

            onDelete && onDelete(name!);
          }}
          onMouseDown={(e) => e.stopPropagation()}
        />
      )}
      {render()}
    </GridItemContainer>
  );
};

const GridItemContainer = styled.div`
  border: 1px solid rgb(217, 217, 217);
  box-shadow: rgb(0 0 0 / 11%) 0px 0.3px 0.9px, rgb(0 0 0 / 13%) 0px 1.6px 3.6px;
  min-width: 30px;
  min-height: 30px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.primaryBackground};
  position: relative;
  display: flex;
  flex-grow: 1;
  padding: 8px 0 0 0;
`;

const DeleteButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
`;

const messages = defineMessages({
  removeFromDashboard: {
    defaultMessage: "Remove from dashboard",
    id: "grid.remove-from-dashboard",
  },
});

export default GridCell;
