import { stringUtils } from "cayo.ui";
import React from "react";
import { Entity } from "../../../api/cayo-graph";
import { AuxRenderProps } from "../../LegacyDetailsList/cell-renderers";
import GridCell from "./GridCell";
import { GridColumn, RenderCellFn } from "./types";

function resolveNullValueDecorator(
  item: Entity,
  column: GridColumn,
  auxProps?: AuxRenderProps
): string {
  let result = column.renderOptions?.nullValueDecorator;
  if (!!result) {
    return result;
  }
  const suffix = "/" + column.fieldName;
  const target =
    (item.objectType || stringUtils.trim(item["@odata.type"], "#")) + "/" + column.fieldName;

  result =
    auxProps?.propertyAnnotations?.find((a) => a.target === target)?.nullValueDecorator ||
    auxProps?.propertyAnnotations?.find((a) => a.target?.endsWith(suffix))?.nullValueDecorator;
  return result ?? "";
}

export const renderEmptyCell: RenderCellFn = ({ item, column, auxProps }) => {
  if (!column || !item) {
    return null;
  }
  const itemValue = item[column?.fieldName ?? ""];
  if (!(itemValue === null || itemValue === undefined)) {
    return null;
  }

  const decorator = resolveNullValueDecorator(item, column, auxProps);

  return (
    <GridCell noCopy={true} renderer="empty-cell">
      <span>{decorator ?? ""}</span>
    </GridCell>
  );
};
