import {
  getFocusStyle,
  IDetailsRowStyles,
  IGroupHeaderStyles,
  mergeStyleSets,
  Stack,
} from "@fluentui/react";
import { ITheme } from "cayo.ui";
import styled from "styled-components";

export const MAX_CELL_WIDTH = 300;
export const CONTAINER_WIDTH = MAX_CELL_WIDTH * 1.2;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const groupHeader = (theme: ITheme, isSelected?: boolean): Partial<IGroupHeaderStyles> => {
  const { semanticColors } = theme.officeTheme;
  return {
    check: { display: "none" },
    title: { paddingLeft: 4 },

    root: [
      isSelected && {
        backgroundColor: semanticColors.listItemBackgroundChecked,
      },
    ],
  };
};

const detailsRow = (
  theme: ITheme,
  maxWidth: number,
  isSelected?: boolean
): Partial<IDetailsRowStyles> => {
  const { semanticColors } = theme.officeTheme;

  return {
    checkCell: { display: "none" },
    root: [
      { width: "100%" },
      isSelected && {
        backgroundColor: semanticColors.listItemBackgroundChecked,
      },
    ],

    cell: {
      maxWidth,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      flexGrow: 1,
      selectors: {
        ":selected": {
          fontWeight: "bold",
        },
      },
    },
    fields: {
      flexGrow: 1,
    },
  };
};

const flatListStyle = (theme: ITheme) => {
  const { palette, fonts } = theme.officeTheme;
  const classNames = mergeStyleSets({
    container: {
      overflow: "auto",

      width: CONTAINER_WIDTH,
    },
    itemCell: [
      getFocusStyle(theme.officeTheme, { inset: -1 }),
      {
        padding: 10,
        boxSizing: "border-box",
        display: "flex",
        selectors: {
          "&:hover": { background: palette.neutralLight },
        },
      },
    ],
    itemSelected: {
      background: theme?.officeTheme?.semanticColors?.listItemBackgroundChecked,
    },

    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: fonts.small.fontSize,
      color: palette.neutralTertiary,
      marginBottom: 10,
    },
  });

  return classNames;
};

const treeStyles = {
  groupHeader,
  detailsRow,
  flatListStyle,
};

export default treeStyles;

export const StyledWorkflowTitle = styled.span<{ isSelected: boolean }>`
  flex-grow: 1;
  width: 1px;
  padding-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: ${(props) => (props.isSelected ? "500 !important" : undefined)};
`;

export const ActionContainer = styled(Stack)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

export const TreeContainer = styled.div`
  height: 100%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 0;
`;
