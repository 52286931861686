import { SignalServiceBase } from "../../services/signal-service-base";
import { IAlertItem } from "./alerts.api";

type AlertChange = "highlightAlerts";

class HightlightedAlerts {
  _alerts: IAlertItem[] = [];
  _signalService: SignalServiceBase<AlertChange, IAlertItem[]> = new SignalServiceBase();
  _lastErrorAlert: IAlertItem | undefined;

  public add = (alert: IAlertItem) => {
    this._alerts.forEach((a) => {
      delete a.clicked;
    });

    alert.highlighted = true;
    alert.clicked = true;

    const existingAlertIndex = this._alerts.findIndex((a) => a.id === alert.id);
    if (existingAlertIndex >= 0) {
      this._alerts[existingAlertIndex] = alert;
    } else {
      this._alerts.push(alert);
    }

    this._signalService.send("highlightAlerts", [alert]);
  };

  public remove = (alert: IAlertItem) => {
    if (this._alerts.find((a) => a.id === alert.id)) {
      this._alerts = this._alerts.filter((a) => a.id !== alert.id);
      alert.highlighted = false;
      alert.clicked = false;
      this._signalService.send("highlightAlerts", [alert]);
    }
  };

  public isHighlighted = (alert: IAlertItem) => {
    return this._alerts.findIndex((a) => a.id === alert.id) >= 0;
  };

  public isClicked = (alert: IAlertItem) => {
    return this._alerts.findIndex((a) => a.id === alert.id && a.clicked) >= 0;
  };

  public resetClickedItem = () => {
    const existingAlert = this._alerts.find((a) => a.clicked);
    if (existingAlert) {
      existingAlert.clicked = false;
      this._signalService.send("highlightAlerts", [existingAlert]);
    }
  };

  public subscribe = (signal: AlertChange, cb: (data?: IAlertItem[]) => void) => {
    return this._signalService.subscribe(signal, cb);
  };

  public isLastErrorAlert = (alert: IAlertItem) => {
    return alert.id === this._lastErrorAlert?.id;
  };

  public setLastErrorAlert = (alert: IAlertItem | undefined) => {
    this._lastErrorAlert = alert;
  };

  public get lastErrorAlert() {
    return this._lastErrorAlert;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new HightlightedAlerts();
