import { builtinFormatters } from "cayo.ui";
import { OdataQuery } from "odata";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Entity } from "../../api/cayo-graph";
import { ActionContext, HttpMethods, IJsonForm } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import useService from "../../services/services.hook";
import useSignals from "../../services/signal-service-base";
import { ajaxUtils } from "../../utils/ajax-utils";
import { ApiPath } from "../../utils/api-path";
import { appSignals, showActionParams } from "../App/utils";
import CollectActionParametersDialog from "../CollectActionParametersDialog";

const guidRegex =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}|[0-9a-fA-F]{8}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{12}/gi;

const ActionDialogHolder = () => {
  const [actionParams, setActionParams] = useState<showActionParams>();
  const [action, setAction] = useState<{ scheme: IJsonForm; object?: Entity }>();
  const actionBuilder = useService("actionBuilder");

  const onClose = useCallback(() => {
    setAction(undefined);
    setActionParams(undefined);
  }, [setAction, setActionParams]);

  useEffect(() => {
    if (!actionParams?.schemeId) {
      return;
    }

    const loadScheme = async () => {
      try {
        let object: any = undefined;

        // handle 'Update Now' & 'Schedule Update' actions (static alerts)
        const isEntityAction = !!actionParams.schemeId!.match(guidRegex)?.length;
        const parameters = actionParams.actionParameters;
        const isPostAction = !!parameters;
        let query: OdataQuery | undefined;
        let method: HttpMethods = "GET";
        let body: ActionContext | undefined;

        if (isPostAction) {
          method = "POST";
          body = {
            selectedIds: actionParams.objects?.map((o) => o[parameters.objectIdPropName]),
            selectedNames: actionParams.objects?.map((o) => o[parameters.objectNamePropName]),
          };
        } else {
          query = {
            independent: actionParams.independent,
          };
        }

        if (!actionParams?.objects?.length && isEntityAction && !isPostAction) {
          var objectPath = builtinFormatters.cutLastSegment(actionParams.schemeId!);

          object = await ajax.fetchFn(new ApiPath(objectPath).toString());
          logger.debug(`object loaded for scheme ${actionParams.schemeId}`, object);
        }

        const scheme = await ajax.getClient(
          "scheme/action/" + actionParams?.schemeId,
          method,
          undefined,
          body,
          query
        );
        setAction({ scheme, object });
      } catch (e) {
        onClose();
        ajaxUtils.handleError(e);
      }
    };

    loadScheme();
  }, [actionParams?.schemeId, onClose]);

  useEffect(() => {
    return appSignals.subscribe("showAction", (prms) => {
      setActionParams(prms);
    });
  }, []);

  const signals = useSignals<"close">([{ s: "close", handler: onClose }]);

  return (
    <ActionDialogHolderContainer>
      {action && (
        <CollectActionParametersDialog
          onClose={onClose}
          actionBuilder={actionBuilder}
          {...action.scheme}
          actionParameters={actionParams?.actionParameters}
          externalObjects={actionParams?.objects || (action.object ? [action.object] : undefined)}
          signals={signals as any}
          onSaveComplete={actionParams?.onSaveComplete}
        />
      )}
    </ActionDialogHolderContainer>
  );
};

const ActionDialogHolderContainer = styled.div`
  position: relative;
`;

export default ActionDialogHolder;
