import { ErrorInfo } from "../api/error-info.model";

function errorToString(msg: any) {
  if (typeof msg === "string") {
    return msg;
  }

  const result = msg as ErrorInfo;
  const error = (result?.error_description ?? result?.error) as any;
  if (!Array.isArray(error)) {
    const anyError = error as any;
    if (typeof anyError === "string") {
      return result?.error_description;
    }

    return anyError.message;
  }

  return result.error_description![0];
}
const odataUtils = {
  errorToString,
};

export default odataUtils;
