import { Dictionary, logger, stringUtils } from "cayo.ui";
import { bindingsUtils } from "../bindings";
import { groupParentheses } from "./odata-logical-expression-evaluator";

export const jsExpressionEvaluatorErrors = {
  invalidExpressionError: "Invalid expression",
};

type ParenthesesGroup = { expressions: string[] };

class JsExpressionEvaluator {
  private log = logger.getLogger("JsExpressionEvaluator");

  constructor(private readonly expr: string, private readonly context: Dictionary) {
    this.log.debug("constructor", this.expr, this.context);
  }

  private resolveParenthesesGroup = (group: ParenthesesGroup) => {
    let value = "";
    for (const g of group.expressions) {
      if (g === "||") {
        continue;
      }
      const resolvedValue = bindingsUtils.resolveExpression(this.context, `{${g}}`);
      if (resolvedValue) {
        value = resolvedValue;
        break;
      }
    }

    if (!value) {
      throw new Error(jsExpressionEvaluatorErrors.invalidExpressionError);
    }

    return value;
  };

  public evaluate = () => {
    const result = this.expr.replace(/\{\{([^{}]+)\}\}/g, (_, token) => {
      var groups = group(token);

      this.log.debug("groups", groups);

      let r = "";
      for (const g of groups) {
        if (typeof g === "string") {
          r += g;
        } else if (typeof g !== "string" && "expressions" in g) {
          r += this.resolveParenthesesGroup(g);
          this.log.debug("ParenthesesGroup", g.expressions);
        }
      }

      return r;
    });

    this.log.debug("result", result);

    return result;
  };
}

export const evaluateJsExpression = (expr: string, context: any) => {
  var result = new JsExpressionEvaluator(expr, context).evaluate();
  return result;
};

function group(str: string) {
  var i = 0;
  let c = "";
  let aposBeginPos = -1;
  let parenthesesBeginPos = -1;
  let openParenthesesCount = 0;
  let groups = [];

  while ((c = str[i])) {
    if (c === "'") {
      aposBeginPos = i;
      const aposEndPos = str.indexOf("'", aposBeginPos + 1);
      if (aposEndPos === -1) {
        throw new Error(jsExpressionEvaluatorErrors.invalidExpressionError);
      }
      const insideApos = str.slice(aposBeginPos + 1, aposEndPos);
      groups.push(insideApos);
      i = aposEndPos;
    } else if (c === "(") {
      if (parenthesesBeginPos === -1) {
        parenthesesBeginPos = i;
      }
      openParenthesesCount++;
    } else if (c === ")") {
      if (parenthesesBeginPos === -1) {
        throw new Error(jsExpressionEvaluatorErrors.invalidExpressionError);
      }

      if (openParenthesesCount === 1) {
        const insideParentheses = str.slice(parenthesesBeginPos, i + 1);

        const t = groupParentheses(insideParentheses);
        const parenthesesGroup: ParenthesesGroup = {
          expressions: t[0],
        };
        groups.push(parenthesesGroup);

        parenthesesBeginPos = -1;
        openParenthesesCount = 0;
      } else {
        openParenthesesCount--;
      }
    } else if (c === "+") {
      const nextPlusPos = str.indexOf("+", i + 1);
      const betweenPluses = str.slice(i + 1, nextPlusPos);
      const hasParenthesesInside = charactersPresent(betweenPluses, "()");
      if (!hasParenthesesInside) {
        const parenthesesGroup: ParenthesesGroup = {
          expressions: [betweenPluses],
        };
        groups.push(parenthesesGroup);
        i = nextPlusPos;
      }
    }

    i++;
  }

  if (!groups?.length) {
    const parenthesesGroup: ParenthesesGroup = {
      expressions: [stringUtils.trim(str)],
    };
    groups.push(parenthesesGroup);
  }

  return groups;
}

function charactersPresent(inputString: string, characters: string): boolean {
  for (const char of characters) {
    if (inputString.includes(char)) {
      return true;
    }
  }
  return false;
}
