import { Text } from "@fluentui/react";
import React, { FC } from "react";
import styled from "styled-components";
import { ReactComponent as ConditionBuilderIcon } from "./condition-builder-icon.svg";
import { ConditionDropdownProps, DropdownBodyProps } from "./types";

const DropdownHeader: FC = () => {
  return (
    <DropdownHeaderContainer>
      <DropdownHeaderIcon>
        <ConditionBuilderIcon />
      </DropdownHeaderIcon>
      <Text block={true} style={{ marginLeft: "10px" }}>
        Condition Builder
      </Text>
    </DropdownHeaderContainer>
  );
};

const DropdownBody: FC<DropdownBodyProps> = ({ children }) => {
  return <DropdownBodyContainer>{children}</DropdownBodyContainer>;
};

const ConditionBuilderDropdown: FC<ConditionDropdownProps> = ({ children }) => {
  return (
    <ConditionBuilderDropdownContainer>
      <DropdownHeader />
      <DropdownBody>{children}</DropdownBody>
    </ConditionBuilderDropdownContainer>
  );
};

export { ConditionBuilderDropdown, DropdownBody, DropdownHeader };

const ConditionBuilderDropdownContainer = styled.div`
  border: 2px solid black;
`;

const DropdownHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 41px;
  background-color: #dddfe0;
  min-width: 350px;
`;

const DropdownHeaderIcon = styled.div`
  display: flex;
`;

const DropdownBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  background-color: #fff;
`;
