import {
  OverflowSet,
  Spinner,
  Stack,
  Text,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { stringUtils } from "cayo.ui";
import React, { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { IBasicList } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import EmptyItem from "../Cards/EmptyItem";
import GenericScheme from "../Schemes/GenericScheme";
import { allRendereres } from "../Schemes/renderers";
import { EmptyContainer } from "../Widgets/common";
import { commonMessages } from "../common-messages";
import { DynamicResizeGroup } from "../DynamicResizeGroup";

interface IOverflowData {
  primary: any[];
  overflow: any[];
  cacheKey?: string;
}

const BasicList: FC<IBasicList> = (props) => {
  const intl = useIntl();
  const { data, isLoading } = ajax.useNotifications(props.url!, props.notificationSource, props);
  const { footer } = props;
  const itemType = stringUtils.dashedToLowCase(props.itemTemplate!.type);
  const rootId = useId("BasicList");

  const itemToRender = useMemo(() => {
    let result: IOverflowData = {
      primary: (data as any[])?.map((d, i) => ({ key: `item${i}`, ...d })),
      overflow: [] as any[],
    };

    return result;
  }, [data]);

  const onRenderItem = (item: any) => {
    return allRendereres[itemType]({ data: item, key: item.key, ...props.itemTemplate });
  };

  const onRenderMore = useCallback(
    (data: any[] | undefined) => {
      const msg = intl.formatMessage(commonMessages.moreItems, { count: data?.length });
      return <MoreText variant="small">{msg}</MoreText>;
    },
    [data]
  );

  const onRenderData = (data: any) => (
    <OverflowSet
      key={rootId + "_more"}
      vertical={true}
      items={data.primary}
      overflowItems={data.overflow.length ? data.overflow : null}
      onRenderItem={onRenderItem}
      onRenderOverflowButton={onRenderMore}
    />
  );

  if (!data || !data.length || isLoading) {
    return (
      <EmptyContainer>
        {isLoading ? (
          <Spinner label={intl.formatMessage(commonMessages.loading)} labelPosition="right" />
        ) : props.noItemsTemplate ? (
          <EmptyItem {...props.noItemsTemplate} {...props} />
        ) : (
          props.noItemsMessage || "no items"
        )}
      </EmptyContainer>
    );
  }

  return (
    <Stack verticalAlign={"start"} style={{ height: "100%" }}>
      <DynamicResizeGroup
        style={{ flexGrow: 1, height: "1px", overflow: "hidden" }}
        role="menubar"
        ariaLabel="Vertical Resize Group with an Overflow Set"
        data={itemToRender}
        onRenderData={onRenderData}
      />
      <GenericScheme
        {...(footer as any)}
        userRole={(props as any)?.userRole}
        style={{ height: "auto", flexShrink: 0 }}
      />
    </Stack>
  );
};


const MoreText = styled(Text)`
  margin-top: -5px;
`;

export default BasicList;
