import {
  Dropdown,
  Icon,
  IDropdownOption,
  IDropdownStyles,
  IRenderFunction,
  Stack,
  Text,
} from "@fluentui/react";
import { stringUtils, useTheme } from "cayo.ui";
import React, { FC, Fragment, useCallback, useMemo, useState } from "react";
import { PropertyAnnotation } from "../api/cayo-graph";
import ajax from "../libs/ajax";
import { ajaxUtils } from "../utils/ajax-utils";
import { appUtils } from "./App/utils";
import Spinner from "./Spinner";

type InplaceDropDownProps = {
  value: any;
  propertyAnnotations?: PropertyAnnotation[];
  url: string;
  targetType: string;
  fieldName: string;
  onRefresh?: () => void;
};

const InplaceDropDown: FC<InplaceDropDownProps> = ({
  value,
  propertyAnnotations,
  fieldName,
  url,
  onRefresh,
}) => {
  const { cayoTheme } = useTheme();
  const [realValue, setRealValue] = useState(value);
  const [isSubmitting, setIsSubmitting] = useState<boolean>();

  const options = useMemo<IDropdownOption[] | undefined>(() => {
    const suffix = "/" + fieldName;

    const annotation = propertyAnnotations?.find((a) => a.target?.endsWith(suffix));

    return annotation?.possibleValues?.map<IDropdownOption>((p) => ({
      key: p.value!,
      selected: realValue === p.value,
      text: stringUtils.toSpaceDelimitedSentenceCase(p.name!)!,
    }));
  }, [realValue, propertyAnnotations]);

  const onServerRenderSelectedItem: IRenderFunction<IDropdownOption[]> = (
    options,
    r
  ): JSX.Element => {
    //const option = options?.find((o) => o.selected);

    const defaultComp = r!(options)!;
    let icon = undefined;
    if (realValue === "resolved") {
      icon = (
        <Icon
          iconName={"SkypeCheck"}
          styles={{
            root: {
              color: cayoTheme.brandColors.success,
              marginTop: 3,
              marginRight: 2,
            },
          }}
        />
      );
    } else if (realValue === "dismissed") {
      icon = (
        <Icon
          iconName={"Cancel"}
          styles={{ root: { color: cayoTheme.brandColors.disabled, marginTop: 3, marginRight: 2 } }}
        />
      );
    } else if (realValue === "new") {
      icon = (
        <Icon
          iconName={"AsteriskSolid"}
          styles={{
            root: {
              color: cayoTheme.brandColors.secondaryHighlight,
              marginTop: 3,
              marginRight: 2,
              fontSize: 13,
            },
          }}
        />
      );
    }

    if (icon) {
      return (
        <Stack
          horizontal={true}
          verticalAlign="center"
          verticalFill={true}
          tokens={{ childrenGap: 6 }}
          grow={true}
        >
          {icon}
          {defaultComp}
        </Stack>
      );
    }
    return defaultComp;
  };

  const styles = useCallback(
    (realValue: any): Partial<IDropdownStyles> => {
      const result = {
        root: { fontSize: 13 },
        title: {
          borderStyle: "none !important",
          paddingLeft: 4,
          ...(realValue === "new" ? { fontWeight: "bold !important" } : {}),
        },
        //dropdown: { borderStyle: "none !important", fontWeight: "bold !important" },
        //dropdownOptionText: { fontWeight: "bold !important" },
      };

      return result;
    },
    [realValue]
  );

  const updateValue = useCallback(
    (newValue: any) => {
      const submit = async () => {
        setIsSubmitting(true);
        try {
          await ajax.getClient("v0/" + url, "PATCH", undefined, {
            [fieldName]: newValue,
          });
          setRealValue(newValue);
          onRefresh && onRefresh();
        } catch (e) {
          const error = await ajaxUtils.getError(e);
          appUtils.showError(error?.error_description);
        } finally {
          setIsSubmitting(false);
        }
      };

      submit();
    },
    [realValue, url]
  );

  return (
    <Stack horizontal={true} verticalAlign="center" verticalFill={true} grow={true}>
      <Text variant="small">{stringUtils.toSpaceDelimitedSentenceCase(fieldName)! + ":"}</Text>
      {isSubmitting && <Spinner size={32} />}
      {!isSubmitting && options?.length ? (
        <Dropdown
          onRenderTitle={onServerRenderSelectedItem}
          dropdownWidth={150}
          options={options}
          styles={styles(realValue)}
          onChange={(_, o) => updateValue(o?.key)}
        />
      ) : (
        <Fragment />
      )}
    </Stack>
  );
};

export default InplaceDropDown;
