import {
  ColumnActionsMode,
  CommandBar,
  CommandBarButton,
  ConstrainMode,
  DetailsHeader,
  DetailsList,
  DetailsListLayoutMode,
  DirectionalHint,
  FontIcon,
  ICommandBarItemProps,
  ICommandBarStyles,
  IObjectWithKey,
  MarqueeSelection,
  PrimaryButton,
  ScrollablePane,
  SearchBox,
  Selection,
  SelectionMode,
  Stack,
  Sticky,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { useAsync } from "@fluentui/react-hooks";
import { LoadingOverlay, domUtils, logger, themeUtils, useTheme } from "cayo.ui";
import React, { FC, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Entity } from "../../api/cayo-graph";
import ajax from "../../libs/ajax";
import { getError } from "../../libs/odata.client";
import { urlUtils } from "../../utils/url-utils";
import { appEvents } from "../App/app-events";
import { appUtils } from "../App/utils";
import { Row } from "../CommonLayout";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
import { useQuickFilterBarButtonStyle } from "../GridContainer/GridFilterBar.style";
import { DispatchAction } from "../GridContainer/grid-model/grid.controller.actions";
import { useStatefulGrid } from "../GridContainer/hooks/useStatefulGrid";
import { renderItemColumn } from "../GridContainer/renderers/renderItemColumn";
import Panel from "../Panel/Panel";
import { commonMessages } from "../common-messages";
import IToolbarActions from "./IToolbarActions";
import objectPickerHooks from "./hooks";
import { fetchObjects } from "./logic";
import useScope from "./scopes.hook";
import { getDetailsListSyles } from "./style";

const log = logger.getLogger("SavedQueryPicker");

const IS_FAVORITE_FIELD_NAME = "isFavorite";

const modelReducer = (old: Entity[], newModel: Entity[]) => {
  return newModel;
};

type SavedQueryOickerProps = {
  id: string;
  path: string;
  closeButtonName: string;
  title: string;
  onClose?: () => void;
  onItemsSelected: (items: Entity[]) => Promise<void>;
  actions?: IToolbarActions;
  favoriteIds?: string[];
  dispatch: DispatchAction;
  isPreviewImmediate?: boolean;
};

const SavedQueryPicker: FC<SavedQueryOickerProps> = (props) => {
  const { id, path, closeButtonName, title, onClose, onItemsSelected, actions, favoriteIds } =
    props;
  const async = useAsync();
  const intl = useIntl();
  const theme = useTheme();
  const [anrFilter, setAnrFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [updateTime, setUpdateTime] = useState(new Date());
  const [items, setItems] = useState<Entity[]>([]);
  const [viewItems, setViewItems] = useState<Entity[]>([]);
  const [favoritesFilter, setFavoritesFilter] = useState(false);
  const [selectedItems, setSelectedItems] = useReducer(modelReducer, []);
  const { getIconAnnotation, getTypeAnnotation } = useTypeAnnotations();

  const allowFavorites = Array.isArray(favoriteIds);

  const { queryPath, scopes, isLoading: isScopeLoading } = useScope(path);

  const schemeId = urlUtils.getSchemeId(path);
  const { isLoading: isAnnotationsLoading, data: propertyAnnotations } =
    ajax.usePropertyAnnotations(`v0/{schemeId}`, { schemeId });

  const btnStyle = useQuickFilterBarButtonStyle();
  const commandBarStyles: ICommandBarStyles = useMemo(
    () => ({
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        margin: "0 5px",
        background: themeUtils.addAlpha(theme.cayoTheme.brandColors.secondaryBackground, 0.7),
        borderRadius: 4,
        height: 40,
        marginBottom: 8,

        selectors: {
          "& > .ms-CommandBar-primaryCommand": { alignItems: "center" },
          ":after": {
            position: "absolute",
            height: 1,
            left: 5,
            right: 5,
            bottom: -8,
            backgroundColor: theme.cayoTheme.brandColors.divider,
            content: "''",
          },
        },
      },
    }),
    [theme]
  );

  const selection = useMemo(() => {
    const newSelection: Selection = new Selection({
      getKey: (item: IObjectWithKey & { id: string }) => item["id"],
      onSelectionChanged: () => {
        const entities = selection.getSelection() as Entity[];
        log.debug("selected entities -> ", entities);
        setSelectedItems(entities);
        if (!!props.isPreviewImmediate && Array.isArray(entities) && entities.length === 1) {
          previewQuery(entities[0]);
        }
      },
    });

    return newSelection;
  }, []);

  const previewQuery = useCallback(
    (item: Entity) => {
      props.dispatch({ kind: "selectQuery", queryId: item.id ?? "", previewQuery: true });
    },
    [props.dispatch]
  );

  const onSetItems = useCallback(
    function setItemsFn(items: Entity[]) {
      if (!allowFavorites) {
        return items;
      }

      return items.map((itm) => {
        itm[IS_FAVORITE_FIELD_NAME] = favoriteIds.includes(itm.id ?? "");
        return itm;
      });
    },
    [favoriteIds]
  );

  const onRefresh = useCallback(
    function onRefresh(createdItem?: Entity) {
      if (createdItem) {
        const newItems = [...items];
        newItems.unshift(createdItem); //FAWORITES

        setItems(onSetItems(newItems));
        selection.selectToKey(createdItem.id!, true);
      } else {
        setUpdateTime(new Date());
      }
    },
    [items]
  );

  const commandBarButtons = !props.isPreviewImmediate
    ? objectPickerHooks.useCommandBar(actions, selectedItems, intl, onRefresh).concat({
        key: "previewQuery",
        text: intl.formatMessage(commonMessages.previewQuery),
        iconProps: { iconName: "RedEye" },
        disabled: !selectedItems || selectedItems.length === 0,
        onClick: () => {
          previewQuery(selectedItems[0]);
        },
      })
    : objectPickerHooks.useCommandBar(actions, selectedItems, intl, onRefresh);

  const defaultColumns = useMemo(() => {
    let initialColumns = [];
    if (allowFavorites) {
      initialColumns.push({
        fieldName: IS_FAVORITE_FIELD_NAME,
        key: IS_FAVORITE_FIELD_NAME,
        name: intl.formatMessage(messages.favorites),
        minWidth: 36,
        maxWidth: 36,
        ColumnActionsMode: ColumnActionsMode.hasDropdown,
        iconName: "FavoriteStar",
        isIconOnly: true,
        columnActionsMode: ColumnActionsMode.disabled,
        isResizable: false,
        styles: {
          cellName: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          cellTitle: {
            paddingLeft: 8,
            flexFlow: "row",
            justifyContent: "center",
          },
        },
        onRenderHeader: (columnProps: any, defaultRender: any) => {
          return (
            <TooltipHost
              id="favHeader"
              content={intl.formatMessage(messages.favorites)}
              directionalHint={DirectionalHint.bottomCenter}
            >
              <div style={{ position: "absolute", top: 3, bottom: 3, left: 3, right: 3 }}></div>
              {defaultRender(columnProps)}
            </TooltipHost>
          );
        },
        onRender: (item: any) => {
          return (
            <div
              style={{
                //width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.dispatch({ kind: "toggleFavorite", id: item.id! });
              }}
              onMouseDown={domUtils.stopMouseDown}
            >
              <FontIcon
                style={{
                  marginTop: 3,
                  color: item[IS_FAVORITE_FIELD_NAME]
                    ? theme.cayoTheme.brandColors.warning
                    : "inherit",
                }}
                className={item[IS_FAVORITE_FIELD_NAME] ? "" : "cs-ShowOnHover"}
                iconName={item[IS_FAVORITE_FIELD_NAME] ? "FavoriteStarFill" : "FavoriteStar"}
              />
            </div>
          );
        },
      });
    }

    initialColumns.push({
      fieldName: "name",
      key: "name",
      name: intl.formatMessage(messages.name),
      minWidth: 150,
      maxWidth: 150,
      columnActionsMode: ColumnActionsMode.clickable,
      isResizable: true,
    } as any);

    initialColumns.push({
      fieldName: "builtIn",
      key: "builtIn",
      name: intl.formatMessage(messages.system),
      minWidth: 70,
      maxWidth: 100,
      columnActionsMode: ColumnActionsMode.clickable,
      isResizable: true,
    });

    initialColumns.push({
      fieldName: "createdBy",
      key: "createdBy",
      name: intl.formatMessage(messages.createdBy),
      minWidth: 100,
      maxWidth: 100,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
    } as any);

    initialColumns.push({
      fieldName: "lastModifiedDateTime",
      key: "lastModifiedDateTime",
      name: intl.formatMessage(messages.modificationTime),
      minWidth: 100,
      maxWidth: 200,
      columnActionsMode: ColumnActionsMode.clickable,
      isResizable: true,
    });

    return initialColumns;
  }, []);

  const {
    sortBy,
    setSortBy,
    columns,
    clear: clearGridSetings,
  } = useStatefulGrid(id, defaultColumns);

  useEffect(
    function fetchItems() {
      if (isScopeLoading || !queryPath) {
        return;
      }
      log.debug(
        "fetchItems -> updateTime, sortBy, queryPath, isScopeLoading",
        updateTime.valueOf(),
        sortBy,
        queryPath,
        isScopeLoading
      );
      setIsLoading(true);
      fetchObjects({
        path: queryPath!,
        searchText: anrFilter,
        orderBy: sortBy.fieldName,
        desc: sortBy.desc,
        filter: undefined,
        select: undefined,
        expand: "createdBy",
      })
        .then((response) => {
          log.log(response);
          // FAVORITES + FILTER
          setItems(onSetItems(response));
        })
        .catch((e) => {
          log.debug(e);
          clearGridSetings();
          setIsLoading(false);
          setItems([]);
          getError(e).catch((msg) => appUtils.showError(msg));
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [updateTime, sortBy, queryPath, isScopeLoading]
  );

  useEffect(
    function updateFavorites() {
      setItems(onSetItems(items));
    },
    [favoriteIds]
  );

  useEffect(
    function () {
      if (!Array.isArray(items)) {
        return;
      }
      if (favoritesFilter) {
        setViewItems(items.filter((itm) => !!itm[IS_FAVORITE_FIELD_NAME]));
      } else {
        setViewItems(items);
      }
    },
    [items, favoritesFilter]
  );

  const onItemSelected = useCallback(() => {
    setIsLoading(true);
    onItemsSelected(selection.getSelection() as Entity[])
      .then(() => {
        onClose!();
      })
      .catch((e) => {
        return getError(e)
          .then((e) => appEvents.trigger({ showError: e }))
          .catch((err) => {
            appEvents.trigger({ showError: err });
            log.error(err);
          });
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [selection]);

  const initFetch = useCallback(
    async.debounce(() => {
      setUpdateTime(new Date());
    }, 1000),
    [setUpdateTime]
  );

  const onFavoriteFilterClick = useCallback(() => {
    setFavoritesFilter((f) => !f);
  }, [setFavoritesFilter]);

  const favoriteFilterBtn: ICommandBarItemProps = useMemo(() => {
    return {
      key: "favoriteFilter",
      onRender: () => {
        return (
          <CommandBarButton
            title={intl.formatMessage(messages.favoritesFilter)}
            text={intl.formatMessage(messages.favoritesFilter)}
            checked={favoritesFilter}
            styles={btnStyle}
            onClick={onFavoriteFilterClick}
          />
        );
      },
    };
  }, [favoritesFilter, onFavoriteFilterClick]);

  const anrSearchInp: ICommandBarItemProps = {
    key: "anrSearchInp",
    onRender: () => {
      return (
        <SearchBox
          styles={{ root: { minWidth: 230 } }}
          value={anrFilter}
          onSearch={(value) => {
            setAnrFilter(value);
          }}
          onChange={(value) => {
            setAnrFilter(value?.target.value || "");
            initFetch();
          }}
          placeholder={intl.formatMessage(messages.searchByName)}
        />
      );
    },
  };

  return (
    <Panel
      isOpen={true}
      isBlocking={false}
      type={"medium"}
      hideCloseButton={true}
      onClose={onClose}
    >
      {(isLoading || isAnnotationsLoading) && <LoadingOverlay />}
      <Stack horizontal={false} style={{ height: "100%", width: "100%" }}>
        <Row
          valign={true}
          justifyContent="flex-start"
          style={{
            padding: "20px 40px 10px 20px",
            position: "relative",
            overflow: "hidden",
            width: "calc(100% - 70px)",
          }}
        >
          <Text variant="large">{title}</Text>
        </Row>
        <Stack
          horizontal={true}
          verticalAlign={scopes?.length ? "end" : "center"}
          style={{
            padding: "8px 10px ",
            margin: "10px 10px 0 10px",
          }}
        >
          {!allowFavorites && (
            <SearchBox
              styles={{ root: { minWidth: 230 } }}
              value={anrFilter}
              onSearch={(value) => {
                setAnrFilter(value);
              }}
              onChange={(value) => {
                setAnrFilter(value?.target.value || "");
                initFetch();
              }}
              placeholder={intl.formatMessage(messages.searchByName)}
            />
          )}

          <CommandBar
            items={commandBarButtons}
            style={{ flexGrow: 1, width: "50%" }}
            styles={{ root: { flexGrow: 1, width: "100%", paddingLeft: 8 } }}
          />
        </Stack>

        {allowFavorites && (
          <CommandBar
            style={{ padding: "4px", margin: "0 10px 0" }}
            items={[anrSearchInp, favoriteFilterBtn]}
            styles={commandBarStyles}
          />
        )}
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",

            overflow: "auto hidden",
            // display: "flex",
            // flex: "1 1 auto",
          }}
        >
          <ScrollablePane
            scrollbarVisibility={"auto"}
            styles={{
              root: { stickyBelow: { display: "none" }, stickyBelowItems: { display: "none" } },
            }}
          >
            <MarqueeSelection selection={selection}>
              <DetailsList
                items={viewItems}
                columns={columns}
                selection={selection}
                onItemInvoked={onItemSelected}
                selectionMode={SelectionMode.single}
                onColumnHeaderClick={(e, c) => {
                  setSortBy({
                    fieldName: c!.fieldName!,
                    desc:
                      sortBy.fieldName === c?.fieldName
                        ? !c!.isSortedDescending
                        : c!.isSortedDescending,
                  });
                }}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onRenderDetailsHeader={(detailsHeaderProps, defaultRender) => {
                  return (
                    <Sticky>
                      <DetailsHeader
                        styles={{
                          root: {
                            paddingTop: 0,
                            selectors: {
                              ".ms-DetailsHeader-cellName": {
                                fontSize: 12,
                              },
                            },
                          },
                        }}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        {...detailsHeaderProps}
                      />
                    </Sticky>
                  );
                }}
                styles={() => getDetailsListSyles()}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                constrainMode={ConstrainMode.unconstrained}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                onRenderItemColumn={(item, index, column) =>
                  renderItemColumn(
                    item,
                    index!,
                    column,
                    (path?: string, query?: string, item?: any) => {
                      appEvents.trigger({
                        showControl: { controlId: "scheme", parameters: item + "/properties" },
                      });
                      log.debug(path, query, item);
                      return Promise.resolve();
                    },
                    {
                      theme,
                      intl,
                      getIconAnnotation,
                      getTypeAnnotation,
                      propertyAnnotations,
                    }
                  )
                }
              />
            </MarqueeSelection>
          </ScrollablePane>
        </div>
        <Row
          style={{
            padding: 20,
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <PrimaryButton disabled={!selection.getSelection().length} onClick={onItemSelected}>
            {closeButtonName || intl.formatMessage(commonMessages.select)}
          </PrimaryButton>
        </Row>
      </Stack>
    </Panel>
  );
};

const messages = defineMessages({
  searchByName: { id: "saved-query-picker.search-by-name", defaultMessage: "Search" },
  modificationTime: {
    id: "saved-query-picker.columns.modification-time",
    defaultMessage: "Modification time",
  },
  name: { id: "saved-query-picker.columns.name", defaultMessage: "Name" },
  system: { id: "saved-query-picker.columns.builtIn", defaultMessage: "Built-in" },
  createdBy: { id: "saved-query-picker.columns.createdBy", defaultMessage: "Created by" },
  favorites: { id: "saved-query-picker.columns.favorites", defaultMessage: "Favorites" },
  favoritesFilter: { id: "saved-query-picker.favoritesFilter", defaultMessage: "Favorites" },
});

export { SavedQueryPicker };
