import logger from "../libs/logger";
import { AccessToken } from "../types/access-token.model";
import LiteEvent from "./lite-event";
import SettingsStore from "./settings-store";

class TokenStorage {
  private readonly onSave = new LiteEvent<AccessToken>();
  readonly key = "token";
  private readonly log = logger.getLogger("TokenStorage");

  public get SaveEvent() {
    return this.onSave.expose();
  }

  constructor() {
    this.log.debug("constructor");
  }

  getToken(): AccessToken | undefined {
    const id_token = sessionStorage.getItem("msal.idtoken");
    if (id_token) {
      return { access_token: id_token };
    }

    const token = this.localAuthSetting.get() || this.sessionAuthSetting.get();
    return token;
  }

  setToken(token: AccessToken | undefined, saveToLocal?: boolean | undefined) {
    let keepInLocalStorage = !!this.localAuthSetting.get()?.access_token;
    if (saveToLocal !== undefined) {
      keepInLocalStorage = saveToLocal;
    }
    keepInLocalStorage ? this.localAuthSetting.set(token) : this.sessionAuthSetting.set(token);
    this.onSave.trigger(token);
  }

  clear() {
    this.localAuthSetting.set(undefined);
    this.sessionAuthSetting.set(undefined);

    this.onSave.trigger(undefined);
  }

  private localAuthSetting = new SettingsStore<AccessToken, {}>("token", localStorage);
  private sessionAuthSetting = new SettingsStore<AccessToken, {}>("token", sessionStorage);
}

const tokenStorage = new TokenStorage();

export type ITokenStorage = typeof tokenStorage;
export default tokenStorage;
