import { useEffect } from "react";
import {
  CommonSignals,
  ISignalServiceBase,
  SignalServiceBase,
} from "../../services/signal-service-base";

export const dataSetWidgetSignals: ISignalServiceBase<CommonSignals, string> =
  new SignalServiceBase<CommonSignals, string>();

type WidgetSignalsProps = { signal: CommonSignals; handler: () => void };

const useWidgetSignals = (prms: WidgetSignalsProps) => {
  const { signal, handler } = prms;
  useEffect(() => {
    return dataSetWidgetSignals.subscribe(signal, handler);
  }, []);
};

export default useWidgetSignals;
