import { useCallback, useEffect, useMemo, useState } from "react";
import { useInitialConfigAlert } from "../../Alerts/initial-config-alert.hook";
import { useSchemes } from "../../App/hooks";
import useRefreshKey from "../../GlobalHooks/use-refresh-key.hook";
import InitialConfigurationController from "./InitialConfigurationController";

const useInitialConfiguration = (isLoggedIn?: boolean) => {
  const { showControl } = useSchemes();

  const { updateTime, forceRefresh } = useRefreshKey("initialConfiguration");
  const [showConfigWizard, setShowConfigWizard] = useState<boolean | undefined>(undefined);

  const { showInitialConfigAlert, hideInitialConfigAlert } = useInitialConfigAlert();

  const controller = useMemo(
    () => new InitialConfigurationController(showControl, forceRefresh),
    []
  );

  useEffect(() => {
    const asyncInit = async () => {
      setShowConfigWizard(undefined);

      await controller.init();

      setShowConfigWizard(controller.wizardVisible);
    };

    if (isLoggedIn) {
      asyncInit();
    }
  }, [controller, isLoggedIn, updateTime]);

  const onAlertCLicked = useCallback(() => {
    const asyncF = async () => {
      controller.showWizard();
    };

    asyncF();
  }, [forceRefresh, controller]);

  useEffect(() => {
    if (controller.hasManagedSystems === undefined) {
      return;
    }

    if (!controller.hasManagedSystems) {
      showInitialConfigAlert(onAlertCLicked);
    } else {
      hideInitialConfigAlert();
    }
  }, [showInitialConfigAlert, !!controller.hasManagedSystems]);

  return {
    showConfigWizard,
    configuration: controller.configuration,
  };
};

export default useInitialConfiguration;
