export function durationMinutes(duration?: number) {
  if (!duration) {
    return "";
  }
  //return intl?.formatTime(duration, { minute: "numeric" })

  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  const unit_h = hours > 1 ? "hours" : "hour";
  const unit_m = minutes > 1 ? "minutes" : "minute";

  return (hours > 0 ? `${hours} ${unit_h}` : "") + (minutes > 0 ? ` ${minutes} ${unit_m}` : "");
}
