import { IDropdownOption } from "@fluentui/react";
import { ComparisonOperator, PropertyNameElement, SchemaElement } from "../../api/cayo-graph";
import { IColumn, IFormField, IObjectPicker } from "../../api/schema.api";
import { oClient } from "../../libs/odata.client";
import { endpoints } from "../../services/endpoints.service";
import tokenStorage from "../../settings/token-storage";
import {
  IExpressionCondition,
  IQuery,
  newQuery,
  setCondition,
} from "../GridContainer/grid-model/Query";
import { DispatchAction } from "../GridContainer/grid-model/grid.controller.actions";

type DictionaryData = {
  value: PropertyNameElement[];
};

export enum SupportedObjectTypeOptionTypes {
  STRING = "string",
  DATETIMEOFFSET = "datetimeoffset",
  BOOLEAN = "boolean",
}

type ObjectTypeOption = {
  name: string;
  allowedOperators: ComparisonOperator[];
  type: SupportedObjectTypeOptionTypes;
};

export type ObjectTypeOptionData = Pick<ObjectTypeOption, "type" | "allowedOperators">;

type ObjectTypeOptionsData = {
  properties: ObjectTypeOption[];
};

type UseConditionBuilder = {
  getColumnsOptions: (
    columns?: IColumn[],
    annotations?: Array<IFormField & IFormField>
  ) => Promise<(IColumn & IObjectPicker)[]>;
  getColumnOptions: (
    fieldName: string,
    columns: (IColumn & IObjectPicker)[]
  ) => Promise<IDropdownOption[]>;
  buildAndFetchQuery: (condition: IExpressionCondition) => Promise<void>;
  getObjectTypesOptions: () => Promise<IDropdownOption[]>;
  getObjectTypePropertyOptions: (
    objectTypeId: string
  ) => Promise<IDropdownOption<ObjectTypeOptionData>[]>;
};

type UseConditionBuilderProps = {
  dispatch?: DispatchAction;
  query?: IQuery;
  objectTypesUrl?: string;
  objectTypesOptionsUrl?: string;
};

const useConditionBuilder = (props?: UseConditionBuilderProps): UseConditionBuilder => {
  async function getColumns(
    columns?: IColumn[],
    annotations?: Array<IFormField & IFormField>
  ): Promise<(IColumn & IObjectPicker)[]> {
    // const res = await fetch("/guardian/scheme/content/history/changeRecords", {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${tokenStorage.getToken()?.access_token}`,
    //   },
    // });

    // const resData = (await res.json()) as IContentScheme;

    // const { detailsList, filterBar } = resData;

    const mergedColumns = columns
      ?.map((v) => {
        const searchRes = annotations?.find((a) => a.name === v.fieldName);
        if (searchRes) return Object.assign({}, v, searchRes);
        return null;
      })
      .filter((v) => v !== null) as Array<IColumn & IObjectPicker>;

    return mergedColumns.filter((c) => c !== undefined);
  }

  function prepareDropdownOptions(
    values: PropertyNameElement[],
    selectPropertyName: string
  ): IDropdownOption[] {
    return values.reduce((prev, cur) => {
      return prev.concat([
        {
          key: cur[selectPropertyName] || cur?.objectName || "",
          text: cur.displayName || cur.name || cur.objectName || "",
        },
      ]);
    }, [] as IDropdownOption[]);
  }

  async function getColumnOptions(
    fieldName: string,
    columns: (IColumn & IObjectPicker)[]
  ): Promise<IDropdownOption[]> {
    const column = columns.find((c) => c.fieldName === fieldName);

    if (column) {
      const res = await fetch(`/guardian/${column.queryPath}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenStorage.getToken()?.access_token}`,
        },
      });

      const resData = (await res.json()) as DictionaryData;
      const options = prepareDropdownOptions(resData.value, column.selectPropertyName || "");

      return options;
    }

    return [] as IDropdownOption[];
  }

  async function buildAndFetchQuery(condition: IExpressionCondition): Promise<void> {
    const updatedQuery = setCondition(props?.query ?? newQuery(), condition);
    if (props?.dispatch) props.dispatch({ kind: "changeQuery", query: updatedQuery });
    // const preparedData = makeExpressionCondition(condition);

    // const res = await fetch("/guardian/v0/configuration/savedQueries/buildQueryFilter()", {
    //   method: "POST",
    //   body: JSON.stringify({
    //     queryBody: {
    //       expression: preparedData,
    //       targetType: "cayo.graph.historyRecord",
    //     },
    //   }),
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${tokenStorage.getToken()?.access_token}`,
    //   },
    // });

    // const resData = await res.json();

    // return resData;
  }

  async function getObjectTypesOptions(): Promise<IDropdownOption[]> {
    return (
      oClient(endpoints.publicUrl)
        // .get(`v0/schema/types?$filter=id eq '37470940b31c509c8f49bbbca9cdc6ce'`)
        .get(props?.objectTypesUrl + "?$count=true")
        .query()
        .then((response: SchemaElement[]) => {
          if (response?.length >= 1) {
            return response
              .reduce((prev, cur) => {
                return prev.concat([
                  {
                    key: cur?.objectName || cur?.fullName || "",
                    text: cur["displayName"] || "",
                    data: cur?.id,
                  },
                ]);
              }, [] as IDropdownOption[])
              .sort((a, b) => a.text.localeCompare(b.text));
          }
          return [] as IDropdownOption[];
        })
    );
  }

  async function getObjectTypePropertyOptions(
    objectTypeId: string
  ): Promise<IDropdownOption<ObjectTypeOptionData>[]> {
    if (props?.objectTypesOptionsUrl && objectTypeId)
      return oClient(endpoints.publicUrl)
        .get(props?.objectTypesOptionsUrl?.replace("{objectTypeId}", objectTypeId))
        .query()
        .then((response: ObjectTypeOptionsData) => {
          if (response?.properties?.length >= 1) {
            return response.properties
              .reduce((prev, cur) => {
                return prev.concat([
                  {
                    key: cur.name,
                    text: cur.name,
                    data: {
                      allowedOperators: cur.allowedOperators,
                      type: cur.type,
                    },
                  },
                ]);
              }, [] as IDropdownOption<ObjectTypeOptionData>[])
              .sort((a, b) => a.text.localeCompare(b.text));
          }
          return [] as IDropdownOption[];
        });
    return [] as IDropdownOption[];
  }

  return {
    getColumnsOptions: getColumns,
    getColumnOptions,
    buildAndFetchQuery,
    getObjectTypesOptions,
    getObjectTypePropertyOptions,
  };
};

export default useConditionBuilder;
