import { IAlertItem } from "./alerts.api";
import hightlightedAlerts from "./hightlighted-alerts";

const getAlertType = (alert: IAlertItem) => {
  return alert.categories?.find((c) => c.name === "AlertType" && c.value === "System")
    ? "systemAlerts"
    : alert.categories?.find((c) => c.name === "AlertType" && c.value === "RiskAssessment")
    ? "riskAssessment"
    : "changeAlerts";
};

const hightlightAlerts = (alerts: IAlertItem[]) => {
  alerts.forEach((a) => {
    a.highlighted = hightlightedAlerts.isHighlighted(a);
  });

  return alerts;
};
const alertUtils = {
  getAlertType,
  hightlightAlerts,
};

export default alertUtils;
