import { createIntl, createIntlCache } from "react-intl";

const localizedMessages = {
  en: require("./messages/en.json"),
};

export const languages = {
  en: "English",
};

const cache = createIntlCache();

let int = createIntl(
  {
    locale: "en",
    messages: localizedMessages["en"],
  },
  cache
);

const localeKey = "locale";
export const defaultLanguage = Object.getOwnPropertyNames(languages)[0];

class LocaleStore {
  public getLanguage() {
    return localStorage.getItem(localeKey) || defaultLanguage;
  }

  public getMessages() {
    return localizedMessages[this.getLanguage()];
  }

  public setLocale(lang: string) {
    localStorage.setItem(localeKey, lang);
  }

  public getLanguages(): string[] {
    return Object.getOwnPropertyNames(localizedMessages);
  }
}

export const localeStore = new LocaleStore();

const translate = (id: string, values?: {}) => {
  return int.formatMessage({ id }, values);
};

export default translate;
