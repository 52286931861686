import { endpoints } from "../services/endpoints.service";
import apiPaths from "../utils/api-path";
import { urlUtils } from "../utils/url-utils";

const databaseSettings = urlUtils.buildRelativePath(
  endpoints.PUBLIC_URL,
  apiPaths.historyDatabaseSettings
);

const uiSettings = urlUtils.buildRelativePath(endpoints.PUBLIC_URL, apiPaths.uiSettings);

const networkSettings = urlUtils.buildRelativePath(endpoints.PUBLIC_URL, apiPaths.networkSettings);

export const settingsEndpoints = {
  databaseSettings,
  uiSettings,
  networkSettings,
};
