import { IDateTimeBox, IMultiCheckbox, dateTimeUtils } from "cayo.ui";
import { IntlShape } from "react-intl";
import { PossibleValueItem, WeeklySchedule } from "../../api/cayo-graph";
import { IFormField } from "../../api/schema.api";
import { nameofFactory } from "../../utils/object-utils";
import { weekDays } from "../DateTimeBox/pickerStrings";
import { formComponentTypes } from "../Form/renderers/form-renderers";
import messages from "./messages";

const weeklyNames = nameofFactory<WeeklySchedule>();

export const possibleWeekDays = weekDays.map<PossibleValueItem>((d) => ({
  name: d,
  value: d.toLowerCase(),
}));

const weeklyForm = (intl: IntlShape, schedule?: WeeklySchedule) => {
  const startTime = dateTimeUtils.convertUtcTimeToLocal(
    dateTimeUtils.extractTime(schedule?.startTime) || dateTimeUtils.convertTimeToUtc("00:00")
  );

  return {
    title: intl.formatMessage(messages.weekly),
    items: [
      {
        type: "datetimebox" as formComponentTypes,
        label: intl.formatMessage(messages.startTime),
        autoFocus: true,
        required: true,
        editMode: "onlyTime",
        name: weeklyNames("startTime"),
        value: startTime,
      } as IDateTimeBox,
      {
        type: "mv.checkbox" as formComponentTypes,
        possibleValues: possibleWeekDays,
        label: intl.formatMessage(messages.selectWeekDaysTitle),
        required: true,
        name: weeklyNames("days"),
        value: schedule?.days,
        horizontal: true,
      } as IMultiCheckbox,
    ] as IFormField[],
  };
};

export default weeklyForm;
