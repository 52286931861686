import { dateTimeUtils, stringUtils } from "cayo.ui";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
  ContinuousSchedule,
  DailySchedule,
  FixedMonthDay,
  FloatingMonthDay,
  HourlySchedule,
  MinutelySchedule,
  MonthlySchedule,
  OneTimeSchedule,
  Schedule,
  WeeklySchedule,
} from "../../api/cayo-graph";
import scheduleTypes from "./types";

const oneTimeFormatter = (schedule: Schedule) => {
  const oneTimeSchedule = schedule as OneTimeSchedule;
  const date = new Date(oneTimeSchedule.startDateTime!);
  const result = (
    <FormattedMessage
      id="inplace-schedule.one-time"
      defaultMessage="At {time} on {date}"
      values={{
        time: dateTimeUtils.getTimeString(date),
        date: date.toLocaleDateString(),
      }}
    />
  );
  return result;
};

const dailyFormatter = (schedule: Schedule) => {
  const dailySchedule = schedule as DailySchedule;

  const localTime = dateTimeUtils.convertUtcTimeToLocal(
    dateTimeUtils.extractTime(dailySchedule.startTime!)!
  );
  const time = dateTimeUtils.parseTime(localTime).value;

  const result =
    dailySchedule.period === 1 ? (
      <FormattedMessage
        id="inplace-schedule.daily-one"
        defaultMessage="At {time} every day"
        values={{
          time: time ? dateTimeUtils.getTimeString(time) : "?",
        }}
      />
    ) : (
      <FormattedMessage
        id="inplace-schedule.daily-n"
        defaultMessage="At {time} every {days} days"
        values={{
          time: time ? dateTimeUtils.getTimeString(time) : "?",
          days: dailySchedule.period,
        }}
      />
    );

  return result;
};

const weeklyFormatter = (schedule: Schedule) => {
  const weeklySchedule = schedule as WeeklySchedule;

  const localTime = dateTimeUtils.convertUtcTimeToLocal(
    dateTimeUtils.extractTime(weeklySchedule.startTime!)!
  );
  const time = dateTimeUtils.parseTime(localTime).value;

  const result = (
    <FormattedMessage
      id="inplace-schedule.weekly"
      defaultMessage="At {time} every {weekDay}"
      values={{
        time: time ? dateTimeUtils.getTimeString(time) : "?",
        weekDay: weeklySchedule.days
          ?.map((d) => stringUtils.toSpaceDelimitedPascalCase(d))
          ?.join(", "),
      }}
    />
  );

  return result;
};

const monthlyFormatter = (schedule: Schedule) => {
  const monthlySchedule = schedule as MonthlySchedule;
  const fixedDay = monthlySchedule.monthDay as FixedMonthDay;
  const floatingDay = monthlySchedule.monthDay as FloatingMonthDay;

  const localTime = dateTimeUtils.convertUtcTimeToLocal(
    dateTimeUtils.extractTime(monthlySchedule.startTime!)!
  );
  const time = dateTimeUtils.parseTime(localTime).value;

  let result: JSX.Element;
  if (fixedDay?.objectType === "cayo.graph.fixedMonthDay") {
    result =
      monthlySchedule.period === 1 ? (
        <FormattedMessage
          id="inplace-schedule.monthly-fixed.one"
          defaultMessage="Runs every month at {time} on day {weekDay}, starting in January"
          values={{
            time: time ? dateTimeUtils.getTimeString(time) : "?",
            weekDay: fixedDay.day,
          }}
        />
      ) : (
        <FormattedMessage
          id="inplace-schedule.monthly-fixed.n"
          defaultMessage="Runs every {period} months at {time} on day {weekDay}, starting in January"
          values={{
            period: monthlySchedule.period,
            time: time ? dateTimeUtils.getTimeString(time) : "?",
            weekDay: fixedDay.day,
          }}
        />
      );
  } else if (floatingDay?.objectType === "cayo.graph.floatingMonthDay") {
    result =
      monthlySchedule.period === 1 ? (
        <FormattedMessage
          id="inplace-schedule.monthly-floating.one"
          defaultMessage="Runs every month at {time} on {dayNumber} {weekDay}, starting in January"
          values={{
            time: time ? dateTimeUtils.getTimeString(time) : "?",
            weekDay: floatingDay.weekDay
              ? stringUtils.toSpaceDelimitedPascalCase(floatingDay.weekDay)
              : floatingDay.weekDay,
            dayNumber: floatingDay.number,
          }}
        />
      ) : (
        <FormattedMessage
          id="inplace-schedule.monthly-floating.n"
          defaultMessage="Runs every {period} months at {time} on {dayNumber} {weekDay}, starting in January"
          values={{
            period: monthlySchedule.period,
            time: time ? dateTimeUtils.getTimeString(time) : "?",
            weekDay: floatingDay.weekDay
              ? stringUtils.toSpaceDelimitedPascalCase(floatingDay.weekDay)
              : floatingDay.weekDay,
            dayNumber: floatingDay.number,
          }}
        />
      );
  } else {
    result = <FormattedMessage id="inplace-schedule.not-set" defaultMessage="Not set" />;
  }

  return result;
};

const hourlyFormatter = (schedule: Schedule) => {
  const hourlySchedule = schedule as HourlySchedule;

  const result =
    hourlySchedule.period === 1 ? (
      hourlySchedule.minute === 0 ? (
        <FormattedMessage id="inplace-schedule.hourly" defaultMessage="Runs every hour " />
      ) : (
        <FormattedMessage
          id="inplace-schedule.hourly-minutes"
          defaultMessage="Runs every hour at {minute} minute"
          values={{
            minute: hourlySchedule.minute,
          }}
        />
      )
    ) : (
      <FormattedMessage
        id="inplace-schedule.hourly-n"
        defaultMessage="Runs every {period} hours at {minute} minute"
        values={{
          period: hourlySchedule.period,
          minute: hourlySchedule.minute,
        }}
      />
    );
  return result;
};

const minutelyFormatter = (schedule: Schedule) => {
  const minutelySchedule = schedule as MinutelySchedule;

  const result =
    minutelySchedule.period === 1 ? (
      <FormattedMessage id="inplace-schedule.minutely" defaultMessage="Runs every minute" />
    ) : (
      <FormattedMessage
        id="inplace-schedule.minutely-n"
        defaultMessage="Runs every {minute} minutes"
        values={{
          minute: minutelySchedule.period,
        }}
      />
    );

  return result;
};

const continuousFormatter = (schedule: Schedule) => {
  const continuousSchedule = schedule as ContinuousSchedule;

  if (typeof continuousSchedule.nextRunDelay === "number" && continuousSchedule.nextRunDelay > 0) {
    return (
      <FormattedMessage
        id="inplace-schedule.continuous-with-delay"
        defaultMessage="Runs continuously, every {duration} second(s)"
        values={{ duration: continuousSchedule.nextRunDelay }}
      />
    );
  }

  return <FormattedMessage id="inplace-schedule.continuous" defaultMessage="Runs continuously" />;
};

const customFormatter = () => (
  <FormattedMessage id="inplace-schedule.custom" defaultMessage="Custom schedule" />
);

type formatScheduleFuncType = typeof oneTimeFormatter;

const scheduleFormatters: { [key in scheduleTypes]: formatScheduleFuncType } = {
  "cayo.graph.notSetSchedule": () => (
    <FormattedMessage id="inplace-schedule.not-set" defaultMessage="Not set" />
  ),
  "cayo.graph.oneTimeSchedule": oneTimeFormatter,
  "cayo.graph.dailySchedule": dailyFormatter,
  "cayo.graph.weeklySchedule": weeklyFormatter,
  "cayo.graph.monthlySchedule": monthlyFormatter,
  "cayo.graph.hourlySchedule": hourlyFormatter,
  "cayo.graph.minutelySchedule": minutelyFormatter,
  "cayo.graph.continuousSchedule": continuousFormatter,
  "cayo.graph.customSchedule": customFormatter,
};

export default scheduleFormatters;
