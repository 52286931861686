import { logger } from "cayo.ui";
import globals from "../settings/globals";

const log = logger.getLogger("cookie");

export function setCookie(cname: string, cvalue: string, exdays: number = -1) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + (exdays ? d.toUTCString() : "-1");
  const secure = globals.isSecureConnection ? "secure" : "";

  const append = [cvalue, expires, "path=/", secure].filter((c) => !!c).join(";");

  const newCookie = cname + "=" + append;

  log.debug("set-cookie:", newCookie);
  document.cookie = newCookie;
}

export const deleteCookie = (cname: string) => {
  document.cookie = cname + "=; Max-Age=-99999999;";
};

function checkClientTimeZone() {
  // Set the client time zone
  var dt = new Date();
  var tz = -dt.getTimezoneOffset();
  setCookie("tz", tz.toString(), 1);
  return tz;
}

// do not remove this call. it's required for subscriptions (signalR)
checkClientTimeZone();
