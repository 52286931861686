import { defineMessages } from "react-intl";

const messages = defineMessages({
  startDate: {
    defaultMessage: "Start on",
    id: "schedule.start-date",
  },
  startTime: {
    defaultMessage: "Start at",
    id: "schedule.start-time",
  },
  oneTime: {
    defaultMessage: "One time",
    id: "schedule.one-time",
  },
  scheduleDialogTitle: {
    defaultMessage: "Schedule",
    id: "schedule.schedule-title",
  },

  daily: {
    defaultMessage: "Daily",
    id: "schedule.daily",
  },
  weekly: {
    defaultMessage: "Weekly",
    id: "schedule.weekly",
  },
  selectWeekDaysTitle: {
    defaultMessage: "On these days",
    id: "schedule.week-day-title",
  },
  selectWeekDayTitle: {
    defaultMessage: "Week day",
    id: "schedule.week-day",
  },
  monthly: {
    defaultMessage: "Monthly",
    id: "schedule.monthly",
  },
  hourly: {
    defaultMessage: "Hourly",
    id: "schedule.hourly",
  },
  minutely: {
    defaultMessage: "Every minute",
    id: "schedule.minutely",
  },
  continuous: {
    defaultMessage: "Continuous",
    id: "schedule.continuous",
  },
  customSchedule: {
    defaultMessage: "Custom",
    id: "schedule.custom-schedule",
  },
  repeatEvery: {
    defaultMessage: "Repeat every",
    id: "schedule.repeat-every",
  },
  onMinute: {
    defaultMessage: "On minute",
    id: "schedule.onminute",
  },
  fixedDay: {
    defaultMessage: "Fixed day",
    id: "schedule.month-day.fixed",
  },
  floatingDay: {
    defaultMessage: "Floating day",
    id: "schedule.month-day.floating",
  },
  continuousDescription: {
    defaultMessage: "Repeat the job as soon as previous run completed",
    id: "schedule.continuous.description",
  },
  monthsTip: {
    defaultMessage: "month(s)",
    id: "schedule.monthly.tip",
  },
  secondsTip: {
    defaultMessage: "seconds",
    id: "schedule.seconds.tip",
  },
  daysTip: {
    defaultMessage: "day(s)",
    id: "schedule.daily.tip",
  },
  hoursTip: {
    defaultMessage: "hour(s)",
    id: "schedule.hourly.tip",
  },
  hoursMinutesTip: {
    defaultMessage: "of the hour",
    id: "schedule.hourly.minutes.tip",
  },
  minutesTip: {
    defaultMessage: "minute(s)",
    id: "schedule.minutely.tip",
  },
  cronDescription: {
    defaultMessage:
      'Use CRON expression to specify the schedule. Examples:<br/><table> <tr> <td><b>Every minute</b></td><td nowrap>0/1 * * * *</td> </tr> <tr> <td><b>Every 2 minutes</b></td nowrap><td>0/2 * * * *</td> </tr> <tr> <td><b>Every hour</b></td><td nowrap>0 1/1 * * *</td> </tr> <tr> <td><b>Every 2 hours</b></td><td nowrap>0 1/2 * * *</td> </tr> <tr> <td><b>On day 1 of the month, every 3 months</b></td><td nowrap>0 0 1 1/3 *</td> </tr> </table><br><a target="_blank" href="https://support.cayosoft.com/hc/en-us/articles/4415481492493" style="white-space: nowrap;pointer-events: none" tabindex="-1">Learn more about how to customize job schedule</a>',
    id: "schedule.custom.cron",
  },
  schedule: {
    defaultMessage: "Schedule",
    id: "schedule.title",
  },
  dayOfMonth: {
    defaultMessage: "Day of month",
    id: "schedule.day-of-manth",
  },
  notSet: {
    defaultMessage: "Not set",
    id: "schedule.not-set",
  },
});

export default messages;
