import {
  ActionButton,
  ComboBox,
  DefaultButton,
  IComboBoxOption,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import { DragDropList, useTheme } from "cayo.ui";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import logger from "../../libs/logger";
import { FCC } from "../../types/react";
import { Column, Row } from "../CommonLayout";
import ObjectIcon from "../ObjectIcon";
import Panel from "../Panel/Panel";
import { commonMessages } from "../common-messages";
import {
  ChooseColumnsDialogController,
  ColumnData,
  IChooseColumnsDialogState,
  IChooseColumnsDialogStateItem,
} from "./ChooseColumnsDialog.controller";

const log = logger.getLogger("QUICK-FILTER-NEW ChooseColumnsDialog");

type ChooseColumnDualogPros = {
  userColumns: ColumnData[];
  allColums: ColumnData[];
  onClose: () => void;
  onSave: (columns: string[]) => void;
};

const dragDropListColumns = [{ key: "key", minWidth: 100, name: "key" }];

const initialState: IChooseColumnsDialogState = {
  items: [],
  canAdd: false,
  canSave: false,
};

export function ChooseColumnsDialog(props: ChooseColumnDualogPros): JSX.Element {
  const ctrl = useRef(
    new ChooseColumnsDialogController(initialState, props.allColums, props.userColumns)
  ).current;

  const [dialogState, setDialogState] = useState(ctrl.state);

  const intl = useIntl();
  const { cayoTheme } = useTheme();

  useEffect(function mount() {
    ctrl.subscribe((state) => {
      log.debug("state changed -> state", state);
      setDialogState(state);
    });
    return () => ctrl.dispose();
  }, []);

  const addColumn = useCallback(() => {
    ctrl.addNew();
  }, []);

  const onRenderItem = (v: IChooseColumnsDialogStateItem) => {
    //log.debug("onRenderItem -> i, v", i, v);
    return (
      <ColumnItem
        key={v.key}
        options={v.value}
        remove={() => ctrl.remove(v.key)}
        canRemove={ctrl.canRemove}
        change={(value) => {
          log.debug("change -> key, value", v.key, value);
          ctrl.change(v.key, value);
        }}
      />
    );
  };

  const reorder = (v: IChooseColumnsDialogStateItem[]) => {
    ctrl.reorder(v.map((i) => i.key));
  };

  return (
    <Panel type={"smallFluid"} isBlocking={true} onClose={props.onClose} isOpen={true}>
      <Column
        style={{
          display: "flex",
          height: "100vh",
          maxHeight: "100%",
          overflowY: "auto",
        }}
      >
        {/* header TODO: icon  */}
        <div>
          <Row style={{ padding: "20px 20px 0 20px" }}>
            <ObjectIcon iconName="CheckboxComposite" />

            <Column>
              <Text block={true} variant={"large"} style={{ marginLeft: 20, marginTop: 10 }}>
                <FormattedMessage
                  id="choose-columns-dialog.title"
                  defaultMessage="Choose columns"
                />
              </Text>
            </Column>
          </Row>
        </div>
        <Stack
          horizontal={false}
          verticalFill={true}
          tokens={cayoTheme.metrics.stackTokens}
          style={{
            padding: "20px 24px 8px 24px",
            flexGrow: 1,
            position: "relative",
            overflowY: "auto",
            height: "100%",
            display: "flex",
            overflowX: "hidden",
          }}
        >
          <DragDropList
            columns={dragDropListColumns}
            items={dialogState.items}
            setValue={reorder}
            onRenderItem={onRenderItem}
          />
          <ActionButton
            iconProps={{ iconName: "Add" }}
            onClick={addColumn}
            disabled={!dialogState.canAdd}
          >
            Add column
          </ActionButton>
        </Stack>
        <Stack
          horizontal={true}
          horizontalAlign={"end"}
          style={{ padding: "20px" }}
          tokens={cayoTheme.metrics.stackTokens}
        >
          <PrimaryButton
            disabled={!dialogState.canSave}
            onClick={() => {
              props.onSave(ctrl.selectedColumns);
            }}
          >
            {intl.formatMessage(commonMessages.save)}
          </PrimaryButton>

          <DefaultButton
            disabled={false}
            onClick={() => {
              props.onClose();
            }}
          >
            {intl.formatMessage(commonMessages.cancel)}
          </DefaultButton>
        </Stack>
      </Column>
    </Panel>
  );
}

type ColumnItemProps = {
  options: IComboBoxOption[];
  canRemove: boolean;
  remove: () => void;
  change: (v: string) => void;
};

const ColumnItem: FCC<ColumnItemProps> = (props) => {
  return (
    <Row style={{ paddingBottom: 0, cursor: "pointer" }} valign={true} draggable={true}>
      <span style={{ padding: "0 4px" }}>&nbsp;::&nbsp;</span>
      <ComboBox
        key={Date.now()}
        options={props.options}
        onChange={(e, option, i, v) => {
          log.debug("ComboBox.changed -> option, i, v", option, i, v);
          props.change(String(option?.key));
        }}
        style={{ flexGrow: 1 }}
      />
      <ActionButton
        style={{ paddingBottom: 4 }}
        disabled={!!props.canRemove}
        iconProps={{ iconName: "Cancel" }}
        onClick={props.remove}
      />
    </Row>
  );
};
