import { ActionButton, DirectionalHint, Separator, Stack, Text } from "@fluentui/react";
import { ITheme, TooltipHost, css, dateTimeUtils, debounce, htmlUtils } from "cayo.ui";
import styled from "styled-components";

import { IUIItem } from "cayo.ui/lib/esm/interfaces/common/IUIItem";
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { Entity, PropertyAnnotation } from "../api/cayo-graph";
import { IJsonForm, IObjectTitle } from "../api/schema.api";
import CollectActionParametersDialog from "../components/CollectActionParametersDialog";
import { endpoints } from "../services/endpoints.service";
import useService from "../services/services.hook";
import { appSettings } from "../settings/app-settings";
import { useSchemes } from "./App/hooks";
import useTypeAnnotations from "./GlobalHooks/type-annotations.hook";
import InplaceDropDown from "./InplaceDropdown";
import ObjectIcon from "./ObjectIcon";
import SeverityBadge from "./SeverityBadge";
import { getHtmlElememt } from "./Utilities/ChevronButton";
import { commonMessages } from "./common-messages";
import { isNativeHTML } from "../utils/html-utils";

const closeTooltipDelay = 10000; // 10 seconds

const ObjectTitle: FC<
  IObjectTitle & { object: Entity; propertyAnnotations?: PropertyAnnotation[] }
> = (props) => {
  const [actionProps, setActionProps] = useState<IJsonForm | null>(null);
  const actionBuilder = useService("actionBuilder");
  const { object, titleProperty, isTitleReadonly, isDescriptionReadonly, objectFacts } = props;

  const realObject = object;
  const description = (realObject as any)?.description;

  const { getIconAnnotation } = useTypeAnnotations();
  const intl = useIntl();
  const showEditButton = !isTitleReadonly || isDescriptionReadonly === false;
  const { refreshPropertiesPanel } = useSchemes();

  const TitleComponent = useMemo(() => {
    return showEditButton ? TitleText : Text;
  }, [showEditButton]);

  let title = useMemo(() => {
    let realTitle =
      (titleProperty && realObject && realObject[titleProperty]) ||
      (realObject as any)?.displayName ||
      (realObject as any)?.objectName;

    realTitle = htmlUtils.sanitize(realTitle);
    return realTitle;
  }, [titleProperty, realObject]);

  if (title instanceof Date) {
    title = dateTimeUtils.getDisplayDate(title);
  }

  const url = endpoints.makeFullApiPath(realObject!.objectPath!);

  const showRenameDialog = useCallback(() => {
    const fields = props.isTitleReadonly
      ? []
      : [
          {
            type: "text-field",
            name: props.titleProperty,
            label: intl.formatMessage(commonMessages.name),
            required: true,
            value: title,
          } as IUIItem,
        ];

    if (props.isDescriptionReadonly === false) {
      fields.push({
        type: "text-field",
        name: "description",
        label: intl.formatMessage(commonMessages.description),
        required: false,
        value: description,
        multiline: true,
      } as IUIItem);
    }

    const params: IJsonForm = {
      title: intl.formatMessage(commonMessages.rename),
      items: fields,
      type: "",
      submit: {
        url,
        method: "PATCH",
        response: {
          "200,204": "close",
        },
      },
    };

    setActionProps(params);
  }, []);

  const objectInfo = useMemo(
    () => ({
      icon: getIconAnnotation((realObject as any)?.nestedType || realObject?.objectType || ""),
      hasSchedule:
        realObject?.objectType && realObject.objectType.toLowerCase().endsWith("job")
          ? true
          : false,
    }),
    [realObject?.objectType]
  );

  const [isTitleClipped, setIsTitleClipped] = useState<boolean>(false);

  const textAreaRef = useRef<HTMLDivElement>(null);
  const calcChevronVisibility = useCallback(() => {
    const htmlElement =
      getHtmlElememt(textAreaRef)?.childNodes?.length &&
      (getHtmlElememt(textAreaRef)!.childNodes[0] as HTMLElement);
    if (
      htmlElement &&
      typeof htmlElement.scrollWidth === "number" &&
      typeof htmlElement.offsetWidth === "number"
    ) {
      const isTitleClipped = htmlElement.scrollWidth > htmlElement.offsetWidth;
      setIsTitleClipped(isTitleClipped);
    }
  }, [textAreaRef]);

  useLayoutEffect(() => {
    calcChevronVisibility();
  }, [calcChevronVisibility]);

  useEffect(() => {
    return appSettings.panelSetting.SaveEvent.on(() => {
      debounce(calcChevronVisibility, 100)();
    });
  }, [textAreaRef, calcChevronVisibility]);

  if (!realObject) {
    return <Fragment />;
  }

  return (
    <Wrapper horizontal={true} verticalAlign={"center"}>
      <ObjectIcon
        iconName={objectInfo?.icon?.iconId}
        // color={iconColors?.color}
        // backgroundColor={iconColors?.backgroundColor}
      />
      <Content grow={true}>
        <Stack>
          <StyledStack
            horizontal={true}
            verticalAlign={"center"}
            horizontalAlign={"start"}
            onClick={showEditButton ? showRenameDialog : undefined}
            showEditButton={showEditButton}
          >
            <TooltipHost
              directionalHint={DirectionalHint.bottomAutoEdge}
              tooltip={title}
              showCopyButton={false}
              showChildrenOnly={!isTitleClipped}
              closeDelay={closeTooltipDelay}
            >
              <TitleComponent block={false} nowrap={true} variant={"large"}>
                <div ref={textAreaRef}>
                  {isNativeHTML(title) ? (
                    <div dangerouslySetInnerHTML={{ __html: title }!} className={css.trimmText} />
                  ) : (
                    <div className={css.trimmText}>{title}</div>
                  )}
                </div>
              </TitleComponent>
            </TooltipHost>
            {showEditButton && (
              <EditButton iconProps={{ iconName: "Edit", styles: { root: { fontSize: 12 } } }} />
            )}
          </StyledStack>
        </Stack>

        <StyledText variant="medium" block={false} nowrap={true} title={description}>
          {description}
        </StyledText>
        {objectFacts?.length && (
          <Stack horizontal={true} verticalAlign="center" tokens={{ childrenGap: 2 }}>
            {objectFacts?.map((f, i) => {
              const parts = f.split("|");
              const prop = parts[0];
              const formatterName = parts.length > 1 ? parts[1] : undefined;
              const formatter = formatterName && formatters[formatterName.toLowerCase()];
              if (formatter) {
                return (
                  <Fragment key={i}>
                    {formatter({
                      value: realObject[prop],
                      propertyAnnotations: props?.propertyAnnotations,
                      fieldName: prop,
                      url: realObject.objectPath,
                      onRefresh: refreshPropertiesPanel,
                    })}
                    {i < objectFacts.length - 1 && <Separator vertical={true} />}
                  </Fragment>
                );
              }
              return <Fragment key={i} />;
            })}
          </Stack>
        )}
      </Content>
      {actionProps && (
        <StyledCollectActionParametersDialog
          {...actionProps}
          onClose={() => setActionProps(null)}
          onSubmitCB={() => {
            refreshPropertiesPanel();
            setActionProps(null);
          }}
          actionBuilder={actionBuilder}
          footer={{ okText: "Yes" }}
        />
      )}
    </Wrapper>
  );
};

const TitleText = styled(Text)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Wrapper = styled(Stack)`
  width: 100%;
  padding-right: 40px;
  padding-top: 34px;
  padding-left: 20px;
`;

const Content = styled(Stack)`
  padding-left: 8px;
  overflow: hidden;
  z-index: 1;
`;

const StyledStack = styled(Stack)<{ showEditButton: boolean }>`
  position: relative;
  cursor: ${(props) => (props.showEditButton ? "pointer" : "auto")};
`;

const EditButton = styled(ActionButton)`
  padding-bottom: 0px;
  height: 24px;
`;

const StyledCollectActionParametersDialog = styled(CollectActionParametersDialog)`
  position: relative;
`;

const StyledText = styled(Text)`
  color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.disabled};
`;

const formatters = {
  badge: SeverityBadge,
  inplacedropdown: InplaceDropDown,
};

export default ObjectTitle;
