import { Stack, Text } from "@fluentui/react";
import { stringUtils, useTheme } from "cayo.ui";
import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { Severity } from "../api/cayo-graph";

const SeverityBadge: FC<{ value: Severity; text?: string }> = ({ value, text }) => {
  const { getColor } = useTheme();

  const iconColors = useMemo(() => {
    if (value !== "debug" && value !== "informational" && value !== undefined) {
      return {
        backgroundColor: getColor(value, "Severity"),
        color: value === "medium" || value === "warning" ? "black" : "white",
      } as Partial<React.CSSProperties>;
    }

    return undefined;
  }, [value]);

  return (
    <Stack horizontal={true} verticalAlign="center" tokens={{ childrenGap: 0 }}>
      <BadgeText
        variant="smallPlus"
        color={iconColors?.color}
        backgroundColor={iconColors?.backgroundColor}
      >
        {text ?? stringUtils.toSpaceDelimitedPascalCase(value)}
      </BadgeText>
    </Stack>
  );
};

const BadgeText = styled(Text)<{ color?: string; backgroundColor?: string }>`
  text-align: center;
  font-weight: 500;
  min-width: 64px;
  position: relative;
  left: -1px;
  padding: 2px 4px;
  color: ${(props) => props.color};
  background: ${(props) => props.backgroundColor};
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  text-transform: uppercase;
`;

export default SeverityBadge;
