import { IIconProps } from "@fluentui/react";
import { ITheme, builtinFormatters, stringUtils, themeUtils } from "cayo.ui";
import { BuiltInFormatters } from "cayo.ui/lib/esm/formatters/builtin-formatters";
import { IntlShape } from "react-intl";
import { IIcon } from "../../api/schema.api";
import logger from "../../libs/logger";
import { Dictionary } from "../../types/dictionary";
import { buildInIconFormatters } from "../formatters/builtin-icon-formatters";
import clientResolvers from "../transforms/client-vars-resolver";
import { evaluateODataLogicalExpression } from "../transforms/odata-logical-expression-evaluator";
import bindingsRegexes from "./regexes";

const log = logger.getLogger("bindings");

const resolveConditionalOperator = (model: Dictionary, expression: string) => {
  const match = expression.match(bindingsRegexes.conditionalOperatorRegex);
  if (match && match.length >= 4 && match[2]) {
    const modelProperty = match[2].trim();
    const expIf = match[3];
    const expElse = match[4];
    const modelValue = model[modelProperty];
    const evalVariable = modelValue ? expIf : expElse;
    return evalVariable.trim();
  } else if (model) {
    let propName = expression;
    const not = propName.startsWith("!");
    propName = propName.replace(/!/, "");

    const isSimpleBinding = propName.indexOf("{") === -1;
    const val = isSimpleBinding ? resolvePropertyPath(model, propName, isSimpleBinding) : undefined;
    if (val !== undefined) {
      return not ? !val : val;
    }
  }

  return undefined;
};

const resolveSimpleExpression = (model: Dictionary, expression: string, intl?: IntlShape): any => {
  if (!bindingsRegexes.specialCharacters.test(expression)) {
    const modelProperty = expression.trim();

    const modelValue =
      clientResolvers.resolve(modelProperty) || handleFormatters(model, modelProperty, intl);
    return expression.replace(
      expression,
      modelValue === undefined || modelValue === null ? "" : modelValue
    );
  } else {
    const match2 = expression.match(bindingsRegexes.insideSquareBruckets);
    if (match2) {
      const nestedProp = expression.replace(bindingsRegexes.insideSquareBruckets, "");

      if (!model) {
        throw new Error("Model is invalid");
      }

      if (nestedProp.indexOf(".") !== -1) {
        // resolves "{errors[0].message}"
        const nestedPropParts = nestedProp.split(".");

        const nestedModel: any = model[nestedPropParts[0]];
        if (nestedModel && nestedModel.length > 0) {
          const arrayObject = nestedModel && resolvePropertyPath(nestedModel, match2[0]);
          const value: any = arrayObject[nestedPropParts[1]];
          return value;
        } else {
          return "";
        }
      } else {
        const nestedModel: any = model[nestedProp];
        return nestedModel && resolvePropertyPath(nestedModel, match2[0]);
      }
    }
  }

  return undefined;
};

const resolveBoolean = (model: Dictionary, modelProperty: string) => {
  const match = modelProperty.match(bindingsRegexes.insideCurlyBrackets);
  if (match?.length) {
    if (match.length > 0) {
      const parts = match[1].split("==");
      if (parts.length > 1) {
        const val = model[parts[0]];

        return val?.toString() === parts[1];
      }
    }
  }

  return undefined;
};

const handleFormatters = (model: Dictionary, modelProperty: string, intl?: IntlShape) => {
  const parts = modelProperty.split("|");
  const value = parts[0].indexOf(".") > 0 ? resolvePropertyPath(model, parts[0]) : model[parts[0]];

  if (parts.length > 1) {
    const formatterName = stringUtils.toCamelCase(parts[1]) as BuiltInFormatters;
    const formatter = (builtinFormatters as any)[formatterName];
    if (!formatter) {
      return `Error: Value ${value} not resolved: Unknown formatter: ${formatterName}`;
    }

    return formatter(value, intl);
  }

  return value;
};

const resolveValueDependsOnReverse = (expression: string, value: string) => {
  const match = expression.match(/([^?]+)\s*\?\s*([^:]+)\s*:\s*([^?]+)/); // conditional operator: ex ? v1 : v2
  if (match?.length === 4) {
    const resolveProp = stringUtils.trim(match[1]);
    const value1 = stringUtils.trim(match[2]);
    return { prop: resolveProp, reverseValue: value === value1 };
  }

  return undefined;
};
const resolveExpression = (model: Dictionary, expression: string, intl?: IntlShape) => {
  if (expression.split(" ").indexOf("eq") > 0) {
    // !!! TODO: move expression type to annotations
    const result = evaluateODataLogicalExpression(expression, model);
    return result;
  }

  const parts = expression.split("==");
  if (parts.length === 2) {
    const leftValue = bindingsUtils.resolvePropertyPath(model, parts[0]);
    const rightValue = parts[1];
    // eslint-disable-next-line eqeqeq
    return leftValue == rightValue;
  }

  let value = undefined;
  if ((value = resolveConditionalOperator(model, expression)) !== undefined) {
    return value;
  }

  let valueResolved = true;
  value = expression.replace(bindingsRegexes.allInsideCurlyBrackets, function (match, token) {
    const result = resolveSimpleExpression(model, token, intl);
    if (result === undefined) {
      valueResolved = false;
    }
    return result;
  });

  return valueResolved ? stringUtils.trim(value, ",") || "" : "";
};

const resolvePropertyPath = (
  model: any,
  expression?: string | undefined,
  isSimpleBinding?: boolean
): any => {
  if (!expression) {
    return model;
  }

  if (!bindingsRegexes.specialCharacters.test(expression)) {
    if (expression.indexOf(".") > 0) {
      const parts = expression.split(".");
      let m = model;
      parts.forEach((p) => {
        if (m) {
          m = m[p];
        } else {
          m = null;
        }
      });

      return isSimpleBinding ? !!m : m;
    }

    return model[expression];
  }

  const match = expression.match(bindingsRegexes.insideSquareBruckets);
  if (match && match.index === 0 && bindingsRegexes.onlyDigit.test(match[1])) {
    const arrayIndex = Number.parseInt(match[1], 10);
    let nextPropPath = expression.replace(match[0], "");
    if (nextPropPath.startsWith(".")) {
      nextPropPath = nextPropPath.slice(1);
    }
    return model[arrayIndex] ? resolvePropertyPath(model[arrayIndex], nextPropPath) : undefined;
  }

  return model;
};

const resolveIcon = (icon: IIcon, data: any, theme: ITheme): IIconProps & Omit<IIcon, "type"> => {
  if (!icon.iconName) {
    return {};
  }

  const match = icon.iconName.match(bindingsRegexes.insideCurlyBrackets);
  const iconExpression = match ? match[1] : icon.iconName;
  const parts = iconExpression && iconExpression.split("|");
  const formatter = parts && parts.length > 1 && parts[1].toLowerCase();

  const props = parts[0].split(",");
  let iconStyle: IIconProps & Omit<IIcon, "type"> = { style: {} };
  if (props.length === 1) {
    const iconName = match ? data[parts[0]] : icon.iconName;

    if (formatter && iconName) {
      const isDisabled = formatter === "severity" && data.disabled;
      iconStyle.iconName = isDisabled ? "DRM" : theme.getIcon(iconName, formatter);
      iconStyle.style = {
        color: isDisabled
          ? theme.cayoTheme.brandColors.disabled
          : theme.getColor(iconName, formatter),
      };
    } else if (iconName) {
      iconStyle.iconName = icon.iconName;
    }
  } else if (formatter) {
    const params = {} as any;
    props.forEach((p) => {
      params[p] = data[p];
    });

    const iconFormatter = (buildInIconFormatters as any)[formatter];
    const resolvedIcon = iconFormatter && iconFormatter(theme, { ...params });
    if (resolvedIcon?.iconName) {
      iconStyle.iconName = resolvedIcon.iconName;
      iconStyle.style = { color: resolvedIcon.color };
    } else {
      log.debug("Unable to resolve icon", icon.iconName, "formatter", formatter);
      return {};
    }
  }

  if (icon.rotate) {
    const rotate = resolveBoolean(data, icon.rotate as unknown as string);
    iconStyle.rotate = rotate === true ? "1" : undefined;
  }

  if (icon.roundBorder) {
    iconStyle.style!.borderRadius = "50%";
    iconStyle.style!.padding = 10;
    iconStyle.style!.border = `1px solid ${theme.cayoTheme.brandColors.divider}`;
  }

  iconStyle.style = { ...iconStyle.style, ...themeUtils.getCSSStyle(icon) };

  return iconStyle;
};

const resolveFormatterControl = (model: { [a: string]: Dictionary }, expression: string) => {
  const parts = expression.split("|");

  if (parts[0].indexOf(",") > 0 && parts.length === 2) {
    const props = stringUtils.trim(parts[0], "{").split(",");
    const formatter = stringUtils.trim(parts[1], "}");
    const controlDesc = { [formatter]: {} as any };
    for (const p of props) {
      controlDesc[formatter][p] = model[p];
    }
    return controlDesc;
  }

  return undefined;
};

export const bindingsUtils = {
  resolveExpression,
  resolvePropertyPath,
  resolveIcon,
  resolveFormatterControl,
  resolveSimpleExpression,
  resolveValueDependsOnReverse,
};
