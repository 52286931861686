import React from "react";
import styled from "styled-components";

const SubMenu = ({
  subMenuItems,
  position,
  colors,
  afterClickCB,
}: {
  subMenuItems: any[];
  colors: { [key: string]: string };
  afterClickCB: () => void;
  position: { top: number; left: number };
}) => {
  return (
    <SubMenuWrapper positions={{ top: position.top, left: position.left }} colors={colors}>
      {subMenuItems.map((subItem: any) => (
        <SubMenuItem
          key={subItem.key}
          onMouseDown={(e) => {
            if (!subItem.disabled) {
              subItem?.onClick(e, subItem);
              afterClickCB();
            }
          }}
        >
          {subItem.text}
        </SubMenuItem>
      ))}
    </SubMenuWrapper>
  );
};

const SubMenuWrapper = styled.div<{
  positions: { top: number; left: number };
  colors: { [key: string]: string };
}>`
  position: absolute;
  top: ${({ positions }) => `${positions.top}px`};
  left: ${({ positions }) => `${positions.left - 2}px`};
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  z-index: 1000000;
`;

const SubMenuItem = styled.button`
  outline: transparent;
  position: relative;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  color: rgb(50, 49, 48);
  background-color: transparent;
  border: none;
  width: 100%;
  height: 30px;
  display: block;
  cursor: pointer;
  padding: 0px 8px 0px 8px;
  text-align: left;

  &:hover {
    background-color: rgb(243, 242, 241);
  }

  &:active {
    background-color: rgb(237, 235, 233);
  }
`;

export default SubMenu;
