//

import { IStyle, Separator, Stack, mergeStyles } from "@fluentui/react";
import { LoadingOverlay } from "cayo.ui";
import { OdataQuery } from "odata";
import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import { objectUtils } from "../../utils/object-utils";
import { StretchedContainer } from "../CommonLayout";
import { GridContainer } from "../GridContainer/GridContainer";
import { IActionPromise } from "../LegacyDetailsList/cell-renderers";
import useOdataScheme, { useGridSettingsKey } from "../Schemes/ODataScheme/hook";
import Tree from "../Tree/Tree";
import { commonMessages } from "../common-messages";
import { refreshTreeInterval } from "./constants";

const log = logger.getLogger("Workflow");
interface IWorkflowProps {
  rootUrl: string;
  parentContextId?: string;
  parentComponentId?: string;
}

async function getJobState(url: string): Promise<string> {
  const hrUrl = url.split("/").slice(0, -1).join("/");
  const odq: OdataQuery = { $select: "executionState" };
  const response = await ajax.getClient(hrUrl, "GET", undefined, undefined, odq);
  return response["executionState"];
}

const Workflow: FC<IWorkflowProps & { style?: CSSProperties }> = ({ rootUrl, style }) => {
  const [schemeId, setSchemeId] = useState<string>();
  const [isJobRunning, setIsJobRunning] = useState<boolean | undefined>();
  const unmounted = useRef(false);
  const intl = useIntl();

  useLayoutEffect(function resolveJobStatus() {
    const loadJobState = async () => {
      const jobState = await getJobState(rootUrl);
      setIsJobRunning(jobState === "running");
      log.debug("jobState", jobState);
    };
    loadJobState();
  }, []);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const onItemSelected: IActionPromise = useCallback(
    async (path?: string, query?: string, item?: any) => {
      // setSchemeId(item.url + "/messages");
      const newSchemeId = `${item?.url || item?.data?.objectPath}/messages`;
      // log.debug("WORKFLOW onItemSelected -> item.name, newSchemeId", item?.name, newSchemeId);
      if (newSchemeId) setSchemeId(() => newSchemeId);
    },
    [setSchemeId]
  );

  const { isLoading: schemeLoading, propertyAnnotations, scheme } = useOdataScheme(schemeId);

  const isLoading = schemeLoading || isJobRunning === undefined;

  const patchedScheme = useMemo(() => {
    if (!scheme) {
      return;
    }
    const result = objectUtils.cloneObject(scheme);

    if (result.targetType === "cayo.graph.executionMessage") {
      const sortedColumn = result.detailsList?.columns?.find((c) => c.isSorted);
      if (sortedColumn && result.filterBar && result.detailsList) {
        sortedColumn.isSortedDescending = false;
        result.filterBar.showSavedQueries = false;
        result.detailsList.notificationMode = isJobRunning ? "polling" : "off";
      }
    }
    return result;
  }, [scheme]);

  const gridSettingsKey = useGridSettingsKey(patchedScheme?.detailsList?.id ?? "Workflow");

  const noData = !patchedScheme && !isLoading;

  const workFlowCss = useMemo(() => {
    return mergeStyles({ ...style, position: "relative", height: 200 } as unknown as IStyle);
  }, [style]);

  return (
    <Stack
      horizontal={true}
      verticalFill={true}
      horizontalAlign={"start"}
      verticalAlign={noData ? "start" : "stretch"}
      disableShrink={false}
      grow={true}
      className={workFlowCss}
    >
      {noData && <StyledBlankView>{intl.formatMessage(commonMessages.noData)}</StyledBlankView>}
      {isJobRunning !== undefined && (
        <Tree
          rootUrl={rootUrl}
          onItemSelected={onItemSelected}
          refreshInterval={isJobRunning ? refreshTreeInterval : 0}
        />
      )}
      {patchedScheme && <Separator vertical={true} />}
      <StretchedContainer>
        {isLoading && <LoadingOverlay />}
        {!isLoading && patchedScheme && (
          <GridContainer
            scheme={patchedScheme!}
            propertyAnnotations={propertyAnnotations}
            gridSettingsKey={gridSettingsKey}
          />
        )}
      </StretchedContainer>
    </Stack>
  );
};

const StyledBlankView = styled.div`
  margin-top: 20px;
  position: absolute;
  right: 25%;
  left: 25%;
`;

export default Workflow;
