function between(a: number, b: number, v?: number) {
  if (typeof v !== "number") {
    return false;
  }

  return v >= Math.min(a, b) && v <= Math.max(a, b);
}

const numberUtils = {
  between,
};

export default numberUtils;
