type Subscription<S> = (state: S) => void;

/**
 * Presentation Logic Controller Ploc
 */
export abstract class Ploc<S> {
  private internalState: S;
  private listeners: Subscription<S>[] = [];

  constructor(initalState: S) {
    this.internalState = initalState;
  }

  public get state(): S {
    return this.internalState;
  }

  changeState(state: S) {
    this.internalState = state;

    if (this.listeners.length > 0) {
      this.listeners.forEach((listener) => listener(this.state));
    }
  }

  subscribe(listener: Subscription<S>): () => void {
    this.listeners.push(listener);
    return () => this.unsubscribe(listener);
  }

  unsubscribe(listener: Subscription<S>) {
    const index = this.listeners.indexOf(listener);
    if (index > -1) {
      this.listeners.splice(index, 1);
    }
  }
  dispose() {
    this.listeners.length = 0;
  }

  protected assignState(props: Partial<S>) {
    this.changeState({ ...this.state, ...props });
  }
}
