import { getTheme, memoizeFunction, mergeStyles } from "@fluentui/react";
import React, { useRef, useState } from "react";
import logger from "../../../libs/logger";

import { FCC } from "../../../types/react";
import clipboardUtils from "../../../utils/clipboard-utils";
import CopyButton from "../../Utilities/CopyButton";
import { renderClassNames } from "./common";

type GridCellProps = {
  toCopyValue?: () => string;
  multiline?: boolean;
  renderer?: string;
  noCopy?: boolean;
};

const log = logger.getLogger("GridCell");

const getClassName = memoizeFunction(
  (multiline: boolean, btnVisible: boolean, copyButtonBgColor: string) => {
    return mergeStyles(
      {
        display: "flex",
      },
      btnVisible &&
        !multiline && {
          marginRight: 20,
        },

      !multiline && {
        //overflow: "hidden",
        // textOverflow: "ellipsis",
        selectors: {
          [`.${renderClassNames.toCopyValueContainer}`]: {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
      },
      multiline && {
        selectors: {
          [`.${renderClassNames.toCopyValueContainer}`]: {
            whiteSpace: "normal",
          },
          ".copy-button": {
            backgroundColor: copyButtonBgColor,
          },
        },
      }
    );
  }
);

const GridCell: FCC<GridCellProps> = ({
  toCopyValue,
  multiline = false,
  renderer = "unknown",
  noCopy = false,
  children,
}) => {
  const copyButtonBgColor = getTheme().semanticColors.listItemBackgroundHovered;

  const [copyBtnVisible, setCopyBtnVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const showCopyButton = () => {
    if (noCopy) return;
    setCopyBtnVisible(true);
  };
  const hideCopyButton = () => {
    setCopyBtnVisible(false);
  };

  const copyValue = () => {
    let toCopy = "";
    if (!!toCopyValue) {
      toCopy = toCopyValue();
    } else {
      toCopy = ref.current?.querySelector("[data-to-copy]")?.innerHTML || "";
    }
    //try get from element
    log.debug("copy value\n", toCopy);
    clipboardUtils.copyToClipboard(toCopy);
  };

  return (
    <div
      ref={ref}
      data-grid-cell={renderer}
      className={getClassName(multiline, copyBtnVisible, copyButtonBgColor)}
      onMouseEnter={showCopyButton}
      onMouseLeave={hideCopyButton}
    >
      {children}
      {copyBtnVisible && (
        <CopyButton
          onClick={copyValue}
          isInsideCopyContainer={true}
          isAbsolutePosition={true}
          style={{ top: 2 }}
        />
      )}
    </div>
  );
};

export default GridCell;
