import { IGridItemSizes, ScreenWidth } from "../../api/schema.api";

const minWidthInfo = {
  lg: 12,
  md: 10,
  sm: 6,
  xs: 3,
  xxs: 1,
};

const getDefaultSize = (name: string, size: ScreenWidth): IGridItemSizes => {
  const maxWidth = minWidthInfo[size];
  const ret: IGridItemSizes = {
    minW: Math.min(2, maxWidth),
    maxW: maxWidth,
    x: 0,
    y: Infinity,
    i: name,
    w: Math.min(2, maxWidth),
    h: 2,
  };

  return ret;
};

const dashboardUtils = { getDefaultSize };

export default dashboardUtils;
