import {
  ActionButton,
  Dropdown,
  Icon,
  IDropdownOption as IOfficeDropdownOption,
  Stack,
  Text,
} from "@fluentui/react";
import {
  chartMessages,
  css,
  dateTimeUtils,
  domUtils,
  LineChart,
  LoadingOverlay,
  useTheme,
} from "cayo.ui";
import React, { FC, FormEvent, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { CustomInterval, SavedQuery } from "../../api/cayo-graph";
import { IDropdownOption, IRunnableButton, ITimeSeries } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import { dateRangeConverters } from "../../scheme/converters/date-range-converters";
import useSignals from "../../services/signal-service-base";
import { appSettings } from "../../settings/app-settings";
import { IComponentViewStorage } from "../../settings/view-storage-factory";
import { urlUtils } from "../../utils/url-utils";
import RunnableButton from "../Buttons/RunnableButton";
import { fromSavedQuery } from "../GridContainer/grid-model/savedQuery.portal";
import { UserSettingsStore } from "../GridContainer/repo/UserSettingsStore";
import injectViewStorage from "../Hoc/inject-view-storage";
import Column from "../Layout/Column";
import Spacer from "../Utilities/Spacer";
import { EmptyContainer } from "./common";
import WidgetContainer from "./Container";
import useWidgetSignals from "./dataset-signals.hook";
import { riskDashboardUrl } from "./GaugeWidget";
import WidgetHeader from "./Header";

const log = logger.getLogger("RecentChanges");
interface IRecentChangesSettings {
  selectedPeriod?: {
    [key: string]: number;
  };
}

const TimeSeries: FC<
  ITimeSeries & { viewStorage: IComponentViewStorage<IRecentChangesSettings> }
> = (props) => {
  const {
    lineChart,
    title,
    periodOptions,
    viewStorage,
    stick,
    url,
    name,
    viewAllTitle,
    runButton,
  } = props;
  const [options, setOptions] = useState<IOfficeDropdownOption[]>(
    initOptions(periodOptions as IDropdownOption[], viewStorage.get(), name!)
  );
  const { officeTheme } = useTheme();
  const intl = useIntl();
  const { gridKey, targetType, path } = getViewInfo(name!);

  const history = useHistory();

  const { data, isLoading, error, reloadData, updateTime } = ajax.useGet(url!, {
    ...props,
    days: options.find((o) => o.selected)?.data || 0,
  });

  useWidgetSignals({
    signal: "refresh",
    handler: reloadData,
  });

  const handleChange = (e: FormEvent<HTMLDivElement>, option?: IOfficeDropdownOption) => {
    if (!option) {
      return;
    }

    options.forEach((element) => {
      element.selected = element.key === option.data;
    });

    setOptions([...options]);

    viewStorage.set({ selectedPeriod: { [name!]: option.data } });
  };

  const selectedPeriod = options.find((o) => o.selected)?.data || 0;

  const makeTick = useCallback(
    (period: number, d: any) => {
      if (!d[period]) {
        return "";
      }
      const periodTime = new Date(d[period].periodTime);
      // const periodTime = dateTimeUtils.parseTime(data.items[period].periodTime, false).value;
      return selectedPeriod === 0
        ? intl.formatRelativeTime(period - 60, "minute")
        : dateTimeUtils.periodToReadableString(periodTime!, selectedPeriod);
    },
    [selectedPeriod]
  );

  const gotoInterval = useCallback(
    async (interval: CustomInterval) => {
      log.debug("gotoInterval -> interval", interval);

      if (!targetType) {
        history.push(path);
        return;
      }

      let savedQuery: SavedQuery = {
        commonConditions: {
          queryTimeInterval: interval,
        },
        targetType,
      };
      const query = fromSavedQuery(savedQuery);

      log.debug("gotoInterval -> savedQuery, query", savedQuery, query);
      new UserSettingsStore(gridKey).saveQuery(query);
      history.push(path);
    },
    [targetType]
  );

  const areaSelectedHandler = useCallback(
    (data: { beginDate: number | Date; endDate: number | Date; selectedPeriod: any }) => {
      log.debug("areaSelectedHandler -> data, selectedPeriod, selectedPeriod.fromOptions", data);

      const hcrSettings = appSettings.viewSetting.getSubKey(gridKey) || {};
      delete hcrSettings?.filter;
      log.debug("areaSelectedHandler -> hcrSettings", hcrSettings);
      appSettings.viewSetting.deleteSubKey(gridKey);
      appSettings.viewSetting.updateSubKey(gridKey, hcrSettings);

      let beginDate = new Date(data.beginDate); // withTimezone(data[0]);
      let endDate = new Date(data.endDate);
      const selectedPeriod = data.selectedPeriod;

      if (beginDate.getTime() === endDate.getTime()) {
        if (selectedPeriod === 0) {
          //last hour
          //beginDate = new Date(beginDate.getTime() - 1 * 60 * 1000 );// -1m
          endDate = new Date(endDate.getTime() + 1 * 60 * 1000); // +1m
        } else if (selectedPeriod === 1) {
          //24 hours
          endDate = new Date(endDate.getTime() + 60 * 60 * 1000); // + 1h
        } else {
          //7 ... 180 days
          endDate = new Date(endDate.getTime() + 24 * 60 * 60 * 1000); // +1d
        }
      }

      if (selectedPeriod > 1) {
        beginDate.setTime(beginDate.getTime() + beginDate.getTimezoneOffset() * 60 * 1000);
        endDate.setTime(endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000);
      }

      const interval = dateRangeConverters.fromRange(beginDate, endDate);
      log.debug("interval, beginDate, endDate", interval, beginDate, endDate);
      gotoInterval(interval!);
    },
    []
  );

  const signals = useSignals<"areaSelected">([{ s: "areaSelected", handler: areaSelectedHandler }]);

  const location = useLocation();

  const showRunButton = useMemo(() => {
    return urlUtils.trim(location.pathname).toLowerCase() !== riskDashboardUrl.toLowerCase();
  }, [location.pathname]);

  return (
    <WidgetContainer stick={stick}>
      <WidgetHeader title={title} onRefresh={reloadData} updateTime={updateTime} />

      {isLoading && <LoadingOverlay />}
      {data?.length && (
        <LineChart
          {...lineChart}
          tickFormatter={makeTick}
          days={options.find((o) => o.selected)?.data || 0}
          data={data}
          onAreaSelected={(data) => signals.send("areaSelected", data)}
        />
      )}
      {!isLoading && !error && !data?.length && props.noItemsMessage}
      {error && (
        <Column style={{ flexGrow: 1, height: "100%" }}>
          <EmptyContainer style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
            <Text style={{ fontWeight: "bold" }}>
              {intl.formatMessage(chartMessages.loadDataError)}
            </Text>
            <Text variant="small">{error}</Text>
          </EmptyContainer>
        </Column>
      )}
      <Stack
        verticalAlign={"center"}
        horizontalAlign={"stretch"}
        horizontal={true}
        className={css.mt12}
        onMouseDown={domUtils.stopMouseDown}
      >
        <Dropdown
          options={options}
          selectedKey={selectedPeriod}
          onChange={handleChange}
          styles={{
            dropdown: { minWidth: "140px" },
            label: { paddingRight: 4 },
            root: {
              alignSelf: "flex-end",
              display: "flex",

              right: "20px",
              top: 0,
            },
          }}
        />
        <Spacer />
        {runButton && showRunButton && (
          <RunnableButton
            {...(runButton as IRunnableButton)}
            onRefresh={reloadData}
            style={{ marginLeft: 8 }}
          />
        )}

        <ActionButton
          id="ViewAllChanges"
          text={""}
          style={{ color: officeTheme.semanticColors.link, height: "auto" }}
          onClick={() => {
            const selectedPeriod = options.find((o) => o.selected);
            const interval = dateRangeConverters.rangeToType(selectedPeriod?.data);
            log.debug("ViewAllChanges.click -> selectedPeriod, interval", selectedPeriod, interval);

            gotoInterval(interval!);
          }}
        >
          {viewAllTitle ?? (
            <FormattedMessage id="recent-changes-chart.view-all" defaultMessage="View all" />
          )}
          <Icon
            iconName={"Forward"}
            style={{
              fontSize: 10,
              fontWeight: "bold",
              paddingLeft: 4,
              paddingTop: 2,
            }}
          />
        </ActionButton>
      </Stack>
    </WidgetContainer>
  );
};

const initOptions = (
  periodOptions: IDropdownOption[],
  settings: IRecentChangesSettings,
  name: string
) => {
  const newOptions = periodOptions.map<IOfficeDropdownOption>((o) => ({
    data: o.value,
    key: o.value,
    selected: o.selected,
    text: o.text || "",
    title: o.text,
  }));

  if (
    settings &&
    settings.selectedPeriod !== undefined &&
    Boolean(Object.keys(settings.selectedPeriod).length)
  ) {
    newOptions.forEach((element) => {
      element.selected = element.key === settings.selectedPeriod?.[name];
    });
  } else {
    if (!newOptions.find((o) => o.selected)) {
      newOptions[0].selected = true;
    }
  }

  return newOptions;
};

const getViewInfo = (name: string) => {
  let gridKey = "";
  let targetType = "";
  let path = "";

  if (name === "threatsByLevel") {
    path = "/riskAssessment/rules";
  } else if (name === "threatAlertsByLevel") {
    gridKey = "riskAssessment/alertsctx_grid_grid";
    targetType = "cayo.graph.riskAlert";
    path = "/riskAssessment/alerts";
  } else {
    gridKey = "changeAudit/changehistoryctx_grid_grid";
    targetType = "cayo.graph.historyRecord";
    path = "/changeAudit/changehistory";
  }

  return { gridKey, targetType, path };
};

export default injectViewStorage(TimeSeries);
