import { urlUtils } from "../utils/url-utils";
import SettingsStore from "./settings-store";

export interface IComponentViewStorage<T> {
  get(): T;
  set(v: T): void;
  reset(): void;
}

export class ViewStorageFactory {
  get<T>(controlId: string): ComponentViewStorage<T> {
    return new ComponentViewStorage<T>(controlId);
  }
}

class ComponentViewStorage<T> implements IComponentViewStorage<T> {
  private settings = new SettingsStore("view", localStorage);

  private get path() {
    return urlUtils.trim(window.location.hash) || "home";
  }

  constructor(readonly controlId: string) {}

  get(): T {
    const sub = this.settings.getSubKey(this.path) as any;
    const value = sub && (sub[this.controlId] as T);
    return value;
  }

  set(v: T): void {
    let newValue = this.settings.getSubKey(this.path) as any;
    if (!newValue) {
      newValue = {};
    }
    newValue[this.controlId] = v;
    this.settings.updateSubKey(this.path, newValue);
  }

  reset(): Promise<void> {
    throw new Error("Method not implemented.");
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ViewStorageFactory();
