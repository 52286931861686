import { Reducer } from "react";
import logger from "../../../libs/logger";
import { IDatabaseConfigurationWizardState } from "./IDatabaseConfigurationWizardState";

type WizardAction =
  | "setStep"
  | "setDbType"
  | "setDbParams"
  | "setValidateDbParamsError"
  | "setConnectDbError";

interface IUpdateWizardAction {
  action: WizardAction;
  newState: IDatabaseConfigurationWizardState;
}

const log = logger.getLogger("DatabaseConfigurationWizard.reducer");

export const wizardStateReducer: Reducer<IDatabaseConfigurationWizardState, IUpdateWizardAction> = (
  state: IDatabaseConfigurationWizardState,
  updateAction?: IUpdateWizardAction
) => {
  if (!updateAction || !updateAction.newState) {
    return state;
  }
  log.debug("dispatch -> state, updateAction", state, updateAction);

  const newState: IDatabaseConfigurationWizardState = { ...state };

  switch (updateAction.action) {
    case "setStep":
      newState.currentStep = updateAction.newState.currentStep;

      break;
    case "setDbType":
      newState.dbType = updateAction.newState.dbType;
      newState.validateDbParamsError = undefined;

      break;

    case "setValidateDbParamsError":
      newState.validateDbParamsError = updateAction.newState.validateDbParamsError;
      break;

    case "setDbParams":
      newState.dbParams = updateAction.newState.dbParams;
      newState.onValidateParams = updateAction.newState.onValidateParams;
      newState.onSaveParams = updateAction.newState.onSaveParams;
      newState.isDbParamsInputValid = updateAction.newState.isDbParamsInputValid;

      break;
  }
  return newState;
};
