import { Stack } from "@fluentui/react";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import ConfigurationTasks, { ConfigurationTask } from "./ConfigurationTasks";
import { configInfo, configurationActions } from "./InitialConfigurationController";
import messages from "./messages";

type StartPageProps = { actions: configurationActions; configInfo: configInfo };
const buttonWidth = 120;

const StartPage: FC<StartPageProps> = ({ actions, configInfo }) => {
  const intl = useIntl();

  const configTasks = useMemo(() => {
    const isDatabaseDisabled = !configInfo.hasLicense;
    const isTenantOrDomainDisabled = isDatabaseDisabled || !configInfo.databaseConfigured;

    const tasks: ConfigurationTask[] = [
      {
        onClick: actions.onActivate,
        icon: "VerifiedBrandSolid",
        text: intl.formatMessage(messages.activateProduct),
        completed: configInfo.hasLicense,
        helpLink: "https://support.cayosoft.com/hc/en-us/articles/360042148531",
        disableIfConfigured: true,
      },
      {
        onClick: actions.onConfigureDatabase,
        icon: "DataManagementSettings",
        text: intl.formatMessage(messages.configureSQL),
        completed: configInfo.databaseConfigured,
        helpLink: "https://support.cayosoft.com/hc/en-us/articles/360047309491",
        disabled: isDatabaseDisabled,
        disableIfConfigured: true,
      },
      {
        onClick: actions.onAddTenant,
        icon: "AADLogo",
        text: intl.formatMessage(messages.addTenant),
        completed: configInfo.hasTenants,
        helpLink: "https://support.cayosoft.com/hc/en-us/articles/360042149331",
        disabled: isTenantOrDomainDisabled,
      },
      {
        onClick: actions.onAddDomain,
        icon: "Org",
        text: intl.formatMessage(messages.addDomain),
        completed: configInfo.hasDomains,
        helpLink: "https://support.cayosoft.com/hc/en-us/articles/360041719372",
        disabled: isTenantOrDomainDisabled,
      },
    ];

    return tasks;
  }, [actions]);

  return (
    <Stack
      verticalAlign={"center"}
      horizontalAlign="center"
      verticalFill={true}
      grow={true}
      style={{
        flex: "1 0 auto",
        padding: "0 24px 0px 24px",
        overflowY: "hidden",
        minHeight: "250px",
      }}
    >
      <ConfigurationTasks buttonWidth={buttonWidth} items={configTasks} />
    </Stack>
  );
};

export default StartPage;
