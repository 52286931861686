import { IComboBoxOption } from "@fluentui/react";
import logger from "../../libs/logger";
import { Ploc } from "./grid-model/ploc";
import { QueryColumnsRegex, columnsToHide } from "../../utils/grid-utils";

export interface IChooseColumnsDialogStateItem {
  key: string;
  value: IComboBoxOption[];
}
export interface IChooseColumnsDialogState {
  items: Array<IChooseColumnsDialogStateItem>;
  canAdd: boolean;
  canSave: boolean;
}

export type ColumnData = { fieldName: string; displayName: string };

export class ChooseColumnsDialogController extends Ploc<IChooseColumnsDialogState> {
  private idx = 1;
  private _allOptions: IComboBoxOption[];
  private _selectedColumns: { key: string; value: string }[];

  private readonly _logger = logger.getLogger("QUICK-FILTER-NEW ChooseColumnsDialogController");
  private log(...msg: any[]) {
    this._logger.log(...msg);
  }

  constructor(
    initialState: IChooseColumnsDialogState,
    private readonly allColumns: ColumnData[],
    private readonly userColumns: ColumnData[]
  ) {
    super(initialState);

    this._allOptions = allColumns.map((c) => ({
      key: c.fieldName,
      text: `${c.displayName} (${c.fieldName})`,
    }));
    this._allOptions.sort((a, b) => a.text.toString().localeCompare(b.text.toString()));

    this._selectedColumns = this.userColumns.map((c) => {
      if (QueryColumnsRegex.test(c.fieldName)) {
        this._allOptions.push({
          key: c.fieldName,
          text: c.displayName,
        });

        this._allOptions = this._allOptions.filter((c) => !columnsToHide.includes(c.key as string));
      }

      return {
        key: this.createKey(),
        value: c.fieldName,
      };
    });

    this.makeStateItems();
  }

  public addNew() {
    this._selectedColumns.push({ key: this.createKey(), value: "" });
    this.makeStateItems();
  }

  public remove(key: string) {
    this._selectedColumns = this._selectedColumns.filter((item) => item.key !== key);
    this.makeStateItems();
  }

  public reorder(keys: string[]) {
    this._selectedColumns.sort((a, b) => keys.indexOf(a.key) - keys.indexOf(b.key));
    this.makeStateItems();
  }

  public change(key: string, newValue: string) {
    const item = this._selectedColumns.find((v) => v.key === key);
    if (!!item) {
      item.value = newValue;
      this.log("change -> key, value, _selectedColumns", key, newValue, this._selectedColumns);
      this.makeStateItems();
    }
  }

  public get canRemove(): boolean {
    return this._selectedColumns.length === 1;
  }

  public get canAdd(): boolean {
    if (this._selectedColumns.some((c) => !c.value)) {
      return false;
    }
    return this._selectedColumns.length < this.allColumns.length;
  }

  public get canSave(): boolean {
    return !this._selectedColumns.some((c) => !c.value);
  }
  public get selectedColumns(): string[] {
    return this._selectedColumns.map((c) => c.value);
  }

  private makeStateItems() {
    const items: IChooseColumnsDialogStateItem[] = this._selectedColumns.map((uc) => ({
      key: uc.key,
      value: this.makeColumnOptions(uc.value),
    }));

    this.assignState({ items, canAdd: this.canAdd, canSave: this.canSave });
  }

  private makeColumnOptions(key: string): IComboBoxOption[] {
    const result = this._allOptions
      .filter((oi) => !this._selectedColumns.some((v) => v.value === oi.key && v.value !== key)) //exclude selected columns
      .map((item) =>
        item.key === key ? { ...item, selected: true } : { ...item, selected: false }
      );
    return result;
  }

  private createKey() {
    return `col${this.idx++}`;
  }
}
