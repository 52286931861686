import React, { FC, useState, useEffect, useRef, CSSProperties } from "react";
import styled from "styled-components";

type DataType = {
  primary: any[];
  overflow: any[];
} | null;

interface DynamicResizeGroupProps {
  style?: CSSProperties;
  role?: string;
  ariaLabel?: string;
  data: DataType;
  itemHeight?: number;
  onRenderData: (data: DataType) => JSX.Element;
}

export const DynamicResizeGroup: FC<DynamicResizeGroupProps> = ({
  style,
  role,
  ariaLabel,
  data,
  itemHeight = 50,
  onRenderData,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);

  const calculateData = () => {
    if (!data) return null;
    const newData = { ...data };
    const maxItems = Math.floor(containerHeight / itemHeight);
    if (newData.primary.length > maxItems) {
      newData.overflow = newData.primary.slice(maxItems);
      newData.primary = newData.primary.slice(0, maxItems);
    }
    return newData;
  };

  useEffect(() => {
    const updateContainerHeight = () => {
      const container = containerRef.current;
      if (container) {
        setContainerHeight(container.clientHeight);
      }
    };

    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);
    return () => window.removeEventListener("resize", updateContainerHeight);
  }, []);

  useEffect(() => {
    const calculatedData = calculateData();
    if (calculatedData) {
      onRenderData(calculatedData);
    }
  }, [containerHeight, data]);

  const renderedData = calculateData();

  return (
    <Container
      id="resize-group-container"
      style={style}
      role={role}
      aria-label={ariaLabel}
      ref={containerRef}
    >
      {onRenderData(renderedData)}
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
`;
