import * as signalR from "@microsoft/signalr";
import { ServiceFactory } from ".";
import { Entity } from "../api/cayo-graph";
import { IInteractiveItem } from "../components/Alerts/alerts.api";
import { appEvents } from "../components/App/app-events";
import logger, { ILogger } from "../libs/logger";
import { appSettings } from "../settings/app-settings";
import { objectUtils } from "../utils/object-utils";
import { endpoints } from "./endpoints.service";

export const ALERTS_PANE_MAX_ITEMS = 100;
export interface INotificationMessage {
  action: NotificationAction;
  body: string;
  source: string;
}

const log = logger.getLogger("notification.api module");

export enum NotificationAction {
  Add,
  Remove,
  Modify,
  Notify,
  Replace,
  RemoveAll,
}

const reconnectTimeout = 2000;

interface INotificationSubscription {
  handler: (messages: INotificationMessage[]) => void;
  source: string;
}

class NotificationService {
  private log: ILogger;
  constructor() {
    this.log = logger.getLogger("NotificationService");
    this.log.debug("NotificationService ctor");
  }

  private notificationsConnection: signalR.HubConnection | null = null;
  private subscriptions: INotificationSubscription[] = [];

  private get isServiceDisabled() {
    return localStorage.getItem("notifications") === "off";
  }

  public start = () => {
    this.log.debug("start");

    if (this.isServiceDisabled) {
      this.log.debug("exit: service disabled");
      return;
    }

    if (!appSettings || !appSettings.accessToken) {
      this.log.debug("no access token found, start reconnect");
      setTimeout(() => this.start(), reconnectTimeout);
      return;
    }

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${endpoints.publicUrl}/hubs/notifications`, {
        accessTokenFactory: () => (appSettings && appSettings.accessToken) || "",
      })
      .configureLogging(signalR.LogLevel.Critical)
      .build();

    connection.on("OnMessages", this.onNotification);

    async function startConnection() {
      let connectionEstablished = false;
      try {
        const serviceStatus = await ServiceFactory.HealthCheckService.getReadyStatus();
        const apiState = serviceStatus?.results?.service?.data?.apiState;
        const newState = apiState?.state;
        if (newState !== "started") {
          appEvents.trigger({
            serverError: apiState,
          });
          throw new Error("server is not ready");
        }

        if (appSettings?.accessToken) {
          await connection.start();
          connectionEstablished = true;
        }

        appEvents.trigger({ webSocketConnectionLost: false });
      } catch (err: any) {
        // if (err?.statusCode === 401 || err?.errorType) {
        //   log.debug("reload page", err);
        //   document.location.reload();
        //   return;
        // }

        if (err.status === 404) {
          appEvents.trigger({ serverError: { message: "", state: "stopped" } });
        }
      } finally {
        if (!connectionEstablished) {
          setTimeout(() => startConnection(), reconnectTimeout);
        }
      }
    }

    connection.onclose(async () => {
      appEvents.trigger({ webSocketConnectionLost: true });
      if (this.notificationsConnection) {
        await startConnection();
      }
    });

    this.notificationsConnection = connection;

    return connection
      .start()
      .then(() => {
        this.log.log("connection established with success, hub: " + endpoints.publicUrl);
      })
      .catch(() => {
        setTimeout(() => startConnection(), reconnectTimeout);
      });
  };

  public stop = () => {
    if (this.notificationsConnection) {
      const oldConnection = this.notificationsConnection;
      this.notificationsConnection = null;
      oldConnection.stop();
    }
  };

  public subscribe(source: string, handler: (messages: INotificationMessage[]) => void) {
    this.subscriptions.push({ source: source.toLowerCase(), handler });

    return () => {
      this.unsubscribe(handler);
    };
  }

  private unsubscribe = (handler: (messages: INotificationMessage[]) => void) => {
    const existingSubscriptionIndex = this.subscriptions.findIndex((s) => s.handler === handler);

    if (existingSubscriptionIndex >= 0) {
      this.subscriptions.splice(existingSubscriptionIndex, 1);
    }
  };

  private onNotification = (body: any) => {
    this.log.debug("onNotification -> body", body);
    const messages = body as INotificationMessage[];
    if (messages.length > 0 && this.subscriptions.length > 0) {
      const messageGroup = objectUtils.groupBy(messages, (m) => m.source.toLowerCase());
      this.log.debug("onNotification -> messageGroup ", messageGroup);
      for (const group of messageGroup) {
        const subscribers = this.subscriptions.filter(
          (s) => s.source.toLowerCase() === group[0].toLowerCase()
        );
        this.log.debug("onNotification subscribers.length -> ", subscribers.length);
        if (subscribers.length > 0) {
          const newMessages = group[1];

          for (const subscriber of subscribers) {
            subscriber.handler(newMessages);
          }
        }
      }
    }
  };
}

export const mergeInteractiveItems = (
  oldItems: IInteractiveItem[],
  newItems: IInteractiveItem[],
  insertNewItems?: boolean | undefined,
  removeConditionHandler?: ((item: IInteractiveItem) => boolean) | undefined
) => {
  for (const newItem of newItems as any[]) {
    const existingItem = (oldItems as any[]).find((j) => j.id === newItem.id);
    if (existingItem) {
      if (removeConditionHandler && removeConditionHandler(existingItem)) {
        const deletingIndex = oldItems.indexOf(existingItem);
        oldItems.splice(deletingIndex, 1);
        continue;
      }

      for (const prop of Object.getOwnPropertyNames(newItem)) {
        if (newItem[prop] && newItem[prop] !== undefined && newItem[prop] !== null) {
          existingItem[prop] = newItem[prop];
        }
      }
    } else if (insertNewItems) {
      oldItems.unshift(newItem);
    }
  }
};

const truncateItemsList = (
  items: IInteractiveItem[],
  maxItems?: number | undefined
): IInteractiveItem[] => {
  log.debug("truncateItemsList");
  return items.slice(0, maxItems ?? ALERTS_PANE_MAX_ITEMS);
};

const modifyInteractiveItems = (
  oldItems: IInteractiveItem[],
  newItems: IInteractiveItem[],
  maxItems?: number | undefined,
  noSort?: boolean,
  addNewToTail?: boolean
) => {
  log.debug("modifyInteractiveItems");
  if (newItems.length === 0) {
    return [];
  }

  const now = new Date();

  for (const newItem of newItems as any[]) {
    const existingItem = (oldItems as any[]).find((j) => j.id === newItem.id);
    if (existingItem) {
      for (const prop of Object.getOwnPropertyNames(newItem)) {
        if (newItem[prop] !== undefined && newItem[prop] !== null) {
          existingItem[prop] = newItem[prop];
        }
      }
    } else {
      if (addNewToTail) {
        oldItems.push(newItem);
        logger.debug("add alert to tail", newItem);
      } else {
        logger.debug("add alert to head", newItem);
        oldItems.unshift(newItem);
      }
    }
  }
  if (!noSort && oldItems.length) {
    const isRisk = (oldItems[0] as Entity).objectType === "cayo.graph.riskAlert";

    if (isRisk) {
      oldItems = oldItems.sort((a, b) => ((b as any)?.severity || 0) - ((a as any)?.severity || 0));
    } else {
      oldItems = oldItems.sort(
        (a, b) => (b.modificationTime || now).getTime() - (a.modificationTime || now).getTime()
      );
    }
  }

  return truncateItemsList(oldItems, maxItems);
};

const deleteInteractiveItems = (
  oldItems: IInteractiveItem[],
  toDeleteItems: IInteractiveItem[]
) => {
  log.debug("deleteInteractiveItems");
  return oldItems.filter((i) => toDeleteItems.findIndex((ii) => ii.id === i.id) === -1);
};

export interface IUpdateInteractiveItemAction {
  action?: NotificationAction | undefined;
  maxItems?: number | undefined;
  newItems?: IInteractiveItem[] | null;
  noSort?: boolean;
  addNewToTail?: boolean;
}

export const updateInteractiveItemsReducer = (
  oldItems: IInteractiveItem[],
  action: IUpdateInteractiveItemAction
): IInteractiveItem[] => {
  if (
    action.action !== NotificationAction.Replace &&
    (!action.newItems || action.newItems.length === 0) &&
    action.action
  ) {
    return oldItems;
  }

  switch (action.action) {
    case NotificationAction.Add:
    case NotificationAction.Modify:
      if (action.newItems) {
        return modifyInteractiveItems(
          oldItems,
          action.newItems ?? [],
          action.maxItems,
          action.noSort,
          action.addNewToTail
        );
      }

      break;

    case NotificationAction.Remove:
      if (action.newItems) {
        return deleteInteractiveItems(oldItems, action.newItems);
      }
      break;

    case NotificationAction.RemoveAll:
      return [];

    case NotificationAction.Replace:
    default:
      return truncateItemsList(action.newItems ?? [], action.maxItems);
  }

  return oldItems;
};

const notificationService = new NotificationService();

export type INotificationService = typeof notificationService;

export default notificationService;
