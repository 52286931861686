import { IFormModel } from ".";
import { Entity } from "../../../api/cayo-graph";

export function getODataTypes(data: Entity, model: IFormModel) {
  const result = {};

  for (const key in model) {
    if (Object.prototype.hasOwnProperty.call(model, key)) {
      const odataKey = "@odata.type";

      if (data[`${key}${odataKey}`]) {
        result[`${key}${odataKey}`] = data[`${key}${odataKey}`];
      }
    }
  }

  return result;
}
