import { Separator, Stack, Text } from "@fluentui/react";
import { useTheme } from "cayo.ui";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import ConfigurationTaskIcon from "./ConfigurationTaskIcon";
import ConfigureButton from "./ConfigureButton";
import messages from "./messages";

export type ConfigurationTask = {
  icon: string;
  text: string;
  helpLink: string;
  onClick: () => void;
  completed?: boolean;
  disableIfConfigured?: boolean;
  disabled?: boolean;
};

type ConfigurationTasksProps = { buttonWidth: number; items: ConfigurationTask[] };

const ConfigurationTasks: FC<ConfigurationTasksProps> = ({ items, buttonWidth }) => {
  const { cayoTheme } = useTheme();
  const intl = useIntl();

  return (
    <Stack
      horizontal={true}
      horizontalAlign="space-between"
      style={{ width: "100%", position: "relative" }}
    >
      {items.map((item, i) => {
        return (
          <Stack key={i} horizontal={false} horizontalAlign="center">
            <ConfigureButton
              {...item}
              disabled={item.disabled || (item.completed && item.disableIfConfigured)}
              width={buttonWidth}
              style={{ marginBottom: 20 }}
            />

            <ConfigurationTaskIcon
              completed={item.completed}
              disabled={item.disabled}
              style={{
                backgroundColor: cayoTheme.brandColors.primaryBackground,
                zIndex: 1,
                padding: "0 4px",
              }}
            />
            <Text variant="small">{intl.formatMessage(messages.stepCount, { index: i + 1 })}</Text>
          </Stack>
        );
      })}
      <div
        style={{
          position: "absolute",
          bottom: 16,
          right: buttonWidth / 2,
          left: buttonWidth / 2,
        }}
      >
        <Separator
          vertical={false}
          alignContent="center"
          styles={{
            root: {
              selectors: {
                ":before": {
                  backgroundColor: cayoTheme.wizard.colors.active,
                },
              },

              padding: 0,
            },
          }}
        />
      </div>
    </Stack>
  );
};

export default ConfigurationTasks;
