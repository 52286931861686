import { FixedList, logger, useTheme } from "cayo.ui";
import React, { FC, Fragment, useMemo } from "react";
import { useIntl } from "react-intl";
import { IFixedList } from "../../../api/schema.api";

import { IColumn } from "@fluentui/react";
import { IAdditionalFormFieldProps } from "../../Form/types";
import useTypeAnnotations from "../../GlobalHooks/type-annotations.hook";
import { getSortedGroups } from "../../GridContainer/converters";
import renderGridCell from "../../GridContainer/renderers/gridCellRenderer";
import { renderRow } from "../../GridContainer/renderers/renderRow";
import useODataFixedList from "./useODataFixedList";

export type FixedListProps = IFixedList & { addProps: IAdditionalFormFieldProps };

export type ListItemClicked = (item: any, index: number, column: IColumn) => Promise<void>;

const ODataFixedList: FC<FixedListProps> = (props) => {
  const { name, label, tooltip, hideWhenEmpty, layoutMode, columns, addProps, groupBy } = props;

  const intl = useIntl();
  const typeAnnotations = useTypeAnnotations();
  const theme = useTheme();
  const { cayoTheme } = theme;

  const { items, commands, missingDataText, onItemClicked } = useODataFixedList(props);

  const auxProps = useMemo(() => {
    return {
      intl,
      theme,
      getTypeAnnotation: typeAnnotations.getTypeAnnotation,
      getIconAnnotation: typeAnnotations.getIconAnnotation,
      propertyAnnotations: addProps?.propertyAnnotations,
    };
  }, []);

  const sortedGroups = useMemo(() => {
    if (items.length && !!groupBy) {
      const groupsAndItems = getSortedGroups(items, groupBy, undefined);
      return { groups: groupsAndItems.newGroups, items: groupsAndItems.newResponse };
    }
    return { groups: undefined, items: items };
  }, [items, groupBy]);

  if (hideWhenEmpty && !items?.length) {
    return <Fragment />;
  }
  logger.debug("FixedList", items);

  return (
    <FixedList
      name={name!}
      label={label}
      value={sortedGroups.items}
      groups={sortedGroups.groups}
      setValue={(v) => {
        logger.debug(v);
      }}
      columns={columns as any}
      commands={commands}
      layoutMode={layoutMode}
      tooltip={tooltip}
      noItemsText={missingDataText}
      selectionMode={props.selectionMode}
      showAdjustColumnsButton={true}
      onRenderRow={(p, r) => renderRow(p!, r!, cayoTheme)}
      onRenderItemColumn={(item, index, column) =>
        renderGridCell(
          item,
          index!,
          column,
          (_, __, item) => onItemClicked(item, index!, column!),
          auxProps
        )
      }
    />
  );
};

export default ODataFixedList;
