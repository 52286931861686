import { MessageBarType, PrimaryButton, Stack } from "@fluentui/react";
import { Html, MessageBar, useTheme } from "cayo.ui";
import React, { FC, Fragment, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { IMessageCarousel } from "../../../api/schema.api";
import clipboardUtils from "../../../utils/clipboard-utils";
import { animation, animationEffects } from "../../Common/Effects";
import ChevronButton, { useChevron } from "../../Utilities/ChevronButton";
import CopyButton from "../../Utilities/CopyButton";
import { getMessageBarStyles } from "../Alerts.styles";
import useMessages from "./hooks";
import severityToMessageBarType from "./logic";
import { MessageCarouselContainer, StyledDiv, extendEffectContainer } from "./styles";

const MessageCarousel: FC<Omit<IMessageCarousel, "type">> = ({ url }) => {
  const { currentMessage, currentMessageIndex, totalMessages, showNextMessage } = useMessages(url);
  const [animationEffect] = useState<animation | undefined>("slideInUp");
  const theme = useTheme();

  const textAreaRef = useRef(null);
  const minTextAreaRef = useRef(null);
  const { textAreaStyle, onChevronClick, isExpanded, isChevronVisible } = useChevron(
    textAreaRef,
    minTextAreaRef
  );

  const styles = useMemo(() => {
    const s = getMessageBarStyles(false, MessageBarType.error, theme);
    const root = s?.root as React.CSSProperties;
    s.innerText = { width: "100%" };
    root.padding = 0;
    return s;
  }, [currentMessage?.severity]);

  if (!currentMessage) {
    return <Fragment />;
  }

  const messageBarType =
    currentMessage.severity === "informational"
      ? "severeInfo"
      : severityToMessageBarType(currentMessage.severity);

  const EffectContainer = extendEffectContainer(
    animationEffects.getEffectContainer(animationEffect),
    textAreaStyle
  );

  return (
    <MessageCarouselContainer verticalAlign={"center"}>
      <MessageBar isMultiline={isExpanded} styles={styles} messageBarType={messageBarType}>
        <Stack horizontal={true} horizontalAlign={"stretch"} wrap={false} verticalAlign={"center"}>
          <EffectContainer key={currentMessageIndex} ref={textAreaRef}>
            {currentMessage?.message && <Html value={currentMessage.message} />}
          </EffectContainer>
          <StyledDiv ref={minTextAreaRef}>&nbsp;</StyledDiv>
          <ChevronButton
            isChevronVisible={isChevronVisible}
            isExpanded={isExpanded}
            onChevronClick={onChevronClick}
          />
          <CopyButton
            onClick={() =>
              currentMessage?.message && clipboardUtils.copyToClipboard(currentMessage.message)
            }
          />
          {totalMessages > 1 ? (
            <Stack verticalAlign={"center"}>
              <PrimaryButton onClick={showNextMessage} styles={{ root: { whiteSpace: "nowrap" } }}>
                <FormattedMessage id="message-carousel.next" defaultMessage="Next" />
                {` [${currentMessageIndex + 1} / ${totalMessages}]`}
              </PrimaryButton>
            </Stack>
          ) : undefined}
        </Stack>
      </MessageBar>
    </MessageCarouselContainer>
  );
};

export default MessageCarousel;
