import { Icon } from "@fluentui/react";
import React from "react";
import { getFormatterFromRenderOptions } from "../../../utils/formatter-utils";
import ErrorTooltip from "../../Common/ErrorTooltip";
import GridCell from "./GridCell";
import { formatValue, renderClassNames } from "./common";

import { Html } from "cayo.ui";
import { RenderCellFn } from "./types";

export const renderFormattedCell: RenderCellFn = ({ item, column, auxProps }) => {
  const value = item[column?.fieldName ?? ""];
  const renderOptions = { itemFormatValue: item.formatValue, ...column?.renderOptions };
  const formatter = getFormatterFromRenderOptions(renderOptions);
  let formattedValue = formatValue(item, column?.fieldName ?? "", renderOptions, auxProps);

  if (formatter === "html") {
    formattedValue = <Html value={formattedValue} />;
  }

  const resultStr = formattedValue || value.toString();

  const showTooltip = column.fieldName === "message" && item?.errors?.length > 0;

  const valueControl = [
    <span key="val" className={renderClassNames.toCopyValueContainer} data-to-copy>
      {resultStr}
    </span>,
  ];
  if (showTooltip) {
    valueControl.push(
      <Icon
        key="icon"
        iconName="Info"
        styles={{
          root: {
            fontSize: 10,
            paddingRight: 6,
            paddingTop: 4,
            paddingLeft: 4,
            fontWeight: "bold",
          },
        }}
      />
    );
  }
  return !showTooltip ? (
    <GridCell renderer="formatted-cell" multiline={column.renderOptions?.multilineString}>
      {valueControl}
    </GridCell>
  ) : (
    <ErrorTooltip id={item.id} errors={item.errors}>
      <GridCell renderer="formatted-cell" multiline={column.renderOptions?.multilineString}>
        {valueControl}
      </GridCell>
    </ErrorTooltip>
  );
};
