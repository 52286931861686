import { useEffect } from "react";

export const useUserInput = (handler: (event: Event) => void) => {
  useEffect(() => {
    const unsubscribe = () => {
      document.removeEventListener("pointerdown", handleUserInput);
      document.removeEventListener("keydown", handleUserInput, true);
    };
    const handleUserInput = (event: any) => {
      unsubscribe();
      handler(event);
    };

    document.addEventListener("pointerdown", handleUserInput);
    document.addEventListener("keydown", handleUserInput, true);

    return unsubscribe;
  }, [handler]);
};
