export const objectTypeToTitle = (v: string) => {
  switch (v) {
    case "microsoft.ad.user":
      return "AD User";
    case "microsoft.graph.user":
      return "Office 365 User";

    case "microsoft.graph.group":
      return "Office 365 Group";

    case "microsoft.ad.group":
      return "AD Group";

    case "microsoft.graph.directoryRole":
      return "Office 365 Role";

    case "microsoft.ad.computer":
      return "AD Computer";

    default:
      break;
  }

  return v;
};
