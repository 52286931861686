import React from "react";
import useService from "../../services/services.hook";

const getComponentName = (n: string) => {
  const parts = n.split("(");
  return parts[parts.length - 1].split(")")[0];
};

const injectViewStorage = (Component: any) => (props: any) => {
  const controlId = props?.id || getComponentName(Component.displayName || Component.name);
  const viewStorage = useService("viewStorageFactory").get(controlId);

  return <Component {...props} viewStorage={viewStorage} />;
};

export default injectViewStorage;
