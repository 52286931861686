import { Icon } from "@fluentui/react";
import { useTheme } from "cayo.ui";
import React, { CSSProperties, FC, useMemo } from "react";

type ConfigurationTaskIconProps = {
  completed?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
};

const ConfigurationTaskIcon: FC<ConfigurationTaskIconProps> = ({ completed, disabled, style }) => {
  const { cayoTheme } = useTheme();
  const iconScheme: { color: string; icon: string } = useMemo(() => {
    if (disabled) {
      return {
        color: cayoTheme.wizard.colors.inactive,
        icon: cayoTheme.wizard.icons.inactive,
      };
    }

    if (completed) {
      return {
        color: cayoTheme.wizard.colors.finished,
        icon: cayoTheme.wizard.icons.visited,
      };
    }

    return {
      color: cayoTheme.wizard.colors.active,
      icon: cayoTheme.wizard.icons.active,
    };
  }, [completed, disabled]);

  return (
    <Icon iconName={iconScheme.icon} style={{ color: iconScheme.color, fontSize: 20, ...style }} />
  );
};

export default ConfigurationTaskIcon;
