import { dateTimeUtils, stringUtils } from "cayo.ui";
import { IntlShape } from "react-intl";
import { AvailabilitySchedule } from "../../api/cayo-graph";
import { commonMessages } from "../common-messages";
import messages from "./messages";

const toReadableText = (intl: IntlShape, value?: AvailabilitySchedule[]) => {
  if (!value || !value?.length) return intl.formatMessage(commonMessages.notset);

  let tempArray: string[] = [];
  for (const schedule of value) {
    const startTime = dateTimeUtils.extractTime(schedule.startTime!);
    const endTime = dateTimeUtils.extractTime(schedule.endTime!) ?? "00:00";

    let temp = schedule.days!.map((d) => stringUtils.toSpaceDelimitedPascalCase(d)).join(", ");
    if (startTime === endTime) {
      temp += " " + intl.formatMessage(messages.fullDay);
    } else {
      temp += " " + intl.formatMessage(messages.timeRange, { startTime, endTime });
      // from ${startTime} to ${endTime}`;
    }

    tempArray.push(temp);
  }

  let result = tempArray.join(" | ");
  return result;
};

const availabilityScheduleFormatter = {
  toReadableText,
};

export default availabilityScheduleFormatter;
