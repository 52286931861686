import { getFormatter } from "cayo.ui";
import React, { Fragment } from "react";
import GridCell from "./GridCell";
import { renderClassNames } from "./common";
import { RenderCellFn } from "./types";

const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})$/;
//TODO:use format column annotation
const dateColumnPattern =
  /(whencreated|datetime|whenchanged|oldvalue|newvalue|createdOn|updatedOn)$/i; //TODO: use column Annotation

export const renderDateCell: RenderCellFn = ({ item, column, auxProps }) => {
  const cellFormatter = column.renderOptions?.formatter;

  if (!dateColumnPattern.test(column.fieldName!) && !cellFormatter?.startsWith("date")) {
    return null;
  }
  const value = item[column.fieldName ?? ""];

  let result: Date | null = null;
  if (typeof value === "string" && datePattern.test(value)) {
    const parsed = Date.parse(value);
    if (!isNaN(parsed)) {
      result = new Date(parsed);
    }
  }
  if (Object.prototype.toString.call(value) === "[object Date]") {
    result = value as Date;
  }
  if (!result) {
    return null;
  } else if (result.getTime() <= 0) {
    return <Fragment></Fragment>;
  } else {
    let text: string | undefined = "";
    const formatter = cellFormatter ? getFormatter(cellFormatter) : undefined;

    if (formatter) {
      text = (formatter as any)(result, auxProps!.intl, column.renderOptions?.additionalParameters);
    } else {
      text = result.toLocaleString();
    }

    return (
      <GridCell renderer="date-cell">
        <span className={renderClassNames.toCopyValueContainer} data-to-copy>
          {text}
        </span>
      </GridCell>
    );
  }
};
