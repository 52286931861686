import { DirectionalHint, IColumn as IOfficeColumn, Stack, Toggle } from "@fluentui/react";
import { ITheme, TooltipHost, getFormatter, htmlUtils, stringUtils } from "cayo.ui";
import React, { FC, Fragment, useMemo } from "react";
import { IntlShape } from "react-intl";
import ReactJson from "react18-json-view";
import styled from "styled-components";
import { Entity, PossibleValueItem, PropertyAnnotation, PropertyIcon } from "../../api/cayo-graph";
import { IColumn, IColumnRenderOptions, IDetailsList } from "../../api/schema.api";
import logger from "../../libs/logger";
import { buildInIconFormatters } from "../../scheme/formatters/builtin-icon-formatters";
import { IAppTheme } from "../../themes";
import clipboardUtils from "../../utils/clipboard-utils";
import { getFormatterFromRenderOptions } from "../../utils/formatter-utils";
import { nameofFactory, objectUtils } from "../../utils/object-utils";
import CellWithCopy, { CopyCellContainer } from "../CellWithCopy";
import ErrorTooltip from "../Common/ErrorTooltip";
import { IGetIconAnnotation, IGetTypeAnnotation } from "../GlobalHooks/type-annotations.hook";
import Icon from "../Graphics/Icon";
import MaskedIcon from "../Graphics/MaskedIcon";
import LinkedObject from "../GridContainer/components/LinkedObject";
import { IQuery } from "../GridContainer/grid-model/Query";
import RenderOptionsFormatter from "../GridContainer/utils/field-formatter";
import { objectTypeToTitle } from "../GridContainer/utils/object-type-to-title";
import Column from "../Layout/Column";
import Row from "../Layout/Row";
import formatterControls, { formatterNames } from "../Schemes/renderers/formatter.renderers";
import { commonMessages } from "../common-messages";
import CloudIcon from "./../../images/cloud.svg";

export type IActionPromise = (path?: string, query?: string, item?: any) => Promise<any>;

type RenderOptionsType = IColumnRenderOptions & {
  itemFormatValue: string | undefined;
};

const formatValue = (
  item: Entity,
  fieldName: string,
  renderOptions: RenderOptionsType | undefined,
  nullValueDecorator: string | undefined,
  auxProps?: AuxRenderProps
) => {
  const suffix = "/" + fieldName;
  const target =
    (item.objectType || stringUtils.trim((item as any)["@odata.type"], "#")) + "/" + fieldName;

  let formatter = renderOptions ? getFormatterFromRenderOptions(renderOptions) : undefined;

  const value = (item as any)[fieldName];
  if (fieldName === "propertyName") {
    return value;
  }

  if (value === null || value === undefined) {
    nullValueDecorator =
      nullValueDecorator ||
      auxProps?.propertyAnnotations?.find((a) => a.target === target)?.nullValueDecorator ||
      auxProps?.propertyAnnotations?.find((a) => a.target?.endsWith(suffix))?.nullValueDecorator;

    if (nullValueDecorator) {
      return nullValueDecorator;
    }
  }

  const annotation =
    auxProps?.propertyAnnotations?.find((a) => a.target === target) ||
    auxProps?.propertyAnnotations?.find((a) => a.target?.endsWith(suffix));
  if (!!annotation?.possibleValues?.length) {
    const posValue = annotation!.possibleValues!.find(
      (v) => v.value?.toLowerCase() === value?.toLowerCase()
    );
    if (posValue) {
      return posValue.name;
    }
  }

  if (!formatter) {
    formatter =
      auxProps?.propertyAnnotations?.find((a) => a.target === target)?.formatValue ||
      auxProps?.propertyAnnotations?.find((a) => a.target?.endsWith(suffix))?.formatValue;
  }

  const fieldFormatter = new RenderOptionsFormatter(renderOptions, getFormatterFromRenderOptions);

  let result = fieldFormatter.getStringValue(value);
  const builtinFormatter = formatter ? getFormatter(formatter) : undefined;
  return (
    result ||
    (builtinFormatter && (builtinFormatter as any)((item as any)[fieldName], auxProps!.intl))
  );
};

export type IconEx = (PropertyIcon & { title?: string; isIconOnly?: boolean }) | undefined;

const lookupIcon = (item: Entity, auxProps: AuxRenderProps, fieldName?: string) => {
  const target = item.objectType + (fieldName ? "/" + fieldName : "");
  let icon: IconEx | undefined;
  let isIconOnly: boolean | undefined;
  let title: string | undefined;
  let tooltip: string | undefined;

  if (
    fieldName === "locationType" ||
    fieldName === "targetObjectType" ||
    fieldName === "targetType"
  ) {
    icon = auxProps.getIconAnnotation((item as any)[fieldName]);
  } else if (fieldName) {
    const suffix = "/" + fieldName;
    const annotation =
      auxProps?.propertyAnnotations?.find((a) => a.target === target) ||
      auxProps?.propertyAnnotations?.find((a) => a.target?.endsWith(suffix));
    if (!annotation || !annotation.icons || annotation.icons.length < 1) {
      return undefined;
    }

    const fieldValue = (item as any)[fieldName];
    icon = annotation!.icons.find((i: any) => i.key === fieldValue);
    if (icon) {
      icon = objectUtils.cloneObject(icon);
    }

    const pv = annotation.possibleValues?.find((v) => v.value === fieldValue);
    if (pv) {
      title = pv.name;
      tooltip = pv.tooltip;
    }

    const resolvedColor =
      icon?.iconStyle?.color && auxProps?.theme?.resolveNamedColor(icon?.iconStyle?.color);
    if (resolvedColor) {
      icon!.iconStyle!.color = resolvedColor;
    }

    isIconOnly = true;
  } else {
    icon = auxProps.getIconAnnotation(item.objectType!);
  }

  return { ...icon, isIconOnly, title, tooltip };
};

const renderObject = (
  key: string,
  o: object,
  title?: string,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  if (o === null) {
    return <Fragment key={key}>{"null"}</Fragment>;
  }

  const titleComponent = title && (
    <div style={{ display: "inline-flex", marginRight: 4 }}>{title}</div>
  );
  const valuesComponent = (
    <div key={key} style={{ display: "flex", flexFlow: "column", paddingBottom: 4 }}>
      {Object.keys(o).map((k, ii) => {
        const value = (o as any)[k] as any;

        const isObject = Object.prototype.toString.call(value) === "[object Object]";
        const isLinkedObject =
          value.objectName &&
          (value.objectType || value.linkType) &&
          (value.originalObjectPath || value.objectPath);
        if (isObject) {
          return (
            <div
              key={ii}
              style={{
                display: "inline-flex",
                alignItems: isLinkedObject ? "center" : "baseline",
                height: "100%",
              }}
            >
              <label>{k}</label>: &nbsp;
              {isLinkedObject ? (
                <LinkedObject
                  {...value}
                  onItemClicked={onItemClicked}
                  getIconAnnotation={auxProps?.getIconAnnotation}
                />
              ) : (
                renderObject(k + ii, value)
              )}
            </div>
          );
        } else if (!isObject) {
          const isArray = Array.isArray(value);
          const isStringArray = isArray && value.length && typeof value[0] === "string";
          let strValue: any = "";
          if (isStringArray) {
            strValue = value as string[]; //.join("; ");
          } else if (isArray) {
            return (
              <div key={ii} style={{ display: "inline-flex", alignItems: "start", height: "100%" }}>
                <label>{k}</label>: &nbsp;
                {renderArray(key + ii, value, undefined, onItemClicked, auxProps)}
              </div>
            );

            // return (value as any[]).map((v, i) =>
            //   renderObject(key + i, v, undefined, onItemClicked, auxProps)
            // );
          } else {
            const dateValue = getDateValue(value);
            if (dateValue) {
              strValue = dateValue.toLocaleString();
            } else {
              strValue = (value ?? "").toString();
            }
          }

          return <CellWithCopy key={ii} title={k} value={strValue} />;
        }

        return (
          <CellWithCopy
            key={ii}
            title={k}
            value={value?.expandMV ? value?.join("; ") : value?.toString()}
          />
        );
      })}
    </div>
  );

  if (titleComponent) {
    return (
      <Row key={key}>
        {titleComponent}
        {valuesComponent}
      </Row>
    );
  }
  return valuesComponent;
};

const renderArray = (
  key: string,
  o: any[],
  title?: string,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  if (Object.prototype.toString.call(o) === "[object Array]") {
    if (o.length === 0) {
      return o.toString();
    }

    const isSingleValue = o.length === 1;
    const firstObject = o[0];

    if (firstObject === null) {
      logger.debug("renderArray: isSingleValue", isSingleValue);
    }

    if (
      firstObject !== null &&
      firstObject.objectName &&
      firstObject.objectType &&
      (firstObject.originalObjectPath || firstObject.objectPath)
    ) {
      return (
        <Column key={key} style={{ height: "100%" }}>
          {o.map((v, i) => (
            <LinkedObject
              key={i}
              {...v}
              onItemClicked={onItemClicked}
              getIconAnnotation={auxProps?.getIconAnnotation}
            />
          ))}
        </Column>
      );
    } else if (o.length > 0 && typeof firstObject === "object") {
      return (
        <React.Fragment key={key}>
          {o.map((v, i) =>
            renderObject(
              i.toString(),
              v,
              isSingleValue ? undefined : "#" + (i + 1) + " ",
              onItemClicked,
              auxProps
            )
          )}
        </React.Fragment>
      );
    }
  }

  return <Fragment key={key} />;
};

export interface AuxRenderProps {
  intl: IntlShape;
  theme: ITheme;
  getTypeAnnotation: IGetTypeAnnotation;
  getIconAnnotation: IGetIconAnnotation;
  propertyAnnotations?: PropertyAnnotation[];
  onApply?: () => void;
  onPickerStateChanged?: (isOpened: boolean) => void;
  targetType?: string;
  possibleValues?: PossibleValueItem[];
  additionalProps?: any;
  innerQuery?: IQuery;
  queryUrl?: string;
}

type ItemRenderer = (
  item: any,
  index: number,
  column: IOfficeColumn,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => JSX.Element | null;

const bool: ItemRenderer = (
  item: any,
  index: number,
  column: IOfficeColumn,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  const value = item[column.fieldName!];
  const isBool = typeof value === "boolean";
  if (!isBool) {
    return null;
  }

  let strValue;
  if (column.fieldName === "builtIn") {
    strValue = auxProps?.intl?.formatMessage(
      value === true ? commonMessages.builtIn : commonMessages.custom
    );
  } else {
    strValue = auxProps?.intl?.formatMessage(
      value === true ? commonMessages.yes : commonMessages.no
    );
  }

  return typeof value === "boolean" ? (
    <Row key={item.id} valign={true}>
      {strValue}
    </Row>
  ) : null;
};

const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})$/;
const dateColumnPattern =
  /(whencreated|datetime|whenchanged|oldvalue|newvalue|createdOn|updatedOn)$/i;

const getDateValue = (value: any) => {
  let result: Date | null = null;

  if (typeof value === "string" && datePattern.test(value)) {
    const parsed = Date.parse(value);
    if (!isNaN(parsed)) {
      result = new Date(parsed);
    }
  }

  return result;
};

const date: ItemRenderer = (
  item: any,
  index: number,
  column: IOfficeColumn,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  if (!dateColumnPattern.test(column.fieldName!)) {
    return null;
  }
  const value = item[column.fieldName!];

  let result = getDateValue(value);

  if (Object.prototype.toString.call(value) === "[object Date]") {
    result = value as Date;
  }

  const renderOptions = (column as any)["renderOptions"] as IColumnRenderOptions;

  const nullValueDecorator = item?.nullValueDecorator || renderOptions?.nullValueDecorator;

  var formatter = item.formatValue;
  var formatedValue = formatValue(
    item,
    column.fieldName!,
    { itemFormatValue: item.formatValue, ...(renderOptions || {}) },
    nullValueDecorator,
    auxProps
  );

  if (result && result?.getTime() <= 0) {
    return <React.Fragment key={item.id} />;
  } else if (result) {
    return (
      <CellWithCopy key={item.id} value={formatter ? formatedValue : result.toLocaleString()} />
    );
  }

  return null;
};

const jobState: ItemRenderer = (
  item: any,
  index: number,
  column: IOfficeColumn,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  const renderOptions = (column as any).renderOptions as IColumnRenderOptions;
  const fieldFormatter = new RenderOptionsFormatter(renderOptions, getFormatterFromRenderOptions);

  if (fieldFormatter.isJobState()) {
    const value = item[column.fieldName!] as string;
    const stringValue = fieldFormatter.getStringValue(item[column.fieldName!] as string);

    const icon = buildInIconFormatters.jobstate(auxProps!.theme! as IAppTheme, {
      executionState: item.executionState,
      lastExecutionResult: item.lastExecutionResult || item.executionResult,
    });

    return (
      <div
        key={item.id}
        style={{
          alignItems: "center",
          display: "flex",
          maxWidth: "100%",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          height: "100%",
        }}
      >
        <Icon
          rotate={value === "running" ? "1" : ""}
          iconName={icon.iconName}
          style={{ fontSize: "14px", color: icon.color }}
        />
        <span
          style={{
            maxWidth: "100%",
            overflow: "hidden",
            paddingLeft: "4px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {stringValue}
        </span>
      </div>
    );
  }

  return null;
};

const propertyIcon = (icon: PropertyIcon, title?: string) => {
  const realTitle = icon?.tooltip ? undefined : stringUtils.makePascal(icon?.title ?? title);

  let iconComp = (
    <Icon
      iconName={icon.iconId}
      rotate={icon.iconId === "Sync" ? "1" : undefined}
      style={{
        color: icon?.iconStyle?.color,
        display: "flex",
        fontSize: icon.iconStyle?.fontSize ? +icon.iconStyle.fontSize : 14,
        fontWeight: icon.iconStyle?.fontWeight as any,
        alignItems: "center",
      }}
    />
  );

  if (icon.tooltip) {
    iconComp = <TooltipHost tooltip={icon.tooltip}>{iconComp}</TooltipHost>;
  }

  return (
    <div
      title={realTitle}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {iconComp}
    </div>
  );
};

const iconOnlyField: ItemRenderer = (
  item: any,
  index: number,
  column: IOfficeColumn,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  let formatter = (column as unknown as IColumn)?.renderOptions?.formatter;
  //if (column.fieldName === "severity") debugger;
  // if (column.fieldName === "severity" && item.objectType === "cayo.graph.riskAlert") {
  //   formatter =  "RiskIndicator";
  // }
  const FormatterControl =
    formatter && formatterControls[formatter.toLowerCase() as formatterNames];
  if (FormatterControl) {
    return <FormatterControl {...item} />;
  }

  const icon = lookupIcon(item, auxProps!, column.fieldName);
  if (!icon || !icon?.isIconOnly) {
    return null;
  }

  let iconComponent = propertyIcon(icon, item[column.fieldName!]);
  if (item?.errors?.length > 0) {
    iconComponent = (
      <ErrorTooltip id={item.id} errors={item.errors}>
        {iconComponent}
      </ErrorTooltip>
    );
  }
  return iconComponent;
};

const array: ItemRenderer = (
  item: any,
  index: number,
  column: IOfficeColumn,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  const value = item[column.fieldName!] as any[];
  if (Object.prototype.toString.call(value) === "[object Array]") {
    if (value.length === 0) {
      return <Fragment />;
    }

    const isSingleValue = value.length === 1;
    const firstObject = value[0];
    if (
      firstObject.objectName &&
      (firstObject.objectType || item.linkType) &&
      (firstObject.originalObjectPath || firstObject.objectPath)
    ) {
      return (
        <Column key={item.id} style={{ height: "100%" }}>
          {value.map((v, i) => (
            <LinkedObject
              key={i}
              {...v}
              onItemClicked={onItemClicked}
              getIconAnnotation={auxProps?.getIconAnnotation}
            />
          ))}
        </Column>
      );
    } else if (value.length > 0 && typeof firstObject === "object") {
      return (
        <React.Fragment key={item.id}>
          {value.map((v, i) =>
            renderObject(
              i.toString(),
              v,
              isSingleValue ? undefined : "#" + (i + 1) + " ",
              onItemClicked,
              auxProps
            )
          )}
        </React.Fragment>
      );
    } else {
      if (item.expandMV) {
        return <CellWithCopy key={item.id} value={value} multiLine={true} />;
      }
      return <CellWithCopy key={item.id} value={value.join("; ")} />;
    }
  }

  return null;
};

const object: ItemRenderer = (
  item: any,
  index: number,
  column: IOfficeColumn,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  const value = item[column.fieldName!];
  if (Object.prototype.toString.call(value) === "[object Object]") {
    const isComplex = objectUtils.isComplexObject(value);
    if (
      value.objectName &&
      (value.objectType || value.linkType) &&
      (value.originalObjectPath || value.objectPath)
    ) {
      return (
        <LinkedObject
          key={item.id}
          {...value}
          onItemClicked={onItemClicked}
          getIconAnnotation={auxProps?.getIconAnnotation}
        />
      );
    } else {
      return isComplex ? (
        <Stack verticalAlign="start" style={{ height: "100%" }}>
          <ReactJson key={item.id} src={value} enableClipboard={true} collapsed={true} />
        </Stack>
      ) : (
        <React.Fragment key={item.id}>
          {renderObject(column.fieldName!, value, undefined, onItemClicked, auxProps)}
        </React.Fragment>
      );
    }
  }

  return null;
};

const str: ItemRenderer = (
  item: any,
  index: number,
  column: IOfficeColumn,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onItemClicked?: IActionPromise | undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auxProps?: AuxRenderProps
) => {
  const value = item[column.fieldName!];
  let formatedValue: any = undefined;

  const renderOptions = (column as any)["renderOptions"] as IColumnRenderOptions;

  const nullValueDecorator = item?.nullValueDecorator || renderOptions?.nullValueDecorator;

  formatedValue = formatValue(
    item,
    column.fieldName!,
    item.formatValue || renderOptions?.formatter,
    nullValueDecorator,
    auxProps
  );

  const showTooltip = column.fieldName === "message" && item?.errors?.length > 0;
  let C =
    (typeof value !== "string" && typeof formatedValue === "string") ||
    typeof value === "string" ? (
      <CellWithCopy
        key={item.id}
        value={formatedValue === undefined ? value : formatedValue}
        multiLine={renderOptions?.multilineString}
        tooltip={item.tooltip}
        showInfoIcon={showTooltip}
      />
    ) : null;

  if (showTooltip) {
    C = (
      <ErrorTooltip id={item.id} errors={item.errors}>
        {C}
      </ErrorTooltip>
    );
  }

  return C;
};

const other: ItemRenderer = (item: any, index: number, column: IOfficeColumn) => {
  return <CellWithCopy key={item.id} value={item[column.fieldName!]?.toString()} />;
};

const detailsListNames = nameofFactory<IDetailsList>();

const columnWithImage: ItemRenderer = (
  item: any,
  index: number,
  column: IOfficeColumn,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  const renderOptions = (column as any).renderOptions as IColumnRenderOptions;
  const fieldFormatter = new RenderOptionsFormatter(renderOptions, getFormatterFromRenderOptions);
  const invokeItemByOneClick =
    auxProps?.additionalProps &&
    auxProps?.additionalProps[detailsListNames("invokeItemByOneClick")];

  const isNone =
    item[column.fieldName!] === "none" && column.fieldName !== "lastVerificationStatus";
  let isColumnWithImage =
    !(column as any).noIcon &&
    ((column.fieldName === "name" && item.objectType) ||
      (column.fieldName === "objectName" && (item.objectType || item.nestedType)) ||
      (column.fieldName === "displayName" && item.objectType && !item.name) ||
      (fieldFormatter.isIconWithText() && !isNone));

  if (isNone) {
    return <Fragment />;
  }

  if (column.isIconOnly) {
    isColumnWithImage = false;
  }

  if (column.fieldName === "displayName" && item.objectName && item.objectType) {
    isColumnWithImage = false;
  }

  let objType: string = "";

  if (isColumnWithImage) {
    objType = item.targetObjectType || item.nestedType || item.objectType;
  }

  if (isColumnWithImage) {
    const addSwitch = !!(column as any as IColumn)?.renderOptions?.switchProperty;

    const isCloud =
      objType.toLowerCase().indexOf("microsoft.graph") !== -1 ||
      objType.toLowerCase().indexOf("cayo.azurerm") !== -1 ||
      (objType === "cayo.graph.authorityPrincipal" && item.source === "azureAD") ||
      objType.toLowerCase().indexOf("microsoft.exo") !== -1;

    const annotation = auxProps?.getTypeAnnotation!(objType);

    const iconSource = fieldFormatter.iconSource;

    let addCloudIcon = !iconSource && isCloud;
    //HARDCODE: BUG 11193 : show cloud icon for threat alert
    if (item.objectType === "cayo.graph.riskAlert" && column.fieldName === "targetObjectName") {
      addCloudIcon = isCloud;
    }

    let iconAnnotation: IconEx = iconSource
      ? lookupIcon(item, auxProps!, iconSource)
      : annotation?.icon;

    const iconTitle =
      iconAnnotation?.title ||
      (column.fieldName === "targetObjectName" && iconSource !== "targetObjectType" // TODO: remove hardcode
        ? objectTypeToTitle(objType)
        : iconSource
        ? item[iconSource]
        : undefined);

    let icon =
      fieldFormatter.isIconWithText() &&
      !item.nestedType &&
      !item.locationType &&
      !item.targetType &&
      !item.targetObjectType
        ? iconOnlyField(item, index, column, onItemClicked, auxProps)
        : (iconAnnotation && propertyIcon(iconAnnotation, iconTitle)) ||
          propertyIcon(
            {
              iconId: objectUtils.isCollection(objType) ? "FabricFolder" : "Document",
            },
            iconTitle
          );

    if (addCloudIcon) {
      icon = (
        <MaskedIcon maskIcon={<img alt={item.name} src={CloudIcon} width={9} height="9" />}>
          {icon}
        </MaskedIcon>
      );
    }

    if (column.fieldName === "name" && item.disabled) {
      icon = (
        <Icon
          iconName={"DRM"}
          style={{
            padding: "0 4px 0 0",
            fontSize: "14px",
            color: auxProps?.theme?.cayoTheme.brandColors.disabled,
          }}
        />
      );
    }

    const nullValueDecorator = item?.nullValueDecorator || renderOptions?.nullValueDecorator;
    const rawValue = item[column.fieldName!];
    let formattedValue = formatValue(
      item,
      column.fieldName!,
      item.formatValue || renderOptions?.formatter,
      nullValueDecorator,
      auxProps
    );

    return (
      <CopyCellContainer
        key={item.id || index}
        style={{
          alignItems: "center",
          display: "flex",
          maxWidth: "100%",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        onClick={() => clipboardUtils.copyToClipboard(formattedValue || rawValue)}
      >
        {addSwitch && (
          <Toggle
            onChange={async () => {
              return (
                onItemClicked &&
                (await onItemClicked(
                  undefined,
                  undefined,
                  (() => {
                    item._hasToggle = true;
                    return item;
                  })()
                ))
              );
            }}
            disabled={item.mandatory}
            checked={!item.disabled}
            styles={{ root: { marginBottom: 0, marginRight: 8 } }}
          />
        )}
        <div style={{ display: "flex", marginRight: 4 }}>{icon}</div>
        <HoveredItem underline={invokeItemByOneClick}>{formattedValue || rawValue}</HoveredItem>
      </CopyCellContainer>
    );
  }

  return null;
};

const HoveredItem = styled.span<{ underline?: boolean }>`
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 4;

  &:hover {
    text-decoration: ${(props) => (props.underline ? "underline" : undefined)};
  }
`;
const maxTextLength = 200;

const HtmlCell: FC<{ html: string }> = ({ html }) => {
  const text = useMemo(() => htmlUtils.convertToText(html, maxTextLength), [html]);

  return (
    <TooltipHost directionalHint={DirectionalHint.rightCenter} tooltip={html} showCopyButton={true}>
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textDecoration: "underline",
        }}
      >
        {text}
      </div>
    </TooltipHost>
  );
};

const html: ItemRenderer = (item: any, index: number, column: IOfficeColumn) => {
  return <HtmlCell html={item[column.fieldName!] as string} />;
};

const cellRenderers = {
  jobState,
  columnWithImage,
  iconOnlyField,
  array,
  object,
  bool,
  date,
  str,
  other,
  html,
};

export default cellRenderers;
