import { getTheme, loadTheme } from "@fluentui/react";
import { ITheme, defaultTheme, fallbackTheme, getColor, getIcon, themeUtils } from "cayo.ui";
import { CSSProperties, useEffect, useState } from "react";
import { IBrandColors, ITheme as IServerTheme } from "../api/schema.api";
import ajax from "../libs/ajax";
import JobStateService, { IconInfo, JobState } from "../services/job-state.service";

export type IAppTheme = ITheme & {
  getJobState: (state: JobState) => IconInfo;
};

export const defaultAppTheme = () => ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getJobState: (state: JobState): IconInfo => ({ iconName: "", color: "" }),
  ...defaultTheme(fallbackTheme.cayoTheme.brandColors),
});

export const fallbackAppTheme = defaultAppTheme();

type brandColorNames = keyof IBrandColors;

export const useServerTheme = (themeName: string) => {
  const [theme, setTheme] = useState<IAppTheme | undefined>(undefined);

  const { data, isLoading } = ajax.useGet(`themes/${themeName || "default"}`);

  useEffect(() => {
    if (!data) {
      return;
    }

    const serverTheme = data as IServerTheme;
    if (serverTheme) {
      const result = defaultTheme(serverTheme.brandColors) as IAppTheme;
      const resolveColor = (color: string) => {
        return color.startsWith("#")
          ? color
          : serverTheme.brandColors[color as brandColorNames] ?? color;
      };

      result.getColor = (v, d) => {
        const c = getColor(v, d, serverTheme, resolveColor);
        return c;
      };
      result.getIcon = (v, d) => getIcon(serverTheme, v, d);
      result.resolveNamedColor = (c) => resolveColor(c);

      const jobStateService = new JobStateService(result);
      result.getJobState = (s) => jobStateService.getJobIcon(s);

      // TODO: remove hardcode
      result.getButtonThemeStyles = (b) => {
        let style = themeUtils.getButtonStyle(b);
        let hover: Partial<CSSProperties> | undefined = undefined;
        let pressed: Partial<CSSProperties> | undefined = undefined;

        if (b.theme) {
          style.backgroundColor = result.getColor(b.theme, "severity") ?? b.theme;
          style.color =
            b.theme === "medium"
              ? serverTheme.brandColors.deleted
              : serverTheme.brandColors.primaryBackground;
          style.border = 0;
          style.transition = "background .5s";

          hover = {
            ...style,
            backgroundColor: themeUtils.lightenDarkenColor(style.backgroundColor, 20),
          };

          pressed = {
            ...style,
          };
        }
        return { normal: style, hover, pressed };
      };

      const palette = serverTheme.officePatelle;
      if (palette) {
        //const officeTheme = createTheme({ palette });
        result.officeTheme = loadTheme({ palette });
      } else {
        result.officeTheme = getTheme();
      }

      setTheme({ ...result });
    }
  }, [themeName, data]);

  return { theme: theme || fallbackAppTheme, isLoading };
};
