import { ICommandBarItemProps } from "@fluentui/react";
import { logger } from "cayo.ui";
import React from "react";
import { IntlShape } from "react-intl";
import { Entity, SavedQuery } from "../../api/cayo-graph";
import { IAdvancedFilterInfo } from "../../utils/grid-utils";
import commandBarButtonIds from "../CommandBar/buttons-ids";
import { IQueryFilterParameters } from "../QueryFilterForm/IQueryFilterParameters";
import { commonMessages } from "../common-messages";
import IToolbarActions from "./IToolbarActions";

const log = logger.getLogger("objectPickerHooks.useCommandBar");

const useCommandBar = (
  actions: IToolbarActions | undefined,
  selection: Entity[] | undefined,
  intl: IntlShape,
  onRefresh?: (createdItem?: Entity) => void
) => {
  const buttons = React.useMemo(() => {
    const buttons: ICommandBarItemProps[] = [];
    if (actions?.onNewClick) {
      const newButton: ICommandBarItemProps = {
        key: commandBarButtonIds.newFilter,
        text: intl.formatMessage(commonMessages.new),
        iconProps: { iconName: "Add" },
        onClick: (ev, item) => {
          item!.data = {
            filter: undefined,
            onFilterSaved: onRefresh,
            saveOnServerOnly: true,
          } as IQueryFilterParameters;
          actions.onNewClick!(ev, item);
        },
      };
      buttons.push(newButton);
    }

    if (actions?.onPropsClick) {
      const newButton: ICommandBarItemProps = {
        key: commandBarButtonIds.propertiesFilter,
        text: intl.formatMessage(commonMessages.properties),
        iconProps: { iconName: "Edit" },
        disabled: !selection || selection.length === 0,
        onClick: (ev, item) => {
          const selectedFilter = selection![0] as SavedQuery;
          item!.data = selectedFilter
            ? ({
                filter: {
                  id: selectedFilter.id,
                  filter: selectedFilter.effectiveFilter,
                  builtIn: selectedFilter.builtIn,
                  name: selectedFilter.name,
                  advancedFilter: selectedFilter.advancedFilter,
                  anrFilter: selectedFilter.anrFilter,
                } as IAdvancedFilterInfo,
                onFilterSaved: onRefresh,
                saveOnServerOnly: true,
              } as IQueryFilterParameters)
            : undefined;
          actions.onPropsClick!(ev, item, selectedFilter);
        },
      };
      buttons.push(newButton);
    }

    if (actions?.onDeleteClick) {
      const newButton: ICommandBarItemProps = {
        key: commandBarButtonIds.deleteFilter,
        text: intl.formatMessage(commonMessages.delete),
        iconProps: { iconName: "Delete" },
        disabled: !selection || selection.length === 0 || selection[0].builtIn === true,
        onClick: (ev, item) => {
          const selectedFilter = selection![0] as SavedQuery;
          item!.data = selectedFilter
            ? ({
                filter: {
                  id: selectedFilter.id,
                  filter: selectedFilter.effectiveFilter,
                  builtIn: selectedFilter.builtIn,
                  name: selectedFilter.name,
                  advancedFilter: selectedFilter.advancedFilter,
                  anrFilter: selectedFilter.anrFilter,
                } as IAdvancedFilterInfo,
                onFilterSaved: onRefresh,
                saveOnServerOnly: true,
              } as IQueryFilterParameters)
            : undefined;
          actions.onDeleteClick!(ev, item);
        },
      };
      buttons.push(newButton);
    }
    log.debug("create buttons -> buttons, selection", buttons, selection);
    return buttons;
  }, [actions?.onNewClick, actions?.onDeleteClick, actions?.onPropsClick, selection, onRefresh]);

  return buttons;
};

const objectPickerHooks = {
  useCommandBar,
};

export default objectPickerHooks;
