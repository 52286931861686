import { PrimaryButton } from "@fluentui/react";
import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { endpoints } from "../../services/endpoints.service";
import customUrlUtils from "../../utils/custom-url-utils";
import downloadUtils from "../../utils/download-utils";
import { showLazyProps } from "../../utils/lazy-props";
import { urlUtils } from "../../utils/url-utils";
import { useSchemes } from "../App/hooks";

type LegacyActionButtonProps = {
  action?: string;
  actionName: string;
  url?: string;
  isStream?: boolean;
  id?: string;
};

const LegacyActionButton: FC<LegacyActionButtonProps> = ({
  action,
  actionName,
  url,
  isStream,
  id,
}) => {
  const { showControl, showPropertiesPanel } = useSchemes();
  const location = useLocation();
  const history = useHistory();

  return (
    <PrimaryButton
      onClick={() => {
        if (url) {
          if (url.startsWith("#")) {
            let parts = url.split("?props=");

            const schemeUrl = customUrlUtils.getActionUrl(url);

            const newPath = parts[0].substring(1);

            if (newPath.endsWith("$value") || isStream) {
              let fullUrl = endpoints.publicUrl + "/v0/" + urlUtils.trim(url);
              downloadUtils.downloadODataFile(fullUrl);
              return;
            }

            history.push(newPath);

            if (schemeUrl) {
              if (location.pathname === newPath) {
                showPropertiesPanel({ id: schemeUrl, parentId: id });
              } else {
                showLazyProps(newPath, schemeUrl!);
              }
            }
          } else {
            window.open(url);
          }
        } else if (action) {
          if (action.toLowerCase() === "activate") {
            showControl({ controlId: "ActivationWizard", folder: "Configuration" });
          }
        }
      }}
      primary={true}
    >
      {actionName}
    </PrimaryButton>
  );
};

export default LegacyActionButton;
