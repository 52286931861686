import { debounce } from "cayo.ui";
import React, { useCallback } from "react";
import { SavedQuery } from "../../api/cayo-graph";
import logger from "../../libs/logger";
import { filterSignalsService } from "../../services/filter-signal.service";
import { gridUtils } from "../../utils/grid-utils";
import { urlUtils } from "../../utils/url-utils";
import { IQueryFilterParameters } from "./IQueryFilterParameters";
import { makeErrorMessage, savedQueries } from "./logic";

const log = logger.getLogger("QUICK-FILTER QueryFilterForm.hooks");

const useHideSaveSuccessInfo = () => {
  const [saveSuccess, setSaveSuccess] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    debounce(() => {
      setSaveSuccess(undefined);
    }, 5000)();
  }, [saveSuccess]);

  return { saveSuccess, setSaveSuccess };
};

const useFormErrors = () => {
  const [errors, setErrors] = React.useState<{ [id: string]: string }>({});

  const setErrorCallback = useCallback(
    (name: string, e: any) => {
      if (e) {
        errors[name!] = e;
      } else if (errors) {
        delete errors[name!];
      }
      setErrors({ ...errors });
    },
    [errors]
  );

  return { errors, setErrors, setErrorCallback };
};

function deleteFilter(
  url: string,
  query: SavedQuery,
  gridId: string,
  prms: IQueryFilterParameters,
  showConfirmation: (message: string, onConfirmed: () => Promise<void>, warning?: string) => void,
  hideConfirmation: () => void,
  onClose: () => void,
  setError: (e: string) => void
) {
  const { onFilterSaved, saveOnServerOnly } = prms;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const gridSettingsKey = urlUtils.trim(window.location.hash + gridId);

  showConfirmation(`Are you sure you want to delete '${query.name}' saved query?`, () => {
    hideConfirmation();

    return savedQueries
      .delete(url!, query.id!)
      .then(() => {
        if (onFilterSaved) {
          onFilterSaved();
        }

        if (!saveOnServerOnly || gridUtils.getFilter(gridSettingsKey)?.id === query.id) {
          filterSignalsService.send("filterSelected", undefined, gridId);
        }

        onClose && onClose();
      })
      .catch((e) => {
        setError(makeErrorMessage(e));
      });
  });
}

export const savedQueryModelReducer = (old: SavedQuery, newModel: SavedQuery) => {
  if (log.isDebugEnabled()) {
    log.debug("savedQueryModelReducer -> ", { old, newModel });
  }
  return { ...old, ...newModel };
};

export const queryFilterFormHooks = {
  useHideSaveSuccessInfo,
  useFormErrors,
  deleteFilter,
  savedQueryModelReducer,
};
