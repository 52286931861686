import { Database, NetworkSettings } from "../../../api/cayo-graph";
import { settingsEndpoints } from "../../../api/settings-endpoints";
import ajax from "../../../libs/ajax";
import logger, { ILogger } from "../../../libs/logger";
import actionHandlers from "../../../scheme/actions/action-handlers";
import { endpoints } from "../../../services/endpoints.service";
import { ajaxUtils } from "../../../utils/ajax-utils";
import apiPaths from "../../../utils/api-path";
import { urlUtils } from "../../../utils/url-utils";
import { IShowControlHandler } from "../../App/types";
import { appSignals, appUtils } from "../../App/utils";

export type configurationActions = {
  onAddTenant: () => void;
  onAddDomain: () => void;
  onActivate: () => void;
  onConfigureDatabase: () => void;
  onConfigureLater: () => void;
};

export type configInfo = {
  hasDomains?: boolean;
  hasTenants?: boolean;
  hasLicense?: boolean;
  databaseConfigured?: boolean;
  useLegacyLicensing?: boolean;
};

type UserSettings = { showInitialWizard?: boolean; databaseConfigured?: boolean };

class InitialConfigurationController {
  private _isWizardVisible: boolean | undefined = undefined;
  private hasDomains: boolean | undefined = undefined;
  private hasTenants: boolean | undefined = undefined;
  private hasLicense: boolean | undefined = undefined;
  private databaseConfigured: boolean | undefined = undefined;
  private useLegacyLicensing?: boolean;
  private log: ILogger = logger.getLogger("InitialConfigurationController");

  constructor(
    readonly showControl: (prms: IShowControlHandler) => void,
    readonly onRefresh: () => void
  ) {
    this.log.debug("managedTenantsUrl", this.managedTenantsUrl);
    this.log.debug("managedDomainsUrl", this.managedDomainsUrl);
    this.log.debug("licensesUrl", this.licensesUrl);
    this.log.debug("databaseSettingsUrl", settingsEndpoints.databaseSettings);
    this.log.debug("uiSettingsUrl", settingsEndpoints.uiSettings);
  }

  readonly managedTenantsUrl = urlUtils.buildRelativePath(
    endpoints.PUBLIC_URL,
    `${apiPaths.managedTenants}/$count`
  );
  readonly managedDomainsUrl = urlUtils.buildRelativePath(
    endpoints.PUBLIC_URL,
    `${apiPaths.managedDomains}/$count`
  );
  readonly licensesUrl = urlUtils.buildRelativePath(
    endpoints.PUBLIC_URL,
    `${apiPaths.subscriptions}/$count`
  );

  readonly serverSubscriptionsUrl = urlUtils.buildRelativePath(
    endpoints.PUBLIC_URL,
    `${apiPaths.serverSubscriptions}/$count`
  );

  private _storageSettings: Database | undefined;

  public async init() {
    let showConfigWizard: boolean | undefined;
    let isDbLocal: boolean | undefined;

    try {
      const managedTenantsCount = (await ajax.getClient(this.managedTenantsUrl)) as number;
      this.hasTenants = !!managedTenantsCount;

      const managedDomainsCount = (await ajax.getClient(this.managedDomainsUrl)) as number;
      this.hasDomains = !!managedDomainsCount;

      const licensesCount = (await ajax.getClient(this.licensesUrl)) as number;
      const serverSubscriptionsCount = (await ajax.getClient(
        this.serverSubscriptionsUrl
      )) as number;
      this.hasLicense = !!licensesCount || !!serverSubscriptionsCount;

      const networkSettings = (await ajax.getClient(
        settingsEndpoints.networkSettings
      )) as NetworkSettings;
      this.useLegacyLicensing = networkSettings?.useLegacyLicensing;

      this._storageSettings = (await ajax.getClient(
        settingsEndpoints.databaseSettings,
        undefined,
        undefined,
        undefined,
        { $select: "*" }
      )) as Database;

      const indexOfLocalDb = this._storageSettings?.server?.toLocaleLowerCase()?.indexOf("localdb");
      isDbLocal = typeof indexOfLocalDb === "number" && indexOfLocalDb >= 0;

      this.log.info("init, storage settings", this._storageSettings);
    } catch (e) {
      await ajaxUtils.handleError(e);
    }

    try {
      const { data } = await ajax.getClient(settingsEndpoints.uiSettings);

      const userSettings: UserSettings = data ? JSON.parse(data) : {};
      this.log.info("init, settings", userSettings);

      showConfigWizard = userSettings?.showInitialWizard ?? undefined;

      if (showConfigWizard === false) {
        this._isWizardVisible = false;
        return;
      }

      this.databaseConfigured = userSettings?.databaseConfigured;
    } catch (e: any) {
      if (e?.status !== 404) {
        const error = await ajaxUtils.getError(e);
        appUtils.showError(error?.error_description);
        return;
      }
    } finally {
      if (!isDbLocal) {
        this.databaseConfigured = true;
      }
    }

    this._isWizardVisible = showConfigWizard ?? undefined;

    if (this._isWizardVisible === undefined) {
      this._isWizardVisible = !this.hasTenants || !this.hasDomains || !this.hasLicense;
    }
  }

  public get wizardVisible() {
    return this._isWizardVisible;
  }
  public get storageSettings() {
    return this._storageSettings;
  }

  private saveSettings = async (newSettings: UserSettings) => {
    const result = await ajax.getClient(settingsEndpoints.uiSettings, "PATCH", undefined, {
      data: JSON.stringify(newSettings),
    });
    return result;
  };

  private configureLater = async () => {
    try {
      const newSettings: UserSettings = {
        showInitialWizard: false,
        databaseConfigured: this.databaseConfigured,
      };

      this.log.info("configureLater, saving settings", newSettings);

      const savedSettings = await this.saveSettings(newSettings);

      this.log.info("configureLater, saved settings", savedSettings);

      this._isWizardVisible = false;
      this.onRefresh();
    } catch (e) {
      await ajaxUtils.handleError(e);
    }
  };

  private useBuiltinDatabase = async () => {
    try {
      const newSettings: UserSettings = {
        databaseConfigured: true,
        showInitialWizard: this._isWizardVisible,
      };

      this.log.info("useBuiltinDatabase, saving settings", newSettings);

      const savedSettings = await this.saveSettings(newSettings);

      this.log.info("useBuiltinDatabase, saved settings", savedSettings);

      this.databaseConfigured = true;
      this.onRefresh();
    } catch (e) {
      await ajaxUtils.handleError(e);
    }
  };

  public showWizard = async () => {
    try {
      const newSettings: UserSettings = {
        showInitialWizard: true,
        databaseConfigured: this.databaseConfigured,
      };

      this.log.info("showWizard, saving settings", newSettings);

      const savedSettings = await this.saveSettings(newSettings);

      this.log.info("showWizard, saved settings", savedSettings);

      this._isWizardVisible = true;
      this.onRefresh();
    } catch (e) {
      await ajaxUtils.handleError(e);
    }
  };

  public get hasManagedSystems() {
    return this.hasDomains === undefined || this.hasTenants === undefined
      ? undefined
      : this.hasDomains || this.hasTenants;
  }

  public get configuration() {
    const onRefresh = this.onRefresh;
    return {
      configInfo: {
        hasDomains: this.hasDomains,
        hasTenants: this.hasTenants,
        hasLicense: this.hasLicense,
        databaseConfigured: this.databaseConfigured,
        useLegacyLicensing: this.useLegacyLicensing,
      } as configInfo,

      actions: {
        onAddTenant: () =>
          this.showControl({
            controlId: "NewManagedTenantWizard",
            folder: "cayo.ui.wizards",
            onRefresh,
            reloadAfterClose: true,
          }),
        onAddDomain: () =>
          this.showControl({
            controlId: "NewManagedDomainWizard",
            folder: "cayo.ui.wizards",
            onRefresh,
            reloadAfterClose: true,
          }),
        onActivate: () => {
          if (this.useLegacyLicensing) {
            this.showControl({
              controlId: "ActivationWizard",
              folder: "cayo.ui.wizards",
              onRefresh,
              reloadAfterClose: true,
            });
          } else {
            actionHandlers["show.wizard"]("product-activation,reloadAfterClose");
          }
        },
        onConfigureDatabase: () =>
          appSignals.send("showClientWizard", {
            schemeId: "DatabaseConfigurationWizard",
            reloadAfterClose: false,
            parameters: {
              databaseSettings: this._storageSettings,
              onUseBuiltinDatabase: this.useBuiltinDatabase,
            },
          }),
        onConfigureLater: this.configureLater,
      } as configurationActions,
    };
  }
}

export default InitialConfigurationController;
