import { ITextField } from "cayo.ui";
import { IntlShape } from "react-intl";
import { CSSProperties } from "styled-components";
import { ContinuousSchedule } from "../../api/cayo-graph";
import { IFormField } from "../../api/schema.api";
import { nameofFactory } from "../../utils/object-utils";
import { formComponentTypes } from "../Form/renderers/form-renderers";
import messages from "./messages";

const continuousNames = nameofFactory<ContinuousSchedule>();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const continuousForm = (intl: IntlShape, schedule?: ContinuousSchedule) => {
  return {
    title: intl.formatMessage(messages.continuous),
    style: { textAlign: "left" } as CSSProperties,
    items: [
      {
        type: "text" as formComponentTypes,
        value: intl.formatMessage(messages.continuousDescription),
      } as any,
      {
        type: "number" as formComponentTypes,
        name: continuousNames("nextRunDelay"),
        value: schedule?.nextRunDelay,
        required: true,
        pattern:
          "^((3600)|(3[0-5][0-9]{2})|([1-2][0-9]{3})|([1-9][0-9]{2})|([1-9][0-9]{1})|([0-9]))$",
        suffix: intl.formatMessage(messages.secondsTip),
        label: "Next Run Delay",
      } as ITextField,
    ] as IFormField[],
  };
};

export default continuousForm;
