import { clipboardUtils as libClipboardUtils } from "cayo.ui";
import { IDetailsList } from "../api/schema.api";

const copyGridToClipboard = (grid: IDetailsList | undefined) => {
  const itemDelimeter = "\t";
  const rowDelimiter = "\r\n";
  const result = new Array<string>();

  if (grid && grid.items && grid.columns) {
    //set headers
    result.push(
      grid.columns
        .reduce((acc, c) => {
          acc.push(`${c.displayName!}`);
          return acc;
        }, new Array<string>())
        .join(itemDelimeter)
    );
    // rows
    const fields = grid.columns.map((c) => c.fieldName!);
    grid.items.forEach((row) => {
      result.push(
        fields
          .reduce((acc, field) => {
            acc.push(`${row[field]}`);
            return acc;
          }, new Array<string>())
          .join(itemDelimeter)
      );
    });
    libClipboardUtils.copyToClipboard(result.join(rowDelimiter));
  }
  libClipboardUtils.copyToClipboard("");
};

const clipboardUtils = { copyGridToClipboard, ...libClipboardUtils };

export default clipboardUtils;
