import { stringUtils } from "cayo.ui";
import React from "react";
import { Entity } from "../../api/cayo-graph";
import { IAdvancedObjectPicker, IColumn, IFormField, IObjectPicker } from "../../api/schema.api";

import logger from "../../libs/logger";
import { IExpressionAdvancedPicker } from "../GridContainer/grid-model/Query";
import AdvanceObjectPicker from "../InplaceAdvanceObjectPicker";
import InplaceObjectPicker from "../InplaceObjectPicker";
import InplaceTextField from "../InplaceTextField";
import { AuxRenderProps } from "../LegacyDetailsList/cell-renderers";
import { IPickerItem } from "../ObjectPicker/IPickerItem";
import { EntitiesToPickerItems } from "../ObjectPicker/logic";
import { formRenderers } from "../QueryFilterForm/renderers";

const log = logger.getLogger("QUICK-FILTER QuickFilterBar/renderers");

type ComponentType = "text" | "dateTimeRange" | "picker" | "advanceObjectPicker";

const renderComponent = (
  annotation: IFormField,
  value: any,
  setValue: (
    value: any,
    annotation: Omit<IFormField, "type">,
    expression?: IExpressionAdvancedPicker
  ) => void,
  setError: (error: string | undefined) => void,
  auxProps: AuxRenderProps
): JSX.Element => {
  log.debug("renderComponent -> ", { annotation, value, auxProps });
  const rendererName = detectComponentType(annotation);
  log.debug("renderComponent -> rendererName", rendererName);
  const renderer = renderers[rendererName];
  try {
    return (
      renderer && renderer(annotation, value, undefined, undefined, setValue, setError, auxProps)
    );
  } catch {
    return <React.Fragment />;
  }
};

const detectComponentType = (annotation: IFormField): ComponentType => {
  // TODO: remove this code and use new ui scheme
  if (annotation.type === "cayo.graph.queryInterval" || annotation.type === "date-time-range") {
    return "dateTimeRange";
  }

  const pickerAnnotation = annotation as IObjectPicker;
  if (pickerAnnotation?.isPicker) {
    return "picker";
  }

  if (annotation.type === "advanced-object-picker") {
    return "advanceObjectPicker";
  }

  return "text";
};

const picker = (
  annotation: IFormField,
  value: any,
  error: string | undefined,
  defaultValue: any,
  setValue: (value: any, annotation: Omit<IFormField, "type">) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setError: (error: string | undefined) => void,
  auxProps: AuxRenderProps
): JSX.Element => {
  const pickerAnnotation = annotation as IObjectPicker;
  const props: Omit<IObjectPicker, "type"> = {
    multiselect: true,
    label: stringUtils.toSpaceDelimitedPascalCase(annotation.label!),
    popupTitle: pickerAnnotation.popupTitle,
    queryPath: pickerAnnotation.queryPath,
    columns: pickerAnnotation.columns,
    popupCloseButtonName: pickerAnnotation.popupCloseButtonName,
    displayProperty: "",
    isPicker: pickerAnnotation.isPicker,
    title: pickerAnnotation.title,
  };

  return (
    <InplaceObjectPicker
      setError={() => null}
      setValue={setValue}
      key={annotation.name}
      onApply={auxProps.onApply}
      onPickerStateChanged={auxProps.onPickerStateChanged}
      value={
        EntitiesToPickerItems(
          value as Entity[],
          auxProps.getIconAnnotation,
          pickerAnnotation,
          auxProps?.possibleValues
        ) as IPickerItem[]
      }
      {...props}
      {...annotation}
      propertyAnnotations={auxProps.propertyAnnotations}
      targetType={auxProps.targetType}
      possibleValues={auxProps?.possibleValues}
    />
  );
};

const text = (
  annotation: IFormField,
  value: any,
  error: string | undefined,
  defaultValue: any,
  setValue: (value: any, annotation: Omit<IFormField, "type">) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setError: (error: string | undefined) => void,
  auxProps: AuxRenderProps
): JSX.Element => {
  return (
    <InplaceTextField
      setError={() => null}
      setValue={setValue}
      key={annotation.name}
      defaultValue={value}
      {...annotation}
      onApply={auxProps.onApply}
      onPickerStateChanged={auxProps.onPickerStateChanged}
    />
  );
};
const renderers = {
  picker,
  text,
  conditionBuilder: (
    c: IColumn[],
    fa: Array<IFormField & IFormField>,
    osc?: (isOpened: boolean) => void
  ) => formRenderers.conditionBuilder(c, fa, osc),
  dateTimeRange: (
    a: IFormField,
    v: any,
    e: any,
    d: any,
    sv: (value: any, annotation: IFormField) => void,
    se: (error: string | undefined) => void
  ) => formRenderers.dateTimeRange({ ...a, label: undefined }, v, e, d, sv, se),
  advanceObjectPicker: (
    a: IAdvancedObjectPicker,
    v: any,
    e: any,
    d: any,
    sv: (value: any, annotation: IFormField) => void,
    se: (error: string | undefined) => void,
    auxProps: AuxRenderProps
  ) => (
    <AdvanceObjectPicker
      annotation={a}
      filterValues={v}
      setValue={sv}
      setError={se}
      auxProps={auxProps}
    />
  ),
};

export default renderComponent;
