interface ILiteEvent<T> {
  off(handler: (data?: T) => void): void;
  on(handler: (data?: T) => void): () => void;
}

export default class LiteEvent<T> implements ILiteEvent<T> {
  private handlers: Array<(data?: T) => void> = [];

  public expose(): ILiteEvent<T> {
    return this;
  }

  public off(handler: (data?: T) => void): void {
    this.handlers = this.handlers.filter((h) => h !== handler);
  }

  public on(handler: (data?: T) => void): () => void {
    this.handlers.push(handler);
    return () => this.off(handler);
  }

  public trigger(data?: T) {
    this.handlers.slice(0).forEach((h) => h(data));
  }
}
