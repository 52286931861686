import { IFormModel } from ".";
import { Entity } from "../../../api/cayo-graph";
import { isObject } from "../../../types/type-guards";

export function normalizeModelForComplexType(data: Entity, model: IFormModel) {
  const result = {};

  for (const key in model) {
    if (Object.prototype.hasOwnProperty.call(model, key)) {
      const element = model[key];

      const hasODataProp = !!element["@odata.type"];

      if (isObject(element)) {
        result[key] = {
          // Complex properties must be fully overwritten!
          ...(hasODataProp ? {} : data[key]),
          ...element,
        };
      } else {
        result[key] = element;
      }
    }
  }

  return result;
}
