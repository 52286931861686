import {
  ChoiceGroup,
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogType,
  MessageBar,
  MessageBarType,
  Stack,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { LoadingOverlay, useTheme } from "cayo.ui";
import React, {
  CSSProperties,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { Schedule as BaseSchedule } from "../../api/cayo-graph";
import { HttpMethods } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import useService from "../../services/services.hook";
import { ajaxUtils } from "../../utils/ajax-utils";
import { IClientComponentParameters } from "../App/app.context";
import FormFooter from "../Form/FormFooter";
import useForm from "../Form/hooks/useForm";
import { FormProps } from "../Form/types";
import Icon from "../Graphics/Icon";
import { commonMessages } from "../common-messages";
import continuousForm from "./continuouslyForm";
import customForm from "./customForm";
import dailyForm from "./dailyForm";
import hourlyForm from "./hourlyForm";
import messages from "./messages";
import minutelyForm from "./minutelyForm";
import monthlyForm from "./monthlyForm";
import notSetForm from "./notSetForm";
import oneTimeForm from "./oneTimeForm";
import scheduleTypes from "./types";
import weeklyForm from "./weeklyForm";

type ScheduleProps = {
  url: string;
  method: HttpMethods;
  object?: BaseSchedule;
  isCustomView?: boolean;
  parameters?: string;
  style?: CSSProperties;
  parentObjectName?: string;
} & IClientComponentParameters;

const Schedule: FC<ScheduleProps> = ({
  url,
  method,
  onClose,
  isCustomView,
  style,
  parameters,
  parentObjectName,
  ...other
}) => {
  const intl = useIntl();
  const { cayoTheme } = useTheme();

  const [selectedSchedule, setSelectedSchedule] = useState<scheduleTypes>(
    (other?.object?.objectType as scheduleTypes) ||
      (isCustomView ? "cayo.graph.notSetSchedule" : "cayo.graph.oneTimeSchedule")
  );

  const [schedule, setSchedule] = useState({});
  const [switching, setSwitching] = useState(false);

  const actionBuilder = useService("actionBuilder");
  const [pastDateIsSelected, setPastDateIsSelected] = useState(false);

  logger.debug(other);
  const options = useMemo(() => {
    let allSchedules = Object.keys(scheduleMap);
    if (parameters?.length) {
      const parts = parameters.split("|");
      allSchedules = allSchedules.filter((s) => parts.findIndex((p) => s.indexOf(p) > 0) >= 0);
      const notSetSchedule = "cayo.graph.notSetSchedule" as scheduleTypes;
      if (!allSchedules.includes(notSetSchedule)) {
        allSchedules.unshift(notSetSchedule);
      }
    }

    return (
      allSchedules
        //.filter((k) => k !== "cayo.graph.minutelySchedule" && k !== "cayo.graph.hourlySchedule")
        .map<IChoiceGroupOption>((k) => ({
          key: k,
          text: scheduleMap[k](intl).title,
        }))
    );
  }, [parameters]);

  const realObject = useMemo(() => {
    let rObject = selectedSchedule === other?.object?.objectType ? other?.object : undefined;

    return schedule[selectedSchedule] || rObject;
  }, [selectedSchedule, other?.object, schedule]);

  const saveResponse = (response: { [key: string]: any }) => {
    if (response) {
      setSchedule({ [selectedSchedule]: response.schedule });
    }
  };

  const scheduleForm = useMemo<FormProps>(() => {
    const formProps = scheduleMap[selectedSchedule] as any;

    const hasChanges = selectedSchedule !== other?.object?.objectType;

    return {
      ...formProps(intl, realObject),
      submit: { url, method },
      "@odata.type": "#" + selectedSchedule,
      bodyProperty: "schedule",
      intl,
      actionBuilder,
      schedule: realObject,
      onClose,
      ...other,
      object: realObject,
      parentObjectName,
      isInsidePropPanel: true,
      hasChanges,
      sendAllFields: true,
      onSubmitCB: saveResponse,
    } as FormProps;
  }, [selectedSchedule, other?.object, realObject]);

  const { isSubmitting, isSaveDisabled, errors, renderer, onSubmit, message, model, clearErrors } =
    useForm(scheduleForm, realObject);

  useEffect(() => {
    if (selectedSchedule === "cayo.graph.oneTimeSchedule") {
      const currentDate = new Date();
      const inputDate = new Date(model.startDateTime);
      setPastDateIsSelected(inputDate < currentDate);
    } else {
      setPastDateIsSelected(false);
    }
  }, [selectedSchedule, model]);

  const onChange = useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
      clearErrors();
      if (option?.key) {
        setSelectedSchedule(option.key as scheduleTypes);
        setSwitching(true);
        setTimeout(() => setSwitching(false), 100);
      }
    },
    [clearErrors, selectedSchedule]
  );

  const deleteSchedule = useCallback(async () => {
    try {
      await ajax.getClient(url, "PATCH", undefined, { schedule: null });
      onClose && onClose();
    } catch (e) {
      await ajaxUtils.handleError(e);
    }
  }, []);

  const labelId: string = useId("dialogLabel");
  const subTextId: string = useId("subTextLabel");

  const modalProps = useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      styles: dialogStyles,
      dragOptions,
    }),
    [labelId, subTextId]
  );

  if (
    style?.display === "none" ||
    (!Object.keys(model)?.length && selectedSchedule !== "cayo.graph.notSetSchedule")
  ) {
    return <Fragment />;
  }

  const content = (
    <>
      {isSubmitting && <LoadingOverlay />}{" "}
      {message?.message && (
        <MessageBar
          styles={{ root: { display: "flex", marginBottom: 12 } }}
          messageBarType={MessageBarType[message?.type ?? "success"]}
          isMultiline={true}
          truncated={true}
        >
          {message?.message}
        </MessageBar>
      )}
      <Stack
        horizontal={true}
        verticalAlign={"stretch"}
        style={{ height: "100%", width: "100%" }}
        tokens={cayoTheme.metrics.stackTokens}
      >
        <ChoiceGroup
          defaultSelectedKey={selectedSchedule}
          options={options}
          onChange={onChange}
          style={{ minWidth: 130, borderRight: `1px solid ${cayoTheme.brandColors.divider}` }}
          styles={{ label: { whiteSpace: "nowrap", textOverflow: "ellipsis" } }}
        />

        <form
          key={selectedSchedule}
          onSubmit={onSubmit}
          noValidate={true}
          style={{
            display: "flex",

            width: "100%",
            flexFlow: "column",
            minHeight: 280,
            alignItems: "flex-start",
            paddingLeft: 8,
            ...(scheduleForm as any)?.style,
          }}
        >
          <Stack
            horizontalAlign="stretch"
            style={{ width: "100%" }}
            tokens={cayoTheme.metrics.stackTokens}
          >
            {switching ? null : renderer()}
          </Stack>
        </form>
      </Stack>
      <div style={{ position: "relative" }}>
        <FormFooter
          isOKDisabled={isSaveDisabled || pastDateIsSelected}
          errors={errors}
          onClose={() => onClose && onClose(false)}
          onSubmit={onSubmit}
          saveError={""}
          footer={{
            okText: intl.formatMessage(commonMessages.save),
            cancelText: isCustomView ? undefined : intl.formatMessage(commonMessages.cancel),
          }}
        />
        {!isCustomView && (
          <DefaultButton
            style={{ position: "absolute", left: 0, bottom: 0 }}
            onClick={deleteSchedule}
          >
            <Stack horizontal={true}>
              <Icon iconName="Calendar+Cancel" />
              {intl.formatMessage(commonMessages.remove)}
            </Stack>
          </DefaultButton>
        )}
      </div>
    </>
  );

  if (isCustomView) {
    return (
      <Stack
        style={{
          ...style,
          padding: "10px 20px 24px 20px",
        }}
      >
        {content}
      </Stack>
    );
  }

  return (
    <Dialog
      dialogContentProps={{
        type: DialogType.normal,
        title: intl.formatMessage(messages.scheduleDialogTitle),
        showCloseButton: true,

        styles: {
          subText: {
            marginTop: 8,
          },
          innerContent: { height: "100%", width: "100%" },
        },
      }}
      styles={{
        main: {
          minWidth: "600px !important",
        },
      }}
      hidden={false}
      modalProps={modalProps}
      onDismiss={() => {
        onClose && onClose(false);
      }}
    >
      {content}
    </Dialog>
  );
};

const dialogStyles = { main: { maxWidth: "460px !important" } };

const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
};

type getFormFuncType = typeof oneTimeForm;

const scheduleMap: { [key in scheduleTypes]: getFormFuncType } = {
  "cayo.graph.notSetSchedule": notSetForm,
  "cayo.graph.oneTimeSchedule": oneTimeForm,
  "cayo.graph.dailySchedule": dailyForm,
  "cayo.graph.weeklySchedule": weeklyForm,
  "cayo.graph.monthlySchedule": monthlyForm,

  "cayo.graph.hourlySchedule": hourlyForm,
  "cayo.graph.minutelySchedule": minutelyForm,

  "cayo.graph.continuousSchedule": continuousForm,
  "cayo.graph.customSchedule": customForm,
};

export default Schedule;
