import { Text } from "@fluentui/react";
import React from "react";
import { useIntl } from "react-intl";
import { IDatasetWidget } from "../../api/schema.api";
import ajax from "../../libs/ajax";
//import { isMedium } from "../../services/nav/nav-hooks";
import { LoadingOverlay, chartMessages } from "cayo.ui";
import Column from "../Layout/Column";
import Widget from "./Widget";
import { EmptyContainer } from "./common";
import useWidgetSignals from "./dataset-signals.hook";

const DatasetWidget = (props: IDatasetWidget) => {
  const intl = useIntl();
  const { data, isLoading, error, reloadData } = ajax.useGet(props.url, props);

  useWidgetSignals({
    signal: "refresh",
    handler: reloadData,
  });

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return (
      <Column style={{ flexGrow: 1, height: "100%" }}>
        <EmptyContainer style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
          {error && (
            <>
              <Text style={{ fontWeight: "bold" }}>
                {intl.formatMessage(chartMessages.loadDataError)}
              </Text>
              <Text variant="small">{error}</Text>
            </>
          )}
        </EmptyContainer>
      </Column>
    );
  }

  return <Widget {...props} data={data} onRefresh={reloadData} />;
};

export default DatasetWidget;
