import {
  CompactPeoplePicker,
  FocusZone,
  FocusZoneDirection,
  FocusZoneTabbableElements,
  IBasePickerSuggestionsProps,
  IPersonaProps,
  IPickerItemProps,
  ISuggestionItemProps,
  IconButton,
  Icon as OfficeIcon,
  Persona,
  PersonaSize,
} from "@fluentui/react";
import { ErrorRecord, Label, useTheme } from "cayo.ui";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Entity, Icon, PossibleValueItem, PropertyAnnotation } from "../../api/cayo-graph";
import { DepsType, IObjectPicker } from "../../api/schema.api";
import logger from "../../libs/logger";
import arrayUtils from "../../utils/array-utils";
import Error from "../Common/Error";
import { Column, Row } from "../CommonLayout";
import { IFormComponent } from "../QueryFilterForm/IFormComponent";
import { commonMessages } from "../common-messages";
import { IPickerItem } from "./IPickerItem";
import ObjectPickerModal from "./ObjectPickerModal";
import { fetchObjects, getTextFromItem, validateInput } from "./logic";
import oDataConverters from "./odata-converters";
import useScope from "./scopes.hook";

const log = logger.getLogger("ObjectPicker");
const ObjectPicker: FC<
  Omit<IObjectPicker, "type"> &
    IFormComponent & {
      value: IPickerItem[];
      hideMore?: boolean;
      showClearButton?: boolean;
      hideModificationTimeColumn?: boolean | undefined;
      hideObjectTypeIcon?: boolean | undefined;
      error?: ErrorRecord;
      possibleValues?: PossibleValueItem[];
      filterDependency?: DepsType;
      offBlurAction?: boolean;
    } & {
      getIconAnnotation: (type: string) => Icon;
      propertyAnnotations?: PropertyAnnotation[];
      targetType?: string;
    }
> = (props) => {
  useEffect(function mount() {
    log.debug(".mount", props);
  }, []);
  const { cayoTheme, officeTheme } = useTheme();
  const intl = useIntl();

  const [mostRecentlyUsed, setMostRecentlyUsed] = useState<IPickerItem[]>([]);
  const [showPopup, setShowPopup] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [lastInput, setLastInput] = useState("");
  const { getIconAnnotation, propertyAnnotations } = props;

  const picker = useRef(null);
  const {
    queryPath,
    scopes,
    isLoading: isScopeLoading,
    selectedScopeKey,
  } = useScope(props.queryPath, props.scope);

  const path = props.path ? "v0/" + props.path : undefined;

  const suggestionProps = useMemo<IBasePickerSuggestionsProps>(() => {
    return {
      noResultsFoundText: props.queryPath && intl.formatMessage(commonMessages.noResults),
      loadingText: intl.formatMessage(commonMessages.loading),
      showRemoveButtons: false,

      styles: () => {
        return {
          root: {
            maxWidth: maxSuggetionItemWidth + "px",
          },
        };
      },
    } as IBasePickerSuggestionsProps;
  }, []);

  const onFilterChanged = useCallback(
    (
      filterText: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      currentPersonas: IPickerItem[],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      limitResults?: number
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
      log.debug("onFilterChanged -> filterText", filterText);
      const actualQueryPath = path || queryPath;
      if (isLoading || isScopeLoading) {
        log.debug("onFilterChanged -> isLoading || isScopeLoading", isLoading || isScopeLoading);
        return [];
      }

      let selectPropertyName = props.selectPropertyName;
      let possibleValues: PossibleValueItem[] | undefined;
      if (!actualQueryPath) {
        log.debug("onFilterChanged loadPossibleValues");
        const suffix = "/" + props.name;
        const target = props.targetType + "/" + props.name;
        possibleValues =
          (
            propertyAnnotations?.find((a) => a.target === target) ||
            propertyAnnotations?.find((a) => a.target?.endsWith(suffix))
          )?.possibleValues ?? props.possibleValues;

        if (!possibleValues?.length) {
          log.debug("onFilterChanged: possibleValues is empty");
          return [];
        }
        // sort possibleValues by name for job type
        if (props.targetType === "cayo.graph.job" && props.name === "objectType") {
          possibleValues.sort(function (a, b) {
            const aName = a.name ?? "";
            const bName = b.name ?? "";
            if (aName > bName) return 1;
            if (aName < bName) return -1;
            return 0;
          });
        }
        selectPropertyName = "id";
        log.debug("onFilterChanged -> possibleValues", possibleValues);
      }

      const fetchFunc = actualQueryPath
        ? fetchObjects({
            path: actualQueryPath,
            searchText: filterText,
            orderBy: props?.defaultOrder,
            desc: undefined,
            filter: typeof props?.filter === "string" && !path ? props.filter : undefined,
            select: undefined,
            expand: undefined,
            additionalFilter:
              props.name === "nestedType" && filterText
                ? ` or contains(displayName,'${filterText}')`
                : "",
            body: props.body,
          })
        : Promise.resolve(
            possibleValues
              ?.map<Entity>((v) => ({
                objectName: v.name,
                id: v.value,
              }))
              .filter((pv) => {
                if (!filterText) {
                  return true;
                } else {
                  return pv.objectName
                    ?.toLocaleLowerCase()
                    .includes(filterText.toLocaleLowerCase());
                }
              })
          );

      return fetchFunc
        .then((response) => {
          if (response?.length) {
            let nonSelectedItems = props.value
              ? response.filter((r) => {
                  const rr = r as any;
                  const name = selectPropertyName ? rr[selectPropertyName!] : r.id;
                  if (!name) {
                    return false;
                  }

                  return props.value?.findIndex((p) => p.id === name) < 0;
                })
              : response;

            nonSelectedItems = props.filterDependency
              ? nonSelectedItems.filter((r) => {
                  let { dependentKey, deps } = props.filterDependency!;

                  return deps.includes(r[dependentKey]);
                })
              : nonSelectedItems;

            return nonSelectedItems.map<IPersonaProps>((v) => {
              const result: IPickerItem = {
                id: v.id!,
                text: (v as any).displayName || v.objectName,
                declaringType: v.declaringType,
                syntax: v.syntax,
                possibleValues: v.possibleValues,
                queryOperators: v.queryOperators,
                locationType: v.locationType,
                objectName: v.objectName,
                objectType: v.objectType,
                link: selectPropertyName
                  ? v[selectPropertyName]
                  : oDataConverters.entityToSoftLink(v),

                onRenderPersonaCoin: () => {
                  if (
                    v.objectType === "cayo.graph.objectTypeElement" ||
                    v.objectType === "cayo.graph.locationElement"
                  ) {
                    const item = v as any;
                    let propWithIcon: any = null;
                    if (item.locationType) {
                      propWithIcon = item.locationType;
                    } else if (v.objectName) {
                      propWithIcon = v.objectName;
                    }

                    const icon = getIconAnnotation(propWithIcon)?.iconId;
                    return icon ? <OfficeIcon iconName={icon} /> : null;
                  }

                  return v.objectType &&
                    !props.selectPropertyName &&
                    props.name === "managedSystemId" ? (
                    <OfficeIcon iconName={getIconAnnotation(v.objectType).iconId} />
                  ) : null;
                },
              };
              return result;
            });
          }

          return [];
        })
        .catch(() => {
          setIsLoading(false);

          return [];
        });
    },
    [scopes, selectedScopeKey, props.value, props.filterDependency]
  );

  const onRemoveSuggestion = (item: IPickerItem): void => {
    const indexPeopleList: number = props.value.indexOf(item);
    const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPickerItem[] = props.value
        .slice(0, indexPeopleList)
        .concat(props.value.slice(indexPeopleList + 1));
      props.setValue(newPeople, props);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople: IPickerItem[] = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }
  };

  const onRenderSuggestionsItem = (
    props: IPersonaProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemProps: ISuggestionItemProps<IPersonaProps>
  ): JSX.Element => {
    return (
      <div className={"ms-Button-flexContainer flexContainer_3696fcf9 flexContainer_9fba0d97"}>
        <div
          className={"ms-PeoplePicker-personaContent"}
          style={{ paddingLeft: 8, maxWidth: maxSuggetionItemWidth }}
        >
          <Persona {...props} size={PersonaSize.small} styles={{}} />
        </div>
      </div>
    );
  };

  const onRenderItem = (
    props: IPickerItemProps<IPersonaProps>,
    removeItem: (id: string) => void
  ): JSX.Element => {
    return (
      <FocusZone
        key={"fz_" + props.index}
        direction={FocusZoneDirection.horizontal}
        handleTabKey={FocusZoneTabbableElements.all}
        isCircularNavigation={true}
        allowFocusRoot={true}
        role={"button"}
        style={{ display: "flex", alignItems: "center", paddingLeft: 4, height: 30 }}
      >
        <div className={"ms-PickerItem-content itemContent_894c3072"}>
          <Persona
            {...props.item}
            size={PersonaSize.small}
            styles={{
              details: { padding: "0 0px 0 6px" },
              root: { height: "unset" },
              textContent: { fontWeight: 600 },
              primaryText: { maxWidth: 374 },
            }}
          />
        </div>
        <div className={"ms-PickerItem-content itemContent_894c3072"}>
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            styles={{ icon: { fontSize: 12, fontWeight: 600 }, root: { height: 28 } }}
            ariaLabel="Delete"
            onClick={() => {
              removeItem(props.item.id!);
            }}
          />
        </div>
      </FocusZone>
    );
  };

  const addItems = useCallback(
    (newItems: IPickerItem[]) => {
      let mergedItems = props.multiselect
        ? [...(props.value || []), ...(newItems || [])]
        : newItems;

      if (props.multiselect) {
        mergedItems = arrayUtils.unique<IPickerItem>(mergedItems);
      }

      props.setValue(mergedItems, props);
    },
    [props.value, props.setValue]
  );

  return (
    <Column>
      <Row style={{ width: "100%", alignItems: "flex-start" }} valign={true}>
        <Column style={{ width: "100%", alignItems: "flex-start", flexGrow: 1 }}>
          <Row style={{ width: "100%", alignItems: "flex-start" }} valign={true}>
            {props.label ? (
              <Label
                required={props.required}
                label={props.label!}
                style={{ flex: "1 0 auto" } as any}
                tooltip={props.tooltip}
              />
            ) : (
              <div style={{ flex: "1 0 auto" }} />
            )}
          </Row>

          <div style={{ position: "relative", width: "100%" }}>
            <CompactPeoplePicker
              onResolveSuggestions={(filter, selectedItems) =>
                onFilterChanged(filter, (selectedItems as IPickerItem[]) || [])
              }
              onEmptyResolveSuggestions={() => onFilterChanged("", [])}
              getTextFromItem={getTextFromItem}
              selectedItems={(() => props.value)()}
              pickerSuggestionsProps={suggestionProps}
              className={"ms-PeoplePicker"}
              styles={{
                itemsWrapper: {},
                text: {
                  paddingRight: 30,
                  border:
                    props.error?.errorMessage && `1px solid ${cayoTheme.brandColors.critical}`,
                },
              }}
              onRenderSuggestionsItem={onRenderSuggestionsItem}
              onRenderItem={(p) =>
                onRenderItem(p, (id) => {
                  const index = props?.value?.findIndex((p) => p.id === id);
                  if (index >= 0) {
                    props.value.splice(index, 1);

                    props.setValue([...props.value], props);
                  }
                })
              }
              onRemoveSuggestion={(item) => onRemoveSuggestion(item as IPickerItem)}
              onValidateInput={validateInput}
              inputProps={{
                "aria-label": props.label,
              }}
              componentRef={picker}
              resolveDelay={500}
              disabled={props.disabled}
              onInputChange={(input) => {
                setLastInput(input);
                return input;
              }}
              onBlur={(e) => {
                if (lastInput && !props.offBlurAction) {
                  log.debug(e.target);

                  const event = new Event("input", { bubbles: true });
                  setNativeValue(e.target, "");
                  e.target!.dispatchEvent(event);

                  const result: IPickerItem = {
                    id: lastInput,
                    text: lastInput,
                    link: lastInput,
                  };

                  addItems([result]);
                }
              }}
              onItemSelected={(item) => {
                const pickerItem = item as IPickerItem;
                const key = (item as any)?.key;

                if (key && !item!.id && !props.selectPropertyName && props.columns?.length) {
                  return null;
                }

                if (key) {
                  item!.id = key;
                }

                if (!pickerItem.link) {
                  pickerItem.link = key;
                  pickerItem.onRenderPersonaCoin = () => {
                    return <OfficeIcon iconName={"CodeEdit"} />;
                  };
                }

                addItems([item] as IPickerItem[]);
                return item as IPersonaProps;
              }}
            />

            {!props.hideMore && !(props.scope?.path && scopes?.length === 0) && (
              <IconButton
                style={{
                  position: "absolute",
                  right: 1,
                  bottom: -1,
                  height: 30,
                  top: -1,
                  opacity: 0.8,
                  marginTop: 2,
                  marginBottom: 2,
                  backgroundColor: cayoTheme.brandColors.secondaryBackground,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                disabled={props.disabled}
                onClick={() => setShowPopup(true)}
                iconProps={{ iconName: "More" }}
                data-testid="object-picker-more-btn"
              />
            )}
            {props.showClearButton && props.value?.length > 0 && (
              <IconButton
                style={{
                  position: "absolute",
                  right: 2,
                  bottom: -1,
                  height: 30,
                  top: 2,
                  opacity: 0.8,
                  marginTop: 0,
                  marginBottom: 0,
                  backgroundColor: "transparent",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                iconProps={{
                  iconName: "Cancel",
                }}
                styles={{ icon: { color: officeTheme.semanticColors.buttonText } }}
                onClick={() => {
                  props.setValue([], props);
                }}
              />
            )}
          </div>
        </Column>
      </Row>
      {props.error?.errorMessage && <Error>{props.error.errorMessage}</Error>}
      {showPopup && (
        <ObjectPickerModal
          id={props.name!}
          key={props.name!}
          columns={props.columns}
          closeButtonName={props.popupCloseButtonName!}
          title={props.popupTitle!}
          path={props.queryPath!}
          defaultOrder={props.defaultOrder}
          filter={props.filter}
          scope={props.scope}
          onClose={() => setShowPopup(false)}
          allowMultiselect={props.multiselect}
          hideModificationTimeColumn={props.hideModificationTimeColumn}
          hideObjectTypeIcon={props.hideObjectTypeIcon}
          onItemsSelected={(items) => {
            setShowPopup(false);
            const newItems = items.map<IPickerItem>((v) => {
              const result: IPickerItem = {
                id: v.id!,
                text: (v as any).displayName || v.objectName,
                link: props.selectPropertyName
                  ? v[props.selectPropertyName]
                  : oDataConverters.entityToSoftLink(v),
                onRenderPersonaCoin: () => {
                  return v.objectType &&
                    !props.selectPropertyName &&
                    props.name === "managedSystemId" ? (
                    <OfficeIcon iconName={getIconAnnotation(v.objectType).iconId} />
                  ) : null;
                },
              };
              return result;
            });

            addItems(newItems);

            return Promise.resolve();
          }}
        />
      )}
    </Column>
  );
};

// todo: move to domUtils
function setNativeValue(element: any, value: any) {
  const valueSetter = Object.getOwnPropertyDescriptor!(element, "value")!.set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, "value")!.set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter!.call(element, value);
  } else {
    valueSetter!.call(element, value);
  }
}
const maxSuggetionItemWidth = 350;

export default ObjectPicker;
