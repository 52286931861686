import { ITheme } from "cayo.ui";
import styled from "styled-components";

export const LayerDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) =>
    (props?.theme as ITheme)?.cayoTheme?.brandColors?.secondaryBackground || "#E0E0E0"};
  opacity: 0.5;
  z-index: 1000001;
  display: flex;
  align-items: center;
  justify-content: center;
`;
