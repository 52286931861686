import { IColumn as IOfficeColumn } from "@fluentui/react";
import { CommonConditions, QueryExpression } from "../api/cayo-graph";
import { IQuery } from "../components/GridContainer/grid-model/Query";
import logger, { dumpToJson } from "../libs/logger";
import { appSettings } from "../settings/app-settings";
import { StoredColumn } from "../settings/stored-column";

const log = logger.getLogger("grid-utils");

export const QueryColumnsRegex = /\w+_((added)|(removed))/;

export const columnsToHide = ["auditCategories", "modifiedPropertyNames", "nestedDisplayType"];

const fixDateTime = (items: any[]) => {
  for (const item of items) {
    for (const k of Object.getOwnPropertyNames(item)) {
      if (k.endsWith("DateTime")) {
        const date = item[k] ? new Date(item[k]) : undefined;
        item[k] = date;
      }
    }
  }
};

const saveGridColumns = (key: string, columns: IOfficeColumn[]) => {
  if (log.isDebugEnabled()) {
    log.debug("saveGridColumns", key, dumpToJson(columns));
  }
  const savingColumns = columns.map<StoredColumn>((c: IOfficeColumn) => {
    const ic: StoredColumn = {
      fieldName: c.fieldName,
      isSorted: c.isSorted,
      isSortedDescending: c.isSortedDescending,
      width: c.currentWidth,
    };

    return ic;
  });

  if (log.isDebugEnabled()) {
    log.debug("saveGridColumns", key, dumpToJson(columns), dumpToJson(savingColumns));
  }
  const settings = appSettings.viewSetting.getSubKey(key) || {}; // getViewSettings(key);
  settings.columns = savingColumns;
  appSettings.viewSetting.updateSubKey(key, settings);
};

const getStoredColumns = (key: string) => {
  let savedColumns: StoredColumn[];

  try {
    savedColumns = (appSettings.viewSetting.getSubKey(key) || {}).columns || [];
  } catch {
    savedColumns = [];
  }

  return savedColumns;
};

const restoreGridColumns = (key: string, columns: IOfficeColumn[]) => {
  const savedColumns: StoredColumn[] = getStoredColumns(key);

  if (savedColumns && savedColumns.length > 0) {
    if (savedColumns.findIndex((c) => c.isSorted !== undefined) >= 0) {
      columns.forEach((c) => {
        c.isSorted = undefined;
        c.isSortedDescending = undefined;
      });

      savedColumns.forEach((c) => {
        const existingColumn = columns.find((cc) => cc.fieldName === c.fieldName);
        if (existingColumn) {
          existingColumn.isSorted = c.isSorted;
          existingColumn.isSortedDescending = c.isSortedDescending;
        }
      });
    }

    savedColumns.forEach((c) => {
      const existingColumn = columns.find((cc) => cc.fieldName === c.fieldName);
      if (existingColumn && c.width) {
        existingColumn.currentWidth = c.width;
        existingColumn.calculatedWidth = c.width;
      }
    });
  }

  const savingColumns = columns.map<StoredColumn>((c: IOfficeColumn) => {
    const ic: StoredColumn = {
      fieldName: c.fieldName,
      isSorted: c.isSorted,
      isSortedDescending: c.isSortedDescending,
      width: c.calculatedWidth,
    };

    return ic;
  });
  if (log.isDebugEnabled()) {
    log.debug("restoreGridColumns", key, dumpToJson(columns), dumpToJson(savingColumns));
  }
  const settings = appSettings.viewSetting.getSubKey(key) || {};
  settings.columns = savingColumns;

  appSettings.viewSetting.updateSubKey(key, settings);
};
interface IFilterInfo {
  id?: string | undefined;
  filter?: string | undefined;
  name?: string | undefined;
  updateTime?: string | undefined;
  builtIn?: boolean | undefined;
}
export interface IAdvancedFilterInfo extends IFilterInfo {
  commonConditions?: CommonConditions;
  expression?: QueryExpression;
  clearFilter?: boolean | undefined;
  anrFilter?: string | undefined;
  advancedFilter?: string | undefined;
  default?: boolean | undefined;
  updateTime?: string;
}

const getFilter = (key: string): IAdvancedFilterInfo | undefined => {
  const settings = appSettings.viewSetting.getSubKey(key);
  // log.debug("quick-filter-bar getFilter -> key, settings", key, settings);
  return settings?.filter;
};

export function getQuery(key: string): IQuery | undefined {
  const settings = appSettings.viewSetting.getSubKey(key);
  return settings?.query;
}
export function saveQuery(key: string, query: IQuery): void {
  const settings = appSettings.viewSetting.getSubKey(key) || {};
  settings.query = query;
  appSettings.viewSetting.updateSubKey(key, settings);
}

export const gridUtils = {
  getStoredColumns,
  restoreGridColumns,
  saveGridColumns,

  getFilter,

  fixDateTime,
};
