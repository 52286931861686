import { INavLinkGroup } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IDropdownOption } from "../../api/schema.api";
import SearchNavController from "./search-nav-controller";

export const useNavTreeSearch = (
  groups: INavLinkGroup[],
  resolveName: (path: string) => string
) => {
  const [searchResult, setSearchResult] = useState<IDropdownOption[]>([]);
  const [searchText, setSearchText] = useState<string>();

  const searchController = useMemo(
    () => new SearchNavController(groups, resolveName),

    [groups]
  );

  const search = useCallback((text?: string) => {
    setSearchText(text);
  }, []);

  useEffect(() => {
    const result = searchController.find(searchText);
    setSearchResult(result);
  }, [searchText, searchController]);

  return { search, searchText, searchResult };
};
