import { OdataQuery } from "odata";
import ajax from "../../../libs/ajax";
import logger from "../../../libs/logger";
import { getErrorMessage } from "../../../libs/odata.client";
import { IQuery } from "../grid-model/Query";
import { IItemsStore, ISearchResult } from "./IItemsStore";

const { getClient } = ajax;

export class ItemsStore implements IItemsStore {
  readonly url: string;
  readonly initialOdataQuery: OdataQuery;
  private readonly _logger = logger.getLogger("QUICK-FILTER-NEW ItemsStore");
  private readonly _requiredProps: string[];

  private log(...msg: any[]) {
    this._logger.log(...msg);
  }

  constructor(itemsUrl: string, pageSize: number = 100) {
    this.log(".ctor -> itemsUrl", itemsUrl);
    const [path, search] = itemsUrl.split("?");
    this.url = path.replace(/^\//, "");
    const params = new URLSearchParams(search);
    this._requiredProps = params.get("$select")?.split(",") ?? [];
    const filter = params.get("$filter");
    this.initialOdataQuery = { $top: pageSize, ...(filter && { $filter: filter }) };
    this.log(".ctor -> odataQuery", this.initialOdataQuery);
  }

  private createQuery(
    query: IQuery,
    effectiveFilter: string,
    columns: string[],
    skipToken: string | null = null,
    top: number
  ): OdataQuery {
    const result = {
      ...this.initialOdataQuery,
      ...toOdataQuery(query),
      $select: this.make$select(columns),
      time: Date.now(),
    };
    if (!!effectiveFilter) {
      result.$filter = decodeURIComponent(effectiveFilter);
      if (this.initialOdataQuery.$filter) {
        result.$filter = `(${result.$filter}) and (${this.initialOdataQuery.$filter})`;
      }
    }
    if (!!skipToken) {
      result[skiptoken] = skipToken;
    }
    if (top > 0) {
      result.$top = Math.ceil(top / result.$top!) * result.$top!;
    }
    return result;
  }

  private make$select(properties: string[]): string {
    if (this._requiredProps.length === 1 && this._requiredProps[0] === "*") {
      return this._requiredProps[0];
    }
    return [...new Set([...this._requiredProps, ...properties])].join(",");
  }
  public async execute(
    query: IQuery,
    effectiveFilter: string,
    columns: string[],
    nextPageToken: string | null = null,
    top: number = 0
  ): Promise<ISearchResult> {
    // get effective filter
    try {
      const odq = this.createQuery(query, effectiveFilter, columns, nextPageToken, top);
      this.log("execute -> query, odataQuery:", query, odq);

      const data = await getClient(this.url, "GET", undefined, undefined, odq, { fragment: "" });
      const skipToken = this.getSkipToken(data[nextLink]);
      this.log("execute -> skipToken, data", nextPageToken, data);
      const items = data.value ?? [];

      return { items, skipToken };
    } catch (e) {
      const msg = await getErrorMessage(e);
      throw Error(msg);
    }
  }
  private getSkipToken(nextLink?: string): string | null {
    if (!nextLink) return null;
    const url = new URL(nextLink);
    return url.searchParams.get(skiptoken);
  }
}

const skiptoken = "$skiptoken";
const nextLink = "@odata.nextLink";

function toOdataQuery(query: IQuery): OdataQuery {
  const result: OdataQuery = {};
  if (query.orderBy) {
    result.$orderby = `${query.orderBy.field} ${
      query.orderBy.direction === "ascending" ? "asc" : "desc"
    }`;
  }
  return result;
}
