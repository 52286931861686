import { IUIItem } from "cayo.ui/lib/esm/interfaces/common/IUIItem";
import { IntlShape } from "react-intl";

import { ErrorRecord, IFormField, ITextField } from "cayo.ui";
import { Entity } from "../../api/cayo-graph";
import { IObjectPicker } from "../../api/schema.api";
import { objectUtils } from "../../utils/object-utils";
import { regexs } from "../../utils/regexs";
import { commonMessages } from "../common-messages";
import modelUtils from "./modelUtils";

type Field = string | { value?: any };

function extractValue(field: Field | undefined): string {
  if (typeof field === "string") {
    return field;
  }

  if (field && typeof field === "object" && "value" in field) {
    const value = field.value;
    return value !== undefined && value !== null ? String(value) : "";
  }

  return "";
}

const checkError = (
  annotation: IFormField,
  v: any,
  setError: (name: string, error?: ErrorRecord) => void,
  intl: IntlShape
) => {
  let errorMessage: string | undefined = undefined;
  let index: number | string | undefined = undefined;
  const hasValue = objectUtils.hasValue(v, annotation.required);
  const isPicker = (annotation as IObjectPicker).isPicker;
  const isMultiValue = Array.isArray(v) && !isPicker;

  if (hasValue && (typeof v === "string" || typeof v === "number")) {
    const value = v?.toString();
    const textAnnotation = annotation as ITextField;
    if (textAnnotation.pattern && !value.match(textAnnotation.pattern)) {
      errorMessage = intl.formatMessage(commonMessages.matchFormat);
      const eg = annotation.placeHolder?.match(regexs.afterEG);
      if (eg) {
        errorMessage += " " + eg[0];
      }
    }
  }

  if (!hasValue && annotation.required) {
    errorMessage = intl.formatMessage(commonMessages.inputRequired);
  } else if (isMultiValue) {
    const textAnnotation = annotation as ITextField;
    v.forEach((field, idx) => {
      const value = extractValue(field);
      if (annotation.required && !value) {
        errorMessage = intl.formatMessage(commonMessages.inputRequired);
        index = idx;
        return;
      }
      if (textAnnotation.pattern && !value.match(textAnnotation.pattern)) {
        errorMessage = intl.formatMessage(commonMessages.matchFormat);
        const eg = annotation.placeHolder?.match(regexs.afterEG);
        if (eg) {
          errorMessage += " " + eg[0];
        }
        index = idx;
        return;
      }
    });
  }

  setError(annotation.name!, errorMessage ? { index, errorMessage } : undefined);
  return !!errorMessage;
};

type FormItem = IUIItem & { modelProperty?: string };

const getName = (item: FormItem) => {
  return item.modelProperty || item.name;
};

const isComplexItem = (item: FormItem) => {
  return !!item.modelProperty;
};

const isPasswordSet = (annotation: ITextField, object?: Entity) => {
  const isPasswordSet =
    object &&
    modelUtils.resolvePropertyValue(`${formUtils.getName(annotation)}@confidential`, object) ===
      "present";

  return isPasswordSet;
};

export const formUtils = {
  checkError,
  getName,
  isComplexItem,
  isPasswordSet,
};
