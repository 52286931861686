import { useEffect, useMemo } from "react";

export type CommonSignals = "refresh";

export interface ISignalServiceBase<S, DT> {
  subscribe: (signal: S, handler: (data?: DT) => void, targetId?: string | undefined) => () => void;

  send: (signal: S, data?: DT, targetId?: string | undefined) => void;
}

export class SignalServiceBase<T, DT> implements ISignalServiceBase<T, DT> {
  private signalMap: { [key: string]: Array<(data?: DT) => void> } = {};

  private _uninit?: () => void;

  constructor(readonly _init?: () => () => void) {}

  private getKey = (signal: T, targetId?: string | undefined) =>
    (signal as unknown as string) + (targetId || "");

  public subscribe(signal: T, handler: (data?: DT) => void, targetId?: string | undefined) {
    const key = this.getKey(signal, targetId);
    const subscriptions = this.signalMap[key];
    const callInit = Object.keys(this.signalMap).length === 0;

    if (!subscriptions) {
      this.signalMap[key] = [];
    }

    this.signalMap[key].push(handler);

    if (this._init && callInit) {
      this._uninit = this._init();
    }

    return () => {
      this.unsubscribe(signal, handler, targetId);
    };
  }

  public send(signal: T, data?: DT, targetId?: string | undefined) {
    const key = this.getKey(signal, targetId);
    const subscriptions = this.signalMap[key];

    subscriptions?.forEach((s) => {
      s(data);
    });
  }

  private unsubscribe = (
    signal: T,
    handler: (data?: DT) => void,
    targetId?: string | undefined
  ) => {
    const key = this.getKey(signal, targetId);
    const subscriptions = this.signalMap[key];

    const existingSubscriptionIndex = subscriptions?.findIndex((h) => h === handler);

    if (existingSubscriptionIndex >= 0) {
      subscriptions.splice(existingSubscriptionIndex, 1);
      this.signalMap[key] = subscriptions;
    }

    if (this._uninit && Object.keys(this.signalMap).length === 0) {
      this._uninit();
    }
  };
}

function useSignals<T>(signalMaps: Array<{ s: T; handler: (data?: any) => void }>) {
  const signalService = useMemo<ISignalServiceBase<T, any>>(
    () => new SignalServiceBase<T, any>(),
    []
  );

  useEffect(() => {
    const unsubscribeHandlers = signalMaps.map((s) => signalService.subscribe(s.s, s.handler));
    return () => {
      unsubscribeHandlers.forEach((unint) => {
        unint();
      });
    };
  }, [signalService]);

  return signalService;
}

export default useSignals;
