import { Text } from "@fluentui/react";
import { ITheme } from "cayo.ui";
import styled from "styled-components";

export const WidgetTitle = styled(Text)`
  color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.disabled};
  font-weight: 500;
  flex-grow: 1;
`;

export const EmptyContainer = styled.div`
  color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.disabled};
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin: 0 auto;
  height: 100%;
  align-items: center;
  font-size: 13px;
  flex-grow: 1;
`;

export const BlankView = styled.div<{ showBorder?: boolean }>`
  align-items: center;
  color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.disabled};
  font-weight: 600;
  margin-top: ${(props) => !props.showBorder && "24px"};
  display: flex;
  justify-content: center;
  text-align: center;
  padding: ${(props) => (props.showBorder ? "20px" : "0")} 20px 20px 20px;
  height: 100%;
  border: ${(props) =>
    props.showBorder && `1px dashed ${(props.theme as ITheme).cayoTheme.brandColors.disabled}`};
`;
