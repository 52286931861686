import { defineMessages } from "react-intl";

export const messages = defineMessages({
  checkbox: {
    defaultMessage: "Adjust columns to show the values of the modified property",
  },

  operatorPlaceholder: {
    defaultMessage: "Choose an Operator",
  },

  valuePlaceholder: {
    defaultMessage: "Add a value",
  },

  reset: {
    defaultMessage: "Reset property value filters",
  },
});
