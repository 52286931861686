import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { appSettings } from "../settings/app-settings";
import ErrorModal from "./ErrorModal";
import { commonMessages } from "./common-messages";
import redirectUtils from "./routing/redirect-utils";

const LoginError: FC = () => {
  const intl = useIntl();

  const params = React.useMemo(() => {
    appSettings.userSetting.update({ authMode: undefined });

    const pos = window.location.hash.indexOf("?");
    const query = window.location.hash.substring(pos);

    const queryParams = new URLSearchParams(query);
    const account = queryParams.get("account");
    const message = queryParams.get("message");

    return { message, account };
  }, []);

  if (!params.account && !params.message) {
    return <Redirect to="/" />;
  }

  return (
    <LayerDiv>
      <ErrorModal
        showCloseAtBottom={true}
        title={params.account && intl.formatMessage(commonMessages.accessIsDenied)}
        message={{
          text: (params.account
            ? intl.formatMessage(commonMessages.accessIsDeniedMessage, {
                account: params.account,
              })
            : params.message) as string,
          severity: "error",
        }}
        onDismiss={() => {
          const redirectUrl = localStorage.getItem(redirectUtils.redirectParameterName);
          localStorage.removeItem(redirectUtils.redirectParameterName);

          window.location.assign(
            "#/login" +
              (redirectUrl ? `?${redirectUtils.redirectParameterName}=${redirectUrl}` : "")
          );
        }}
      />
    </LayerDiv>
  );
};

const LayerDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default LoginError;
