import { UserProfileClient } from "../../../api/user-profile.api";
import logger from "../../../libs/logger";
import { endpoints } from "../../../services/endpoints.service";
import { getQuery, saveQuery } from "../../../utils/grid-utils";
import { IQuery } from "../grid-model/Query";

type UserColumns = {
  columns: string[];
};
export class UserSettingsStore {
  private readonly _logger = logger.getLogger("QUICK-FILTER-NEW UserSettingsStore");

  private readonly _client: UserProfileClient<UserColumns>;
  private readonly _elementClass = "recentQueries";
  private readonly _name: string;

  private log(...msg: any[]) {
    this._logger.log(...msg);
  }
  constructor(private key: string) {
    this.log(".ctor -> key", key);
    this._name = key.replaceAll("/", "_");
    this._client = new UserProfileClient(endpoints.publicUrl);
  }

  public getQuery(): IQuery | undefined {
    const result = getQuery(this.key);
    this.log("getQuery -> key, result", this.key, result);
    return result;
  }
  public saveQuery(query: IQuery) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { effectiveFilter, alertRule, job, ...rest } = query;
    this.log("saveQuery -> key, query, save", this.key, query, rest);
    saveQuery(this.key, rest);
  }

  public async getColumns(): Promise<string[]> {
    try {
      const result = await this._client.getElement(this._elementClass, this._name);
      this.log("getColumns -> result", result);
      if (!result) {
        return [];
      }
      return result.columns;
    } catch (error: any) {
      if (error.status === 404) {
        this._logger.debug("getColumns -> error", error);
      } else {
        this._logger.error("getColumns -> error", error);
      }
    }
    return [];
  }

  public async saveColumns(columns: string[]): Promise<void> {
    this.log("saveColumns -> fields", columns);
    await this._client.saveElement(this._elementClass, this._name, { columns: columns });
  }
}
