/* eslint-disable no-unused-vars */
import { Stack } from "@fluentui/react";
import { themeUtils, useTheme } from "cayo.ui";
import React, { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import { IImage } from "../../api/schema.api";
import Icon from "./Icon";

const Image: FC<IImage> = (props) => {
  const { cayoTheme } = useTheme();
  const { width, height, url, fallbackIcon } = props;
  const [base64Image, setBase64Image] = useState<string>();

  useEffect(() => {
    const loadAsync = async () => {
      try {
        const imageData = await fetch(url!);
        const reader = new FileReader();
        reader.onload = (event: any) => {
          const result = event.target.result;
          setBase64Image(result);
        };
        const blob = await imageData.blob();
        if (blob?.size) {
          reader.readAsDataURL(blob);
        } else {
          setBase64Image("");
        }
      } catch (e) {
        setBase64Image("");
      }
    };

    loadAsync();
  }, [url]);

  const additionalStyle = useMemo<Partial<CSSProperties>>(
    () => (props.roundBorder ? themeUtils.getRoundBorderStyle(cayoTheme.brandColors) : {}),
    [props.roundBorder]
  );

  if (fallbackIcon && !base64Image) {
    return (
      <Stack
        verticalAlign={"center"}
        horizontalAlign={"center"}
        style={{ padding: 10, width, height, ...additionalStyle }}
      >
        <Icon {...fallbackIcon} />
      </Stack>
    );
  }

  return (
    <img
      src={fallbackIcon ? base64Image : props.url}
      alt=""
      width={width}
      height={height}
      style={additionalStyle}
    />
  );
};

export default Image;
