import { resolvePropertyValue, splitPropPath } from "./aux-props-utils";
import { buildModel, initPickers } from "./build-model";
import { getODataTypes } from "./field-odata-type-mapper";
import { getEditableItems, getModel, getVisibleItems } from "./get-model";
import { normalizeModelForComplexType } from "./model-normalizers"; 

export type IFormModel = { [key: string]: any };

const modelUtils = {
  buildModel,
  getModel,
  getEditableItems,
  initPickers,
  getVisibleItems,
  resolvePropertyValue,
  splitPropPath,
  normalizeModelForComplexType,
  getODataTypes
};

export default modelUtils;
