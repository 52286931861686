import {
  CalloutContent,
  IButtonStyles,
  IMessageBarStyles,
  IPanelStyles,
  MessageBarType,
} from "@fluentui/react";
import styled, { keyframes } from "styled-components";

import { ITheme, themeUtils } from "cayo.ui";

export const getMenuButtonStyles = (isSelected: boolean = false, width?: number) => {
  const style: IButtonStyles = {
    icon: {
      color: isSelected ? "#1c222a" : "white",
      fontSize: "16px",
    },
    label: {
      display: "none",
    },

    flexContainer: {
      justifyContent: "center",
    },

    root: {
      backgroundColor: isSelected ? "white" : "transparent",
      color: "white",
      fontSize: "10px",
      height: "65px",
      padding: "10px",
      borderRadius: 0,
      width: width || "50px",
    },
    rootHovered: {
      background: isSelected ? "white" : "#1c222a",
      color: "black",
    },
    iconHovered: {
      color: isSelected ? "#1c222a" : "white",
    },
    iconPressed: {
      color: isSelected ? "#1c222a" : "white",
    },
  };

  return style;
};

export const getAlertsPanelStyles = (theme: ITheme): Partial<IPanelStyles> => {
  return {
    content: {
      backgroundColor: `${theme.officeTheme.semanticColors.bodyStandoutBackground} !important`,
      paddingBottom: "0px !important",
      paddingLeft: "20px !important",
      paddingRight: "20px !important",

      marginTop: 0,
      overflowX: "hidden",
    },

    contentInner: {
      padding: "0 !important",
    },
    main: {
      backgroundColor: `${theme.officeTheme.semanticColors.bodyStandoutBackground} !important`,
      padding: "0 !important",
    },
    root: {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
    commands: {
      backgroundColor: theme.officeTheme.semanticColors.bodyStandoutBackground,
      zIndex: "5 !important",
    },
  };
};

export const alertDisappearEffect = keyframes`
  0% {
    right: 0%;
  }

  100% {
    right: -120%;
  }
`;

export const NewAlertItemContainer = styled.div<{}>`
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: pointer;
  padding-right: 4px !important;
  padding-left: 0px !important;
  max-height: 57px;
  height: 57px;
`;

export const NewAlertCallout = styled(CalloutContent)`
  box-shadow: rgba(0, 0, 0, 0.11) 0px 1.5px 0.9px, rgba(0, 0, 0, 0.133) 0px 2.6px 4.6px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.21) 0px 2.5px 0.9px, rgba(0, 0, 0, 0.233) 0px 3.6px 4.6px;
  }

  z-index: 1000;
`;

export const getMessageBarStyles = (
  showSlider: boolean,
  type: MessageBarType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  theme: ITheme
): Partial<IMessageBarStyles> => {
  const commonStyles: Partial<IMessageBarStyles> = {};

  const backgroundColor =
    type === 0 ? themeUtils.addAlpha(theme.cayoTheme.brandColors.primaryHighlight, 0.2) : undefined;
  commonStyles.root = { padding: 4, backgroundColor };
  // if (type === MessageBarType.info) {
  //   commonStyles.root.backgroundColor = theme.cayoTheme.brandColors.primaryHighlight;
  // }
  commonStyles.text = { fontWeight: "bold" };
  commonStyles.innerText = {
    selectors: { span: { maxWidth: "100%" } },
  };

  if (type === MessageBarType.error) {
    commonStyles.content = { alignItems: "center" };
  }

  commonStyles.iconContainer = { alignItems: "center" };
  if (showSlider) {
    commonStyles.iconContainer = { marginLeft: 52, ...commonStyles.iconContainer };
  }

  return commonStyles;
};
