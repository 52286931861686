import { getTheme, Icon, IStyle, mergeStyles } from "@fluentui/react";
import { Tooltip } from "cayo.ui";
import React, { Fragment, useMemo, useState } from "react";
import styled, { css, CSSProperties } from "styled-components";
import { FCC } from "../types/react";
import clipboardUtils from "../utils/clipboard-utils";
import { Column, MvPropValue, PropValue } from "./CommonLayout";
import CopyButton from "./Utilities/CopyButton";

export const CopyCellContainer: FCC<{
  onClick: () => void;
  multiLine?: boolean;
  showIntoButton?: boolean;
  style?: CSSProperties;
}> = ({ children, multiLine, onClick, style, showIntoButton }) => {
  const [copyBtnVisible, setCopyBtnVisible] = useState(false);

  const ContainerCss = useMemo(() => mergeStyles(style as unknown as IStyle), [style]);

  return (
    <StyledCopyCellContainer
      className={ContainerCss}
      multiLine={multiLine}
      onMouseEnter={() => setCopyBtnVisible(true)}
      onMouseLeave={() => {
        setCopyBtnVisible(false);
      }}
    >
      {children}
      {showIntoButton && (
        <Icon
          iconName="Info"
          styles={{
            root: {
              fontSize: 10,
              paddingRight: 6,
              paddingTop: 4,
              paddingLeft: 4,
              fontWeight: "bold",
            },
          }}
        />
      )}
      {copyBtnVisible && (
        <>
          <CopyButton isInsideCopyContainer={true} isAbsolutePosition={true} onClick={onClick} />
        </>
      )}
    </StyledCopyCellContainer>
  );
};

const StyledCopyCellContainer = styled.div<{ multiLine?: boolean | undefined }>`
  display: flex;
  align-items: center;

  .text-cell-nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  & > .copy-button {
    display: none;
  }
  &:hover {
    ${(props) =>
      !props.multiLine &&
      css`
        margin-right: 20px;
      `}
    .copy-button {
      display: block;
      ${(props) =>
        props.multiLine &&
        css`
          background-color: ${getTheme().semanticColors.listItemBackgroundHovered};
        `}
    }
  }
  .text-cell-wrap {
    width: 100%;
    white-space: normal;
  }
`;

const CellWithCopy: React.FC<{
  title?: string | undefined;
  tooltip?: string | undefined;
  value: string | string[];
  useMonoFont?: boolean | undefined;
  multiLine?: boolean | undefined;
  showInfoIcon?: boolean;
}> = ({ title, value, useMonoFont, multiLine, tooltip, showInfoIcon }) => {
  const isArray = Object.prototype.toString.call(value) === "[object Array]";
  return (
    <CopyCellContainer
      multiLine={multiLine}
      onClick={() => {
        clipboardUtils.copyToClipboard(isArray ? (value as []).join("\n") : (value as string));
      }}
      showIntoButton={showInfoIcon}
    >
      {isArray && (
        <Column className={"text-cell-wrap"}>
          {title && <label>{title}</label>}
          <MvPropValue>
            {(value as []).map((v, i) => {
              return (
                <Fragment key={i}>
                  {v}
                  <br />
                </Fragment>
              );
            })}
          </MvPropValue>
        </Column>
      )}
      {!isArray && (
        <div className={multiLine ? "text-cell-wrap" : "text-cell-nowrap"}>
          {title && <label>{title + ": "}</label>}
          {useMonoFont ? <PropValue> {value}</PropValue> : value}
          {tooltip && <Tooltip tooltip={tooltip} mini={true} />}
        </div>
      )}
    </CopyCellContainer>
  );
};

export default React.memo(CellWithCopy);
