import { Icon } from "@fluentui/react";
import { useTheme } from "cayo.ui";
import React, { FC } from "react";
import { CSSProperties } from "styled-components";

type MaskPosition = "topLeft" | "rightBottom" | "leftBottom";
interface MaskedIconProps {
  color?: string | undefined;
  maskIcon: string | JSX.Element;
  maskPosition?: MaskPosition;
  title?: string;
  children?: any;
  disabled?: boolean;
  margin?: string;
  fontSize?: number;
  style?: CSSProperties;
}

const MaskedIcon: FC<MaskedIconProps> = ({
  maskIcon,
  children,
  color,
  maskPosition,
  title,
  disabled,
  margin,
  style,
  fontSize,
}) => {
  const { cayoTheme, officeTheme } = useTheme();
  const isIconName = typeof maskIcon === "string";

  if (typeof maskIcon === "string" && maskIcon.indexOf(",") > 0) {
    const parts = maskIcon.split(",");
    maskIcon = parts[0];
    maskPosition = parts[1] as MaskPosition;
  }

  const pos = maskPosition || "topLeft";

  const smallContainerStyle: CSSProperties = {
    alignItems: "center",
    borderRadius: isIconName && pos !== "rightBottom" ? "50%" : undefined,

    display: "flex",
    justifyContent: "center",

    backgroundColor:
      isIconName && pos !== "rightBottom"
        ? disabled
          ? cayoTheme.brandColors.disabled
          : color || cayoTheme.brandColors.primaryHighlight
        : undefined,

    margin: "0 0 0 4px",

    height: 8,
    width: 8,

    left: pos === "topLeft" ? -8 : undefined,
    top: pos === "topLeft" ? -3 : undefined,

    right: pos === "rightBottom" ? -2 : undefined,
    bottom: pos === "rightBottom" ? -2 : undefined,

    position: "absolute",
  };

  if (pos === "leftBottom") {
    smallContainerStyle.left = -6;
    smallContainerStyle.bottom = -6;
    delete smallContainerStyle.right;
    delete smallContainerStyle.top;
    delete smallContainerStyle.backgroundColor;
  }

  return (
    <div
      style={{
        position: "relative",
        margin: margin ?? "0 4px 0 0",
        alignItems: "center",
        display: "flex",
        alignContent: "center",
        ...style,
      }}
      title={title}
    >
      {children}
      <div style={smallContainerStyle}>
        {!isIconName && maskIcon}
        {isIconName && (
          <Icon
            iconName={maskIcon as string}
            style={{
              color: disabled
                ? officeTheme.semanticColors.primaryButtonTextDisabled
                : color || cayoTheme.brandColors.primaryHighlight,

              fontSize: fontSize ?? "10px",
              fontWeight: "bold",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MaskedIcon;
