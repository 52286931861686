import {
  ColumnActionsMode,
  CommandBar,
  ConstrainMode,
  DetailsHeader,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  IObjectWithKey,
  MarqueeSelection,
  PrimaryButton,
  ScrollablePane,
  SearchBox,
  Selection,
  SelectionMode,
  Stack,
  Sticky,
  Text,
} from "@fluentui/react";
import { LoadingOverlay, debounce, stringUtils, useTheme } from "cayo.ui";
import React, { FC, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Entity } from "../../api/cayo-graph";
import { IColumnRenderOptions } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import { getError } from "../../libs/odata.client";
import { urlUtils } from "../../utils/url-utils";
import { appEvents } from "../App/app-events";
import { appUtils } from "../App/utils";
import { Row } from "../CommonLayout";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
import { useStatefulGrid } from "../GridContainer/hooks/useStatefulGrid";
import { renderItemColumn } from "../GridContainer/renderers/renderItemColumn";
import Panel from "../Panel/Panel";
import { commonMessages } from "../common-messages";
import IObjectPickerModal from "./IObjectPickerModal";
import objectPickerHooks from "./hooks";
import { fetchObjects } from "./logic";
import useScope from "./scopes.hook";
import { getDetailsListSyles } from "./style";

const modelReducer = (old: Entity[], newModel: Entity[]) => {
  return newModel;
};

const ObjectPickerModal: FC<IObjectPickerModal> = (props) => {
  const {
    id,
    path,
    filter,
    select,
    columns: columnProps,
    closeButtonName,
    allowMultiselect,
    title,
    onClose,
    onItemsSelected,
    actions,
    hideModificationTimeColumn,
    hideObjectTypeIcon,
  } = props;
  const intl = useIntl();
  const theme = useTheme();
  const [anrFilter, setAnrFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [updateTime, setUpdateTime] = useState(new Date());
  const [items, setItems] = useState<Entity[]>([]);
  const [selectedItems, setSelectedItems] = useReducer(modelReducer, []);
  const { getIconAnnotation, getTypeAnnotation } = useTypeAnnotations();
  const {
    queryPath,
    scopes,
    isLoading: isScopeLoading,
    selectedScopeKey,
    setSelectedScopeKey,
  } = useScope(path, props.scope);

  const schemeId = urlUtils.getSchemeId(path);
  const { isLoading: isAnnotationsLoading, data: propertyAnnotations } =
    ajax.usePropertyAnnotations(`v0/{schemeId}`, { schemeId });

  const selection = useMemo(() => {
    const newSelection: Selection = new Selection({
      getKey: (item: IObjectWithKey & { id: string }) => item["id"],
      onSelectionChanged: () => setSelectedItems(selection.getSelection() as Entity[]),
    });

    return newSelection;
  }, []);

  const onRefresh = useCallback(
    function onRefresh(createdItem?: Entity) {
      if (createdItem) {
        const newItems = [...items];
        newItems.unshift(createdItem);

        setItems(newItems);
        selection.selectToKey(createdItem.id!, true);
      } else {
        setUpdateTime(new Date());
      }
    },
    [items]
  );

  const commandBarButtons = objectPickerHooks.useCommandBar(
    actions,
    selectedItems,
    intl,
    onRefresh
  );

  const isSavedQuery = !columnProps?.length && path.indexOf("configuration/savedQueries") > 0;

  const defaultColumns = useMemo(() => {
    let initialColumns = [];

    if (columnProps?.length) {
      initialColumns = columnProps?.map((c) => {
        const columnActionsMode = c.columnActionsMode && ColumnActionsMode[c.columnActionsMode];
        return {
          fieldName: (c as any)?.fieldName || c.name!,
          key: (c as any)?.fieldName || c.name!,
          name: c.displayName ?? stringUtils.makePascal(c.name || (c as any).fieldName),
          minWidth: 200,
          maxWidth: 200,
          columnActionsMode:
            typeof columnActionsMode === "number"
              ? columnActionsMode
              : c.supportsSorting === false
              ? ColumnActionsMode.disabled
              : ColumnActionsMode.clickable,
          isResizable: true,
          noIcon: hideObjectTypeIcon,
          renderOptions:
            (c as any)?.renderOptions ||
            (c.formatValue ? ({ formatter: c.formatValue } as IColumnRenderOptions) : undefined),
        };
      });
    } else {
      initialColumns.push({
        fieldName: "name",
        key: "name",
        name: intl.formatMessage(messages.name),
        minWidth: 150,
        maxWidth: 150,
        columnActionsMode: ColumnActionsMode.clickable,
        isResizable: true,
        noIcon: hideObjectTypeIcon,
      } as any);
    }

    if (!hideModificationTimeColumn) {
      initialColumns.push({
        fieldName: "builtIn",
        key: "builtIn",
        name: intl.formatMessage(messages.system),
        minWidth: 70,
        maxWidth: 100,
        columnActionsMode: ColumnActionsMode.clickable,
        isResizable: true,
      });

      if (isSavedQuery) {
        initialColumns.push({
          fieldName: "createdBy",
          key: "createdBy",
          name: intl.formatMessage(messages.createdBy),
          minWidth: 100,
          maxWidth: 100,
          columnActionsMode: ColumnActionsMode.disabled,
          isResizable: true,
          noIcon: hideObjectTypeIcon,
        } as any);
      }

      initialColumns.push({
        fieldName: "lastModifiedDateTime",
        key: "lastModifiedDateTime",
        name: intl.formatMessage(messages.modificationTime),
        minWidth: 100,
        maxWidth: 200,
        columnActionsMode: ColumnActionsMode.clickable,
        isResizable: true,
      });
    }

    return initialColumns;
  }, [hideModificationTimeColumn]);

  const {
    sortBy,
    setSortBy,
    columns,
    clear: clearGridSetings,
  } = useStatefulGrid(id, defaultColumns);

  useEffect(() => {
    if (isScopeLoading || !queryPath) {
      return;
    }

    setIsLoading(true);
    fetchObjects({
      path: queryPath!,
      searchText: anrFilter,
      orderBy: props.defaultOrder || sortBy.fieldName,
      desc: sortBy.desc,
      filter: typeof filter === "string" ? filter : undefined,
      select,
      expand: isSavedQuery ? "createdBy" : undefined,
    })
      .then((response) => {
        logger.log(response);
        setItems(response);
      })
      .catch((e) => {
        logger.debug(e);
        clearGridSetings();
        setIsLoading(false);
        setItems([]);
        getError(e).catch((msg) => appUtils.showError(msg));
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [updateTime, sortBy, queryPath, isScopeLoading]);

  const onItemSelected = useCallback(() => {
    setIsLoading(true);
    onItemsSelected(selection.getSelection() as Entity[])
      .then(() => {
        onClose!();
      })
      .catch((e) => {
        return getError(e)
          .then((e) => appEvents.trigger({ showError: e }))
          .catch((err) => {
            appEvents.trigger({ showError: err });
            logger.error(err);
          });
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [selection]);

  return (
    <Panel
      isOpen={true}
      isBlocking={false}
      type={"medium"}
      hideCloseButton={true}
      onClose={onClose}
    >
      {(isLoading || isAnnotationsLoading) && <LoadingOverlay />}
      <Stack horizontal={false} style={{ height: "100%", width: "100%" }}>
        <Row
          valign={true}
          justifyContent="flex-start"
          style={{
            padding: "20px 40px 10px 20px",
            position: "relative",
            overflow: "hidden",
            width: "calc(100% - 70px)",
          }}
        >
          <Text variant="large">{title}</Text>
        </Row>
        <Stack
          horizontal={true}
          verticalAlign={scopes?.length ? "end" : "center"}
          style={{
            padding: "8px 10px ",
            margin: "10px 10px 0 10px",
          }}
        >
          <SearchBox
            styles={{ root: { minWidth: 230 } }}
            value={anrFilter}
            onSearch={(value) => {
              setAnrFilter(value);
            }}
            onChange={(value) => {
              setAnrFilter(value?.target.value || "");
              debounce(() => {
                setUpdateTime(new Date());
              }, 1000)();
            }}
            placeholder={intl.formatMessage(messages.searchByName)}
          />
          {!!scopes?.length && (
            <Dropdown
              selectedKey={selectedScopeKey}
              onChange={(e, o) => setSelectedScopeKey(o!.key as string)}
              options={scopes}
              label={props.scope?.name}
              style={{ minWidth: 170 }}
              styles={{
                dropdown: { marginLeft: 4 },
                label: { marginLeft: 4 },
                root: { width: "100%" },
              }}
            />
          )}
          <CommandBar
            items={commandBarButtons}
            style={{ flexGrow: 1, width: "50%" }}
            styles={{ root: { flexGrow: 1, width: "100%", paddingLeft: 8 } }}
          />
        </Stack>

        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",

            overflow: "auto hidden",
            // display: "flex",
            // flex: "1 1 auto",
          }}
        >
          <ScrollablePane
            scrollbarVisibility={"auto"}
            styles={{
              root: { stickyBelow: { display: "none" }, stickyBelowItems: { display: "none" } },
            }}
          >
            <MarqueeSelection selection={selection}>
              <DetailsList
                items={items}
                columns={columns}
                selection={selection}
                onItemInvoked={onItemSelected}
                selectionMode={
                  allowMultiselect === true ? SelectionMode.multiple : SelectionMode.single
                }
                onColumnHeaderClick={(e, c) => {
                  setSortBy({
                    fieldName: c!.fieldName!,
                    desc:
                      sortBy.fieldName === c?.fieldName
                        ? !c!.isSortedDescending
                        : c!.isSortedDescending,
                  });
                }}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onRenderDetailsHeader={(detailsHeaderProps, defaultRender) => {
                  return (
                    <Sticky>
                      <DetailsHeader
                        styles={{
                          root: {
                            paddingTop: 0,
                            selectors: {
                              ".ms-DetailsHeader-cellName": {
                                fontSize: 12,
                              },
                            },
                          },
                        }}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        {...detailsHeaderProps}
                      />
                    </Sticky>
                  );
                }}
                styles={() => getDetailsListSyles()}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                constrainMode={ConstrainMode.unconstrained}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                onRenderItemColumn={(item, index, column) =>
                  renderItemColumn(
                    item,
                    index!,
                    column,
                    (path?: string, query?: string, item?: any) => {
                      appEvents.trigger({
                        showControl: { controlId: "scheme", parameters: item + "/properties" },
                      });
                      logger.debug(path, query, item);
                      return Promise.resolve();
                    },
                    {
                      theme,
                      intl,
                      getIconAnnotation,
                      getTypeAnnotation,
                      propertyAnnotations,
                    }
                  )
                }
              />
            </MarqueeSelection>
          </ScrollablePane>
        </div>
        <Row
          style={{
            padding: 20,
            justifyContent: "flex-end",
            width: "100%",
            // position: "absolute",
            // bottom: 0,
            // left: 0,
          }}
        >
          <PrimaryButton onClick={onItemSelected}>
            {closeButtonName || intl.formatMessage(commonMessages.select)}
          </PrimaryButton>
        </Row>
      </Stack>
    </Panel>
  );
};

const messages = defineMessages({
  searchByName: { id: "object-picker-modal.search-by-name", defaultMessage: "Search" },
  modificationTime: {
    id: "object-picker-modal.columns.modification-time",
    defaultMessage: "Modification time",
  },
  name: { id: "object-picker-modal.columns.name", defaultMessage: "Name" },
  system: { id: "object-picker-modal.columns.builtIn", defaultMessage: "Built-in" },
  createdBy: { id: "object-picker-modal.columns.createdBy", defaultMessage: "Created by" },
});

export default ObjectPickerModal;
