import { Reducer } from "react";
import logger from "../../../libs/logger";
import { IPropertiesPanel } from "./types";

type actionType = "show" | "close" | "update" | "closeAll";
type panelAction = { type: actionType; panel?: IPropertiesPanel };
const log = logger.getLogger("panelReducer");

const panelReducer: Reducer<IPropertiesPanel[], panelAction> = (state, action) => {
  const existingPanel = state.find(
    (p) => p.parentId === action?.panel?.parentId && !action?.panel?.property
  );

  const updatePanel = () => {
    if (existingPanel) {
      if (action.panel!.id) {
        existingPanel.id = action.panel!.id!;
        existingPanel.key = new Date().toISOString();
        existingPanel.object = action.panel?.object;
        //state = [...state];
      }
    }
  };

  log.debug("action", action, "state", state);
  switch (action.type) {
    case "show":
      if (existingPanel) {
        const existingPanelIndex = state.findIndex((s) => s === existingPanel);
        if (existingPanelIndex > 0) {
          state.splice(existingPanelIndex, 1);
          state.push(existingPanel); // move to front
        }
      } else {
        state.push(action.panel!);
      }

      break;

    case "update":
      updatePanel();
      break;

    case "closeAll":
      state = [];
      break;

    default:
      if (state.length > 0) {
        if (!action.panel?.parentId) {
          const last = state.splice(state.length - 1);
          const onComplete = (last[0] as any).onComplete; // TEMP
          const onClose = (last[0] as any).onClose; // TEMP
          if (onComplete) {
            onComplete();
          } else if (onClose) {
            onClose();
          }
        } else if (existingPanel) {
          var newState = state.filter((p) => existingPanel.id !== p.id);
          if (newState.length !== state.length) {
            if (existingPanel.onClose) {
              existingPanel.onClose(existingPanel.object);
            }
          }
          state = newState;
        }
      }

      break;
  }

  return [...state];
};

export default panelReducer;
