import { ICommandBarItemProps } from "@fluentui/react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Entity } from "../../api/cayo-graph";
import { DispatchAction } from "../GridContainer/grid-model/grid.controller.actions";
import { commandBarMessages } from "./messages";

export const useFarItems = (
  resetButton: ICommandBarItemProps | null,
  showSelectionCounter?: boolean,
  dispatch?: DispatchAction,
  selectedItems?: Entity[]
) => {
  const intl = useIntl();
  const result = useMemo(() => {
    const items = [];
    if (resetButton) {
      items.push(resetButton);
    }

    if (showSelectionCounter && selectedItems?.length) {
      const selection: ICommandBarItemProps = {
        key: "sc" + commandBarMessages.selectedCount,
        text: intl.formatMessage(commandBarMessages.selectedCount, { count: selectedItems.length }),
        iconProps: { iconName: "Clear" },
        style: { fontWeight: "bold" },
        buttonStyles: {
          flexContainer: { flexDirection: "row-reverse" },
          label: { fontWeight: 500 },
        },
        onClick: dispatch
          ? () => {
              dispatch!({ kind: "changedSelectedItems", items: [] });
              return true;
            }
          : undefined,
      };

      //logger.debug(selection);
      items.push(selection);
    }

    return items;
  }, [resetButton, selectedItems]);

  return result;
};
