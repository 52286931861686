import { OdataConfig, OdataQuery } from "odata";
import { OHandler } from "odata/dist/types/OHandler";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Entity, PropertyAnnotation } from "../api/cayo-graph";
import { IDetailsList } from "../api/schema.api";
import { appEvents } from "../components/App/app-events";
import globalHooks from "../components/GlobalHooks";
import IAuxRenderParams from "../components/Schemes/AuxRenderProps";
import schemeParts from "../components/Schemes/scheme-parts";
import clientResolvers from "../scheme/transforms/client-vars-resolver";
import ODataFilterParser from "../scheme/transforms/odata-filter-parser";
import actionRegexes from "../scheme/actions/regexs";
import { bindingsUtils } from "../scheme/bindings";
import { endpoints } from "../services/endpoints.service";
import { INotificationMessage, NotificationAction } from "../services/notification.service";
import useService from "../services/services.hook";
import { ajaxUtils } from "../utils/ajax-utils";
import InteractiveList from "../utils/interactive-list";
import { objectUtils } from "../utils/object-utils";
import queryUtils from "../utils/query-utils";
import logger from "./logger";
import { oClient } from "./odata.client";

const log = logger.getLogger("ajax");

const getClient = (
  url: string,
  method?: string | undefined,
  bodyType?: string | undefined,
  body?: any,
  query?: OdataQuery,
  config: OdataConfig | any = {}
) => {
  if (!url) {
    throw new Error("Url is not specified.");
  }

  method = method?.toLowerCase() || "get";
  let handler: OHandler | null = null;

  switch (method) {
    case "get":
      handler = oClient(endpoints.publicUrl, config).get(url);
      break;

    case "delete":
      handler = oClient(endpoints.publicUrl).delete(url);
      break;

    case "post":
    case "put":
    case "patch": {
      handler = oClient(endpoints.publicUrl, {
        headers: {
          "Content-Type": bodyType || "application/json",
        },
      })[method](url, body) as OHandler;
      break;
    }

    default:
      throw new Error("Invalid http method");
  }

  return handler!.query(query);
};

const useFetch = <T = any>(
  url: string,
  method?: string | undefined,
  props?: any,
  bodyType?: string | undefined,
  body?: any
) => {
  const unmounted = globalHooks.useUnmounted();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<T>();

  const [error, setError] = useState<string | undefined>(undefined);
  const [updateTime, setUpdateTime] = useState(new Date());

  const { realUrl, deps } = useMemo(() => {
    let isResolved = !!url;
    const deps: string[] = [];
    var result = url?.replace(actionRegexes.insideCurlyBrackets, function (match, token) {
      let resolvedProp = props && props[token];
      if (resolvedProp === undefined) {
        resolvedProp = clientResolvers.resolve(token);
        if (!resolvedProp) {
          isResolved = false;
        }
      } else {
        deps.push(resolvedProp);
      }

      return resolvedProp;
    });

    return { realUrl: isResolved ? result : url, deps: deps.join("") };
  }, [props, url]);

  useEffect(() => {
    if (unmounted.current) {
      return;
    }

    if (!realUrl || realUrl.indexOf("//") !== -1 || realUrl.indexOf("{") !== -1) {
      setIsLoading(false);
      return;
    }

    const query = props?.query || queryUtils.getQuery(props as IDetailsList);

    setIsLoading(true);
    setError(undefined);

    ajax
      .getClient(realUrl, method, body, bodyType, query)
      .then((response) => {
        if (props?.compressResponse) {
          log.debug("type annotations loaded", response);
          log.debug("size of loaded annotations", objectUtils.getObjectSizeInBytes(response));

          response = objectUtils.removeNullAndEmptyProperties(response);

          log.debug("compressed type annotations", response);
          log.debug(
            "size of loaded annotations after compressing",
            objectUtils.getObjectSizeInBytes(response)
          );
        }

        response = bindingsUtils.resolvePropertyPath(response, props?.propertyPath);

        if (!unmounted.current) {
          setData(response);
        }
      })
      .catch((e) => {
        if (!e.status && e instanceof Error && e.stack) {
          log.debug(`Request failed: url=${realUrl} method=${method} `, e);
          appEvents.trigger({ serverError: { message: "", state: "none" } });
          return;
        }

        ajaxUtils.getError(e).then((ee) => {
          if (e === 404) {
            e = "Object not found";
          }
          log.debug(`Request failed: url=${realUrl} method=${method} `, ee);

          if (!unmounted.current) {
            setError(ee.error_description);
          }
        });
      })
      .then(() => {
        if (!unmounted.current) {
          setIsLoading(false);
        }
      });
  }, [realUrl, props?.propertyPath, updateTime, deps, (props as IAuxRenderParams)?.refreshKey]);

  return {
    data,
    isLoading,
    error,
    setData,
    updateTime,
    reloadData: () => {
      setData(undefined);
      setUpdateTime(new Date());
    },
  };
};

const useNotifications = (
  url: string,
  notificationSource?: string,
  props?: any,
  bodyType?: string | undefined
) => {
  const { data, isLoading, error, reloadData } = useFetch(url, "GET", props, bodyType);
  const log = props?.log;
  const notificationService = useService("notificationService");
  const [interactiveData, setInteractiveData] = useState<any[]>([]);

  const interactiveList = useMemo(() => new InteractiveList([]), []);
  const objectFilter = useMemo(() => {
    if (url.indexOf("contains(") > 0) {
      return null;
    }
    return new ODataFilterParser(url);
  }, [url]);

  const onMessages = useCallback((messages: INotificationMessage[]) => {
    try {
      const msgGroups = objectUtils.groupBy(messages, (j) => j.action);
      for (const group of msgGroups) {
        const newItems: Entity[] = [];
        const deletedItems: Entity[] = [];
        for (const message of group[1]) {
          const item = objectUtils.parseJsonAndFixDateTime(message.body) as Entity;

          if (
            message.action !== NotificationAction.Remove &&
            objectFilter !== null &&
            !objectFilter.parse(item)
          ) {
            continue;
          }

          log?.debug("onMessages -> new item", item, "action", message.action);

          switch (message.action) {
            case NotificationAction.Add:
            case NotificationAction.Modify:
              newItems.push(item);
              break;
            case NotificationAction.Remove:
              deletedItems.push(item);
              break;
          }
        }

        if (newItems.length) {
          interactiveList.push(newItems);
        }
        if (deletedItems.length) {
          interactiveList.delete(deletedItems);
        }

        setInteractiveData(interactiveList.Items);
      }
    } catch (e) {
      log?.error("onMessages:Error", e);
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      interactiveList.reset();
      setInteractiveData([]);
      return;
    }

    if (data?.length && interactiveList.Items.length === 0) {
      interactiveList.push(data);
      setInteractiveData(data);
    }

    return notificationSource
      ? notificationService.subscribe(notificationSource, onMessages)
      : undefined;
  }, [isLoading]);

  // useEffect(() => {
  //   data && interactiveList.push(data);
  // }, [data]);

  return { data: interactiveData, isLoading, error, reloadData };
};

const ajax = {
  getClient,
  fetchFn: (url: string) => getClient(url, "get"),
  useFetch,
  useNotifications,
  useGet: <T = any>(url: string, props?: any, bodyType?: string | undefined) =>
    useFetch<T>(url, "GET", props, bodyType),

  usePropertyAnnotations: (url: string, props?: any, bodyType?: string | undefined) => {
    return ajax.useGet<PropertyAnnotation[]>(
      `${url}/${schemeParts.paths.effectiveAnnotations}`,
      { ...props, compressResponse: true },
      bodyType
    );
  },
};

export default ajax;
