import {
  Checkbox,
  DateTimeBox,
  ErrorRecord,
  IDateTimeBox,
  IMultiCheckbox,
  MultiCheckbox,
  Text,
  TextField,
  Toggle,
  stringUtils,
} from "cayo.ui";
import React from "react";
import { IntlShape } from "react-intl";
import { AvailabilitySchedule, CustomInterval, Entity } from "../../api/cayo-graph";
import {
  ICheckbox,
  IColumn,
  IDropDown,
  IFormField,
  IInplaceAvailabilitySchedule,
  IObjectPicker,
  ITextField,
} from "../../api/schema.api";
import { dateRangeConverters } from "../../scheme/converters/date-range-converters";
import { regexs } from "../../utils/regexs";
import { InplaceConditionBuilder } from "../ConditionBuilder/ConditionBuilder";
import DropDown from "../DropDown";
import fieldTypes, { odataTypeToHtml } from "../Form/modelUtils/fieldTypes";
import TimeLimit from "../InplaceAvailabilitySchedule/TimeLimit";
import InplaceDateTimeRange from "../InplaceDateTimeRange";
import { AuxRenderProps } from "../LegacyDetailsList/cell-renderers";
import ObjectPicker from "../ObjectPicker";
import { IPickerItem } from "../ObjectPicker/IPickerItem";
import { EntitiesToPickerItems } from "../ObjectPicker/logic";
import { allRendereres } from "../Schemes/renderers";
import { commonMessages } from "../common-messages";

const renderComponent = (
  annotation: IFormField,
  value: any,
  error: string | undefined,
  disabled: boolean,
  setValue: (value: any, annotation: Omit<IFormField, "type">) => void,
  setError: (name: string, error?: ErrorRecord) => void,
  auxProps: AuxRenderProps
): JSX.Element => {
  const renderer = detectComponent(annotation);
  try {
    if (!(disabled && annotation.collapseIfDisabled))
      return (
        renderer &&
        renderer(annotation as any, value, error, disabled, setValue, setError, auxProps)
      );
  } catch {}

  return <React.Fragment />;
};

const textField = (
  annotation: ITextField,
  value: any,
  error: string | undefined,
  disabled: boolean | undefined,
  setValue: (value: any, annotation: IFormField) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setError: (name: string, error?: ErrorRecord) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auxProps: AuxRenderProps
): JSX.Element => {
  // const addProps = annotation.type === "password" ? { canRevealPassword: true } : {};

  const type = odataTypeToHtml(annotation);
  let realValue = annotation.disabled ? value : disabled ? "" : value?.toString() || "";
  //HACK: show value for advancedFilter
  if (annotation.name === "advancedFilter") {
    realValue = value?.toString() || "";
  }

  return (
    <TextField
      key={annotation.name}
      type={type}
      value={realValue}
      errorMessage={disabled ? undefined : error}
      pattern={annotation.pattern}
      required={annotation.required}
      placeHolder={disabled ? undefined : annotation.placeHolder}
      disabled={annotation.disabled || disabled}
      multiline={annotation.isMultiline}
      autoFocus={annotation.autoFocus}
      tooltip={annotation.tooltip}
      hidden={annotation.hidden}
      isConfidential={annotation.isConfidential}
      copyEnabled={annotation.copyEnabled}
      collapseIfDisabled={annotation.hidden}
      //  style={(annotation as any)?.style}
      setValue={(v) => {
        setValue(v, annotation);
        if (error) {
          checkError(annotation, v as string, setError, auxProps.intl);
        }
      }}
      onBlur={() => {
        // checkError(annotation, value, setError, intl);
      }}
      label={
        annotation.label !== ""
          ? annotation.label || stringUtils.toSpaceDelimitedPascalCase(annotation.name!)
          : annotation.label
      }
      //tip={annotation.tip}
    />
  );
};

const timelimit = (
  annotation: ITextField,
  value: any,
  error: string | undefined,
  disabled: boolean | undefined,
  setValue: (value: any, annotation: Omit<IFormField, "type">) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setError: (name: string, error?: ErrorRecord) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auxProps: AuxRenderProps
): JSX.Element => {
  const props = annotation as IInplaceAvailabilitySchedule;
  return (
    <TimeLimit
      name={annotation.name!}
      value={value as unknown as AvailabilitySchedule[]}
      setValue={setValue}
      serverTimeZone={props.serverTimeZone}
    />
  );
};

const text = (annotation: ITextField): JSX.Element => {
  return <Text key={annotation.name || annotation.value} block={true} value={annotation.value} />;
};

const checkbox = (
  annotation: IFormField,
  value: any,
  error: string | undefined,
  disabled: boolean,
  setValue: (value: any, annotation: IFormField) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setError: (name: string, error?: ErrorRecord) => void
): JSX.Element => {
  const checkBoxAnnotation = annotation as ICheckbox;

  const Comp = checkBoxAnnotation.onText ? Toggle : Checkbox;
  return (
    <Comp
      key={annotation.name}
      value={!!value}
      tooltip={annotation.tooltip}
      disabled={disabled}
      note={annotation.note}
      setValue={(v) => setValue(v, annotation)}
      // onBlur={(e) => setValue(e.currentTarget.value, annotation)}

      label={
        annotation.label === ""
          ? ""
          : annotation.label || stringUtils.toSpaceDelimitedPascalCase(annotation.name!)
      }
      onText={checkBoxAnnotation.onText}
      offText={checkBoxAnnotation.offText}
    />
  );
};

const multicheckbox = (
  annotation: IFormField,
  value: any,
  error: string | undefined,
  disabled: boolean,
  setValue: (value: any, annotation: IFormField) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setError: (name: string, error?: ErrorRecord) => void
): JSX.Element => {
  const multiCheckboxAnnotation = annotation as IMultiCheckbox;
  return (
    <MultiCheckbox
      key={annotation.name}
      value={value}
      tooltip={annotation.tooltip}
      disabled={disabled}
      setValue={(v) => setValue(v, annotation)}
      // onBlur={(e) => setValue(e.currentTarget.value, annotation)}
      label={annotation.label || stringUtils.toSpaceDelimitedPascalCase(annotation.name!)}
      possibleValues={multiCheckboxAnnotation.possibleValues}
      required={annotation.required}
      horizontal={annotation.required}
    />
  );
};

const picker = (
  annotation: IFormField,
  value: any,
  error: string | undefined,
  disabled: boolean,
  setValue: (value: any, annotation: Omit<IFormField, "type">) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setError: (name: string, error?: ErrorRecord) => void,
  auxProps: AuxRenderProps
): JSX.Element => {
  const pickerAnnotation = annotation as IObjectPicker;
  const props: Omit<IObjectPicker, "type"> = {
    multiselect: pickerAnnotation.multiselect,
    label: stringUtils.toSpaceDelimitedPascalCase(annotation.label!),
    popupTitle: pickerAnnotation.popupTitle,
    queryPath: pickerAnnotation.queryPath,
    columns: pickerAnnotation.columns,
    popupCloseButtonName: pickerAnnotation.popupCloseButtonName,
    displayProperty: "",
    isPicker: pickerAnnotation.isPicker,
    dependsFrom: pickerAnnotation.dependsFrom,
    disabled,
    defaultValue: pickerAnnotation.defaultValue,
    title: pickerAnnotation.title,
  };
  const isSimplePicker = pickerAnnotation.isPicker && !pickerAnnotation?.columns?.length;

  // eslint-disable-next-line no-unused-vars

  return (
    <ObjectPicker
      setError={() => null}
      error={error}
      setValue={(v, a) => {
        setValue(v, a);

        if (error) {
          checkError(a, v, setError, auxProps.intl);
        }
      }}
      key={annotation.name}
      hideModificationTimeColumn={true}
      hideObjectTypeIcon={
        annotation.name !== "managedSystemId" && annotation.name !== "targetObjectLocations"
      }
      hideMore={isSimplePicker}
      getIconAnnotation={auxProps.getIconAnnotation}
      value={
        EntitiesToPickerItems(
          value as Entity[],
          auxProps.getIconAnnotation,
          pickerAnnotation,
          auxProps?.possibleValues
        ) as IPickerItem[]
      }
      {...annotation}
      {...props}
      intl={auxProps.intl}
      {...(auxProps.theme as any)}
      possibleValues={auxProps?.possibleValues}
      defaultValue={pickerAnnotation.defaultValue}
    />
  );
};

const conditionBuilder = (
  columns: IColumn[],
  filterAnnotations: Array<IFormField & IFormField>,
  onPickerStateChanged?: (isOpened: boolean) => void
): JSX.Element => {
  return (
    <InplaceConditionBuilder
      columns={columns}
      filterAnnotations={filterAnnotations}
      onPickerStateChanged={onPickerStateChanged}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dateTimeRange = (
  annotation: IFormField,
  value: any,
  error: string | undefined,
  disabled: boolean,
  setValue: (value: any, annotation: IFormField) => void,
  setError: (name: string, error?: ErrorRecord) => void
): JSX.Element => {
  const interval = (value || dateRangeConverters.fromPredefinedRange("0")) as CustomInterval;

  const predefinedRange = interval?.startDateTime
    ? "Custom"
    : dateRangeConverters.fromPredefinedType(interval);

  const begin = interval?.startDateTime ? new Date(interval.startDateTime) : undefined;
  const end = interval?.endDateTime ? new Date(interval.endDateTime) : undefined;

  return (
    <InplaceDateTimeRange
      {...(annotation as any)} // TODO:
      disabled={disabled}
      begin={begin}
      end={end}
      predefinedRange={predefinedRange || "0"}
      key={annotation.name}
      setValue={setValue}
      setError={setError}
    />
  );
};

const dropdown = (
  annotation: IFormField,
  value: any,
  error: string | undefined,
  disabled: boolean,
  setValue: (value: any, annotation: IFormField) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setError: (name: string, error?: ErrorRecord) => void,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auxProps: AuxRenderProps
): JSX.Element => {
  return (
    <DropDown
      selectedKey={value}
      disabled={disabled}
      onChange={(e, v) => setValue(v?.key, annotation)}
      // onBlur={(e) => setValue(e.currentTarget.value, annotation)}
      label={
        annotation.label === ""
          ? ""
          : annotation.label || stringUtils.toSpaceDelimitedPascalCase(annotation.name!)
      }
      options={(annotation as IDropDown).options}
      required={annotation?.required}
      tooltip={annotation?.tooltip}
      style={(annotation as any)?.style}
      note={annotation.note}
    />
  );
};

const datetimebox = (
  annotation: IDateTimeBox,
  value: any,
  error: string | undefined,
  disabled: boolean,
  setValue: (value: any, annotation: IFormField) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setError: (name: string, error?: ErrorRecord) => void,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auxProps: AuxRenderProps
): JSX.Element => {
  return (
    <DateTimeBox
      key={annotation.name}
      setError={setError}
      setValue={(v) => setValue(v, annotation)}
      editMode={annotation.editMode}
      label={annotation.label!}
      value={value}
      disabled={disabled}
      {...annotation}
    />
  );
};

// const monthDay = (
//   annotation: ICustomFieldAnnotation,
//   value: any,
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   error: string | undefined,
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   disabled: boolean | undefined,
//   setValue: (value: any, annotation: ICustomFieldAnnotation) => void,
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   setError: (error: string | undefined) => void,
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   auxProps: AuxRenderProps
// ): JSX.Element => {
//   return <MonthDay monthDay={value} setValue={(v) => setValue(v, annotation)} />;
// };

export const formRenderers = {
  textField,
  picker,
  dateTimeRange,
  conditionBuilder,
  checkbox,
  dropdown,
  datetimebox,
};

const checkError = (
  annotation: Omit<ITextField, "type">,
  v: any,
  setError: (name: string, error?: ErrorRecord) => void,
  intl: IntlShape
) => {
  let errorMessage: string | undefined = undefined;
  const hasValue = Array.isArray(v) ? v.length : !!v;
  const value = v as string;
  if (hasValue) {
    if (annotation.pattern && !value?.toString().match(annotation.pattern)) {
      errorMessage = intl.formatMessage(commonMessages.matchFormat);
      const eg = annotation.placeHolder?.match(regexs.afterEG);
      if (eg) {
        errorMessage += " " + eg[0];
      }
    }
  } else if (annotation.required) {
    errorMessage = intl.formatMessage(commonMessages.inputRequired);
  }

  setError(annotation.name!, errorMessage ? { errorMessage } : undefined);
  return !!errorMessage;
};

const controlsMap = {
  [fieldTypes.boolean]: checkbox,
  [fieldTypes.dateTime]: datetimebox,
  [fieldTypes.dateTimeOffset]: datetimebox,
  [fieldTypes.enum]: dropdown,
  [fieldTypes.enumCollection]: multicheckbox,
  [fieldTypes.string]: textField,
  [fieldTypes.int32]: textField,
  text: text,
  timelimit: timelimit,
  //[fieldTypes.monthDay]: monthDay,
};

const detectComponent = (annotation: IFormField) => {
  if (annotation.type === "cayo.graph.queryInterval" || annotation.type === "date-time-range") {
    return dateTimeRange;
  }

  const pickerAnnotation = annotation as IObjectPicker;
  if (pickerAnnotation?.isPicker) {
    return picker;
  }

  const result = controlsMap[annotation.type!] || allRendereres[annotation.type!] || textField;
  return result;
};
export default renderComponent;
