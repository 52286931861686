import React from "react";
import { FCC } from "../../../types/react";
import StyledThumb from "./StyledThumb";
import useScrollResizer from "./hooks";

const Resizer: FCC<{
  onWidthChanged: (width: number, isFullScreen: boolean) => void;
}> = (props) => {
  const { isHovered, resizingTargetRef, ...mouseEvents } = useScrollResizer(props.onWidthChanged);

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <StyledThumb isHovered={isHovered} {...mouseEvents} />
      <div style={{ display: "flex", height: "100%" }} ref={resizingTargetRef}>
        {props.children}
      </div>
    </div>
  );
};

export default Resizer;
