import { ActionButton, MessageBarType } from "@fluentui/react";
import { Html, MessageBarTypeExtended, htmlUtils, useComponentLogger, useTheme } from "cayo.ui";
import React, { FC, Fragment, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { FormattedMessage } from "react-intl";
import { IStaticAlerts } from "../../../api/schema.api";
import logger from "../../../libs/logger";
import { endpoints } from "../../../services/endpoints.service";
import { NotificationAction } from "../../../services/notification.service";
import { regexs } from "../../../utils/regexs";
import { animation, animationEffects } from "../../Common/Effects";
import { Row } from "../../CommonLayout";
import Actions from "../Actions";
import { alertDispatcher } from "../AlertDispatcher";
import { getMessageBarStyles } from "../Alerts.styles";
import InitialConfigAlert from "../InitialConfigAlert";
import LegacyActionButton from "../LegacyActionButton";
import severityToMessageBarType from "../MessageCarousel/logic";
import AlertsService, { IAlertItem } from "../alerts.api";
import { useInitialConfigAlert } from "../initial-config-alert.hook";
import { alertsReducer } from "../static-alerts.reducer";
import {
  MessageWrapper,
  NextLink,
  NextWrapper,
  StaticAlertsContainer,
  StyledMessageBar,
} from "./styles";

const StaticAlerts: FC<IStaticAlerts> = (props) => {
  const [alerts, setAlerts] = useReducer(alertsReducer, []);

  const theme = useTheme();
  const log = useComponentLogger(StaticAlerts);

  const { canDismissSystemAlerts } = props;
  const [animationEffect, setAnimationEffect] = useState<animation | undefined>(undefined);
  const [counter, updateCounter] = useState(0);
  const [updateTime, setUpdateTime] = useState<Date | undefined>(undefined);

  const { intialConfigAlert } = useInitialConfigAlert();

  const [alertIndex, setAlertIndex] = useState(0);
  const cachedService = useMemo(
    () =>
      new AlertsService(
        endpoints.publicUrl,
        props.alertsUrl!,
        props.alertsUrl!,
        props.dismissAlertsUrl!
      ),
    []
  );

  useEffect(() => {
    const onNewAlerts = (action: NotificationAction, newAlerts: IAlertItem[] | undefined) => {
      log.debug("alerts arrived", newAlerts);

      let result = alerts;
      if (newAlerts) {
        if (NotificationAction[action] === "Remove") {
          result = alerts.filter((a) => newAlerts.find((n) => n.id !== a.id!));
        } else {
          for (const newAlert of newAlerts) {
            if (newAlert.status === "dismissed") {
              const deletingAlertIndex = result?.findIndex((a) => a.id === newAlert.id);
              if (deletingAlertIndex >= 0) {
                result.splice(deletingAlertIndex, 1);
              }
            } else {
              result = result.filter((i) => i.id !== newAlert.id);
              result.push(newAlert);
            }
          }
        }

        setAlerts([...result]);
        if (result.length) {
          setAlertIndex(0);
        }
      }
    };

    if (intialConfigAlert) {
      return;
    }

    return alertDispatcher.subscribe("staticAlerts", (data) => {
      if (!data) {
        setUpdateTime(new Date());
        return;
      }

      onNewAlerts(data.action!, data?.newItems as IAlertItem[]);
    });
  }, [alerts, intialConfigAlert]);

  useEffect(() => {
    if (intialConfigAlert) {
      return;
    }

    setTimeout(
      () =>
        cachedService.getNewAlerts().then((alerts) => {
          setAlerts(alerts.alerts);
        }),
      1000
    );
  }, [updateTime, intialConfigAlert]);

  const showDismissButton = useMemo(() => {
    return (cachedService.dismissAlertUrl && alerts?.find((a) => a.dismissable)) || false;
  }, [alerts, cachedService?.dismissAlertUrl]);

  const alertInfo = useMemo(() => {
    const hasAlerts = alerts && alerts.length > 0;
    const alert = hasAlerts && alerts![alertIndex % alerts!.length];
    if (!alert && hasAlerts) {
      setAlertIndex(0);
      return;
    }
    let type: MessageBarTypeExtended | undefined;
    if (alert && alert.status === "new") {
      type = severityToMessageBarType(alert.severity);

      const urlMatches = alert.action && regexs.hrefRegex.exec(alert.action);
      const typeMatches = alert.action && regexs.typeRegex.exec(alert.action);
      const urlBodyMatches = alert.action && regexs.hrefBodyRegex.exec(alert.action);

      let action = "";
      let url = "";
      let isStream = false;
      let urlName = "";
      if (urlMatches && urlMatches.length > 0) {
        url = urlMatches[1];
      }

      if (typeMatches && typeMatches.length > 0) {
        isStream = typeMatches[1] === mediaTypes.octetStream;
      }

      if (urlBodyMatches && urlBodyMatches.length > 1) {
        urlName = urlBodyMatches[1];
      }
      let hash: string | undefined;

      try {
        if (url) {
          hash = new URL(url).hash;
        }
      } catch (e) {
        logger.log(e);
      }

      if (hash) {
        url = "";
        const actionMatches = alert.action && regexs.actionRegex.exec(hash);
        action = (actionMatches && actionMatches.length > 1 && actionMatches[1]) || "";

        if (!action) {
          url = hash;
        }
      }

      return {
        type,
        isVisible: true,
        title: htmlUtils.sanitize(alert.subject),
        actionName: urlName,
        url,
        action,
        dismissable: alert.dismissable,
        isStream,
        actions: alert.actions,
        ownerId: alert.ownerId,
      };
    }

    return { type: undefined };
  }, [alerts, alertIndex]);

  const slideAlert = useCallback(
    (forward: boolean) => {
      let newIndex = alertIndex + (forward ? 1 : -1);
      if (newIndex < 0) {
        newIndex += alerts!.length;
      }

      updateCounter(counter + 1);
      setAnimationEffect(forward ? forwardEffect : backwardEffect);
      setAlertIndex(newIndex % alerts!.length);
    },
    [alerts, alertIndex]
  );

  const onDismiss = useCallback(() => {
    const currentAlert = alerts && alerts[alertIndex];
    if (currentAlert) {
      cachedService.dismissAlert(currentAlert);
    }
  }, [alerts, alertIndex]);

  const showNextButton = (alerts && alerts.length > 1) || false;

  if (intialConfigAlert) {
    return <InitialConfigAlert {...intialConfigAlert} />;
  }

  if (!alertInfo || alertInfo.type === undefined) {
    return <Fragment />;
  }

  const EffectContainer = animationEffects.getEffectContainer(animationEffect);

  const hasLegacyAction = alertInfo.url || alertInfo.action;
  const hasActions = !!alertInfo?.actions?.length;

  return (
    <StaticAlertsContainer>
      <StyledMessageBar
        isVisible={alertInfo.isVisible}
        isMultiline={false}
        styles={getMessageBarStyles(false, MessageBarType[alertInfo.type], theme)}
        messageBarType={alertInfo.type}
        actions={
          <Row valign={true}>
            {hasLegacyAction && !hasActions && <LegacyActionButton {...alertInfo} id={props.id} />}
            {hasActions && <Actions actions={alertInfo.actions!} />}
            {showNextButton && (
              <NextLink
                onClick={() => slideAlert(true)}
                styles={{ root: { alignItems: "center", textUnderlinePosition: "under" } }}
              >
                <NextWrapper horizontal={true} verticalAlign="center">
                  <MessageWrapper>
                    <FormattedMessage id="static-alerts.next" defaultMessage="Next" />
                    {` [${alertIndex + 1} / ${alerts?.length}]`}
                  </MessageWrapper>
                  &nbsp;
                </NextWrapper>
              </NextLink>
            )}
            {showDismissButton && (
              <ActionButton
                disabled={!alertInfo.dismissable || (!canDismissSystemAlerts && !alertInfo.ownerId)}
                iconProps={{ iconName: "Cancel" }}
                ariaLabel="Cancel Button"
                onClick={onDismiss}
              />
            )}
          </Row>
        }
        {...props}
      >
        <EffectContainer key={counter}>
          <Html value={alertInfo.title} noWrap={true} truncated={true} />
        </EffectContainer>
      </StyledMessageBar>
    </StaticAlertsContainer>
  );
};

const forwardEffect = "slideInRight";
const backwardEffect = "slideInRight";
const mediaTypes = { octetStream: "application/octet-stream" };

export default StaticAlerts;
