import { IDateTimeBox } from "cayo.ui";
import { IntlShape } from "react-intl";
import { OneTimeSchedule, Schedule } from "../../api/cayo-graph";
import { IFormField } from "../../api/schema.api";
import { nameofFactory } from "../../utils/object-utils";
import { formComponentTypes } from "../Form/renderers/form-renderers";
import messages from "./messages";

const oneTimeNames = nameofFactory<OneTimeSchedule>();

const oneTimeForm = (intl: IntlShape, schedule?: Schedule) => {
  const result = {
    title: intl.formatMessage(messages.oneTime),
    items: [
      {
        type: "datetimebox" as formComponentTypes,
        label: intl.formatMessage(messages.startDate),
        autoFocus: true,
        required: true,
        name: oneTimeNames("startDateTime"),
        showTimeZoneInfo: true,
        value:
          (schedule as OneTimeSchedule)?.startDateTime || new Date(new Date().getTime() + 60000),
      } as IDateTimeBox,
    ] as IFormField[],
  };

  return result;
};

export default oneTimeForm;
