import { ITextField } from "cayo.ui";
import { IntlShape } from "react-intl";
import { HourlySchedule } from "../../api/cayo-graph";
import { IFormField } from "../../api/schema.api";
import { nameofFactory } from "../../utils/object-utils";
import { regexs } from "../../utils/regexs";
import { formComponentTypes } from "../Form/renderers/form-renderers";
import messages from "./messages";
import scheduleRegexes from "./regexes";

const hourlyNames = nameofFactory<HourlySchedule>();

const hourlyForm = (intl: IntlShape, schedule?: HourlySchedule) => {
  return {
    title: intl.formatMessage(messages.hourly),
    items: [
      {
        type: "number" as formComponentTypes,
        label: intl.formatMessage(messages.repeatEvery),
        autoFocus: true,
        required: true,
        pattern: regexs.positiveInt32Regex.source,
        name: hourlyNames("period"),
        value: schedule?.period?.toString() || "1",
        tip: intl.formatMessage(messages.hoursTip),
      } as ITextField,
      {
        type: "number" as formComponentTypes,
        label: intl.formatMessage(messages.onMinute),
        required: true,
        pattern: scheduleRegexes.minutesRange.source,
        name: hourlyNames("minute"),
        value: schedule?.minute?.toString() || "0",
        tip: intl.formatMessage(messages.hoursMinutesTip),
      } as ITextField,
    ] as IFormField[],
  };
};

export default hourlyForm;
