import { LoadingOverlay } from "cayo.ui";
import React, { useEffect } from "react";
import { FCC } from "../../types/react";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";

const ODataApp: FCC<{ isLoggedIn: boolean }> = ({ isLoggedIn, children }) => {
  const { isLoading: isTypeAnnotationsLoading, reloadData } = useTypeAnnotations();

  useEffect(() => {
    if (isLoggedIn) {
      reloadData();
    }
  }, [isLoggedIn]);

  if (isTypeAnnotationsLoading) {
    return <LoadingOverlay text="Loading annotations..." />;
  }

  return <>{children}</>;
};

export default ODataApp;
