import React, { FC } from "react";
import CommandBar from "../../CommandBar";
import ObjectTitle from "../../ObjectTitle";

const CustomCommandBar: FC = (props: any) => {
  return (
    <CommandBar
      overflowProps={{ ariaLabel: "More commands" }}
      scheme={props}
      {...props}
      gridState={{ selectedItems: [props?.object] }}
      style={{ marginLeft: 16 }}
    />
  );
};

const customFormRenderers = {
  objecttitle: ObjectTitle,
  commandbar: CustomCommandBar,
};

export default customFormRenderers;
