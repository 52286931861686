import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
  width: 580px;

  .column-checkbox {
    margin-top: 10px;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-top: 10px;

  div {
    min-width: 120px;
    width: 100%;
  }

  .text-field {
    min-width: 200px;
  }
`;

export const Reset = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  display: flex;
  -webkit-box-pack: justify;
  gap: 4px;
  cursor: pointer;

  i {
    align-items: center;
    display: flex;
  }
`;

export const FooterWrap = styled.div`
  color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  gap: 4px;
  margin-top: 10px;
`;
