export function arrayBufferToBase64(buffer?: ArrayBuffer) {
  if (!buffer) {
    return "";
  }
  const view = new Uint8Array(buffer);
  const binaryString = String.fromCharCode.apply(null, Array.from(view)); // Convert buffer to binary string
  const base64String = btoa(binaryString); // Convert binary string to Base64

  return base64String;
}
