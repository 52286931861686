import {
  Callout,
  CommandBar,
  DirectionalHint,
  List,
  Nav as OfficeNav,
  SearchBox,
  Stack,
} from "@fluentui/react";
import { ITheme, useComponentLogger, useTheme } from "cayo.ui";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { INavLink as IAdminNavLink, IScheme } from "../../api/schema.api";
import { urlUtils } from "../../utils/url-utils";
import useCallout from "../GlobalHooks/callout.hook";
import Icon from "../Graphics/Icon";
import { onRenderCell } from "../InplaceDateTimeRange/renderers";
import { classNames } from "../InplaceDateTimeRange/styles";
import { useNavTreeSearch } from "./Nav.Logic";
import NavCollapsed from "./NavCollapsed";
import { getSearchBoxStyle, navTreeStyle } from "./NavTree.Style";
import { useNav, useToggleNavCollapsed } from "./nav-hooks";

type NavTreeProps = IScheme & {
  groups: IAdminNavLink[];
};

const Nav: FC<NavTreeProps> = (props) => {
  const log = useComponentLogger(Nav);
  const { groups, onLinkExpandHandler, selectedKey, isMedium, resolveName } = useNav(props.groups);
  const { collapsed } = useToggleNavCollapsed();
  const theme = useTheme();

  const { isCalloutVisible, onShowTooltip, onDismiss, tooltipButtonRef, calloutRef } = useCallout();
  const { search, searchResult } = useNavTreeSearch(groups, resolveName);
  const history = useHistory();

  log.debug("props, isMedium", props, isMedium);

  return (
    <NavContainer collapsed={collapsed} isMedium={isMedium}>
      {!collapsed && (
        <Stack horizontal={true} verticalAlign={"center"} style={{ padding: "4px 8px" }}>
          <div ref={tooltipButtonRef} style={{ width: "100%", flexGrow: 1, minWidth: "100%" }}>
            <SearchBox
              placeholder={"Search menu"}
              styles={getSearchBoxStyle(theme)}
              style={{}}
              onFocus={() => {
                if (searchResult.length) {
                  onShowTooltip();
                }
              }}
              onChange={(e) => {
                search(e?.currentTarget.value);

                if (e?.currentTarget.value) {
                  onShowTooltip();
                } else {
                  onDismiss();
                }
              }}
            />
          </div>
          <CommandBar items={[]} style={{ visibility: "hidden" }} />
        </Stack>
      )}
      {isCalloutVisible && !!searchResult.length && (
        <Callout
          target={tooltipButtonRef}
          ref={calloutRef}
          setInitialFocus={false}
          directionalHint={DirectionalHint.bottomCenter}
          onDismiss={onDismiss}
          role="alertdialog"
          layerProps={{ styles: { root: { zIndex: 1000002 } } }} //loading overlay
          style={{ maxWidth: 300 }}
          styles={{ root: { minWidth: 250 } }}
          //onMouseLeave={onDismiss}
        >
          <List
            className={classNames.optionsList}
            items={searchResult}
            onRenderCell={(item, index) =>
              onRenderCell(item!, index!, true, (item) => {
                onDismiss();
                const newPath = urlUtils.trim(item.value);
                history.push("/" + newPath);
              })
            }
          ></List>
        </Callout>
      )}
      <div style={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
        {!collapsed ? (
          <OfficeNav
            groups={groups}
            onLinkExpandClick={onLinkExpandHandler}
            selectedKey={selectedKey}
            onRenderLink={(p, r) => {
              if (p?.isCustomIcon) {
                return (
                  <Stack
                    verticalAlign={"center"}
                    verticalFill={true}
                    horizontal={true}
                    horizontalAlign={"baseline"}
                    style={{ height: "100%" }}
                    tokens={{ childrenGap: 6 }}
                  >
                    <Icon
                      iconName={p?.icon}
                      style={{
                        height: 32,
                        color: "white",
                        fontSize: 16,
                        marginLeft: -6,
                        marginTop: -12,
                        width: 16,
                      }}
                    />
                    {r!({ ...p, icon: undefined })}
                  </Stack>
                );
              } else {
                return r!(p);
              }
            }}
            styles={navTreeStyle(theme)}
          />
        ) : (
          <NavCollapsed
            groups={groups}
            selectedKey={selectedKey}
            expandLink={onLinkExpandHandler}
          ></NavCollapsed>
        )}
      </div>
    </NavContainer>
  );
};

const NavContainer = styled.div<{ collapsed: boolean; isMedium: boolean }>`
  height: 100%;
  display: flex;
  width: ${(props) => (props.collapsed ? "50px" : "300px")};
  flex-flow: column;
  flex-shrink: 0;
  justify-content: stretch;
  background-color: ${(props) => (props.theme as ITheme).cayoTheme.brandColors.thirdBackground};
`;

export default Nav;
