import { FormatFuncType, TooltipHost, getFormatter, stringUtils } from "cayo.ui";
import React from "react";
import { Entity, PropertyIcon } from "../../../api/cayo-graph";
import { IColumnRenderOptions } from "../../../api/schema.api";
import { getFormatterFromRenderOptions } from "../../../utils/formatter-utils";
import { objectUtils } from "../../../utils/object-utils";
import Icon from "../../Graphics/Icon";
import Column from "../../Layout/Column";
import Row from "../../Layout/Row";
import { AuxRenderProps, IActionPromise, IconEx } from "../../LegacyDetailsList/cell-renderers";
import LinkedObject from "../components/LinkedObject";
import RenderOptionsFormatter from "../utils/field-formatter";

export const renderObjectProp = (
  key: string,
  o: object,
  title?: string,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  const titleComponent = title && (
    <div style={{ display: "inline-flex", marginRight: 4 }}>{title}</div>
  );
  const valuesComponent = (
    <div key={key} style={{ display: "flex", flexFlow: "column", paddingBottom: 4 }}>
      {Object.keys(o).map((k, ii) => {
        const value = o[k] as any;
        const isObject = Object.prototype.toString.call(value) === "[object Object]";
        const isLinkedObject = isObjectLink(value);
        if (isObject) {
          return (
            <div
              key={ii}
              style={{
                display: "inline-flex",
                alignItems: isLinkedObject ? "center" : "baseline",
                height: "100%",
              }}
            >
              <label>{k}</label>: &nbsp;
              {isLinkedObject ? (
                <LinkedObject
                  {...value}
                  onItemClicked={onItemClicked}
                  getIconAnnotation={auxProps?.getIconAnnotation}
                />
              ) : (
                renderObjectProp(k + ii, value)
              )}
            </div>
          );
        } else if (!isObject) {
          const isArray = Array.isArray(value);
          const isStringArray = isArray && value.length && typeof value[0] === "string";
          let strValue = "";
          if (isStringArray) {
            strValue = value.join("; ");
          } else if (isArray) {
            return (
              <div key={ii} style={{ display: "inline-flex", alignItems: "start", height: "100%" }}>
                <label>{k}</label>: &nbsp;
                {renderArrayProp(key + ii, value, undefined, onItemClicked, auxProps)}
              </div>
            );
          } else {
            strValue = (value ?? "").toString();
          }
          return (
            <div key={ii}>
              <label>{k}:</label>
              <span key={ii}> {strValue}</span>
            </div>
          );
        }
        return (
          <div key={ii}>
            <label></label>
            <span>{value?.toString()}</span>)
          </div>
        );
      })}
    </div>
  );

  if (titleComponent) {
    return (
      <Row key={key}>
        {titleComponent}
        {valuesComponent}
      </Row>
    );
  }
  return valuesComponent;
};

const renderArrayProp = (
  key: string,
  o: any[],
  title?: string,
  onItemClicked?: IActionPromise | undefined,
  auxProps?: AuxRenderProps
) => {
  if (Object.prototype.toString.call(o) !== "[object Array]") {
    return <React.Fragment key={key} />;
  }
  // exclude null value
  const arr = o.filter((v) => !(v === null || v === undefined));
  if (arr.length === 0) {
    return <React.Fragment key={key} />;
  }
  const isSingleValue = o.length === 1;
  const firstObject = arr[0];
  if (
    firstObject.objectName &&
    firstObject.objectType &&
    (firstObject.originalObjectPath || firstObject.objectPath)
  ) {
    return (
      <Column key={key} style={{ height: "100%" }}>
        {arr.map((v, i) => (
          <LinkedObject
            key={i}
            {...v}
            onItemClicked={onItemClicked}
            getIconAnnotation={auxProps?.getIconAnnotation}
          />
        ))}
      </Column>
    );
  } else if (arr.length > 0 && typeof firstObject === "object") {
    return (
      <React.Fragment key={key}>
        {arr.map((v, i) =>
          renderObjectProp(
            i.toString(),
            v,
            isSingleValue ? undefined : "#" + (i + 1) + " ",
            onItemClicked,
            auxProps
          )
        )}
      </React.Fragment>
    );
  }
  return <React.Fragment key={key} />;
};

export function isObjectLink(value: any): boolean {
  return (
    !!value &&
    !!(
      value.objectName &&
      (value.objectType || value.linkType) &&
      (value.originalObjectPath || value.objectPath)
    )
  );
}

const typeFields = ["locationType", "targetObjectType", "targetType"];

export const lookupIcon = (item: Entity, auxProps: AuxRenderProps, fieldName?: string) => {
  const target = item.objectType + (fieldName ? "/" + fieldName : "");
  let icon: IconEx | undefined;
  let isIconOnly: boolean | undefined;
  let title: string | undefined;
  let tooltip: string | undefined;

  if (typeFields.includes(fieldName ?? "")) {
    icon = auxProps.getIconAnnotation(item[fieldName!]);
  } else if (fieldName) {
    const suffix = "/" + fieldName;
    const annotation =
      auxProps?.propertyAnnotations?.find((a) => a.target === target) ||
      auxProps?.propertyAnnotations?.find((a) => a.target?.endsWith(suffix));
    if (!annotation || !annotation.icons || annotation.icons.length < 1) {
      return undefined;
    }

    const fieldValue = item[fieldName];
    icon = annotation!.icons.find((i: any) => i.key === fieldValue);
    if (icon) {
      icon = objectUtils.cloneObject(icon);
    }

    const pv = annotation.possibleValues?.find((v) => v.value === fieldValue);
    if (pv) {
      title = pv.name;
      tooltip = pv.tooltip;
    }

    const resolvedColor =
      icon?.iconStyle?.color && auxProps?.theme?.resolveNamedColor(icon?.iconStyle?.color);
    if (resolvedColor) {
      icon!.iconStyle!.color = resolvedColor;
    }

    isIconOnly = true;
  } else {
    icon = auxProps.getIconAnnotation(item.objectType!);
  }

  return { ...icon, isIconOnly, title, tooltip };
};

export const propertyIcon = (icon: PropertyIcon, title?: string) => {
  const realTitle = icon?.tooltip ? undefined : stringUtils.makePascal(icon?.title ?? title);

  let iconComp = (
    <Icon
      iconName={icon.iconId}
      rotate={icon.iconId === "Sync" ? "1" : undefined}
      style={{
        color: icon?.iconStyle?.color,
        display: "flex",
        fontSize: icon.iconStyle?.fontSize ? +icon.iconStyle.fontSize : 14,
        fontWeight: icon.iconStyle?.fontWeight as any,
        alignItems: "center",
      }}
    />
  );

  if (icon.tooltip) {
    iconComp = <TooltipHost tooltip={icon.tooltip}>{iconComp}</TooltipHost>;
  }

  return (
    <div
      title={realTitle}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {iconComp}
    </div>
  );
};

type RenderOptionsType = IColumnRenderOptions & {
  itemFormatValue: string | undefined;
};

export const formatValue = (
  item: Entity,
  fieldName: string,
  renderOptions: RenderOptionsType | undefined,
  auxProps?: AuxRenderProps
) => {
  const suffix = "/" + fieldName;
  const target = (item.objectType || stringUtils.trim(item["@odata.type"], "#")) + "/" + fieldName;

  let formatter = renderOptions ? getFormatterFromRenderOptions(renderOptions) : undefined;

  const value = item[fieldName];
  if (fieldName === "propertyName") {
    return value;
  }

  const annotation =
    auxProps?.propertyAnnotations?.find((a) => a.target === target) ||
    auxProps?.propertyAnnotations?.find((a) => a.target?.endsWith(suffix));
  if (!!annotation?.possibleValues?.length) {
    const posValue = annotation!.possibleValues!.find(
      (v) => v.value?.toLowerCase() === value?.toLowerCase()
    );
    if (posValue) {
      return posValue.name;
    }
  }

  if (!formatter) {
    formatter =
      auxProps?.propertyAnnotations?.find((a) => a.target === target)?.formatValue ||
      auxProps?.propertyAnnotations?.find((a) => a.target?.endsWith(suffix))?.formatValue;
  }

  const fieldFormatter = new RenderOptionsFormatter(renderOptions, getFormatterFromRenderOptions);

  let result = fieldFormatter.getStringValue(value);
  const builtinFormatter = formatter && (getFormatter(formatter) as FormatFuncType);
  return result || (builtinFormatter && builtinFormatter(item[fieldName], auxProps!.intl));
};

export const renderClassNames = {
  toCopyValueContainer: "cs-to-copy-value-container",
};
