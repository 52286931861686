import rootLogger from "loglevel";
import prefixer from "loglevel-plugin-prefix";

const defaultLevel: rootLogger.LogLevelDesc =
  process.env.NODE_ENV === "production"
    ? "error"
    : process.env.NODE_ENV === "test"
    ? "silent"
    : "debug";
rootLogger.setDefaultLevel(defaultLevel);

prefixer.reg(rootLogger);
prefixer.apply(rootLogger, {
  template: "[%t] %l %n ",
  timestampFormatter(date: Date) {
    return date.toISOString();
  },
});

export interface ILogger extends rootLogger.Logger {
  isTraseEnabled(): boolean;
  isDebugEnabled(): boolean;
  isInfoEnabled(): boolean;
  isWarnEnabled(): boolean;
  isErrorEnabled(): boolean;
}

function decorate(log: rootLogger.Logger): ILogger {
  const l = log as ILogger;
  if (!!l.isTraseEnabled) {
    return l;
  } else {
    l.isTraseEnabled = function () {
      return this.getLevel() <= this.levels.TRACE;
    };
    l.isDebugEnabled = function () {
      return this.getLevel() <= this.levels.DEBUG;
    };
    l.isInfoEnabled = function () {
      return this.getLevel() <= this.levels.INFO;
    };
    l.isErrorEnabled = function () {
      return this.getLevel() <= this.levels.ERROR;
    };
    l.isWarnEnabled = function () {
      return this.getLevel() <= this.levels.WARN;
    };
  }

  return l;
}

class RootLogger {
  constructor(private rl: rootLogger.RootLogger) {}

  public getLogger(loggerName: string): ILogger {
    return decorate(this.rl.getLogger(loggerName));
  }

  public trace(...msg: any[]) {
    this.rl.trace(...msg);
  }

  public debug(...msg: any[]) {
    this.rl.debug(...msg);
  }

  public log(...msg: any[]) {
    this.rl.log(...msg);
  }

  public info(...msg: any[]) {
    this.rl.info(...msg);
  }

  public warn(...msg: any[]) {
    this.rl.warn(...msg);
  }

  public error(...msg: any[]) {
    this.rl.error(...msg);
  }
}

// override getLogger method
// const original = rootLogger.getLogger;
// rootLogger.getLogger = (name: string) => decorate(original(name));

const setLogLevelKey = "setLogLevel";
(global as any)[setLogLevelKey] = (level: rootLogger.LogLevelDesc) => {
  rootLogger.setLevel(level);
  // set level for child loggers
  Object.entries(rootLogger.getLoggers()).forEach(([, l]) => {
    l.setLevel(level);
  });
};

const getLogLevelKey = "getLogLevel";
(global as any)[getLogLevelKey] = () => rootLogger.getLevel();

const logger = new RootLogger(rootLogger);

export const dumpToJson = (obj: any) => {
  try {
    return JSON.stringify(obj, null, 2);
  } catch (e) {
    return `Serialization error: ${e}`;
  }
};

export default logger;
