import { ITextField } from "cayo.ui";
import { IntlShape } from "react-intl";
import { CustomSchedule } from "../../api/cayo-graph";
import { IFormField } from "../../api/schema.api";
import { nameofFactory } from "../../utils/object-utils";
import { formComponentTypes } from "../Form/renderers/form-renderers";
import messages from "./messages";

const customScheduleNames = nameofFactory<CustomSchedule>();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const customForm = (intl: IntlShape, schedule?: CustomSchedule) => {
  return {
    title: intl.formatMessage(messages.customSchedule),
    style: { width: "100%" },
    items: [
      {
        type: "textfield" as formComponentTypes,
        required: true,
        autoFocus: true,
        name: customScheduleNames("expression"),
        value: schedule?.expression,
        tooltip: intl.formatMessage(messages.cronDescription),
        label: "Expression",
      } as ITextField,
    ] as IFormField[],
  };
};

export default customForm;
