import {
  ActionButton,
  Icon,
  IFontStyles,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import { domUtils, Gauge, IGauge, useTheme } from "cayo.ui";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { bindingsUtils } from "../../scheme/bindings";
import { ISignalServiceBase } from "../../services/signal-service-base";
import { urlUtils } from "../../utils/url-utils";

import { commonMessages } from "../common-messages";
import useRunnableObject from "../GlobalHooks/runnable-object.hook";
import SeverityBadge from "../SeverityBadge";
import { dataSetWidgetSignals } from "./dataset-signals.hook";

import WidgetHeader from "./Header";

type GaugeWidgetProps = IGauge & {
  nameKey: string;
  colors?: string[];
  noItemsMessage?: string;
  bindings?: { [key: string]: string };
  dataKeys: string[];
  valueKey: string;

  data: any[];
  signals: ISignalServiceBase<any, any>;
  onRefresh?: () => void;
};

const calcPie = (isRunning: boolean, currentWidth: number, currentHeight: number) => {
  const innerRadius = Math.min(currentHeight, currentWidth) / 4 - 2;
  let outerRadius = innerRadius * 1.5;
  const pieRadius = {
    innerRadius,
    outerRadius,
  };

  let cy = currentHeight - (currentHeight - innerRadius - outerRadius) / 2;
  if (cy < outerRadius) {
    // cy = (currentHeight - innerRadius - outerRadius) / 2;
    pieRadius.outerRadius -= 30;
    if (pieRadius.outerRadius > 50) pieRadius.outerRadius = innerRadius + 30;
  }

  const isMinHeight = currentHeight < 125;
  const pieProps = {
    startAngle: 180,
    endAngle: 0,
    cx: currentWidth / 2,
    cy: isMinHeight ? "40%" : "50%",
  };

  return {
    pieRadius,
    pieProps,
    width: currentWidth,
    height: currentHeight,
    infoPanelBottom:
      (isMinHeight ? 7 : 15) + (isMinHeight && isRunning ? 12 : isRunning ? 7 : 0) + "%",
    infoPanelToken: (isMinHeight ? 6 : 16) + "px",
    hideGoto: isMinHeight,
    levelFontSize: (isMinHeight ? "xSmall" : "medium") as keyof IFontStyles,
  };
};

type PieProps = ReturnType<typeof calcPie>;

export const riskDashboardUrl = "riskAssessment/dashboard";

const GaugeWidget = (props: GaugeWidgetProps) => {
  const { colors, data, dataKeys, valueKey, nameKey } = props;
  const { cayoTheme, officeTheme } = useTheme();
  const [measures, setMeasures] = useState<PieProps>();
  const history = useHistory();
  const intl = useIntl();

  // TEMP: move footer to widget scheme
  const location = useLocation();
  const showGoto = useMemo(() => {
    return urlUtils.trim(location.pathname).toLowerCase() !== riskDashboardUrl.toLowerCase();
  }, [location.pathname]);

  const gotoHandler = useMemo(() => {
    if (showGoto) {
      return () => history.push(riskDashboardUrl);
    }

    return undefined;
  }, [showGoto]);

  const realColors = useMemo<any>(() => {
    if (colors) {
      const result: any = {};
      dataKeys!.forEach((k, i) => {
        const color = colors[i];
        const resolvedColor = color.indexOf(".")
          ? (bindingsUtils.resolveExpression(cayoTheme as any, color) as string)
          : color;

        result[k] = resolvedColor;
      });

      return result;
    }

    return cayoTheme.lineChart.lineColors;
  }, [dataKeys, colors]);

  const chartData = useMemo(() => {
    const sectorSize = colors?.length ? 180 / colors!.length : 0;
    var colorData = dataKeys!.map((k) => {
      // const cd = data.find((d) => d[nameKey] === k);
      return { value: sectorSize, color: realColors[k] };
    });

    const sumValues = colorData.map((cur) => cur.value).reduce((a, b) => a + b) || 0;
    let severity = undefined;
    const arrowIndex = colorData.length
      ? dataKeys.findIndex((k) => {
          const counter = data?.find((d) => d[nameKey] === k && d[valueKey]);
          if (counter) {
            severity = (counter[nameKey] as string)?.toUpperCase();
            return counter[valueKey];
          }

          return undefined;
        })
      : undefined;
    const hasArrow = typeof arrowIndex === "number" && arrowIndex >= 0;
    const chartValue = hasArrow ? (colors!.length - arrowIndex) * sectorSize - sectorSize / 2 : 0;

    const arrowData = [{ value: chartValue }, { value: 0 }, { value: sumValues - chartValue }];

    const rawSum = data?.length ? data.map((cur) => cur[valueKey])?.reduce((a, b) => a + b) : 0;

    return {
      colorData: colorData?.reverse(),
      sumValues,
      arrowData,
      chartValue,
      rawSum,
      severity,
    };
  }, [realColors, data, dataKeys]);

  const ref = useRef<any>(null);

  const { onUpdate, objectState } = useRunnableObject({
    actionPath: "run",
    objectPath: "riskAssessment/jobs/8815a12811c84ff084d9c98fd0e9b213",
    stateProp: "executionState",
    onRefresh: () => dataSetWidgetSignals.send("refresh"),
  });

  const isRunning = objectState === "running";

  useLayoutEffect(() => {
    setMeasures(calcPie(isRunning, ref?.current?.clientWidth, ref?.current?.clientHeight));
  }, [ref?.current?.clientWidth, ref?.current?.clientHeight, isRunning]);

  return (
    <Stack
      horizontal={false}
      verticalFill={true}
      horizontalAlign="start"
      verticalAlign="stretch"
      grow={false}
      style={{ height: "100%", width: "100%" }}
    >
      <WidgetHeader title={"Threat meter"} titleSeparator={false} showClock={false} />
      <div
        ref={ref}
        style={{
          flexGrow: 1,
          height: "100%",
          width: "100%",
          display: "flex",
          flexFlow: "column",
          flexShrink: 0,
          cursor: "pointer",
        }}
      >
        <Gauge type="gauge" gotoHandler={gotoHandler} measures={measures} chartData={chartData} />
        <div
          onClick={gotoHandler}
          style={{
            position: "absolute",
            bottom: measures?.infoPanelBottom,
            left: 0,
            right: 0,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            flexFlow: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {!isRunning && data !== undefined && !chartData.severity && (
            <SeverityBadge value="none" text="No threats detected" />
          )}
          {!isRunning && data === undefined && (
            <Text>{intl.formatMessage(commonMessages.noDataAvailable)}</Text>
          )}
          {!isRunning && chartData.severity && (
            <Text
              variant={measures?.levelFontSize}
              block={false}
              style={{
                textTransform: chartData.severity && "uppercase",
                fontWeight: chartData.severity && "bold",
              }}
            >
              {chartData.severity ?? (
                <FormattedMessage id="gauge.no-threats" defaultMessage={"No threats detected"} />
              )}
            </Text>
          )}
          {isRunning ? (
            <Stack verticalAlign="center" horizontalAlign="center" grow={true} verticalFill={true}>
              <Spinner
                size={SpinnerSize.small}
                label={intl.formatMessage(commonMessages.detectingThreats)}
                labelPosition="right"
              />
            </Stack>
          ) : (
            <PrimaryButton
              style={{ marginTop: measures?.infoPanelToken }}
              onClick={(e) => {
                domUtils.stopMouseDown(e);
                onUpdate();
              }}
              onMouseDown={domUtils.stopMouseDown}
            >
              <FormattedMessage id="gauge.run" defaultMessage="Run" />
            </PrimaryButton>
          )}
        </div>
      </div>
      {gotoHandler && !measures?.hideGoto && (
        <div
          style={{
            position: "absolute",
            bottom: 8,
            left: 0,
            right: 8,
            display: "flex",
            justifyContent: "right",
          }}
        >
          <ActionButton
            id="ViewAllChanges"
            text={""}
            style={{ color: officeTheme.semanticColors.link, height: "auto" }}
            onClick={() => history.push(riskDashboardUrl)}
            onMouseDown={domUtils.stopMouseDown}
          >
            <FormattedMessage id="gauge.threats.to-dashboard" defaultMessage="Go to dashboard" />
            <Icon
              iconName={"Forward"}
              style={{
                fontSize: 10,
                fontWeight: "bold",
                paddingLeft: 4,
                paddingTop: 2,
              }}
            />
          </ActionButton>
        </div>
      )}
    </Stack>
  );
};

export default GaugeWidget;
