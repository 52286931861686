import { useId } from "@fluentui/react-hooks";
import { LoadingOverlay } from "cayo.ui";
import React, { CSSProperties, FC, Fragment, useMemo } from "react";
import { Entity, PropertyAnnotation } from "../../api/cayo-graph";
import { IScheme } from "../../api/schema.api";
import ajax from "../../libs/ajax";
import logger from "../../libs/logger";
import { IPropertiesPanelSignals } from "../../services/properties-panel-signal.service";
import useService from "../../services/services.hook";
import Dashboard from "../Dashboard";
import Form from "../Form";
import useTypeAnnotations from "../GlobalHooks/type-annotations.hook";
import GenericScheme from "./GenericScheme";
import LoginScheme from "./LoginScheme";
import schemeParts from "./scheme-parts";

export type AuxSchemeProps = {
  object?: Entity;
  signals?: IPropertiesPanelSignals;
  propertyAnnotations?: PropertyAnnotation[];
  parentObjectName?: string;
  parentContainerName?: string;
  onSubmit?: (object: Entity) => Promise<void>;
};

export type CommonSchemeLoaderProps = {
  schemePath: string;
  style?: CSSProperties;
  auxProps?: AuxSchemeProps;
};

type SchemeLoaderProps = CommonSchemeLoaderProps & {};

const SchemeLoader: FC<SchemeLoaderProps> = ({ schemePath, style, auxProps }) => {
  const { object, propertyAnnotations, parentObjectName, parentContainerName, signals, onSubmit } =
    auxProps || {};
  const { getIconAnnotation } = useTypeAnnotations();

  const schemeId = useId("scheme");
  const schemeProps = useMemo(() => {
    const isDashboard = schemePath.toLowerCase().startsWith(schemeParts.ids.dashboard);
    const parts = schemePath.split("/");
    const feature = isDashboard && parts.length === 2 ? parts[1] : undefined;

    if (isDashboard) {
      logger.debug("d");
    }
    return {
      id: schemePath || "guardian",
      componentId: isDashboard ? schemeParts.ids.dashboard : schemePath || "guardian",
      feature,
    };
  }, [schemePath]);

  const { data: jsonScheme, isLoading, error } = ajax.useGet("scheme/{id}", schemeProps);

  const actionBuilder = useService("actionBuilder");

  const type = (jsonScheme as IScheme)?.type;
  const isForm = type === "json-form";
  const compProps = useMemo(() => {
    const Comp = isForm ? Form : schemeMap[schemeProps.componentId] || GenericScheme;

    const isInsidePropPanel = /^(form\/(edit|new|type)|custom|picker)\//.test(schemePath);

    return { Comp, isInsidePropPanel };
  }, [type, schemeProps.componentId, style]);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <Fragment>{error}</Fragment>;
  }

  return (
    <compProps.Comp
      {...jsonScheme}
      key={schemeId || "guardian"}
      actionBuilder={actionBuilder}
      object={object}
      style={style}
      feature={schemeProps.feature}
      propertyAnnotations={propertyAnnotations}
      parentObjectName={parentObjectName}
      parentContainerName={parentContainerName}
      signals={signals}
      onSubmit={isForm ? onSubmit : undefined}
      isInsidePropPanel={compProps.isInsidePropPanel}
      getIconAnnotation={getIconAnnotation}
    />
  );
};

const schemeMap = {
  login: LoginScheme,
  dashboard: Dashboard,
};

export default SchemeLoader;
