import { stringUtils } from "cayo.ui";
import React from "react";
import { Entity } from "../../../api/cayo-graph";
import { IObjectPicker } from "../../../api/schema.api";
import ObjectPicker from "../../ObjectPicker";
import { IPickerItem } from "../../ObjectPicker/IPickerItem";
import { EntitiesToPickerItems } from "../../ObjectPicker/logic";
import { isPicker } from "../modelUtils/build-model";
import { IAdditionalFormFieldProps } from "../types";
import { formUtils } from "../utils";

const objectpicker = (
  annotation: IObjectPicker,
  addProps: IAdditionalFormFieldProps
): JSX.Element => {
  const {
    value,
    disabled,
    setValue,
    error,
    setError,
    getIconAnnotation,
    intl,
    theme,
    possibleValues,
  } = addProps;

  const pickerAnnotation = annotation;
  const props: IObjectPicker = {
    multiselect: pickerAnnotation.multiselect,
    label: stringUtils.toSpaceDelimitedPascalCase(annotation.label!),
    popupTitle: pickerAnnotation.popupTitle,
    queryPath: pickerAnnotation.queryPath,
    columns: pickerAnnotation.columns,
    popupCloseButtonName: pickerAnnotation.popupCloseButtonName,
    displayProperty: "",
    isPicker: isPicker(pickerAnnotation),
    dependsFrom: pickerAnnotation.dependsFrom,
    disabled,
    defaultValue: pickerAnnotation.defaultValue,
    type: annotation.type,
    title: annotation.title,
  };

  const isSimplePicker = pickerAnnotation.isPicker && !pickerAnnotation?.columns?.length;

  // eslint-disable-next-line no-unused-vars

  return (
    <ObjectPicker
      setError={() => null}
      error={error}
      setValue={(v) => {
        setValue(v, annotation);

        if (error) {
          formUtils.checkError(annotation, v, setError, intl);
        }
      }}
      key={annotation.name}
      name={annotation.name}
      hideModificationTimeColumn={true}
      hideObjectTypeIcon={
        annotation.name !== "managedSystemId" && annotation.name !== "targetObjectLocations"
      }
      hideMore={isSimplePicker}
      getIconAnnotation={getIconAnnotation}
      value={
        EntitiesToPickerItems(
          Array.isArray(value) ? (value as Entity[]) : value && [value],
          getIconAnnotation,
          pickerAnnotation as any, //TEMP
          possibleValues
        ) as IPickerItem[]
      }
      {...annotation}
      {...props}
      intl={intl}
      {...(theme as any)}
      possibleValues={possibleValues}
      defaultValue={pickerAnnotation.defaultValue}
    />
  );
};

const pickerRenderers = {
  objectpicker,
};

export default pickerRenderers;
