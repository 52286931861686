import {
  IDateTimeBox,
  IDropDown,
  IFormatableField,
  dateTimeUtils,
  logger,
  mvConverters,
} from "cayo.ui";
import modelUtils, { IFormModel } from ".";
import { IFormField, IJsonForm, IObjectPicker } from "../../../api/schema.api";
import { nameofFactory } from "../../../utils/object-utils";

import { IUIItem } from "cayo.ui/lib/esm/interfaces/common/IUIItem";
import { Entity } from "../../../api/cayo-graph";
import { ajaxUtils } from "../../../utils/ajax-utils";
import { appUtils } from "../../App/utils";
import { resolveSoftLink } from "../../ObjectPicker/logic";
import { formComponentTypes } from "../renderers/form-renderers";
import { FormProps } from "../types";
import fieldTypes from "./fieldTypes";

const jsonFormNames = nameofFactory<IJsonForm>();

const log = logger.getLogger("build-model");

export const isPicker = (a: IUIItem) => {
  return a.type === "object-picker";
};

export const isPropertyGroup = (a: IUIItem) => {
  return a.type === "property-group";
};

const isDropDown = (a: IUIItem) => {
  return a.type === "drop-down";
};

type FormItem = IUIItem & { modelProperty?: string; value?: any };

export const getPropValue = (item: FormItem, object?: Entity) => {
  let result: any = undefined;
  const property = item.modelProperty || item.name!;
  if (property.indexOf(".") === -1) {
    result = object ? object[property!] : item.value;
  } else if (object) {
    const parts = property.split(".");
    let current = object;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (current[part]) {
        current = current[part];
      } else {
        break;
      }
    }

    result = current;
  }

  return result;
};

const getModelPropertyName = (name: string, parentName?: string) => {
  return parentName ? `${parentName}.${name}` : name;
};

export const buildModel = (formProps: FormProps, parentName?: string) => {
  const { items, object } = formProps;
  let model: IFormModel = {};
  if (!items?.length) {
    return model;
  }

  if (formProps.targetType) {
    model[jsonFormNames("targetType")] = formProps.targetType;
  }

  const formFields = items.filter((i) => !!i.name && i.type) as IFormField[];
  for (const item of formFields) {
    const name = getModelPropertyName(item.name!, parentName);
    if (item.name !== name) {
      (item as any).modelProperty = name;
    }

    let value = object
      ? modelUtils.resolvePropertyValue(name, object, item.type)
      : (item as any).value;

    const vc = mvConverters.to[item.type];
    if (vc) {
      value = vc(value);
    }
    if (isPropertyGroup(item)) {
      if (!(item as IFormatableField).formatter) {
        const propertyGroup = {
          ...formProps,
          items: (item as IJsonForm).items,
          targetType: undefined,
        };
        const { model: innerModel } = buildModel(
          propertyGroup,
          (item as any).modelProperty ?? item.name
        );
        model = { ...model, ...innerModel };
        continue;
      }
    } else if (!isPicker(item) && !value) {
      if (isDropDown(item)) {
        const dropDown = item as IDropDown;
        const hasSelection = !!dropDown?.options?.find((o) => o.selected);
        if (!hasSelection && !dropDown.readOnly) {
          dropDown.options[0].selected = true;
          value = dropDown.options[0].key;
        }
      } else {
        value = value ?? item.defaultValue;
        if (item.type === "number" && typeof value === "string") {
          let convertedValue = Number.parseInt(value);
          if (Number.isNaN(convertedValue)) {
            log.error("Failed to parse value", value);
          } else {
            value = convertedValue;
          }
        }
      }
    }

    if (
      item.type === ("datetimebox" as formComponentTypes) &&
      (item as IDateTimeBox).editMode === "onlyTime" &&
      typeof value === "string"
    ) {
      value = value.slice(0, 5);

      value = dateTimeUtils.convertUtcTimeToLocal(value);
    }

    if (isDropDown(item) ?? value?.length) {
      logger.debug("dd");
    }

    model[name!] = value;
  }

  const pickerAnnotations = items?.filter(isPicker)?.map<IObjectPicker>((a) => a as IObjectPicker);

  const simplePickers = pickerAnnotations?.filter(
    (l) => l.defaultValue && l.dataType !== fieldTypes.softLink
  );

  simplePickers!.forEach((a) => {
    const picker = a as IObjectPicker;
    let mv =
      typeof picker?.defaultValue === "string"
        ? picker?.defaultValue?.split(";").map((v) => decodeURI(v))
        : undefined;

    if (picker.type !== fieldTypes.softLink || !picker.defaultValue) {
      model[a.name!] = mv;
    }
  });

  const softLinkPickers = pickerAnnotations?.filter(
    (l) => l.defaultValue && l.dataType === fieldTypes.softLink
  );

  log.debug("buildModel", model);

  return { model, hasAsyncPickers: !!softLinkPickers?.length };
};

export const initPickers = async (formProps: FormProps, model: IFormModel) => {
  const { items } = formProps;

  if (!items?.length) {
    return false;
  }

  const pickerAnnotations = items?.filter(isPicker)?.map<IObjectPicker>((a) => a as IObjectPicker);

  const softLinkPickers = pickerAnnotations?.filter(
    (l) => l.defaultValue && l.dataType === fieldTypes.softLink
  );

  if (softLinkPickers?.length) {
    try {
      for (const p of softLinkPickers) {
        model[p.name!] = await resolveSoftLink(p.queryPath, p.defaultValue!);
      }
    } catch (e) {
      const error = await ajaxUtils.getError(e);
      appUtils.showError(error.error_description || error.error);
    }

    return true;
  }

  return false;
};
