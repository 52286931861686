import { Icon as FIcon } from "@fluentui/react";
import { useTheme } from "cayo.ui";
import React, { SyntheticEvent, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import Icon from "../../Graphics/Icon";
import SubMenu from "./SubMenu";

const defaultStyles = {
  fontSize: "16px",
  width: "16px",
};

const ContextualMenuItem = ({ item, afterClickCB }: { item: any; afterClickCB: () => void }) => {
  const { text, iconProps, onClick } = item;
  const { officeTheme, cayoTheme } = useTheme();
  const subMenuItems = item?.subMenuProps?.items;
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuPosition, setSubMenuPosition] = useState({ top: 0, left: 0 });

  const colors = {
    hover: officeTheme.semanticColors.menuItemBackgroundHovered,
    active: officeTheme.semanticColors.menuItemBackgroundPressed,
  };

  const isComposition = iconProps?.iconName?.indexOf("+") > -1;

  const handleClick = (e: SyntheticEvent) => {
    if (!item?.disabled && !subMenuItems) {
      onClick(e, item);
      afterClickCB();
    }
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!subMenuItems || !subMenuItems?.length) return;

    const rect = e.currentTarget.getBoundingClientRect();
    setSubMenuPosition({ top: rect.top, left: rect.right });
    setShowSubMenu(true);
  };

  const handleMouseLeave = () => {
    setShowSubMenu(false);
  };

  return (
    <Item
      colors={colors}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Wrapper>
        <Icon
          iconName={iconProps.iconName}
          maskedIconStyle={{ margin: isComposition ? "0px" : "0 4px 0 0" }}
          style={{
            ...defaultStyles,
            ...iconProps.style,
            color: item?.disabled
              ? cayoTheme.brandColors.disabled
              : officeTheme.semanticColors.menuIcon,
          }}
        />
        <Text>{text}</Text>
        {subMenuItems && subMenuItems?.length > 0 && (
          <>
            <FIcon iconName={"ChevronRight"} />

            {showSubMenu &&
              createPortal(
                <SubMenu
                  colors={colors}
                  subMenuItems={subMenuItems}
                  position={subMenuPosition}
                  afterClickCB={afterClickCB}
                />,
                document.body
              )}
          </>
        )}
      </Wrapper>
    </Item>
  );
};

const Item = styled.button<{ colors: { [key: string]: string } }>`
  outline: transparent;
  position: relative;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  color: rgb(50, 49, 48);
  background-color: transparent;
  border: none;
  width: 100%;
  height: 36px;
  display: block;
  cursor: pointer;
  padding: 0px 8px 0px 8px;
  text-align: left;

  &:hover {
    background-color: ${({ colors }) => colors.hover};
  }

  &:active {
    background-color: ${({ colors }) => colors.active};
  }
`;

const Wrapper = styled.div`
  white-space: nowrap;
  height: inherit;
  display: flex;
  align-items: center;
  max-width: 100%;
  gap: 10px;
`;

const Text = styled.span`
  vertical-align: middle;
  display: inline-block;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default ContextualMenuItem;
