import { dateTimeUtils, stringUtils } from "cayo.ui";
import { OdataQuery } from "odata";
import { useMemo } from "react";
import { Entity } from "../../../api/cayo-graph";
import { IObjectLink } from "../../../api/schema.api";
import ajax from "../../../libs/ajax";
import { bindingsUtils } from "../../../scheme/bindings";
import useTypeAnnotations from "../../GlobalHooks/type-annotations.hook";

type Props = Pick<IObjectLink, "name" | "isSoftLink"> & { objectPath?: string; object?: Entity };
const linkProps: OdataQuery = { $select: "objectName,objectPath,id,objectType" };

const useObjectLink = (props: Props) => {
  const { object, name, isSoftLink } = props;
  const { getIconAnnotation } = useTypeAnnotations();
  const softLink = isSoftLink ? object && object[name!] : undefined;

  const realLink =
    !isSoftLink &&
    ((object && bindingsUtils.resolvePropertyPath(object as any, `${name}.objectPath`)) ||
      bindingsUtils.resolveExpression(object as any, `{objectPath}/${name}`));

  const { data: navObject, isLoading } = ajax.useGet<Entity>(
    realLink ? "v0/" + realLink : "",
    { query: linkProps },
    undefined
  );

  const realObject = useMemo(() => {
    return softLink ?? navObject;
  }, [softLink, navObject]);

  const objIcon =
    realObject &&
    getIconAnnotation(
      realObject.objectType ||
        (realObject["@odata.type"] as string)?.replace("#", "") ||
        (realObject as any).linkType
    ).iconId;

  const objectName = useMemo(() => {
    const parts = realObject?.objectName?.split("|");

    try {
      if (parts?.length && parts.length > 1) {
        const dStr = stringUtils.trim(parts[1], " ");
        const d = new Date(dStr);

        if (dateTimeUtils.isValidDate(d)) {
          return (
            parts[0] + ` | ${d.toLocaleDateString()} ${dateTimeUtils.getLocaleTimeFromDate(d)}`
          );
        }
      }
    } catch {}

    return realObject?.objectName;
  }, [realObject?.objectName]);

  return { isLoading, objIcon, navObject: realObject, objectName };
};

export default useObjectLink;
