import { AccessToken } from "../types/access-token.model";
import { GridViewSettings } from "./grid-view-settings";
import PropertiesPanelSettings from "./properties-panel.settins";
import SettingsStore from "./settings-store";
import UserSettings from "./user-settings";
import { ViewSettings } from "./view-settings";

class AppSettings {
  public get accessToken(): string | undefined {
    let idToken = sessionStorage.getItem("id_token") || sessionStorage.getItem("msal.idtoken");

    if (idToken) {
      return idToken;
    }

    const token = this.sessionAuthSetting.get() || this.localAuthSetting.get();

    return token && (token as AccessToken).access_token;
  }

  public get localAuthStorage(): SettingsStore<AccessToken, {}> {
    return this.localAuthSetting;
  }

  public get sessionAuthStorage(): SettingsStore<AccessToken, {}> {
    return this.sessionAuthSetting;
  }

  public get userSetting(): SettingsStore<UserSettings, {}> {
    return this.userSettings;
  }

  public get panelSetting(): SettingsStore<PropertiesPanelSettings, {}> {
    return this.panelSettings;
  }

  public get viewSetting(): SettingsStore<ViewSettings, GridViewSettings> {
    return this.viewSettings;
  }

  private localAuthSetting = new SettingsStore<AccessToken, {}>("token", localStorage);
  private sessionAuthSetting = new SettingsStore<AccessToken, {}>("token", sessionStorage);
  private userSettings = new SettingsStore<UserSettings, {}>("user", localStorage);
  private panelSettings = new SettingsStore<PropertiesPanelSettings, {}>("panel", localStorage);
  private viewSettings = new SettingsStore<ViewSettings, GridViewSettings>("view", localStorage);
}

export const appSettings: AppSettings = new AppSettings();
