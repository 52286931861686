import { defineMessages } from "react-intl";

export const messages = defineMessages({
  // finish
  closeConfirmation: {
    defaultMessage: "Database configuration is incomplete.",
    id: "database-wizard.close-confirmation",
  },

  wizardTitle: {
    defaultMessage: "Connect database",
    id: "database-wizard.title",
  },
  firstStepTitle: {
    defaultMessage: "Select connection option and specify connection parameters",
    id: "database-wizard.first-step-title",
  },
  firstStepName: {
    defaultMessage: "Specify connection parameters",
    id: "database-wizard.first-step-name",
  },

  finishStepName: {
    defaultMessage: "Check and confirm",
    id: "database-wizard.finish-step-name",
  },
  onPremDatabase: {
    defaultMessage: "Connect an on-premises SQL database (Recommended option)",
    id: "database-wizard.onprem-database",
  },
  azureDatabase: {
    defaultMessage: "Connect an Azure SQL database (Recommended option)",
    id: "database-wizard.azure-database",
  },
  builtInDatabase: {
    defaultMessage: "Continue using the built-in database",
    id: "database-wizard.built-in-database",
  },
  validatingDBParameters: {
    defaultMessage: "Validating database parameters...",
    id: "database-wizard.validating-db-parameters",
  },
  connect: {
    defaultMessage: "Connect",
    id: "database-wizard.connect-button-name",
  },
  confirm: {
    defaultMessage: "Confirm",
    id: "database-wizard.confirm-button-name",
  },
  builtinFinishDescription: {
    defaultMessage: "Press Confirm to continue using the built-in database.",
    id: "database-wizard.builtin-finish-description",
  },
  azureFinishDescription: {
    defaultMessage:
      "Press Confirm to connect a pre-created SQL database to Cayosoft Guardian. Cayosoft Guardian will be restarted automatically after establishing a connection to an external database. Please wait until web portal re-appears.",
    id: "database-wizard.azure-finish-description",
  },
  onpremFinishDescription: {
    defaultMessage:
      "Press Confirm to connect the specified SQL database to Cayosoft Guardian. A Cayosoft Guardian service will be restarted automatically after establishing connection to an external database. Please wait until Cayosoft Guardian web portal page reappears.",
    id: "database-wizard.on-prem-finish-description",
  },
  databaseConfigurationBuiltinWarning: {
    defaultMessage:
      "Use this option for <a href='https://support.cayosoft.com/hc/en-us/articles/9992376382477'>Forest Recovery Only or Freeware subscriptions</a>, and trials.<br/>The built-in database has storage and performance limitations, therefore it is not recommended for subscriptions with Change Monitoring.",
    id: "database-configuration.builtin-warning",
  },
});
